import React from 'react';
import Validator from 'validatorjs';
import {Field} from 'react-final-form';
import {InputField} from "../_common_/forms/forms";
import ConfirmFormDialogNew from "../dialog/ConfirmFormDialogNew";
import {useDispatch} from 'react-redux';
import "./editRelationshipNameDialog.scss";
import {updateRelationshipName} from "../../store/wizard/action_creators";
import {isEmpty} from 'lodash'

const validate = (values) => {
    const rules = {};
    const validator = new Validator(values, rules);
    validator.passes();
    return validator.errors.all();
};


const EditRelationshipNameDialog = (props) => {
    const {name, relationshipId} = props;
    const dispatch = useDispatch();
    const handleSubmit = (values) => {
        if (isEmpty(values.relationshipName)) {
            return updateRelationshipName(relationshipId, {remove: ["name"]})(dispatch)
        }
        else {
            return updateRelationshipName(relationshipId, {add: [{key: "name", value: values.relationshipName}]})(dispatch)
        }
    }

    return (
        <ConfirmFormDialogNew
            title={`Update Relationship Name`}
            activateSubmit={handleSubmit}
            confirmText="Update"
            layoutClassName="edit-relationship-name-dialog"
            validate={validate}
            extractError={(error) => error.data.message ? error.data.message : error.data}
            disableActionTillChange={true}
            {...props}
        >
            <Field component={InputField} name="relationshipName"
                              placeholder={""  || "Name your relationship"} label="Name"
                              type="text" initialValue={name}/>
        </ConfirmFormDialogNew>
    )
};

export default EditRelationshipNameDialog;
