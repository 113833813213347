import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import {
    getFormValuesFromSettings,
    getSettingsFromSessionStorage,
    saveSettingsToSessionStorage
} from './../../../utils/settings-utils';
import {
    getCredentialsByType,
    getKeyNameByProviderAndProtocol,
    getTypeByProviderAndProtocol
} from './../../../utils/sync-utils';
import useRunOnce from './../../../hooks/useRunOnce';
import { getWizardParamsFromWizardState } from '../../../utils/mapStateToPropsUtils';
import { updateUrlParams, wizardStepExited, wizardStepStarted } from './../../../store/wizard/action_creators';
import { isCloudToCloud, protocolSupportsTags, findAclValuesByKey, aclOptionsMap } from "./../../../utils/relationshipUtils";
import { noop, get } from 'lodash'
import SyncSettingsForm from "../../tabs/syncSettings/SyncSettingsForm";
import ButtonsPanel from "../../widgets/ButtonsPanel";
import WizardStepHeader from "./WizardStepHeaderNew";

const SyncSettingsStep = (props) => {
    const { _wizard, routing, history, up, _cmIntegration, continueToNextStep } = props;
    const { _wizard: { wizardInfo: { source: wizardSource }, selectedWizardParams } } = props;
    const typeByProvider = getTypeByProviderAndProtocol(wizardSource.provider, wizardSource.protocol);
    const keyByProvider = getKeyNameByProviderAndProtocol(wizardSource.provider, wizardSource.protocol);
    const { copyAclOptions, copyAcl } = getCredentialsByType(typeByProvider, get(selectedWizardParams, keyByProvider));
    const { wizardInfo, isSecure } = _wizard;

    const [initialValues, setInitialValues] = useState();
    let submitMethod = noop;
    const setSubmitMethod = (handleSubmit) => {
        submitMethod = handleSubmit;
    }



    useRunOnce(() => {
        const cloudToCloud = isCloudToCloud(null, wizardInfo.source.protocol, wizardInfo.target.protocol)
            || wizardInfo.source.protocol === "box" || wizardInfo.target.protocol === "box"; //box should include only mtime like cloudToCloud
        const initialSettings = getSettingsFromSessionStorage(cloudToCloud);
        const hasOntapS3 = wizardInfo.source.provider === 'ontap' || wizardInfo.target.provider === 'ontap';
        const hasHardLinks = !isSecure && wizardInfo.source.protocol === 'nfs' && wizardInfo.target.protocol === 'nfs';
        const initialFormValues = getFormValuesFromSettings(initialSettings, false, cloudToCloud, hasOntapS3, hasHardLinks, copyAclOptions? findAclValuesByKey(copyAclOptions.value) : {});
        setInitialValues(initialFormValues);
    });

    const onContinue = () => {
        continueToNextStep();
    };

    const doSubmit = (settings) => {
        const falsyAcl = {
            label: 'Copy only files',
            value: 'FILES'
        };

        if (copyAclOptions) {
            const { value: aclValue } = copyAclOptions || falsyAcl;
            
            settings = {
                ...settings,
                ...aclOptionsMap[aclValue]
            }
        } else if (copyAcl) {
            settings.copyAcl = copyAcl;
        }

        saveSettingsToSessionStorage(settings);
        onContinue();
    };

    const sourceProtocol = wizardInfo.source.protocol;
    const targetProtocol = wizardInfo.target.protocol;
    const targetProvider = wizardInfo.target.provider;
    const sourceProvider = wizardInfo.source.provider;
    const cloudToCloud = isCloudToCloud(null, sourceProtocol, targetProtocol);
    const showObjectTaggingSection = protocolSupportsTags(targetProtocol) && (targetProvider !== 'ontap');
    const showCopyPropertiesSection = cloudToCloud && targetProvider !== "ontap" && sourceProvider !== "ontap";
    const showCreateTimeFilterSection = sourceProtocol === "cifs";
    const isCloudToCloudFlag = cloudToCloud || sourceProtocol === "box" || targetProtocol === "box";
    const includeBox = sourceProtocol === "box" || targetProtocol === "box";
    const supportsTagsCloudToCloud = protocolSupportsTags(sourceProtocol, sourceProvider) && protocolSupportsTags(targetProtocol, targetProvider);
    const hasOntapS3 = sourceProvider === 'ontap' || targetProvider === 'ontap';
    const hasHardLinks = !isSecure && sourceProtocol === 'nfs' && targetProtocol === 'nfs';
    const supportsContinuousSync = ['s3', 'gcp', 'azure'].includes(sourceProvider);

    const options = {
        showObjectTaggingSection, isCloudToCloud: isCloudToCloudFlag, supportsTagsCloudToCloud, hasOntapS3, hasHardLinks,
        showCopyPropertiesSection, showCreateTimeFilterSection, supportsContinuousSync, sourceProvider, includeBox, copyAclOptions
    }

    return (<div className="wizard-step settings-step">
        <div className={classNames("scrollable-area", { up, down: !up })}>

            <WizardStepHeader
                _wizard={_wizard}
                routing={routing}
                history={history}
                title="Sync Relationship Settings"
            />

            <div className="header-text">You can modify settings that define how source files and folders are synced and maintained
                in the target location</div>

            <SyncSettingsForm doSubmit={doSubmit}
                initialValues={initialValues}
                setSubmitMethod={setSubmitMethod} options={options} dataSenseIntegration={!!_cmIntegration.dataSense} />
        </div>
        <ButtonsPanel
            variant="white"
            type="submit"
            onClick={(event) => submitMethod(event)}
            text="Continue" />
    </div>)
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _ui: { ...state.syncReducer._ui, ...state.global._ui },
        routing: state.router,
        _cmIntegration: state.global._cmIntegration,
    }
};

const mapDispatchToProps = {
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(SyncSettingsStep);
