import React from 'react';
import classNames from 'classnames';
import "./loading-bar.scss";

const LoadingBar = ({className}) => {
    const classes = classNames("loading-bar", className);
    return (
        <div className={classes}>
            <div className="the-blue"/>
        </div>
    )
};

export default LoadingBar;