import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {
    clearUrlParamsIfChanged,
    getDataBrokersGroups,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from './../../../store/wizard/action_creators'
import {FM_AGENT} from './../../../consts';
import classNames from 'classnames';
import useRightPanel from './../../../hooks/useRightPanel';
import DataBrokerWizard from './../../dataBrokerWizard/DataBrokerWizard';
import ButtonsPanel from "./../../widgets/ButtonsPanel";
import _ from 'lodash';
import {Button} from '@netapp/shared-components';
import {getPropFromState, getWizardParamsFromWizardState} from './../../../utils/mapStateToPropsUtils';
import {credentialsRequiredOnDataBroker} from './../../../utils/reducerUtils';
import {ReactComponent as DataBrokerIcon} from "./../../../assets/svgs/data-broker-default.svg";
import useShowHowItWorks from "./useShowHowItWorksNew";
import HowDoesItWorkButton from "../../systemFlow/HowDoesItWorkButton";
import DataBrokersNew from "./DataBrokersNew";
import useStepinize from "../useStepinize";
import './../../tabs/manageDataBrokers/manageGroups.scss'

const useDataBrokers = (data, wizardInfo, isSecure, shouldDisplayPortBool) => {
    const dataBrokersGroupsDisabledInfo = useMemo(() => {
        return data.map(group => {
            if (isSecure){
                if (group.dataBrokers.length > 1) {
                    return {
                        ...group,
                        isDisabled: true,
                        disabledErrorMessage: "An encrypted sync relationship must be associated with a data broker group that has only one data broker. Select a different data broker group."
                    }
                }
                else if (!group.dataBrokers[0].isReady) {
                    return {
                        ...group,
                        isDisabled: true,
                        disabledErrorMessage: "The data broker group must contain an active data broker. If in process of creation, please wait until complete. Or select a different data broker group"
                    }
                }
                return {
                    ...group
                }
            }

            const activeDataBrokers = _.filter(group.dataBrokers, {isReady: true})
            if (activeDataBrokers.length === 0) {
                return {
                    ...group,
                    isDisabled: true,
                    disabledErrorMessage: "The data broker group must contain an active data broker. If in process of creation, please wait until complete. Or select a different data broker group"
                }
            }
            //no data broker with awsCredentials
            const missingAwsCredentials = credentialsRequiredOnDataBroker('s3', wizardInfo) && !_.find(group.dataBrokers, (dataBroker) => {
                return !!dataBroker?.placement?.awsAccountId;
            });
            //no dataBroker with gcp credentials
            const missingGcpCredentials = credentialsRequiredOnDataBroker('gcp', wizardInfo) && !_.find(group.dataBrokers, dataBroker => {
                return !!dataBroker.placement?.gcpProjectId;
            });

/*
            const LimitationOfBrokersAndGroupsRequired = ['gdrive', 'box'].includes(wizardInfo.target.protocol)
                && (group.dataBrokers.length > 1  ||
                (group?.configuration?.scanner?.concurrency > 1
                || group?.configuration?.transferrer?.processesLimit > 1 ||
                    group?.configuration?.scanner?.concurrency > 1 ||
                    group?.configuration?.transferrer?.processesLimit > 1));
*/

            if (missingGcpCredentials || missingAwsCredentials /*|| LimitationOfBrokersAndGroupsRequired*/) {
                return {
                    ...group,
                    isDisabled: missingAwsCredentials || missingGcpCredentials /*|| LimitationOfBrokersAndGroupsRequired*/,
                    disabledErrorMessage: getMissingCredentialsMessage(missingAwsCredentials, missingGcpCredentials/*, LimitationOfBrokersAndGroupsRequired*/)
                }
            }

            //no need to disable, return the group as is.
            return group;

        })
    }, [data, wizardInfo, isSecure]);

    //filteredDataBrokers contain all brokers that has port in case shouldDisplayPortBool is true, and all brokers without port otherwise.
    //This is because this component can be used in different places in the sync wizard.
    const filteredDataBrokers = dataBrokersGroupsDisabledInfo ? dataBrokersGroupsDisabledInfo.filter(group => (shouldDisplayPortBool ? !!group.dataBrokers[0].port : !group.dataBrokers[0].port)) : [];

    return {groups: filteredDataBrokers}
};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetDataBroker' : 'dataBroker';
};

const fetchData = ({getDataBrokersGroups}) => {
    getDataBrokersGroups();
};
const shouldFetch = (props) => {
    return !props._dataBrokersGroups || !props._dataBrokersGroups.succeeded;
};
const isFetching = ({_dataBrokersGroups}) => {
    return (_dataBrokersGroups.inProgress || !_dataBrokersGroups.data);
};

const loading = () => {
    return {
        className: 'data-movers',
        text: FM_AGENT + 's',
        icon: require('./../../../assets/svgs/group-icon.svg')
    }
}

const DataBrokerStep = props => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {isTargetStepFromSameSourceType} = moreStepInfo;
    const {_dataBrokersGroups, _wizard, up, _relationships,
        continueToNextStep, state, updateSelectedParams, name} = props;
    const {data} = _dataBrokersGroups;
    const {wizardInfo, wizardTypeForSystemFlow, isSecure, selectedWizardParams} = useMemo(() => {
        if (_wizard) {
            return {wizardInfo: _wizard.wizardInfo, wizardTypeForSystemFlow: _wizard.wizardTypeForSystemFlow, isSecure: _wizard.isSecure, selectedWizardParams: _wizard.selectedWizardParams}
        }
        return {};
    }, [_wizard]);

    const {selectedDataBroker, selectedIsTargetDataBrokerInitiator} = selectedWizardParams || {};

    const {setPanelContent, closePanel} = useRightPanel();

    const shouldDisplayPortBool = shouldDisplayPort({
        isSecure,
        selectedIsTargetDataBrokerInitiator,
        isTargetStepFromSameSourceType
    });

    const {groups} = useDataBrokers(data || [], wizardInfo, isSecure, shouldDisplayPortBool);

    const {showHowItWorks, setShowHowItWorks, HowItWorks} = useShowHowItWorks({_relationships, _wizard, selectedParam: selectedDataBroker, name});

    const isAwsAccessRequired = (wizardInfo.source.protocol === 's3' && wizardInfo.source.provider === 's3') || (wizardInfo.target.protocol === 's3' && wizardInfo.target.provider === 's3');
    const isGcpAccessRequired = wizardInfo.source.protocol === 'gcp' || wizardInfo.target.protocol === 'gcp';

    const startDataBrokerWizard = () => {
        setPanelContent(<DataBrokerWizard
            closePanel={closePanel}
            isGcpAccessRequired={isGcpAccessRequired}
            isAwsAccessRequired={isAwsAccessRequired}
            shouldDisplayPortBool={shouldDisplayPortBool}
        />)
    };

    return isFetching(props) ? (LoadingComp)
    : (<div className="full-height">
        {groups.length > 0 &&
        <DataBrokersNew
            continueToNextStep={continueToNextStep}
            wizardState={state}
            updateSelectedParams={updateSelectedParams}
            startDataBrokerWizard={startDataBrokerWizard}
            shouldDisplayPortBool={shouldDisplayPortBool}
            isAwsAccessRequired={isAwsAccessRequired}
            isGcpAccessRequired={isGcpAccessRequired}
            data={groups} {...props} {...moreStepInfo} />}
        {groups.length === 0 &&
        <>
            {showHowItWorks &&
            <div className="wizard-step create-data-broker">
                <div className={classNames("scrollable-area", {up, down: !up})}>
                    <HowItWorks/>
                </div>
                <ButtonsPanel
                    variant="white"
                    type="button"
                    onClick={() => {setShowHowItWorks(false)}} text="Continue"/>
            </div>}

            {!showHowItWorks &&
            <div className="wizard no-existing-brokers">
                <HowDoesItWorkButton wizardType={wizardTypeForSystemFlow} isSecure={isSecure}/>
                <DataBrokerIcon/>
                <div className="title">{`You don't have any existing data brokers`}</div>
                <Button type="button" className="add-button" onClick={startDataBrokerWizard}>
                    <span>+</span>
                    <div>Create Data Broker</div>
                </Button>
            </div>
            }
        </>}
    </div>)
};

const shouldDisplayPort = ({selectedIsTargetDataBrokerInitiator, isTargetStepFromSameSourceType, isSecure}) => {
    if (isSecure) {
        return ((selectedIsTargetDataBrokerInitiator === 'true' && !isTargetStepFromSameSourceType) ||
            (selectedIsTargetDataBrokerInitiator === 'false' && isTargetStepFromSameSourceType));
    }
    return false;
};

const getMissingCredentialsMessage = (missingAwsCredentials, missingGcpCredentials, LimitationOfBrokersAndGroupsRequired) => {
    return LimitationOfBrokersAndGroupsRequired ?
        `A group that has more than 1 data broker or a group that has a data broker configuration with multiple scanners and transferrers can’t be used in a relationship that includes Box or Google Drive.`
        : missingAwsCredentials && missingGcpCredentials
        ? `A group without any data broker that has AWS and GCP credentials can't be used in a relationship that includes S3 and GCP buckets. Select a group with an on-prem data broker that has AWS and GCP credentials.`
        : missingAwsCredentials
            ? `A group without any data broker that has AWS credentials can't be used in a relationship that includes an S3 bucket.`
            : `A group without any data broker that has GCP credentials can't be used in a relationship that includes a GCP bucket.`
};


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _dataBrokersGroups: getPropFromState(state, "_dataBrokersGroups", {}),
        _relationships: getPropFromState(state, "_relationships", {}),
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getDataBrokersGroups,
    clearUrlParamsIfChanged,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(DataBrokerStep);
