import React from "react";
import {LICENSE_TYPE_FREE_TRIAL, LICENSE_TYPE_PAYGO, LICENSE_TYPE_PAYGO_WITH_BYOL, FREE_TRIAL_PERIOD} from '../../../consts';
import classNames from "classnames";
import {ReactComponent as TriangleIcon} from "../../../assets/svgs/exclamation-mark-triangle-icon.svg";


const LicenseInfo = ({className, licenseType, action, subscribed=false, licensesCapacityWarning='', noCharge}) => {

    const renderLicenseWarning = (warningText) => {
        return <div className={classNames(className, 'licenses-capacity-warning')}>
            <span><TriangleIcon/></span>
            <span className="wordwrap">{warningText}</span>
        </div>
    };

    if (licensesCapacityWarning) {
        return renderLicenseWarning(licensesCapacityWarning);
    }

    switch (licenseType) {
        case LICENSE_TYPE_FREE_TRIAL:
            return <div className={className}>
                The free trial automatically begins when you {action ? action : 'register'}.<br/>
                {!subscribed &&
                <div>{`To continue using this service after the ${FREE_TRIAL_PERIOD}-day free trial ends, you have two options:`}</div>}
            </div>;
        case LICENSE_TYPE_PAYGO:
            if (!subscribed && !noCharge) {
                return renderLicenseWarning(`The ${FREE_TRIAL_PERIOD}-day free trial has ended. To continue using this service, you have two options:`);
            }
            return <div/>;
        case LICENSE_TYPE_PAYGO_WITH_BYOL:
            return <div className={className}>
                The number of relationships that you created is larger than the number of reserved relationships
                that your licenses allow. Any additional relationships will be charged at an hourly rate according to
                the number of relationships.</div>;
        default:
            return <div/>
    }
};

export default LicenseInfo;