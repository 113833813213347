import React, {useCallback} from "react";
import SelectableTable from "./SelectableTable";
import { NEW_TARGET_FOLDER_ID } from "../../consts";
import {Field, initialize} from 'redux-form';
import useRunOnce from "../../hooks/useRunOnce";
import {ReactComponent as PlusIcon} from "../../assets/svgs/plus-fill-circle-icon.svg";
import {ReactComponent as CheckmarkIcon} from "../../assets/svgs/checkmark-fill-circle-icon.svg";
import {ReactComponent as XIcon} from "../../assets/svgs/x.svg";

require("./createFolderRow.scss");

const CreateFolderRow = (props) => {
    const {initialTargetFolder, dispatch, selectionOptions,
        currentPath, formName, resetForm, targetPlaceholder, itemIdentifier} = props;

    useRunOnce(() => {
        if (initialTargetFolder) {
            dispatch(initialize(formName, {targetFolder: initialTargetFolder}));
            selectionOptions.selector(currentPath + "/" + NEW_TARGET_FOLDER_ID);
        }
    });

    const renderCreateFolderRow = useCallback(() => {
        const isSelected = selectionOptions.selectedId && selectionOptions.selectedId.endsWith(NEW_TARGET_FOLDER_ID);

        if (!isSelected) {
            return (
                <div className="create-folder-row">
                    <td key="plus-icon" className="check plus-button">
                        <span><PlusIcon/></span>
                    </td>
                    <td className="path">{targetPlaceholder}</td>
                </div>
            );
        }
        else {
            return (
                <div className="create-folder-row">
                    <td key="check-icon" className="check" onClick={resetForm}>
                        <span><CheckmarkIcon/></span>
                    </td>
                    <td className="path target-folder-name">
                        <Field name="targetFolder" component="input"
                               autoFocus
                               placeholder={targetPlaceholder}
                               autoComplete="off"
                                /* don't un-toggle selection when clicking inside of the input */
                               onClick={ (e) => {e.stopPropagation();} }/>
                    </td>
                    <td className="dive clear-button" onClick={resetForm}>
                        {/* any click will outside of the input will clear the target folder selection and text */}
                        <button type="button">
                            <span><XIcon/></span>
                        </button>
                    </td>
                </div>
            )
        }
    }, [selectionOptions, resetForm, targetPlaceholder]);

    const data = [{path: currentPath, [itemIdentifier]: currentPath + '/' + NEW_TARGET_FOLDER_ID}];

    return <SelectableTable noAnimate={true} Table={{renderRow: (selectionOptions, targetName) => renderCreateFolderRow(selectionOptions, targetName)}}
                     data={data} itemIdentifier={itemIdentifier} selectionOptions={selectionOptions}/>

};

export default CreateFolderRow
