import { ThemeProviderProps } from "@netapp/design-system/dist/components/ThemeProvider";

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
type Theme = PropType<ThemeProviderProps, 'theme'>;

export const theme: Theme = 'light';

export const rootThemeVariable = (cssVariable: string) => {
    const root = document.querySelector(`.${theme}-theme`);

    if (root) {
        var rs = getComputedStyle(root!);
        return rs.getPropertyValue(cssVariable);
    }

    return ''
}