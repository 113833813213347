import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AccountInfo } from "../store.types";

const SLICE_NAME = 'accountInfo';

export const initialState: AccountInfo = {};

const accountInfoSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setAccountInfo(state, action: PayloadAction<AccountInfo>) {
            return action.payload;
        },
        setConnector(state, action: PayloadAction<string>) {
            state.connectorId = action.payload;
        },
        setWorkspace(state, action: PayloadAction<string>) {
            state.workspaceId = action.payload;
        }
    }
});

export const { setAccountInfo, setConnector, setWorkspace } = accountInfoSlice.actions;

export default accountInfoSlice;