import React, {useMemo} from 'react';
import {reduxForm, Field, FieldArray, initialize} from 'redux-form';
import {InputField} from '../../_common_/forms/forms.jsx';
import ButtonsPanel from "../../widgets/ButtonsPanel";
import {MAX_S3_TAGS, SERVICE_NAME} from '../../../consts';
import classNames from "classnames";
import _ from "lodash";
import {getObjectStorageTargetTypeFromWizardInfo} from '../../../utils/sync-utils';
import {protocolSupportsTags} from '../../../utils/relationshipUtils';
import WizardStepHeader from './WizardStepHeaderNew';
import useRunOnceWhenTruthy from "../../../hooks/useRunOnceWhenTruthy";
import {ReactComponent as XIcon} from "../../../assets/svgs/x.svg";
import {stringToTags} from "../../../utils/helpers";


const validationMap = {
    s3_metadata: {
        maxKeyLength: 128,
        maxValueLength: 256,
        keyValidationRegex: /[^\d\w+-=._:/@]/,
        keyValidationRegexErr: `Amazon metadata names may contain letters, numbers, or the following special characters: + - . _ : / @`,
        valueValidationRegex: /[^\x00-\x7F]+/, // eslint-disable-line
        valueValidationRegexErr: `Amazon metadata values may contain only ASCII characters`,
    },
    s3: {
        maxKeyLength: 128,
        maxValueLength: 256,
        keyValidationRegex: /^(aws:)|[^\d\s\w+-=._:/@]/,
        keyValidationRegexErr: `Amazon tag keys may not start with "aws:" and may contain letters, numbers, spaces, or the following special characters: + - . _ : / @`,
        valueValidationRegex: /[^\d\s\w+-=._:/@]/,
        valueValidationRegexErr: `Amazon tag values may contain letters, numbers, spaces, or the following special characters + - . _ : / @`,
    },
    azure: {
        maxKeyLength: 128,
        maxValueLength: 256,
        keyValidationRegex: /[^\d\s\w+-=._:/]/,
        keyValidationRegexErr: `Azure index tag keys may letters, numbers, spaces, or the following special characters + - . _ : /`,
        valueValidationRegex: /[^\d\s\w+-=._:/@]/,
        valueValidationRegexErr: `Azure index tag values may contain letters, numbers, spaces, or the following special characters + - . _ : /`,
    },
    azure_metadata: {
        maxKeyLength: 512,
        maxValueLength: 256,
        keyValidationRegex: /^[^a-zA-Z_]|[^\w]/,
        keyValidationRegexErr: `Azure metadata keys may not start with a number, and may contain letters, numbers or the special character _`,
        valueValidationRegex: /[^\x00-\x7F]+/, // eslint-disable-line
        valueValidationRegexErr: `Azure metadata values may contain only ASCII characters`
    },
    gcp_metadata: {
        maxKeyLength: 512,
        maxValueLength: 256,
        keyValidationRegex: /^[^a-zA-Z_]|[^\w]/,
        keyValidationRegexErr: `GCP metadata keys may not start with a number, and may contain letters, numbers or the special character _`,
        valueValidationRegex: /[^\x00-\x7F]+/, // eslint-disable-line
        valueValidationRegexErr: `GCP metadata values may contain only ASCII characters`
    },
    gdrive_metadata: {
        maxKeyLength: 124,
        maxValueLength: 124,
        maxTotalLength: 124,
    }
};

const validationFunc = (tag, targetProtocol, objectTaggingDisabled) => {

    const validationKey = targetProtocol + (objectTaggingDisabled ? '_metadata' : '');
    const {
        maxKeyLength,
        maxValueLength,
        maxTotalLength,
        keyValidationRegex,
        keyValidationRegexErr,
        valueValidationRegex,
        valueValidationRegexErr
    } = validationMap[validationKey];
    let errors = {};

    if (!_.isEmpty(tag)) {
        if (!_.isEmpty(tag.key) && _.isEmpty(tag.value))
            errors.value = "Tag Value is required for a tag with Key";
        if (_.isEmpty(tag.key) && !_.isEmpty(tag.value))
            errors.key = "Tag Key is required for a tag with Value";
        if (tag.key && tag.key.length > maxKeyLength - 1)
            errors.key = `Tag Key must be no longer than ${maxKeyLength} characters`;
        if (tag.value && tag.value.length > maxValueLength - 1)
            errors.value = `Tag Value must be no longer than ${maxValueLength} characters`;
        if (maxTotalLength && tag.key && tag.value) {
            if (tag.key.length + tag.value.length > maxTotalLength) {
                errors.key = `Value and Key together, must be no longer than ${maxTotalLength} characters`
            }
        }
        if (tag.value && valueValidationRegex && valueValidationRegex.test(tag.value)) {
            errors.value = valueValidationRegexErr;
        }
        if (tag.key && keyValidationRegex && keyValidationRegex.test(tag.key)) {
            errors.key = keyValidationRegexErr;
        }
    }
    return errors;
};

const validate = (values, {targetProtocol, objectTaggingDisabled}) => {

    let errors = {};
    if (values.tags)
        errors.tags = values.tags.map((tag) => validationFunc(tag, targetProtocol, objectTaggingDisabled));
    //check if all the tags are valid, hence no error for any tag
    const errorsEmpty = _.find(errors.tags, (obj) => !_.isEmpty(obj)) === undefined;
    return errorsEmpty ? {} : errors;
};

const renderTags = ({fields, targetProtocol, typeName, objectTaggingDisabled}) => {

    const validationKey = targetProtocol + (objectTaggingDisabled ? '_metadata' : '');
    const {maxKeyLength, maxValueLength} = validationMap[validationKey];
    return (
        <div>
            {fields.map((tag, index) =>
                <div key={index} className="row tag">
                    <div className="6-small columns key">
                        <Field component={InputField} label={`${typeName} Key`} name={`${tag}.key`}
                               placeholder={`Up to ${maxKeyLength} characters`}/>
                    </div>
                    <div className="6-small columns value">
                        <Field component={InputField} label={`${typeName} Value`} name={`${tag}.value`}
                               placeholder={`Up to ${maxValueLength} characters`}/>
                    </div>
                    {index !== 0 && <div className="close" onClick={() => fields.remove(index)}><span><XIcon/></span></div>}
                </div>
            )}
            <div className="row add-tag">
                {fields.length < MAX_S3_TAGS && <div>
                    <div className="circle" onClick={() => fields.push()}>+</div>
                    <div>Add Relationship {typeName}</div>
                </div>}
                <div className="info">Optional Field | [Up to {MAX_S3_TAGS}]</div>
            </div>
        </div>
    )
};


const RelationshipTags = (props) => {
    const {dispatch, complete, handleSubmit, relationshipTags, objectTaggingDisabled, setObjectTaggingDisabled,
        _wizard, routing, history, wizardInfo, up, targetProtocol, targetProvider} = props;

    const initialTags = useMemo(() => relationshipTags && relationshipTags !== "noTags" ? stringToTags(relationshipTags) : [{key:"", value:""}], [relationshipTags]);

    const target = useMemo(() => getObjectStorageTargetTypeFromWizardInfo(wizardInfo), [wizardInfo]);

    useRunOnceWhenTruthy(() => {
        dispatch(initialize("relationshipTags", {tags: initialTags}, ['tags[].key', 'tags[].value']));
    }, initialTags);

    const submit = (values) => {
        return Promise.resolve(true)
            .then(() => {
                complete(values, objectTaggingDisabled);
            });
    };

    const typeName = objectTaggingDisabled ? "Metadata" : "Tag";


    return (<form className="wizard-step-form" onSubmit={handleSubmit(submit)}>
            <div className={classNames("scrollable-area", {up, down: !up})}>

                <WizardStepHeader
                    _wizard={_wizard}
                    routing={routing}
                    history={history}
                    title={`Relationship ${typeName === "Tag" ? "Tags" : "Metadata"}`}
                />
                <div className="tags-content">
                    <div className="row-medium">
                        <p>{SERVICE_NAME} assigns the relationship {typeName.toLowerCase() === 'tag' ? 'tags' : 'metadata'} to all of the files transferred to the {target}.<br/>
                        </p>{typeName === "Tag" && <p>This enables you to search for the transferred files by using the {typeName.toLowerCase()} values.</p>}
                    </div>
                </div>
                {protocolSupportsTags(targetProtocol, targetProvider) && <div className="object-tagging">
                    <label className="radio-button">
                        <input name="copyToTags" type="radio"
                               checked={!objectTaggingDisabled}
                               onChange={() => setObjectTaggingDisabled(false)}/>
                        Save on Object's Tags
                    </label>
                    <label className="radio-button">
                        <input name="copyToMetadata" type="radio"
                               checked={objectTaggingDisabled}
                               onChange={() => setObjectTaggingDisabled(true)}/>
                        Save On Object's Metadata
                    </label>
                </div>}

                <FieldArray name="tags" component={renderTags} targetProtocol={targetProtocol} typeName={typeName}
                            objectTaggingDisabled={objectTaggingDisabled}/>
            </div>
            <ButtonsPanel
                variant="white"
                type="submit"
                text="Continue"/>
        </form>
    )
};

export const RelationshipTagsForm = reduxForm({
    form: 'relationshipTags',
    validate
})(RelationshipTags);
