import React from "react";
import EnforcementButton from '../cmIntegration/EnforcementButton';
import {ButtonBase, MenuPopover} from '@netapp/shared-components';
import {cmNavigateTo} from "../../utils/cm-integration-utils";
import "./cmDashboardTabs.scss";
import TabHeader from "../tabs/TabHeader";

export const CmHeaderRightSide = () => {
    return (
        <div className="cm-dashboard-tabs">
        <div className="enforcement-button-container">
            <EnforcementButton/>
            <div className={'main-menu'}>
                <MenuPopover popoverClass={'more-info-popover'} triggerVariant={"card"}
                             menuItems={[
                                 <ButtonBase className="audit" onClick={() => cmNavigateTo('/timeline', null, {service: 'cloud-sync'})}>Timeline</ButtonBase>
                             ]}
                />
            </div>
        </div>
    </div>)
};

export const DashboardTabs = () => {
  return (  <div className="cm-dashboard-tabs">
        <TabHeader/>
    </div>)
};

