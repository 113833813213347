import React, { useState } from 'react';
import {
    FM_AGENT_TYPE_AWS,
    FM_AGENT_TYPE_AZURE,
    FM_AGENT_TYPE_GCP,
    FM_AGENT_TYPE_ONPREM
} from '../../consts';
import { connect } from "react-redux";
import ProviderButton from './ProviderButton';
import { InputField, CheckboxField, TextAreaField } from '../_common_/forms/forms';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Validator from 'validatorjs';
import useRunOnce from '../../hooks/useRunOnce';
import DataBrokerLoadingPage from './DataBrokerLoadingPage';
import { createDataBroker } from "../../store/wizard/action_creators";
import ButtonsPanel from '../widgets/ButtonsPanel';
import './dataBrokerDetails.scss';

const getMoreInfo = (fromGroups, existingGroupName) => {
    if (!fromGroups) return <div />
    if (existingGroupName) {
        return (
            <div className="more-info">
                You are creating a data broker within the <b>"{existingGroupName}"</b> group. Grouping data brokers
                together can help improve the performance of sync relationships.
            </div>
        )
    }
    else return (
        <div className="more-info">
            By default, we'll create this new data broker in a new group by itself. To create this new data broker in an existing group,<br />
            exit this screen and click the action button to the far right of an existing group's name. Grouping data brokers together <br />
            can help improve the performance of sync relationships.
        </div>
    )
}
const DataBrokerDetails = props => {
    const { isGcpAccessRequired, isAwsAccessRequired, shouldDisplayPortBool, goToNextStep, setType, type, defaultProvider = FM_AGENT_TYPE_ONPREM.name,
        initialize, existingGroupId, existingGroupName, setDataBroker, fromManageGroups } = props;
    const { createDataBroker } = props;
    const { handleSubmit, submitting } = props;
    const [ownCertificate, setOwnCertificate] = useState(false);

    useRunOnce(() => {
        if (defaultProvider) {
            setType(defaultProvider);
        }
        if (shouldDisplayPortBool)
            initialize({ port: "443" });
    });

    const onSubmit = async ({ name, port, serverName, certificate, privateKey }) => {
        let dataBrokerDetails = { name, type, port };
        if (ownCertificate) dataBrokerDetails = {...dataBrokerDetails, serverName, certificate, privateKey}

        if (existingGroupId) {
            dataBrokerDetails.groupId = existingGroupId;
        }
        try {
            const newDataBroker = await createDataBroker(dataBrokerDetails);
            setDataBroker(newDataBroker);
            goToNextStep();
        }
        catch (err) {
            handleError(err);
        }
    };

    const handleError = (err) => {
        if (err.status !== 401) {
            const errorMessage = err.data.message ? err.data.message : err.data;
            throw new SubmissionError({ name: errorMessage });
        }
    };

    const credentialsRequiredText = isGcpAccessRequired && isAwsAccessRequired ? 'AWS and Google Cloud' : isGcpAccessRequired ? 'Google Cloud' : 'AWS';
    const disabledTitle = `This type of data broker isn’t supported because ${credentialsRequiredText} credentials are required for this relationship.`;

    return <form className="data-broker-wizard-step data-broker-details" onSubmit={handleSubmit(onSubmit)}>
        {!submitting && <>


            <h3>Select a Provider</h3>

            <div className="content-area">
                <div className="provider-types">
                    <ProviderButton
                        displayName={FM_AGENT_TYPE_AZURE.providerName}
                        handleClick={() => {
                            setType(FM_AGENT_TYPE_AZURE.name)
                        }}
                        icon={FM_AGENT_TYPE_AZURE.colorIcon}
                        disabledIcon={FM_AGENT_TYPE_AZURE.disabledIcon}
                        selected={type === FM_AGENT_TYPE_AZURE.name}
                        disabled={(isGcpAccessRequired && defaultProvider !== FM_AGENT_TYPE_AZURE.name) || ![FM_AGENT_TYPE_AZURE.name, FM_AGENT_TYPE_ONPREM.name, FM_AGENT_TYPE_AWS.name].includes(defaultProvider)}
                        disabledTitle={disabledTitle}
                    />
                    <ProviderButton
                        displayName={FM_AGENT_TYPE_AWS.providerName}
                        handleClick={() => {
                            setType(FM_AGENT_TYPE_AWS.name)
                        }}
                        icon={FM_AGENT_TYPE_AWS.colorIcon}
                        disabledIcon={FM_AGENT_TYPE_AWS.disabledIcon}
                        selected={type === FM_AGENT_TYPE_AWS.name}
                        disabled={(isGcpAccessRequired && defaultProvider !== FM_AGENT_TYPE_AWS.name) || (defaultProvider !== FM_AGENT_TYPE_AWS.name && defaultProvider !== FM_AGENT_TYPE_ONPREM.name)}
                        disabledTitle={disabledTitle}
                    />
                    <ProviderButton
                        displayName={`${FM_AGENT_TYPE_GCP.providerName} Platform`}
                        handleClick={() => {
                            setType(FM_AGENT_TYPE_GCP.name)
                        }}
                        icon={FM_AGENT_TYPE_GCP.colorIcon}
                        disabledIcon={FM_AGENT_TYPE_GCP.disabledIcon}
                        selected={type === FM_AGENT_TYPE_GCP.name}
                        disabled={(isAwsAccessRequired && defaultProvider !== FM_AGENT_TYPE_GCP.name) || (defaultProvider !== FM_AGENT_TYPE_GCP.name && defaultProvider !== FM_AGENT_TYPE_ONPREM.name)}
                        disabledTitle={disabledTitle}
                    />
                    <ProviderButton
                        displayName="On-Prem Data Broker"
                        handleClick={() => {
                            setType(FM_AGENT_TYPE_ONPREM.name)
                        }}
                        icon={FM_AGENT_TYPE_ONPREM.colorIcon}
                        disabledIcon={FM_AGENT_TYPE_ONPREM.disabledIcon}
                        selected={type === FM_AGENT_TYPE_ONPREM.name} />
                </div>
                {type && <div className="form-inputs">
                    <div className="inputs">
                        <Field className="name-input" name="name" component={InputField} label="Data Broker Name" disabled={submitting} />
                        {shouldDisplayPortBool &&
                            <Field className="port-input" name="port" component={InputField} label="Port" disabled={submitting} />
                        }
                    </div>
                    {shouldDisplayPortBool && <div className='userCertificate'>
                        <Field value={ownCertificate} type="checkbox" component={CheckboxField} name="copyAcl" onChange={event => setOwnCertificate(event.target.checked)}>
                            <label htmlFor="copyAcl">Set your own certificate</label>
                        </Field>
                        {ownCertificate && <div className='certificateInputArea'>
                            <Field className="certificate-input" name="serverName" component={InputField} label="Server Name"/>
                            <div className='sertificateArea'>
                                <Field className="certificate-input" name="privateKey" component={TextAreaField} label="Private key"/>
                                <Field className="certificate-input" name="certificate" component={TextAreaField} label="Certificate"/>
                            </div>
                        </div>}
                    </div>}
                </div>}
                {getMoreInfo(fromManageGroups, existingGroupName)}
            </div>
        </>}

        {submitting && <DataBrokerLoadingPage />}

        <ButtonsPanel
            variant="transparent"
            type="submit"
            text="Continue"
            disabled={!type || submitting} />

    </form>
};

const rules = {
    name: ['required', 'regex:/^[a-zA-Z][a-zA-Z0-9-]{0,127}$/'],
    privateKey: ['required'],
    certificate: ['required'],
    serverName: ['required', 'max:256'],
    port: ['required', 'numeric', 'between:1,65535']
};

const validate = (values) => {
    const validator = new Validator(values, rules,
        {
            'regex.name': 'Name must contain only letters, numbers, dashes, must start with a letter and may be up to 128 characters.',
            'between.port': 'Port must be between 1 to 65535'
        });
    validator.passes();
    return validator.errors.all();
};

const DataBrokerDetailsForm = reduxForm({
    form: 'providerSelection',
    validate
})(DataBrokerDetails);

export default connect(null, { createDataBroker })(DataBrokerDetailsForm);

