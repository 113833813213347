import {useState} from 'react'
import {isAclSupportedNfsVersion} from "../../../../utils/relationshipUtils";
import {getNfsVersionList} from "../../../../utils/sync-utils";
import {END_POINT_NAMES_MAP} from "../../../../consts";
import {InputField, SelectField} from "../../../_common_/forms/forms";
import AclSelectionFinalForm from "../AclSelectionFinalFormNew";
import {Field} from "react-final-form";

const NfsComponent = ({provider, protocols, formValues, isSecure, form}) => {
    const [shouldShowAcl, setShouldShowAcl] = useState(()=> {
        return isAclSupportedNfsVersion(formValues?.version?.value);
    });
    const onVersionChange = (version) => {
        form.change('version', version)
        if (!isAclSupportedNfsVersion(version.value)) {
            setShouldShowAcl(false);
            form.change("copyAcl", false);
        }
        else {
            setShouldShowAcl(true);
        }
    }

    const versionsList = getNfsVersionList(provider, false);
    const showACL = protocols === "nfs-nfs" && !isSecure &&  shouldShowAcl; //for nfs make sure the version fits
    const showAccessPoint = provider === "efs";
    const versionTitle = END_POINT_NAMES_MAP[provider.toUpperCase()];
    return <>
        {provider !== 'anf' && <div className="version">
            <div>{versionTitle} Version :</div>
            <Field component={SelectField}
                   onChange={onVersionChange}
                   variant="inline"
                   name="version"
                   removeBottomMargin={true}
                   options={versionsList} className="nfs-version-cb"
                   isSearchable={false}/>
        </div>}
        {showAccessPoint && <div className="credentials-container">
            <Field name="accessPoint"
                   component={InputField}
                   label="EFS Access Point ID"
                   optional={true}/>
        </div>}
        {showACL && <AclSelectionFinalForm/>}
    </>;
}

export default NfsComponent;
