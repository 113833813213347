import React, { useEffect, useMemo, useState } from 'react'
import { connect } from "react-redux";
import { find, map, noop } from 'lodash'
import { ReactComponent as RightArrow } from "../../../assets/svgs/right-arrow.svg";
import { ReactComponent as TimeIcon } from "./../../../assets/svgs/scan-duration.svg";

import MainLoader from "../../_common_/MainLoader/MainLoader";
import { deleteReports } from "../../../store/wizard/action_creators";
import ReportStatus from "./ReportStatus";
import useRunOnceWhenTruthy from "../../../hooks/useRunOnceWhenTruthy";
import { MenuPopover, Select, useDialog } from '@netapp/shared-components';
import moment from "moment";
import ReportViewFiles from "./ReportViewFiles";
import ReportViewObjects from './ReportViewObjects'
import DeleteReportsDialog from "./DeleteReportDialog";

import './reportView.scss'
import { DISABLED_BUTTONS_TITLES, REPORT_STATUS_RUNNING } from "../../../consts";
import ReportErrorsStatus from "./ReportErrorsStatus";
import { HeaderItem } from "../commonComponents/ViewCommonComponents";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useNavigate, useParams } from 'react-router-dom';

const getEndpoint = (endpoints, reportId) => {
    return find(endpoints, (endpoint) => {
        const report = find(endpoint.reports, { id: reportId })
        if (report) return endpoint;
        else return null;
    });
}

const saveToPdf = (report) => {
    setTimeout(() => {
        const elem = document.getElementById('pdf')
        html2canvas(elem, { width: 1280, height: 1255, scale: 2, backgroundColor: "#d7d7d7" })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', [1280, 1255]);
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PDF', 0, 0, pdfWidth, pdfHeight);
                const startTime = moment(report.startTime).format('lll');
                pdf.save(`report-${startTime}.pdf`);
            });
    }, 100);
}

const menuItems = (deleteReports, reportId, setDialog, numOfErrors, report, navigate) => {
    return [
        <button type="button" onClick={(element) => {
            setDialog(<DeleteReportsDialog className="default-dialog" reports={[reportId]} deleteReports={deleteReports}
                rerouteOnComplete={true} navigate={navigate} />, element.target);
        }}
        >
            {report.status === REPORT_STATUS_RUNNING ? "Abort report" : "Delete report"}
        </button>,
        <button type="button"
            onClick={() => reportId && numOfErrors > 0 ? navigate({
                pathname: `/report-errors/${reportId}`,
                search: window.location.search
            }) : noop()}
            disabled={!reportId || numOfErrors === 0}
            title={!reportId ? DISABLED_BUTTONS_TITLES.noPreports : numOfErrors === 0 ? "No Errors" : ""}
        >
            {"View errors"}
        </button>,
        <button type="button"
            onClick={() => reportId ? saveToPdf(report) : noop()}
            disabled={!reportId}
            title={!reportId ? DISABLED_BUTTONS_TITLES.noPreports : ""}
        >
            {"Download pdf"}
        </button>
    ]
}

const filesProtocols = ['nfs', 'cifs', 'box', 'sftp', 'gdrive'];

const ReportView = (props) => {
    const { _endpoints, deleteReports } = props;
    const { reportId } = useParams('reportId');
    const { setDialog } = useDialog();
    const navigate = useNavigate();

    const [report, setReport] = useState(null);
    const [endpoint, setEndpoint] = useState(null)

    const reportsOptions = useMemo(() => map(endpoint?.reports, report => {
        return { label: `Report Start Time: ${moment(report.startTime).format('lll')}`, value: report.id }
    }), [endpoint?.reports]);


    useRunOnceWhenTruthy(() => {
        navigate({
            pathname: `/reports`,
            search: window.location.search
        });
    }, !_endpoints?.data);


    useEffect(() => {
        if (!_endpoints?.data) return;
        const foundEndpoint = getEndpoint(_endpoints.data, reportId);
        const foundReport = find(foundEndpoint.reports, { id: reportId });
        setEndpoint(foundEndpoint)
        setReport(foundReport);
    }, [report, _endpoints, reportId]);

    const reportsClicked = () => {
        navigate({
            pathname: `/reports`,
            search: window.location.search
        });
    };

    const handleReportChange = (event) => {
        const reportId = event.value;
        navigate({
            pathname: `/report-view/${reportId}`,
            search: window.location.search
        });
    }

    if (!report) return <MainLoader />
    const { ui: { endPointSVG, endPointTitle, numOfErrors, humanizedDuration }, endpoint: { protocol, [protocol]: { provider } } } = endpoint;
    const filesProtocol = filesProtocols.includes(protocol);
    return (
        <div className="scrollable-area">
            <div className="report-view">
                <div className="bread-crumbs" title={endPointTitle}>
                    <div className="back" onClick={reportsClicked}>Reports Table </div>
                    <div className="right-arrow"><RightArrow /></div>
                    <div className="path">{endPointTitle}</div>
                </div>
                <div className="pdf" id="pdf">
                    <div className="header" id="pdf1">
                        <div className="header-info">
                            {endPointSVG}
                            <HeaderItem title="Path" data={endPointTitle} className='header-item-path' />
                            <HeaderItem title="Status" data={<ReportStatus status={report.status} reason={report?.reason} />} />
                            {numOfErrors > 0 && <HeaderItem title="Errors"
                                data={<ReportErrorsStatus errorCount={numOfErrors}
                                    lastReportId={report?.id} />} />}
                            <HeaderItem title="Scan Duration" data={<div className="scan-duration"><TimeIcon />{humanizedDuration}</div>} />
                        </div>
                        <div className="select-container">
                            <Select options={reportsOptions}
                                isSearchable={false}
                                isClearable={false}
                                onChange={handleReportChange}
                                value={find(reportsOptions, (option) => option.value === reportId)} />

                            <div className="menu-popover-container">
                                <MenuPopover
                                    popoverClass="menu-popover"
                                    triggerVariant="card"
                                    menuItems={menuItems(deleteReports, reportId, setDialog, numOfErrors, report, navigate)}
                                />
                            </div>


                        </div>
                    </div>
                    {filesProtocol && <ReportViewFiles report={report} protocol={protocol} />}
                    {!filesProtocol && <ReportViewObjects report={report} protocol={protocol} provider={provider} />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    _endpoints: state.syncReducer._endpoints
});

export default connect(mapStateToProps, { deleteReports })(ReportView)
