import React, {useEffect, useMemo} from 'react';
import Isvg from 'react-inlinesvg';
import classNames from 'classnames';
import {Field} from 'redux-form';
import {InputField, SelectField} from './../../_common_/forms/forms.jsx';
import _ from 'lodash';
import {END_POINT_NAMES_MAP} from './../../../consts';
import ButtonsPanel from './../../widgets/ButtonsPanel';

import './serverStepTemplateNew.scss';
import useShowHowItWorks from "./useShowHowItWorksNew";
import HostsTable from "./HostsTableNew";
import WizardStepHeader from "./WizardStepHeaderNew";
import ChangeServer from "./ChangeServerNew";
import useServerStep from "./useServerStepNew";
import { addNotification } from '../../../store/global/action_creators.js';
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts.js';
import { useDispatch } from 'react-redux';


const ServerStepTemplate = (props) => {
    const {handleSubmit, up, _wizard, stepOptions, change, _relationships, name} = props;
    const {title, versionsList, serverSVG, serverPlaceholder, SecondScreenUi, storage,
        serverType, serverTitle, selectType, onChangeVersion, server, getAccessPointParams, changeServerTitle} = stepOptions;

    const dispatch = useDispatch();

    const {step, setStep, submit, scrollArea, selectedVersionValue,
        selectedIp, error, resetCredentialsInput, setSelectedIp, setSelectedVersionValue, setError} = useServerStep({...props, getExtraPath: getAccessPointParams || (() => {}), stepType: "servers"});

    const {showHowItWorks, setShowHowItWorks, HowItWorks} = useShowHowItWorks({_relationships, _wizard, selectedParam: server, name});

    const data = useMemo(() => {
        return _.uniqBy(storage?.data, 'host');
    }, [storage]);

    const changeVersion = (selectedVersion) => {
        setSelectedVersionValue(selectedVersion.value)
        if (onChangeVersion) {
            onChangeVersion(selectedVersion);
        }
    };

    const setNewServer = (serverNameEvent) => {
        resetCredentialsInput();
        const serverName = serverNameEvent.target.value;
        const {stepOptions: {validate}} = props;
        const errors = validate({serverName}, props);
        setSelectedIp(_.isEmpty(errors.serverName) ? serverName : null);
        setError(null);
        return serverName;
    };

    const setSelected = (serverName) => {
        setSelectedIp(serverName);
        setError(null);
        change('serverName', serverName);
    };

    const selectedOption = {value: selectedVersionValue, label: selectedVersionValue};
    const firstTime = data?.length === 0;

    const serverTitleType = `${serverTitle}${selectType && ' '}${selectType}`

    useEffect(() => {
        if (!showHowItWorks && error) {
            addNotification({
                id: NOTIFICATION_CONSTS.UNIQUE_IDS.SERVER_STEP_NOTIFICATION,
                type: NOTIFICATION_CONSTS.TYPE.ERROR,
                children: error
            })(dispatch);
        }
    }, [dispatch, showHowItWorks, error]);

    return (<>
        {!data ? <div/> :
            <div className="wizard-step server-step">
                <form className="wizard-step-form" onSubmit={handleSubmit(submit)}>
                    <div className={classNames('scrollable-area', {up, down: !up})} ref={scrollArea}>

                        {showHowItWorks && <HowItWorks/>}

                        {!showHowItWorks && <>

                            <WizardStepHeader
                                _wizard={_wizard}
                                title={title}
                            />
                            {versionsList &&
                            <div className="version">
                                <div>{END_POINT_NAMES_MAP[serverType]} Version :</div>
                                <Field component={SelectField}
                                       selectedValue={selectedOption}
                                       variant="inline"
                                       onChange={changeVersion} valueField="value"
                                       name="version"
                                       removeBottomMargin={true}
                                       options={versionsList} className="nfs-version-cb"
                                       isSearchable={false}/>
                            </div>
                            }

                            {step === 'ip' && <>
                                <div className="align-center selected-server">
                                    <div className="new-nfs">
                                        <Isvg src={require(`./../../../assets/svgs/${serverSVG}`)}/>
                                        <Field name="serverName"
                                               className="nfs-address"
                                               onChange={setNewServer}
                                               component={InputField}
                                               placeholder={serverPlaceholder}
                                               label={["Azure", "Azure Data Lake Gen 2"].includes(serverType ) ? `Enter the name of an ${serverTitleType}:` :`Define a New ${serverTitleType}:`}/>
                                    </div>
                                </div>

                                {!firstTime && <div className="row-medium align-center">
                                    <div className="hosts-server-table">
                                        <div className="or-divider">
                                            <div className="left-border"/>
                                            <div className="circle">or</div>
                                            <div className="right-border"/>
                                        </div>
                                        <div className="select-header">Select an
                                            Existing {serverTitle} {selectType}</div>
                                        <div className="hostsSelect"><HostsTable selector={setSelected}
                                                                                 tableData={data}
                                                                                 selectedId={selectedIp}/></div>
                                    </div>
                                </div>}
                            </>}

                            {step === 'credentials' && <>
                                <div className="align-center selected-server">
                                    <div className="new-nfs selected">
                                        <Isvg src={require(`./../../../assets/svgs/${serverSVG}`)}/>
                                        <Field name="serverName"
                                               className="nfs-address"
                                               component={InputField}
                                               label={`Selected ${serverTitleType}:`}
                                               disabled={true}/>
                                        <ChangeServer title={changeServerTitle || "Change Server"} onClick={() => {
                                            setStep('ip')
                                        }}/>
                                    </div>
                                </div>
                                <SecondScreenUi {...props} version={selectedVersionValue}/>
                            </>}

                        </>}
                    </div>

                    {showHowItWorks && <ButtonsPanel
                        variant="white"
                        type="button"
                        onClick={() => {setShowHowItWorks(false)}}
                        text="Continue"/>}

                    {!showHowItWorks && <ButtonsPanel
                        variant="white"
                        type="submit"
                        text="Continue"/>}
                </form>
            </div>}
    </>)
};

export default ServerStepTemplate;
