import { apiSlice } from "./api.slice";
import { Relationship } from "./api.types";

const relationshipApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRelationships: builder.query<Relationship[], void>({
            query: () => {
                return {
                    url: `relationships-v2`
                }
            },
            providesTags: result => result ? [
                ...result.map(({ id }) => ({ type: 'relationships' as const, id })),
                { type: 'relationships', id: 'LIST' }
            ] : [{ type: 'relationships', id: 'LIST' }]
        })
    })
});

export const { useLazyGetRelationshipsQuery } = relationshipApiSlice;