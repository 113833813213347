import React from 'react';
import {Field} from 'react-final-form';
import {InputField} from "../../_common_/forms/forms";
import Validator from 'validatorjs';
import ConfirmFormDialogNew from "../../dialog/ConfirmFormDialogNew";
import {useDispatch} from 'react-redux';
import {updateDataBrokerProxy} from "../../../store/wizard/action_creators";
import {isEmpty} from 'lodash'
import "./editProxyDialog.scss";
import {HOST_IPV4_IPV6_REG_EXP, HOST_NAME_REG_EXP, IP_REG_EXP} from "../../../consts";

const rules = {
    password: ['required_with:userName'],
    userName: ['required_with:password','regex:/^[a-zA-Z][a-zA-Z0-9-]{0,127}$/'],
    host: ['required'],
    port: ['required','numeric', 'between:1,65535']
}
const validate = (values) => {
    const {host} = values;
    const validator = new Validator(values, rules);
    validator.setAttributeNames({userName: "User Name", host: "Host", port: "Port"});
    validator.passes();
    const errors =  validator.errors.all();
    if (host && !IP_REG_EXP.test(host) && !HOST_NAME_REG_EXP.test(host) && !HOST_IPV4_IPV6_REG_EXP.test(host)) {
        errors.host = errors.host || [];
        errors.host.push("Invalid hostname or IP address for Host");
    }
    return errors;
};


const EditProxyDialog = (props) => {
    const {data: {id, name}} = props;
    const dispatch = useDispatch();
    const handleSubmit = (values) => {
        if (!isEmpty(values))
            return updateDataBrokerProxy(id, values)(dispatch)
        else return Promise.resolve(true);
    }

    return (
        <ConfirmFormDialogNew
            {...props}
            title={`Update Proxy for Data Broker "${name}"`}
            activateSubmit={handleSubmit}
            confirmText="Update"
            className="edit-proxy-dialog"
            validate={validate}
            extractError={(error) => error.data.message ? error.data.message : error.data}
        >
            <div>
                <b><span>Pay attention: </span> Entering wrong values will cause the Data Broker to become inaccessible, and the only way to fix it will be to create a new Data Broker</b>
                <Field component={InputField} name={"host"}
                       placeholder="Example: 172.16.254.1" label="Host:"
                       type="text" info={"The IP or FQDN address of the proxy server (must be accessible from the data broker subnet)"}/>
                <Field component={InputField} name={"port"}
                       placeholder="Example: 8080" label="Port:"
                       type="text" info={"The TCP port on which the proxy server listens"}/>
                <Field component={InputField} name={"userName"}
                       placeholder="User Name" label="User Name:"
                       type="text" info={"The proxy user name if proxy authentication is needed"}/>
                <Field component={InputField} name={"password"}
                       placeholder="Password" label="Password:"
                       type="password" info={"The proxy password if proxy authentication is needed"}/>
            </div>
        </ConfirmFormDialogNew>
    )
};

export default EditProxyDialog;
