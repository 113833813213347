import React from 'react'
import {useDispatch} from 'react-redux';
import {Form} from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import {DefaultDialogLayout, useDialog} from '@netapp/shared-components';
import { submitErrorsMutators} from 'final-form-submit-errors';


const ConfirmFormDialogNew = ({activateSubmit, extractError, title, confirmText, validate, children, className, layoutClassName, disableActionTillChange, ...props}) => {
    const dispatch = useDispatch();
    const {closeDialog, setDisableClose} = useDialog();

    const getButtons = (submitting, form) => {
        const buttonsArr = [{
            type: "submit",
            text: confirmText,
            loading: submitting,
            disabled: disableActionTillChange && !form.getState().dirty,
            thin: true
        }];

        if (closeDialog) {
            buttonsArr.push({
                text: 'Cancel',
                variant: 'secondary',
                onClick: closeDialog,
                thin: true,
                disabled: submitting
            })
        }
        return buttonsArr
    }
    return (
        <Form validate={(values) => validate(values, props)}
              mutators={{
                  // add submitErrorsMutators to your mutators
                  ...submitErrorsMutators
              }}
              onSubmit={(async (values)=> {
            try {
                setDisableClose(true);
                await activateSubmit(values, dispatch);
                setDisableClose(false)
                closeDialog();
            }
            catch (error) {
                return Promise.resolve({[FORM_ERROR] : extractError ? extractError(error) : "Unknown error"});
            }
        })}>
            {({handleSubmit, submitError, submitting, form}) => {
                const formState = form.getState();
                const {modifiedSinceLastSubmit} = formState;
                return (
            <form noValidate className='generic-form-dialog' onSubmit={handleSubmit}>
                <DefaultDialogLayout
                    className={`${className || ""} ${layoutClassName || ""}`}
                    title={title}
                    buttonsArr={getButtons(submitting, form)}
                    isLoading={submitting}
                    errorMessage={submitError && !modifiedSinceLastSubmit ? submitError : null}>
                    {children}
                </DefaultDialogLayout>
            </form>
        )}}</Form>
    )
}

export default ConfirmFormDialogNew
