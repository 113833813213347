import React, {useState} from 'react';
import {connect} from 'react-redux';
import classNames from "classnames";
import _ from "lodash";
import {FlowEncrypted} from '../../systemFlow/SystemFlow';
import ButtonsPanel from '../../widgets/ButtonsPanel';
import useRunOnce from '../../../hooks/useRunOnce';
import {updateUrlParams, wizardStepExited, wizardStepStarted} from '../../../store/wizard/action_creators';
import WizardStepHeader from './WizardStepHeaderNew';
import {Button} from '@netapp/shared-components';
import {ReactComponent as DotsIcon} from "./../../../assets/svgs/dots-connecting.svg";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";

require("./dataBrokerFunctionStepNew.scss");

const DataBrokerFunctionStep = (props) => {
    const {up, _wizard, routing, history, updateSelectedParams, continueToNextStep} = props;
    const {wizardTypeForSystemFlow, selectedWizardParams: {selectedIsTargetDataBrokerInitiator}} = _wizard;

    const [isTargetDataBrokerInit, setIsTargetDataBrokerInit] = useState("false");

    useRunOnce(() => {
        if (!_.isUndefined(selectedIsTargetDataBrokerInitiator)) {
            setIsTargetDataBrokerInit(selectedIsTargetDataBrokerInitiator);
        }
    });

    const onContinue = () => {
        updateSelectedParams({isTargetDataBrokerInitiator: isTargetDataBrokerInit});
        continueToNextStep();
    };

    return (
        <div className="data-broker-function wizard-step">
            <div className={classNames("scrollable-area", {up, down: !up})}>
                <WizardStepHeader
                    _wizard={_wizard}
                    routing={routing}
                    history={history}
                    title="Define Data Brokers Functionality"
                />

                <p className="description">Define which data broker listens for connection requests on a given port and which one initiates the connection. <br/>
                    After you create the relationship, the initiator starts the connection between the two data brokers and data transfers from the source to the target.</p>

                <div className="diagram">
                    <FlowEncrypted wizardType={wizardTypeForSystemFlow}/>
                </div>
                <div className="buttons-container">
                    <div className="databrokers-function-pair">
                        <div className="databroker-function source"><div>{(isTargetDataBrokerInit === "true")? "LISTENER" : "INITIATOR"}</div></div>
                        <div className="databroker-function target"><div>{(isTargetDataBrokerInit === "true")? "INITIATOR" : "LISTENER"}</div></div>
                    </div>

                    <div className="button-wrapper">
                        <DotsIcon className="dots"/>
                        <Button className="switch-initiator" variant="primary" type="button" onClick={() => {setIsTargetDataBrokerInit(isTargetDataBrokerInit === "true" ? "false" : "true")}}>
                            Switch Functionality</Button>
                        <DotsIcon className="dots translated"/>
                    </div>
                </div>
            </div>
            <ButtonsPanel
                variant="white"
                onClick={onContinue}
                text="Continue"
                type="button"/>
        </div>)
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        routing: state.router,
    }
};

const mapDispatchToProps = {
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(DataBrokerFunctionStep);
