import React from 'react'
import {TooltipInfo} from '@netapp/shared-components';
import {REPORT_STATUS_MAP} from "../../../consts";
import "./reportStatus.scss"

const ReportStatus = ({status, reason}) => {
    const icon = REPORT_STATUS_MAP[status]?.icon;
    const text = REPORT_STATUS_MAP[status]?.text;
    return (
        <div className="report-status">
            {icon && !reason && <div>{icon}</div>}
            {icon && reason && <div className="info"><TooltipInfo popoverClass="report-error-info">{reason}</TooltipInfo></div>}
            {text && <div className="report-status-text">{text}</div>}
            {(!icon || !text) && <div>---</div>}
        </div>
    )
}

export default ReportStatus;
