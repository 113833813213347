import ErrorsDialog from './ErrorsDialog';
import {getDataBrokerNameById} from "../../utils/data-utils";


const RelationshipErrorsDialog = ({errors}) => ErrorsDialog({
  errors,
  errorIdentifier: 'dataBrokerId' ,
  dialogTitle: `${errors.length} error${errors.length > 1 ? 's' : ''} found`,
  getErrorTitle: ({dataBrokerId}) => `Data Broker ${getDataBrokerNameById(dataBrokerId)} failed to connect to the source or target`
});

export default RelationshipErrorsDialog;