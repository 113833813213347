import React from "react";
import {ReactComponent as PencilIcon} from '../../../assets/svgs/pencil-icon.svg';
import {ButtonBase} from "@netapp/shared-components";
import "./changeServerNew.scss";

const ChangeServer = ({onClick, title}) => (
    <ButtonBase type="button" className="text-button change-server" onClick={onClick}>
        <PencilIcon/>
        {title}
    </ButtonBase>
);

export default ChangeServer;
