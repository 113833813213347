import _ from "lodash";
import {fixPath} from "../utils/encoders";
import {addUnhandledMessages, buildStateFromAction} from "../utils/reducerUtils"
import Immutable from "seamless-immutable";
import {RESPONSE_STATUS_FAILED} from "./../consts"
import {
    isObjExistInField,
    updateFailedAndRetrievedAllInAllPlaces,
    updateRetrievedAllInsideFolders
} from "./listActionsUtilsHelpers";


export const updateStoreForShowAction = (key, stateFields, state, action) => {
    const keyValue = Immutable.getIn(state, key);

    if(keyValue){
        //it is an existing show field, just merge with current action to avoid loosing all the folders that were kept in it
        if (_.reduce(stateFields, (condition, field) => condition && (keyValue[field] === action.extra[field]), true)) {
            const builtStateFromAction = buildStateFromAction(action);
            delete builtStateFromAction.data;
            return Immutable.setIn(state, key, Object.assign({}, keyValue, builtStateFromAction));
        }
    }

    const innerState = buildStateFromAction(action, builtState => { //it is a new showShares, for a new host or new dataBroker, clear the old data
        delete builtState.data;
        builtState.folders = {
            "-": {}
        };
        stateFields.forEach(field => {
            builtState[field] = action.extra[field];
        });
        return builtState;
    });
    return Immutable.setIn(state, key, innerState)

};

export const dataAsArray = obj => builtState => {
    builtState.data = (obj && obj.data) ? obj.data : [];
    return builtState;
};
//The function is called in list folders action
export const updateStoreForListFoldersAction = (key, path, state, action, conditionToReturnCurrentState = () => {return true}) => {
    const dataFromState = Immutable.getIn(state, key);
    if (!dataFromState || !dataFromState.data || !dataFromState.data.length > 0 || !conditionToReturnCurrentState()) return state;
    const builtState = buildStateFromAction(action, dataAsArray(dataFromState.folders[path]));
    return state.updateIn(key.concat(["folders"]), folders => folders.set(path, builtState));
};

//Function for update_exports and update_shares actions
export const updateStoreForUpdateShowActionForFileServer = (key, payloadType, fileType, state, action,  conditionToAddUnhandledMessage, keyInResponse = "paths") => {
    //in case we get update exports without requesting show exports (initiated by the server to check if nfs server is connectable on agent creation)
    if (!Immutable.getIn(state, key) || !conditionToAddUnhandledMessage()) {
        return addUnhandledMessages(state, action);
    }
    const {status} = action.payload[payloadType];
    const updatedState = updateFailedAndRetrievedAllInAllPlaces(state, key, status);

    if(status === "FAILED")
        return updatedState;
    else {
        const payloadList = action.payload[payloadType][keyInResponse];
        const updatedList = payloadList.map(directory => {
            return {path: directory, actualPath: fixPath(directory,true), host: action.payload[payloadType].host};
        });

        const updatedStateWithData = updatedState.updateIn(key.concat(["data"]), exports => _.unionBy((exports || []), updatedList, 'actualPath'))
            .updateIn(key.concat(["folders", "-", "data"]), folderData => _.unionBy((folderData || []), updatedList, 'actualPath'));
        return updatedStateWithData;
    }
};

export const updateStoreForUpdateShowActionForBox= (key, payloadType, state, action,  conditionToAddUnhandledMessage, payloadKey = "folders") => {
    //in case we get update exports without requesting show exports (initiated by the server to check if nfs server is connectable on agent creation)
    if (!Immutable.getIn(state, key) || !conditionToAddUnhandledMessage()) {
        return addUnhandledMessages(state, action);
    }
    const {status} = action.payload[payloadType];
    const updatedState = updateFailedAndRetrievedAllInAllPlaces(state, key, status);

    if(status === "FAILED")
        return updatedState;
    else {
        const payloadList = action.payload[payloadType][payloadKey];
        const updatedList = payloadList.map(directory => {
            return {path: directory.name, actualPath: fixPath(directory.name,true), id: directory.id, host: action.payload[payloadType].appName};
        });

        const updatedStateWithData = updatedState.updateIn(key.concat(["data"]), exports => _.unionBy((exports || []), updatedList, 'actualPath'))
            .updateIn(key.concat(["folders", "-", "data"]), folderData => _.unionBy((folderData || []), updatedList, 'actualPath'))
            .updateIn(key.concat(["folders", "-"]), root => root.set("folderId", 0)); //update id 0 for the root
        return updatedStateWithData;
    }
};



// Function for update buckets action and update sgws buckets action
export const updateStoreForUpdateBucketsAction = (key, payloadType, innerKey,  state , action) => {
    const keyValue = Immutable.getIn(state, key);
    if (!keyValue) {
        return addUnhandledMessages(state, action);
    }

    const {status, retrievedAll} = action.payload[payloadType];
    const updatedState = updateFailedAndRetrievedAllInAllPlaces(state, key, status, retrievedAll);
    
    if (status !== "FAILED") {
        const payloadList = innerKey ? action.payload[payloadType][innerKey] : action.payload[payloadType];

        const updatedList = payloadList.map(directory => {
            directory.path = directory.name;
            directory.actualPath = directory.name;
            return directory;
        });
        return updatedState.updateIn(key.concat(["data"]), buckets => _.unionBy((buckets || []), updatedList, 'actualPath'))
            .updateIn(key.concat(["folders", "-", "data"]), folderData => _.unionBy((folderData || []), updatedList, 'actualPath'));
    }
    return updatedState;
};

//Function for update_buckets_folders and update_sg_bucket_folders action
export const updateStoreForUpdateBucketsFoldersAction = (key, payloadType, state, action, storageRoot = "bucket", listName = "list") => {
    const {[storageRoot]: bucket, prefix, path:pathFromPayload} = action.payload.data;
    // on azure data lake we return path because it is a real folder and not prefix
    let updatedPrefix = prefix ?? pathFromPayload;
    if (prefix && !prefix.startsWith("/")) {
        updatedPrefix = "/" + prefix;
    }
    if (pathFromPayload && !pathFromPayload.startsWith("/")) {
        updatedPrefix = "/" + pathFromPayload;
    }
    const path = (prefix||pathFromPayload) ? `${bucket}${updatedPrefix}` : bucket;
    const keyValue = Immutable.getIn(state, key);
    if (!keyValue) {
        return addUnhandledMessages(state, action);
    }
    if(!keyValue || !keyValue.data || !keyValue.data.length > 0 || !isObjExistInField(key, state, {name: bucket})) return state;
    if (!keyValue.folders[path]) return state;

    if(action.payload[payloadType].status === RESPONSE_STATUS_FAILED){
        return updateRetrievedAllInsideFolders(state, key, path);
    }


    const list = action.payload[payloadType][listName];
    const subFolders = list.map(folder => {
        return {bucket: bucket, actualPath: path + fixPath(folder), name: folder, path: folder};
    });
    return updateRetrievedAllInsideFolders(state.updateIn(key.concat(["folders", path, "data"]), data => _.unionBy(data, subFolders, 'actualPath')), key, path);
};



