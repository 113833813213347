import {useEffect, useRef} from "react";
import {includes} from 'lodash'
import { matchPath } from "react-router";
import {
    CM_WE_PROVIDER_SUPPORTED_TYPES,
} from "../consts";


const CM_ACTIONS = {
    ready: 'SERVICE:READY',
    navigate: 'SERVICE:NAVIGATE',
    addNss: 'SERVICE:ADD-NSS',
    onNssAdded: 'SERVICE:NSS-ADDED',
    onTokenUpdate: 'SERVICE:TOKEN-UPDATE',
    onWorkspaceChange: 'SERVICE:WORKSPACE-CHANGE',
    onConnectorChange: 'SERVICE:CONNECTOR-CHANGE',
    onLocationChanged: 'SERVICE:LOCATION-CHANGE'
};

export const sendAppReady = () => {
    window.parent.postMessage({
        type: CM_ACTIONS.ready,
    }, '*')
};

export const navigateToAddNssCredentials = () => {
    console.log('%c posting message SERVICE:ADD-NSS', 'font-weight:bold;color:green');
    window.parent.postMessage({
        type: CM_ACTIONS.addNss
    }, '*')
}

export const cmNavigateTo = (pathname, action, state) => {
    console.log(`%c cmNavigate to: ${pathname} action: ${action} state:${JSON.stringify(state, null, 2)}`, 'font-weight:bold;color:blue');
    window.parent.postMessage({
        type:CM_ACTIONS.navigate,
        payload: {pathname, action, state}
    }, '*')
};

export const isIntegratedCmProvider = (provider) => includes(CM_WE_PROVIDER_SUPPORTED_TYPES, provider);

export const buildCMApiPath = (provider, isHA) => {
    const key = `${provider.toLowerCase()}${isHA ? '-ha' : ''}`
    const paths = {
        "onprem": "onprem",
        "amazon-ha" : "aws/ha",
        "amazon" : "vsa",
        "azure-ha" : "azure/ha",
        "azure" : "azure/vsa",
        "gcp-ha" : "gcp/ha",
        "gcp" : "gcp/vsa",
    }
    return paths[key];
}

export const isInIframe = () => window.self !== window.top;
export const isLaptopScreen = () => window.screen.height <= 900;


export const useHandleCmMessages = (eventHandlers) => {
    const eventsHandlersRef = useRef(eventHandlers);
    eventsHandlersRef.current = eventHandlers;

    useEffect(() => {
        const messageHandler = (event) => {
            const {onConnectorChange, onWorkspaceChange, onTokenUpdate, onNssAdded, onLocationChanged } = eventsHandlersRef.current;
            const {type, payload} = event.data;
            if (includes(Object.values(CM_ACTIONS), type)) {
                console.log(`%c Got message from CM type: ${type} payload: ${JSON.stringify(payload, null, 2)}`, 'font-weight:bold; color:green');
            }
            switch (type) {
                case CM_ACTIONS.onConnectorChange:
                    if (onConnectorChange) {
                        onConnectorChange(payload);
                    }
                    break;
                case CM_ACTIONS.onWorkspaceChange:
                    if (onWorkspaceChange) {
                        onWorkspaceChange(payload);
                    }
                    break;
                case CM_ACTIONS.onTokenUpdate:
                    if (onTokenUpdate) {
                        const {accessToken, userMetadata} = payload;
                        onTokenUpdate({accessToken, userMetadata});
                    }
                    break;
                case CM_ACTIONS.onNssAdded: {
                    if (onNssAdded) {
                        console.log('%c got NSS-ADDED event, getting nss credentials', 'font-weight:bold; color:blue');
                        onNssAdded();
                    }
                    break;
                }
                case CM_ACTIONS.onLocationChanged: {
                    if (onLocationChanged) {
                        onLocationChanged(payload);
                    }
                    break;
                }
                default:
            }

        };
        window.addEventListener('message', messageHandler, false);
        return () => window.removeEventListener('message', messageHandler, false);
    }, [])

};

export const getFromRightPanelValue  = (hasSourceWorkingEnvironment, hasTargetWorkingEnvironment) => {
    if (hasSourceWorkingEnvironment && hasTargetWorkingEnvironment){
        return 'both';
    } else if (hasSourceWorkingEnvironment){
        return 'source';
    } else {
        return 'target';
    }
};


export function getRelationshipIdFromPath(pathname) {
    if (pathname) {
        const resultTest = matchPath({path:'/sync/relationships/:relationshipId'}, pathname);
        if (resultTest){
            return {relationshipId:resultTest?.params?.relationshipId ?? null};
        }
    }
    return {relationshipId:null};
}
