import React, { useEffect } from 'react';
import { buildUrlParams } from './utils/encoders';
import { updateUrlParams } from './store/wizard/action_creators';
import {
    setCmIntegrationDetails,
    getNSSCredentials,
    getAccounts, bootAnalytics
} from './store/global/action_creators';
import ContentWrapper from './components/ContentWrapper';
import TabHeader from './components/tabs/TabHeader';
import Dashboard from './components/tabs/Dashboard';
import LicenseSettings from './components/tabs/licenseSettings/LicenseSettings';
import SyncSettingsEdit from './components/tabs/syncSettings/SyncSettingsEdit';
import FailedDetails from './components/tabs/FailedDetails';
import SyncHeader from './components/newWizard/NewSyncHeader';
import NewWizardContent from './components/newWizard/NewWizardContent';
import { useDialog } from "@netapp/shared-components"
import ThanksForSubscribingDialog from './components/tabs/licenseSettings/ThanksForSubscribingDialog';
import * as actionCreatorsConsts from './store/global/action_creators_consts';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import Oops from './components/Oops';
import CmIntegrationEntryPoint from './components/cmIntegration/CmIntegrationEntryPoint';
import CmHeader from './components/cmIntegration/CmHeader';
import MainLoader from './components/_common_/MainLoader/MainLoader';
import ReloadMessage from './components/cmIntegration/ReloadMessage';
import { getPropFromState } from './utils/mapStateToPropsUtils';
import { setActiveAccount } from "./utils/messages-utils";
import useRunOnce from './hooks/useRunOnce';
import DeploymentsTracker from './components/tabs/DeploymentsTracker';
import ManageGroups from "./components/tabs/manageDataBrokers/ManageGroups";
import GroupSettings from './components/tabs/manageDataBrokers/GroupSettings'
import Reports from "./components/tabs/reports/Reports";
import ReportView from "./components/tabs/reports/ReportView";
import { refreshSso, Auth } from './auth/auth';
import { setUserInfoSuccess, setUserInfoFailed } from './store/global/action_creators';
import { handleAuthResult } from './utils/auth-utils';
import { sendAppReady, useHandleCmMessages } from './utils/cm-integration-utils';
import LandingPage from "./LandingPage";
import ReportErrors from "./components/tabs/reports/ReportErrors";
import { store } from './store/store';
import { useDispatch } from 'react-redux';
import sdk, { setAuthHeader } from "./sdk";
import DragSourceAndTargetStepNew from "./components/newWizard/steps/DragSourceAndTargetStepNew";
import 'chart.js/auto'
import queryString from "query-string"; //needed for all the components that are using react-chartjs-2
import { ROUTES } from './consts';
import NotificationGroup from './components/NotificationGroup/NotificationGroup';
import FullStoryComp from './components/FullStoryComp.jsx';
import { setAccessTokenAndMetaData } from './syncNew/store/slices/auth.slice';
import { Route, useNavigate, useLocation, Routes, useParams } from 'react-router-dom';

const redirect = ({ auth, _ui, _relationships, _licenseSettings, setDialog, navigate }) => {
    if (!get(_licenseSettings, 'succeeded')) return; // wait for license data

    // if user is subscribing
    if (get(auth, "data.subscribing")) {
        // show thanks dialog on top on the appropriate screen
        setDialog(<ThanksForSubscribingDialog className="confirm thanks-for-subscribing" />);
        // do not return in order to show the appropriate screen below
    }
    // else if user needs to pay (has relationships that are not payed via subscription or license)
    else if (_ui.showTrialPage) {
        // show the trial screen with payment options
        navigate({
            pathname: `/license`,
            search: `${window.location.search}&trial=true`
        });
        return;
    }

    if (!_relationships || !_relationships.data) return; // wait for relationships data

    if (_relationships.data.length > 0) navigate({
        pathname: `/dashboard`,
        search: window.location.search
    });
    else {
        navigate({
            pathname: ROUTES.DRAG_SOURCE_AND_TARGET,
            search: window.location.search
        });
    }
};

const RedirectorDumb = props => {
    const { auth, _ui, _relationships, _licenseSettings } = props;
    const { setDialog } = useDialog();
    const navigate = useNavigate();

    useEffect(() => {
        redirect({ auth, _ui, _relationships, _licenseSettings, setDialog, navigate });
    }, [auth, _ui, _relationships, _licenseSettings, setDialog, navigate]);
    return (<div />);

};

const redirectorMapStateToProps = (state) => ({
    auth: state.global.auth,
    _licenseSettings: state.global._licenseSettings,
    _accounts: state.global._accounts,
    _accountInfo: state.global._accountInfo,
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    _relationships: getPropFromState(state, "_relationships", {})
});
const Redirector = connect(redirectorMapStateToProps)(RedirectorDumb);

const AuthenticatedRoute = props => {
    const { pathname } = useLocation();

    if (props.auth.succeeded) {
        return (
            <>
                {pathname === '/cm' && <CmIntegrationEntryPoint />}
                {pathname !== '/cm' && <RouteWithSelectedAccount {...props} />}
                <DeploymentsTracker />
            </>
        )
    } else {
        <MainLoader />
    }
}

const RouteWithSelectedAccount = (props) => {
    const { auth, _accounts, _accountInfo } = props;

    if (auth.accountError) {
        document.body.className = "no-sso"; //to remove the widget
        return <Oops error={auth.accountError} />;
    }

    const AppFrame = () => (
        <>
            <CmHeader />
            <MainLoader />
        </>
    );

    if (!get(_accounts, 'succeeded') || get(_accountInfo, 'inProgress') || get(_accountInfo, 'failed')) return <AppFrame />;

    //An account was selected:
    if (get(_accountInfo, 'succeeded')) {
        return <AppRoutes {...props} />;
    }
    return <LandingPage /> //arrived without account id. go to landing page and redirect to OCCM
};

const AppRoutes = () => {
    useRunOnce(() => {
        sendAppReady();
    });

    return (<Routes>
        <Route exact path="/*" element={<ContentWrapper Content={Redirector} contentUiState="" />} />
        <Route path={`/${ROUTES.DASHBOARD}/:relationshipId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={Dashboard}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.DASHBOARD}/*`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={Dashboard}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.LICENSE}`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={LicenseSettings}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.MANAGE}/:groupId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={ManageGroups}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.MANAGE}/*`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={ManageGroups}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.GROUP_SETTINGS}/:groupId/:groupName`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={GroupSettings}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.REPORTS}/:relationshipId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={Reports}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.REPORTS}/*`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={Reports}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.REPORTS_VIEW}/:reportId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={ReportView}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.REPORTS_ERROR}/:reportId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={ReportErrors}
                contentUiState="tab"
            />
        } />
        <Route path={`${ROUTES.SETTINGS}/:relationshipId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={SyncSettingsEdit}
                contentUiState="tab"
                showCmTabs={true}
            />
        } />
        <Route path={`${ROUTES.FAILED}/:relationshipId`} element={
            <ContentWrapper PrimaryHeader={TabHeader}
                ContentHeader={CmHeader}
                Content={FailedDetails}
                contentUiState="tab"
            />
        } />
        <Route path={ROUTES.DRAG_SOURCE_AND_TARGET} element={
            <ContentWrapper PrimaryHeader={SyncHeader} hideMainHeader={true}
                Content={DragSourceAndTargetStepNew}
                ContentHeader={CmHeader}
                contentUiState="wizard"
            />

        } />
        <Route path={`${ROUTES.SYNC_NEW}/:protocols/:providers/:isSecure/:firstStep`} element={
            <ContentWrapper PrimaryHeader={SyncHeader} hideMainHeader={true}
                Content={NewWizardContent}
                ContentHeader={CmHeader}
                contentUiState="wizard"
                contentKey="sync-wizard"
            />
        } />
    </Routes>)
};

const App = props => {
    const location = useLocation();
    const params = useParams();
    const { _routing, auth, updateUrlParams } = props;
    const cmIntegrationByPath = location && location.pathname === '/cm';

    const dispatch = useDispatch();
    // initialize
    // #1 get auth token
    //#2 initialize cm integration - if exists
    // #3 buildUrlParams
    useHandleCmMessages({
        onTokenUpdate: payload => handleAuthResult({ ...payload, fromCM: true }),
        onConnectorChange: payload => {
            return payload?.connectorId ? store.dispatch({ type: actionCreatorsConsts.SET_CM_INTEGRATION_DETAILS, payload: { general: { agentId: payload.connectorId } } }) : noop
        },
        onWorkspaceChange: payload => {
            return payload?.workspaceId ? store.dispatch({ type: actionCreatorsConsts.SET_CM_INTEGRATION_DETAILS, payload: { general: { workspaceId: payload.workspaceId } } }) : noop
        },
        onNssAdded: () => {
            return store.dispatch({ type: actionCreatorsConsts.GET_NSS_CREDENTIALS, payload: sdk.getNSSCredentials() })
        },
        onLocationChanged: ({ pathname }) => {
            store.dispatch({ type: actionCreatorsConsts.SET_CM_INTEGRATION_DETAILS, payload: { general: { routingParams: { pathname, fromCanvas: false } } } })
        }
    });

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);
        const { accessToken, userMetadata } = queryParams;

        //This is the normal flow - auth params are sent by CM in the query string
        if (accessToken && userMetadata) { //delay the authorization handle to allow navigation
            setTimeout(() => {
                const tokenWithBearer = `Bearer ${accessToken}`;
                setAuthHeader({ authorization: tokenWithBearer });
                dispatch(setUserInfoSuccess({
                    accessToken: tokenWithBearer,
                    userMetadata: userMetadata
                }));
                dispatch(setAccessTokenAndMetaData({
                    accessToken: tokenWithBearer,
                    userMetadata: userMetadata
                }));
                dispatch(getAccounts());
                dispatch(bootAnalytics());
            }, 300);
        }

        //This is used for:
        // 1. Getting the auth token during development or testing (redirect is allowed)
        // 2. Support old CM installations that don't send the auth params in the query string (redirect is NOT allowed)
        if (!accessToken) {
            const opts = {
                clientID: process.env.REACT_APP_AUTH_CLIENT,
                domain: process.env.REACT_APP_AUTH_DOMAIN,
                audience: process.env.REACT_APP_AUTH_AUDIENCE
            };
            window.auth = new Auth(opts);
            refreshSso(setUserInfoSuccess, setUserInfoFailed)

        }

    }, [dispatch]);

    useRunOnce(() => {
        const urlParams = buildUrlParams(_routing, { params }, location, window.location.href);
        updateUrlParams(urlParams);
    });

    if (!auth) return <MainLoader />;

    if (cmIntegrationByPath && auth.failed) {
        return <ReloadMessage />;
    }

    if (auth.failed && !auth.needLogin) {
        return <Oops error={auth.error} />
    }

    if (!auth.data) {
        return <MainLoader />;
    }

    return (
        <>
            <FullStoryComp />
            <AuthenticatedRoute {...props} />
            <NotificationGroup />
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.global.auth || { succeeded: false, inProgress: true, failed: false },
        _accounts: state.global._accounts,
        _accountInfo: state.global._accountInfo,
        _ui: { ...state.syncReducer._ui, ...state.global._ui },
        _routing: getPropFromState(state, "_routing", {}),
        _notifications: state.global._notifications,
        ...ownProps

    }
};

const mapDispatchToProps = { updateUrlParams, setActiveAccount, setCmIntegrationDetails, getNSSCredentials }

export default connect(mapStateToProps, mapDispatchToProps)(App);
