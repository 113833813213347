import React from 'react';
import {DefaultDialogLayout, useDialog} from '@netapp/shared-components';

const AlertDialog = ({children, ...props}) => {
    const {closeDialog} = useDialog();

    return (
        <DefaultDialogLayout
            buttonsArr={[
               {
                    text: 'Close',
                    variant: 'secondary',
                    onClick: closeDialog,
                    thin: true
               }]
            }
            {...props}>

            {children}

        </DefaultDialogLayout>
    );
};

export default AlertDialog;