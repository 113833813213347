import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "../store.types";

const SLICE_NAME = 'auth';

export const initialState: Auth = {
    isSuccess: false,
};

const authSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setAuth(state, action: PayloadAction<Auth>) {
            return action.payload;
        },
        setAccessTokenAndMetaData(state, action: PayloadAction<{ accessToken: string, userMetadata: string }>) {
            const { accessToken, userMetadata } = action.payload;
            state.accessToken = accessToken;
            state.userMetadata = userMetadata;
        },
        setUpdateAuthTime(state, action: PayloadAction<number>) {
            state.updateTime = action.payload;
        }
    },
    extraReducers: builder => {
        //listening to a query fulfilled action
        // builder.addMatcher(extendedApiSlice.endpoints.getPokemonByName.matchFulfilled, (state, action) => {
        //     state.yyy = JSON.stringify(action.payload);
        // })
    }
});

export const { setAuth, setAccessTokenAndMetaData, setUpdateAuthTime } = authSlice.actions;

export default authSlice;