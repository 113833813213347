import {ReactComponent as BucketIcon} from "../../../assets/svgs/bucket-icon.svg";
import {ReactComponent as XIcon} from "../../../assets/svgs/x.svg";

const BucketSecondStepHeader = ({handleClose, bucketPath}) => {
    return (
        <div className="bucket-info">
            <div className="title">
                Selected S3 bucket:
            </div>
            <div className="bucket-name-container">
                <div className="bucket-name">
                    <BucketIcon/>
                    <div>{bucketPath}</div>
                </div>
                <div className="close-step" onClick={handleClose}>
                    <XIcon/>
                </div>
            </div>
        </div>
    )
}

export default BucketSecondStepHeader;
