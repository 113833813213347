import React from 'react';
import {connect} from "react-redux";
import {createStringFromEndPointObject} from "./../../utils/helpers";
import {syncNow} from '../../store/wizard/action_creators';
import ConfirmDialog from '../dialog/ConfirmDialog';

const SyncNowConfirmDialog = ({syncNow, data}) => (
    <ConfirmDialog
        title="Sync Now"
        onConfirm={() => syncNow(data.id)}
        confirmText="Sync"
    >
        <p>Are you sure you want to sync the data from : {createStringFromEndPointObject(data.source)} to: {createStringFromEndPointObject(data.target)}?</p>
    </ConfirmDialog>
);

export default connect(null, {syncNow})(SyncNowConfirmDialog);