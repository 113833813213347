import React, { BaseSyntheticEvent, useState } from "react";
import './editRelationshipNameDialog.scss';
//@ts-ignore
import { useDialog, DefaultDialogLayout, TextInput } from '@netapp/shared-components';
import { useTranslation } from "react-i18next";

const EditRelationshipNameDialog = () => {
    const i18DefaultPath = "sync.dashboard.relationship.editDialog";
    const { t } = useTranslation();
    const { closeDialog } = useDialog();
    const [relationshipName, setRelationshipName] = useState();

    const buttonsArr = [
        {
            text: t(`sync.actions.update`),
            loading: false,
            thin: true,
            // onClick: deleteReports,
            disabled: !relationshipName
        },
        {
            text: t(`sync.actions.cancel`),
            variant: 'secondary',
            loading: false,
            thin: true,
            onClick: closeDialog
        }
    ]

    return (
        <DefaultDialogLayout
            buttonsArr={buttonsArr}
            title={t(`${i18DefaultPath}.title`)}
            className='editRelationshipName'>
            <div className="editRelationship">
                <TextInput label={t(`${i18DefaultPath}.inputTitle`)} onChange={(ev: BaseSyntheticEvent) => setRelationshipName(ev.target.value)}/>
            </div>
        </DefaultDialogLayout>
    )
}

export default EditRelationshipNameDialog;