import {
    handleErrors,
    handleUnhandledMessages,
    updateKeyWithBuiltState
} from "../../utils/reducerUtils";
import {merge, get, filter, cloneDeep, remove} from 'lodash';
import Immutable from "seamless-immutable";
import * as actionCreatorsConsts from "./action_creators_consts";
import * as consts from "../../consts";
import * as licenseUtils from "../../utils/license-utils";
import {bootGoogleAnalytics} from "../../utils/analytics";
import {UPDATE_DATA_BROKER_STATUS} from "../wizard/action_creators_consts";
import { NOTIFICATION_CONSTS } from "../../consts/notification.consts";
import {v4 as uuidv4} from 'uuid';
import {BROKER_STATUS_COMPLETE} from '../../consts';


export const globalDefaultState = () => Immutable({
    _ui: {},
    unhandledMessages: [],
    _licenses: {},
    _notifications: [],
    _cmIntegration: {},
    _nssCredentials: {}
});

export const globalReducer = (initialState = globalDefaultState(), action = {}) => {
    const state = handleErrors(action, initialState);
    const updatedState = handleAction(state, action);
    return handleUnhandledMessages(updatedState, handleAction);
};

const handleAction = (state, action) => {
    action = cloneDeep(action);

    switch (action.type) {
        case actionCreatorsConsts.AUTH_REFRESH_SSO_SUCCESS: {
            const {userMetadata, accessToken} = action.payload;
            const userData = JSON.parse(atob(userMetadata));
            const {name, email, company} = userData["http://cloud.netapp.com/user_metadata"];
            const uid = userData.sub;
            return state.setIn(["auth", "data", "email"], email)
                .setIn(["auth", "data", "userName"], name)
                .setIn(["auth", "data", "company"], company)
                .setIn(["auth", "data", "uid"], uid)
                .setIn(["auth", "inProgress"], false)
                .setIn(["auth", "failed"], false)
                .setIn(["auth", "succeeded"], true)
                .setIn(["auth", "accessToken"], accessToken);
        }
        case actionCreatorsConsts.AUTH_REFRESH_SSO_FAILED: {
            return state.setIn(["auth", "inProgress"], false)
                .setIn(["auth", "succeeded"], false)
                .setIn(["auth", "failed"], true)
                .setIn(["auth", "error"], action.payload.error_description)
                .setIn(["auth", "needLogin"], action.payload.needLogin);
        }
        case actionCreatorsConsts.GET_ACCOUNTS: {
            let updatedState = updateKeyWithBuiltState(state, action, ["_accounts"]);
            if (action.status === actionCreatorsConsts.STATUS_ERROR) {
                updatedState = updatedState.setIn(['auth', 'accountError'], action.payload?.data.message);
            }

            return updatedState;
        }
        case actionCreatorsConsts.GET_ACCOUNT_INFO: {
            console.log('Get accountInfo reducer', action);
            let updatedState = updateKeyWithBuiltState(state, action, ["_accountInfo"]);

            if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                const {payload} = action;
                updatedState = updatedState.setIn(['auth', 'data'], merge({}, get(updatedState, "auth.data"), payload));
                return licenseUtils.licenseEnforcement(updatedState);
            }
            if (action.status === actionCreatorsConsts.STATUS_ERROR) {
                return updatedState.setIn(['auth', 'accountError'], action.payload?.data?.message);
            }
            return updatedState;
        }
        case actionCreatorsConsts.CLEAR_ACCOUNT_DATA: {
            const {auth, _accounts, _accountInfo, _routing, _cmIntegration, _ui} = state;
            return Immutable({
                ...globalDefaultState(),
                auth,
                _accounts,
                _accountInfo,
                _routing,
                _cmIntegration,
                _ui
            });
        }
        case actionCreatorsConsts.BOOT_ANALYTICS: {
            const force = get(state._routing, "location.query.analytics", "").toLowerCase() === "true";
            if (force || process.env.NODE_ENV === "production") {
                //The Google Analytics "CS-OCCM Integarion" view is filtered by "Application Type" = "OCCM integration"
                bootGoogleAnalytics("OCCM integration" , get(state,'auth.data.email'));
            }
            return state;
        }
        default: {
            //do nothing
        }
    }

    if (!state.auth) return state;

    switch (action.type) {
        case actionCreatorsConsts.GET_LICENSE_SETTINGS: {
            const calculateLicenseType = ({freeTrial, totalReservedRelationships, chargedRelationships}) => {
                if (freeTrial) return consts.LICENSE_TYPE_FREE_TRIAL;
                else {
                    if (Number.isInteger(totalReservedRelationships)) {
                        if (totalReservedRelationships === 0) {
                            return consts.LICENSE_TYPE_PAYGO;
                        } else if (chargedRelationships > 0) {
                            return consts.LICENSE_TYPE_PAYGO_WITH_BYOL;
                        } else {
                            return consts.LICENSE_TYPE_BYOL
                        }
                    }
                }
                return consts.LICENSE_TYPE_UNKNOWN;
            };

            let builtState = updateKeyWithBuiltState(state, action, ["_licenseSettings"]);

            if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                builtState = licenseUtils.licenseEnforcement(builtState);

                return builtState.setIn(["_licenseSettings", "data", "licenseType"], calculateLicenseType(action.payload));
            }

            return builtState;
        }
        case actionCreatorsConsts.GET_BYOL_LICENSES: {
            return licenseUtils.licenseEnforcement(updateKeyWithBuiltState(state, action, ["_licenses"]));
        }
        case actionCreatorsConsts.ADD_BYOL_LICENSE: {
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                return state.updateIn(["_licenses", "data", "licenses"], licenses => {
                    const {serialNumber} = action.payload
                    let licensesMutable = licenses ? licenses.asMutable() : [];
                    //remove current license if already exists
                    remove(licensesMutable, {serialNumber})
                    licensesMutable.push(action.payload);
                    return Immutable(licensesMutable);
                });
            }
            return state;
        }
        case actionCreatorsConsts.VISITED_TRIAL_PAGE: {
            return state.setIn(["_ui", "visitedTrialPage"], true)
                .setIn(["_ui", "showTrialPage"], false);
        }
        case actionCreatorsConsts.UPDATE_SUBSCRIPTION_STATUS: {
            const builtState = state.setIn(['auth', 'data', 'subscriptionStatus'], action.payload[consts.PAYLOAD_UPDATE_SUBSCRIPTION_STATUS].subscriptionStatus);

            return licenseUtils.licenseEnforcement(builtState);
        }
        case actionCreatorsConsts.UPDATE_ACCOUNT_INFO: {
            const payload = action.payload[consts.PAYLOAD_UPDATE_ACCOUNT_INFO];
            if (state._accountInfo.data.accountId === payload.accountId) {
                const updatedState = state.setIn(["_accountInfo", "data"], merge({}, get(state, "_accountInfo.data"), payload))
                    .setIn(["auth", "data"], merge({}, get(state, "auth.data"), payload));
                return licenseUtils.licenseEnforcement(updatedState);
            }
            return state;
        }
        case actionCreatorsConsts.ADD_NOTIFICATION: {
            const {type, id} = action.payload;
            return state.updateIn([consts.GLOBAL_KEYS._NOTIFICATIONS], notifications => {
                let notificationsAsMutable = notifications ? notifications.asMutable() : [];
                //remove current notification if already exists
                remove(notificationsAsMutable, {type, id})
                notificationsAsMutable.push({...action.payload, id: action.payload.id || uuidv4()});
                return Immutable(notificationsAsMutable);
            });
        }
        case actionCreatorsConsts.CLEAR_NOTIFICATIONS: {
            const notifications = get(state, consts.GLOBAL_KEYS._NOTIFICATIONS)?.filter(notification => {
                return action.payload.includes(notification.type);
            });
            return state.set(consts.GLOBAL_KEYS._NOTIFICATIONS, [...notifications]);
        }
        case actionCreatorsConsts.REMOVE_NOTIFICATION: {
            const { id } = action.payload;
            const updatedNotifications = filter(state._notifications, notification => notification.id !== id);
            return state.set(consts.GLOBAL_KEYS._NOTIFICATIONS, updatedNotifications);
        }
        //This is a special case that a message in handles by 2 reducers
        case UPDATE_DATA_BROKER_STATUS: {
            const {status, isNewBroker} = action.payload[consts.PAYLOAD_DATA_BROKER_STATUS];
            if (status === BROKER_STATUS_COMPLETE && isNewBroker) {
                return state.set(consts.GLOBAL_KEYS._NOTIFICATIONS, [...state._notifications, {
                    id: uuidv4(),
                    type: NOTIFICATION_CONSTS.TYPE.INFO,
                    children: "Data Broker deployment is completed"
                }]);
            }
            return state;
        }
        case actionCreatorsConsts.SET_CM_INTEGRATION_DETAILS: {
            //action can be partical on connector and workspace update, need to merge
            return state.setIn(["_cmIntegration"], merge({}, get(state, "_cmIntegration"), action.payload));

        }
        case actionCreatorsConsts.CLEAR_CM_INTEGRATION_DETAILS: {
            if (action?.payload?.endPoint){
                const {endPoint} = action.payload;
                return state.setIn(["_cmIntegration", "inputDetails", endPoint], {}).setIn(["_cmIntegration", "dataSense"], null);

            } else {
                return state.setIn(["_cmIntegration", "inputDetails"], {source:{}, target:{}}).setIn(["_cmIntegration", "dataSense"], null);
            }

        }
        case actionCreatorsConsts.GET_NSS_CREDENTIALS: {
            return updateKeyWithBuiltState(state, action, ["_nssCredentials"]);
        }
        default: {
            return state
        }
    }
};
