import {useMemo} from 'react';
import useFetchWhenChange from '../../hooks/useFetchWhenChange';
import * as awsService from '../../services/aws.api.service';
import _ from "lodash";

const useAwsForm = (credentials, vpc, handleError) => {

    const vpcs = useFetchWhenChange(awsService.getVpcs, 'Vpcs', [credentials], null, null, handleError);
    const subnets = useFetchWhenChange(awsService.getSubnets, 'Subnets', [credentials],null, null, handleError);
    const keyPairs = useFetchWhenChange(awsService.getKeyPairs, 'KeyPairs', [credentials], null, null, handleError);
    const kmsKeys = useFetchWhenChange(awsService.getKmsKeys, 'Aliases', [credentials], null, null, handleError);

    const vpcsOptions = useMemo(() => {
       return vpcs?.map(vpc => {
           const nameTag = vpc.Tags && vpc.Tags.find(tag => tag.Key === 'Name');
           return {
               value: vpc.VpcId,
               label: `${vpc.VpcId} - ${vpc.CidrBlock}`,
               label2: `${nameTag ? nameTag.Value + " | " : ""}${subnets?.filter(subnet => subnet.VpcId === vpc.VpcId).length} Subnets`
           }
       })
    }, [vpcs, subnets]);

    const subnetsOptions = useMemo(() => {
        return vpc && _
                .chain(subnets)
                .filter(subnet => subnet.VpcId === vpc)
                .map(subnet => {
                    const nameTag = subnet.Tags && subnet.Tags.find(tag => tag.Key === 'Name');
                    return {
                        value: subnet.SubnetId,
                        label: subnet.CidrBlock,
                        label2: nameTag && nameTag.Value
                    }
                })
                .value()
    }, [vpc, subnets]);

    const keyPairsOptions = useMemo(() => {
        return keyPairs?.map(keyPair => ({value: keyPair.KeyName, label:keyPair.KeyName}));
    }, [keyPairs]);


    const kmsKeysOptions = useMemo(() => {
        return kmsKeys?.filter(kmsKey => !kmsKey.AliasName.includes('/aws/')).map(kmsKey => ({
            value: kmsKey.AliasName,
            label: kmsKey.AliasName,
            label2: kmsKey.TargetKeyId // because not all kms keys has alias
        }));
    }, [kmsKeys]);

    return {vpcsOptions, subnetsOptions, keyPairsOptions, kmsKeysOptions}
};

export default useAwsForm;
