import {useRef, useEffect} from 'react';

const useRunOnceWhenTruthy = (callback, condition) => {
    const isRun = useRef(false)
    useEffect(() => {
        if (condition && !isRun.current) {
            callback();
            isRun.current = true;
        }
    }, [isRun, callback, condition]);
};

export default useRunOnceWhenTruthy;
