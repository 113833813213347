import {getWizardSelectedParams} from "./sync-utils"
import {SERVER_TYPES} from '../consts';

export function getPropFromState(state, key, defaultValue) {
    return state.syncReducer[key] ? state.syncReducer[key] : defaultValue;
}

export const buildServersList = (state) => {
    return Object.values(SERVER_TYPES).reduce((res, k) => {
            res[k] = (state.syncReducer._servers && state.syncReducer._servers[k]) ? state.syncReducer._servers[k] : {inProgress: true};
            return res;
        }, {});
};

export const getWizardParamsFromWizardState = (fullState, ownProps, wizardState) => {
    const {syncReducer: state} = fullState;
    if (!state._wizard) return state._wizard;

    const selectedWizardParams = getWizardSelectedParams(state, wizardState);
    const {selectedDataBroker, selectedDataBrokerTarget, ...rest} = selectedWizardParams;
    if (process.env.REACT_APP_INFO_LOGS === "true"){
        console.log(`%cgetWizardParamsFromWizardState\nwizardState:\n${JSON.stringify(wizardState, null,2)}\nselectedWizardParams\n${JSON.stringify(rest, null, 2)}`, 'font-weight:bold; color:purple')
    }
    return state.setIn(["_wizard", "selectedWizardParams"], {...selectedWizardParams});
}
