import React from "react";
import {ReactComponent as DataBrokerIcon} from "../../../assets/svgs/group-icon.svg";

import "./reviewGroupCardNew.scss";
import DoubleLineItem from "../../_common_/DoubleLineItem";

const ReviewGroupCard = (props) => {
    const {selectedGroup} = props;
    return <div className="review-data-broker-card">
        {selectedGroup ? <div className="data-broker-icon"><DataBrokerIcon/></div> : ""}
        <div className="broker-details">
            <div className="name">Data Broker Group</div>
            <div className="data-brokers-info">
                <DoubleLineItem title="Name" value={selectedGroup.name}/>
                <DoubleLineItem title="Data Brokers" value={selectedGroup.dataBrokers.length}/>
                <DoubleLineItem title="Relationships" value={selectedGroup.relationships.length}/>
            </div>

        </div>
    </div>
};

export default ReviewGroupCard;
