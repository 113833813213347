import * as actionCreatorsConsts from "./action_creators_consts";
import sdk from "../../sdk";
import {create, createMultiple} from "../../utils/actions-utils";
import {GET_LICENSE_SETTINGS} from "./action_creators_consts";
import { NOTIFICATION_CONSTS } from "../../consts/notification.consts";

export const setUserInfoSuccess = (payload) => create(actionCreatorsConsts.AUTH_REFRESH_SSO_SUCCESS, payload);

export const setUserInfoFailed = (payload) => create(actionCreatorsConsts.AUTH_REFRESH_SSO_FAILED, payload);

export const getAccountInfo = (accountId)=> createMultiple([{type:actionCreatorsConsts.GET_ACCOUNT_INFO, payload:sdk.getAccountInfo(accountId)},{type: GET_LICENSE_SETTINGS, payload: sdk.getLicenseSettings()}]);

export const clearCurrentAccountData = ()=> create(actionCreatorsConsts.CLEAR_ACCOUNT_DATA);

export const getLicenseSettings = () => create(actionCreatorsConsts.GET_LICENSE_SETTINGS, sdk.getLicenseSettings());
export const getBYOLLicenses = () => create(actionCreatorsConsts.GET_BYOL_LICENSES, sdk.getBYOLLicenses());
export const getNSSCredentials = () => create(actionCreatorsConsts.GET_NSS_CREDENTIALS, sdk.getNSSCredentials());
export const addBYOLLicense = (values) => create(actionCreatorsConsts.ADD_BYOL_LICENSE, sdk.addBYOLLicense(values), undefined, true);
export const visitedTrialPage = () => create(actionCreatorsConsts.VISITED_TRIAL_PAGE);

export const updateAccountInfo = (payload) => create(actionCreatorsConsts.UPDATE_ACCOUNT_INFO, payload);

export const updateSubscriptionStatus = (payload) => create(actionCreatorsConsts.UPDATE_SUBSCRIPTION_STATUS, payload);//handles errors by itself

export const getAccounts = (mpCustomerToken, mpProvider) => {
    return dispatch =>  dispatch({
        type: actionCreatorsConsts.GET_ACCOUNTS,
        payload: (async () => {
            const accounts = await sdk.getAccounts();
            //For marketplace registration, arbitrarily use the first account.
            //It doesn't really matter, because the server fans out the registration info to all the user's accounts.
            await sdk.registerToMarketplace(accounts[0].accountId, mpCustomerToken, mpProvider);
            return accounts;
        })()
    })
};

export const bootAnalytics = () => create(actionCreatorsConsts.BOOT_ANALYTICS);

export const addNotification = (payload) => create(actionCreatorsConsts.ADD_NOTIFICATION, payload);
export const removeNotification = (payload) => create(actionCreatorsConsts.REMOVE_NOTIFICATION, payload);
export const clearNotifications = (payload = [NOTIFICATION_CONSTS.TYPE.INFO, NOTIFICATION_CONSTS.TYPE.SUCCESS]) => create(actionCreatorsConsts.CLEAR_NOTIFICATIONS, payload)

export const setCmIntegrationDetails = (payload) => create(actionCreatorsConsts.SET_CM_INTEGRATION_DETAILS, payload);
export const clearCmIntegrationDetails = (payload) => create(actionCreatorsConsts.CLEAR_CM_INTEGRATION_DETAILS, payload);
