import React from 'react';
import {
    clearShowAction,
    clearUrlParamsIfChanged,
    getServersByType,
    getAnfVolumes,
    getCMVolumes,
    getFSXVolumes,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from '../../../store/wizard/action_creators';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { InputField } from "../../_common_/forms/forms";
import { CASH_KEYS, CIFS_VERSIONS, DEFAULT_CIFS_VERSION, END_POINT_NAMES_MAP, VOLUME_HAS_NO_EXPORT_CIFS } from '../../../consts';
import ServerStepTemplate from "./ServerStepTemplateNew";
import { find } from 'lodash';
import { calculateStepId } from '../../../utils/sync-utils';
import { extractIpAddressAndPathFromUrl } from "../../../utils/encoders";
import { fetchAnfVolumes, getIsIntegratedStep } from '../../../utils/wizard-utils';
import {
    buildServersList,
    getPropFromState,
    getWizardParamsFromWizardState
} from '../../../utils/mapStateToPropsUtils';
import VolumeStepTemplate from './VolumeStepTemplateNew';
import { getCvoWorkingEnvironments } from "../../../utils/data-utils";
import { supportAclCopy } from "../../../utils/relationshipUtils";
import { validate } from './additionalSettings/validators/cifsValidatorNew'
import useStepinize from "../useStepinize";
import AnfVolSelection from "./AnfVolSelectionNew";
import CmVolSelection from "./CmVolSelectionNew";
import AclSelection from "./AclSelectionNew";

const indefinite = require('indefinite');

const SecondScreenUi = ({ _wizard: { protocols, wizardInfo: { source, target }, selectedWizardParams }, isTargetStepFromSameSourceType, isSourceStep }) => {
    const showACL = !isTargetStepFromSameSourceType && isSourceStep && supportAclCopy(protocols, source, target);
    
    return (
        <div>
            <div className="credentials-container credentials">
                <div className="credentials-title">Define SMB Credentials:</div>
                <div className="credentials-fields">
                    <Field name="username" component={InputField} label="User Name" />
                    <Field name="password" component={InputField} label="Password" type="password" autoComplete="off" />
                    <Field name="domain" component={InputField} label="Domain (Optional)" />
                </div>
            </div>
            {showACL && <AclSelection aclMultiSelection={showACL === 'multi'}/>}
        </div>);
};

const getWeByProvider = (selectedWizardParams, provider, isTargetStepFromSameSourceType) => {
    const { selectedAnfCifsWe, selectedAnfCifsWeTarget, selectedCvoCifsWe, selectedCvoCifsWeTarget,
        selectedFsxCifsWeTarget, selectedFsxCifsWe,
        selectedOnpremCifsWe, selectedOnpremCifsWeTarget } = selectedWizardParams || {};
    if (provider === "anf") {
        return isTargetStepFromSameSourceType ? selectedAnfCifsWeTarget : selectedAnfCifsWe;
    }
    if (provider === "cvo") {
        return isTargetStepFromSameSourceType ? selectedCvoCifsWeTarget : selectedCvoCifsWe
    }
    if (provider === "onprem") {
        return isTargetStepFromSameSourceType ? selectedOnpremCifsWeTarget : selectedOnpremCifsWe
    }
    if (provider === "fsx") {
        return isTargetStepFromSameSourceType ? selectedFsxCifsWeTarget : selectedFsxCifsWe
    }

}

const getVolumesByProvider = (we, provider, _anfVoluems, _cmVolumes, _fsxVolumes) => {
    if (provider === "anf") {
        return _anfVoluems[we]
    }
    if (provider === "cvo" || provider === "onprem") {
        return _cmVolumes[we];
    }
    if (provider === "fsx") {
        return _fsxVolumes[we]
    }
    return []
}

const getFilterFunctionByProvider = (provider) => {
    if (provider === "anf") return (vol) => (vol.protocol === "smb" || vol.protocol === "dual");
    if (provider === "cvo" || provider === "onprem" || provider === "fsx") return (vol) => !!vol.cifsShareAccessPoint;
    return () => true;

}

const cmVolumeMapFunction = (vol, filterFunction) => {
    if (filterFunction(vol)) {
        return { ...vol, isDisabled: true, title: VOLUME_HAS_NO_EXPORT_CIFS }
    }
    const cifsShareAccessPoint = vol.mountPoint; //we kept it in cmVolSelection in the mountPoint
    const params = extractIpAddressAndPathFromUrl(cifsShareAccessPoint);
    //will happen in case of an offline volume
    if (!params.actualPath) {
        return { ...vol, isDisabled: true, title: VOLUME_HAS_NO_EXPORT_CIFS }
    }
    return vol;
}


const getVolumeSelectionComp = (provider, volumes, selectedVolume, refreshStep) => {
    if (provider === "anf") {
        return <AnfVolSelection volumes={volumes}
            protocol="cifs"
            defaultVolumeId={selectedVolume}
            volumesFilterFunction={getFilterFunctionByProvider(provider)}
            refreshStep={refreshStep}
        />
    }
    else {
        return <CmVolSelection volumes={volumes}
            protocol="cifs"
            defaultVolumeId={selectedVolume}
            volumeMapFunction={(vol) => cmVolumeMapFunction(vol, (vol) => !vol.mountPoint)}
            refreshStep={refreshStep}
        />
    }
}

const loading = ({ endpointObj }) => {
    const serverType = endpointObj.provider;
    return {
        text: `${END_POINT_NAMES_MAP[serverType.toUpperCase()]}`,
        icon: require(`./../../../assets/svgs/${serverType}-default.svg`)
    }
}

const stepId = ({ _wizard: { wizardInfo }, isTargetStepFromSameSourceType }) => {
    return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "cifs");
};

const fetchData = (props) => {
    const { endpointObj, protocolAndProviderKey } = props;
    if (getIsIntegratedStep(endpointObj)) {
        if (endpointObj.provider === "anf") {
            fetchAnfVolumes(props, "cifs");
        }
        else if (endpointObj.provider === "cvo") {
            const { isTargetStepFromSameSourceType, _wizard: { selectedWizardParams }, _cmWorkingEnvironments } = props;
            const { selectedCvoCifsWe, selectedCvoCifsWeTarget } = selectedWizardParams;
            const selectedWe = isTargetStepFromSameSourceType ? selectedCvoCifsWeTarget : selectedCvoCifsWe;
            const allWe = getCvoWorkingEnvironments(_cmWorkingEnvironments);
            const actualWE = find(allWe, { publicId: selectedWe })
            return props.getCMVolumes(actualWE.cloudProviderName, actualWE.isHA, selectedWe);
        }
        else if (endpointObj.provider === "onprem") {
            const { isTargetStepFromSameSourceType, _wizard: { selectedWizardParams } } = props;
            const { selectedOnpremCifsWe, selectedOnpremCifsWeTarget } = selectedWizardParams;
            const selectedWe = isTargetStepFromSameSourceType ? selectedOnpremCifsWeTarget : selectedOnpremCifsWe;
            return props.getCMVolumes(endpointObj.provider, false, selectedWe);
        }
        else if (endpointObj.provider === "fsx") {
            const { isTargetStepFromSameSourceType, _wizard: { selectedWizardParams } } = props;
            const { selectedFsxCifsWe, selectedFsxCifsWeTarget } = selectedWizardParams;
            const selectedWe = isTargetStepFromSameSourceType ? selectedFsxCifsWeTarget : selectedFsxCifsWe;
            return props.getFSXVolumes(selectedWe);
        }
    }
    else {
        props.getServersByType(endpointObj.protocol, endpointObj.provider, protocolAndProviderKey);
    }
};

const shouldFetch = (props) => {
    const { endpointObj, isTargetStepFromSameSourceType, protocolAndProviderKey, _wizard: { selectedWizardParams } } = props;
    if (getIsIntegratedStep(endpointObj)) {
        const relevantWe = getWeByProvider(selectedWizardParams, endpointObj.provider, isTargetStepFromSameSourceType);
        if (endpointObj.provider === "anf") {
            const { _anfVolumes } = props;
            return !_anfVolumes[relevantWe] || !_anfVolumes[relevantWe].succeeded;
        }
        if (endpointObj.provider === "cvo" || endpointObj.provider === "onprem") {
            const { _cmVolumes } = props;
            return !_cmVolumes[relevantWe] || !_cmVolumes[relevantWe].succeeded;
        }
        if (endpointObj.provider === "fsx") {
            const { _fsxVolumes } = props;
            return !_fsxVolumes[relevantWe] || !_fsxVolumes[relevantWe].succeeded;
        }
    }
    return (!props._servers || !props._servers[protocolAndProviderKey] || !props._servers[protocolAndProviderKey].succeeded);
};

const isFetching = (props) => {
    const { endpointObj, isTargetStepFromSameSourceType, _wizard: { selectedWizardParams } } = props;

    if (getIsIntegratedStep(endpointObj)) {
        const relevantWe = getWeByProvider(selectedWizardParams, endpointObj.provider, isTargetStepFromSameSourceType);
        if (endpointObj.provider === "anf") {
            const { _anfVolumes } = props;
            return _anfVolumes[relevantWe] && _anfVolumes[relevantWe].inProgress;
        }
        if (endpointObj.provider === "cvo" || endpointObj.provider === "onprem") {
            const { _cmVolumes } = props;
            return _cmVolumes[relevantWe] && _cmVolumes[relevantWe].inProgress;
        }
        if (endpointObj.provider === "fsx") {
            const { _fsxVolumes } = props;
            return _fsxVolumes[relevantWe] && _fsxVolumes[relevantWe].inProgress;
        }
    }
    else {
        const { _servers, protocolAndProviderKey } = props;
        return _servers[protocolAndProviderKey].inProgress;
    }
};



const CIFSServerStep = (props) => {
    const { LoadingComp, moreStepInfo } = useStepinize({ props, fetchData, shouldFetch, loading, stepId })

    const { _servers, _anfVolumes, _cmVolumes, _fsxVolumes, _wizard, clearSelectedParams, state } = props;
    const { isTargetStepFromSameSourceType, isSourceStep, endpointObj, protocolAndProviderKey } = moreStepInfo;
    const { selectedWizardParams } = _wizard;
    const { selectedCIFS, selectedCIFSTarget, cifsVersion, targetCifsVersion, selectedCifsVolTarget, selectedCifsVol, selectedDataBroker } = selectedWizardParams || {};

    const provider = endpointObj.provider;
    const isVolumeStep = getIsIntegratedStep(endpointObj);
    const relevantWe = getWeByProvider(selectedWizardParams, provider, isTargetStepFromSameSourceType);
    const selectedVolume = isTargetStepFromSameSourceType ? selectedCifsVolTarget : selectedCifsVol;

    const serverType = provider !== endpointObj.protocol ? provider : 'smb';
    const selectedServer = isTargetStepFromSameSourceType ? selectedCIFSTarget : selectedCIFS;
    const volumes = getVolumesByProvider(relevantWe, provider, _anfVolumes, _cmVolumes, _fsxVolumes)

    const volumesOptions = {
        selectedVolumeId: selectedVolume,
        volumeSelectionElement: getVolumeSelectionComp(provider, volumes, selectedVolume, () => {
            fetchData({ ...props, ...moreStepInfo, refreshVolumes: true })
        }),
        volumeSelectionReady: !!volumes
    };

    const serverTitle = isVolumeStep ? END_POINT_NAMES_MAP[provider.toUpperCase()] : serverType.toUpperCase();
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["sharePathTarget", "rootShareTarget"] : ["sharePath", "rootShare"];

    const getShowSharesKey = () => {
        return selectedServer && `${selectedServer}${selectedDataBroker ? `-${selectedDataBroker.id}` : ''}`
    };

    const stepOptions = {
        storage: _servers[protocolAndProviderKey],
        server: selectedServer,
        serverSVG: `${provider}-default.svg`,
        volumesOptions,
        serverType: "CIFS",
        serverTitle,
        selectType: "Server",
        serverPlaceholder: "For example: 217.70.21.18 or netapp.com",
        //CS-6249 UI: a typo (Ben's answer attached) - temporary fix
        title: `Select ${serverTitle === 'FSx for ONTAP' ? `an ${serverTitle}` : indefinite(serverTitle)} ${isSourceStep ? "Source" : "Target"}`,
        selectedVersion: (isTargetStepFromSameSourceType ? targetCifsVersion : cifsVersion) || DEFAULT_CIFS_VERSION,
        versionsList: isVolumeStep ? null : CIFS_VERSIONS,
        clearShowOptions: { keyToClear: [CASH_KEYS._SHOW_SHARES, getShowSharesKey()] },
        validate,
        SecondScreenUi,
        shouldGoToSecondScreen: () => true,
        stateParamsToClear,
        getSelectedParams: (host, version) => {
            const hostParam = isTargetStepFromSameSourceType ? 'cifsServerTarget' : 'cifsServer';
            const versionParam = isTargetStepFromSameSourceType ? 'targetCifsVersion' : 'cifsVersion';
            if ((state[hostParam] && state[hostParam] !== host) || (state[versionParam] && state[versionParam] !== version)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam]: host,
                [versionParam]: version
            }
        },
        getCmVolumeParams: (volumeId, volumeRoot) => {
            const volParam = isTargetStepFromSameSourceType ? 'cifsVolTarget' : 'cifsVol';
            const exportParam = isTargetStepFromSameSourceType ? 'rootShareTarget' : 'rootShare';
            const pathParam = isTargetStepFromSameSourceType ? 'sharePathTarget' : 'sharePath';

            if (volumeId && state[volParam] !== volumeId) {
                clearSelectedParams([volParam, exportParam, pathParam])
            }

            const volParams = volumeId ? {
                [volParam]: volumeId,
                [exportParam]: volumeRoot
            } : {};
            return volParams;
        }

    };

    return isFetching({ ...props, ...moreStepInfo }) ? LoadingComp : isVolumeStep ?
        <VolumeStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo} /> :
        <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo} />;
};

let CIFSServerStepForm = reduxForm({
    form: 'cifsServer',
    validate,
    shouldError: () => true
})(CIFSServerStep);

const selector = formValueSelector('cifsServer');
CIFSServerStepForm = connect(
    (state) => ({
        copyAcl: selector(state, "copyAcl")
    })
)(CIFSServerStepForm);

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _servers: buildServersList(state),
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _anfVolumes: getPropFromState(state, CASH_KEYS._ANF_VOLUMES, {}),
        _fsxVolumes: getPropFromState(state, CASH_KEYS._FSX_VOLUMES, {}),
        _cmVolumes: getPropFromState(state, "_cmVolumes", {}),
        _cmWorkingEnvironments: getPropFromState(state, "_cmWorkingEnvironments", {}),
        _accountInfo: state.global._accountInfo,
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: { ...state.syncReducer._ui, ...state.global._ui },
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getServersByType,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
    getAnfVolumes,
    getCMVolumes,
    getFSXVolumes
};

export default connect(mapStateToProps, mapDispatchToProps)(CIFSServerStepForm);


