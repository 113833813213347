import {useMemo, useEffect} from 'react';
import useFetchOnce from '../../hooks/useFetchOnce';
import {
    checkProjectPermissions,
    gcpDeployPermissions,
    getProjectsList,
    getRegions,
    getServiceAccounts,
    getSubnets,
    getHostedProject,
    getUsableSubnets
} from '../../services/gcp.api.service';
import useFetchWhenChange from '../../hooks/useFetchWhenChange';
import _ from 'lodash';

const getLastDirectory = (path) => path.split('/').pop();

const lastDirectoryMappingFunc = (path) => {
    const lastDirectory = getLastDirectory(path);
    return {label: lastDirectory, value: lastDirectory}
};

const projectsMappings = ({name, projectId}) => ({label: name, value: projectId});
const regionsMappings = ({name, zones}) => ({
    label: name,
    value: name,
    zones
});

const serviceAccountMapping = ({displayName, email}) => ({
    label: displayName,
    value: email
});

const buildVpcListFromSubnets = subnets => {
    const vpcs = subnets.map(subnet => ({
        label: subnet.networkName,
        value: subnet.network
    }));
    return _.uniqBy(vpcs, 'value');
};

const sharedSubnetMapping = (item) => {
    const region = _.last(item.subnetwork.split('regions/')).split('/')[0];
    const subnetValue = `projects/${item.subnetwork.split('projects/')[1]}`;
    const networkValue = `projects/${item.network.split('projects/')[1]}`;

    return {
        value: subnetValue,
        label: _.last(item.subnetwork.split('/')),
        networkName: _.last(item.network.split('/')),
        network: networkValue,
        cidr: item.ipCidrRange,
        region: region
    }
};

const subnetsMapping = (item) => {
    const subnetValue = `projects/${item.selfLink.split('projects/')[1]}`;
    const networkValue = `projects/${item.network.split('projects/')[1]}`;

    return {
        value: subnetValue,
        label: item.name,
        networkName: _.last(item.network.split('/')),
        network: networkValue
    }
};

const sortByLabel = item => item.label;

const useGcpForm = (selectedProjectId, selectedVpc, selectedRegion, handleError) => {
    const projects = useFetchOnce(getProjectsList, 'projects', projectsMappings, sortByLabel, handleError);
    const serviceAccounts = useFetchWhenChange(getServiceAccounts, 'accounts', [selectedProjectId], serviceAccountMapping, sortByLabel, handleError);
    const regions = useFetchWhenChange(getRegions, 'items', [selectedProjectId], regionsMappings, sortByLabel, handleError);

    const zones = useMemo(() => selectedRegion?.zones.map(lastDirectoryMappingFunc), [selectedRegion]);

   const {subnets, vpcs} = useGetNetworks(selectedProjectId, selectedRegion, selectedVpc, handleError);

    useCheckProjectPermissions(selectedProjectId, handleError);
    return {projects, serviceAccounts, regions, vpcs, zones, subnets}
};

export default useGcpForm;

const useGetNetworks = (selectedProjectId, selectedRegion, selectedVpc, handleError) => {

    const hostedProject = useFetchWhenChange(getHostedProject, 'name', [selectedProjectId], null, null, handleError);
    const usableSubnets = useFetchWhenChange(getUsableSubnets, 'items', [hostedProject], sharedSubnetMapping, null, handleError);
    const regionalSubnets = useFetchWhenChange(getSubnets, 'items', [selectedProjectId, selectedRegion?.value], subnetsMapping, null, handleError);

    const sharedSubnets = useMemo(() => (usableSubnets?.filter(subnet => subnet.region === selectedRegion?.value)) || [],
        [usableSubnets, selectedRegion]);

    const allSubnets = useMemo(() => regionalSubnets ? [...regionalSubnets, ...sharedSubnets] : [...sharedSubnets], [regionalSubnets, sharedSubnets]);
    const vpcs = _.sortBy(buildVpcListFromSubnets(allSubnets), sortByLabel);

    const subnets = useMemo(() => {
        const subnets = allSubnets.filter(subnet => subnet.networkName === selectedVpc?.label);
        return _.sortBy(subnets, sortByLabel);
    }, [allSubnets, selectedVpc]);

    return {subnets, vpcs};
};

const useCheckProjectPermissions = (projectId, handleError) => {
    useEffect(() => {
        if (projectId) {
            checkProjectPermissions(projectId).then(res => {
                const missingPermissions = gcpDeployPermissions.filter(permission => (res.permissions || []).indexOf(permission) === -1);
                if (missingPermissions.length > 0) {
                    handleError(`The caller is missing the following permissions on project '${projectId}': ${missingPermissions.join(', ')}`);
                }
            })
        }
    }, [projectId, handleError])
};
