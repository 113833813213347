import React, {useState} from "react";
import BucketSecondStepHeader from "../BucketSecondStepHeaderNew";
import EncryptionSettingsUI from "../encryptionSettings/EncryptionSettingsUINew";
import {STORAGE_CLASS_OPTIONS} from "../../../../consts";
import {chain} from 'lodash'
import {supportAclCopy} from "../../../../utils/relationshipUtils";
import {SelectField} from "../../../_common_/forms/forms";
import AclSelectionFinalForm from "../AclSelectionFinalFormNew";
import {Field} from 'react-final-form';
import {TooltipInfo} from '@netapp/shared-components';
import useRunOnceWhenTruthy from "../../../../hooks/useRunOnceWhenTruthy";
import {updateFormValues} from "../../../../utils/formUtils";
import {getCredentialsByType} from "../../../../utils/sync-utils";

const getKmsKeysOptions = (bucketInfo) => {
    return chain(bucketInfo.kmsKeys)
        .map((key) => ({
            label: key.name,
            value: key.arn,
            disabled: !key.isEnabled,
            disabledTitle: "Key is disabled",
        }))
        .value();
}

function getS3privateLinksOptions(bucketInfo) {
    return chain(bucketInfo.s3PrivateLinks).map((link) => ({
        label: `Vpc Id | ${link.vpcId}`, label2: `Vpc Endpoint | ${link.vpcEndpointId}`, value: link.dnsEntry
    })).value();
}

const BucketsAdditionalSettingsUI = ({isSourceStep, isTargetStepFromSameSourceType, source, target, bucketInfo, bucketPath, bucketFullPath, protocols,
                                      selectedEncryptionType, selectedStorageClass, returnToBucketsStep,
                                      form, formValues}) => {
    const [s3PrivateLinksOptions, setS3PrivateLinksOptions] = useState([]);
    useRunOnceWhenTruthy(() => {
        setS3PrivateLinksOptions(getS3privateLinksOptions(bucketInfo));
        const s3PrivateLinks = getCredentialsByType('s3', bucketFullPath, isTargetStepFromSameSourceType)?.s3PrivateLinks; //can be undefined so not using deconstruction
        if (s3PrivateLinks) {
            updateFormValues({s3PrivateLinks}, form)
        }
    }, bucketInfo.s3PrivateLinks)
    const tooltipText = bucketInfo.s3PrivateLinksErrorReason ? <span><b>Attention: </b> {bucketInfo.s3PrivateLinksErrorReason}</span> : null;
    return (<div className="bucket-setup">
        <BucketSecondStepHeader bucketPath={bucketPath} handleClose={() => returnToBucketsStep(form)}/>
        <div className="setup-container">
            <div className="s3-private-links-container">
                {bucketInfo.s3PrivateLinksErrorReason && <TooltipInfo popoverClass="s3-private-links-warning" className="s3-private-links-tooltip">{tooltipText}</TooltipInfo>}
                <Field component={SelectField}
                       variant='two-lines'
                       isLoading={!bucketInfo.s3PrivateLinks}
                       name="s3PrivateLinks"
                       options={s3PrivateLinksOptions}
                       label="S3 Private Links:"/>
            </div>

        </div>
        {!isSourceStep &&<div className="setup-container">

            <EncryptionSettingsUI
                bucketPath={bucketPath}
                bucketFullPath={bucketFullPath}
                bucketInfo={bucketInfo}
                selectedEncryptionType={selectedEncryptionType}
                selectedStorageClass={selectedStorageClass}
                storageClassOptions={STORAGE_CLASS_OPTIONS}
                kmsKeysOptions={getKmsKeysOptions(bucketInfo)}
                formValues={formValues}
                form={form}
                isTargetStepFromSameSourceType={isTargetStepFromSameSourceType}
            />

        </div>}
        {supportAclCopy(protocols, source, target) && isSourceStep && <div className="setup-container">
            <AclSelectionFinalForm/>
        </div>}
    </div>)

}

export default BucketsAdditionalSettingsUI
