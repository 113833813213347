import React from "react";
import {reduxForm, Field, formValueSelector} from 'redux-form';
import {connect} from 'react-redux';
import ButtonsPanel from '../widgets/ButtonsPanel';
import {InputField, CheckboxField} from '../_common_/forms/forms';
import Validator from "validatorjs";
import classNames from "classnames";
import {FM_AGENT_TYPE_GCP, INSTANCE_DETAILS_FORM} from '../../consts';
import Notice from '../notifications/Notice';
import {ReactComponent as TriangleIcon} from "../../assets/svgs/triangle.svg";

import "./instanceProxy.scss";

const InstanceProxy = props => {
    const {onContinue, handleSubmit, submitting, enableCredentials, type} = props;

    const notice = type === FM_AGENT_TYPE_GCP.name
        ? "If a proxy is required for internet access, then the proxy must be in Google Cloud and use the same service account as the data broker."
        : null;

    return (<>
        <form className="data-broker-wizard-step proxy-step" onSubmit={handleSubmit((values) => onContinue(extractValues(values)))}>
            <div className="title">Proxy Configuration <span>(optional)</span></div>
            <div className="content-area">
                {notice && <Notice>{notice}</Notice>}
                <div className="proxy-section">
                    <Field name="proxyHost" className="proxy-host" component={InputField} label="Host" disabled={submitting} placeholder={"Example: 172.16.254.1"}
                        info={"The IP or FQDN address of the proxy server (must be accessible from the data broker subnet)"}/>
                    <Field name="proxyPort" component={InputField} label="Port" disabled={submitting} placeholder={"Example: 8080"}
                        info={"The TCP port on which the proxy server listens"}/>
                </div>
                <div className="proxy-section credentials-section">
                    <Field name="enableCredentials" id="sectionSwitch" type="checkbox" component={CheckboxField} className="section-switch">
                        <label htmlFor="sectionSwitch">Define credentials for this proxy <TriangleIcon className={`${enableCredentials ? "up" : ""}`}/></label>
                    </Field>
                    <div className={classNames("credentials", {closed: !enableCredentials})}>
                        <Field name="proxyUsername" component={InputField} label="User Name" disabled={submitting}
                            info={"The proxy user name if proxy authentication is needed"}/>
                        <Field name="proxyPassword" component={InputField} label="Password" type="password" autoComplete="off" disabled={submitting}
                            info={"The proxy password if proxy authentication is needed"}/>
                    </div>
                </div>
            </div>

            <ButtonsPanel
                variant="transparent"
                type="submit"
                text="Continue"
                submitting={submitting}/>

        </form>
    </>)
};

const validate = (values) => {
    const hostPortRequired = values.enableCredentials && (values.proxyUsername || values.proxyPassword);
    const portRules = ['required', 'numeric', 'between:1,65535'];
    if (!hostPortRequired) {
        portRules.shift();
    }
    const rules = {
        proxyUsername: values.enableCredentials && values.proxyPassword ? ['required', 'max:256'] : [],
        proxyPassword: values.enableCredentials && values.proxyUsername ? ['required'] : [],
        proxyHost: hostPortRequired ? ['required'] : [],
        proxyPort: portRules
    };

    const validator = new Validator(values, rules, {
        "max.proxyHost": "Hostname or IP address must be less than 256 characters",
        "between.proxyPort": "Port must be between 1 to 65535"});

    validator.setAttributeNames({
        proxyUsername: 'User Name',
        proxyPassword : 'Password',
        proxyHost: 'Host',
        proxyPort: 'Port'
    });
    validator.passes();
    return validator.errors.all();
};

const connectedToForm = reduxForm({
    form: INSTANCE_DETAILS_FORM,
    validate,
    destroyOnUnmount: false
})(InstanceProxy);

const selector = formValueSelector(INSTANCE_DETAILS_FORM);

export default connect(state => ({
    enableCredentials: selector(state, 'enableCredentials')
}))(connectedToForm)

const extractValues = (values) => ({
    proxyHost: values.proxyHost || "",
    proxyPort: values.proxyPort || "",
    proxyUsername: (values.enableCredentials && values.proxyUsername) || "",
    proxyPassword: (values.enableCredentials && values.proxyPassword) || ""
});

