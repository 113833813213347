import React from "react";
import _ from "lodash";
import {randomString} from '../utils/helpers';
import * as azureService from '../services/azure.api.service';

export const getDeployName = (dataBrokerName) => {
    //In GCP the name must be under 60 chars and without capital letters
    const dataBrokerShortName = _.truncate(dataBrokerName, {length: 40, omission: "-"});
    const name = `${dataBrokerShortName}-${randomString(3)}`;
    return name.toLowerCase();
};

const extractErrorAzure = (error, fallbackMsg) => {
    const msg = extractErrorMessageAzure(error, fallbackMsg);
    return (error.status === 401)
        ? <span>{msg} Click <button className="text-button" type="button" onClick={azureService.doLogin}>here</button> to login.</span>
        : msg;
};

const extractErrorMessageAzure = (error, fallbackMsg = "Unknown error") => {
    if (!error) return fallbackMsg;
    if (typeof error === "string") return error;
    if (error.data && error.data.message && typeof error.data.message === "string") return error.data.message;
    if (error.data && error.data.error && error.data.error.message && typeof error.data.error.message === "string") return error.data.error.message;
    if (error.details && error.details[0]) {
        const parsed = JSON.parse(error.details[0].message);
        const msg = _.get(parsed, "error.details[0].message") ||  _.get(parsed, "error.message");
        if (msg && typeof msg === "string") return msg;
    }
    if (error.message && typeof error.message === "string") return error.message;
    return fallbackMsg;
};

const extractErrorGcp = (err, fallbackMsg = "An error occurred during the Google Cloud login process. This can happen if popups aren't allowed by your web browser or if you chose a user who doesn’t have the required authorization.") => {
    if (!err) return fallbackMsg;
    const error = err.error ? err.error : err;
    if (typeof error === "string") return error;
    if (error.message && typeof error.message === "string") return error.message;
    let errorMessage = JSON.stringify(error.errors);
    if (error.errors[0] && error.errors[0].message) {
        const errorObj = JSON.parse(error.errors[0].message);
        if (errorObj.ResourceErrorMessage) {
            errorMessage = errorObj.ResourceErrorMessage.message;
        }
    }
    if (errorMessage && typeof errorMessage === "string") return errorMessage;
    return fallbackMsg;
};

const extractErrorAws = (error, fallbackMsg = "Unknown error") => {
    let errorMsg = error && error.message ? error.message.toString() : error?.toString() || fallbackMsg;
    if (errorMsg === '[object Object]') {
        errorMsg = fallbackMsg;
    }
    return errorMsg;
};

export {
    extractErrorAzure,
    extractErrorGcp,
    extractErrorAws
}
