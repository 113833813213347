import React, {useEffect, useState} from 'react'
import {ReactComponent as DirectoriesIcon} from "../../../assets/svgs/directories-icon.svg";
import {ReactComponent as FilesIcon} from "../../../assets/svgs/files-icon.svg";
import {ReactComponent as SymLinkIcon} from "../../../assets/svgs/sym-link-icon.svg";
import {ReactComponent as OthersIcon} from "../../../assets/svgs/others-icon.svg";
import {ReactComponent as DepthIcon} from "../../../assets/svgs/depth-icon.svg";
import {ReactComponent as WidthIcon} from "../../../assets/svgs/width-icon.svg";
import numeral from "numeral";
import {keys, map, reduce, values, isEqual} from 'lodash'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar, Doughnut} from 'react-chartjs-2';
import {TooltipInfo} from '@netapp/shared-components';
import BarsGraph from "./BarsGraph";
import SummaryItem from './SummaryItem'
import {getVerticalBarOptions} from "./reportData";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const buildBarsDataSet = (sizes) => {
    const labels = keys(sizes);
    const colorArray = Array.apply(null,{length: labels.length}).map(() => '#1fa0ff');
    const hoverArray = Array.apply(null,{length: labels.length}).map(() => '#006dc9');
    const datasets = [{
        data:  values(sizes),
        backgroundColor: colorArray,
        hoverBackgroundColor: hoverArray,
        borderColor: colorArray,
        borderWidth: 1,
        barThickness: 19,
        borderRadius: 8

    }]
    return {labels, datasets};
}

const buildHorizontalGraphData = (sizes) => {
    const labels = keys(sizes);
    const counts = values(sizes)
    const total = reduce(counts, (sum, size) => sum + size, 0)
    return map(labels, (label, index) => ({
        name: label,
        count: numeral(counts[index]).format('0.[0]a'),
        percent: (counts[index] / total) * 100
    }));
}

const ReportViewFiles = ({report, protocol}) => {
    const [pieChartData, setPieChartData] = useState([])
    const [totalCount, setTotalCount] = useState("N/A")
    const [totalSize, setTotalSize] = useState("N/A")
    const [fileSizes, setFileSizes] = useState(null)
    const [accessTime, setAccessTime] = useState(null)
    const [modifyTime, setModifyTime] = useState(null)
    const [changeTime, setChangeTime] = useState(null)

    useEffect(() => {
        const pieChartDataNew = {
            values: [report.statistics.totals.directories, report.statistics.totals.files, report.statistics.totals.symbolicLinks, report.statistics.totals.others],
            colors: ["#8345e6", "#71dbc7", "#af149f", "#2d6bcc"]
        }
        if (!isEqual(pieChartDataNew, pieChartData)) {
            setPieChartData(pieChartDataNew)
        }
        if (!['box', 'gdrive'].includes(protocol)) {
            setTotalCount(numeral(report.statistics.totals.directories +
                report.statistics.totals.files +
                report.statistics.totals.others +
                report.statistics.totals.symbolicLinks).format('0.[00] a')
            )
        }
        else {
            setTotalCount(numeral(report.statistics.totals.directories +
                report.statistics.totals.files).format('0.[00] a')
            )
        }
        setTotalSize(report.statistics.totals.size)
        setFileSizes(buildBarsDataSet(report.statistics.distributions.size))
        setAccessTime(buildHorizontalGraphData(report.statistics.distributions.atime))
        setModifyTime(buildHorizontalGraphData(report.statistics.distributions.mtime))
        setChangeTime(buildHorizontalGraphData(report.statistics.distributions.ctime))
    }, [report, pieChartData, protocol])

    if (!report || !fileSizes) return <div/>
    const maxValue = Math.max(...values(report.statistics.distributions.size));
    const noChangeTime = protocol === "sftp" || protocol === "box" || protocol === 'gdrive';
    const noAccessTime = protocol === "box" || protocol === 'gdrive';
    const numOfColumns = noAccessTime && noChangeTime ? 3 : (!noChangeTime && !noAccessTime ? 1 : 2);

    return (<>
        <div className="summary report-card">
            <div className="card-header">Path Items</div>
            <div className="summary-content">
                <div className="graph">
                    <Doughnut
                        options={{
                            animation:false,
                            plugins: {datalabels: {display: false}, tooltip: {enabled: false}},
                            preserveAspectRatio: false,
                            cutout: 90,
                            height: "260px",
                            width: "200px"}}
                        legend={{ display: false }}
                        data={{
                            datasets: [{
                                data: pieChartData.values,
                                backgroundColor: pieChartData.colors}]}}/>
                    <div className="graph-info">
                        <div className="graph-info-count">{totalCount}</div>
                        <div className="graph-info-title">Total Items</div>
                    </div>
                </div>
                <div className={`summary-numbers ${protocol}`}>
                    <SummaryItem top={{icon:DirectoriesIcon, title: "Directories", data: report.statistics.totals.directories}} bottom={{title:"Empty Directories" ,data:report.statistics.totals.emptyDirectories}}/>
                    <SummaryItem top={{icon:FilesIcon, title: "Files", data: report.statistics.totals.files}} bottom={{title:"Empty Files" ,data:report.statistics.totals.emptyFiles}}/>
                    {!["box", "gdrive"].includes(protocol) && <SummaryItem top={{icon:SymLinkIcon, title: "Symbolic Links", data: report.statistics.totals.symbolicLinks}}/>}
                    <SummaryItem top={{icon:OthersIcon, title: "Total Size", data: totalSize, convertToBytes: true}}/>
                </div>
            </div>
        </div>
        <div className="files">
            <div className="files-sizes report-card">
                <div className="card-header">Files Size</div>
                <div className="card-content">
                    <Bar data={fileSizes} options={getVerticalBarOptions(maxValue)}/>
                </div>
            </div>
            <div className="files-maximums report-card">
                <div className="card-header maximums-header">
                    <div>Maximums</div>
                    <TooltipInfo className="maximums-info">
                        <div className="maximums-info">
                            <div className="maximum-info-depth">
                                <b>Maximum Depth</b><br/>
                                The maximum number of subdirectories that were scanned within a directory. In the following example, the maximum depth is 2: directory/subdirectory1/subdirectory2
                            </div>
                            <br/>
                            <div className="maximum-info-width">
                                <b>Maximum Width</b><br/>
                                The maximum number of entries that were scanned in one directory. An entry is a file, subdirectory, symbolic link, etc.
                            </div>
                        </div>
                    </TooltipInfo>
                </div>
                <div className="maximums-container">
                    <div className="maximum-part">
                        <SummaryItem top={{icon: DepthIcon, title: "Depth", data: report.statistics.maximums.depth}}/>
                    </div>
                    <div className="maximum-part">
                        <SummaryItem top={{icon:WidthIcon, title: "Width", data: report.statistics.maximums.width}}/>
                    </div>
                </div>

            </div>
        </div>

        <div className="times">
            {!noAccessTime && <div className={`times-box access report-card ${noChangeTime ? "multiple-columns-2" : ""}`}>
                <div className="card-header">Access Time<TooltipInfo>The last time that a file was accessed (read).</TooltipInfo></div>
                <div className="card-content"><BarsGraph data={accessTime} numOfColumns={numOfColumns}/></div>
            </div>}
            {!noChangeTime && <div className={`times-box change report-card ${noAccessTime ? "multiple-columns-2" : ""}`}>
                <div className="card-header">Change Time<TooltipInfo>The last time that the metadata of a file was changed (for example, the permissions).</TooltipInfo></div>
                <div className="card-content"><BarsGraph data={changeTime} numOfColumns={numOfColumns}/></div>
            </div>}
            <div className={`times-box modify report-card ${numOfColumns === 2 ? "multiple-columns-2" : (numOfColumns === 3 ? "multiple-columns-3" : "")}`}>
                <div className="card-header">Modify Time<TooltipInfo>The last time that a file was modified.</TooltipInfo></div>
                <div className="card-content"><BarsGraph data={modifyTime} numOfColumns={numOfColumns}/></div>
            </div>
        </div>

    </>)
}

export default ReportViewFiles;
