
let tokenClient;


export const init = async () => {
  await new Promise((resolve,reject) => {
    // eslint-disable-next-line no-undef
    gapi.load('client', {callback:resolve, onerror:reject})
  });
  console.log('init');
  // eslint-disable-next-line no-undef
  await gapi.client.init({});
  await new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GCP_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/cloud-platform",
        prompt: 'consent',
        callback: ''
      })
      resolve();
    } catch (err) {
      reject(err);
    }
  });
}

export const getToken = async () => {
  return new Promise((resolve, reject) => {
    try {
      // Settle this promise in the response callback for requestAccessToken()
      tokenClient.callback = (resp) => {
        if (resp.error !== undefined) {
          console.log('Error', resp.error);
          reject(resp);
        }
        // GIS has automatically updated gapi.client with the newly issued access token.
        // eslint-disable-next-line no-undef
        resolve(resp);
      };
      tokenClient.requestAccessToken();
    } catch (err) {
      console.log(err)
      reject(err);
    }
  });
}
