import React from "react";
import classNames from "classnames";
import {ReactComponent as CheckmarkIcon} from "./../../assets/svgs/checkmark-blue.svg";
import {ReactComponent as CircleIcon} from "./../../assets/svgs/circle-empty.svg";

import "./newWizardHeaderStep.scss"

const NewWizardHeaderStep = (props) => {
    const {stepName, current, future, goBack, addClass, stepNumber, fadedRight, fadedLeft} = props;
    const classes = classNames(addClass, {
        "wizard-header-step": true,
        current,
        future,
        "fade-in": true
    });

    const stepNumberElement = <div className="step-number">
        {future && <CircleIcon/>}
        <div className="number-text"><div>{stepNumber}</div></div>
    </div>;

    return (
        <div className={classes} title={stepName}>
            <button type="button" className={classNames({disabled: !goBack})} onClick={goBack ? goBack : () => {}}>
                {(current || future)
                    ? stepNumberElement
                    : <CheckmarkIcon/>
                }
                <span className="step-name" title={stepName}>{stepName}</span>
            </button>
            {(fadedRight || fadedLeft) &&
            <div className={classNames("faded-mask", {"right": fadedRight, "left": fadedLeft})}/>}
        </div>
    )
};

export default NewWizardHeaderStep;
