import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { find } from 'lodash';
import { ButtonText } from '@netapp/shared-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { getDataBrokerConfiguration, updateDataBrokerConfiguration } from '../../../store/wizard/action_creators';
import { prettyfyJson, selectText } from '../../../utils/helpers';
import { getPropFromState } from '../../../utils/mapStateToPropsUtils';
import MainLoader from '../../_common_/MainLoader/MainLoader';
import AlertDialog from '../AlertDialog';
import './dataBrokerConfiguration.scss';
import { RESPONSE_STATUS_FAILED, RESPONSE_STATUS_SUCCEEDED } from '../../../consts';

const DataBrokerConfigurationDialog = ({groupId, dataBrokerId, _dataBrokersGroups}) => {
    const configurationAreaRef = useRef();
    const dispatch = useDispatch();
    const [configuration, setConfiguration] = useState();

    useEffect(() => {
        getDataBrokerConfiguration(groupId, dataBrokerId)(dispatch);

        return () => {
            updateDataBrokerConfiguration({ data: null }, { agentId: dataBrokerId, groupId })(dispatch);
        }
    },[dispatch, dataBrokerId, groupId])

    useEffect(() => {
        const group = find(_dataBrokersGroups?.data, { id: groupId });
        const dataBroker = find(group?.dataBrokers, { id: dataBrokerId });

        if (dataBroker?.localConfiguration) {
            const { data, status, reason } = dataBroker.localConfiguration;
            setConfiguration({
                data: data ? prettyfyJson(data) : null,
                status,
                reason
            });
        }
    }, [_dataBrokersGroups, dataBrokerId, groupId]);

    const dialogContent = () => {
        if (configuration?.status === RESPONSE_STATUS_SUCCEEDED) {
            return <>
                <CopyToClipboard text={configuration} onCopy={() => selectText(configurationAreaRef.current)}>
                    <ButtonText className='copyButton'>Copy</ButtonText>
                </CopyToClipboard>
                <pre ref={configurationAreaRef} className='configuration-area'>
                    {configuration.data}
                </pre>
            </>
        } else if (configuration?.status === RESPONSE_STATUS_FAILED) {
            return (
                <pre className='configuration-area'>
                    <p>Can't load data broker configuration...</p>
                    <p>{configuration.reason}</p>
                </pre>
            )
        }
    }

    return <AlertDialog title='Custom Configuration' className="local-json-dialog" errorMessage={configuration?.status === RESPONSE_STATUS_FAILED ? 'JSON not loaded' : undefined}>
        {![RESPONSE_STATUS_SUCCEEDED, RESPONSE_STATUS_FAILED].includes(configuration?.status) ? <MainLoader /> : dialogContent()}
    </AlertDialog>
}

const mapStateToProps = (state, ownProps) => ({
    _dataBrokersGroups: getPropFromState(state, "_dataBrokersGroups", {}),
    ...ownProps
})

export default connect( mapStateToProps )(DataBrokerConfigurationDialog);