import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as actionCreators from './store/global/action_creators';
import * as wizardActionCreators from './store/wizard/action_creators';
import { store } from './store/store';
import sdk from "./sdk";
import numeral from "numeral";
import AppWithRouter from './App';
import { RightPanelProvider } from './context/right-panel-context';
import { DialogContextProvider } from '@netapp/shared-components';
import { ThemeProvider } from "@netapp/design-system";
import { theme } from './syncNew/utils/domUtils';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { BrowserRouter } from 'react-router-dom';

import "./index.scss";

i18next.use(Backend).use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        backend: {
            loadPath: './resources/i18n/{{lng}}.json'
        }
    })

Object.assign(numeral.locales["en"].abbreviations, { thousand: "K", million: "M", billion: "B", trillion: "T" });

if (window.navigator.userAgent.indexOf("MSIE 10") > -1 || window.navigator.userAgent.match(/Trident\//)) {
    document.getElementById('noIE').className = "show";
    document.getElementById('app').className = "hide";
} else {
    window.__$$expose = {
        actionCreators,
        wizardActionCreators,
        store,
        sdk
    };

    sdk.interceptors().response.use(null, function (error) {
        return Promise.reject(error.response);
    });


    // history.listen(location => {
    //     window.ga && window.ga('send', 'pageview', location.pathname);
    // });


    const container = document.getElementById('root');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript

    root.render(
        <Provider store={store}>
            <ThemeProvider isIframe={true} theme={theme}>
                <DialogContextProvider>
                    <RightPanelProvider>
                        <BrowserRouter>
                            <AppWithRouter />
                        </BrowserRouter>
                    </RightPanelProvider>
                </DialogContextProvider>
            </ThemeProvider>
        </Provider>,
    );
}
