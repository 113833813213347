import React, {useCallback} from 'react';
import { store } from '../../../store/store';
import {
    showShares,
    addNewShare,
    listShareFolders,
    clearFoldersCache,
    resetCacheForReload,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators'
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import ShowDirectoriesStepTemplate from "./ShowDirectoriesStepTemplateNew";
import {getPropFromState} from '../../../utils/mapStateToPropsUtils';
import {getIsIntegratedStep} from '../../../utils/wizard-utils';
import {isIntegratedCmProvider} from "../../../utils/cm-integration-utils";
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import ManualDirectoryDialog from "../../systemFlow/ManualDirectoryDialog";
import { CASH_KEYS } from '../../../consts';

const innerColumns = (row) => {
    //SER-306 SER- Wrong details in select directory screen - don't show information at all
    return [<td className="path" title={row.path} key="path">{row.path}</td>];
};

const loading = () => {return {text: "Shares", icon: require("./../../../assets/svgs/directory-default.svg")}}

const sharesKey = (isTargetStepFromSameSourceType, selectedCIFSTarget, selectedCIFS, selectedDataBroker) => {
    const encodedCIFS = isTargetStepFromSameSourceType ? selectedCIFSTarget : selectedCIFS;
    return `${encodedCIFS}-${selectedDataBroker.id}`;
}

const fetchData = (props) => {
    const { dispatch } = store;
    const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams: {
        selectedCIFSTarget, selectedCIFS, targetCifsVersion ,cifsVersion, selectedDataBroker}}} = props;
    const localSelectedCIFS = isTargetStepFromSameSourceType ? selectedCIFSTarget : selectedCIFS;
    const localCifsVersion = isTargetStepFromSameSourceType ? targetCifsVersion : cifsVersion;

    const storageKey = sharesKey(isTargetStepFromSameSourceType, selectedCIFSTarget, selectedCIFS, selectedDataBroker);
    resetCacheForReload({ cacheName: CASH_KEYS._SHOW_SHARES, storageKey })(dispatch);

    return props.showShares(localSelectedCIFS, localCifsVersion, isTargetStepFromSameSourceType, selectedDataBroker.id);
};

const shouldFetch = (props) => {
    const {
        _showShares, isTargetStepFromSameSourceType,
        _wizard: {selectedWizardParams: {selectedCIFSTarget, selectedCIFS, selectedDataBroker}}
    } = props;
    const showSharesKey = sharesKey(isTargetStepFromSameSourceType, selectedCIFSTarget, selectedCIFS, selectedDataBroker);
    const showSharesForCIFS = _showShares[showSharesKey];
    return !showSharesForCIFS || showSharesForCIFS.failedRetrieve;

};

const isFetching = (props) => {
    const {_showShares, _wizard: {selectedWizardParams: {selectedCIFS, selectedCIFSTarget, selectedDataBroker}}, isTargetStepFromSameSourceType} = props;
    const showSharesKey = sharesKey(isTargetStepFromSameSourceType, selectedCIFSTarget, selectedCIFS, selectedDataBroker);
    const showSharesForCIFS = _showShares[showSharesKey];
    if (!showSharesForCIFS) return true;
    return (showSharesForCIFS.inProgress) || ((!showSharesForCIFS.data || showSharesForCIFS.data.length === 0) && !showSharesForCIFS.retrievedAll);
};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetShares' : 'shares';
};



const ShowSharesStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {isTargetStepFromSameSourceType,isSourceStep, endpointObj} = moreStepInfo;

    const {clearFoldersCache, _showShares, listShareFolders, addNewShare,
        _wizard: {selectedWizardParams: {selectedCIFS, selectedCIFSTarget, selectedDataBroker,
            selectedShare, selectedShareTarget, selectedRootShare, selectedRootShareTarget,
            cifsVersion, targetCifsVersion}}} = props;
    const actualCifsVersion = isTargetStepFromSameSourceType ? targetCifsVersion : cifsVersion;
    const actualCifsServer = isTargetStepFromSameSourceType ? selectedCIFSTarget : selectedCIFS;
    const storageKey = `${actualCifsServer}-${selectedDataBroker.id}`;

    const addManualShare = useCallback((values) => {
        const currentCIFS = isTargetStepFromSameSourceType ? selectedCIFSTarget: selectedCIFS;
        addNewShare(values.directoryName, currentCIFS, selectedDataBroker.id, selectedDataBroker.id);
    }, [isTargetStepFromSameSourceType, selectedCIFSTarget, selectedCIFS, selectedDataBroker, addNewShare]);

    const stepOptions = {
        containerOptions: {
            singularTitle: "Share",
            pluralTitle: "Shares",
            itemIdentifier: "actualPath",
            rootName: "All Shares",
            targetName: "Define Target Share",
            directoriesName: "Directories",
            directoryName: "directory",
            icon: require(`./../../../assets/svgs/directory-no-circle.svg`)
        },
        failedMessage: "Unable to retrieve shares",
        notFoundMessage: "No shares were found",
        title: `Select a ${isSourceStep ? "Source" : "Target"} Share`,
        selectedDirectory: selectedShare,
        selectedRootDirectory: selectedRootShare,
        selectedDirectoryTarget: selectedShareTarget,
        selectedRootDirectoryTarget: selectedRootShareTarget,
        isStaticRoot: getIsIntegratedStep( endpointObj),
        version: actualCifsVersion,
        storage: _showShares,
        storageKey,
        listSubDirectoriesAction: listShareFolders,
        selectedServer: selectedCIFS,
        selectedServerTarget: selectedCIFSTarget,
        stepFormName: "sharesForm",
        innerColumns,
        addManualOptions: {
            manualForm: ManualDirectoryDialog,
            directoryType: "share",
            addManualAction: addManualShare,
            addManualText: "Add Share Manually"
        },
        //no need to refresh step in integrated mode. we picked volume
        refreshStep: isIntegratedCmProvider(endpointObj.provider) ? undefined : () => {
            clearFoldersCache({ cacheName: CASH_KEYS._SHOW_SHARES, storageKey });
            fetchData(props)
        },
        getSelectedParams: (fullPath, rootValue) => {
            const exportParam = isTargetStepFromSameSourceType ? 'sharePathTarget' : 'sharePath';
            const rootExportParam = isTargetStepFromSameSourceType ? 'rootShareTarget' : 'rootShare'
            const selectedParams = {
                [exportParam]: fullPath,
                [rootExportParam]: rootValue
            }
            return selectedParams;
        }
    };


    return isFetching({...props, ...moreStepInfo}) ? LoadingComp :<ShowDirectoriesStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>
};

const SharesForm = reduxForm({form: 'sharesForm'})(ShowSharesStep);

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showShares:  getPropFromState(state, CASH_KEYS._SHOW_SHARES, {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router
    }
};

const mapDispatchToProps = {
    showShares,
    addNewShare,
    listShareFolders,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(SharesForm);
