export const getVerticalBarOptions = (maxValue) => {
    const stepSize = Math.ceil(maxValue/5);
    return {
        animation: false,
        plugins: {
            legend: {display: false},
            tooltip: {
                callbacks: {
                    title: function() {
                        return '';
                    },
                    label: function(tooltipItem) {
                        return `${tooltipItem.formattedValue} files`;
                    },
                    afterLabel: function() {
                        return '';
                    }
                },
                backgroundColor: '#fbfbfb',
                bodyColor: '#404040',
                bodySpacing: 10,
                padding: 10,
                displayColors: false,
                caretPadding: 10,
                cornerRadius: 0
            },
        },
        layout: {padding: {top: 20, bottom: 10}},
        maintainAspectRatio: false,
        hover: {
            animationDuration: 0
        },
        scales: {
            x: {
                grid: {
                    display: false, // this will hide vertical lines
                },
            },
            y: {
                grid: {
                    color: '#e0e0e0',
                    borderDash: [2,5],
                    display: true
                },
                ticks: {
                    stepSize,
                    beginAtZero: true,
                    suggestedMax: maxValue + Math.ceil(stepSize/5),
                    autoSkip: true,
                    maxTicksLimit: 10
                }

            }
        },
    }

}
