export const UPDATE_URL_PARAMS = 'UPDATE_URL_PARAMS';
export const DATA_BROKER = 'DATA_BROKER';
export const DATA_BROKER_RESTART = 'DATA_BROKER_RESTART';
export const DATA_BROKER_PROXY = 'DATA_BROKER_PROXY';
export const DELETE_GROUP = 'DELETE_GROUP';
export const GET_SERVERS = 'GET_SERVERS';
export const GET_AZURE_STORAGE_ACCOUNTS = 'GET_AZURE_STORAGE_ACCOUNTS';
export const GET_AZURE_DATA_LAKE_STORAGE_ACCOUNTS = 'GET_AZURE_DATA_LAKE_STORAGE_ACCOUNTS';
export const DELETE_DATA_BROKER = 'DELETE_DATA_BROKER';
export const MOVE_DATA_BROKER = 'MOVE_DATA_BROKER';
export const SHOW_EXPORTS = "SHOW_EXPORTS";
export const SHOW_SHARES = "SHOW_SHARES";
export const LIST_FOLDERS = "LIST_FOLDERS";
export const LIST_SHARE_FOLDERS = "LIST_SHARE_FOLDERS";
export const SHOW_BUCKETS = "SHOW_BUCKETS";
export const LIST_BUCKETS_FOLDERS = "LIST_BUCKETS_FOLDERS";
export const SHOW_AZURE_BLOB_CONTAINERS = "SHOW_AZURE_BLOB_CONTAINERS";
export const LIST_CONTAINERS_FOLDERS = "LIST_CONTAINERS_FOLDERS";
export const SHOW_AZURE_DATA_LAKE_CONTAINERS = "SHOW_AZURE_DATA_LAKE_CONTAINERS";
export const LIST_AZURE_DATA_LAKE_CONTAINERS_FOLDERS = "LIST_AZURE_DATA_LAKE_CONTAINERS_FOLDERS";
export const SHOW_S3BASED_BUCKETS = "SHOW_S3BASED_BUCKETS";
export const LIST_S3BASED_BUCKETS_FOLDERS = "LIST_S3BASED_BUCKETS_FOLDERS";
export const SHOW_GCP_BUCKETS = "SHOW_GCP_BUCKETS";
export const LIST_GCP_BUCKET_FOLDERS = "LIST_GCP_BUCKETS_FOLDERS";
export const LIST_BOX_ROOT_FOLDER = "LIST_BOX_ROOT_FOLDER";
export const LIST_BOX_FOLDERS = "LIST_BOX_FOLDERS";
export const LIST_GOOGLE_DRIVES = "LIST_GOOGLE_DRIVES";
export const LIST_GOOGLE_DRIVE_FOLDERS = "LIST_GOOGLE_DRIVE_FOLDERS";
export const LIST_SFTP_ROOT_DIRECTORY = "LIST_SFTP_ROOT_DIRECTORY";
export const LIST_SFTP_DIRECTORIES = "LIST_SFTP_DIRECTORIES";
export const DELETE_RELATIONSHIP = "DELETE_RELATIONSHIP";
export const CREATE_SYNC = "CREATE_SYNC";
export const UPDATE_CREDENTIALS = "UPDATE_CREDENTIALS";
export const UPDATE_RELATIONSHIP_NAME = "UPDATE_RELATIONSHIP_NAME";
export const CLEAR_SYNC = "CLEAR_SYNC";
export const CLEAR_WIZARD_CACHE = "CLEAR_WIZARD_CACHE";
export const CLEAR_NEW_WIZARD = "CLEAR_NEW_WIZARD";
export const CLEAR_FOLDERS_CACHE = "CLEAR_FOLDERS_CACHE";
export const ADD_BUCKET_NAME = "ADD_BUCKET_NAME";
export const ADD_EXPORT_MANUALLY = "ADD_EXPORT_MANUALLY";
export const ADD_SHARE_MANUALLY = "ADD_SHARE_MANUALLY";
export const LIST_AWS_KMS_KEYS = "LIST_AWS_KMS_KEYS";
export const LIST_S3_PRIVATE_LINKS = "LIST_S3_PRIVATE_LINKS";
export const RESET_CACHE_FOR_RELOAD = "RESET_CASH_FOR_RELOAD";


export const SYNC_NOW = "SYNC_NOW";
export const ABORT_SYNC = "ABORT_SYNC";

export const UPDATE_RELATIONSHIP_SETTINGS = "UPDATE_RELATIONSHIP_SETTINGS";

export const DOWNLOAD_DATA_BROKER_LOGS = "DOWNLOAD_DATA_BROKER_LOGS";

export const UPDATE_DATA_BROKER_STATUS = "UPDATE_DATA_BROKER_STATUS";
export const UPDATE_EXPORTS = "UPDATE_EXPORTS";
export const UPDATE_SHARES = "UPDATE_SHARES";
export const UPDATE_FOLDERS = "UPDATE_FOLDERS";
export const UPDATE_SHARE_FOLDERS = "UPDATE_SHARE_FOLDERS";
export const UPDATE_BUCKETS = "UPDATE_BUCKETS";
export const UPDATE_BUCKETS_FOLDERS = "UPDATE_BUCKETS_FOLDERS";
export const UPDATE_CONTAINERS = "UPDATE_CONTAINERS";
export const UPDATE_DATA_LAKE_CONTAINERS = "UPDATE_DATA_LAKE_CONTAINERS";
export const UPDATE_CONTAINERS_FOLDERS = "UPDATE_CONTAINERS_FOLDERS";
export const UPDATE_DATA_LAKE_CONTAINERS_FOLDERS = "UPDATE_DATA_LAKE_CONTAINERS_FOLDERS";
export const UPDATE_S3BASED_BUCKETS = "UPDATE_S3BASED_BUCKETS";
export const UPDATE_S3BASED_BUCKETS_FOLDERS = "UPDATE_S3BASED_BUCKETS_FOLDERS";
export const UPDATE_GCP_BUCKETS = "UPDATE_GCP_BUCKETS";
export const UPDATE_GCP_BUCKET_FOLDERS = "UPDATE_GCP_BUCKET_FOLDERS";
export const UPDATE_BOX_ROOT_FOLDER = "UPDATE_BOX_ROOT_FOLDER";
export const UPDATE_BOX_FOLDERS = "UPDATE_BOX_FOLDERS";
export const UPDATE_GOOGLE_DRIVES = "UPDATE_GOOGLE_DRIVES";
export const UPDATE_GOOGLE_DRIVE_FOLDERS = "UPDATE_GOOGLE_DRIVE_FOLDERS";
export const UPDATE_SFTP_ROOT_DIRECTORY = "UPDATE_SFTP_ROOT_DIRECTORY ";
export const UPDATE_SFTP_DIRECTORIES = "UPDATE_SFTP_DIRECTORIES";
export const UPDATE_RELATIONSHIP = "UPDATE_RELATIONSHIP";
export const UPDATE_DATABROKER_TRANSFER_RATE = "UPDATE_DATABROKER_TRANSFER_RATE";
export const UPDATE_DATA_BROKER_LOGS = "UPDATE_DATA_BROKER_LOGS";
export const UPDATE_KMS_KEYS = "UPDATE_KMS_KEYS";
export const UPDATE_S3_PRIVATE_LINKS = "UPDATE_S3_PRIVATE_LINKS";

export const CLEAR_CLOSE_SEARCH_WIDGET = "CLEAR_CLOSE_SEARCH_WIDGET";
export const CLOSE_SEARCH_WIDGET = "CLOSE_SEARCH_WIDGET";

export const START_WIZARD = "START_WIZARD";
export const STARTED_WIZARD_STEP = "STARTED_WIZARD_STEP";
export const STARTED_NEW_WIZARD_STEP = "STARTED_NEW_WIZARD_STEP";
export const EXITED_WIZARD_STEP = "EXITED_WIZARD_STEP";

export const UPDATE_STEP_CREDENTIALS = "UPDATE_STEP_CREDENTIALS";

export const STATUS_ERROR = 'ERROR';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_PROGRESS = 'IN-PROGRESS';

export const REMOVE_ERROR = "REMOVE_ERROR";
export const CLEAR_URL_PARAMS = "CLEAR_URL_PARAMS";
export const CLEAR_SHOW_ACTION = "CLEAR_SHOW_ACTION";

export const BUCKETS_SELECT_STEP = "BUCKETS_SELECT_STEP";
export const BUCKETS_ADDITIONAL_SETTINGS_STEP = "BUCKETS_ADDITIONAL_SETTINGS_STEP";
export const AZURE_ADDITIONAL_SETTINGS_STEP = "AZURE_ADDITIONAL_SETTINGS_STEP";
export const AZURE_SELECT_STEP = "AZURE_SELECT_STEP";
export const GCP_ADDITIONAL_SETTINGS_STEP = "GCP_ADDITIONAL_SETTINGS_STEP";
export const GCP_SELECT_STEP = "GCP_SELECT_STEP";

export const GET_RELATIONSHIPS = "GET_RELATIONSHIPS";

export const GET_CVS_WORKING_ENVIRONMENTS = "GET_CVS_WORKING_ENVIRONMENTS";
export const GET_ANF_VOLUMES = "GET_ANF_VOLUMES";
export const GET_CM_WORKING_ENVIRONMENTS = "GET_CM_WORKING_ENVIRONMENTS";
export const GET_CM_VOLUMES = "GET_CM_VOLUMES";
export const GET_FSX_WORKING_ENVIRONMENTS = "GET_FSX_WORKING_ENVIRONMENTS";
export const GET_FSX_VOLUMES = "GET_FSX_VOLUMES";

export const GET_DATA_BROKERS_GROUPS = "GET_DATA_BROKERS_GROUPS";
export const RENAME_GROUP = "RENAME_GROUP";
export const EDIT_GROUP_SETTINGS = "EDIT_GROUP_SETTINGS";

export const GET_DATA_BROKER_CONFIGURATION = "GET_DATA_BROKER_CONFIGURATION";
export const UPDATE_DATA_BROKER_CONFIGURATION = "UPDATE_DATA_BROKER_CONFIGURATION";

export const GET_ENDPOINTS_AND_REPORTS = "GET_ENDPOINTS_AND_REPORTS"
export const CREATE_REPORT = "CREATE_REPORT"
export const DELETE_REPORTS = "DELETE_REPORTS"
export const UPDATE_REPORT = "UPDATE_REPORT"

export const action_update_map = {
    [SHOW_EXPORTS]: UPDATE_EXPORTS,
    [SHOW_SHARES]: UPDATE_SHARES,
    [SHOW_BUCKETS]: UPDATE_BUCKETS,
    [LIST_BUCKETS_FOLDERS]: UPDATE_BUCKETS_FOLDERS,
    [SHOW_AZURE_BLOB_CONTAINERS]: UPDATE_CONTAINERS,
    [LIST_CONTAINERS_FOLDERS]: UPDATE_CONTAINERS_FOLDERS,
    [SHOW_S3BASED_BUCKETS]: UPDATE_S3BASED_BUCKETS,
    [LIST_S3BASED_BUCKETS_FOLDERS]: UPDATE_S3BASED_BUCKETS_FOLDERS,
    [LIST_FOLDERS]: UPDATE_FOLDERS,
    [LIST_SHARE_FOLDERS]: UPDATE_SHARE_FOLDERS,
    [CREATE_REPORT]: UPDATE_REPORT
};
