import React from 'react';
import {
    // addNewShare,
    listGoogleDrives,
    listGoogleDriveFolders,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators'
import {connect} from 'react-redux';
import {getDirectoryObjectFromCacheGoogle} from "../../../utils/encoders"
import {reduxForm} from 'redux-form';
import ShowDirectoriesStepTemplate from "./ShowDirectoriesStepTemplateNew";
import {getPropFromState} from '../../../utils/mapStateToPropsUtils';
import {isIntegratedCmProvider} from "../../../utils/cm-integration-utils";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import useStepinize from "../useStepinize";
import { fixGooglePath } from '../../../utils/sync-utils';
// import ManualDirectoryDialog from "../systemFlow/ManualDirectoryDialog";

const innerColumns = (row) => {
    //SER-306 SER- Wrong details in select directory screen - don't show information at all
    return [<td className="path" title={row.path} key="path">{row.path}</td>];
};

const loading = () => {return {text: "Folders", icon: require("../../../assets/svgs/directory-default.svg")}}
const fetchData = (props) => {
    const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams: {
        selectedGoogleTarget, selectedGoogle, selectedDataBroker}}} = props;
    const localSelectedGoogle = isTargetStepFromSameSourceType ? selectedGoogleTarget : selectedGoogle;
    return props.listGoogleDrives(selectedDataBroker.id, localSelectedGoogle);
};

const shouldFetch = (props) => {
    const {
        _showGoogleDrives, isTargetStepFromSameSourceType,
        _wizard: {selectedWizardParams: {selectedGoogleTarget, selectedGoogle}}
    } = props;
    const encodedGoogle = isTargetStepFromSameSourceType ? selectedGoogleTarget : selectedGoogle;
    const storageKey = `${encodedGoogle}`;
    const showDirectoriesForSpecificClientId = _showGoogleDrives[storageKey];
    return !showDirectoriesForSpecificClientId || showDirectoriesForSpecificClientId.failedRetrieve;

};

const isFetching = (props) => {
    const {_showGoogleDrives, _wizard: {selectedWizardParams: {selectedGoogle, selectedGoogleTarget}}, isTargetStepFromSameSourceType} = props;
    const encodedGoogle = isTargetStepFromSameSourceType ? selectedGoogleTarget : selectedGoogle;
    const storageKey = `${encodedGoogle}`;
    const showDirectoriesForGoogle = _showGoogleDrives[storageKey];
    if (!showDirectoriesForGoogle) return true;
    return (showDirectoriesForGoogle.inProgress) || ((!showDirectoriesForGoogle.data || showDirectoriesForGoogle.data.length === 0) && !showDirectoriesForGoogle.retrievedAll);
};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetgdriveFolders' : 'gdriveFolders';
};


const ShowGoogleDriveFoldersStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

    const {clearFoldersCache, _showGoogleDrives, listGoogleDriveFolders,
        _wizard: {selectedWizardParams: {selectedDataBroker, selectedGoogle, selectedGoogleTarget,
            selectedGoogleDriveFolder, selectedGoogleDriveFolderTarget, selectedGoogleDrive, selectedGoogleDriveTarget,
            }}} = props;
    const storageKey = isTargetStepFromSameSourceType ? selectedGoogleTarget : selectedGoogle;

    const stepOptions = {
        containerOptions: {
            singularTitle: "Folder",
            pluralTitle: "Folders",
            itemIdentifier: "actualPath",
            rootName: "Root Folder",
            targetName: "Define Target Folder",
            directoriesName: "Folders",
            directoryName: "folder",
            icon: require(`../../../assets/svgs/directory-no-circle.svg`),
            useIndexForKey: true
        },
        failedMessage: "Unable to retrieve folders",
        notFoundMessage: "No folders were found",
        title: `Select a ${isSourceStep ? "Source" : "Target"} Folder`,
        selectedDirectory: selectedGoogleDriveFolder,
        selectedRootDirectory: selectedGoogleDrive,
        selectedDirectoryTarget: selectedGoogleDriveFolderTarget,
        selectedRootDirectoryTarget: selectedGoogleDriveTarget,
        isStaticRoot: false,
        storage: _showGoogleDrives,
        storageKey,
        listSubDirectoriesAction: listGoogleDriveFolders,
        getListSubDirectoriesActionParams : (path) => {
            const {foundDirectory: directoryObject} = getDirectoryObjectFromCacheGoogle(_showGoogleDrives, storageKey, path);
            const params = {
                subject: storageKey,
                groupId: selectedDataBroker.id,
                isTargetStepFromSameSourceType,
                folderName: directoryObject.actualPath,
                driveId: directoryObject.driveId || directoryObject.id
            };
            if (directoryObject.folderId) {
                params.folderId = directoryObject.folderId; //don't put folder id when drilling down on a drive
            }
            console.log(`%c getListSubDirectoriesActionParams google drive\n${JSON.stringify(params, null , 2)}`, 'font-weight:bold;color:brown')
            return params
        },
        selectedServer: selectedGoogle,
        selectedServerTarget: selectedGoogleTarget,
        stepFormName: "GoogleDriveFoldersForm",
        innerColumns,
        refreshStep: isIntegratedCmProvider(endpointObj.provider) ? undefined : () => {
            clearFoldersCache({cacheName:"_showGoogleDrives",storageKey});
            fetchData(props)
        },
        getSelectedParams: (fullPath, rootValue) => {
            const exportParam = isTargetStepFromSameSourceType ? 'googleFolderTarget' : 'googleFolder';
            const rootExportParam = isTargetStepFromSameSourceType ? 'googleDriveTarget' : 'googleDrive'
            const selectedParams = {
                [exportParam]: fullPath,
                [rootExportParam]: rootValue
            }
            return selectedParams;
        },
        pathMask: fixGooglePath
    };


    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ShowDirectoriesStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>
};

const GoogleDriveFoldersForm = reduxForm({form: 'GoogleDriveFoldersForm'})(ShowGoogleDriveFoldersStep);

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showGoogleDrives:  getPropFromState(state, "_showGoogleDrives", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    listGoogleDrives,
    listGoogleDriveFolders,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleDriveFoldersForm);
