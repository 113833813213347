import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { find } from 'lodash'
import { ReactComponent as RightArrow } from "../../../assets/svgs/right-arrow.svg";
import MainLoader from "../../_common_/MainLoader/MainLoader";
import useRunOnceWhenTruthy from "../../../hooks/useRunOnceWhenTruthy";
import { LegendWide, SummaryCard } from "../FailedComponents";
import { ReactComponent as DirectoriesIcon } from "../../../assets/svgs/directories-icon.svg";
import { ReactComponent as ObjectsIcon } from "../../../assets/svgs/objects-icon.svg";
import SelectableTable from "../../table/SelectableTable";
import numeral from "numeral";
import { HeaderItem } from "../commonComponents/ViewCommonComponents";
import { useNavigate, useParams } from 'react-router-dom';
require("./reportErrors.scss");

const getEndpoint = (endpoints, reportId) => {
    return find(endpoints, (endpoint) => {
        const report = find(endpoint.reports, { id: reportId })
        if (report) return endpoint;
        else return null;
    });
}

const filesProtocols = ['nfs', 'cifs', 'sftp'];

const renderRow = (row) => (
    <div className="table-row">
        <td className="blank" />
        <td className="value">{row.counter}</td>
        <td className="type">{row.errorCode}</td>
        <td className="host-name" title={row.description}>{row.description}</td>
    </div>
);

const renderHeader = () => (
    <thead>
        <tr>
            <td className="blank" style={{ width: "3%" }} />
            <td>Failed Items</td>
            <td>Error Code</td>
            <td>Failure Reason</td>
        </tr>
    </thead>
);

const ReportErrors = (props) => {
    const { _endpoints } = props;
    const { reportId } = useParams('reportId');
    const navigate = useNavigate();

    const [report, setReport] = useState(null);
    const [endpoint, setEndpoint] = useState(null);

    useRunOnceWhenTruthy(() => {
        navigate({
            pathname: `/reports`,
            search: window.location.search
        });
    }, !_endpoints?.data);


    useEffect(() => {
        if (!_endpoints?.data) return;
        const foundEndpoint = getEndpoint(_endpoints.data, reportId);
        const foundReport = find(foundEndpoint.reports, { id: reportId });
        setEndpoint(foundEndpoint)
        setReport(foundReport);
    }, [report, _endpoints, reportId]);

    const reportsClicked = () => {
        navigate({
            pathname: `/reports`,
            search: window.location.search
        });
    };

    const pathClicked = () => {
        navigate({
            pathname: `/report-view/${reportId}`,
            search: window.location.search
        });
    };

    if (!report) return <MainLoader />
    const { ui: { endPointTitle, errors: { scannedFiles, scannedObjects, failed, topErrors } }, endpoint: { protocol } } = endpoint;
    const filesProtocol = filesProtocols.includes(protocol);
    const tableData = topErrors.map((error, index) => ({
        id: index,
        counter: error.counter,
        errorCode: error.errorCode,
        description: error.description
    }));


    const scanned = filesProtocol ? scannedFiles : scannedObjects;
    const formattedScanned = numeral(scanned).format('0.[00] a');
    return (
        <div className="scrollable-area">
            <div className="report-errors">
                <div className="bread-crumbs" title={endPointTitle}>
                    <div className="back" onClick={reportsClicked}>Reports Table </div>
                    <div className="right-arrow"><RightArrow /></div>
                    <div className="back" onClick={pathClicked}>{endPointTitle}</div>
                    <div className="right-arrow"><RightArrow /></div>
                    <div className="last">Errors</div>
                </div>
                <div className="header">
                    <div className="header-info">
                        <HeaderItem className="blue" data="Report Information" />
                        <HeaderItem title="Path" data={endPointTitle} />
                    </div>
                </div>
                <div className="summary-graphs">
                    <>
                        <SummaryCard header={`Failed to Scan ${!filesProtocol ? '(Objects)' : '(Directories)'}`}
                            labelInsidePie="Failed"
                            failedCount={failed}
                            successCount={scanned}>
                            <LegendWide failedLabel="Failed to scan"
                                successLabel="Succeeded to scan"
                                failedCount={failed}
                                successCount={formattedScanned}
                                icon={filesProtocol ? <DirectoriesIcon /> : <ObjectsIcon />}
                                subtitle={filesProtocol ? "Directories" : "Objects"} />
                        </SummaryCard>
                    </>
                </div>
                <div className="failed-files-table">
                    <div className="table-title">
                        <h5>Top Failures Reasons</h5>
                    </div>
                    <SelectableTable Table={{ renderRow, renderHeader }} data={tableData}>
                    </SelectableTable>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    _endpoints: state.syncReducer._endpoints
});

export default connect(mapStateToProps, null)(ReportErrors)
