import React, {useState, useMemo} from 'react'
import { ReactComponent as ExpandIcon} from '../../../assets/svgs/collapse-arrow.svg'
import { ReactComponent as ExpandedIcon} from '../../../assets/svgs/collapse-arrow-down.svg'
import {ReactComponent as OrangeExclamationIcon} from '../../../assets/svgs/orange-exclamation-icon.svg'
import {convertSpeed, formatBytes} from "../../../utils/helpers";
import BrokerStatusDisplay from "../../newWizard/steps/BrokerStatusDisplay"; //CS-7125 Remove old wizard
import {MenuPopover, useDialog} from '@netapp/shared-components';
import {FM_AGENT_TYPE_AWS, FM_AGENT_TYPE_AZURE, FM_AGENT_TYPE_GCP, FM_AGENT_TYPE_ONPREM} from "../../../consts";
import {ReactComponent as RemoveIcon} from "../../../assets/svgs/remove-circle-icon.svg";
import {ReactComponent as ShowIcon} from "../../../assets/svgs/eye.svg";
import {ReactComponent as RestartIcon} from "../../../assets/svgs/restart-db-circle-icon.svg";
import {ReactComponent as MoveIcon} from "../../../assets/svgs/move-icon.svg";
import {ReactComponent as EditIcon} from "../../../assets/svgs/pencil-circle-icon.svg";
import DeleteDataBrokerDialog from "../../newWizard/steps/DeleteDataBrokerDialog";
import {getFullDataBrokersList} from "../../../utils/dataBrokersUtils";
import {connect,useSelector} from 'react-redux';
import Notice from "../../notifications/Notice";
import RestartDataBrokerDialog from "./RestartDataBrokerDialog";
import EditProxyDialog from "./EditProxyDialog";
import MoveDataBrokerDialog from "../MoveDataBrokerDialog";
import {isEmpty, find} from 'lodash'
import DataBrokerConfigurationDialog from '../../dialog/dataBrokerConfiguration/dataBrokerConfigurationDialog';
import { deploySelector } from '../../../store/selectors/deploy.selector';

import './managedDataBroker.scss'

const getDisableMoveMessage = (dataBroker, dataBrokerGroup) => {
    if (!isEmpty(dataBrokerGroup?.encryptionType)) {
        return "Moving this data broker isn’t allowed because it belongs to a group that manages an encrypted sync relationship."
    }
    else if (!dataBroker?.placement) {
        return "Moving this data broker isn’t allowed because the data broker is still being deployed."
    }
    else if (dataBrokerGroup.dataBrokers.length === 1 && dataBrokerGroup.relationships.length > 0) {
        return "Moving this data broker isn’t allowed because it’s the last data broker in a group that manages existing sync relationships."
    }
}
const managedDataBrokerMenu = (dataBroker, setDialog, last, dataBrokerGroups, inWizard) => {

    const editProxySupported = dataBroker.isReady && (dataBroker.placement?.canUpdateProxy === true);
    const dataBrokerGroup = find(dataBrokerGroups, {id: dataBroker.groupId})
    if (!dataBrokerGroup) return []; //right after the move it will be null for a second
    const disallowMove = !isEmpty(dataBrokerGroup?.encryptionType) || !dataBroker?.placement || (dataBrokerGroup.dataBrokers.length === 1 && dataBrokerGroup.relationships.length > 0);
    const disallowMoveMessage = getDisableMoveMessage(dataBroker, dataBrokerGroup);

    const notInWizardButtons = [
        <button disabled={disallowMove} type="button" title={disallowMove ? disallowMoveMessage : ""}
                onClick={(element) => {
                    setDialog(<MoveDataBrokerDialog className="default-dialog" data={dataBroker}
                                                    dataBrokersGroups={dataBrokerGroups}/>, element.target);
                }}>
            <span className="garbage-bin"><MoveIcon/></span>
            <span>Move Data Broker</span>
        </button>,
        <button disabled={!editProxySupported}
                title={editProxySupported ? '' : 'This Data Broker does not support edit proxy configuration'}
                type="button" onClick={(element) => {
            setDialog(<EditProxyDialog className="default-dialog" data={dataBroker}/>, element.target);
        }}>
            <span className="garbage-bin"><EditIcon/></span>
            <span>Edit Proxy Configuration</span>
        </button>
    ]

    const buttons = [
        <button disabled={!dataBroker.isReady} type="button" onClick={(element) => {
            setDialog(<DataBrokerConfigurationDialog dataBrokerId={dataBroker.id} groupId={dataBroker.groupId}/>, element.target);
        }}>
            <span className="garbage-bin"><ShowIcon/></span>
            <span>Show Custom Configuration</span>
        </button>,
        <button type="button" onClick={(element) => {
            setDialog(<DeleteDataBrokerDialog className="default-dialog" data={dataBroker} last={last}/>, element.target);
        }}>
            <span className="garbage-bin"><RemoveIcon/></span>
            <span>Remove Data Broker</span>
        </button>,
        <button disabled={!dataBroker.isReady} type="button" onClick={(element) => {
            setDialog(<RestartDataBrokerDialog className="default-dialog" data={dataBroker}/>, element.target);
        }}>
            <span className="garbage-bin"><RestartIcon/></span>
            <span>Restart Data Broker</span>
        </button>,
    ];

    if (!inWizard) {
        buttons.push(...notInWizardButtons);
    }
    return buttons;
};


const DoubleLine = ({title, value, className, titleIcon}) => {
    return(
        <div className={`placement-double-line flex flex-dir-column ${className ? className : ''}`}>
            <div className="placement-dl-value" title={value}>{value}</div>
            <div className="placement-dl-title">
                <div>{title}</div>
                {titleIcon && titleIcon}
            </div>

        </div>
    )
}

const PlacementLine = ({title, children}) => {
    return (
        <div className="placement-line">
            <div className="placement-title">{title}</div>
            {children}
        </div>
    )
}
const PlacementInfo = ({dataBroker, inWizard}) => {
    const {latestVersion, placement} = dataBroker
    const type = dataBroker.type
    const isOnPrem = type === FM_AGENT_TYPE_ONPREM.name
    const isAWS = type === FM_AGENT_TYPE_AWS.name
    const isGCP = type === FM_AGENT_TYPE_GCP.name
    const isAzure = type === FM_AGENT_TYPE_AZURE.name
    const availableRam = placement? formatBytes(placement.osTotalMem) : "N/A";
    if (!placement) return <div/>
    return (
        <div className="flex flex-dir-column">
            <PlacementLine title="Information">
                <DoubleLine title="Broker ID" value={dataBroker.id}/>
                {!isOnPrem && <DoubleLine title="Instance ID" value={placement.instanceId}/>}
                <DoubleLine title="Host Name" value={placement.hostname}/>
                {isGCP && <DoubleLine title="Project Id" value={placement.gcpProjectId}/>}
                {isAWS && <DoubleLine title="Account Id" value={placement.awsAccountId}/>}
                {isAzure && <DoubleLine title="Resource Group" value={placement.resourceGroupName}/>}
            </PlacementLine>
            <PlacementLine title="Network">
                {isOnPrem && <>
                        <DoubleLine title="Private IP" value={placement.privateIp}/>
                    </>}
                {isAWS && <>
                        <DoubleLine title="Region" value={placement.region}/>
                        <DoubleLine title="Availability Zone" value={placement.availabilityZone}/>
                        <DoubleLine title="VPC" value={placement.vpcId}/>
                        <DoubleLine title="Subnet" value={placement.subnetId}/>
                        <DoubleLine title="Private IP" value={placement.privateIp}/>
                    </>}
                {isGCP && <>
                    <DoubleLine title="Zone" value={placement.zone}/>
                    <DoubleLine title="Network" value={placement.network}/>
                    <DoubleLine title="Subnet" value={placement.subnetCidrBlock}/>
                    <DoubleLine title="Private IP" value={placement.privateIp}/>
                </>}
                {isAzure && <>
                    <DoubleLine title="Region" value={placement.region}/>
                    <DoubleLine title="Subnet" value={placement.subnetCidrBlock}/>
                    <DoubleLine title="Private IP" value={placement.privateIp}/>
                </>}
            </PlacementLine>
            {!inWizard && placement.proxy && <PlacementLine title="Proxy">
                <DoubleLine title="Proxy" value={placement.proxy} className="full-width"/>
            </PlacementLine>}
            {!inWizard && <PlacementLine title="Software">
                <DoubleLine title="Linux Distribution & Version" value={placement.platform}/>
                <DoubleLine title="Vault Version" value={placement.vault}/>
                <DoubleLine title="Node Version" value={placement.node}/>
                <DoubleLine title="Data Broker Version" value={placement.version} titleIcon={latestVersion !== placement.version ? <OrangeExclamationIcon/> : null}/>
            </PlacementLine>}
            {!inWizard && <PlacementLine title="Hardware">
                <DoubleLine title="Available CPUs" value={placement.cpus}/>
                <DoubleLine title="Available RAM" value={`${availableRam.size} ${availableRam.suffix}`}/>
            </PlacementLine>}
            {!inWizard && <PlacementLine title="Configuration">
                <DoubleLine title="Scanner Concurrency" value={placement.scanner?.concurrency ?? 'N/A'}/>
                <DoubleLine title="Scanner Processes Limit" value={placement.scanner?.processesLimit ?? 'N/A'}/>
                <DoubleLine title="Transferrer Concurrency" value={placement.transferrer?.concurrency ?? 'N/A'}/>
                <DoubleLine title="Transferrer Processes Limit" value={placement.transferrer?.processesLimit ?? 'N/A'}/>
            </PlacementLine>}
            {!inWizard && <PlacementLine title="Processes">
                <DoubleLine title="Scanner" value={placement.scanner?.processes ?? 'N/A'}/>
                <DoubleLine title="Transferrer" value={placement.transferrer?.processes ?? 'N/A'}/>
            </PlacementLine>}
        </div>
    )
}

const FooterWarning = ({warningText}) => {
    return (
        <div className="broker-footer-warning">
            <Notice type="warning" noIcon={true}>{warningText}</Notice>
        </div>
    )
}

const ManagedDataBroker = (props) => {
    const {dataBroker: {isWaiting, isDisabled, isFailed, name, type, ui, transferRate, latestVersion, placement},
        dataBroker, last, expand, inWizard, dataBrokersGroups} = props
    const {version, canUpdateNodeVersion} = placement || {};
    const [collapsed, setCollapsed] = useState(!expand || isWaiting) //when waiting should always be collapsed CS-5842
    const deployments = useSelector(deploySelector.deployments);

    const fullDataBrokerInArray = useMemo(() => getFullDataBrokersList(deployments, [dataBroker]), [dataBroker, deployments])
    const isReady =  !isDisabled && !isWaiting && !isFailed;
    const {setDialog} = useDialog();

    return (
        <div className="managed-data-broker">
            <div className="managed-data-broker-collapsed-container">
                <div className="managed-data-broker-header">
                    <div className="name-icon">
                        {isReady ? ui.type.managedCircleIcon: ui.type.disabledManagedCircleIcon}
                        <div className={`name ${isWaiting ? 'disabled' : ''}`} title={name}>{name}</div>
                    </div>
                    <div className={`managed-header-text ${isWaiting ? 'disabled' : ''}`}>{type}</div>
                    <div className={`managed-header-text wider ${isWaiting ? 'disabled' : ''}`}>Transfer Rate: {transferRate && !isFailed ? convertSpeed([transferRate]): 'N/A'}</div>

                </div>
                <div className="managed-data-broker-header-status">
                    <BrokerStatusDisplay {...fullDataBrokerInArray[0]}/>
                </div>
                <div className="managed-data-broker-buttons">
                    {!isWaiting && !inWizard && <div className="managed-data-broker-collapse"
                                        onClick={() => setCollapsed(!collapsed)}>
                        {collapsed && <ExpandIcon/>}
                        {!collapsed && <ExpandedIcon/>}
                    </div>}
                    <MenuPopover
                        popoverClass="menu-popover"
                        triggerVariant="card"
                        menuItems={managedDataBrokerMenu(props.dataBroker, setDialog, last, dataBrokersGroups, inWizard)}
                    />
                </div>
            </div>
            {(!collapsed || inWizard) && <div className="managed-data-broker-content">
                <PlacementInfo dataBroker={dataBroker} inWizard={inWizard}/>
            </div>}
            {canUpdateNodeVersion === false && <FooterWarning warningText={`This Data broker is running on an old operating system and should be upgraded.`}/>}
            {latestVersion && version && latestVersion !== version && <FooterWarning warningText={`This Data broker is not updated with the latest version (${latestVersion}). If you experience performance problems please contact support.`}/>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    dataBrokersGroups: state.syncReducer._dataBrokersGroups?.data
});

const ConnectedManagedDataBroker = connect(mapStateToProps, {})(ManagedDataBroker);
export default ConnectedManagedDataBroker;
