import {find, map, groupBy, keys, flatten} from 'lodash'

export const getFullDataBrokersList = (deployments, dataBrokers) => {
    return map(dataBrokers, dataBroker => {
        if (dataBroker.placement) return dataBroker;
        const deployment = find(deployments, {dataBrokerId: dataBroker.id});
        return {...dataBroker, deployment}
    })
};

export const getDataBrokerAllowedType = (dataBrokers) => {
    const dataBrokersByTypes = keys(groupBy(dataBrokers, 'type'));
    return dataBrokersByTypes.length === 1 ? dataBrokersByTypes[0] : find(dataBrokersByTypes, type => type !== 'ONPREM')
};

export const getAllDataBrokers = groups => flatten(groups?.map(group => group.dataBrokers));


