import React, { useState, useRef, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
  NEW_TARGET_FOLDER_ID,
  STORAGE_OBJECT_KEY_MAX_LENGTH,
} from "../../../consts";
import { isString, noop, isEmpty, isEqual, merge } from "lodash";
import classNames from "classnames";
import { useDialog } from "@netapp/shared-components";
import RefreshButton from "./../../refreshButton/RefreshButton";
import ButtonsPanel from "./../../widgets/ButtonsPanel";
import useRunOnce from "./../../../hooks/useRunOnce";
import { scrollToTop } from "./../../../utils/wizard-utils";
import { ReactComponent as PlusIcon } from "./../../../assets/svgs/plus-fill-circle-icon.svg";
import { ReactComponent as BucketIcon } from "./../../../assets/svgs/bucket-icon.svg";
import { Form } from "react-final-form";
import useRunOnceWhenTruthy from "./../../../hooks/useRunOnceWhenTruthy";
import {saveStepCredentials, getCredentialsByType} from "./../../../utils/sync-utils";
import WizardStepHeader from "./WizardStepHeaderNew";
import NewBucketDialog from "../../systemFlow/NewBucketDialog";
import DrillDownBreadCrumbs from "../../table/DrillDownBreadCrumbs";
import TargetSelectionNotice from "./TargetSelectionNoticeNew";
import { addNotification } from "../../../store/global/action_creators";
import { NOTIFICATION_CONSTS } from "../../../consts/notification.consts";
import { useTranslation } from "react-i18next";
import { wizardSelector } from "../../../syncNew/store/selectors/wizard.selector";
import { setTargetGcpBucketName } from "../../../syncNew/store/slices/wizard.slice";

require("./showObjectStorageStepNew.scss");

const OBJECT_STORAGE_FOLDER_REGEX = /\/+/;

const ShowObjectStorageStepTemplate = (props) => {
  const {stepOptions, _wizard, routing, history, addNewBucket: addNewBucketAction,
    up, isTargetStepFromSameSourceType, continueToNextStep, updateSelectedParams, isSourceStep} = props;
  const {storageObjectName, storageObjectType, currentStep, updateStepInfo, containerOptions, selectedBaseObject,
    resetToFirstStepAction, storage, listSubFoldersAction,
    getStorageByBrokerId, failedMessage, notFoundMessage, bottomMessage, innerColumns, stepFormName, innerPathBeginWithSlash,
    title, getSecondStepUi, supportAddStorageObject, refreshStep, validate, initialFormValues, getExtraUrlParamsNew, getSelectedParams} = stepOptions;
  const { directoryName, nameForSetupStep } = containerOptions;
  const {selectedWizardParams: { selectedDataBroker }, wizardInfo: {source, target}} = _wizard;
  const { setDialog } = useDialog();
  const dispatch = useDispatch();
  const googleEncryption = useSelector(wizardSelector.targetGcpSelectedEncryption);
  const { t } = useTranslation();

  const [localState, setLocalState] = useState({
    selectedId: selectedBaseObject || null,
    error: null,
    initialPath: selectedBaseObject || null,
    step: "",
  });

  const [formValues, setFormValues] = useState(null);
  const scrollArea = useRef();
  const errorRef = useRef();

  useRunOnceWhenTruthy(() => {
    if (initialFormValues) {
      setFormValues(initialFormValues);
    }
  }, [initialFormValues]);

  useEffect(() => {
    scrollToTop(scrollArea?.current);
  }, [currentStep]);

  useRunOnce(() => {
    if (resetToFirstStepAction) {
      resetToFirstStepAction();
    }
  });

  const setSelected = (selected) => {
    setLocalState({ ...localState, selectedId: selected.value, error: null });
  };

  const submit = (values) => {
    return Promise.resolve(true).then(() => {
      onContinue(values);
    });
  };

  const onContinue = (values) => {
    let error;

    const whatNext = (step, targetFolderFullPath) => {
      let shouldContinue = true;

      if (!isSourceStep && target.provider === 'gcp') {
        const bucketName = storageObjectPath.split('/')[0];
        dispatch(setTargetGcpBucketName(bucketName));
      }

      if (updateStepInfo) {
        shouldContinue = updateStepInfo(step, targetFolderFullPath);
      }
      if (shouldContinue) goToNextStep(targetFolderFullPath, values);
    };

    if (!localState.selectedId) {
      error = `In order to continue to the next step please select a ${storageObjectName}.`;
    }
    // user chose a storageObject
    else {
      const step = currentStep || "select";
      // chose new target folder - validate
      if (localState.selectedId.endsWith(NEW_TARGET_FOLDER_ID)) {
        const path = localState.selectedId.replace(NEW_TARGET_FOLDER_ID, ""); // remove target const from path
        const { targetFolder } = values;
        // validate folder name exists
        if (!targetFolder || !isString(targetFolder)) {
          error = `Please enter ${storageObjectType} ${directoryName} name.`;
        }
        // folder name exists
        else {
          // validate object key length
          const storageObjectSlashIndex = path.indexOf("/");
          const objectStoragePathLength =
            storageObjectSlashIndex === -1
              ? 0
              : path.length - storageObjectSlashIndex; // path without the storageObject name
          if (
            objectStoragePathLength + targetFolder.length >=
            STORAGE_OBJECT_KEY_MAX_LENGTH
          ) {
            error = `${storageObjectType} object key must be less than 1024 characters.`;
          }
          // forward slash is not allowed in S3 folder name
          else if (OBJECT_STORAGE_FOLDER_REGEX.test(targetFolder)) {
            error = `${storageObjectType} ${directoryName} name shouldn't contain /`;
          }
        }

        // valid folder name - go to next path with storageObject path that the folder will be created in
        if (!error) {
          const targetFolderFullPath = localState.selectedId.replace(
            NEW_TARGET_FOLDER_ID,
            () => targetFolder
          );
          setLocalState({ ...localState, selectedId: targetFolderFullPath });

          whatNext(step, targetFolderFullPath);
        }
      } else if (step === 'secondStep' && target.provider === 'gcp' && googleEncryption && googleEncryption.isValid === false) {
        error = t(`sync.wizard.encryptionSetting.google.custom.notificationError`);
      }
      // chose existing folder - no validations
      else {
        whatNext(step, localState.selectedId);
      }
    }

    // update errors only in case there are errors, otherwise we get errors in the console for trying to set state of unmounted component
    if (error) {
      setLocalState({ ...localState, error })
      setTimeout(() => {
        addNotification({
          id: NOTIFICATION_CONSTS.UNIQUE_IDS.SHOW_OBJECT_STORAGE_STEP_NOTIFICATION,
          type: NOTIFICATION_CONSTS.TYPE.ERROR,
          children: errorRef.current
        })(dispatch)
      }, 0);
    };
  };

  const goToNextStep = (storageObjectPath, values) => {
    //only for S3 and GCP the acl is handled in the second step of buckets. in all other places it is handled in the server step
    const provider = isSourceStep ? source.provider: target.provider;
    if (provider === 's3' || provider === 'gcp') {
      const cred = getCredentialsByType(provider, storageObjectPath, isTargetStepFromSameSourceType);
      const noCredentials = isEmpty(cred);
      const credentialsChanged = !isEqual(values, cred);
      if (noCredentials || credentialsChanged) {
        //make sure  to take also the target folder from last crednetials so they won't be deleted. CS-6497
        saveStepCredentials(provider, storageObjectPath, merge({}, cred, values), isTargetStepFromSameSourceType);
      }
    }
    // clear errors
    setLocalState({ ...localState, error: null });
    //set it back to selection step
    if (resetToFirstStepAction) resetToFirstStepAction();
    // go to next step

    //handle extra params from getExtraUrlParams (get them as object and spread them into state) in case of target step only
    const extraParams = !isSourceStep? getExtraUrlParamsNew(values): {}
    updateSelectedParams({...getSelectedParams(storageObjectPath), ...extraParams})
    continueToNextStep();
  };

  const drillDown = (path, storageObject) => {
    const {
      selectedWizardParams: { selectedDataBroker },
    } = _wizard;
    const storageObjectsByBrokerId = getStorageByBrokerId
      ? storage[selectedDataBroker.id]
      : storage;
    if (
      storageObjectsByBrokerId &&
      storageObjectsByBrokerId.folders &&
      storageObjectsByBrokerId.folders[path] &&
      (storageObjectsByBrokerId.folders[path].succeeded ||
        storageObjectsByBrokerId.folders[path].inProgress)
    )
      return;
    listSubFoldersAction(storageObject, path, selectedDataBroker.id);
  };

  const addNewBucket = (values) => {
    addNewBucketAction(
        values.bucketName,
        _wizard.selectedWizardParams.selectedDataBroker.id,
        supportAddStorageObject.cacheName,
        _wizard.selectedWizardParams.selectedDataBroker.id,
    );
    setSelected({ value: values.bucketName });
  };

  const isRefreshDisabled = () => {
    const { isSourceStep, wizardTypeForSystemFlow, selectedWizardParams } =
      _wizard;
    const { selectedStorageAccountName, selectedStorageAccountNameTarget } =
      selectedWizardParams;
    const isSameAzureHost =
      selectedStorageAccountNameTarget &&
      selectedStorageAccountName === selectedStorageAccountNameTarget;

    return (
      (wizardTypeForSystemFlow === "s3-s3" ||
        wizardTypeForSystemFlow === "gcp-gcp" ||
        isSameAzureHost) &&
      !isSourceStep
    );
  };

  const storageObjectsByBrokerId = getStorageByBrokerId
    ? storage[selectedDataBroker.id]
    : storage;
  const step = currentStep || "select";
  const storageObjectPath = localState.selectedId
    ? localState.selectedId.split("/")[0]
    : "";
  const storageObjectFullPath = localState.selectedId || "";
  const error =
    storageObjectsByBrokerId && storageObjectsByBrokerId.failed
      ? storageObjectsByBrokerId.data
      : localState.error;
  const { data, folders, retrievedAll, failedRetrieve } =
    storageObjectsByBrokerId;
  const headerAddon = (
    <button
      type="button"
      className={"outline new-bucket"}
      onClick={(el) =>
        setDialog(
          <NewBucketDialog
            onSubmitFunction={addNewBucket}
            {...supportAddStorageObject}
          />,
          el.target
        )
      }
    >
      <PlusIcon />
      Add Bucket Manually
    </button>
  );

  return (
    <div className="wizard-step show-buckets-step">
      <Form
        onSubmit={submit}
        validate={(values) => (validate ? validate(values, props) : noop)}
        initialValues={formValues}
      >
        {({ handleSubmit, form, values, submitError }) => {
          const formState = form.getState();
          const {modifiedSinceLastSubmit} = formState;
          errorRef.current = !modifiedSinceLastSubmit ? error || submitError : null;

          return (
            <form noValidate className="wizard-step-form" onSubmit={handleSubmit}>
              <div
                className={classNames("scrollable-area", { up, down: !up })}
                ref={scrollArea}
              >
                <WizardStepHeader
                  _wizard={_wizard}
                  routing={routing}
                  history={history}
                  title={step === "select" ? title : nameForSetupStep}
                  rightAction={
                    supportAddStorageObject && step === "select"
                      ? headerAddon
                      : null
                  }
                />

                {data && data.length > 0 && step === "select" && (
                  <div
                    className={classNames({
                      "disable-change": step !== "select",
                    })}
                  >
                    <div>
                      {!isSourceStep && (
                        <div className="header-text">
                          Drill down to select an existing {directoryName}{" "}
                          within the {storageObjectName.toLowerCase()} or to select a new{" "}
                          {directoryName} that you create inside the{" "}
                          {storageObjectName.toLowerCase()}
                        </div>
                      )}
                      {!isSourceStep && (
                        <TargetSelectionNotice
                          selected={localState.selectedId}
                          directory={directoryName === "directory"}
                        />
                      )}
                    </div>
                    <DrillDownBreadCrumbs formName={stepFormName} containerOptions={containerOptions} drillDown={drillDown}
                      data={folders} selectionOptions={{selector: setSelected, selectedId: localState.selectedId, pathPrefix: "",}}
                      scrollToTop={() => scrollToTop(scrollArea?.current)} innerColumns={innerColumns} canCreateTargetFolder={!isSourceStep}
                       resetForm={props.reset} refreshButton={step === "select" ? (
                           <RefreshButton
                            onClick={refreshStep}
                            storageObjectName={`${storageObjectName}s`}
                            disabled={isRefreshDisabled()}
                          />
                        ) : null
                      }
                      initialPath={localState.initialPath || localState.selectedId}
                      {...props} beginWithSlash={innerPathBeginWithSlash} isNewForm={true}
                    />
                  </div>
                )}

                {((retrievedAll &&
                  step === "select" &&
                  data &&
                  data.length === 0) ||
                  !data) && (
                  <div className="nothing-found">
                    <span>
                      <BucketIcon />
                    </span>
                    <p>
                      {failedRetrieve
                        ? `${failedMessage}`
                        : `${notFoundMessage}`}
                    </p>
                    <p>{bottomMessage}</p>
                  </div>
                )}

                {step === "secondStep" &&
                  getSecondStepUi(step, storageObjectPath, values, form, props, storageObjectFullPath)}
              </div>

              <ButtonsPanel
                variant="white"
                type="submit"
                text="Continue"
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default ShowObjectStorageStepTemplate;
