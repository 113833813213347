import React from 'react';
import {ReactComponent as EnvelopeIcon} from "../../../assets/svgs/envelope.svg";

require("./contactUs.scss");

const ContactUs = () =>
    (<div className="contact-us">
        <span className="float-center"><EnvelopeIcon/></span>
        <p>For more information. Please contact support.</p>
    </div>);

export default ContactUs;
