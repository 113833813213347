import React, {useState, useMemo} from "react"
import SearchWidget from "../widgets/SearchWidget"
import ViewToggleWidget from "../widgets/ViewToggleWidget"
import ItemCountWidget from "../widgets/ItemCountWidget"
import {defaultFilterWithKeys} from "../../utils/helpers"
import SelectableTable from "./SelectableTable";
import {ReactComponent as ComponentIcon} from '../../assets/svgs/component-view-icon.svg'
require('./searchableSelectableTable.scss')

const SearchableSelectableTable = (props) => {
    const {containerOptions, Table, data, menuItems, isMenuDisabled, selectionOptions, stillLoading, outsideFilter, noHeader, moreActions, refreshButton, onSelect} = props;
    const {singularTitle, pluralTitle, viewToggle, itemIdentifier, keysToSearch} = containerOptions;

    const [filter, setFilter] = useState(null);

    const filteredItems = useMemo(() => filter
        ? data.filter(item => defaultFilterWithKeys(item, filter, keysToSearch))
        : data, [filter, data, keysToSearch]);

    //in case there is an outside filter the data length is the already filtered items
    const filteredItemCount = filter ? filteredItems.length  : data.length;
    return (
        <div className="searchable-selectable-table-container">
            {!noHeader && <div className="widgets-container">
                <ItemCountWidget singularTitle={singularTitle} pluralTitle={pluralTitle}
                                 itemCount={outsideFilter?.fullLength || data.length}
                                 filteredItemCount={filteredItemCount}
                                 clearOutsideFilter={outsideFilter?.clear}

                />
                <div className="right-container">
                    <SearchWidget items={data} setFilter={setFilter}/>
                    {refreshButton && refreshButton}
                    {viewToggle ? <ViewToggleWidget toggle={viewToggle} icon={<ComponentIcon/>}/> : null}
                </div>
            </div>}
            <SelectableTable Table={Table} menuItems={menuItems} isMenuDisabled={isMenuDisabled} data={filteredItems}
                             selectionOptions={selectionOptions} itemIdentifier={itemIdentifier}
                             noAnimate={data.length > 20} moreActions={moreActions} onSelect={onSelect}/>
            {stillLoading && <div className="table-loader">
                <img src={require("./../../assets/images/table-loader.gif")} alt=""/>
            </div>}
        </div>
    )
};

export default SearchableSelectableTable;
