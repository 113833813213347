import React, { useState, useCallback } from "react";
import {
  azureSelectStep,
  showAzureBlobContainers,
  listAzureBlobContainerFolders,
  azureAdditionalStorageSettingsStep,
  clearFoldersCache,
  updateUrlParams,
  wizardStepStarted,
  wizardStepExited,
  clearUrlParamsIfChanged,
} from "../../../store/wizard/action_creators";
import { connect } from "react-redux";
import TagsPopover from "../../widgets/TagsPopover";
import ShowObjectStorageStepTemplate from "./ShowObjectStorageStepTemplateNew";
import {
  AZURE_BLOB_TIER_OPTIONS,
  DEFAULT_AZURE_BLOB_TIER,
} from "../../../consts";
import AdditionalSettingsUI from "./additionalSettings/AdditionalSettingsUINew";
import _ from "lodash";
import {getPropFromState,} from "../../../utils/mapStateToPropsUtils";
import { ReactComponent as AzureIcon } from "../../../assets/svgs/azure-no-circle.svg";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import useStepinize from "../useStepinize";

require("./showObjectStorageStepNew.scss");
const loading = () => {
  return {
    text: "Containers",
    icon: require("../../../assets/svgs/azure-default.svg"),
  };
}

const fetchData = (props) => {
  const { isTargetStepFromSameSourceType } = props;
  const localSelectedStorageAccountProp = isTargetStepFromSameSourceType
      ? props._wizard.selectedWizardParams.selectedStorageAccountNameTarget
      : props._wizard.selectedWizardParams.selectedStorageAccountName;
  return props.showAzureBlobContainers(
      localSelectedStorageAccountProp,
      isTargetStepFromSameSourceType,
      props._wizard.selectedWizardParams.selectedDataBroker.id,
  );
};

const shouldFetch = (props) => {
  const {
    _showAzureBlobContainers,
    _wizard: {
      selectedWizardParams: {
        selectedStorageAccountName,
        selectedStorageAccountNameTarget,
        selectedDataBroker,
      },
    },
    isTargetStepFromSameSourceType,
  } = props;
  const actualStorageAccountName = isTargetStepFromSameSourceType
      ? selectedStorageAccountNameTarget
      : selectedStorageAccountName;
  const azureBlobContainersForStorageAccount =
      _showAzureBlobContainers[actualStorageAccountName] &&
      _showAzureBlobContainers[actualStorageAccountName].groupId ===
      _.get(selectedDataBroker, "id")
          ? _showAzureBlobContainers[actualStorageAccountName]
          : null;
  return (
      !azureBlobContainersForStorageAccount ||
      azureBlobContainersForStorageAccount.failedRetrieve
  );
};

const stepId = ({ isTargetStepFromSameSourceType }) => {
  return isTargetStepFromSameSourceType
      ? "targetBlobContainers"
      : "blobContainers";
};

const isFetching = (props) => {
  const {
    _showAzureBlobContainers,
    _wizard: {
      selectedWizardParams: {
        selectedStorageAccountName,
        selectedStorageAccountNameTarget,
      },
    },
    isTargetStepFromSameSourceType,
  } = props;
  const actualStorageAccountName = isTargetStepFromSameSourceType
      ? selectedStorageAccountNameTarget
      : selectedStorageAccountName;
  const azureBlobContainersForStorageAccount =
      _showAzureBlobContainers[actualStorageAccountName];
  if (!azureBlobContainersForStorageAccount) return true;
  return (
      azureBlobContainersForStorageAccount.inProgress ||
      ((!azureBlobContainersForStorageAccount.data ||
          azureBlobContainersForStorageAccount.data.length === 0) &&
          !azureBlobContainersForStorageAccount.retrievedAll)
  );
};


const innerColumns = (row) => {
  const { tags } = row;
  return tags
    ? [
        <td className="path" key="path">
          <div className="text" title={row.path}>
            {row.path}
          </div>
          <div className="title">Name</div>
        </td>,
        <TagsPopover tags={tags} inTable={true} leftAdjust={-470} key="tags" />,
      ]
    : [
        <td className="path" title={row.path} key="path">
          {row.path}
        </td>,
      ];
};

const ShowAzureBlobContainersStep = (props) => {
  const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
  const {isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

  const {azureSelectStep, azureAdditionalStorageSettingsStep, listAzureBlobContainerFolders, clearFoldersCache,
        _showAzureBlobContainers, _ui: { azure }, _wizard: {selectedWizardParams: {selectedAzureBlobContainerPath,
        selectedAzureBlobContainerPathTarget, selectedBlobTier, selectedStorageAccountName,
        selectedStorageAccountNameTarget}}} = props;
  const [blobTier, setBlobTier] = useState(selectedBlobTier);
  const step =
    azure && azure.step && azure.step === "additionalSettings"
      ? "secondStep"
      : "select";
  const actualStorageAccountName = isTargetStepFromSameSourceType
    ? selectedStorageAccountNameTarget
    : selectedStorageAccountName;
  const azureBlobContainersForStorageAccount =
    _showAzureBlobContainers[actualStorageAccountName];

  const containerOptions = {
    singularTitle: "Container",
    pluralTitle: "Containers",
    itemIdentifier: "actualPath",
    rootName: "All Containers",
    targetName: "Define Target Directory",
    directoriesName: "Directories",
    directoryName: "directory",
    icon: require("../../../assets/svgs/bucket-icon.svg"),
    nameForSetupStep: "Blob Setup",
  };

  const callListAzureBlobContainerFolders = useCallback(
    (container, path, groupId) => {
      listAzureBlobContainerFolders(
          actualStorageAccountName,
          container,
          path,
          isTargetStepFromSameSourceType,
          groupId
      );
    },
    [
      isTargetStepFromSameSourceType,
      listAzureBlobContainerFolders,
      actualStorageAccountName,
    ]
  );

  const returnToBucketsStep = () => {
    setBlobTier(DEFAULT_AZURE_BLOB_TIER);
    window.setFormValue("blobTier", DEFAULT_AZURE_BLOB_TIER); //change in the form itself
    azureSelectStep();
  };

  const changeBlobTierSelection = (selectedOptions) => {
    setBlobTier(selectedOptions.value);
  };

  const getSecondStepUi = (step, bucketPath, formValues, form) => {
    if (!bucketPath) return <div />;
    return (
      <AdditionalSettingsUI
        step={step}
        bucketPath={bucketPath}
        isSourceStep={props._wizard.isSourceStep}
        handleClose={returnToBucketsStep}
        selectedOption={blobTier || DEFAULT_AZURE_BLOB_TIER}
        selectionOptions={AZURE_BLOB_TIER_OPTIONS}
        handleOptionSelection={changeBlobTierSelection}
        title="Selected Azure blob"
        icon={<AzureIcon />}
        optionsTitle="Azure Blob Tier"
        form={form}
        formValues={formValues}
      />
    );
  };

  const setAdditionalSettings = (step) => {
    if (step === "select" && !isSourceStep) {
      azureAdditionalStorageSettingsStep();
      return false;
    } else {
      return true;
    }
  };

  const stepOptions = {
    selectedBaseObject: isTargetStepFromSameSourceType
      ? selectedAzureBlobContainerPathTarget
      : selectedAzureBlobContainerPath,
    title: `Select ${isSourceStep ? "Source" : "Target"} Container`,
    failedMessage: "Unable to retrieve containers",
    notFoundMessage: "No containers were found",
    bottomMessage: "in your Azure Storage Account",
    storage: azureBlobContainersForStorageAccount,
    getStorageByBrokerId: false,
    listSubFoldersAction: callListAzureBlobContainerFolders,
    storageObjectName: "Container",
    storageObjectType: "Azure Blob",
    innerColumns,
    stepFormName: "azureBlobContainersForm",
    containerOptions,
    innerPathBeginWithSlash: false,
    getSecondStepUi: getSecondStepUi,
    updateStepInfo: setAdditionalSettings,
    currentStep: step,
    extraUrlParam: blobTier || DEFAULT_AZURE_BLOB_TIER,
    resetToFirstStepAction: azureSelectStep,
    refreshStep: () => {
      clearFoldersCache({
        cacheName: "_showAzureBlobContainers",
        storageKey: actualStorageAccountName,
      });
      fetchData(props);
    },
    initialFormValues: { blobTier },
    getSelectedParams: (bucket) => {
      const paramName = isTargetStepFromSameSourceType ? 'azureBlobContainerPathTarget' : 'azureBlobContainerPath';
      return {[paramName] : bucket}
    },
    getExtraUrlParamsNew: (formValues) =>{
      const blobTierParam = isTargetStepFromSameSourceType ? 'blobTierTarget' : 'blobTier'
      return {
        [blobTierParam]: blobTier || DEFAULT_AZURE_BLOB_TIER
      }
    },

  };

  return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ShowObjectStorageStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
    _showAzureBlobContainers: getPropFromState(
      state,
      "_showAzureBlobContainers",
      {}
    ),
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    routing: state.router,
  };
};

const mapDispatchToProps = {
  showAzureBlobContainers,
  listAzureBlobContainerFolders,
  azureSelectStep,
  azureAdditionalStorageSettingsStep,
  clearFoldersCache,
  clearUrlParamsIfChanged,
  updateUrlParams,
  wizardStepStarted,
  wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAzureBlobContainersStep);
