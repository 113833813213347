import React, { useMemo } from "react";
import { RESPONSE_STATUS_RUNNING } from '../../consts';
import { map, compact, filter } from 'lodash';
import { convertSpeed } from '../../utils/helpers';
import { ReactComponent as InfoIcon } from "./../../assets/svgs/info.svg";
import { useNavigate } from 'react-router-dom';

import "./dataBrokerGroupInfo.scss";

const DataBrokerGroupInfo = ({ group: { dataBrokers, id, name }, copyDoneByCloudProvider, relationshipStatus }) => {
    const activeDataBrokers = filter(dataBrokers, { isReady: true })
    const transferRate = useMemo(() => convertSpeed(compact(map(activeDataBrokers, 'transferRate'))), [activeDataBrokers]);

    const navigate = useNavigate();

    return (
        <div className="data-broker-row">
            <div className="data-broker-summary" title={name}>
                <button type="button"
                    className="info"
                    onClick={() => navigate({
                        pathname: `/manage/${id}`,
                        search: window.location.search
                    })}
                    key="dataBrokerGroup">
                    <InfoIcon />
                </button>
                <div className="data-broker-name">{name}</div>
            </div>
            {!copyDoneByCloudProvider && relationshipStatus === RESPONSE_STATUS_RUNNING && transferRate !== 'N/A' &&
                <div className="data-broker-transfer-rate">({transferRate})</div>
            }
        </div>
    )
};

export default DataBrokerGroupInfo;
