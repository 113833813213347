import React from "react";
import { SERVICE_NAME } from "../consts";
import { ReactComponent as OopsIcon } from "../assets/svgs/oops.svg";

require("./oops.scss");

const Oops = ({ error }) => {
    return (
        <div className="oops">
            <div className="logo">
                <img src={require('../assets/images/netapp-logo-oops.jpg')} alt="" />
            </div>
            <div>
                <span><OopsIcon /></span>
            </div>
            <h1>Oops! something went wrong</h1>

            <p>Please contact {SERVICE_NAME} support for more information about the error</p>
            <b>{`"${error}"`}</b>

            <div className="support">
                <a href={`mailto:ng-cloudsync-contact@netapp.com?subject=Cloud Sync - SSO login problem ${error}`} rel="noreferrer">Contact Support</a>
            </div>
        </div>
    )
};

export default Oops;
