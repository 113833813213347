import React from 'react';
import {
    clearShowAction,
    clearUrlParamsIfChanged,
    getServersByType,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from '../../../store/wizard/action_creators';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {InputField, TextAreaField} from "../../_common_/forms/forms";
import { END_POINT_NAMES_MAP} from '../../../consts';
import Validator from 'validatorjs';
import ServerStepTemplate from "./ServerStepTemplateNew";
import {calculateStepId} from '../../../utils/sync-utils';
import {buildServersList, getPropFromState} from '../../../utils/mapStateToPropsUtils';
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
require('./googleStepNew.scss')

const rules = {
    serverName: ['required', 'email' ],
    clientEmail: ['required', 'email'],
    privateKey: ['required'],
};

const validate = (values) => {
    const validator = new Validator(values, rules);
    validator.setAttributeNames({
        serverName: 'user email',
        clientEmail: 'service account email',
        privateKey: 'private key',
    });
    validator.passes();
    return validator.errors.all();
};

const SecondScreenUi = () => {
    return (<div className="credentials-container google">
        <div className="credentials-title">Define Google Drive Credentials:</div>
        <div className="credentials-fields">
            <div className="left pane">
                <Field name="clientEmail" component={InputField} label="Service Account Email" type="text"/>
                <Field name="privateKey" component={TextAreaField} label="Private key" type="text"/>
            </div>
        </div>
    </div>)
};

const loading = ({endpointObj}) => {
    const serverType =endpointObj.provider;
    return {
        text: `${END_POINT_NAMES_MAP[serverType.toUpperCase()]}`,
        icon: require(`../../../assets/svgs/${serverType}-default.svg`)
    }
}

const stepId = ({_wizard: {wizardInfo}, isTargetStepFromSameSourceType}) => {
    return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "gdrive");
};


const fetchData = (props) => {
    const {endpointObj, protocolAndProviderKey} = props;
    props.getServersByType(endpointObj.protocol, endpointObj.provider, protocolAndProviderKey);
};

const shouldFetch = (props) => {
    const {protocolAndProviderKey} = props;
    return (!props._servers || !props._servers[protocolAndProviderKey] || !props._servers[protocolAndProviderKey].succeeded);
};

const isFetching = (props) => {
    const {_servers, protocolAndProviderKey} = props;
    return _servers[protocolAndProviderKey]?.inProgress;
};

const GoogleStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, protocolAndProviderKey, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;
    if (!props._wizard) return <div/>;
    const {_servers, _wizard, state, clearSelectedParams} = props;
    const {selectedWizardParams} = _wizard;
     const {selectedGoogle, selectedGoogleTarget} = selectedWizardParams;

    const provider = endpointObj.provider;
    const selectedServer = isTargetStepFromSameSourceType ? selectedGoogleTarget : selectedGoogle;
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["googleFolderTarget", "googleDriveTarget"] : ["googleFolder", "googleDrive"];

    const stepOptions = {
        storage: _servers[protocolAndProviderKey],
        server: selectedServer,
        serverSVG: `${provider}-default.svg`,
        serverType: "Google",
        serverTitle: "User Email",
        selectType: "",
        serverPlaceholder: "",
        title: `Select a Google Drive ${isSourceStep ? "Source" : "Target"}`,
        changeServerTitle: "Change Google Drive Email",
        clearShowOptions: {keyToClear: ['_showGoogleDrives', selectedServer]},
        validate,
        SecondScreenUi,
        stateParamsToClear,
        shouldGoToSecondScreen: () => true,
        getSelectedParams: (host) => {
            const hostParam = isTargetStepFromSameSourceType ? 'googleTarget' : 'google';
            if ((state[hostParam] && state[hostParam] !== host)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam] : host
            }
        }
    };

    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

let BoxStepForm = reduxForm({
    form: 'googleDrive',
    validate,
    shouldError: () => true,
})(GoogleStep);


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _servers: buildServersList(state),
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _accountInfo: state.global._accountInfo,
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getServersByType,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxStepForm);


