import React, { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import SelectableTable from "./../table/SelectableTable";
import { defaultFilter } from "./../../utils/helpers"
import ItemCountWidget from "./../widgets/ItemCountWidget"
import SearchWidget from "./../widgets/SearchWidget"
import Isvg from 'react-inlinesvg';
import ScrollArea from "react-scrollbar"
import classNames from "classnames";
import CreateFolderRow from "./CreateFolderRow";
import CreateFolderRowNewForm from "./CreateFolderRowNewForm";
import { NEW_TARGET_FOLDER_ID } from "../../consts";
import { removeStartingAndEndingSlash } from "./../../utils/encoders";
import { breakFullPathToRootAndPath } from "./../../utils/sync-utils";
import useRunOnceWhenTruthy from '../../hooks/useRunOnceWhenTruthy';
import { ReactComponent as RightChevronIcon } from "./../../assets/svgs/right-chevron.svg";
import { ReactComponent as CheckmarkIcon } from "./../../assets/svgs/checkmark-fill-circle-icon.svg";
import { connect } from 'react-redux';
import { closeSearchWidget } from "../../store/wizard/action_creators";
import { FlashingDotsLoader, Typography } from '@netapp/design-system';

require("./drilldownBreadcrumbs.scss");

const DrillDownBreadCrumbs = (props) => {
    const { initialPath, rootExport, data: folders, canCreateTargetFolder, isStaticRoot, isNewForm, refreshButton, pathMask = path => path } = props;

    const [state, setState] = useState({ paths: [], currentPath: "-" });
    const [filter, setFilter] = useState(null);
    const _scrollbar = useRef();

    useRunOnceWhenTruthy(() => {
        //In cmIntegration anf (isStaticRoot), the root is decided in the anfVolume step. So we need to programmatically drill down to this vol.
        //After "continue", the rootExport is added a "/" at the beginning, so we know not to do this again (back navigation) - see CS-4719
        // console.log(`%c drill down init ${initialPath} ${rootExport}`, 'font-weight:bold;color:red');
        if (rootExport && (!initialPath || (initialPath === rootExport))) {
            // console.log(`%c calling set selected with ${rootExport}`, 'font-weight:bold;color:purple');
            // drillDown({path: `/${rootExport}`, actualPath: `/${rootExport}`});
            setSelected(`${!rootExport.startsWith("/") ? "/" : ""}${rootExport}`, true)
        } else if (initialPath && initialPath !== rootExport) {
            // console.log(`%c calling set selected with ${initialPath}`, 'font-weight:bold;color:purple');
            setSelected(initialPath, true);
        }
    }, isStaticRoot);


    useRunOnceWhenTruthy(() => {
        const { selectionOptions } = props;

        const { pathWithoutRoot, rootOfPath } = breakFullPathToRootAndPath(initialPath, rootExport);
        const trimmedRoot = removeStartingAndEndingSlash(rootOfPath);//remove first / from root path
        const paths = _.compact(pathWithoutRoot.split("/"));

        //if the path is /folder1/folder2. then currentLocation to show is folder1, and folder2 will be selected in it.
        const currentLocationFullPaths = paths.length < 1 ? paths : _.take(paths, paths.length - 1);
        const currentLocationPaths = _.concat([trimmedRoot], currentLocationFullPaths);

        //if root is /, then we shouldn't  add another / at the beginning.
        const updatedCurrentLocation = rootExport === "/" ? currentLocationPaths.join("/") : selectionOptions.pathPrefix + currentLocationPaths.join("/");

        //if root is /, then we shouldn't  add another / at the beginning.
        const updatedInitialPath = rootExport === "/" ? rootExport + initialPath : initialPath;

        // console.log(`%c DrillDown folders ${JSON.stringify(folders)}\npaths ${paths}\n currentLocation ${updatedCurrentLocation}\ninitialPath ${updatedInitialPath}\nrootExport ${rootExport}`, "font-weight: bold; color:green");
        //in case of refresh, if we are in inner folders we can't have the folders ready for us, skip selection
        if (!(folders && _.has(folders, updatedCurrentLocation))) {
            return;
        }
        const innerFolders = folders[updatedCurrentLocation].data;
        const targetFolder = !_.find(innerFolders, { actualPath: updatedInitialPath }) && initialPath !== (rootExport === "/" ? currentLocationPaths.join("/") : selectionOptions.pathPrefix + currentLocationPaths.join("/")) ? paths[paths.length - 1] : null;
        setState({ ...state, paths: currentLocationPaths, currentPath: updatedCurrentLocation, targetFolder });
        // console.log("!!!!!!!!!!!!!!!!! drillDown updating selector with ", updatedInitialPath, " and root value ", updatedCurrentLocation, " and targetFolder ", targetFolder);
        props.selectionOptions.selector({ value: updatedInitialPath, rootValue: rootExport });

    }, initialPath);

    useEffect(() => {
        setTimeout(() => {
            if (_scrollbar.current) {
                _scrollbar.current.scrollArea.refresh();
                _scrollbar.current.scrollRight();
            }
        }, 200);
    }, [state.currentPath]);

    const getRootPath = (currentPath) => {
        const { selectionOptions: { pathPrefix } } = props;
        return state.paths.length > 0 ? pathPrefix + state.paths[0] : currentPath;
    };

    const drillDown = (row) => {
        const { selectionOptions: { pathPrefix } } = props;
        const path = row.path;
        const pathNoSlash = removeStartingAndEndingSlash(path);
        const initialPathForDrillDown = state.paths.length > 0 ? pathPrefix + state.paths[0] : row.actualPath;
        // console.log(`%c DrillDownBreadCrumbs root ${initialPathForDrillDown} path (${row.actualPath})`, "font-weight: bold;");
        props.drillDown(row.actualPath, initialPathForDrillDown);
        setState({
            ...state,
            paths: state.paths.concat(pathNoSlash),
            currentPath: row.actualPath
        });
        // if not selected - select row path
        if (props.selectionOptions.selectedId !== row.actualPath) {
            props.selectionOptions.selector({ value: row.actualPath, rootValue: getRootPath(row.actualPath) });
        }
        props.closeSearchWidget();
        props.scrollToTop();
    };

    const setSelected = (id) => {
        // if already selected - un-select
        //removed the unselect since when going back and forward we don't want to unselect and we don't know how to differ
        //see jira CS-6295 UI: the ANF source share is not saved in the wizard
        // console.log(`%c drill down setting selected ${id} ${getRootPath(id)}`, 'font-weight:bold;color:red');
        props.selectionOptions.selector({ value: id, rootValue: getRootPath(id) });
    };

    const renderRow = (innerColumns, row) => {
        return (
            <div>
                <td className="check"><span><CheckmarkIcon /></span>
                </td>
                {innerColumns && innerColumns(row).map((td, key) => React.cloneElement(td, { key }))}
                <td className="dive">
                    <button type="button" onClick={(e) => {
                        drillDown(row);
                        e.stopPropagation();
                    }}><span><RightChevronIcon /></span></button>
                </td>
            </div>
        )
    };

    const goBackTo = (index) => {
        const { selectionOptions } = props;
        let newPathsArray = _.take(state.paths, index);
        const newPath = newPathsArray.length > 0 ? (newPathsArray[0] === "/" ? "" : selectionOptions.pathPrefix) + newPathsArray.join("/") : "-";
        const newState = { ...state, paths: newPathsArray, currentPath: newPath };
        const rootPath = getRootPath(newPath);
        // console.log(`%c DrillDownBreadCrumbs goBackTo root @ ${rootPath} newPath ${newPath} paths ${newPathsArray}`, "font-weight: bold;");
        setState(newState);
        props.selectionOptions.selector(newPath === "-" ? { value: null, rootValue: null } : {
            value: newPath,
            rootValue: rootPath
        });
    };


    const { paths, targetFolder } = state;
    //isStaticRoot: In cmIntegration anf, the root is decided in the anfVolume step.
    //We want to block the option to show all the volumes in that specific ip, and to show the ip instead of "all directories" text.
    const {
        containerOptions: { itemIdentifier, rootName, targetName, icon, directoriesName, useIndexForKey },
        innerColumns, dispatch, formName, isSourceStep, isTargetStepFromSameSourceType, _wizard: { selectedWizardParams: { selectedNFS, selectedNFSTarget, selectedCIFS, selectedCIFSTarget } }
    } = props;
    const currentPath = state.currentPath === "//" ? "/" : state.currentPath;
    let foldersList = folders[currentPath];
    const { data, retrievedAll } = foldersList || {};
    const filteredItems = filter ? data?.filter(item => defaultFilter(item, filter)) : data || [];
    const selectionOptions = { ...props.selectionOptions, selector: setSelected };
    const selectedNewTargetFolder = selectionOptions.selectedId?.endsWith(NEW_TARGET_FOLDER_ID);
    const hasSelection = data?.find(item => item[itemIdentifier] === selectionOptions.selectedId) || selectedNewTargetFolder;

    return (<div className={classNames("drilldown-breadcrumbs", { "has-selection": hasSelection })}>
        <div className="search-row">
            <div className="item-count">
                <Isvg className="icon" src={icon?.default || icon} />
                <ItemCountWidget itemCount={data ? data.length : 0}
                    filteredItemCount={filteredItems?.length} />
                {!retrievedAll && <div className='loadingDots'>
                    <FlashingDotsLoader className='dotsLoader' />
                    <Typography variant='Regular_14'>Loading</Typography>
                </div>}
            </div>
            <div className="search-and-refresh">
                <SearchWidget setFilter={setFilter} {...props} />
                {refreshButton && refreshButton}
            </div>
        </div>
        <div className="breadcrumbs-row clearfix">
            <ScrollArea
                style={{ width: "100%", height: "100%" }}
                className="breadcrumb-scroll"
                contentClassName="breadbox"
                smoothScrolling={true}
                vertical={false}
                minScrollSize={40}
                ref={e => _scrollbar.current = e}
            >
                <span className="path">
                    <button type="button" className="name"
                        onClick={() => goBackTo(0)}
                        disabled={paths.length === 0 || isStaticRoot}>
                        {isStaticRoot ? (isTargetStepFromSameSourceType ? (isSourceStep ? (selectedNFS || selectedCIFS) : (selectedNFSTarget || selectedCIFSTarget)) : (selectedNFS || selectedCIFS)) : rootName}
                    </button>
                </span>

                {paths.map((path, index) => (
                    <span className="path" key={`path-${index}`}>
                        <span className="chevron"><RightChevronIcon /></span>
                        <span className="check"><CheckmarkIcon /></span>
                        <span className="name"><button type="button" onClick={() => {
                            goBackTo(index + 1)
                        }} disabled={index === paths.length - 1}>{path ? pathMask(path) : "/"}</button></span>
                    </span>
                ))}
            </ScrollArea>
        </div>
        <div>
            {state.currentPath !== "-" && canCreateTargetFolder && !isNewForm &&
                <CreateFolderRow itemIdentifier={itemIdentifier} selectionOptions={selectionOptions}
                    currentPath={state.currentPath} resetForm={props.resetForm}
                    targetPlaceholder={targetName} initialTargetFolder={targetFolder} dispatch={dispatch}
                    formName={formName} />}
            {state.currentPath !== "-" && canCreateTargetFolder && isNewForm &&
                <CreateFolderRowNewForm itemIdentifier={itemIdentifier} selectionOptions={selectionOptions}
                    currentPath={state.currentPath} resetForm={props.resetForm}
                    targetPlaceholder={targetName} initialTargetFolder={targetFolder} dispatch={dispatch}
                    formName={formName} />}

            {(filteredItems.length > 0 || !retrievedAll) &&
                <SelectableTable
                    noAnimate={true}
                    Table={{ renderRow: renderRow.bind(this, innerColumns) }}
                    data={filteredItems}
                    itemIdentifier={itemIdentifier}
                    selectionOptions={selectionOptions}
                    useIndexForKey={useIndexForKey}
                />}

            {!retrievedAll && <div className="table-loader">
                <img src={require("./../../assets/images/table-loader.gif")} alt="" />
            </div>}

            {(filteredItems.length === 0 && retrievedAll) &&
                <div className="folder-empty">
                    <div><span><Isvg src={icon?.default || icon} /></span></div>
                    No {directoriesName} Found
                </div>}
        </div>
    </div>)
};

export default connect(null, { closeSearchWidget })(DrillDownBreadCrumbs);
