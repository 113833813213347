import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useInterval} from '../../hooks/useInterval';
import {v4 as uuidv4} from 'uuid';
import {setDeploymentStatus} from '../../store/deploy/deploySlice';
import * as awsService from '../../services/aws.api.service';
import {DEPLOY_STATUS_ERROR, DEPLOY_STATUS_PROGRESS, DEPLOY_STATUS_SUCCESS} from '../../consts';
import {FM_AGENT_TYPE_AWS, FM_AGENT_TYPE_AZURE, FM_AGENT_TYPE_GCP} from '../../consts';
import * as azureService from '../../services/azure.api.service';
import * as gcpService from '../../services/gcp.api.service';
import {extractErrorAzure, extractErrorGcp, extractErrorAws} from "../../utils/providers-utils";
import { deploySelector } from '../../store/selectors/deploy.selector';

const POLLING_INTERVAL = 10000;

//This component is in charge of keeping the data broker deployments status up to date, no matter where the user navigates in the app
const DeploymentsTracker = ({setDeploymentStatus}) => {

    //each deployment has a separate poller, that works as long as the status is "in progress"
    //it should stop polling when the status is error/success

    const deployments = useSelector(deploySelector.deployments);

    return deployments ? deployments.map(deployment => <Tracker
        key={deployment.dataBrokerId}
        dataBrokerId={deployment.dataBrokerId}
        providerType={deployment.providerType}
        deployDetails={deployment.deployDetails}
        setDeploymentStatus={setDeploymentStatus}
    />) : null;
};

export default DeploymentsTracker;

const Tracker = ({providerType, deployDetails, dataBrokerId}) => {
    const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);

    const dispatch = useDispatch();
    useInterval(async () => {
        let pollingResponse = {
            status: null,
            errorMessage: null
        };
        try {
            if (providerType === FM_AGENT_TYPE_AWS.name) {
                pollingResponse = await getDeploymentStatusAws(deployDetails);
            }
            if (providerType === FM_AGENT_TYPE_AZURE.name) {
                pollingResponse = await getDeploymentStatusAzure(deployDetails);
            }
            if (providerType === FM_AGENT_TYPE_GCP.name) {
                pollingResponse = await getDeploymentStatusGcp(deployDetails);
            }
        } catch (error) {
            pollingResponse = {
                status: DEPLOY_STATUS_ERROR,
                errorMessage: error?.message
            }
        }
        if (pollingResponse.status !== DEPLOY_STATUS_PROGRESS) {

            dispatch(setDeploymentStatus({
                dataBrokerId: dataBrokerId,
                deployStatus: pollingResponse.status,
                errorMessage: pollingResponse.errorMessage
            }));
            setPollingInterval(null);

            if (providerType === FM_AGENT_TYPE_AZURE.name && deployDetails.createRole){ // TODO add a condition for create role
                await createAzureRoleAndAssign(deployDetails);
            }

        }
    }, pollingInterval);

    return <div/>;
};

const getDeploymentStatusAws = async ({credentials, region, stackId}) => {

    try {
        const res = await awsService.getDeploymentStatus(credentials, region, stackId);
        const {StackStatus, StackStatusReason} = res.Stacks[0];
        // console.log(`>>> got stack status ${StackStatus}`);
        if (StackStatus === 'CREATE_FAILED'
            || StackStatus === 'ROLLBACK_COMPLETE'
            || StackStatus === 'ROLLBACK_FAILED'
            || StackStatus === 'ROLLBACK_IN_PROGRESS') {
            return {
                status: DEPLOY_STATUS_ERROR,
                errorMessage: extractErrorAws(StackStatusReason)
            };
        }
        if (StackStatus === 'CREATE_COMPLETE') {
            return {
                status: DEPLOY_STATUS_SUCCESS,
                errorMessage: null
            };
        }
        return {
            status: DEPLOY_STATUS_PROGRESS,
            errorMessage: null
        };

    } catch (error) {
        throw new Error(extractErrorAws(error));
    }
};

const getDeploymentStatusAzure = async ({subscriptionId, resourceGroupName, deploymentName}) => {

    try {
        const res = await azureService.getDeploymentStatus(subscriptionId, resourceGroupName, deploymentName);
        const status = res.properties.provisioningState;
        if (status === 'Failed') {
            return {
                status: DEPLOY_STATUS_ERROR,
                errorMessage: extractErrorAzure(res.properties.error)
            };
        }
        if (status === 'Canceled' || status === 'Deleting') {
            return {
                status: DEPLOY_STATUS_ERROR,
                errorMessage: "The deployment was canceled or deleted. You’ll need to try again"
            };
        }
        if (status === 'Succeeded') {
            return {
                status: DEPLOY_STATUS_SUCCESS,
                errorMessage: null
            };
        }
        return {
            status: DEPLOY_STATUS_PROGRESS,
            errorMessage: null
        };

    } catch (error) {
        const errorMsg = extractErrorAzure(error);
        throw new Error(errorMsg);
    }
};

const getDeploymentStatusGcp = async ({projectId, operationName}) => {

    try {
        const statusResponse = await gcpService.getOperationStatus(
            projectId,
            operationName
        );
        if (statusResponse.error) {
            return {
                status: DEPLOY_STATUS_ERROR,
                errorMessage: extractErrorGcp(statusResponse.error)
            };
        }
        if (statusResponse.status === 'DONE') {
            return {
                status: DEPLOY_STATUS_SUCCESS,
                errorMessage: null
            };
        }
        return {
            status: DEPLOY_STATUS_PROGRESS,
            errorMessage: null
        };
    }
    catch (error) {
        const errorMsg = extractErrorGcp(error.error);
        throw new Error(errorMsg);
    }
};

const createAzureRoleAndAssign = async ({subscriptionId, resourceGroupName,properties}) => {
    const vmName = properties.parameters.virtualMachineName.value;

    const vmData = await azureService.getVm(subscriptionId, resourceGroupName, vmName);

    const principalId = vmData.identity.principalId;

    const roleId = uuidv4();
    const roleAssignmentId = uuidv4();
    const role = await azureService.createRoleDefinition(subscriptionId,roleId);

    await azureService.assignRole(subscriptionId, roleAssignmentId, role.id, principalId)
}
