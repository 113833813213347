import React, {useCallback, useEffect, useState} from 'react';
import Validator from 'validatorjs';
import {Field} from 'react-final-form';
import {InputField} from "../_common_/forms/forms";
import {moveDataBroker} from "../../store/wizard/action_creators";
import CardsContainer from "../cards/CardsContainer";
import {FM_AGENT} from "../../consts";
import {ReactComponent as DataBrokerGroupIcon} from "../../assets/svgs/move-data-broker-group-icon.svg";
import {ReactComponent as PencilIcon} from "../../assets/svgs/pencil-icon.svg";
import DoubleLineItem from "../_common_/DoubleLineItem";
import {find, without, isEmpty} from "lodash";
import {useDispatch} from 'react-redux';
import pluralize from 'pluralize'
import "./moveDataBrokerDialog.scss"
import ConfirmFormDialogNew from "../dialog/ConfirmFormDialogNew";
import { SubmitErrorsSpy } from 'final-form-submit-errors';


const validate = (values) => {
    const rules = {};
    const validator = new Validator(values, rules);
    validator.passes();
    return validator.errors.all();
};


const MoveDataBrokerDialog = (props) => {
    const {dataBrokersGroups, data} = props;
    const {id: dataBrokerId, name, groupId} = data || {};
    const dispatch = useDispatch();
    const dataBrokerGroup = find(dataBrokersGroups, {id: groupId})
    const groupsWithoutDataBrokerGroup = without(dataBrokersGroups, dataBrokerGroup).filter((group) => isEmpty(group.encryptionType));
    const title = name && dataBrokerGroup ? `Move Data Broker "${name}" from "${dataBrokerGroup.name}" Group` : 'Move Data Broker';

    const [newGroupSelected, setNewGroupSelected] = useState(false);

    const [selectedId, setSelectedId] = useState(() => {
        if (groupsWithoutDataBrokerGroup.length === 1) {
            return groupsWithoutDataBrokerGroup[0].id
        }
        return null;
    });

    const setSelectedDataBrokerGroup = useCallback((id) => {
        if (selectedId !== id) {
            setSelectedId(id);
            setNewGroupSelected(false);
        }
    }, [selectedId, setNewGroupSelected]);

    const handleSubmit = (values) => {
        const {newGroupName} = values;
        if (!selectedId && !newGroupSelected) {
            throw new Error("Please select a target Data Broker Group")
        }
        else if (newGroupSelected && isEmpty(newGroupName)){
            throw new Error("Please define a name to the new Data Broker Group");
        }
        else {
            const data = {};
            if (newGroupSelected) {
                data.targetGroupName = newGroupName;
            }
            else {
                data.targetGroupId = selectedId;
            }
            return moveDataBroker(dataBrokerId, data)(dispatch)
        }
    }

    const handleNewGroupSelected = () => {
        setNewGroupSelected(true);
        setSelectedId(null);
    }


    return (<ConfirmFormDialogNew
            title={title}
            confirmText="Move"
            layoutClassName="move-data-broker-dialog"
            extractError={(error) => {
                return error?.message || error?.data?.message;
            }}
            activateSubmit={handleSubmit}
            validate={validate}
            {...props}
        >
            <div className="move-data-broker-groups">
                <div className="pane left">
                    <div className="title">Select a Group to move Data Broker To:</div>
                    <div><b>Notice: </b>Only valid groups for this data broker appear in the list.</div>
                </div>
                <div className="pane right">
                    <div className={`new-group ${newGroupSelected ? 'selected' : ""}`}>
                        <NewGroup handleNewGroupSelected={handleNewGroupSelected} newGroupSelected={newGroupSelected}/>
                    </div>
                    {groupsWithoutDataBrokerGroup &&
                    <CardsContainer containerOptions={{singularTitle: FM_AGENT, pluralTitle: FM_AGENT + 's'}}
                                    selectionOptions={{selector: setSelectedDataBrokerGroup, selectedId}}
                                    className="groups-list" customClass="in-dialog"
                                    Card={{renderContent}} data={groupsWithoutDataBrokerGroup}/>
                    }
                    <SubmitErrorsSpy/>
                </div>

            </div>
        </ConfirmFormDialogNew>
    );
}

const renderContent = ({data}) => {
    return <GroupInfo group={data}/>
}

const GroupInfo = ({group}) => {
    return <div className="move-data-broker-group-card">
        {group ? <div className="group-icon"><DataBrokerGroupIcon/></div> : ""}
        <div className="broker-details">
            <DoubleLineItem title={`${group.dataBrokers.length} ${pluralize("Data Broker", group.dataBrokers.length)} | ${group.relationships.length} ${pluralize("Relationship",group.relationships.length)}`} value={group.name}/>
        </div>
    </div>

}

const NewGroup = ({handleNewGroupSelected, newGroupSelected}) => {
    const [editMode, setEditMode] = useState(false);
    useEffect(()=> {
        setEditMode(newGroupSelected)
    }, [newGroupSelected])

    return <div className={`move-data-broker-group-card new-group-card ${editMode ? 'edit' : ''}`} onClick={() => {
        // setEditMode(true);
        handleNewGroupSelected()
    }}>
        <div className="group-icon"><DataBrokerGroupIcon/></div>
        <div className="broker-details">
            {!editMode &&  <div className="new-data-broker-group-container">
                <DoubleLineItem title="" value="New Data Broker Group"/>
                <PencilIcon/>
            </div>}
            {editMode && <Field component={InputField} name="newGroupName"
                   placeholder={"Name your new group"} label="New Data Broker Group Name"
                   type="text"/>}
        </div>
    </div>

}

export default MoveDataBrokerDialog;
