import {setAuthHeader} from '../sdk';
import {setUserInfoSuccess, getAccounts, bootAnalytics} from '../store/global/action_creators';
import {store} from "../store/store";
import { setAccessTokenAndMetaData } from '../syncNew/store/slices/auth.slice';

export const handleAuthResult = ({accessToken, userMetadata, marketPlaceToken, marketPlaceProvider, fromCM}) => {
    const loginToken = `Bearer ${accessToken}`;
    // eslint-disable-next-line no-console
    console.log(`>>>>>>>>>>>>>>> handleAuthResult token:\n${loginToken}\n>>>>>>>>>>>>>>>>>>>>`);
    window.localStorage.auth0LoginToken = loginToken;
    setAuthHeader({authorization: loginToken});
    const {dispatch} = store;
    dispatch(setUserInfoSuccess({accessToken: loginToken, userMetadata}));
    dispatch(setAccessTokenAndMetaData({
        accessToken,
        userMetadata
    }));
    if (!fromCM) { //if it is a refresh token from CM no need to do the following
        dispatch(getAccounts(loginToken, marketPlaceToken ? marketPlaceToken : undefined, marketPlaceProvider ? marketPlaceProvider : undefined)); //this needs the user token so must be after login
        dispatch(bootAnalytics());
    }
};

export const btoaEncoded = (value) => {
    return window.btoa(unescape(encodeURIComponent(value)));
}
