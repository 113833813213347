import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/wizard/action_creators';
import { clearNotifications } from '../store/global/action_creators';
import { buildUrlParams } from "../utils/encoders";
import { getPropFromState } from "../utils/mapStateToPropsUtils";
import useRunOnce from '../hooks/useRunOnce';
import { ROUTES } from '../consts';
import { routeFromPath, rootPath } from '../utils/helpers';
import { theme } from '../syncNew/utils/domUtils';
import { useParams, useLocation, useNavigate } from 'react-router-dom';


export const routeToNewPathName = ({ pathname, relationshipId, viewDashboard, fromCanvas }, navigate, pathnameFromLocation, clearNotifications = () => null) => {
    const currentPath = pathname ? routeFromPath(pathname) : ROUTES.SYNC;
    const basePath = rootPath(pathnameFromLocation);
    if (currentPath !== ROUTES.SYNC && currentPath !== relationshipId) {
        if (currentPath !== basePath) {
            navigate({
                pathname: `/${currentPath}`,
                search: window.location.search
            });
            clearNotifications();
        }
    } else {
        if (!fromCanvas && viewDashboard) {
            const path = Object.values(ROUTES).includes(basePath) ? basePath : ROUTES.DASHBOARD;
            navigate({
                pathname: relationshipId ? `/${ROUTES.DASHBOARD}/${relationshipId}` : `/${path}`,
                search: window.location.search
            });
        }
        else {
            navigate({
                pathname: `/${ROUTES.DRAG_SOURCE_AND_TARGET}`,
                search: window.location.search
            });
        }

        clearNotifications()
    }
};

const ContentWrapper = (props) => {
    const { _routing, updateUrlParams, _ui, showCmTabs, _cmIntegration, clearNotifications,
        ContentHeader, Content, PrimaryHeader, contentKey, contentUiState, hideMainHeader } = props;
    const { general: { routingParams } } = _cmIntegration;
    const { blocked: isUiBlocked } = _ui;

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.ga && window.ga('send', 'pageview', location.pathname);
    }, [location])

    useRunOnce(() => {
        const urlParams = buildUrlParams(_routing, { params }, location, window.location.href);
        updateUrlParams(urlParams);
    });

    useEffect(() => {
        if (routingParams) clearNotifications();
    }, [routingParams, clearNotifications, navigate])

    useEffect(() => {
        if (_routing?.href !== window.location.href) {
            const urlParams = buildUrlParams(_routing, { params }, location, window.location.href);
            updateUrlParams(urlParams);
        }
    }, [_routing, params, location, updateUrlParams]);

    useEffect(() => {
        //enforcement - when UI is blocked - don't allow any path but License Settings
        if (!isUiBlocked) return;
        const currentPath = window.location.pathname;
        const isRoutedToLicenseScreen = currentPath === "/license" || currentPath === '/manage';
        if (isUiBlocked && !isRoutedToLicenseScreen) {
            navigate({
                pathname: '/license',
                search: window.location.search
            });
        }
    }, [isUiBlocked, _routing, navigate]);

    let uiState = contentUiState;
    const component = {
        content: <Content {...props} />,
        primaryHeader: PrimaryHeader ? <PrimaryHeader {...props} /> : undefined,
        header: ContentHeader ? <ContentHeader {...props} /> : undefined,
        key: contentKey
    };

    if (hideMainHeader) {
        uiState = uiState + " no-portal-widget";
    }

    uiState = uiState + ` cm-integration ${theme}-theme`;

    //TODO improve
    document.body.className = uiState;

    return (
        <div className="content-wrapper">

            <section id="primary">
                {component.header &&
                    <div className="secondary-shadow">
                        <header className="secondary">
                            <ContentHeader showCmTabs={showCmTabs} />
                        </header>
                    </div>}


                <section className="content">
                    {component.content}
                </section>
            </section>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    auth: state.global.auth || { succeeded: false, inProgress: true, failed: false }, //for the footer
    _routing: getPropFromState(state, "_routing", {}),
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    _licenseSettings: state.global._licenseSettings, //for the footer,
    _wizard: getPropFromState(state, "_wizard", {}),
    _cmIntegration: state.global._cmIntegration,
    ...ownProps
});

export default connect(mapStateToProps, { ...actionCreators, clearNotifications })(ContentWrapper);
