import React from 'react'
import classNames from "classnames";
import {ReactComponent as RefreshIcon} from "./../../assets/svgs/refresh.svg";


require("./refreshButton.scss");

const RefreshButton = ({onClick, disabled, storageObjectName}) => {
    const refreshButtonClassNames = classNames('refresh-button', {'disabled': disabled});

    return <div className="refresh-button-container" title={disabled ? 'Please refresh from the source step' : `Refresh ${storageObjectName}`}>
        <button type="button" className={refreshButtonClassNames} onClick={onClick} disabled={disabled}>
            <RefreshIcon/>
        </button>
    </div>
};

export default RefreshButton;