import React from "react";
import classNames from "classnames";
import {DEPLOY_STATUS_ERROR, DEPLOY_STATUS_PROGRESS, DEPLOY_STATUS_SUCCESS} from '../../../consts';
import {TooltipInfo} from '@netapp/shared-components';
import ReactLoading from 'react-loading';
import {ReactComponent as GreenCheckMark} from '../../../assets/svgs/checkmark-green.svg'
import {ReactComponent as FailedIcon} from '../../../assets/svgs/failed-icon.svg'
import {ReactComponent as ErrorIcon} from '../../../assets/svgs/error-icon.svg'
import {ReactComponent as QuestionMarkIcon} from '../../../assets/svgs/question-mark-icon.svg'
import {ReactComponent as WarningIcon} from '../../../assets/svgs/notification-icon-warning.svg'
import "./brokerStatusDisplay.scss";

const BrokerStatusDisplay = ({isFailed, isDisabled, isWaiting, deployment, agentFailureMessage, disabledErrorMessage, count, inWizard}) => {
    const status = getStatus({isFailed, isDisabled, isWaiting, deployment, agentFailureMessage, disabledErrorMessage});
    return (<div className={classNames("broker-status-display", status.type.toLowerCase())} >
        <div className="icon">{status.icon}</div>
        <div className="text">{ `${count ? `${count} `: ''} ${status.text}`}</div>
        {status.errorMessageText && (!count || inWizard) && <TooltipInfo>
            {status.errorMessageText}
        </TooltipInfo>}
    </div>)
};
export default BrokerStatusDisplay;

const getStatus = ({isFailed, isDisabled, isWaiting, deployment, agentFailureMessage, disabledErrorMessage}) => {
    const isError       = isFailed;
    const isDeploying   = !isDisabled && isWaiting && deployment && (deployment.deployStatus === DEPLOY_STATUS_PROGRESS || deployment.deployStatus === DEPLOY_STATUS_SUCCESS);
    const isDeployError = !isDisabled && isWaiting && deployment && deployment.deployStatus === DEPLOY_STATUS_ERROR;
    const isUnknown     = !isDisabled && isWaiting && !deployment;

    let statusType;
    if (isDisabled) statusType = "disabled";
    else if (isError || isDeployError)  statusType = DEPLOY_STATUS_ERROR; //in wizard we want the disabled to be shown
    else if (isDeploying) statusType = DEPLOY_STATUS_PROGRESS;
    else if (isUnknown) statusType = "unknown";
    else statusType = DEPLOY_STATUS_SUCCESS;

    switch (statusType) {
        case DEPLOY_STATUS_ERROR: {
            return {
                type: statusType,
                text: isFailed ? "Failed" : "Error",
                icon: isFailed || isDisabled ? <FailedIcon/> : <ErrorIcon/>,
                errorMessageText: !isWaiting ? agentFailureMessage || disabledErrorMessage || '' : deployment.errorMessage || ''
            }
        }
        case "disabled": {
            return {
                type: statusType,
                text: "Not Compatible",
                icon: <WarningIcon/>,
                errorMessageText: disabledErrorMessage
            }
        }
        case DEPLOY_STATUS_PROGRESS: {
            return {
                type: statusType,
                text: "Deploying",
                icon: <ReactLoading type="spin" color="#1fa0ff" height={0} width={20} delay={50} />
            }
        }

        case DEPLOY_STATUS_SUCCESS: {
            return {
                type: statusType,
                text: "Active",
                icon: <GreenCheckMark/>
            }
        }
        case "unknown":
        default: {
            return {
                type: statusType,
                text: "Unknown",
                icon: <QuestionMarkIcon/>,
                errorMessageText: "The data broker hasn’t communicated its status to Copy & sync yet."

            }
        }
    }
};
