import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { calculateLicenseStatusFooter } from '../../utils/license-utils';
import "./enforcementButton.scss";
import { useNavigate } from 'react-router-dom';

const EnforcementButton = ({ auth, _licenseSettings }) => {
    const navigate = useNavigate();

    const { licenseStatusText, warning, error, title } = calculateLicenseStatusFooter({ auth, _licenseSettings });
    if (licenseStatusText === "ACTIVE SUBSCRIPTION") return null;

    return (
        <div className={classNames("enforcement-button", { warning, error })}>
            <button type="button" onClick={() => {
                navigate({
                    pathname: '/license',
                    search: window.location.search
                })
            }}
                title={title}>
                {licenseStatusText}
            </button>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.global.auth,
    _licenseSettings: state.global._licenseSettings
});

export default connect(mapStateToProps)(EnforcementButton);