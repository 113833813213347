import { Encryption, Gcp, KmsKey, SyncState } from "../store.types";
import rootSelector from "./root.selector";

const getWizardtate = rootSelector.wizard;

export const wizardSelector = {
    targetGcp(state: SyncState): Gcp {
        const { target } = getWizardtate(state).step; 
        return ((target || {}) as Gcp);
    },
    targetGcpBucket(state: SyncState) {
        return wizardSelector.targetGcp(state).bucket || {}; 
    },
    targetGcpSelectedEncryption(state: SyncState): Encryption | undefined {
        const { encryption } = wizardSelector.targetGcpBucket(state) || {};
        return encryption;
    },
    targetGcpEncryptOptions(state: SyncState, bucket: string): KmsKey | undefined {
        if (!bucket) return undefined;

        const { encryptOptions } = wizardSelector.targetGcp(state);
        const kmsKyeObj = (encryptOptions || {})[bucket];

        return (kmsKyeObj || {}).kmskey;
    }
}