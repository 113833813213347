import sdk from "../sdk";
import {
    startup,
    updateBoxFolders,
    updateBoxRootFolders,
    updateBuckets,
    updateBucketsFolders,
    updateContainers,
    updateContainersFolders,
    updateDataBrokerLogs,
    updateDataBrokerStatus,
    updateDatabrokerTransferRate,
    updateExports,
    updateFolders,
    updateGCPBucketFolders,
    updateGCPBuckets,
    updateKmsKeys,
    updateRelationship,
    updateReport,
    updateS3BasedBuckets,
    updateS3BasedBucketsFolders,
    updateS3PrivateLinks,
    updateSftpDirectories,
    updateSftpRootDirectory,
    updateShareFolders,
    updateShares,
    updateDataLakeContainers,
    updateDataLakeContainerFolders, updateGoogleDrives, updateGoogleDriveFolders, updateDataBrokerConfiguration
} from "../store/wizard/action_creators";
import * as actionCreatorsConsts from "../store/global/action_creators_consts";
import * as consts from "../consts";
import {
    clearCurrentAccountData,
    getAccountInfo,
    updateAccountInfo,
    updateSubscriptionStatus
} from "../store/global/action_creators";
import { setTargetGcpEncryptOptions } from "../syncNew/store/slices/wizard.slice";

let lastMessage;

export function messagesPoller() {
    return (dispatch) => {
        return sdk.messages(lastMessage)
            .then(r => {
                handleMessages(dispatch, r);
                dispatch(messagesPoller());
            })
            .catch(e => {
                //don't send error message for unauthorized response on get messages, to avoid showing it in the general error panel
                if (e && e.status !== 401) {
                    dispatch({
                        type: actionCreatorsConsts.MESSAGES,
                        status: actionCreatorsConsts.STATUS_ERROR,
                        payload: e
                    });
                }
                sleep(5000).then(()=>{dispatch(messagesPoller())});
            })
    }
}

export const setActiveAccount = ({accountId}) => {
    return dispatch => {
        dispatch(clearCurrentAccountData()); //In order to prevent "old" data from being rendered until the new data arrives
        dispatch(getAccountInfo(accountId))
            .then(() => {
                startup(dispatch);
                if (!lastMessage) dispatch(messagesPoller()); //start polling only the first time an account is selected
            })
            .catch(e => {
                //don't send error message for unauthorized response on get messages, to avoid showing it in the general error panel
                console.log(`%c got error on getAccountInfo ${JSON.stringify(e, null, 2)}`, 'font-weight:bold; color:red');
                if (e?.status !== 401) {
                    dispatch({
                        type: actionCreatorsConsts.MESSAGES,
                        status: actionCreatorsConsts.STATUS_ERROR,
                        payload: e
                    });
                }
            });
    }
};

export function handleMessages(dispatch, messages) {
    messages.forEach(message => {
        lastMessage = message.id;
        message.payload.requestId = message.header.requestId;
        const groupId = message.header.groupId;
        if (message.payload[consts.PAYLOAD_DATA_BROKER_STATUS]) {
            dispatch(updateDataBrokerStatus(message.payload));
        } else if (message.payload[consts.PAYLOAD_SHOW_EXPORTS]) { //list exports
            dispatch(updateExports({...message.payload, dataBrokerId: message.header.agentId, groupId}));
        } else if (message.payload[consts.PAYLOAD_LIST_CIFS_SHARES]) { //list cifs share
            dispatch(updateShares({...message.payload, dataBrokerId: message.header.agentId, groupId}));
        } else if (message.payload[consts.PAYLOAD_LIST_FOLDERS]) { //list sub folders of export
            dispatch(updateFolders({...message.payload, dataBrokerId: message.header.agentId, groupId}));
        } else if (message.payload[consts.PAYLOAD_LIST_CIFS_SHARE_FOLDERS]) { //list sub folders of shares
            dispatch(updateShareFolders({...message.payload, dataBrokerId: message.header.agentId, groupId}));
        } else if (message.payload[consts.PAYLOAD_LIST_BUCKETS]) { //buckets
            message.payload.dataBrokerId  = message.header.agentId;
            message.payload.groupId = groupId;
            dispatch(updateBuckets(message.payload)); //sub directories of buckets
        } else if (message.payload[consts.PAYLOAD_LIST_SUB_DIRECTORIES]) {
            message.payload.dataBrokerId  = message.header.agentId;
            message.payload.groupId = groupId;
            dispatch(updateBucketsFolders(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_CONTAINERS]) { //azure blob containers
            message.payload.dataBrokerId  = message.header.agentId;
            message.payload.groupId = groupId;
            dispatch(updateContainers(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_CONTAINER_SUB_DIRECTORIES]) { //sub directories of containers
            message.payload.dataBrokerId  = message.header.agentId;
            message.payload.groupId = groupId;
            dispatch(updateContainersFolders(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_SGWS_BUCKETS]) { //sgws buckets
            dispatch(updateS3BasedBuckets(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_IBM_BUCKETS]) { //ibm buckets
            dispatch(updateS3BasedBuckets(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_ONTAP_BUCKETS]) { //sgws buckets
            dispatch(updateS3BasedBuckets(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_SGWS_BUCKETS_FOLDERS]) { //sub directories of sgws buckets
            dispatch(updateS3BasedBucketsFolders(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_IBM_BUCKETS_FOLDERS]) { //sub directories of sgws buckets
            dispatch(updateS3BasedBucketsFolders(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_ONTAP_BUCKETS_FOLDERS]) { //sub directories of ibm buckets
            dispatch(updateS3BasedBucketsFolders(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_GCP_BUCKETS]) { //gcp buckets
            message.payload.dataBrokerId  = message.header.agentId;
            message.payload.groupId = groupId;
            dispatch(updateGCPBuckets(message.payload));
        } else if (message.payload[consts.PAYLOAD_LIST_GCP_BUCKET_FOLDERS]) { //sub directories of gcp buckets
            message.payload.dataBrokerId  = message.header.agentId;
            message.payload.groupId = groupId;
            dispatch(updateGCPBucketFolders(message.payload));
        } else if (message.payload[consts.PAYLOAD_UPDATE_RELATIONSHIP]) {
            dispatch(updateRelationship(message.payload))
        } else if (message.payload[consts.PAYLOAD_UPDATE_SUBSCRIPTION_STATUS]) {
            dispatch(updateSubscriptionStatus(message.payload))
        } else if (message.payload[consts.PAYLOAD_DATABROKER_TRANSFER_RATE]) {
            dispatch(updateDatabrokerTransferRate(message.payload))
        } else if (message.payload[consts.PAYLOAD_UPDATE_ACCOUNT_INFO]) {
            dispatch(updateAccountInfo(message.payload))
        } else if (message.payload[consts.PAYLOAD_DATA_BROKER_LOGS]) {
            dispatch(updateDataBrokerLogs(message.payload))
        } else if (message.payload[consts.PAYLOAD_UPDATE_REPORT]) {
            dispatch(updateReport(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_KMS_KEYS]) {
            dispatch(updateKmsKeys({...message.payload, dataBrokerId: message.header.agentId, groupId}))
        } else if (message.payload[consts.PAYLOAD_LIST_S3_PRIVATE_LINKS]) {
            dispatch(updateS3PrivateLinks({...message.payload, dataBrokerId: message.header.agentId, groupId}))
        } else if (message.payload[consts.PAYLOAD_LIST_BOX_ROOT_FOLDER]) {
            dispatch(updateBoxRootFolders(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_BOX_FOLDERS]) {
            dispatch(updateBoxFolders(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_GOOGLE_DRIVES]) {
            dispatch(updateGoogleDrives(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_GOOGLE_DRIVE_FOLDERS]) {
            dispatch(updateGoogleDriveFolders(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_SFTP_ROOT_DIRECTORY]) {
            dispatch(updateSftpRootDirectory(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_SFTP_DIRECTORIES]) {
            dispatch(updateSftpDirectories(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_DATA_LAKE_CONTAINERS]){
            dispatch(updateDataLakeContainers(message.payload))
        } else if (message.payload[consts.PAYLOAD_LIST_DATA_LAKE_CONTAINER_SUB_DIRECTORIES]) {
            dispatch(updateDataLakeContainerFolders(message.payload))
        } else if (message.payload[consts.PAYLOAD_DATA_BROKER_LOCAL_CONFIGURATION]) {
            dispatch(updateDataBrokerConfiguration(message.payload[consts.PAYLOAD_DATA_BROKER_LOCAL_CONFIGURATION], message.header))
        } else if (message.payload[consts.PAYLOAD_LIST_GCP_KMS_KEYS]) {
            dispatch(setTargetGcpEncryptOptions(message.payload[consts.PAYLOAD_LIST_GCP_KMS_KEYS]))
        }
    })
}

const sleep = (period) =>  {
    return new Promise(resolve => setTimeout(() => resolve(), period));
};
