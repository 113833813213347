import React from 'react';
import {
    getServersByType,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import ServerStepTemplate from "./ServerStepTemplateNew";
import {buildS3BasedCacheKey, calculateStepId} from '../../../utils/sync-utils';
import {
    buildServersList,
    getPropFromState,
    getWizardParamsFromWizardState
} from '../../../utils/mapStateToPropsUtils';
import S3BasedAdditionalSettings from "./additionalSettings/S3BasedAdditionalSettingsNew";
import {validate} from './additionalSettings/validators/s3BasedValidatorNew'
import useStepinize, {buildMoreStepInfo} from "../useStepinize";

const loading= ({endpointObj}) => {
    const serverType = endpointObj.provider || endpointObj.protocol;
    return {text: "Object Storage Servers", icon: require(`../../../assets/svgs/${serverType}-default.svg`)}}

const stepId = ({_wizard: {wizardInfo}, isTargetStepFromSameSourceType}) => {
    return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "s3");
};

const fetchData = (props) => {
    const { endpointObj, protocolAndProviderKey } = props;
    props.getServersByType(endpointObj.protocol, endpointObj.provider, protocolAndProviderKey);
};
const shouldFetch = (props) => {
    const { protocolAndProviderKey } = props;
    return !props._servers || !props._servers[protocolAndProviderKey] || !props._servers[protocolAndProviderKey].succeeded;
};

const isFetching = (props) => {
    const {_servers, protocolAndProviderKey} = props;
    return _servers[protocolAndProviderKey].inProgress;
};

const S3BasedServerStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, protocolAndProviderKey, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

    console.log('>>>>>>>>>>endpointObj', endpointObj);
    const {_servers, _wizard, state, clearSelectedParams} = props;
    const {selectedWizardParams: {selectedS3Based, selectedS3BasedTarget}} = _wizard;
    const selectedServer = isTargetStepFromSameSourceType ? selectedS3BasedTarget : selectedS3Based;
    const cacheKey = buildS3BasedCacheKey(selectedServer, isTargetStepFromSameSourceType);
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["s3BasedBucketPathTarget"] : ["s3BasedBucketPath"]

    const stepOptions = {
        storage: _servers[protocolAndProviderKey],
        server: selectedServer,
        serverSVG: `${endpointObj.provider}-default.svg`,
        serverType: "Object Storage",
        serverTitle: "Object Storage",
        selectType: "Server",
        serverPlaceholder:  `For example: 217.70.21.18 or https://netapp.com`,
        title: `Select an Object Storage ${isSourceStep ? "Source" : "Target"}`,
        clearShowOptions: {keyToClear: ['_showS3BasedBuckets', cacheKey]},
        validate,
        SecondScreenUi: S3BasedAdditionalSettings,
        stateParamsToClear,
        shouldGoToSecondScreen: () => true,
        getSelectedParams: (host) => {
            const hostParam = isTargetStepFromSameSourceType ? 's3BasedTarget' : 's3Based';
            if ((state[hostParam] && state[hostParam] !== host)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam] : host
            }
        }

    };

    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

const S3BasedServerStepForm = reduxForm({
    form: 's3BasedServer',
    validate,
    shouldError: () => true
})(S3BasedServerStep);

const mapStateToProps = (state, ownProps) => {
    const wizard = getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard;
    const moreStepInfo = buildMoreStepInfo({_wizard: wizard, name: ownProps.name}, stepId);
    return {
        ...ownProps,
        ...moreStepInfo,
        _servers: buildServersList(state),
        _wizard: wizard,
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getServersByType,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(S3BasedServerStepForm);
