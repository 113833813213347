import React from "react";
import {ClickableDiv} from '../widgets/ClickableDiv';
import classNames from "classnames";
import _ from "lodash";
import {ReactComponent as CheckmarkIcon} from "../../assets/svgs/checkmark-fill-circle-icon.svg";

import "./providerButton.scss";

const ProviderButton = ({displayName, handleClick, icon, disabledIcon, selected, disabled, disabledTitle}) => {
    return (<div className={classNames("provider-type button", {selected}, {disabled})}>
        <ClickableDiv onClick={disabled ? _.noop : handleClick} title={disabled ? disabledTitle : ""}>
            <div className="provider-type-content">
                <div className="provider-type-icon">{disabled ? disabledIcon : icon}</div>
                <div className="provider-type-text">{displayName}</div>
            </div>
        </ClickableDiv>
        <div className="checkmark"><CheckmarkIcon/></div>
    </div>)
};

export default ProviderButton;