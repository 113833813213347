import React from "react";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NotificationPanel } from '@netapp/design-system'
import { removeNotification } from "../../store/global/action_creators";
import { ButtonText } from "@netapp/shared-components";
import { NOTIFICATION_CONSTS } from "../../consts/notification.consts";

import './NotificationGroup.scss'

//Support for custome function in moreInfo 
export const handleChildren = (notification) => {
    const { children, moreInfo } = notification;
    if (typeof moreInfo === 'function') {
        return (
            <div className="notificationChildren">
                <span key={`notificationGroupCustomeFuncText${Date.now()}`}>{children}</span>
                <ButtonText className="cs_moreInfo" key={`notificationGroupCustomeFuncMoreInfo${Date.now()}`}
                    onClick={moreInfo}>More info</ButtonText>
            </div>
        );
    }

    return children;
};

const NotificationGroup = ({ _notifications }) => {
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        setNotifications(_notifications.map((notification) => {
            return {
                type: notification.type || NOTIFICATION_CONSTS.TYPE.ERROR,
                children: handleChildren(notification),
                onClose: () => {
                    notification.onClose && notification.onClose();
                    removeNotification(notification)(dispatch);
                },
                moreInfo: typeof notification.moreInfo === 'function' ? null : notification.moreInfo
            }
        }));
    }, [_notifications, dispatch])

    return <NotificationPanel className="notificationGroup"
        notifications={notifications}
        isFooter={true}
    />
};

const mapStateToProps = (state, ownProps) => ({
    _notifications: state.global._notifications,
    ...ownProps
});

export default connect(mapStateToProps)(NotificationGroup);