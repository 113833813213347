import React, { useState, useCallback } from "react";
import {
  azureSelectStep,
  showAzureDataLakeContainers,
  listAzureDataLakeContainerFolders,
  azureAdditionalStorageSettingsStep,
  clearFoldersCache,
  updateUrlParams,
  wizardStepStarted,
  wizardStepExited,
  clearUrlParamsIfChanged,
} from "../../../store/wizard/action_creators";
import { connect } from "react-redux";
import TagsPopover from "../../widgets/TagsPopover";
import ShowObjectStorageStepTemplate from "./ShowObjectStorageStepTemplateNew";
import {
  AZURE_BLOB_TIER_OPTIONS,
  DEFAULT_AZURE_BLOB_TIER,
} from "../../../consts";
import AdditionalSettingsUI from "./additionalSettings/AdditionalSettingsUINew";
import _ from "lodash";
import {getPropFromState,} from "../../../utils/mapStateToPropsUtils";
import { ReactComponent as AzureIcon } from "../../../assets/svgs/azure-no-circle.svg";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import useStepinize from "../useStepinize";

require("./showObjectStorageStepNew.scss");
const loading = () => {
  return {
    text: "Containers",
    icon: require("../../../assets/svgs/azure_data_lake-default.svg"),
  };
}

const fetchData = (props) => {
  const { isTargetStepFromSameSourceType } = props;
  const localSelectedStorageAccountProp = isTargetStepFromSameSourceType
      ? props._wizard.selectedWizardParams.selectedStorageAccountNameTarget
      : props._wizard.selectedWizardParams.selectedStorageAccountName;
  return props.showAzureDataLakeContainers(
      localSelectedStorageAccountProp,
      isTargetStepFromSameSourceType,
      props._wizard.selectedWizardParams.selectedDataBroker.id,
  );
};

const shouldFetch = (props) => {
  const {
    _showAzureDataLakeContainers,
    _wizard: {
      selectedWizardParams: {
        selectedStorageAccountName,
        selectedStorageAccountNameTarget,
        selectedDataBroker,
      },
    },
    isTargetStepFromSameSourceType,
  } = props;
  const actualStorageAccountName = isTargetStepFromSameSourceType
      ? selectedStorageAccountNameTarget
      : selectedStorageAccountName;
  const azureBlobContainersForStorageAccount =
      _showAzureDataLakeContainers[actualStorageAccountName] &&
      _showAzureDataLakeContainers[actualStorageAccountName].groupId ===
      _.get(selectedDataBroker, "id")
          ? _showAzureDataLakeContainers[actualStorageAccountName]
          : null;
  return (
      !azureBlobContainersForStorageAccount ||
      azureBlobContainersForStorageAccount.failedRetrieve
  );
};

const stepId = ({ isTargetStepFromSameSourceType }) => {
  return isTargetStepFromSameSourceType
      ? "targetDataLakeContainers"
      : "dataLakeContainers";
};

const isFetching = (props) => {
  const {
    _showAzureDataLakeContainers,
    _wizard: {
      selectedWizardParams: {
        selectedStorageAccountName,
        selectedStorageAccountNameTarget,
      },
    },
    isTargetStepFromSameSourceType,
  } = props;
  const actualStorageAccountName = isTargetStepFromSameSourceType
      ? selectedStorageAccountNameTarget
      : selectedStorageAccountName;
  const azureBlobContainersForStorageAccount =
      _showAzureDataLakeContainers[actualStorageAccountName];
  if (!azureBlobContainersForStorageAccount) return true;
  return (
      azureBlobContainersForStorageAccount.inProgress ||
      ((!azureBlobContainersForStorageAccount.data ||
          azureBlobContainersForStorageAccount.data.length === 0) &&
          !azureBlobContainersForStorageAccount.retrievedAll)
  );
};


const innerColumns = (row) => {
  const { tags } = row;
  return tags
    ? [
        <td className="path" key="path">
          <div className="text" title={row.path}>
            {row.path}
          </div>
          <div className="title">Name</div>
        </td>,
        <TagsPopover tags={tags} inTable={true} leftAdjust={-470} key="tags" />,
      ]
    : [
        <td className="path" title={row.path} key="path">
          {row.path}
        </td>,
      ];
};

const ShowAzureDataLakeContainersStep = (props) => {
  const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
  const {isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

  const {azureSelectStep, /*azureAdditionalStorageSettingsStep,*/ listAzureDataLakeContainerFolders, clearFoldersCache,
        _showAzureDataLakeContainers,/* _ui: { azure },*/ _wizard: {selectedWizardParams: {selectedAzureDataLakeContainerPath,
        selectedAzureDataLakeContainerPathTarget, selectedDataLakeTier, selectedStorageAccountName,
        selectedStorageAccountNameTarget}}} = props;
  const [dataLakeTier, setDataLakeTier] = useState(selectedDataLakeTier);
  const step = "select";
  const actualStorageAccountName = isTargetStepFromSameSourceType
    ? selectedStorageAccountNameTarget
    : selectedStorageAccountName;
  const azureBlobContainersForStorageAccount =
    _showAzureDataLakeContainers[actualStorageAccountName];

  const containerOptions = {
    singularTitle: "Container",
    pluralTitle: "Containers",
    itemIdentifier: "actualPath",
    rootName: "All Containers",
    targetName: "Define Target Directory",
    directoriesName: "Directories",
    directoryName: "directory",
    icon: require("../../../assets/svgs/bucket-icon.svg"),
    nameForSetupStep: "Data Lake Setup",
  };

  const callListAzureDataLakeContainerFolders = useCallback(
    (container, path, groupId) => {
      listAzureDataLakeContainerFolders(
          actualStorageAccountName,
          container,
          path,
          isTargetStepFromSameSourceType,
          groupId
      );
    },
    [
      isTargetStepFromSameSourceType,
      listAzureDataLakeContainerFolders,
      actualStorageAccountName,
    ]
  );

  const returnToBucketsStep = () => {
    setDataLakeTier(DEFAULT_AZURE_BLOB_TIER);
    window.setFormValue("blobTier", DEFAULT_AZURE_BLOB_TIER); //change in the form itself
    azureSelectStep();
  };

  const changeDataLakeTierSelection= (selectedOptions) => {
    setDataLakeTier(selectedOptions.value);
  };

  const getSecondStepUi = (step, bucketPath, formValues, form) => {
    if (!bucketPath) return <div />;
    return (
      <AdditionalSettingsUI
        step={step}
        bucketPath={bucketPath}
        isSourceStep={props._wizard.isSourceStep}
        handleClose={returnToBucketsStep}
        selectedOption={dataLakeTier || DEFAULT_AZURE_BLOB_TIER}
        selectionOptions={AZURE_BLOB_TIER_OPTIONS}
        handleOptionSelection={changeDataLakeTierSelection}
        title="Selected Azure Data Lake Gen 2 Container"
        icon={<AzureIcon />}
        optionsTitle="Azure Data Lake Gen 2 Access Tier"
        form={form}
        formValues={formValues}
      />
    );
  };

  // disabling access tier step currently not supported
  const setAdditionalSettings = (step) => {
/*    if (step === "select" && !isSourceStep) {
      azureAdditionalStorageSettingsStep();
      return false;
    } else {*/
      return true;
    // }
  };

  const stepOptions = {
    selectedBaseObject: isTargetStepFromSameSourceType
      ? selectedAzureDataLakeContainerPathTarget
      : selectedAzureDataLakeContainerPath,
    title: `Select ${isSourceStep ? "Source" : "Target"} Container`,
    failedMessage: "Unable to retrieve containers",
    notFoundMessage: "No containers were found",
    bottomMessage: "in your Azure Storage Account",
    storage: azureBlobContainersForStorageAccount,
    getStorageByBrokerId: false,
    listSubFoldersAction: callListAzureDataLakeContainerFolders,
    storageObjectName: "Container",
    storageObjectType: "Azure Data Lake Gen 2",
    innerColumns,
    stepFormName: "azureDataLakeContainersForm",
    containerOptions,
    innerPathBeginWithSlash: false,
    getSecondStepUi: getSecondStepUi,
    updateStepInfo: setAdditionalSettings,
    currentStep: step,
    extraUrlParam: dataLakeTier || DEFAULT_AZURE_BLOB_TIER,
    resetToFirstStepAction: azureSelectStep,
    refreshStep: () => {
      clearFoldersCache({
        cacheName: "_showAzureDataLakeContainers",
        storageKey: actualStorageAccountName,
      });
      fetchData(props);
    },
    initialFormValues: { blobTier: dataLakeTier },
    getSelectedParams: (bucket) => {
      const paramName = isTargetStepFromSameSourceType ? 'azureDataLakeContainerPathTarget' : 'azureDataLakeContainerPath';
      return {[paramName] : bucket}
    },
    getExtraUrlParamsNew: (formValues) =>{
      const blobTierParam = isTargetStepFromSameSourceType ? 'dataLakeTierTarget' : 'dataLakeTier';
      return {
        [blobTierParam]: dataLakeTier || DEFAULT_AZURE_BLOB_TIER
      }
    },

  };

  return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ShowObjectStorageStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
    _showAzureDataLakeContainers: getPropFromState(
      state,
      "_showAzureDataLakeContainers",
      {}
    ),
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    routing: state.router,
  };
};

const mapDispatchToProps = {
  showAzureDataLakeContainers,
  listAzureDataLakeContainerFolders,
  azureSelectStep,
  azureAdditionalStorageSettingsStep,
  clearFoldersCache,
  clearUrlParamsIfChanged,
  updateUrlParams,
  wizardStepStarted,
  wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAzureDataLakeContainersStep);
