import React from 'react';
import ConfirmDialog from "../../dialog/ConfirmDialog";
import pluralize from 'pluralize'
import {REPORT_STATUS_RUNNING} from "../../../consts";

const DeleteReportsDialog = ({reports, deleteReports, rerouteOnComplete, status, fromAll, navigate}) => {
    const numOfReports = reports.length;
    const confirmText = `${status === REPORT_STATUS_RUNNING ? "Abort" : "Delete"} ${fromAll ? "Reports" : pluralize("Report", numOfReports)}`;
    return (
        <ConfirmDialog
            title={confirmText}
            confirmText={confirmText}
            onConfirm={async () => {
                try {
                    await deleteReports(reports)
                    if (rerouteOnComplete) {
                        navigate({
                            pathname: `/reports/`,
                            search: window.location.search
                        })
                    }
                }
                catch(error) {
                    throw(error);
                }
            }}
            extractError={(error) => error?.data?.message}
        >
            {status !== REPORT_STATUS_RUNNING && <p>Are you sure you want to delete {numOfReports > 1 || fromAll ? "all the" : rerouteOnComplete ? "the current" : "the last "} {numOfReports > 1 || fromAll ? 'reports': 'report'}?</p>}
            {status === REPORT_STATUS_RUNNING && <p>If you abort the report creation, Copy & sync interrupts the process and deletes the report.<br/>
                Creating a new report will start the process all over.<br/><br/>
                Are you sure you want to proceed?</p>}

        </ConfirmDialog>
    );
};

export default DeleteReportsDialog;
