import React, { useState, useEffect } from 'react';
import {
    InputField,
    SelectField,
    RadioInputs,
    CheckboxField,
    CheckboxInputs,
    MultiSelectInput,
    DatePicker
} from '../../_common_/forms/forms';
import { Form, Field } from 'react-final-form'
import * as utils from '../../../utils/settings-utils';
import classNames from 'classnames';
import { get, includes, noop } from 'lodash';
import Validator from 'validatorjs';
import { useDialog } from "@netapp/shared-components"
import ResetSettingsDialog from './ResetSettingsDialog';
import { convertSizeToBytes } from '../../../utils/helpers';
import { ReactComponent as DownChevronIcon } from "./../../../assets/svgs/down-chevron.svg";
import { ReactComponent as TriangleIcon } from "./../../../assets/svgs/exclamation-mark-triangle-icon.svg";
import { ReactComponent as ResetIcon } from "./../../../assets/svgs/reset.svg";
import { useDispatch } from 'react-redux';
import { findAclValuesByKey, ACL_PLACEHOLDER, aclOptionsSettings, findAclOptionByValues } from '../../../utils/relationshipUtils';


require("./syncSettings.scss");

//******************* steps to add a new setting to the form ****************
// 1. In render:
//  Add AccordionComponent and edit its properties using the new setting name
//  Insert the setting's relevant input/s as children.
//
// 2. If necessary, add validation rules to "validate" function
//
// 3. In settings-utils:
//  add the default value to SETTINGS_DEFAULTS
//  add relevant code to getSummaryLines
//  add relevant code to createSettingsObjectFromFormValues
//  add relevant code to getFormValuesFromSettings
//
// 4. Style: in syncSettings.scss, go to "accordion type-specific" area and add your relevant css
//**************************************************************************


const validate = values => {
    const rules = {}; // for validatorjs validations
    const errors = {}; // for custom validations
    const customErrors = {};

    // eslint-disable-next-line no-unused-vars
    Validator.register('customInteger', (value) => {
        //In order to allow leading zeros, e.g. 010 is an integer that equals 10. See jira CS-4097.
        const valueNum = +value;
        return Number.isInteger(valueNum);
    }, `The :attribute field must be an integer`);

    //grace period validation:
    const graceResolution = get(values.graceResolution, 'value');

    if (!includes(values.gracePeriodOn, utils.NOT_EXCLUDE_FILES_OPTION)) {
        rules.graceResolution = 'required';
        if (graceResolution === 'Minutes' || graceResolution === 'Seconds') {
            rules.graceValue = 'required|numeric|between:1,60|customInteger';
        } else if (graceResolution === 'Hours') {
            rules.graceValue = 'required|numeric|between:1,24|customInteger';
        } else if (graceResolution === 'Days') {
            rules.graceValue = 'required|numeric|between:1,365|customInteger';
        }
    }

    //file types validation:
    rules.fileTypes = 'required';
    customErrors['required.fileTypes'] = 'At least one type must be selected.';

    //file size validation:
    if (includes(values.fileSizeOption, utils.FILE_SIZE_OPTION)) {
        rules.fileMinSizeValue = 'required|numeric';
        rules.fileMaxSizeValue = 'required|numeric';
        const fileMinSizeResolution = get(values.fileMinSizeResolution, 'value');
        const fileMaxSizeResolution = get(values.fileMaxSizeResolution, 'value');
        const minBytes = convertSizeToBytes(values.fileMinSizeValue, fileMinSizeResolution);
        const maxBytes = convertSizeToBytes(values.fileMaxSizeValue, fileMaxSizeResolution);
        if (fileMinSizeResolution === "B") rules.fileMinSizeValue = 'required|customInteger';
        if (fileMaxSizeResolution === "B") rules.fileMaxSizeValue = 'required|customInteger';

        if (maxBytes > utils.UI_MAX_FILE_SIZE_LIMIT) {
            errors.fileMaxSizeValue = "The Max File Size can't be larger than 4 PB";
        }
        if (minBytes > maxBytes) {
            errors.fileMaxSizeValue = "The Min File Size can't be larger then the Max File Size";
        }
    }

    //files regex validation
    if (values.filesRegex) {
        const invalidRegex = [];
        let regexValue = ''
        values.filesRegex.forEach(regexItem => {
            try {
                regexValue = regexItem.value;
                new RegExp(regexValue);
            } catch (error) {
                invalidRegex.push(regexValue);
            }
        });

        errors.filesExtensions = invalidRegex.length > 0 ? `The following patterns are invalid: ${invalidRegex.join(', ')}` : undefined;
    }

    //date modified validation:
    const dateModifiedOption = get(values.fileDateModifiedOption, 'value');
    if (dateModifiedOption !== utils.DATE_MODIFIED_OPTIONS.ALL) {
        const minRequired = dateModifiedOption === utils.DATE_MODIFIED_OPTIONS.ONLY_MIN || dateModifiedOption === utils.DATE_MODIFIED_OPTIONS.RANGE;
        const maxRequired = dateModifiedOption === utils.DATE_MODIFIED_OPTIONS.ONLY_MAX || dateModifiedOption === utils.DATE_MODIFIED_OPTIONS.RANGE;
        if (minRequired) {
            rules.fileDateModifiedMin = "required|date";
        }
        if (maxRequired) {
            rules.fileDateModifiedMax = "required|date";
        }
    }

    //date created validation:
    const dateCreatedOption = get(values.fileDateCreatedOption, 'value');
    if (dateCreatedOption !== utils.DATE_CREATED_OPTIONS.ALL) {
        const minRequired = dateCreatedOption === utils.DATE_CREATED_OPTIONS.ONLY_MIN || dateCreatedOption === utils.DATE_CREATED_OPTIONS.RANGE;
        const maxRequired = dateCreatedOption === utils.DATE_CREATED_OPTIONS.ONLY_MAX || dateCreatedOption === utils.DATE_CREATED_OPTIONS.RANGE;
        if (minRequired) {
            rules.fileDateCreatedMin = "required|date";
        }
        if (maxRequired) {
            rules.fileDateCreatedMax = "required|date";
        }
    }

    //schedule validation:
    if (utils.isScheduleActive(values.scheduleStatus)) {
        if (get(values.scheduleResolution, 'value') === 'Days') rules.scheduleInterval = 'required|numeric|between:1,365|customInteger';
        else if (get(values.scheduleResolution, 'value') === 'Hours') rules.scheduleInterval = 'required|numeric|between:1,24|customInteger';
        else if (get(values.scheduleResolution, 'value') === 'Minutes') rules.scheduleInterval = 'required|numeric|between:1,60|customInteger';
    }

    //abort validation:
    if (utils.isAbortActive(values.abortStatus)) {
        if (get(values.abortResolution, 'value') === 'Days') rules.abortInterval = 'required|numeric|between:1,365|customInteger';
        else if (get(values.abortResolution, 'value') === 'Hours') rules.abortInterval = 'required|numeric|between:1,24|customInteger';
        else if (get(values.abortResolution, 'value') === 'Minutes') rules.abortInterval = 'required|numeric|between:15,60|customInteger';
    }

    const validator = new Validator(values, rules, customErrors);
    validator.setAttributeNames({
        graceValue: `${graceResolution} value`,
        fileMinSizeValue: 'Min File Size',
        fileMaxSizeValue: 'Max File Size',
        fileDateModifiedMin: 'From date',
        fileDateModifiedMax: 'To date',
        fileDateCreatedMin: 'From date',
        fileDateCreatedMax: 'To date',
        scheduleInterval: 'Interval value',
        abortInterval: 'Interval value'
    });
    validator.passes();

    return { ...validator.errors.all(), ...errors };
};

const AccordionComponent = ({ title, summary, children, containerClassName, isOpen, handleSectionClick, error }) => {
    return (
        <div className={classNames("accordion-container", containerClassName)}>
            <div className={classNames("title", { "error": error })} onClick={handleSectionClick}>{title}</div>
            <div className={classNames("middle", { "show-content": isOpen })}>
                <div className={classNames("summary-area", { "show": !isOpen })} onClick={handleSectionClick}>

                    {error && <div className="error placeholder"><span><TriangleIcon /></span>
                        <div className="error-text">{error}</div>
                    </div>}
                    {!error && <div className="summary-text placeholder" title={typeof summary === 'string' ? summary : ''}>{summary}</div>}
                </div>
                {/*css is used to show/hide the input area, because if the element is unmounted from the DOM the fields are unregistered from the form, and no validation is done*/}
                <div className={classNames("input-area", { "show": isOpen })}>
                    {children}
                    <div className={classNames("error-area placeholder", { "hidden": !error })}>{error}</div>
                </div>
            </div>
            <div className={classNames("chevron end", { "up": isOpen })} onClick={handleSectionClick}>
                <span><DownChevronIcon /></span>
            </div>
        </div>)
};

const calculateSchedulePlaceholder = (value) => {
    switch (value) {
        case "Hours": return "1-24";
        case "Days": return "1-365";
        case "Minutes": return "1-60";
        default: return "";
    }
};

const calculateAbortSchedulePlaceholder = (value) => {
    switch (value) {
        case "Hours": return "1-24";
        case "Days": return "1-365";
        case "Minutes": return "15-60";
        default: return "";
    }
};

const calculateGracePeriodPlaceholder = (value) => {
    switch (value) {
        case "Hours": return "1-24";
        case "Days": return "1-365";
        case "Minutes":
        case "Seconds":
            return "1-60";
        default: return "";
    }
};

const SettingsSelectField = (props) => <Field
    component={SelectField}
    valueField="value"
    isSearchable={false}
    variant="underline"
    removeBottomMargin={true}
    {...props}
/>;


const SyncSettingsForm = ({
    setSubmitMethod, setSubmitting,
    doSubmit, initialValues, isEditMode, options, dataSenseIntegration
}) => {

    const { setDialog } = useDialog();
    const { showAclSection, copyAclOptions, showObjectTaggingSection, isCloudToCloud, supportsTagsCloudToCloud, hasOntapS3, hasHardLinks,
        showCopyPropertiesSection, showCreateTimeFilterSection, supportsContinuousSync, sourceProvider, includeBox } = options;
    const [formInitialValues, setFormInitialValues] = useState(null);
    const dispatch = useDispatch();
    const formDefaults = utils.getFormValuesFromSettings(utils.SETTINGS_DEFAULTS, isEditMode, isCloudToCloud, hasOntapS3, hasHardLinks, copyAclOptions ? findAclValuesByKey(copyAclOptions.value) : {});

    useEffect(() => {
        if (initialValues) {
            setFormInitialValues(initialValues)
        }
    }, [initialValues])

    const [openedSection, setOpenedSection] = useState(null);

    const handleSectionClick = (section) => {
        setOpenedSection(section === openedSection ? null : section);
    };

    const setAclOptionsOnSettings = values => {
        const { copyAcl, aclOptions, ...rest } = values;
        if (aclOptions) return { ...rest, ...findAclValuesByKey(aclOptions.value) };
        else return { ...rest, copyAcl };
    }

    const setInitialAclOptionsOnSettings = values => {
        const { copyAcl, aclOptions } = formInitialValues;
        const { copyAcl: initialCopyAcl, ...rest } = values;
        if (aclOptions) return { ...rest, aclOptions: { ...findAclOptionByValues(false, true) } };
        else return { ...rest, copyAcl };
    }

    const handleSubmittedData = (values, dispatch) => {

        //update the parent (editSettings) to show the submitting loader on the button
        if (setSubmitting) {
            setSubmitting(true);
        }

        const valuesForSave = setAclOptionsOnSettings(values);
        const settingsObj = utils.createSettingsObjectFromFormValues(valuesForSave, isCloudToCloud);
        return doSubmit(settingsObj, dispatch);
    };

    const resetSettings = () => {
        const formValues = setInitialAclOptionsOnSettings(formDefaults);
        setFormInitialValues(formValues);
        setOpenedSection(null);
    };

    return !formInitialValues ? (<div />) : (
        <div className="sync-settings">
            <Form onSubmit={(values) => handleSubmittedData(values, dispatch)} validate={validate} initialValues={formInitialValues}>
                {({ handleSubmit, form, values: formValues, errors: formErrors }) => {
                    setSubmitMethod(handleSubmit); //to allow submit from outside button
                    const summaryLines = utils.getSummaryLines(formValues, isCloudToCloud, hasOntapS3, utils.isHardlinksSelected(formValues), copyAclOptions);
                    // "Schedule" section:
                    const hideScheduleDefinitions = !utils.isScheduleActive(formValues.scheduleStatus);
                    const hideAbortDefinitions = !utils.isAbortActive(formValues.abortStatus);
                    const schedulePlaceholder = formValues.scheduleResolution ? calculateSchedulePlaceholder(formValues.scheduleResolution.value) : "";
                    const scheduleRadioInputs = isEditMode ? [utils.SCHEDULE_OPTIONS.ON, utils.SCHEDULE_OPTIONS.OFF] : [utils.SCHEDULE_OPTIONS.NOW, utils.SCHEDULE_OPTIONS.ON, utils.SCHEDULE_OPTIONS.ONCE];
                    const scheduleCheckedIndex = isEditMode ? (formValues.scheduleStatus === utils.SCHEDULE_OPTIONS.ON ? 0 : 1) :
                        (formValues.scheduleStatus === utils.SCHEDULE_OPTIONS.NOW ? 0 : formValues.scheduleStatus === utils.SCHEDULE_OPTIONS.ON ? 1 : 2);
                    const scheduleOptionNote = {
                        [utils.SCHEDULE_OPTIONS.NOW]: "The first sync will start after the wizard is completed.",
                        [utils.SCHEDULE_OPTIONS.ON]: isEditMode ? "The sync can be scheduled to start within the next 24 hours." : "You can define the first sync to start within the next 24 hours.",
                        [utils.SCHEDULE_OPTIONS.ONCE]: "The data sync will occur once. It won't be scheduled to repeat in the future.",
                        [utils.SCHEDULE_OPTIONS.OFF]: "No syncs will be scheduled for this relationship."
                    };

                    //abort sync
                    const abortPlaceholder = formValues.abortResolution ? calculateAbortSchedulePlaceholder(formValues.abortResolution.value) : "";
                    const abortRadioInputs = [utils.ABORT_OPTIONS.OFF, utils.ABORT_OPTIONS.ON];
                    const abortCheckedIndex = formValues.abortStatus === utils.ABORT_OPTIONS.ON ? 1 : 0;
                    const abortOptionNote = {
                        [utils.ABORT_OPTIONS.ON]: isEditMode ? "Define when to cancel an active sync that hasn’t completed. Any changes will be applied to the next data sync." : "Define when to cancel an active sync that hasn’t completed.",
                        [utils.ABORT_OPTIONS.OFF]: "Sync will not be canceled for this relationship."
                    }

                    //"Retries" section:
                    const retriesSelect = <SettingsSelectField name="retries" options={utils.getRetriesOptions()} />;

                    // Continuous Sync section:
                    const continuousSyncEnabled = formValues.continuousSync ?? false;

                    // "Recently modified" section:
                    const graceRes = get(formValues.graceResolution, "value", "");
                    const gracePlaceholder = calculateGracePeriodPlaceholder(graceRes);
                    const disableGrace = formValues.gracePeriodOn === utils.NOT_EXCLUDE_FILES_OPTION || continuousSyncEnabled;

                    const isDeleteOnTargetDisabled = formValues.deleteOnSource === utils.DELETE_FILES_FROM_SOURCE_OPTION_TEXT;

                    // "File size" section:
                    const disableFileSize = formValues.fileSizeOption === utils.FILE_SIZE_OPTION_DISABLED;

                    // "Date modified" section:
                    const showMinCalendar = includes([utils.DATE_MODIFIED_OPTIONS.ONLY_MIN, utils.DATE_MODIFIED_OPTIONS.RANGE], get(formValues.fileDateModifiedOption, 'value'));
                    const showMaxCalendar = includes([utils.DATE_MODIFIED_OPTIONS.ONLY_MAX, utils.DATE_MODIFIED_OPTIONS.RANGE], get(formValues.fileDateModifiedOption, 'value'));

                    //Date Created section
                    const showMinCreatedCalendar = includes([utils.DATE_CREATED_OPTIONS.ONLY_MIN, utils.DATE_CREATED_OPTIONS.RANGE], get(formValues.fileDateCreatedOption, 'value'));
                    const showMaxCreatedCalendar = includes([utils.DATE_CREATED_OPTIONS.ONLY_MAX, utils.DATE_CREATED_OPTIONS.RANGE], get(formValues.fileDateCreatedOption, 'value'));

                    const dateDisabledTitle = "This option is not available based on your selection above.";
                    const aclDisabledTitle = 'This option can be changed only when copying files.';


                    const handleDeleteOnSourceChange = (selectedIndex) => {
                        if (selectedIndex === 1) form.change("deleteOnTarget", utils.NOT_DELETE_FILES_FROM_TARGET_OPTION_TEXT);
                    };

                    const handleContinuousSyncSelection = (event) => {
                        if (event.target.checked) {
                            form.change("abortStatus", utils.ABORT_OPTIONS.OFF);
                            form.change("gracePeriodOn", utils.NOT_EXCLUDE_FILES_OPTION);
                            form.change("fileDateModifiedOption.value", utils.DATE_MODIFIED_OPTIONS.ALL);
                            form.change("fileDateModifiedMax", null);
                            form.change("fileDateModifiedMin", new Date(utils.MIN_DATE_POSSIBLE));
                            form.change("scheduleStatus", utils.SCHEDULE_OPTIONS.ONCE);
                            form.change('notifications', undefined);
                        } else {
                            form.change("scheduleStatus", utils.SCHEDULE_OPTIONS.NOW);
                            form.change("gracePeriodOn", utils.EXCLUDE_FILES_OPTION);
                        }
                    };

                    const deleteOnSourceDisabledTitle = () => {
                        if (isEditMode) return "This option can be changed only when creating a relationship.";
                        else if (utils.aclMultiAndOnly(formValues, copyAclOptions)) return aclDisabledTitle
                    }

                    const getAclOptions = () => {
                        const disabledOption = formValues.deleteOnTarget === utils.DELETE_FILES_FROM_TARGET_OPTION_TEXT ? {
                            ACL: {
                                disabled: true,
                                disabledTitle: 'This option cannot be selected when "Delete Files On Target" is set'
                            }
                        } : {};
                        return aclOptionsSettings(disabledOption);
                    }

                    const aclSelectionChanged = (aclSelection) => {
                        const { aclOptions, fileTypes: formFileTypes } = formValues;
                        if (aclOptions) {
                            const { copyData } = findAclValuesByKey(aclOptions.value);
                            const { copyData: copyDataNew } = findAclValuesByKey(aclSelection.value);
                            let compareBy = formDefaults.compareBy;
                            let fileTypes = formDefaults.fileTypes;

                            if (copyDataNew !== copyData && !copyDataNew) {
                                compareBy = [utils.compareByMap.mtime]
                                fileTypes = formFileTypes.filter(type => type !== utils.fileTypesMap.hardlinks);
                            }

                            setFormInitialValues({ ...formValues, aclOptions: aclSelection, compareBy, fileTypes });
                        }
                    }

                    const filesSizesError = formErrors.fileMinSizeValue || formErrors.fileMaxSizeValue ? `${formErrors.fileMinSizeValue ? formErrors.fileMinSizeValue : ''} ${formErrors.fileMinSizeValue && formErrors.fileMaxSizeValue ? ' And' : ''} ${formErrors.fileMaxSizeValue ? formErrors.fileMaxSizeValue : ''}` : '';
                    return (<form noValidate onSubmit={handleSubmit} id="syncSettings">

                        <h5 className="category-title">General</h5>


                        <AccordionComponent title="Schedule" isOpen={openedSection === 'schedule'}
                            error={formErrors.scheduleInterval}
                            summary={summaryLines.schedule}
                            handleSectionClick={handleSectionClick.bind(this, 'schedule')}
                            containerClassName="schedule">
                            <Field component={RadioInputs} name="scheduleStatus" type="radio"
                                changeSelection={noop}
                                disabled={continuousSyncEnabled}
                                checkedIndex={scheduleCheckedIndex}
                                inputs={scheduleRadioInputs} />
                            <div className={classNames("note", { "disable-change": continuousSyncEnabled })}>{scheduleOptionNote[formValues.scheduleStatus]}</div>
                            {continuousSyncEnabled && <div
                                className="warning">{`We automatically set this option to "One time copy" because you enabled continuous sync option`}</div>}
                            <div className={classNames({ "hidden": hideScheduleDefinitions })}>
                                <div className={classNames("schedule-definition", { "disable-change": continuousSyncEnabled })}>
                                    <div className="text">
                                        The {isEditMode ? "next" : formValues.scheduleStatus === utils.SCHEDULE_OPTIONS.NOW ? "next" : "first"} sync
                                        will automatically start at
                                    </div>
                                    <div className="inline-field">
                                        <SettingsSelectField name="scheduleNextTime"
                                            options={utils.getScheduleNextTimeOptions()} />
                                    </div>
                                </div>
                                <div className={classNames("schedule-definition", { "disable-change": continuousSyncEnabled })}>
                                    <div className="text">and will repeat every</div>
                                    <div className="user-input">
                                        <div className="inline-field schedule-interval">
                                            <Field component={InputField} name="scheduleInterval"
                                                placeholder={schedulePlaceholder} className={'inline'} removeBottomMargin={true} />
                                        </div>
                                        <div className="inline-field">
                                            <SettingsSelectField name="scheduleResolution"
                                                options={utils.getScheduleResolutionOptions()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionComponent>

                        <AccordionComponent title="Sync Timeout" isOpen={openedSection === 'scheduleAbort'}
                            error={formErrors.abortInterval}
                            summary={summaryLines.scheduleAbort}
                            handleSectionClick={handleSectionClick.bind(this, 'scheduleAbort')}
                            containerClassName="abort">
                            <Field component={RadioInputs} name="abortStatus" type="radio"
                                changeSelection={noop}
                                checkedIndex={abortCheckedIndex}
                                disabled={continuousSyncEnabled}
                                inputs={abortRadioInputs} />
                            <div className={classNames("note", { "disable-change": continuousSyncEnabled })}>{abortOptionNote[formValues.abortStatus]}</div>
                            <div className={classNames({ "hidden": hideAbortDefinitions })}>
                                <div className={"schedule-definition"}>
                                    <div className="text">The sync will be canceled after</div>
                                    <div className="user-input">
                                        <div className="inline-field schedule-interval">
                                            <Field component={InputField} name="abortInterval"
                                                placeholder={abortPlaceholder} className={'inline'} removeBottomMargin={true} />
                                        </div>
                                        <div className="inline-field">
                                            <SettingsSelectField name="abortResolution"
                                                options={utils.getAbortResolutionOptions()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {continuousSyncEnabled && <div
                                className="warning">{`We automatically set this option to "Sync will not be canceled" because you enabled continuous sync option`}</div>}
                        </AccordionComponent>

                        <AccordionComponent title="Notifications" isOpen={openedSection === 'notifications'}
                            summary={summaryLines.notifications}
                            handleSectionClick={() => handleSectionClick('notifications')}
                            containerClassName="notifications">
                            <div className="placeholder">
                                <div className="instructions">Select the notifications you would like to get</div>
                                <Field id="notifications" component={CheckboxInputs} name="notifications"
                                    options={utils.getNotificationsOptions(continuousSyncEnabled)} />
                            </div>
                            <div className="notifications-notice">
                                <div><span className="notifications-label">Notice: </span>To receive notifications through email, configure alert and notification settings to allow Info notifications.  <a href="https://docs.netapp.com/us-en/cloud-manager-setup-admin/task-monitor-cm-operations.html#setting-email-notification-settings" target="_blank" rel="noopener noreferrer">Learn how to set email notifications</a>.</div>
                            </div>
                        </AccordionComponent>

                        <AccordionComponent title="Retries" isOpen={openedSection === 'retries'}
                            summary={summaryLines.retries}
                            handleSectionClick={() => handleSectionClick('retries')}
                            containerClassName="retries">
                            <div className="placeholder">Retry <span
                                className="inline-field">{retriesSelect}</span> times before skipping file (0-10)
                            </div>
                        </AccordionComponent>
                        {supportsContinuousSync && <AccordionComponent title="Continuous Sync" isOpen={openedSection === 'continuousSync'}
                            error={formErrors.continuousSync}
                            summary={summaryLines.continuousSync}
                            handleSectionClick={() => handleSectionClick('continuousSync')}
                            containerClassName="continuousSync">
                            <div className="placeholder">
                                <Field id="continuousSync" type="checkbox" component={CheckboxField} name="continuousSync"
                                    onClick={handleContinuousSyncSelection}>
                                    <label htmlFor="continuousSync">Monitor the source and sync changes as they occur</label>
                                </Field>
                                <div className="continuousSync-notice">
                                    <div><span className="continuousSync-label">Notice:</span>Continuous Sync will disable Schedule Sync and will change Sync Timeout, Recently Modified Files and Date Modified to their default values </div>
                                </div>
                            </div>
                        </AccordionComponent>}

                        <h5 className="category-title">Files and Directories</h5>

                        <AccordionComponent title="Compare By" isOpen={openedSection === 'compareBy'}
                            summary={summaryLines.compareBy}
                            handleSectionClick={() => handleSectionClick('compareBy')}
                            containerClassName="file-types">
                            <div className="placeholder">
                                <div className="instructions">Select the attributes you would like to compare
                                    Directories/Files by:
                                </div>
                                <Field component={CheckboxInputs} name="compareBy"
                                    options={utils.getCompareByOptions(isCloudToCloud, hasOntapS3, utils.aclMultiAndOnly(formValues, copyAclOptions))} />
                            </div>
                        </AccordionComponent>
                        {showCopyPropertiesSection && <AccordionComponent title="Copy For Objects" isOpen={openedSection === 'copyProperties'}
                            summary={summaryLines.copyProperties}
                            handleSectionClick={() => handleSectionClick('copyProperties')}
                            containerClassName="file-types">
                            <div className="placeholder">
                                <div className="instructions">Select the properties you would like to copy:
                                </div>
                                <Field component={CheckboxInputs} name="copyProperties"
                                    disabled={isEditMode}
                                    disabledTitle={isEditMode ? "This option can be changed only when creating a relationship." : null}
                                    options={utils.getCopyPropertiesOptions(supportsTagsCloudToCloud, isEditMode)} />
                            </div>
                        </AccordionComponent>}

                        <AccordionComponent title="Recently Modified Files" isOpen={openedSection === 'gracePeriod'}
                            error={formErrors.graceValue}
                            summary={summaryLines.gracePeriod}
                            handleSectionClick={() => handleSectionClick('gracePeriod')}
                            containerClassName="grace-period">
                            <div className="row">
                                <Field component={RadioInputs}
                                    changeSelection={noop}
                                    checkedIndex={formValues.gracePeriodOn === utils.EXCLUDE_FILES_OPTION ? 0 : 1}
                                    name="gracePeriodOn"
                                    disabled={continuousSyncEnabled}
                                    inputs={[utils.EXCLUDE_FILES_OPTION, utils.NOT_EXCLUDE_FILES_OPTION]}
                                    className="auto-sync" />
                                <div className={classNames("grace-value-column", { "disable-change": disableGrace })}>
                                    <Field component={InputField} disabled={disableGrace}
                                        className="inline grace-value value" removeBottomMargin={true}
                                        name="graceValue" placeholder={gracePlaceholder} />
                                </div>
                                <div
                                    className={classNames("grace-resolution-column ", { "disable-change": disableGrace })}>
                                    <SettingsSelectField disabled={disableGrace} name="graceResolution"
                                        options={utils.getGraceResolutionOptions()} />
                                </div>
                                <span className={classNames("last-part", { "disable-change": continuousSyncEnabled })}> before the scheduled sync</span>
                            </div>
                            {continuousSyncEnabled && <div
                                className="warning">{`We automatically set this option to "${utils.NOT_EXCLUDE_FILES_OPTION}" because you enabled continuous sync option`}</div>}
                        </AccordionComponent>

                        {!dataSenseIntegration && <AccordionComponent title="Delete Files On Source" isOpen={openedSection === 'deleteOnSource'}
                            summary={summaryLines.deleteOnSource}
                            handleSectionClick={() => handleSectionClick('deleteOnSource')}
                            containerClassName="delete-on-source">
                            <Field component={RadioInputs}
                                type='radio'
                                changeSelection={handleDeleteOnSourceChange}
                                disabled={isEditMode || utils.aclMultiAndOnly(formValues, copyAclOptions)}
                                disabledTitle={deleteOnSourceDisabledTitle()}
                                checkedIndex={formValues.deleteOnSource === utils.NOT_DELETE_FILES_FROM_SOURCE_OPTION_TEXT ? 0 : 1}
                                name="deleteOnSource"
                                inputs={[utils.NOT_DELETE_FILES_FROM_SOURCE_OPTION_TEXT, utils.DELETE_FILES_FROM_SOURCE_OPTION_TEXT]} />
                            {formValues.deleteOnSource === utils.DELETE_FILES_FROM_SOURCE_OPTION_TEXT &&
                                <div className="warning"><span><TriangleIcon /></span>
                                    {`Important: This option includes the risk of data loss because the source files are deleted after they're copied.
                         After you enable this option, you also need to change a parameter in the local.json file on the data broker. Refer to the documentation for details.`}
                                </div>}
                        </AccordionComponent>}

                        {!dataSenseIntegration && <AccordionComponent title="Delete Files On Target" isOpen={openedSection === 'deleteOnTarget'}
                            summary={summaryLines.deleteOnTarget}
                            handleSectionClick={() => handleSectionClick('deleteOnTarget')}
                            containerClassName="delete-on-target">
                            <Field component={RadioInputs}
                                disabled={isDeleteOnTargetDisabled || utils.aclMultiAndOnly(formValues, copyAclOptions)}
                                disabledTitle={aclDisabledTitle}
                                type="radio"
                                changeSelection={noop}
                                checkedIndex={formValues.deleteOnTarget === utils.NOT_DELETE_FILES_FROM_TARGET_OPTION_TEXT ? 0 : 1}
                                name="deleteOnTarget"
                                inputs={[utils.NOT_DELETE_FILES_FROM_TARGET_OPTION_TEXT, utils.DELETE_FILES_FROM_TARGET_OPTION_TEXT]} />
                            {formValues.deleteOnTarget === utils.DELETE_FILES_FROM_TARGET_OPTION_TEXT &&
                                <div className="warning"><span><TriangleIcon /></span>
                                    Important: This option is not typically recommended, especially if the
                                    target <br /> already contains files. It might result in data loss.
                                </div>}
                            {isDeleteOnTargetDisabled && <div
                                className="warning">{`We automatically set this option to "Never delete files from the target location" because you enabled the option to delete files from the source location.`}</div>}
                        </AccordionComponent>}

                        {showObjectTaggingSection && isEditMode &&
                            <AccordionComponent title="Object Tagging" isOpen={openedSection === 'objectTagging'}
                                summary={summaryLines.objectTagging}
                                handleSectionClick={() => handleSectionClick('objectTagging')}
                                containerClassName="disable-object-tagging">
                                <Field component={RadioInputs}
                                    changeSelection={noop}
                                    checkedIndex={formValues.objectTagging === utils.OBJECT_TAGGING_USE_OPTION_TEXT ? 0 : 1}
                                    name="objectTagging"
                                    inputs={[utils.OBJECT_TAGGING_USE_OPTION_TEXT, utils.OBJECT_TAGGING_DISABLE_OPTION_TEXT]} />
                            </AccordionComponent>}

                        {!dataSenseIntegration && <AccordionComponent title="File Types" isOpen={openedSection === 'fileTypes'}
                            error={formErrors.fileTypes}
                            summary={summaryLines.fileTypes}
                            handleSectionClick={() => handleSectionClick('fileTypes')}
                            containerClassName="file-types">
                            <div className="placeholder">
                                <div className="instructions">Select at least one file type that you want to include:
                                </div>
                                <Field component={CheckboxInputs} name="fileTypes"
                                    options={utils.getFileTypesOptions(hasOntapS3, hasHardLinks, utils.aclMultiAndOnly(formValues, copyAclOptions))} />
                                {utils.isHardlinksSelected(formValues) && <div className="warning">
                                    <span><TriangleIcon /></span>
                                    When using hardlinks, you are limited to one scanner concurrency and scanner process.
                                </div>}
                            </div>
                        </AccordionComponent>}

                        <AccordionComponent title="Exclude Files" isOpen={openedSection === 'filesExtensions'}
                            error={formErrors.filesExtensions}
                            summary={summaryLines.filesToExclude}
                            handleSectionClick={() => handleSectionClick('filesExtensions')}
                            containerClassName="files-extensions">
                            <div className="placeholder">
                                <div className="instructions">Enter the file extensions or regex to exclude (Limited to 15 options):
                                </div>
                                <Field component={MultiSelectInput} label="Exclude files by extensions"
                                    name="filesExtensions"
                                    placeholder="Type the file extension and press Enter"
                                    limit={15} inputTransformation={utils.extensionsInputTransformation} />
                                <Field component={MultiSelectInput} label="Exclude files by regex"
                                    name="filesRegex"
                                    placeholder="Type the regex and press Enter"
                                    limit={15} inputTransformation={utils.namesInputTransformation} />
                            </div>
                        </AccordionComponent>
                        {(!isCloudToCloud || includeBox) && <AccordionComponent title="Exclude Directories" isOpen={openedSection === 'directoriesNames'}
                            error={formErrors.directoriesNames}
                            summary={summaryLines.directoriesNames}
                            handleSectionClick={() => handleSectionClick('directoriesNames')}
                            containerClassName="directories-names">
                            <div className="placeholder">
                                <div className="instructions">Enter the directories names or directories full paths to exclude (the limit is 15
                                    names):
                                </div>
                                <Field component={MultiSelectInput} label="Directories names to exclude"
                                    name="directoriesNames"
                                    placeholder="Type the directory name and press Enter"
                                    limit={15} inputTransformation={utils.namesInputTransformation} />
                                <Field component={MultiSelectInput} label="Exclude directories by regex"
                                    name="directoriesRegex"
                                    placeholder="Type the regex and press Enter"
                                    limit={15} inputTransformation={utils.namesInputTransformation} />
                                <div className="directories-names-notice">
                                    <div><span className="directories-names-label">Notice:</span>We filter out by default directories : .copy-offload, .snapshot,  ~snapshot</div>
                                </div>
                            </div>
                        </AccordionComponent>}

                        <AccordionComponent title="File Size" isOpen={openedSection === 'fileSize'}
                            error={filesSizesError}
                            summary={summaryLines.fileSize}
                            handleSectionClick={() => handleSectionClick('fileSize')}
                            containerClassName="file-size">
                            <Field component={RadioInputs} className="file-size-radio" type="radio"
                                changeSelection={noop}
                                checkedIndex={formValues.fileSizeOption === utils.FILE_SIZE_OPTION ? 1 : 0}
                                name="fileSizeOption"
                                inputs={[utils.FILE_SIZE_OPTION_DISABLED, utils.FILE_SIZE_OPTION]} />
                            <div className={classNames("file-size-row", { "disable-change": disableFileSize })}>
                                <div className="row-title">Min File Size</div>
                                <Field component={InputField} disabled={disableFileSize} name="fileMinSizeValue"
                                    className="inline file-size-value" removeBottomMargin={true} />
                                <SettingsSelectField disabled={disableFileSize} name="fileMinSizeResolution"
                                    options={utils.getFileSizeResolutionOptions()}
                                    className="inline-field" />
                            </div>
                            <div className={classNames("file-size-row", { "disable-change": disableFileSize })}>
                                <div className="row-title">Max File Size</div>
                                <Field component={InputField} disabled={disableFileSize} name="fileMaxSizeValue"
                                    className="inline file-size-value" removeBottomMargin={true} />
                                <SettingsSelectField disabled={disableFileSize} name="fileMaxSizeResolution"
                                    options={utils.getFileSizeResolutionOptions()}
                                    className="inline-field" />
                            </div>
                            {supportsContinuousSync && sourceProvider === 's3' && <div className="file-size-notice">
                                <div><span className="file-size-label">Notice:</span>When Continuous Sync is enabled, filter by size will be active only on copy events (not on delete events).</div>
                            </div>}
                        </AccordionComponent>

                        <AccordionComponent title="Date Modified" isOpen={openedSection === 'dateModified'}
                            error={formErrors.fileDateModifiedMin || formErrors.fileDateModifiedMax}
                            summary={summaryLines.dateModified}
                            handleSectionClick={() => handleSectionClick('dateModified')}
                            containerClassName="date-modified">
                            <div className="placeholder">
                                <Field component={SelectField} valueField="value"
                                    name="fileDateModifiedOption"
                                    maxMenuHeight={200}
                                    disabled={continuousSyncEnabled}
                                    options={utils.getDateModifiedOptions()} isSearchable={false} />
                                <div className="calendars-row">
                                    <div className="calendar-item">
                                        <div> From:</div>
                                        <Field component={DatePicker} name="fileDateModifiedMin"
                                            isDisabled={!showMinCalendar} disabledTitle={dateDisabledTitle}
                                            minDatePossible={new Date(utils.MIN_DATE_POSSIBLE)}
                                            maxDate={showMaxCalendar ? formValues.fileDateModifiedMax : null} />
                                    </div>
                                    <div className="calendar-item">
                                        <div> To:</div>
                                        <Field component={DatePicker} name="fileDateModifiedMax"
                                            isDisabled={!showMaxCalendar} disabledTitle={dateDisabledTitle}
                                            minDatePossible={new Date(utils.MIN_DATE_POSSIBLE)}
                                            minDate={showMinCalendar ? formValues.fileDateModifiedMin : null} />
                                    </div>
                                </div>
                                {continuousSyncEnabled && <div
                                    className="warning">{`We automatically set this option to "${utils.DATE_MODIFIED_OPTIONS.ALL}" because you enabled continuous sync option`}</div>}
                            </div>
                        </AccordionComponent>

                        {showCreateTimeFilterSection && <AccordionComponent title="Date Created" isOpen={openedSection === 'dateCreated'}
                            error={formErrors.fileDateCreatedMin || formErrors.fileDateCreatedMax}
                            summary={summaryLines.dateCreated}
                            handleSectionClick={() => handleSectionClick('dateCreated')}
                            containerClassName="date-modified">
                            <div className="placeholder">
                                <Field component={SelectField} valueField="value"
                                    name="fileDateCreatedOption"
                                    maxMenuHeight={200}
                                    options={utils.getDateCreatedOptions()} isSearchable={false} />
                                <div className="calendars-row">
                                    <div className="calendar-item">
                                        <div> From:</div>
                                        <Field component={DatePicker} name="fileDateCreatedMin"
                                            isDisabled={!showMinCreatedCalendar} disabledTitle={dateDisabledTitle}
                                            minDatePossible={new Date(utils.MIN_DATE_POSSIBLE)}
                                            maxDate={showMaxCreatedCalendar ? formValues.fileDateCreatedMax : null} />
                                    </div>
                                    <div className="calendar-item">
                                        <div> To:</div>
                                        <Field component={DatePicker} name="fileDateCreatedMax"
                                            isDisabled={!showMaxCreatedCalendar} disabledTitle={dateDisabledTitle}
                                            minDatePossible={new Date(utils.MIN_DATE_POSSIBLE)}
                                            minDate={showMinCreatedCalendar ? formValues.fileDateCreatedMin : null} />
                                    </div>
                                </div>
                            </div>
                        </AccordionComponent>}

                        {showAclSection &&
                            <AccordionComponent title="ACL - Access Control List" isOpen={openedSection === 'acl'}
                                error={formErrors.copyAcl}
                                summary={summaryLines.acl}
                                handleSectionClick={() => handleSectionClick('acl')}
                                containerClassName="acl">
                                <div className="placeholder">
                                    {showAclSection === 'multi' ? <>
                                        <Field component={SelectField}
                                            valueField="value"
                                            name='aclOptions'
                                            maxMenuHeight={200}
                                            options={getAclOptions()}
                                            isSearchable={false}
                                            placeholder={ACL_PLACEHOLDER}
                                            onChange={aclSelectionChanged}
                                        />
                                    </> :
                                        <Field id="copyAcl" type="checkbox" component={CheckboxField} name="copyAcl">
                                            <label htmlFor="copyAcl">Copy Access Control Lists to the target</label>
                                        </Field>}
                                    <div className="acl-notice">
                                        <div><span className="acl-notice-label">Notice:</span>Copying ACLs can affect sync
                                            performance
                                        </div>
                                    </div>
                                </div>
                            </AccordionComponent>}

                    </form>
                    )
                }}
            </Form>
            <div className="reset-click" onClick={(el) => {
                setDialog(<ResetSettingsDialog resetSettings={resetSettings} />, el.target)
            }}>
                <span><ResetIcon /></span>
                Reset to defaults
            </div>
        </div>
    )
};

export default SyncSettingsForm;
