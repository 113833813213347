import React from 'react';
import {ReactComponent as SyncIcon} from "../../assets/svgs/sync-cloud-icon.svg";
import {ReactComponent as XIcon} from "../../assets/svgs/x.svg";
import { Link } from 'react-router-dom';

import "./newSyncHeader.scss";

const SyncHeader = () => {
    return (
        <div className="sync-header">
            <div className="sync-header-left">
                <SyncIcon className="sync-cloud"/>
                <div className="title">
                    Sync Relationship
                </div>
            </div>
            <Link className="close" to={`/dashboard`}><XIcon/></Link>
        </div>
    )
};
export default SyncHeader;
