import React, {useCallback} from 'react';
import {useDialog} from "@netapp/shared-components"
import {
    DOCUMENTATION_LICENSING_URL, FREE_TRIAL_PERIOD, AWS_MP_PRICING_MODEL_PAYGO_URL,
    AWS_MP_PRICING_MODEL_ANNUAL_URL, AZURE_MP_PRICING_MODEL_URL,
    AWS_MP_SUBSCRIBE_URL, AWS_MP_UNSUBSCRIBE_URL, SERVICE_NAME
} from '../../../consts';
import SubscriptionFlowDialog from './SubscriptionFlowDialog';
import Isvg from 'react-inlinesvg';
import {Button} from '@netapp/shared-components';
import {ReactComponent as CheckmarkIcon} from "../../../assets/svgs/checkmark-circle-icon.svg";
import {ReactComponent as XCircleIIcon} from "../../../assets/svgs/x-circle-icon.svg";


require("./subscribe.scss");

const Subscribe = (props) => {
    const {subscribing, subscribed, failedToSubscribe, freeTrial, hasLicense, isPayGoSubscription, provider} = props;
    const iconFileName = provider === 'AWS' ? 'aws-default' : 'azure-default';
    const {setDialog} = useDialog();

    const renderSubscribeTitle = useCallback(() => {
        if (subscribed) {
            return (
            <div className="icon-with-title">
                <span><CheckmarkIcon/></span>
                <h5>Subscribed ({provider} {isPayGoSubscription ? 'Pay-As-You-Go' : 'annual'} subscription)</h5>
            </div>
            );
        }

        if (failedToSubscribe) {
            return (
            <div className="icon-with-title failed">
                <span><XCircleIIcon/></span>
                <h5>Failed to subscribe from the {provider} Marketplace</h5>
            </div>
            );
        }

        if (subscribing) return <h5>Subscribing from {provider}</h5>;

        return <h5>Billing from your {provider} monthly bill</h5>;
    }, [subscribed, failedToSubscribe, subscribing, isPayGoSubscription, provider]);

    const renderSubscribeText = () => {
        const unsubscribed = !subscribed && !subscribing;
        const priceModelLink = provider === 'AWS' ? (isPayGoSubscription ? AWS_MP_PRICING_MODEL_PAYGO_URL : AWS_MP_PRICING_MODEL_ANNUAL_URL) : AZURE_MP_PRICING_MODEL_URL;

        if (subscribing) {
            return <span>Your subscription will be completed soon.</span>
        }

        if (failedToSubscribe) {
            return <span>Unsubscribe from the {provider} Marketplace and start the subscription process again.</span>
        }

        // build text dynamically
        let subscribeText = "";

        if (subscribed && freeTrial) {
            subscribeText = `After the ${FREE_TRIAL_PERIOD}-day free trial is over, you will be charged hourly according to the number of sync relationships that you created`
        }

        if (subscribed && !freeTrial && isPayGoSubscription) {
            subscribeText = `You will be charged hourly according to the number of sync relationships that you created`
        }

        if (subscribed && !freeTrial && !isPayGoSubscription) {
            subscribeText = `You have an annual subscription to ${SERVICE_NAME}. You can create up to 20 sync relationships using your annual subscription${provider=== 'Azure' ? '. Additional relationships will be charged by the hour' : ''}`;
        }

        if (unsubscribed && freeTrial) {
            subscribeText = `After the ${FREE_TRIAL_PERIOD}-day free trial is over, pay-as-you-go with hourly rates based on the number of sync relationships that you create, 
                                or purchase a credit of relationships by paying up front for a year`
        }

        if (unsubscribed && !freeTrial) {
            subscribeText = `Pay-as-you-go with hourly rates based on the number of sync relationships that you create, 
                                or purchase a credit of relationships by paying up front for a year`
        }

        // is has license - note that the subscription will only affect the relationships that are not in the license capacity
        if (!subscribing && hasLicense) {
            subscribeText += ` (additional to the reserved relationships that your licenses allow)`
        }

        subscribeText += '.';

        // only show price model link when subscribed (otherwise pricing model is shown on subscribe page itself)
        if (subscribed) {
            return (
                <span>
                    {subscribeText}
                    <br/>
                    <a href={priceModelLink} target="_blank" rel="noopener noreferrer">{`View the pricing model`}</a>
                </span>
            );
        }


        return <span>{subscribeText}</span>;
    };

    const renderSubscribeAction = () => {

        if (subscribing) {
            return <img className="loading" src={require('../../../assets/images/loading.gif')} alt=""/>
        }
        if (failedToSubscribe) {
            return <Button type="button" variant="primary" onClick={(clickElement) => showUnsubscribeConfirmation(clickElement, provider)}>Unsubscribe</Button>
        }
        if (!subscribed) {
            return <Button type="button" variant="primary" onClick={(clickElement) => showSubscribeConfirmation(clickElement, provider)}>Subscribe</Button>
        }

        return <div/>
    };

    const showSubscribeConfirmation = (clickElement, provider) => {
        setDialog(<SubscriptionFlowDialog providerLink={provider === 'AWS' ? AWS_MP_SUBSCRIBE_URL : process.env.REACT_APP_AZURE_SUBSCRIPTION_URL}
                                           providerInstructions={provider === 'AWS' ? `In the AWS Marketplace, you should click Continue, then Subscribe, and then Set Up Your Account.` :
                                               `In the Azure Portal, click Create, select your options, and click Subscribe (select Monthly to pay by the hour). When deployment is
                                                complete, click the name of the SaaS resource in the notification popup, and then click Configure Account.`}
                                           backToCloudSyncInstructions={`You are then redirected back to ${SERVICE_NAME}.`}
                                           videoLink= {`${DOCUMENTATION_LICENSING_URL}#${provider.toLowerCase()}`}
                                           provider={provider}
                                           iconFileName={`${provider.toLowerCase()}-marketplace`}/>, clickElement.target);
    };

    const showUnsubscribeConfirmation = (clickElement, provider) => {
        setDialog(<SubscriptionFlowDialog providerLink={provider === 'AWS' ? AWS_MP_UNSUBSCRIBE_URL : process.env.REACT_APP_AZURE_SUBSCRIPTION_URL}
                                           providerInstructions={`At the ${provider} Marketplace, look for Data Fabric ${SERVICE_NAME} Service and click CANCEL SUBSCRIPTION.`}
                                           backToCloudSyncInstructions={`You should then return to ${SERVICE_NAME} to start the subscription process again.`}
                                           provider={provider}
                                           iconFileName={`${provider.toLowerCase()}-marketplace`}/>, clickElement.target);
    };

    return (
        <div className="subscribe payment-option row medium">
            <div className="payment-icon">
                <span><Isvg src={require(`../../../assets/svgs/${iconFileName}.svg`)}/></span>
            </div>

            <div className="medium-8 columns payment-text">
                {renderSubscribeTitle()}

                {renderSubscribeText()}
            </div>

            <div className="medium-1 columns payment-action">
                {renderSubscribeAction()}
            </div>

        </div>
    );
};

export default Subscribe;
