import {
    getFSXWorkingEnvironments,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from '../../../store/wizard/action_creators';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {buildServersList, getPropFromState} from '../../../utils/mapStateToPropsUtils';
import WeStepTemplate from "./WeStepTemplateNew";
import {capitalize, find} from 'lodash'
import FsxWeCard from "./FsxWeCardNew";
import "./cmWeStepNew.scss";
import {getFsxWorkingEnvironmentsWithId} from "../../../utils/data-utils";
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";

const loading = () => {
    return {
        className: 'cvo-we',
        text: 'Working Environments',
        icon: require('../../../assets/svgs/fsx-default.svg')
    }
}

const stepId = ({isTargetStepFromSameSourceType, protocol}) => {
    const protocolCapitalized = capitalize(protocol);
    return isTargetStepFromSameSourceType ? `targetFsx${protocolCapitalized}We` : `fsx${protocolCapitalized}We`;
};
const fetchData = (props) => {
    props.getFSXWorkingEnvironments();
};
const shouldFetch = (props) => {
    return !props._fsxWorkingEnvironments || (!props._fsxWorkingEnvironments.inProgress && !props._fsxWorkingEnvironments.succeeded)
};
const isFetching = ({_fsxWorkingEnvironments}) => {
    return _fsxWorkingEnvironments?.inProgress;
};


const FsxWeStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {isTargetStepFromSameSourceType,isSourceStep, protocol} = moreStepInfo;

    const {_fsxWorkingEnvironments, _cmIntegration: {inputDetails}, clearSelectedParams, state} = props;
    const { viewDashboard, source, target} = inputDetails || {};
    const protocolCapitalized = capitalize(protocol);

    //in case it was selected in the CM gallery
    const showOnlyCmSelection = (isSourceStep ? source?.sourceWorkingEnvironmentType?.toLowerCase() === "fsx" : target?.targetWorkingEnvironmentType?.toLowerCase() === 'fsx') && !viewDashboard;
    const workingEnvironmentName = isSourceStep ? source?.sourceWorkingEnvironmentName : target?.targetWorkingEnvironmentName;

    const fsxWorkingEnvironments = useMemo(() => {
        const allWE = getFsxWorkingEnvironmentsWithId(_fsxWorkingEnvironments.data);
        const selectedWe = find(allWE, {name: workingEnvironmentName});
        return showOnlyCmSelection && selectedWe ? [selectedWe] : allWE;
    }, [_fsxWorkingEnvironments?.data, showOnlyCmSelection, workingEnvironmentName]);

    //initialize selection:
    const stepOptions = {
        workingEnvironments: fsxWorkingEnvironments,
        selectedStepParamName: `selectedFsx${protocolCapitalized}We`,
        selectedTargetStepParamName: `selectedFsx${protocolCapitalized}WeTarget`,
        showOnlyCmSelection,
        WECard: FsxWeCard,
        getSelectedParams: (we) => {
            const paramName = isTargetStepFromSameSourceType ? `fsx${protocolCapitalized}WeTarget` :  `fsx${protocolCapitalized}We`

            if (state[paramName] && state[paramName] !== we) {
                 const hostParam = isTargetStepFromSameSourceType ? `${protocol}ServerTarget` : `${protocol}Server`;
                const versionParam = isTargetStepFromSameSourceType ? `target${protocolCapitalized}Version` : `${protocol}Version`;
                const volParam = isTargetStepFromSameSourceType? `${protocol}VolTarget` : `${protocol}Vol`;
                const wordForExport = protocol === 'cifs' ? 'share':'export';
                const wordForExportCapitalized = capitalize(wordForExport);
                const exportParam = isTargetStepFromSameSourceType ? `root${wordForExportCapitalized}Target` : `root${wordForExportCapitalized}`;
                const pathParam = isTargetStepFromSameSourceType ? `${wordForExport}PathTarget` : `${wordForExport}Path`;

                const stateParamsToClear = [exportParam, volParam, versionParam, hostParam, pathParam];

                clearSelectedParams(stateParamsToClear);
            }



            return {
                [paramName] : we
            }
        }

    }
    return (isFetching({...props, ...moreStepInfo}) ? LoadingComp : <WeStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>)
}


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _servers: buildServersList(state),
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _fsxWorkingEnvironments: state.syncReducer._fsxWorkingEnvironments,
        _relationships: getPropFromState(state, "_relationships", {}),
        _cmIntegration: state.global._cmIntegration,
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getFSXWorkingEnvironments,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(FsxWeStep);
