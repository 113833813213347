import React from 'react';
import Isvg from 'react-inlinesvg';
import classNames from "classnames";
import "./loading-icon.scss";

const LoadingIcon = ({icon, text, className, removeLoading}) => {
    return (
        <div className={classNames("loading-icon", className)}>
             <span><Isvg src={icon?.default || icon}/></span>
            <div className="text">
                {!removeLoading? "Loading": ""} {text}
            </div>
        </div>
    )
};

export default LoadingIcon;
