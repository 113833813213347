import React from 'react'
import {SelectField} from "./forms";
import {Field} from 'redux-form';

const SelectReduxField = ({data, handleChangeFunction, placeholder, label, displayName, fieldName, isDisabled, disabledTitle}) => {
    const options = data || [];
    const title = isDisabled ? disabledTitle : "";

    return (
        <div title={title}>
            <Field component={SelectField}
                   onChange={handleChangeFunction}
                   name={fieldName}
                   options={options}
                   label={label}
                   isLoading={!isDisabled && !data}
                   placeholder={placeholder}
                   valueField="value"
                   isDisabled={isDisabled}
                   noOptionsMessage={() => `No ${displayName} available`}
            />
        </div>
    )
};

export default SelectReduxField;
