import React from "react";
import {ReactComponent as Warning} from '../../assets/svgs/notification-icon-warning.svg'
import {ReactComponent as Info} from '../../assets/svgs/notification-icon-info.svg'
import {ReactComponent as Error} from '../../assets/svgs/notification-icon-error.svg'
import "./notice.scss";

const Notice = ({children, type = "warning", noticeTitle, noIcon}) => {
    return <div className={`notice-container ${type}`}>
        {!noIcon && <div className="notice-icon">
            {type === "warning" && <Warning/>}
            {type === "info" && <Info/>}
            {type === "error" && <Error/>}
        </div>}
        <div className="notice-body">
            {type !== "info"  && <span className="notice-title">{noticeTitle? `${noticeTitle}:` : "Notice:"}</span>}
            {children}
        </div>
    </div>
};

export default Notice;
