import axios from 'axios';
import {store} from '../store/store';
import {getAccountId} from "../utils/data-utils";
import {
    getCMVolumesMocked,
    getCMWorkingEnvironmentsMocked,
    getFSXVolumesMocked,
    getFSXWorkingEnvironmentsMocked
} from '../simulator/cm.api.service.simulator'
import {buildCMApiPath} from "../utils/cm-integration-utils";


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CM_API_BASE_URL
});

const isSimulator = process.env.REACT_APP_SIMULATE_CM_API === "true";
const isDemo = process.env.REACT_APP_SIMULATE_API === "true";

const extractData = (response) => {
    return {...response.data};
};

const extractyArrayData = (response) => {
    return response.data;
}

const getHeaders = () => {
    const accessToken = store.getState().global.auth.accessToken;
    const {agentId} = store.getState().global._cmIntegration.general;
    const accountId = getAccountId();
    return isDemo ?
        {headers: {Authorization: accessToken, "x-Agent-id": agentId, "x-tenancy-account-id": accountId, "x-simulator" : "true", referer:'cs'}} :
        {headers: {Authorization: accessToken, "x-Agent-id": agentId, "x-tenancy-account-id": accountId, referer:'cs'}};
};

const service = {
    getCMWorkingEnvironments: (workspaceId) => {
        if (!workspaceId) return Promise.resolve({noWorkspaceId: true})
        const {agentId} = store.getState().global._cmIntegration.general;
        if (!agentId) return Promise.resolve({noAgent: true}) //connector is off. will give error in drag source and target
        if (isSimulator ) return Promise.resolve(getCMWorkingEnvironmentsMocked(workspaceId))
        return axiosInstance.get(`/occm/api/working-environments?tenantId=${workspaceId}&fields=name,status`, getHeaders()).then(extractData);
    },
    getCMVolumes: (provider, isHA, weId) => {
        if (isSimulator) return Promise.resolve(getCMVolumesMocked())
        const {agentId} = store.getState().global._cmIntegration.general;
        if (!agentId) return Promise.resolve([]) //connector is off. will give error in drag source and target
        const path = buildCMApiPath(provider, isHA);
        return axiosInstance.get(`/occm/api/${path}/volumes?workingEnvironmentId=${weId}`, getHeaders()).then(extractData);
    },
    getFSXWorkingEnvironments: (workspaceId) => {
        if (!workspaceId) return Promise.resolve({noWorkspaceId: true})
        const {agentId} = store.getState().global._cmIntegration.general;
        if (!agentId) return Promise.resolve({noAgent: true}) //connector is off. will give error in drag source and target
        if (isSimulator ) return Promise.resolve(getFSXWorkingEnvironmentsMocked())
        const accountId = getAccountId();
        return axiosInstance.get(`/fsx-ontap/working-environments/${accountId}?workspace-id=${workspaceId}&provider-details=true`, getHeaders()).then(extractyArrayData);
    },
    getFSXVolumes: (weId) => {
        if (isSimulator) return Promise.resolve(getFSXVolumesMocked())
        const {agentId} = store.getState().global._cmIntegration.general;
        if (!agentId) return Promise.resolve([]) //connector is off. will give error in drag source and target
        return axiosInstance.get(`/occm/api/fsx/volumes?fileSystemId=${weId}`, getHeaders()).then(extractData);
    }
};

export default service;
