import React, {useCallback, useEffect, useState} from 'react';
import Isvg from 'react-inlinesvg';
import classNames from "classnames";
import {Field} from 'redux-form';
import {InputField, SelectField} from '../../_common_/forms/forms.jsx';
import ButtonsPanel from '../../widgets/ButtonsPanel';
import WizardStepHeader from "./WizardStepHeaderNew";
import useServerStep from "./useServerStepNew";
import ChangeServer from './ChangeServerNew';
import useShowHowItWorks from "./useShowHowItWorksNew";
import {flatten, map, uniq, includes} from 'lodash'
import {CIFS_VERSIONS, CM_NFS_VERSIONS, END_POINT_NAMES_MAP} from "../../../consts";
import "./serverStepTemplateNew.scss";
import { addNotification, clearNotifications } from '../../../store/global/action_creators.js';
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts.js';
import { useDispatch } from 'react-redux';

const VolumeStepTemplate = (props) => {
    const {handleSubmit, up, _wizard, stepOptions, change, _relationships, name} = props;
    const {title, serverSVG, SecondScreenUi, serverTitle, selectType, volumesOptions, onChangeVersion, serverType,
        changeServerTitle, getCmVolumeParams} = stepOptions;
    const {selectedVolumeId, volumeSelectionElement, volumeSelectionReady} = volumesOptions;
    const [volumeInfo, setVolumeInfo] = useState(null);
    const  [list, setList] = useState(null);
    const dispatch = useDispatch();

    const {step, setStep, submit, scrollArea, selectedVersionValue,
        error, resetCredentialsInput, setSelectedIp, setSelectedVersionValue} = useServerStep({...props, getExtraPath: () => getCmVolumeParams(volumeInfo?.volumeId, volumeInfo?.volumeRoot), stepType: "volumes"});

    const setVolumeInStep = useCallback((volume) => {
        if (!volume) return;
        const {version, listOfNFSVersions, listOfCifsVersions, ipAddress, id, root} = volume;
        if (id === volumeInfo?.volumeId) return;

        setVolumeInfo({volumeId: id, volumeRoot: root});
        if (version) { //use the volume version
            if (onChangeVersion) {
                onChangeVersion(version);
            }
            setSelectedIp(ipAddress);
            setSelectedVersionValue(version);

        } else {
            setSelectedIp(ipAddress)
        }
        if (listOfNFSVersions) {
            let uniqVersions = uniq(listOfNFSVersions);
            if (includes(uniqVersions, "any") || includes(uniqVersions, "nfs")) { //for fsx nfs is like any
               uniqVersions = ["any"];
            }
            const versions = map(uniqVersions, uniqueVersion => {
                return CM_NFS_VERSIONS[uniqueVersion] ? [...CM_NFS_VERSIONS[uniqueVersion]] : []
            });
            setList(flatten(versions))
        }
        if (listOfCifsVersions) {
            setList(CIFS_VERSIONS)
        }
        change("serverName", ipAddress);
        resetCredentialsInput();

        clearNotifications()(dispatch);
    }, [change, onChangeVersion, dispatch, setVolumeInfo, setSelectedIp, setSelectedVersionValue, resetCredentialsInput,
        volumeInfo, setList]);

    const changeVersion = (selectedVersion) => {
        setSelectedVersionValue(selectedVersion.value)
        if (onChangeVersion) {
            onChangeVersion(selectedVersion);
        }
    };

    const selectVolumeComp = React.cloneElement(volumeSelectionElement, {setVolumeInStep})
    const {showHowItWorks, setShowHowItWorks, HowItWorks} = useShowHowItWorks({_relationships, _wizard, selectedParam: selectedVolumeId, name});
    const selectedOption = {value: selectedVersionValue, label: selectedVersionValue};

    useEffect(() => {
        if (!showHowItWorks && error) {
            addNotification({
                id: NOTIFICATION_CONSTS.UNIQUE_IDS.VOLUME_STEP_NOTIFICATION,
                type: NOTIFICATION_CONSTS.TYPE.ERROR,
                children: error
            })(dispatch);
        }
    }, [dispatch, showHowItWorks, error]);

    return (<>
        {!volumeSelectionReady ? <div>Nothing is there</div> :
            <div className="wizard-step server-step">
                <form className="wizard-step-form" onSubmit={handleSubmit(submit)}>
                    <div className={classNames("scrollable-area", {up, down: !up})} ref={scrollArea}>

                        {showHowItWorks && <HowItWorks/>}

                        {!showHowItWorks && <>

                            <WizardStepHeader
                                _wizard={_wizard}
                                title={title}
                            />
                            {list && step === "credentials" &&
                            <div className="version">
                                <div>{END_POINT_NAMES_MAP[serverType]} Version :</div>
                                <Field component={SelectField}
                                       selectedValue={selectedOption}
                                       variant="inline"
                                       onChange={changeVersion} valueField="value"
                                       name="version"
                                       removeBottomMargin={true}
                                       options={list} className="nfs-version-cb"
                                       isSearchable={false}/>
                            </div>
                            }

                            {step === "ip" && selectVolumeComp}

                            {step === 'credentials' && <>
                                <div className="align-center selected-server">
                                    <div className="new-nfs selected">
                                        <Isvg src={require(`./../../../assets/svgs/${serverSVG}`)}/>
                                        <Field name="serverName"
                                               className="nfs-address"
                                               component={InputField}
                                               label={`Selected ${serverTitle} ${selectType}:`}
                                               disabled={true}/>
                                        <ChangeServer title={changeServerTitle || "Change Server"}  onClick={() => {
                                            setStep('ip')
                                        }}/>
                                    </div>
                                </div>
                                <SecondScreenUi {...props} version={selectedVersionValue}/>
                            </>}
                        </>}
                    </div>

                    {showHowItWorks && <ButtonsPanel
                        variant="white"
                        type="button"
                        onClick={()=>{setShowHowItWorks(false)}}
                        text="Continue"/>}

                    {!showHowItWorks && <ButtonsPanel
                        variant="white"
                        type="submit"
                        text="Continue"/>}

            </form>
        </div>}
        </>)
};

export default VolumeStepTemplate;
