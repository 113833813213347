import { useSelector } from "react-redux";
import { apiSlice, skip } from "./api.slice";
import { accountInfoSelector } from "../store/selectors/accountInfo.selector";
import { FeatureFlags } from "../utils/featureFlagUtil";
import { encryptionType } from "../store/store.types";
import { wizardSelector } from "../store/selectors/wizard.selector";

export interface GroupApiParams {
    accountId: string,
    groupId: string,
    bucket: string
}

const groupApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        requestListGcpKmsKeys: builder.query<void, GroupApiParams>({
            query: ({ accountId, groupId, bucket }) => {
                return {
                    url: `accounts/${accountId}/groups/${groupId}/list-gcp-kms-keys`,
                    params: { bucket }
                }
            },
            providesTags: [{ type: 'groups' }]
        })
    })
});

export const useRequestListGcpKmsKeysProxy = (dataBrokerGroupId: string, googleEncryptType: encryptionType) => {
    const { useRequestListGcpKmsKeysQuery } = groupApiSlice;
    const accountId = useSelector(accountInfoSelector.accountId);
    const targetGcpBucket = useSelector(wizardSelector.targetGcpBucket);
    const { name: selectedTargetGoogleBucketPath } = targetGcpBucket || {}
    
    useRequestListGcpKmsKeysQuery({
        accountId: accountId!,
        groupId: dataBrokerGroupId,
        bucket: selectedTargetGoogleBucketPath!
    }, { skip: skip(accountId, FeatureFlags.CS_7644, !selectedTargetGoogleBucketPath || googleEncryptType !== 'managed')})
}