import MockAdapter from 'axios-mock-adapter';

export const mockAzureRoutes = (axiosInstance) => {
    mockAxiosRoutes(axiosInstance, azureRoutes);
};

export const mockGcpRoutes = (axiosInstance) => {
    mockAxiosRoutes(axiosInstance, gcpRoutes);
};

export const mockAwsRoutes = (axiosInstance) => {
    mockAxiosRoutes(axiosInstance, awsRoutes);
};

const mockAxiosRoutes = (axiosInstance, routes) => {
    const mock = new MockAdapter(axiosInstance, {onNoMatch: 'passthrough'});
    routes(mock);
};

const awsRoutes = (mock) => {
    mock.onGet(/\/aws\/describe-vpcs$/).reply(200, {
        "Vpcs": [
            {
                "CidrBlock": "10.1.0.0/24",
                "VpcId": "vpc-1",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "VPC-1"
                    }
                ]
            },
            {
                "CidrBlock": "10.2.0.0/24",
                "VpcId": "vpc-2",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "VPC-2"
                    }
                ]
            },
            {
                "CidrBlock": "10.3.0.0/24",
                "VpcId": "vpc-3",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "VPC-3"
                    }
                ]
            }
        ]
    })
        .onGet(/\/aws\/describe-subnets$/).reply(200, {
        "Subnets": [
            {
                "CidrBlock": "10.10.10.0/24",
                "SubnetId": "subnet-11",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "SUBNET-11"
                    }
                ],
                "VpcId": "vpc-1"
            },
            {
                "CidrBlock": "10.10.20.0/24",
                "SubnetId": "subnet-12",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "SUBNET-12"
                    }
                ],
                "VpcId": "vpc-1"
            },
            {
                "CidrBlock": "10.10.30.0/24",
                "SubnetId": "subnet-21",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "SUBNET-21"
                    }
                ],
                "VpcId": "vpc-2"
            },
            {
                "CidrBlock": "10.10.30.0/24",
                "SubnetId": "subnet-31",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "SUBNET-31"
                    }
                ],
                "VpcId": "vpc-3"
            },
            {
                "CidrBlock": "10.10.40.0/24",
                "SubnetId": "subnet-32",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "SUBNET-32"
                    }
                ],
                "VpcId": "vpc-3"
            },
            {
                "CidrBlock": "10.10.50.0/24",
                "SubnetId": "subnet-33",
                "Tags": [
                    {
                        "Key": "Name",
                        "Value": "SUBNET-33"
                    }
                ],
                "VpcId": "vpc-3"
            }
        ]
    })
        .onGet(/\/aws\/describe-key-pairs$/).reply(200, {
        "KeyPairs": [
            {
                "KeyName": "Red_Key"
            },
            {
                "KeyName": "Green_Key"
            },
            {
                "KeyName": "Blue_Key"
            },
            {
                "KeyName": "Yellow_Key"
            }
        ]
    })
        .onGet(/\/aws\/describe-aliases-kms$/).reply(200, {
        "Aliases": [
            {
                "AliasArn": "arn:aws:kms:us-east-1:464262061435:alias/kms2",
                "AliasName": "alias/kms2",
                "CreationDate": 1516003564.695,
                "LastUpdatedDate": 1516003564.695,
                "TargetKeyId": "14efb9b4-ca18-4421-94e9-ff6504fd5ac1"
            },
            {
                "AliasArn": "arn:aws:kms:us-east-1:464262061435:alias/kms1",
                "AliasName": "alias/kms1",
                "CreationDate": 1676213868.225,
                "LastUpdatedDate": 1676213868.225,
                "TargetKeyId": "cc20d391-a124-4bda-b059-b672828179eb"
            },
            {
                "AliasArn": "arn:aws:kms:us-east-1:464262061435:alias/aws/ebs",
                "AliasName": "alias/aws/ebs",
                "CreationDate": 1.459762458349E9,
                "LastUpdatedDate": 1.459762458349E9,
                "TargetKeyId": "1f7d68b4-0971-4c23-9e17-58d411be4db9"
            }
        ],
        "Truncated": false
    })

        .onGet(/\/aws\/describe-stacks\/deploy-error$/).reply(200, {
        "Stacks": [
            {
                "StackId": "StackError",
                "StackStatus": "CREATE_FAILED",
                "StackName": "StackError",
                "StackStatusReason": {message: "This is deploy error in AWS"}
            }
        ]
    })

        .onGet(/\/aws\/describe-stacks$/).reply(200, {
        "Stacks": [
            {
                "StackId": "stack1",
                "StackStatus": "CREATE_COMPLETE",
                "StackName": "stack1",
                "StackStatusReason": null
            }
        ]
    })
        .onPost(/\/aws\/create-stack/).reply(config => {
        const data = JSON.parse(config.data);
        if (data.StackName.startsWith("waitingdatabrokererror")) return [200, {"StackId": "StackError"}];
        return [200, {"StackId": "stack1"}]
    })

        .onPost(/\/aws\/delete-stack/).reply(200, {})
};

const azureRoutes = (mock) => {
    mock.onGet(/\/azure\/subscriptions\?api-version=\S+/).reply((config) => {
       if (config.url.includes('skiptoken')){
           return [200, {"value": [
                   {
                       "id": "/subscriptions/subscription4",
                       "subscriptionId": "subscription4",
                       "displayName": "Subscription4",
                       "state": "Enabled"
                   },
                   {
                       "id": "/subscriptions/subscription5",
                       "subscriptionId": "subscription5",
                       "displayName": "Subscription5",
                       "state": "Enabled"
                   },
                   {
                       "id": "/subscriptions/subscription6",
                       "subscriptionId": "subscription6",
                       "displayName": "Subscription6",
                       "state": "Enabled"
                   }
               ],
           }]
       } else {
           return [200, {"value": [
                   {
                       "id": "/subscriptions/subscription1",
                       "subscriptionId": "subscription1",
                       "displayName": "Subscription1",
                       "state": "Enabled"
                   },
                   {
                       "id": "/subscriptions/subscription2",
                       "subscriptionId": "subscription2",
                       "displayName": "Subscription2",
                       "state": "Enabled"
                   },
                   {
                       "id": "/subscriptions/subscription3",
                       "subscriptionId": "subscription3",
                       "displayName": "Subscription3",
                       "state": "Enabled"
                   }
               ],
               "nextLink": `${config.baseURL}/${config.url}?&skiptoken=1`
           }]
       }
})
        .onGet(/\/azure\/subscriptions\/\S+\/providers\/Microsoft.MarketplaceOrdering\/offerTypes\/virtualmachine\/publishers\/erockyenterprisesoftwarefoundationinc1653071250513\/offers\/rockylinux-9\/plans\/rockylinux-9\/agreements\/current\?api-version=\S+/).reply(200, {
        "properties": {
            "licenseTextLink": "link",
            "privacyPolicyLink": "link",
            "marketplaceTermsLink": "link",
            "retrieveDatetime": "01-01-2023",
            "signature": "signature"
        }
    })
        .onPut(/\/azure\/subscriptions\/\S+\/providers\/Microsoft.MarketplaceOrdering\/offerTypes\/virtualmachine\/publishers\/erockyenterprisesoftwarefoundationinc1653071250513\/offers\/rockylinux-9\/plans\/rockylinux-9\/agreements\/current\?api-version=\S+/).reply(200, {})
        .onGet(/\/azure\/subscriptions\/\S+\/providers\/Microsoft.Network\/virtualNetworks\?api-version=\S+/).reply((config) => {
        if (config.url.includes('skiptoken')){
            return [200, {"value": [
                    {
                        "id": "/subscriptions/subscription1/resourceGroups/rg1/providers/Microsoft.Network/virtualNetworks/vnet2",
                        "name": "vnet4",
                        "location": "centralus",
                        "properties": {
                            "subnets": [
                                {
                                    "id": "/subscriptions/subscription1/resourceGroups/rg2/providers/Microsoft.Network/virtualNetworks/vnet2/subnets/subnet-1",
                                    "name": "subnet-1",
                                    "properties": {
                                        "provisioningState": "Succeeded"
                                    }
                                },
                                {
                                    "id": "/subscriptions/subscription1/resourceGroups/rg2/providers/Microsoft.Network/virtualNetworks/vnet2/subnets/subnet-2",
                                    "name": "subnet-2",
                                    "properties": {
                                        "provisioningState": "Succeeded"
                                    }
                                }
                            ]
                        }
                    }
                ],
            }]
        } else {
            return [200, {"value": [
                    {
                        "id": "/subscriptions/subscription1/resourceGroups/rg1/providers/Microsoft.Network/virtualNetworks/vnet1",
                        "name": "vnet1",
                        "location": "centralus",
                        "properties": {
                            "subnets": [
                                {
                                    "id": "/subscriptions/subscription1/resourceGroups/rg2/providers/Microsoft.Network/virtualNetworks/vnet1/subnets/subnet-1",
                                    "name": "subnet-1",
                                    "properties": {
                                        "provisioningState": "Succeeded"
                                    }
                                },
                                {
                                    "id": "/subscriptions/subscription1/resourceGroups/rg2/providers/Microsoft.Network/virtualNetworks/vnet1/subnets/subnet-2",
                                    "name": "subnet-2",
                                    "properties": {
                                        "provisioningState": "Succeeded"
                                    }
                                }
                            ]
                        }
                    }
                ],
                "nextLink": `${config.baseURL}/${config.url}?&skiptoken=1`
            }]
        }
    })
    .onGet(/\/azure\/subscriptions\/\S+\/providers\/Microsoft.Network\/networkSecurityGroups\?api-version=\S+/).reply((config) => {
            if (config.url.includes('skiptoken')) {
                return [200, {"value": [
                        {
                            "name": "nsg1",
                            "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1",
                            "type": "Microsoft.Network/networkSecurityGroups",
                            "location": "centralus",
                            "properties": {
                              "provisioningState": "Succeeded",
                              "securityRules": [],
                              "defaultSecurityRules": [
                                {
                                  "name": "AllowVnetInBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1/defaultSecurityRules/AllowVnetInBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow inbound traffic from all VMs in VNET",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "VirtualNetwork",
                                    "destinationAddressPrefix": "VirtualNetwork",
                                    "access": "Allow",
                                    "priority": 65000,
                                    "direction": "Inbound"
                                  }
                                },
                                {
                                  "name": "AllowAzureLoadBalancerInBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1/defaultSecurityRules/AllowAzureLoadBalancerInBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow inbound traffic from azure load balancer",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "AzureLoadBalancer",
                                    "destinationAddressPrefix": "*",
                                    "access": "Allow",
                                    "priority": 65001,
                                    "direction": "Inbound"
                                  }
                                },
                                {
                                  "name": "DenyAllInBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1/defaultSecurityRules/DenyAllInBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Deny all inbound traffic",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "*",
                                    "destinationAddressPrefix": "*",
                                    "access": "Deny",
                                    "priority": 65500,
                                    "direction": "Inbound"
                                  }
                                },
                                {
                                  "name": "AllowVnetOutBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1/defaultSecurityRules/AllowVnetOutBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow outbound traffic from all VMs to all VMs in VNET",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "VirtualNetwork",
                                    "destinationAddressPrefix": "VirtualNetwork",
                                    "access": "Allow",
                                    "priority": 65000,
                                    "direction": "Outbound"
                                  }
                                },
                                {
                                  "name": "AllowInternetOutBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1/defaultSecurityRules/AllowInternetOutBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow outbound traffic from all VMs to Internet",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "*",
                                    "destinationAddressPrefix": "Internet",
                                    "access": "Allow",
                                    "priority": 65001,
                                    "direction": "Outbound"
                                  }
                                },
                                {
                                  "name": "DenyAllOutBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg1/providers/Microsoft.Network/networkSecurityGroups/nsg1/defaultSecurityRules/DenyAllOutBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Deny all outbound traffic",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "*",
                                    "destinationAddressPrefix": "*",
                                    "access": "Deny",
                                    "priority": 65500,
                                    "direction": "Outbound"
                                  }
                                }
                              ]
                            }
                          }
                    ],
                }]
            } else {
                return [200, {"value": [
                        {
                            "name": "nsg3",
                            "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3",
                            "type": "Microsoft.Network/networkSecurityGroups",
                            "location": "centralus",
                            "properties": {
                              "provisioningState": "Succeeded",
                              "securityRules": [],
                              "defaultSecurityRules": [
                                {
                                  "name": "AllowVnetInBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3/defaultSecurityRules/AllowVnetInBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow inbound traffic from all VMs in VNET",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "VirtualNetwork",
                                    "destinationAddressPrefix": "VirtualNetwork",
                                    "access": "Allow",
                                    "priority": 65000,
                                    "direction": "Inbound"
                                  }
                                },
                                {
                                  "name": "AllowAzureLoadBalancerInBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3/defaultSecurityRules/AllowAzureLoadBalancerInBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow inbound traffic from azure load balancer",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "AzureLoadBalancer",
                                    "destinationAddressPrefix": "*",
                                    "access": "Allow",
                                    "priority": 65001,
                                    "direction": "Inbound"
                                  }
                                },
                                {
                                  "name": "DenyAllInBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3/defaultSecurityRules/DenyAllInBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Deny all inbound traffic",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "*",
                                    "destinationAddressPrefix": "*",
                                    "access": "Deny",
                                    "priority": 65500,
                                    "direction": "Inbound"
                                  }
                                },
                                {
                                  "name": "AllowVnetOutBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3/defaultSecurityRules/AllowVnetOutBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow outbound traffic from all VMs to all VMs in VNET",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "VirtualNetwork",
                                    "destinationAddressPrefix": "VirtualNetwork",
                                    "access": "Allow",
                                    "priority": 65000,
                                    "direction": "Outbound"
                                  }
                                },
                                {
                                  "name": "AllowInternetOutBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3/defaultSecurityRules/AllowInternetOutBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Allow outbound traffic from all VMs to Internet",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "*",
                                    "destinationAddressPrefix": "Internet",
                                    "access": "Allow",
                                    "priority": 65001,
                                    "direction": "Outbound"
                                  }
                                },
                                {
                                  "name": "DenyAllOutBound",
                                  "id": "/subscriptions/subid/resourceGroups/rg2/providers/Microsoft.Network/networkSecurityGroups/nsg3/defaultSecurityRules/DenyAllOutBound",
                                  "properties": {
                                    "provisioningState": "Succeeded",
                                    "description": "Deny all outbound traffic",
                                    "protocol": "*",
                                    "sourcePortRange": "*",
                                    "destinationPortRange": "*",
                                    "sourceAddressPrefix": "*",
                                    "destinationAddressPrefix": "*",
                                    "access": "Deny",
                                    "priority": 65500,
                                    "direction": "Outbound"
                                  }
                                }
                              ]
                            }
                          }
                    ],
                    "nextLink": `${config.baseURL}/${config.url}?&skiptoken=1`
                }]
            }
        })
        .onGet(/\/azure\/subscriptions\/\S+\/locations\?api-version=\S+/).reply(200, {
        "value": [
            {
                "id": "/subscriptions/subscription1/locations/eastasia",
                "name": "eastasia",
                "displayName": "East Asia"
            },
            {
                "id": "/subscriptions/subscription1/locations/southeastasia",
                "name": "southeastasia",
                "displayName": "Southeast Asia"
            },
            {
                "id": "/subscriptions/subscription1/locations/centralus",
                "name": "centralus",
                "displayName": "Central US"
            },
            {
                "id": "/subscriptions/subscription1/locations/eastus",
                "name": "eastus",
                "displayName": "East US"
            },
            {
                "id": "/subscriptions/subscription1/locations/eastus2",
                "name": "eastus2",
                "displayName": "East US 2"
            },
            {
                "id": "/subscriptions/subscription1/locations/westus",
                "name": "westus",
                "displayName": "West US"
            },
            {
                "id": "/subscriptions/subscription1/locations/northcentralus",
                "name": "northcentralus",
                "displayName": "North Central US"
            },
            {
                "id": "/subscriptions/subscription1/locations/southcentralus",
                "name": "southcentralus",
                "displayName": "South Central US"
            },
            {
                "id": "/subscriptions/subscription1/locations/northeurope",
                "name": "northeurope",
                "displayName": "North Europe"
            }
        ]
    })
        .onGet(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Compute\/virtualMachines\/\S+\/?api-version=\S+/).reply(200, {
        identity: {
            principalId: 'principalId'
        }
    })
        .onGet(/\/azure\/subscriptions\/\S+\/resourcegroups\?api-version=\S+/).reply((config) => {
        if (config.url.includes('skiptoken')){
            return [200, {        "value": [
                    {
                        "id": "/subscriptions/subscription1/resourceGroups/resourceGroup-2",
                        "name": "resourceGroup-1",
                        "location": "centralus",
                        "properties": {
                            "provisioningState": "Succeeded"
                        }
                    }
                ]
            }]
        } else {
            return [200, {"value": [
                    {
                        "id": "/subscriptions/subscription1/resourceGroups/resourceGroup-1",
                        "name": "resourceGroup-1",
                        "location": "centralus",
                        "properties": {
                            "provisioningState": "Succeeded"
                        }
                    }
                ],
                "nextLink": `${config.baseURL}/${config.url}?&skiptoken=1`
            }]
        }
    })
        .onGet(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Resources\/deployments\/waitingdatabrokererror\S+\?api-version=\S+/).reply(200, {
        "properties": {"provisioningState": "Failed", "error": "This is Azure deploy error"}
    })
        .onGet(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Resources\/deployments\/\S+\?api-version=\S+/).reply(200, {
        "properties": {"provisioningState": "Succeeded"}
    })
        .onPost(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Resources\/deployments\/\S+\/validate\?api-version=\S+/).reply(200, {})
        .onPut(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Resources\/deployments\/\S+\?api-version=\S+/).reply(200, {})
        .onPut(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Authorization\/roleDefinitions\/\S+\?api-version=\S+/).reply(200, {
        id: 'roleId'
    })
        .onPut(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\/providers\/Microsoft.Authorization\/roleAssignments\/\S+\?api-version=\S+/).reply(200, {})
        .onPut(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\?api-version=\S+/).reply(200, {})
        .onDelete(/\/azure\/subscriptions\/\S+\/resourcegroups\/\S+\?api-version=\S+/).reply(200, {})
};

const gcpRoutes = (mock) => {
    mock.onGet(/\/gcp\/projects$/).reply(200, {
        'projects': [
            {
                'projectNumber': '1111111111111',
                'projectId': 'mockProjectId1',
                'lifecycleState': 'ACTIVE',
                'name': 'Mock 1',
                'createTime': '2019-11-22T15:56:31.955Z'
            },
            {
                'projectNumber': '22222222222222',
                'projectId': 'mockProjectId2',
                'lifecycleState': 'ACTIVE',
                'name': 'Mock 2',
                'createTime': '2019-02-16T19:13:52.036Z'
            },
            {
                'projectNumber': '333333333333',
                'projectId': 'mockProjectId3',
                'lifecycleState': 'ACTIVE',
                'name': 'Mock 3',
                'createTime': '2019-01-26T15:48:13.580Z'
            },
            {
                'projectNumber': '44444444444',
                'projectId': 'mockProjectId4',
                'lifecycleState': 'ACTIVE',
                'name': 'Mock 4',
                'createTime': '2018-12-26T17:11:21.056Z'
            }
        ]
    })
        .onGet(/\/gcp\/\S+\/regions$/).reply(200, {
        "id": "projects/cloudsync-dev-214020/regions",
        "items": [
            {
                "id": "1220",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-east1",
                "description": "asia-east1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-east1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-east1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-east1-b"
                ],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-east1",
                "kind": "compute#region"
            },
            {
                "id": "1370",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-east2",
                "description": "asia-east2",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-east2-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-east2-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-east2-a"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-east2",
                "kind": "compute#region"
            },
            {
                "id": "1250",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-northeast1",
                "description": "asia-northeast1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast1-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-northeast1",
                "kind": "compute#region"
            },
            {
                "id": "1390",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-northeast2",
                "description": "asia-northeast2",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast2-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast2-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast2-a"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-northeast2",
                "kind": "compute#region"
            },
            {
                "id": "1410",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-northeast3",
                "description": "asia-northeast3",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast3-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast3-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-northeast3-b"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-northeast3",
                "kind": "compute#region"
            },
            {
                "id": "1320",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-south1",
                "description": "asia-south1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-south1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-south1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-south1-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-south1",
                "kind": "compute#region"
            },
            {
                "id": "1260",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "asia-southeast1",
                "description": "asia-southeast1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-southeast1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-southeast1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/asia-southeast1-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/asia-southeast1",
                "kind": "compute#region"
            },
            {
                "id": "1280",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "australia-southeast1",
                "description": "australia-southeast1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/australia-southeast1-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/australia-southeast1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/australia-southeast1-b"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/australia-southeast1",
                "kind": "compute#region"
            },
            {
                "id": "1350",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "europe-north1",
                "description": "europe-north1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-north1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-north1-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-north1-a"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/europe-north1",
                "kind": "compute#region"
            },
            {
                "id": "1100",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "europe-west1",
                "description": "europe-west1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west1-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west1-d"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/europe-west1",
                "kind": "compute#region"
            },
            {
                "id": "1290",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "europe-west2",
                "description": "europe-west2",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west2-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west2-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west2-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/europe-west2",
                "kind": "compute#region"
            },
            {
                "id": "1300",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "europe-west3",
                "description": "europe-west3",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west3-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west3-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west3-b"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/europe-west3",
                "kind": "compute#region"
            },
            {
                "id": "1340",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "europe-west4",
                "description": "europe-west4",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west4-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west4-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west4-a"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/europe-west4",
                "kind": "compute#region"
            },
            {
                "id": "1380",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "europe-west6",
                "description": "europe-west6",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west6-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west6-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/europe-west6-a"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/europe-west6",
                "kind": "compute#region"
            },
            {
                "id": "1330",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "northamerica-northeast1",
                "description": "northamerica-northeast1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/northamerica-northeast1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/northamerica-northeast1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/northamerica-northeast1-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/northamerica-northeast1",
                "kind": "compute#region"
            },
            {
                "id": "1310",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "southamerica-east1",
                "description": "southamerica-east1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/southamerica-east1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/southamerica-east1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/southamerica-east1-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/southamerica-east1",
                "kind": "compute#region"
            },
            {
                "id": "1000",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-central1",
                "description": "us-central1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-central1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-central1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-central1-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-central1-f"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-central1",
                "kind": "compute#region"
            },
            {
                "id": "1230",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-east1",
                "description": "us-east1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-east1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-east1-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-east1-d"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-east1",
                "kind": "compute#region"
            },
            {
                "id": "1270",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-east4",
                "description": "us-east4",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-east4-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-east4-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-east4-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-east4",
                "kind": "compute#region"
            },
            {
                "id": "1210",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-west1",
                "description": "us-west1",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west1-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west1-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west1-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-west1",
                "kind": "compute#region"
            },
            {
                "id": "1360",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-west2",
                "description": "us-west2",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west2-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west2-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west2-a"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-west2",
                "kind": "compute#region"
            },
            {
                "id": "1420",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-west3",
                "description": "us-west3",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west3-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west3-b",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west3-c"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-west3",
                "kind": "compute#region"
            },
            {
                "id": "1430",
                "creationTimestamp": "1969-12-31T16:00:00.000-08:00",
                "name": "us-west4",
                "description": "us-west4",
                "status": "UP",
                "zones": [
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west4-c",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west4-a",
                    "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/zones/us-west4-b"
                ],
                "quotas": [],
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/us-west4",
                "kind": "compute#region"
            }
        ],
        "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions",
        "kind": "compute#regionList"
    })
        .onGet(/\/gcp\/\S+\/serviceAccounts$/).reply(200, {
        "accounts": [
            {
                "name": "projects/cloudsync-dev-214020/serviceAccounts/744787498589-compute@developer.gserviceaccount.com",
                "projectId": "cloudsync-dev-214020",
                "uniqueId": "100462458872578114074",
                "email": "744787498589-compute@developer.gserviceaccount.com",
                "displayName": "ServiceAccount1",
                "etag": "MDEwMjE5MjA=",
                "oauth2ClientId": "100462458872578114074"
            },
            {
                "name": "projects/cloudsync-dev-214020/serviceAccounts/cloudmanager-service-account@cloudsync-dev-214020.iam.gserviceaccount.com",
                "projectId": "cloudsync-dev-214020",
                "uniqueId": "102217358851946603445",
                "email": "cloudmanager-service-account@cloudsync-dev-214020.iam.gserviceaccount.com",
                "displayName": "ServiceAccount2",
                "etag": "MDEwMjE5MjA=",
                "oauth2ClientId": "102217358851946603445"
            },
            {
                "name": "projects/cloudsync-dev-214020/serviceAccounts/storage-admin@cloudsync-dev-214020.iam.gserviceaccount.com",
                "projectId": "cloudsync-dev-214020",
                "uniqueId": "118178822057866083432",
                "email": "storage-admin@cloudsync-dev-214020.iam.gserviceaccount.com",
                "displayName": "ServiceAccount3",
                "etag": "MDEwMjE5MjA=",
                "oauth2ClientId": "118178822057866083432"
            },
            {
                "name": "projects/cloudsync-dev-214020/serviceAccounts/deploy-data-broker@cloudsync-dev-214020.iam.gserviceaccount.com",
                "projectId": "cloudsync-dev-214020",
                "uniqueId": "110964961185446804836",
                "email": "deploy-data-broker@cloudsync-dev-214020.iam.gserviceaccount.com",
                "displayName": "ServiceAccount4",
                "etag": "MDEwMjE5MjA=",
                "oauth2ClientId": "110964961185446804836"
            }
        ]
    })
        .onGet(/\/gcp\/\S+\/getXpnHost$/).reply(200, {
        "name": "shared-host"
    })
        .onGet(/\/gcp\/\S+\/aggregated\/subnetworks\/listUsable$/).reply(200, {
        "items": [
            {
                "ipCidrRange": "1.1.1.1/10",
                "network": "https://www.googleapis.com/compute/v1/projects/shared-host/global/networks/default",
                "subnetwork": "https://www.googleapis.com/compute/v1/projects/shared-host/regions/east-us1/subnetworks/default"
            },
            {
                "ipCidrRange": "2.2.2.2/10",
                "network": "https://www.googleapis.com/compute/v1/projects/shared-host/global/networks/shared-network",
                "subnetwork": "https://www.googleapis.com/compute/v1/projects/shared-host/regions/east-us1/subnetworks/shared-network"
            }
        ]
    })
        .onGet(/\/gcp\/\S+\/regions\/\S+\/subnetworks$/).reply(200, {
        "items": [
            {
                "ipCidrRange": "1.2.3.4/10",
                "network": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/global/networks/default",
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/east-us1/subnetworks/default",
                "name": "default"
            },
            {
                "ipCidrRange": "2.2.2.2/10",
                "network": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/global/networks/network1",
                "selfLink": "https://www.googleapis.com/compute/v1/projects/cloudsync-dev-214020/regions/east-us1/subnetworks/network1",
                "name": "network1"
            }
        ]
    })
        .onGet(/\/gcp\/\S+\/global\/operations\/deploy-error$/).reply(200, {"error": "This is gcp deploy error"})
        .onGet(/\/gcp\/\S+\/global\/operations\/\S+/).reply(200, {"status": "DONE"})

        .onPost(/\/gcp\/\S+:testIamPermissions$/).reply(200, {
        "permissions": [
            "compute.networks.list",
            "compute.regions.list",
            "deploymentmanager.deployments.create",
            "deploymentmanager.deployments.delete",
            "deploymentmanager.operations.get",
            "iam.serviceAccounts.list"
        ]
    })
        .onPost(/\/gcp\/\S+\/global\/deployments\?\S+/).reply(config => {
        const data = JSON.parse(config.data);
        if (data.name.startsWith("waitingdatabrokererror")) return [200, {"name": "deploy-error", "status": "RUNNING"}];
        return [200, {"name": "deployment1", "status": "RUNNING"}]
    })
};

