import React from "react";
import classNames from "classnames";
import {STATUS_PROGRESS} from '../../../store/global/action_creators_consts';
import {TooltipInfo} from '@netapp/shared-components';
import {checkIfFailExist, noConnectionToAllBrokers} from '../../../utils/dashboard-utils';
import {useDialog} from "@netapp/shared-components"
import DownloadLogsDialog from '../DownloadLogsDialog';
import _ from "lodash";
import RelationshipRecommendations from "../../RelationshipRecommendations/RelationshipRecommendations";
import {Popover} from '@netapp/shared-components';
import {isCloudToCloud} from '../../../utils/relationshipUtils';
import {ReactComponent as ArrowIcon} from "./../../../assets/svgs/top-right-arrow-icon.svg";
import {ReactComponent as DownloadIcon} from "./../../../assets/svgs/download-no-fill.svg";
import { useNavigate } from 'react-router-dom';

require("./syncStatistics.scss");


const SyncStatistics = (props) => {
    const {relationship, isCardView, closeDialog, calledFrom, downloadDataBrokerLogs} = props;
    const {ui: {activityValues}} = relationship;
    const showObjects = isCloudToCloud(relationship);
    const {setDialog} = useDialog();
    const navigate = useNavigate();

    if (!activityValues) return <div/>;

    const isFailExist = checkIfFailExist(activityValues);

    const {_downloadLogsRequests} = props;
    const downloadLogsInProgress = _.get(_downloadLogsRequests, [relationship.relationshipId, 'status']) === STATUS_PROGRESS;
    const noConnectionToBrokers = noConnectionToAllBrokers(relationship.groupInfo.dataBrokers);
    const disableDownloadLogs = noConnectionToBrokers || downloadLogsInProgress;
    const downloadLogsTitle = noConnectionToBrokers ? 'No available data brokers' : '';

    const viewFailedClicked = () => {
        navigate({
            pathname: `/failed/${relationship.relationshipId}`,
            search: `${window.location.search}&calledFrom=${calledFrom}`
        })
        if (closeDialog) closeDialog();
    };

    const syncTimingElement = isCardView ?
        (<div>{relationship.ui.lastTransferredHeaderText}</div>) :
        (<div className="duration-popover">
            <TooltipInfo popoverClass="timing-info">{relationship.ui.lastTransferredHeaderText}</TooltipInfo>
            <div>Sync Timing</div>
        </div>);

    return (<div className="sync-info">
        <div className={classNames({"card-view": isCardView}, "sync-duration")}>
            {syncTimingElement}
        </div>
        <div className={classNames({"card-view": isCardView}, "phases-summary")}>
            <PhaseInfo title="Scan" data={activityValues.scan} showObjects={showObjects}/>
            <PhaseInfo title="Copy" data={activityValues.copy} showObjects={showObjects}/>
        </div>
        <div className="bottom-line">
            <div className="recommendations">
                {relationship.ui.recommendations && relationship.ui.recommendations[0] && <RelationshipRecommendations recommendations={relationship.ui.recommendations} variant='cardView' />}
            </div>
            <div className="links">
        <div className={classNames({"card-view": isCardView}, "info-links download-logs")}>
            <Popover popoverClass={classNames("download-logs text-popover", {'hidden': !downloadLogsInProgress})}
                     trigger="hover"
                          container={
                              <button type="button" disabled={disableDownloadLogs} title={downloadLogsTitle} className="download-logs"
                                      onClick={(el) => {
                                          setDialog(<DownloadLogsDialog sourceName='relationship' relationship={relationship}
                                                                         downloadDataBrokerLogs={downloadDataBrokerLogs}/>, el.target);
                                      }}>
                                  <span className="icon"><DownloadIcon/></span>
                                  <span className="text">Download Data Broker Logs</span>
                              </button>}>
                {`Request was sent. This process usually takes a couple of minutes. The logs will be saved to your web browser's download location.`}
            </Popover>
        </div>

        {isFailExist &&
        <div onClick={viewFailedClicked} className={classNames({"card-view": isCardView}, "info-links view-failed-files")}>
            <ArrowIcon/>View details about sync failures
        </div>}
            </div>
        </div>
    </div>);
};

export default SyncStatistics;

const DataDisplay = ({value, label, isFailed}) => (
    <div className={classNames("data-display", {"hidden": !value, "failed": isFailed})}>
        <div className="value">{value}</div>
        <div className="text">{label}</div>
</div>);

const StatusLine = ({data: {name, data}, showObjects}) => {
    const sumOriginalValues = Object.values(data).reduce((sum, {original})=> sum + original, 0);
    const isFailExists = (name === "Failed") &&  sumOriginalValues > 0;

    return (<div className={classNames({failed: isFailExists}, "status-line")}>
        <div className="line-title">{name}</div>
        <div className="details">
            {!showObjects && <DataDisplay value={data.dirs.formatted} label={data.dirs.label} isFailed={name === "Failed" && data.dirs.original > 0}/>}
            <DataDisplay value={data.files.formatted} label={showObjects ? "Objects" : data.files.label} isFailed={name === "Failed" && data.files.original > 0}/>
            <DataDisplay value={data.bytes.formatted} label={data.bytes.label} isFailed={name === "Failed" && data.bytes.original > 0}/>
        </div>
    </div>)
};

const PhaseInfo = ({title, data, showObjects}) => (<div className={`phase-info ${title}`}>
    <div className="phase-title">{title}</div>
    {data.map((statusData)=>(<StatusLine key={`${title}-${statusData.name}`} data={statusData} showObjects={showObjects}/>))}
</div>);
