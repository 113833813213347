import React from 'react';
import {convertBytesToSize} from "../../../utils/helpers";
import {ReactComponent as CheckmarkIcon} from "../../../assets/svgs/checkmark-fill-circle-icon.svg";
import {ReactComponent as AnfIcon} from "../../../assets/svgs/anf-default.svg";

import "./anfWeCardNew.scss";

const AnfWeCard = {
    className: () => "anf-we-card",
    renderContent: ({data}) => {
        const {statistics, name} = data;
        const {volumesCount, totalAllocatedBytes, totalUsedBytes, locationsCount} = statistics;
        const freeCapacityBytes = totalAllocatedBytes - totalUsedBytes;
        const freeCapacity = convertBytesToSize(freeCapacityBytes);
        const freeCapacityStr = `${freeCapacity.value} ${freeCapacity.resolution}`;
        return (<>
                <div className="checkmark"><CheckmarkIcon/></div>
                <div className="card-content">
                    <div className="card-icon"><AnfIcon/></div>
                    <div className="card-details">
                        <div className="we-name">{name}</div>
                        <div className="we-details">
                            <CardDetail value={locationsCount} name="Locations"/>
                            <CardDetail value={volumesCount} name="Volumes"/>
                            <CardDetail value={freeCapacityStr} name="Free Capacity"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default AnfWeCard;

const CardDetail = ({value, name}) => {
    return <div className="card-detail">
        <div className="value" title={value}>{value}</div>
        <div className="name">{name}</div>
    </div>
};
