import {useContext} from "react";
import {PanelContext} from '../context/right-panel-context';

const useRightPanel = () => {
    const {setPanelContent, closePanel} = useContext(PanelContext);
    if (setPanelContent === undefined) {
        throw new Error('useRightPanel must be used within a UserProvider')
    }
    return {setPanelContent, closePanel};
};

export default useRightPanel;