import {fixPath} from "../utils/encoders";
import Immutable from "seamless-immutable";
import _ from "lodash";


export const isObjExistInField = (key, state, obj) => {
    const fullKey = Immutable.getIn(state, key.concat(["data"]));
    return _.find(fullKey, obj);
};
export const isHostEqual = (key, payloadType, fileType, state, action) => {
    return isEqualByInnerKey(key, payloadType, state, action, "host");
};
export const isEqualByInnerKey = (key, payloadType, state, action, innerKey) => {
    return Immutable.getIn(state, key)[innerKey] === action.payload[payloadType][innerKey]
};
export const updateRetrievedAllInsideFolders = (state, key, path) => state.updateIn(key.concat(["folders", path]), root => root.set("retrievedAll", true));

export const updateFailedAndRetrievedAllInAllPlaces = (state, key, status, retrievedAll) => {
    const failedRetrieveStatus = status === 'FAILED';
    const isRetrievedAll = retrievedAll !== false;

    const updatedState = state.updateIn(key, show => {
        return show.set("retrievedAll", isRetrievedAll)
            .set("failedRetrieve", failedRetrieveStatus)
    });
    const updatedFolders = updatedState.updateIn(key.concat(["folders", "-"]), root => root.set("retrievedAll", isRetrievedAll));
    return updatedFolders;

};//The function is called in update folders action ( for NFS, CIFS and SFTP)
export const updateStoreForUpdateFoldersActionForFileServer = (key, payloadType, state, action, innerKey = "folders") => {
    const {[innerKey]:folders, status} = action.payload[payloadType];
    const {share} = action.payload[payloadType];
    let {path} = action.payload[payloadType];
    path = (share) ? (path ? `${fixPath(share)}${fixPath(path)}` : fixPath(share)) : path ;
    const dataFromState = Immutable.getIn(state, key);
    if (!dataFromState || !dataFromState.data || !dataFromState.data.length > 0 || !dataFromState.folders[path]) return state;
    if (status === 'FAILED') {
        return updateRetrievedAllInsideFolders(state, key, path);
    }
    const updatedState = state.updateIn(key.concat(["folders", path, "data"]), data => _.unionBy(data, (folders || []).map(folder => ({
        path: folder,
        actualPath: path + fixPath(folder)
    }))));
    return updateRetrievedAllInsideFolders(updatedState, key, path);
};
export const updateStoreForUpdateBoxFolders = (key, payloadType, state, action, driveId) => {
    const {folders, status} = action.payload[payloadType];
    const {folderName, folderId} = action.payload[payloadType];
    const path = folderName;
    const dataFromState = Immutable.getIn(state, key);
    if (!dataFromState || !dataFromState.data || !dataFromState.data.length > 0 || !dataFromState.folders[path]) return state;
    if (status === 'FAILED') {
        return updateRetrievedAllInsideFolders(state, key, path);
    }
    const updatedState = state.updateIn(key.concat(["folders", path, "data"]),
        data => _.unionBy(data, (folders || []).map(directory => ({
            path: directory.name,
            actualPath: path + fixPath(directory.name),
            folderId: directory.id,
            driveId  //relevant for google drive only
        }))))
        .updateIn(key.concat(["folders", path]), root => root.set("folderId", folderId));
    return updateRetrievedAllInsideFolders(updatedState, key, path);
};

export const updateStoreForUpdateGoogleDriveFolders = (key, payloadType, state, action) => {
    const {folders, status} = action.payload[payloadType];
    const {folderName, driveId, folderId} = action.payload[payloadType];
    const path = folderName;
    const dataFromState = Immutable.getIn(state, key);
    if (!dataFromState || !dataFromState.data || !dataFromState.data.length > 0 || !dataFromState.folders[path]) return state;
    if (status === 'FAILED') {
        return updateRetrievedAllInsideFolders(state, key, path);
    }
    const updatedState = state.updateIn(key.concat(["folders", path, "data"]),
        data => _.unionBy(data, (folders || []).map(directory => {
            const directoryId = directory.id;
            return ({
                path: directory.name,
                folderId: directoryId,
                actualPath: directoryId ? `${path}${fixPath(directory.name)}(${directoryId})` : `${path}${fixPath(directory.name)}`, //for the unique table identifier for google- since we can have two folders with the same name
                driveId,  //taken from the update_ action
                parentFolderId: folderId //taken from the update_ action - it is the id of the parent folder
            })
        })))
        .updateIn(key.concat(["folders", path]), root => root.set("folderId", folderId).set('driveId', driveId));
    return updateRetrievedAllInsideFolders(updatedState, key, path);
};
