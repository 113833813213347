import axios from "axios";
import {mockGcpRoutes} from '../simulator/cloudProvidersSimulator';

const useMockApi = process.env.REACT_APP_SIMULATE_API === 'true';
const mockBaseApi = 'http://gcp';

const axiosInstance = axios.create();
if (useMockApi) {
    mockGcpRoutes(axiosInstance);
}

const apisBase = {
    getProjects: useMockApi ? mockBaseApi : 'https://cloudresourcemanager.googleapis.com/v1',
    getServiceAccounts: useMockApi ? mockBaseApi : `https://iam.googleapis.com/v1/projects`,
    getRegions: useMockApi ? mockBaseApi : `https://www.googleapis.com/compute/v1/projects`,
    deploy: useMockApi ? mockBaseApi : 'https://www.googleapis.com/deploymentmanager/v2/projects',
    getOperationStatus: useMockApi ? mockBaseApi : 'https://www.googleapis.com/deploymentmanager/v2/projects',
    checkPermissions: useMockApi ? mockBaseApi : 'https://cloudresourcemanager.googleapis.com/v1/projects',
    getSubnets: useMockApi ? mockBaseApi : 'https://www.googleapis.com/compute/v1/projects',
    getHostedProject: useMockApi ? mockBaseApi : 'https://www.googleapis.com/compute/v1/projects',
    getUsableSubnets: useMockApi ? mockBaseApi : 'https://www.googleapis.com/compute/v1/projects'
};

const makeGapiRequest = (method, url, body, params) => {
    if (useMockApi) {
        return axiosInstance[method.toLowerCase()](url, body).then(res => res.data);
    }

    //eslint-disable-next-line no-undef
    return gapi.client.request({
        method,
        'path': url,
        body,
        params
    }).then(res => res.result).catch(res => {
        throw res.result;
    });
};

export const getProjectsList = async () => {
    let allProjects = [];
    let response = {
        projects: null,
        nextPageToken: null
    };
    try {
        do {
            const url = `${apisBase.getProjects}/projects${response?.nextPageToken ? '?pageToken=' + response?.nextPageToken : ''}`;
            response = await makeGapiRequest("GET", url);
            allProjects = [...allProjects, ...response.projects]
        } while (response.nextPageToken);
        return {projects: allProjects};
    }
    catch (e) {
        throw e;
    }
};

export const getServiceAccounts = async (projectId) => {
    let serviceAccounts = [];
    
    async function* pagination() {
        const url = `${apisBase.getServiceAccounts}/${projectId}/serviceAccounts`;
        let { accounts, nextPageToken } = await makeGapiRequest("GET", url);
        yield accounts;
        while (nextPageToken) {
            ({ accounts, nextPageToken } = await makeGapiRequest("GET", url, undefined, { pageToken: nextPageToken }));
            yield accounts;
        }
    }

    try {
        for await (const accounts of pagination()) {
            serviceAccounts = [...serviceAccounts, ...accounts];
        }
        return { accounts: serviceAccounts };
    } catch (error) {
        throw error;
    }
};

export const getRegions = (projectId) => {
    const url = `${apisBase.getRegions}/${projectId}/regions`;
    return makeGapiRequest("GET", url);
};

export const deploy = (payload, projectId) => {
    const url = `${apisBase.deploy}/${projectId}/global/deployments?preview=false`;
    return makeGapiRequest("POST", url, payload);
};

export const getOperationStatus = (projectId, operationName) => {
    const url = `${apisBase.getOperationStatus}/${projectId}/global/operations/${operationName}`;
    return makeGapiRequest("GET", url);
};

export const getDeployment = (projectId, deploymentName) => {
    const url = `${apisBase.deploy}/${projectId}/global/deployments/${deploymentName}`;
    return makeGapiRequest("GET", url);
};

export const stopDeployment = (projectId, deploymentName, payload) => {
    const url = `${apisBase.deploy}/${projectId}/global/deployments/${deploymentName}/stop`;
    return makeGapiRequest("POST", url, payload);
};

export const deleteDeployment = (projectId, deploymentName) => {
    const url = `${apisBase.deploy}/${projectId}/global/deployments/${deploymentName}`;
    return makeGapiRequest("DELETE", url);
};

export const gcpDeployPermissions = [
    'compute.networks.list',
    'compute.regions.list',
    'deploymentmanager.deployments.create',
    'deploymentmanager.deployments.delete',
    'deploymentmanager.operations.get',
    'iam.serviceAccounts.list'
];

export const checkProjectPermissions = (projectId) => {
    const payload = {
        permissions: gcpDeployPermissions
    };
    const url = `${apisBase.checkPermissions}/${projectId}:testIamPermissions`;
    return makeGapiRequest("POST", url, payload);
};

export const getHostedProject = (projectId) => {
    const url = `${apisBase.getHostedProject}/${projectId}/getXpnHost`;
    return makeGapiRequest("GET", url);
};

export const getUsableSubnets = (hostedProjectId) => {
    const url = `${apisBase.getUsableSubnets}/${hostedProjectId}/aggregated/subnetworks/listUsable`;
    return makeGapiRequest("GET", url);
};

export const getSubnets = (projectId, regionId) => {
    const url = `${apisBase.getSubnets}/${projectId}/regions/${regionId}/subnetworks`;
    return makeGapiRequest("GET", url);
};
