import React from 'react';
import {
    azureDataLakeStorageAccounts,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import ServerStepTemplate from "./ServerStepTemplateNew";
import {
    END_POINT_NAMES_MAP,
} from '../../../consts';
import {
    getPropFromState,
    getWizardParamsFromWizardState
} from '../../../utils/mapStateToPropsUtils';
import {validate} from "./additionalSettings/validators/azureValidatorNew";
import useStepinize from "../useStepinize";
import AzureAdditionalSettings from "./additionalSettings/AzureAdditionalSettingsNew";

const loading =  () => {return {text: END_POINT_NAMES_MAP.AZURE_DATA_LAKE, icon: require("../../../assets/svgs/azure_data_lake-default.svg")}};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetAzureDataLake' : 'azureDataLake';
};

const fetchData = ({azureDataLakeStorageAccounts}) => {
    azureDataLakeStorageAccounts();
};
const shouldFetch = (props) => {
    return !props._azureDataLakeStorageAccounts || !props._azureDataLakeStorageAccounts.succeeded;
};
const isFetching = ({_azureDataLakeStorageAccounts}) => _azureDataLakeStorageAccounts.inProgress || !_azureDataLakeStorageAccounts.data;

const AzureDataLakeStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

    const {_azureDataLakeStorageAccounts, _wizard, _ui: {azure}, state, clearSelectedParams} = props;
    const {selectedWizardParams: {selectedStorageAccountName, selectedStorageAccountNameTarget}} = _wizard;
    const selectedServer = isTargetStepFromSameSourceType ? selectedStorageAccountNameTarget : selectedStorageAccountName;
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["azureDataLakeContainerPathTarget"] : ["azureDataLakeContainerPath"];

    const stepOptions = {
        storage: _azureDataLakeStorageAccounts,
        server: selectedServer,
        serverSVG: "azure-default.svg",
        step: azure ? azure.step : "ip",
        serverType: "Azure Data Lake Gen 2",
        serverTitle: "Azure Data Lake Gen 2",
        selectType: "Storage Account",
        serverPlaceholder: "",
        title: `Select an ${END_POINT_NAMES_MAP.AZURE_DATA_LAKE} ${isSourceStep ? "Source" : "Target"}`,
        changeServerTitle: "Change Azure Storage Account",
        clearShowOptions: {keyToClear: ['_showAzureBlobContainers', selectedServer]},
        validate,
        SecondScreenUi: AzureAdditionalSettings,
        shouldGoToSecondScreen: () => true,
        stateParamsToClear,
        getSelectedParams: (host) => {
            const hostParam = isTargetStepFromSameSourceType ? 'storageAccountNameTarget' : 'storageAccountName';
            if ((state[hostParam] && state[hostParam] !== host)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam] : host
            }
        }

    };

    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

let AzureDataLakeStepForm = reduxForm({
    form: 'azureDataLake',
    validate,
    shouldError: () => true
})(AzureDataLakeStep);

const selector = formValueSelector('azureDataLake');
AzureDataLakeStepForm = connect(
    (state) => ({
        copyAcl : selector(state, "copyAcl")
    })
)(AzureDataLakeStepForm);

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _azureDataLakeStorageAccounts: getPropFromState(state, "_azureDataLakeStorageAccounts", {}),
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    azureDataLakeStorageAccounts,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(AzureDataLakeStepForm);
