export enum FeatureFlags {
    CS_7644 = 'sync global refactor',
    CS_7662 = 'add infra structure only',
    CS_7671 = 'relationshipCard refactor',
    CS_7677 = 'support google encryption'
}

const isFeatureFlag = (featureFlag: FeatureFlags): boolean => {
    const featureFlagMap = {
        [FeatureFlags.CS_7644]: true,
        [FeatureFlags.CS_7662]: true,
        [FeatureFlags.CS_7671]: false,
        [FeatureFlags.CS_7677]: true
    }

    return featureFlagMap[featureFlag];
}

export default isFeatureFlag;