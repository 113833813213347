import {
    getCMWorkingEnvironments,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {getOnpremWorkingEnvironments} from '../../../utils/data-utils';
import {buildServersList, getPropFromState} from '../../../utils/mapStateToPropsUtils';
import WeStepTemplate from "./WeStepTemplateNew";
import {capitalize, find} from 'lodash'
import OnpremWeCard from "./OnpremWeCardNew";
import "./cmWeStepNew.scss";
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";

const  loading = () => {
    return {
        className: 'cvo-we',
        text: 'Working Environments',
        icon: require('./../../../assets/svgs/onprem-default.svg')
    }
}

const stepId = ({isTargetStepFromSameSourceType, protocol}) => {
    const protocolCapitalized = capitalize(protocol);
    return isTargetStepFromSameSourceType ? `targetOnprem${protocolCapitalized}We` : `onprem${protocolCapitalized}We`;
};
const fetchData = (props) => {
    const {_cmIntegration: {general: {workspaceId}}} = props;
    props.getCMWorkingEnvironments(workspaceId);
};
const isFetching = ({_cmWorkingEnvironments}) => {
    return _cmWorkingEnvironments?.inProgress;
};

const shouldFetch = (props) => {
    return !props._cmWorkingEnvironments || (!props._cmWorkingEnvironments.inProgress && !props._cmWorkingEnvironments.succeeded)
};

const OnpremWeStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {isSourceStep, isTargetStepFromSameSourceType, protocol} = moreStepInfo;
    const {_cmWorkingEnvironments, _cmIntegration: {inputDetails}, clearSelectedParams, state} = props;
    const { viewDashboard, source, target} = inputDetails || {};
    const protocolCapitalized = capitalize(protocol);

    //in case it was selected in the CM gallery
    const showOnlyCmSelection = (isSourceStep ? source?.sourceWorkingEnvironmentType?.toLowerCase() === "onprem" : target?.targetWorkingEnvironmentType?.toLowerCase() === 'onprem') && !viewDashboard;
    const workingEnvironmentName = isSourceStep ? source?.sourceWorkingEnvironmentName : target?.targetWorkingEnvironmentName;

    const onpremWorkingEnvironments = useMemo(() => {
        const allWe = getOnpremWorkingEnvironments(_cmWorkingEnvironments);
        const selectedWe = find(allWe, {name: workingEnvironmentName});
        return showOnlyCmSelection && selectedWe ? [selectedWe] : allWe;
    }, [_cmWorkingEnvironments, showOnlyCmSelection, workingEnvironmentName]);

    //initialize selection:
    const stepOptions = {
        workingEnvironments: onpremWorkingEnvironments,
        selectedStepParamName: `selectedCvo${protocolCapitalized}We`,
        selectedTargetStepParamName: `selectedCvo${protocolCapitalized}WeTarget`,
        showOnlyCmSelection,
        WECard: OnpremWeCard,
        getSelectedParams: (selectedWE) => {
            const paramName = isTargetStepFromSameSourceType ?  `onprem${protocolCapitalized}WeTarget` :  `onprem${protocolCapitalized}We`;

            if (state[paramName] && state[paramName] !== selectedWE) {
                const hostParam = isTargetStepFromSameSourceType ? `${protocol}ServerTarget` : `${protocol}Server`;
                const versionParam = isTargetStepFromSameSourceType ? `target${protocolCapitalized}Version` : `${protocol}Version`;
                const volParam = isTargetStepFromSameSourceType? `${protocol}VolTarget` : `${protocol}Vol`;
                const wordForExport = protocol === 'cifs' ? 'share':'export';
                const wordForExportCapitalized = capitalize(wordForExport);
                const exportParam = isTargetStepFromSameSourceType ? `root${wordForExportCapitalized}Target` : `root${wordForExportCapitalized}`;
                const pathParam = isTargetStepFromSameSourceType ? `${wordForExport}PathTarget` : `${wordForExport}Path`;

                const stateParamsToClear = [exportParam, volParam, versionParam, hostParam, pathParam];

                clearSelectedParams(stateParamsToClear);
            }

            return {[paramName]: selectedWE}
        }
    }
    return (isFetching({...props, ...moreStepInfo}) ? LoadingComp : <WeStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>)
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _servers: buildServersList(state),
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _cmWorkingEnvironments: state.syncReducer._cmWorkingEnvironments,
        _relationships: getPropFromState(state, "_relationships", {}),
        _cmIntegration: state.global._cmIntegration,
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getCMWorkingEnvironments,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(OnpremWeStep);
