/*eslint-disable */
export const bootGoogleAnalytics = (applicationType, userEmail) => {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    window.ga('create', 'UA-115286337-2', 'auto');
    // dimension1 is a custom dimension that is defined in GA account, and represents Application Type. In order to filter the OCCM Integration related traffic.
    window.ga('set', 'dimension1', applicationType);
    window.ga('set', 'email', userEmail);
    window.ga('send', 'pageview'); // see also history.listen in index.jsx
};
/*eslint-enable */
