import React from 'react';
import {
    clearShowAction,
    clearUrlParamsIfChanged,
    getAnfVolumes,
    getCMVolumes,
    getCMWorkingEnvironments,
    getFSXVolumes,
    getServersByType,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted,
    clearFoldersCache,
} from './../../../store/wizard/action_creators';
import {buildServersList, getPropFromState} from "./../../../utils/mapStateToPropsUtils";
import {connect, useDispatch} from 'react-redux';
import {change, Field, reduxForm} from 'redux-form';
import {
    ACL_DISABLED_MESSAGE,
    DEFAULT_NFS_VERSION,
    END_POINT_NAMES_MAP,
    HOST_IPV4_IPV6_REG_EXP,
    HOST_NAME_REG_EXP,
    IP_REG_EXP,
    VOLUME_HAS_NO_EXPORT_NFS,
    CASH_KEYS,
} from './../../../consts';
import ServerStepTemplate from "./ServerStepTemplateNew";
import {calculateStepId, getCredentialsByType, getNfsVersionList} from './../../../utils/sync-utils';
import {fetchAnfVolumes, getIsIntegratedStep} from './../../../utils/wizard-utils';
import {isAclSupportedNfsVersion} from './../../../utils/relationshipUtils';
import {InputField} from '../../_common_/forms/forms';
import {find} from 'lodash'
import {getCvoWorkingEnvironments} from "./../../../utils/data-utils";
import {extractIpAddressAndPathFromUrl} from "./../../../utils/encoders";
import useStepinize, {buildMoreStepInfo} from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import AclSelection from "./AclSelectionNew";
import VolumeStepTemplate from "./VolumeStepTemplateNew";
import AnfVolSelection from "./AnfVolSelectionNew";
import CmVolSelection from "./CmVolSelectionNew";

const indefinite = require('indefinite');

const validate = ({serverName}) => {
    let errors = {};
    if (serverName && serverName.length > 256) {
        errors.serverName = "NFS hostname or IP address must be less than 256 characters";
    } else if (!IP_REG_EXP.test(serverName) && !HOST_NAME_REG_EXP.test(serverName) && !HOST_IPV4_IPV6_REG_EXP.test(serverName)) {
        errors.serverName = "Invalid hostname or IP address";
    }
    return errors;
};

const SecondScreenUi = ({version, endpointObj: {provider}, _wizard, isTargetStepFromSameSourceType}) => {
    const {protocols, isSecure} = _wizard;
    const showACL = protocols === "nfs-nfs" && !isTargetStepFromSameSourceType && !isSecure && isAclSupportedNfsVersion(version);
    const showAccessPoint = provider === "efs";
    return <>
        {showAccessPoint && <div className="credentials-container">
            <Field name="accessPoint"
                   component={InputField}
                   label="EFS Access Point ID"
                   optional={true}/>
        </div>}
        {showACL && <AclSelection aclMultiSelection={true}/>}
    </>;
};

const getWeByProvider = (selectedWizardParams, provider, isTargetStepFromSameSourceType) => {
    const {selectedAnfNfsWe, selectedAnfNfsWeTarget, selectedCvoNfsWe, selectedCvoNfsWeTarget, selectedFsxNfsWeTarget, selectedFsxNfsWe,
        selectedOnpremNfsWeTarget, selectedOnpremNfsWe} = selectedWizardParams;
    if (provider === "anf") {
        return isTargetStepFromSameSourceType ? selectedAnfNfsWeTarget : selectedAnfNfsWe;
    }
    if (provider === "cvo") {
        return isTargetStepFromSameSourceType ? selectedCvoNfsWeTarget : selectedCvoNfsWe
    }
    if (provider === "onprem") {
        return isTargetStepFromSameSourceType ? selectedOnpremNfsWeTarget : selectedOnpremNfsWe
    }
    if (provider === "fsx") {
        return isTargetStepFromSameSourceType ? selectedFsxNfsWeTarget : selectedFsxNfsWe;
    }

}

const getVolumesByProvider = (we, provider, _anfVoluems, _cmVolumes, _fsxVolumes) => {
    if (provider === "anf") {
        return _anfVoluems[we]
    }
    if (provider === "cvo" || provider === "onprem") {
        return _cmVolumes[we];
    }
    if (provider === "fsx") {
        return _fsxVolumes[we]
    }
    return null;
}

const anfVolumeMapFunction = (vol, wasAclSelectedOnSource) => {
    return wasAclSelectedOnSource && !isAclSupportedNfsVersion(vol.version) ?
        {...vol, isDisabled: true, title: ACL_DISABLED_MESSAGE} : vol;}

const cmVolumeMapFunction = (vol, wasAclSelectedOnSource, filterFunction) => {
    if (wasAclSelectedOnSource && (!(vol.listOfNFSVersions||[]).includes("nfs4"))) {
        return {...vol, isDisabled: true, title: ACL_DISABLED_MESSAGE}
    }
    if (filterFunction(vol)){
        return {...vol, isDisabled: true, title: VOLUME_HAS_NO_EXPORT_NFS}
    }
    const mountPoint = vol.mountPoint;
    const params = extractIpAddressAndPathFromUrl(mountPoint);
    //will happen in case of an offline volume
    if (!params.actualPath) {
        return {...vol, isDisabled: true, title: VOLUME_HAS_NO_EXPORT_NFS}
    }
    return vol;
}



const getVolumeSelectionComp = (provider, volumes, selectedVolume, wasAclSelectedOnSource, refreshStep) => {
    if (provider === "anf") {
        return <AnfVolSelection volumes={volumes}
            protocol="nfs"
            defaultVolumeId={selectedVolume}
            volumesFilterFunction={(vol) => vol.protocol === "nfs" || vol.protocol === "dual"}
            volumeMapFunction={(vol) => anfVolumeMapFunction(vol, wasAclSelectedOnSource)}
            refreshStep={refreshStep}
        />
    } else {
        return <CmVolSelection volumes={volumes}
                               protocol="nfs"
                               defaultVolumeId={selectedVolume}
                               volumeMapFunction={(vol) => cmVolumeMapFunction(vol, wasAclSelectedOnSource, (vol) => !vol.mountPoint)}
                               refreshStep={refreshStep}
        />
    }
}

const stepId = ({_wizard: {wizardInfo}, isTargetStepFromSameSourceType}) => {
    return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "nfs");
};

const fetchData = (props) => {
    const {endpointObj, protocolAndProviderKey} = props;
    if (getIsIntegratedStep( endpointObj)) {
        if (endpointObj.provider === "anf") {
            fetchAnfVolumes(props, "nfs");
        }
        else if (endpointObj.provider === "cvo"){
            const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams}, _cmWorkingEnvironments} = props;
            const {selectedCvoNfsWe, selectedCvoNfsWeTarget} =  selectedWizardParams;
            const selectedWe = isTargetStepFromSameSourceType ? selectedCvoNfsWeTarget : selectedCvoNfsWe;
            const allWe = getCvoWorkingEnvironments(_cmWorkingEnvironments);
            const actualWE = find(allWe, {publicId: selectedWe})
            return props.getCMVolumes(actualWE.cloudProviderName, actualWE.isHA, selectedWe);
        }
        else if (endpointObj.provider === "onprem"){
            const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams}} = props;
            const {selectedOnpremNfsWe, selectedOnpremNfsWeTarget} =  selectedWizardParams;
            const selectedWe = isTargetStepFromSameSourceType ? selectedOnpremNfsWeTarget : selectedOnpremNfsWe;
            return props.getCMVolumes(endpointObj.provider, false, selectedWe);
        }
        else if (endpointObj.provider === "fsx"){
            const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams}} = props;
            const {selectedFsxNfsWe, selectedFsxNfsWeTarget} =  selectedWizardParams;
            const selectedWe = isTargetStepFromSameSourceType ? selectedFsxNfsWeTarget : selectedFsxNfsWe;
            clearFoldersCache({
                cacheName: CASH_KEYS._FSX_VOLUMES,
                storageKey: selectedWe,
            });
            return props.getFSXVolumes(selectedWe);
        }
    }
    else {
        props.getServersByType(endpointObj.protocol, endpointObj.provider, protocolAndProviderKey);
    }
};

const shouldFetch = (props) => {
    const {isTargetStepFromSameSourceType, endpointObj, protocolAndProviderKey,_wizard: {selectedWizardParams}} = props;
    if (getIsIntegratedStep( endpointObj)) {
        const relevantWe = getWeByProvider(selectedWizardParams, endpointObj.provider, isTargetStepFromSameSourceType);
        if (endpointObj.provider === "anf") {
            const {_anfVolumes} = props;
            return !_anfVolumes[relevantWe] || !_anfVolumes[relevantWe].succeeded;
        }
        if (endpointObj.provider === "cvo" || endpointObj.provider === "onprem") {
            const {_cmVolumes} = props;
            return !_cmVolumes[relevantWe] || !_cmVolumes[relevantWe].succeeded;
        }
        if (endpointObj.provider === "fsx") {
            const {_fsxVolumes} = props;
            return !_fsxVolumes[relevantWe] || !_fsxVolumes[relevantWe].succeeded;
        }
    }
    return (!props._servers || !props._servers[protocolAndProviderKey] || !props._servers[protocolAndProviderKey].succeeded)
};

const isFetching = (props) => {
    const { endpointObj, isTargetStepFromSameSourceType, _wizard: {selectedWizardParams}} = props;
    if (getIsIntegratedStep( endpointObj)) {
        const {provider} = endpointObj;
        const relevantWe = getWeByProvider(selectedWizardParams, provider, isTargetStepFromSameSourceType);
        if (provider === "anf") {
            const {_anfVolumes} = props;
            return _anfVolumes[relevantWe] && _anfVolumes[relevantWe].inProgress;
        }
        if (provider === "cvo" || provider === "onprem") {
            const {_cmVolumes} = props;
            return _cmVolumes[relevantWe] && _cmVolumes[relevantWe].inProgress;
        }
        if (provider === "fsx") {
            const {_fsxVolumes} = props;
            return _fsxVolumes[relevantWe] && _fsxVolumes[relevantWe].inProgress;
        }
    }
    else {
        const {_servers, protocolAndProviderKey} = props;
        return _servers[protocolAndProviderKey].inProgress;
    }
};



const loading = ({endpointObj}) => {
    const serverType = endpointObj.provider;
    return {
        text: `${END_POINT_NAMES_MAP[serverType.toUpperCase()]}`,
        icon: require(`./../../../assets/svgs/${serverType}-default.svg`)
    }
}


const NFSServerStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, protocolAndProviderKey, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;
    const {_servers, _anfVolumes, _cmVolumes, _fsxVolumes, _wizard, state, clearSelectedParams} = props;
    const {selectedWizardParams, isSecure, protocols} = _wizard;
    const {selectedNFS, selectedNFSTarget, nfsVersion, targetNfsVersion, selectedNfsVolTarget, selectedNfsVol} = selectedWizardParams;
    const dispatch = useDispatch();

    const provider = endpointObj.provider;
    const isVolumeStep = getIsIntegratedStep( endpointObj);
    const relevantWe = getWeByProvider(selectedWizardParams, provider, isTargetStepFromSameSourceType);
    const selectedVolume = isTargetStepFromSameSourceType ? selectedNfsVolTarget : selectedNfsVol;

    const selectedServer = isTargetStepFromSameSourceType ? selectedNFSTarget : selectedNFS;
    const aclSupportedWizardType = protocols === "nfs-nfs" && !isTargetStepFromSameSourceType && !isSecure;
    const wasAclSelectedOnSource = selectedNFS && isTargetStepFromSameSourceType ? getAclSelectedOnSource(selectedNFS) : false;
    const versionsList = isVolumeStep ? null : getNfsVersionList(provider, wasAclSelectedOnSource);
    const defaultNFSVersion = DEFAULT_NFS_VERSION[wasAclSelectedOnSource ? "NFS_ACL" : provider.toUpperCase()];

    const onChangeVersion = (version) => {
        if (!isAclSupportedNfsVersion(version.value)) {
            dispatch(change("nfsServer", "copyAcl", false));
        }
    };

    const volumes = getVolumesByProvider(relevantWe, provider,_anfVolumes, _cmVolumes, _fsxVolumes);
    const volumesOptions = {
        selectedVolumeId : selectedVolume,
        volumeSelectionElement: getVolumeSelectionComp(provider, volumes, selectedVolume, wasAclSelectedOnSource, () => {
            fetchData({...props, ...moreStepInfo, refreshVolumes: true})
        }),
        volumeSelectionReady: !!volumes
    };

    const serverType = isVolumeStep ? END_POINT_NAMES_MAP[provider.toUpperCase()] : provider.toUpperCase();
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["exportPathTarget", "rootExportTarget"] : ["exportPath", "rootExport"];
    const stepOptions = {
        storage: _servers[protocolAndProviderKey],
        server: selectedServer,
        serverSVG: `${endpointObj.provider}-default.svg`,
        volumesOptions,
        serverTitle: serverType,
        serverType: "NFS",
        selectType: "Server",
        serverPlaceholder: "For example: 217.70.21.18 or netapp.com",
        //CS-6249 UI: a typo (Ben's answer attached) - temporary fix
        title: `Select ${serverType === 'FSx for ONTAP' ? `an ${serverType}` : indefinite(serverType)} ${isSourceStep ? "Source" : "Target"}`,
        selectedVersion: (isTargetStepFromSameSourceType ? targetNfsVersion : nfsVersion) || defaultNFSVersion,
        versionsList,
        onChangeVersion,
        validate,
        SecondScreenUi,
        stateParamsToClear,
        getSelectedParams: (host, version) => {
            const hostParam = isTargetStepFromSameSourceType ? 'nfsServerTarget' : 'nfsServer';
            const versionParam = isTargetStepFromSameSourceType ? 'targetNfsVersion' : 'nfsVersion';
            if ((state[hostParam] && state[hostParam] !== host) || (state[versionParam] && state[versionParam] !== version)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam] : host,
                [versionParam] : version
            }
        },
        getCmVolumeParams : (volumeId, volumeRoot) => {
            const volParam = isTargetStepFromSameSourceType? 'nfsVolTarget' : 'nfsVol';
            const exportParam = isTargetStepFromSameSourceType ? 'rootExportTarget' : 'rootExport';
            const pathParam = isTargetStepFromSameSourceType ? 'exportPathTarget' : 'exportPath';

            if (volumeId && state[volParam] !== volumeId){
                clearSelectedParams([volParam, exportParam, pathParam])
            }
            const volParams = volumeId ? {
                [volParam] : volumeId,
                [exportParam] : volumeRoot
            } : {};
            return volParams;
        },
        getAccessPointParams: (values) => {
            const accessPointParam = isTargetStepFromSameSourceType? 'efsAccessPointTarget':'efsAccessPoint';
            return values.accessPoint? {
                [accessPointParam] : values.accessPoint
            }: {}
        },
        shouldGoToSecondScreen: (version) => provider === "efs" || provider === "cvo" || provider === "onprem" || provider === "fsx" || (aclSupportedWizardType && isAclSupportedNfsVersion(version))
    };

    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : isVolumeStep ? <VolumeStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/> : <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

const NFSServerStepForm = reduxForm({
    form: 'nfsServer',
    validate
})(NFSServerStep);

const mapStateToProps = (state, ownProps) => {
    const wizard = getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard;
    const moreStepInfo = buildMoreStepInfo({_wizard: wizard, name: ownProps.name}, stepId);

    return {
        ...ownProps,
        ...moreStepInfo,
        _servers: buildServersList(state),
        _wizard: wizard,
        _anfVolumes:  getPropFromState(state, CASH_KEYS._ANF_VOLUMES, {}),
        _cmVolumes: getPropFromState(state, "_cmVolumes", {}),
        _fsxVolumes: getPropFromState(state, CASH_KEYS._FSX_VOLUMES, {}),
        _cmWorkingEnvironments: getPropFromState(state, "_cmWorkingEnvironments", {}),
        _cmIntegration: state.global._cmIntegration,
        _accountInfo: state.global._accountInfo,
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getServersByType,
    getAnfVolumes,
    getCMVolumes,
    getFSXVolumes,
    getCMWorkingEnvironments,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
    clearFoldersCache
};

export default connect(mapStateToProps, mapDispatchToProps)(NFSServerStepForm);

const getAclSelectedOnSource = (selectedNFS) => {
    const credObj = getCredentialsByType('nfs', selectedNFS, false, ['serverName', 'version']);
    return credObj?.copyAcl || (credObj?.copyAclOptions && credObj?.copyAclOptions.value !== 'FILES');
};

