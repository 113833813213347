import React from 'react';
import {connect, useSelector} from "react-redux";
import {FM_AGENT_TYPE_AWS, FM_AGENT_TYPE_AZURE, FM_AGENT_TYPE_GCP, FM_AGENT_TYPE_ONPREM, SERVICE_NAME} from "../../../consts";
import {deleteDataBroker} from "../../../store/wizard/action_creators";
import ConfirmDialog from "../../dialog/ConfirmDialog";
import { deploySelector } from '../../../store/selectors/deploy.selector';
import _ from "lodash";

const DeleteDataBrokerDialog = ({data, deleteDataBroker, last}) => {

    const deployments = useSelector(deploySelector.deployments);

    const existingDeployment = _.find(deployments, {dataBrokerId: data.id});

    return (
        <ConfirmDialog
            title={`Remove Data Broker`}
            confirmText="Remove Data Broker"
            onConfirm={() => deleteDataBroker(data.id, existingDeployment)}
            extractError={(error) => error?.data?.message}
        >
            {!existingDeployment &&
            <p>This action only deletes the Data Broker from {SERVICE_NAME}&#39;s records. <br/>
                You need to delete
                {data.type === FM_AGENT_TYPE_AWS.name && <span> the CloudFormation Stack in AWS.</span>}
                {data.type === FM_AGENT_TYPE_AZURE.name && <span> the Virtual Machine in Azure.</span>}
                {data.type === FM_AGENT_TYPE_GCP.name && <span> the instance in GCP.</span>}
                {data.type === FM_AGENT_TYPE_ONPREM.name && <span> any additional cloud resources you created.</span>}
            </p>}

            {existingDeployment &&
            <p>This action deletes the Data Broker from {SERVICE_NAME}&#39;s records. <br/>
            {SERVICE_NAME} will also attempt to delete all the cloud resources that were created during the deployment.</p>}

            {last &&
            <p>This is the only Data Broker in the group. Deleting the Data Broker will delete the group, as well.</p>}

            <p>Are you sure that you want to remove the Data Broker {`'${data.name}'`}?</p>

        </ConfirmDialog>
    );
};


export default connect(null, {deleteDataBroker})(DeleteDataBrokerDialog);
