import React, { useMemo } from 'react'
import { ReactComponent as DataBrokerIcon } from '../../../assets/svgs/group-icon.svg'
import { ReactComponent as Initiator } from '../../../assets/svgs/initiator-label.svg'
import { ReactComponent as Listener } from '../../../assets/svgs/listener-label.svg'
import { ReactComponent as GroupSettings } from '../../../assets/svgs/group-settings-icon.svg'
import { ReactComponent as OrangeExclamationIcon } from '../../../assets/svgs/orange-exclamation-icon.svg'
import { useSelector } from 'react-redux';
import { compact, find, groupBy, map } from 'lodash'
import { convertSpeed } from "../../../utils/helpers";
import BrokerStatusDisplay from "../../newWizard/steps/BrokerStatusDisplay";
import { getFullDataBrokersList } from "../../../utils/dataBrokersUtils";
import ManagedDataBroker from "./ManagedDataBroker";
import {
    DATA_BROKER_ENCRYPTION_TYPES,
    DEPLOY_STATUS_ERROR,
    DEPLOY_STATUS_PROGRESS,
    DEPLOY_STATUS_SUCCESS
} from "../../../consts";
import { ButtonBase } from "@netapp/shared-components";
import { compareConfiguration, extractConfigurationFromGroupOrPlacement } from "../../../utils/data-utils";
import { deploySelector } from '../../../store/selectors/deploy.selector';

import './groupCard.scss'
import DoubleLineItem from "../../_common_/DoubleLineItem";
import { useNavigate } from 'react-router-dom';

const splitWaitingToTypes = (dataBrokers) => {
    if (!dataBrokers) return null;
    return groupBy(dataBrokers, ({ isDisabled, isWaiting, deployment }) => {
        const isDeploying = !isDisabled && isWaiting && deployment && (deployment.deployStatus === DEPLOY_STATUS_PROGRESS || deployment.deployStatus === DEPLOY_STATUS_SUCCESS);
        const isDeployError = !isDisabled && isWaiting && deployment && deployment.deployStatus === DEPLOY_STATUS_ERROR;
        let statusType;
        if (isDeployError) statusType = DEPLOY_STATUS_ERROR;
        else if (isDeploying) statusType = DEPLOY_STATUS_PROGRESS;
        else statusType = 'WAITING';
        return statusType;
    });
};

const getStatusComp = (dataBrokers, group, inWizard) => {
    const groupedByStatus = groupBy(dataBrokers, "status");
    const active = groupedByStatus["COMPLETE"]?.length ? groupedByStatus["COMPLETE"][0] : null;
    const inactive = groupedByStatus["FAILED"]?.length ? groupedByStatus["FAILED"][0] : null;
    const waitingTypes = splitWaitingToTypes(groupedByStatus['WAITING'] || groupedByStatus['undefined']); //waiting data broker doesn't return a status for now
    const deploying = waitingTypes && waitingTypes[DEPLOY_STATUS_PROGRESS]?.length ? waitingTypes[DEPLOY_STATUS_PROGRESS][0] : null;
    const error = waitingTypes && waitingTypes[DEPLOY_STATUS_ERROR]?.length ? waitingTypes[DEPLOY_STATUS_ERROR][0] : null;
    const waiting = waitingTypes && waitingTypes['WAITING']?.length ? waitingTypes['WAITING'][0] : null;



    return (<div className="data-brokers-status-line">
        {active && <BrokerStatusDisplay {...active} count={groupedByStatus["COMPLETE"].length} inWizard={inWizard}
            isDisabled={group.isDisabled} disabledErrorMessage={group.disabledErrorMessage || null} />}
        {inactive && <BrokerStatusDisplay {...inactive} count={groupedByStatus["FAILED"].length} inWizard={inWizard}
            isDisabled={group.isDisabled} disabledErrorMessage={group.disabledErrorMessage || null} />}
        {error && <BrokerStatusDisplay {...error} count={waitingTypes[DEPLOY_STATUS_ERROR].length} inWizard={inWizard}
            isDisabled={group.isDisabled} disabledErrorMessage={group.disabledErrorMessage || null} />}
        {deploying && <BrokerStatusDisplay {...deploying} count={waitingTypes[DEPLOY_STATUS_PROGRESS].length} inWizard={inWizard}
            isDisabled={group.isDisabled} disabledErrorMessage={group.disabledErrorMessage || null} />}
        {waiting && <BrokerStatusDisplay {...waiting} count={waitingTypes["WAITING"].length} inWizard={inWizard}
            isDisabled={group.isDisabled} disabledErrorMessage={group.disabledErrorMessage || null} />}
    </div>
    )
};


const atLeastOneDataBrokerNoMatch = (dataBrokers, group) => {
    const groupConfiguration = group?.configuration ? extractConfigurationFromGroupOrPlacement(group.configuration) : null;
    if (!groupConfiguration) return false;
    //return offline data brokers as if they are ok. cause we don't really know
    return find(dataBrokers, (dataBroker) => dataBroker.isReady ? !compareConfiguration(extractConfigurationFromGroupOrPlacement(dataBroker?.placement), groupConfiguration) : false);
}

const GroupHeader = ({ data, inWizard }) => {
    const { dataBrokers, encryptionType } = data;
    const showOrangeExclamation = atLeastOneDataBrokerNoMatch(dataBrokers, data);

    const deployments = useSelector(deploySelector.deployments);
    const navigate = useNavigate();

    const statusComp = useMemo(() => getStatusComp(getFullDataBrokersList(deployments, data.dataBrokers), data, inWizard), [data, deployments, inWizard]);
    const initiator = encryptionType === DATA_BROKER_ENCRYPTION_TYPES["Initiator"]
    const listener = encryptionType === DATA_BROKER_ENCRYPTION_TYPES["Listener"];

    return (<div className="group-card-collapsed">
        <div className='group-card-header'>
            <div className="group-icon"><DataBrokerIcon /></div>
            <div className="group-name-container" title={data.name}>
                <div className="group-name">{data.name}</div>
                {initiator && <Initiator />}
                {listener && <Listener />}
            </div>
            {!inWizard &&
                <ButtonBase type="button"
                    onClick={() => navigate({
                        pathname: `/group-settings/${data.id}/${data.name}`,
                        search: window.location.search
                    })}>
                    <GroupSettings className="group-settings-icon" />{showOrangeExclamation ?
                        <OrangeExclamationIcon className="orange-exclamation-icon" /> : ""}
                </ButtonBase>}
        </div>
        <div className="group-card-info">
            <DoubleLineItem title="Data Brokers" value={data.dataBrokers.length} />
            <DoubleLineItem title="Transfer Rate" value={convertSpeed(compact(map(data.dataBrokers, 'transferRate')))} />
            <DoubleLineItem title="Relationships" value={data.relationships?.length || 0} />
            <DoubleLineItem title="Data Brokers Status" valueComp={statusComp} />
        </div>
    </div>)
};

const GroupCard = {
    className: () => {
        return "group-card"
    },
    renderContent: ({ data, additionalData }) => {
        const expand = data.id === additionalData?.extendCard;
        const inWizard = additionalData?.inWizard;
        return (
            <div>
                <GroupHeader data={data} inWizard={inWizard} />
                <div className="flex flex-dir-column managed-data-brokers-container">
                    {data.dataBrokers.map((dataBroker) =>
                        <ManagedDataBroker key={dataBroker.id} dataBroker={dataBroker} last={data.dataBrokers.length === 1} expand={expand} inWizard={inWizard} />
                    )}
                </div>
            </div>
        )
    },
    renderCollapsed: (data, additionalData) => {
        const inWizard = additionalData?.inWizard;
        return (
            <GroupHeader data={data} inWizard={inWizard} />
        )
    },
    getInitiallyExpanded: (data, additionalData) => {
        return (data.id === additionalData?.extendCard)
    },
    isSelectionDisabled: (group) => {
        return group.isDisabled;
    }
};

export default GroupCard;
