import React, {useState, useMemo} from 'react';
import {ReactComponent as ArrowRight} from "../../assets/svgs/arrow-right-pointer.svg";
import classNames from 'classnames';
import {getPropFromState} from '../../utils/mapStateToPropsUtils'
import {connect, useDispatch} from 'react-redux';
import _ from "lodash";
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';
import NewWizardHeaderStep from './NewWizardHeaderStep';
import {wizardStepExited} from '../../store/wizard/action_creators'
import { clearNotifications } from '../../store/global/action_creators'

import "./newWizardHeader.scss"

const RENDERED_STEPS_NUMBER = 4;

const NewWizardHeader = (props) => {
    const {_wizard, goToStep} = props;
    return (<div className={classNames("wizard-header-new", {hidden: _wizard && _wizard.hideHeader})}>
            {_wizard?.currentStep && <WizardSteps {...props} goBackTo={goToStep}/>}
        </div>)
};

const WizardSteps = (props) => {
    const {_wizard: {stepsOrder, stepInfoById, currentStep}, goBackTo} = props;
    const currentIndex = stepsOrder.indexOf(currentStep);
    const {segments, renderedSegmentIndex, handleArrowClick} = useStepsSegment(stepsOrder, RENDERED_STEPS_NUMBER, currentIndex);
    const dispatch = useDispatch();

    const showLeftArrow = renderedSegmentIndex > 0;
    const showRightArrow = renderedSegmentIndex < segments.length - 1;

    const goBackToStep = (stepIndex) => {
        goBackTo(stepIndex + 1);

        clearNotifications()(dispatch);
    }
    return (
        <div className="steps-with-arrows">

            {showLeftArrow && <ArrowButton onClick={(e) => handleArrowClick(e, "down")} isLeft={true}/>}

            <div className="steps">
            {segments[renderedSegmentIndex].map((stepId, indexInSegment) => {
                const stepIndex = stepsOrder.indexOf(stepId);
                const goBack = stepIndex < currentIndex ? () => goBackToStep(stepIndex) : undefined;
                const isFirst = stepIndex === 0;
                const isLast = stepIndex === (stepsOrder.length - 1);
                return (<NewWizardHeaderStep
                    key={stepId}
                    goBack={goBack}
                    current={stepIndex === currentIndex}
                    future={currentIndex < stepIndex}
                    stepName={stepInfoById[stepId].name}
                    stepNumber={stepIndex + 1}
                    fadedRight={indexInSegment === (RENDERED_STEPS_NUMBER - 1) && !isLast}
                    fadedLeft={indexInSegment === 0 && !isFirst}
                />)
            })}
            </div>

            {showRightArrow && <ArrowButton onClick={(e) => handleArrowClick(e, "up")} isLeft={false}/>}

        </div>
    )
};

const mapStateToProps = state => ({
    _wizard: getPropFromState(state, "_wizard", null),
    _routing: getPropFromState(state, "_routing", {}),
    _ui: {...state.syncReducer._ui, ...state.global._ui}
});

export default connect(mapStateToProps, {wizardStepExited})(NewWizardHeader);

const useStepsSegment = (allSteps, renderedStepsCount, currentIndex) => {
    //The steps array is divided into segments.
    //Each segment has also 2 steps from previous segment, and 2 from the next (in order to show the faded steps at the beginning/end of a segment).
    //The last segment can't be of partial length.

    //Example for a segments array:
    // [ ["0", "1", "2", "3", "4"] , ["3", "4", "5", "6", "7"], ["5", "6", "7", "8", "9"]]


    //renderedSegment is the segment that is currently shown
    //activeSegment is the segment with the current active step

    const segments = useMemo(()=>{
        if (allSteps.length === renderedStepsCount) return [allSteps];

        let index = 0;
        const chunks = [];
        while (index < allSteps.length - 2 ) {
            chunks.push(_.slice(allSteps, index, index + RENDERED_STEPS_NUMBER));
            index += RENDERED_STEPS_NUMBER - 2;
        }

        //last segment should always have RENDERED_STEPS_NUMBER steps
        const chunksWithoutLast = _.dropRight(chunks, 1);
        const lastChunk = _.takeRight(allSteps, renderedStepsCount);
        return [...chunksWithoutLast, lastChunk]
    }, [allSteps, renderedStepsCount]);

    const activeSegmentIndex = useMemo(()=> {
        //Edge cases: first and last
        if (currentIndex === 0) return 0;
        if (currentIndex === allSteps.length - 1) return segments.length - 1;

        //this is the formula for getting the active segment.
        //I have a truly marvelous proof of this, which this margin is too narrow to contain.
        return Math.floor((currentIndex - 1) / (renderedStepsCount - 2));

    }, [currentIndex, renderedStepsCount, allSteps, segments]);

    const [renderedSegmentIndex, setRenderedSegmentIndex] = useState(activeSegmentIndex);

    useDeepCompareEffect(() => {
        if (activeSegmentIndex !== renderedSegmentIndex) {
            setRenderedSegmentIndex(activeSegmentIndex);
        }
    }, [activeSegmentIndex]);

    const handleArrowClick = (e, direction) => {
        const newSegmentIndex = direction === "up" ?  renderedSegmentIndex + 1 : renderedSegmentIndex - 1;
        setRenderedSegmentIndex(newSegmentIndex);
        e.stopPropagation();
    };

    return {segments, renderedSegmentIndex, handleArrowClick}
};

const ArrowButton = ({onClick, isLeft}) => (<button
        type="button"
        onClick={onClick}
        className={`arrow ${isLeft ? "left" : ""}`}>
    <ArrowRight/>
</button>);
