import React from "react";
import {createSplitPath, createStringFromEndPointObject} from "./../../utils/helpers"
import {
    getClassBySyncStatus,
    getFixedProtocol,
    getSVGByProtocol,
    getSVGBySyncStatus,
    noConnectionToAllBrokers
} from "../../utils/dashboard-utils";
import {
    CREDENTIALS_BY_PROTOCOL,
    RESPONSE_STATUS_ABORTED,
    RESPONSE_STATUS_FAILED,
    RESPONSE_STATUS_RUNNING,
    STATUS_CONTINUOUSLY_SYNCING,
} from "../../consts";
import {ButtonBase} from '@netapp/shared-components';
import EditCredentialsDialog from "./EditCredentialsDialog";
import {ReactComponent as EditCredentialsIcon} from "../../assets/svgs/pencil-icon.svg"
import {noop} from 'lodash'
import RelationshipName from "./relationshipCard/RelationshipName";

const EndPointInfo = ({svg, from, path, showEditCredentials, isRunning, protocol ,setDialog, relationshipId}) => {
    const splitPath = createSplitPath(path);

    return (
        <div className="endpoint-cell">
            <div className="endpoint-info">
                {svg}
                <b>{splitPath[0]}</b>
                <div className="endpoint-text">{splitPath.slice(1).join('')}</div>
            </div>
            {showEditCredentials && <div title={isRunning ? "Credentials can't be updated while sync in progress" : ""}
                                         className={`edit-credentials ${isRunning ? 'disabled' : ''}`} onClick={(el) =>
            {
                isRunning ? noop() : setDialog(
                <EditCredentialsDialog
                    relationshipId={relationshipId}
                    from={from} protocol={protocol}
                    credentialsToUpdate={CREDENTIALS_BY_PROTOCOL[protocol]}
                    path={path}
                />,
                el.target
            )}
            }
            >
                <EditCredentialsIcon/>
                <div>Update Credentials</div>
            </div>}

        </div>
    )


}

const RelationshipTable = {
    renderRow: (row, setDialog, moreActions) => {
        const isRunning = [RESPONSE_STATUS_RUNNING, STATUS_CONTINUOUSLY_SYNCING].includes(row.activity.status);
        const {dataBrokers} = row.groupInfo || {};
        const {ui: {source, target}, relationshipId, name} = row;
        const showUnknown = isRunning && noConnectionToAllBrokers(dataBrokers);
        const syncStatusClass = getClassBySyncStatus(row.ui.status, showUnknown);
        const isSecureRelationship = !!row.encryption;
        const sourceSVG = getSVGByProtocol(getFixedProtocol(source), isSecureRelationship);
        const targetSVG = getSVGByProtocol(getFixedProtocol(target), isSecureRelationship);
        const syncStatusSVG = getSVGBySyncStatus(row.ui.status);

        const statusText = showUnknown ? "Unknown" : row.ui.statusText;

        const sourceTitle = createStringFromEndPointObject(source);
        const targetTitle = createStringFromEndPointObject(target);
        const statusClass = (row.ui.status === RESPONSE_STATUS_FAILED || row.ui.status === RESPONSE_STATUS_ABORTED) ? "fail" : (showUnknown ? "unknown" : "");

        return (
            <div>
                {/*blank*/}
                <td className="blank"/>
                {/*name*/}
                <td><RelationshipName name={name} relationshipId={relationshipId} inTable={true} isRunning={isRunning}/></td>
                {/*source*/}
                <td className="endpoint-container" title={sourceTitle}>
                    <EndPointInfo svg={sourceSVG} path={sourceTitle}
                                  setDialog={setDialog} from={"Source"} isRunning={isRunning} protocol={source.protocol}
                                  showEditCredentials={false} relationshipId={relationshipId}/>
                </td>
                {/*target*/}
                <td className="endpoint-container" title={targetTitle}>
                    <div className="target-container">
                        <EndPointInfo svg={targetSVG} path={targetTitle}
                                      from="Target" setDialog={setDialog} isRunning={isRunning} protocol={target.protocol}
                                      showEditCredentials={false} relationshipId={relationshipId}/>
                    </div>
                </td>
                <td className={syncStatusClass}>
                    <div className="status-details">
                        <div className={`status-txt ${statusClass}`}>
                            {!showUnknown && <div> {syncStatusSVG}</div>}
                            <span className="cycel-icon">{statusText}</span>
                        </div>
                    </div>
                </td>
                <td className="more-action">
                    <ButtonBase className="more-info-trigger" onClick={(el) => {
                        return moreActions?.updateRelationshipId(relationshipId)
                    }}>
                        More info
                    </ButtonBase>
                </td>
            </div>
        )
    },
    getTableHeader: (applySort) => {
        return [
            {className: 'blank', width: "3%"},
            {name: "name", text: "Name", sort: applySort, width: "22%"},
            {text: "Source", width: "29%"},
            {text: "Target", width: "29%"},
            {text: "Sync Status", width: "18%"},
            {text: "More", width: "10%"},
            {className: 'table-menu', text: "", width: "3%"}
        ]

    }
};

export default RelationshipTable;
