import React from 'react';
import ConfirmDialog from '../dialog/ConfirmDialog';

const SubscriptionRequiredDialog = ({errorMessage, navigate}) => {

    const goToLicenseSettings = () => {
        navigate({
            pathname: `/license`,
            search: window.location.search
        });
    };

    return (
        <ConfirmDialog
            title="Subscription Required"
            onConfirm={goToLicenseSettings}
            confirmText="Go to License Settings"
        >
            <p className="wordwrap">{errorMessage}</p>
        </ConfirmDialog>
    );
};

export default SubscriptionRequiredDialog;

