import React from "react";
import OnPremInstallationInstructions from "./OnPremInstallationInstructions";
import ButtonsPanel from '../widgets/ButtonsPanel';

const InstanceDetailsOnprem = props => {
    const {handleContinue} = props;

    return <div className="data-broker-wizard-step instance-details-onprem">
        <div className="title">Installation</div>
        <div className="content-area">
           <OnPremInstallationInstructions {...props}/>
        </div>
        <ButtonsPanel
            variant="transparent"
            type="button"
            text="Continue"
            onClick={handleContinue}/>
    </div>;
};

export default InstanceDetailsOnprem;
