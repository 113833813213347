import {useEffect} from 'react';

const useEvent = (event, handler, passive=false) => {
    useEffect(() => {
        // initiate the event handler
        window.addEventListener(event, handler, passive);

        // this will clean up the event when the component is unmounted
        return function cleanup() {
            window.removeEventListener(event, handler);
        };
    }, [handler, event, passive]);
};

export default useEvent;