import React, {useState, useMemo} from "react";
import classNames from "classnames";
import SearchWidget from "./../widgets/SearchWidget"
import ViewToggleWidget from "./../widgets/ViewToggleWidget"
import ItemCountWidget from "./../widgets/ItemCountWidget"
import {defaultFilterWithKeys} from "./../../utils/helpers"
import CardItem from "./CardItem";
import {ReactComponent as TableIcon} from './../../assets/svgs/table-view-icon.svg'
import SortWidget from "../widgets/SortWidget";
import {sortBy, reverse, isNull} from "lodash";
import FilterWidget from "../widgets/filter/FilterWidget";


require("./cardsContainer.scss");

const sortCards = (desc, sortKey, updatedData) => {
    const sortedCards = sortBy(updatedData, [sortKey]);
    if (desc) {
        return reverse(sortedCards);
    }
    else {
        return sortedCards;
    }
}

const CardsContainer = (props) => {
    const {className, data, Card, wizardType, containerOptions, selectionOptions = {}, menuItems, isMenuDisabled, additionalData, customClass, outsideFilter} = props;
    const {singularTitle, pluralTitle, viewToggle, noWidgets = false, keysToSearch, sort: allowSort, filter: allowFilterBy} = containerOptions;
    const {filterOptions: {filters, filterName, applyFilter, title}} = allowFilterBy || {filterOptions: {}};

    const [filter, setFilter] = useState(null);
    const [sortDesc, setSorted] = useState(null);

    const filteredAndSorted = useMemo(() => {
        let updatedData = data;
        if (filter) {
            updatedData = data.filter(item => defaultFilterWithKeys(item, filter, keysToSearch))

        } if (!isNull(sortDesc) && allowSort?.key) {
            updatedData = sortCards(sortDesc, allowSort.key, updatedData)
        }
        return updatedData;
    }, [filter, data, keysToSearch, allowSort?.key, sortDesc]);


    //in case there is an outside filter the data length is the already filtered items
    const filteredItemCount = filter || sortDesc ? filteredAndSorted.length  : data.length;

    return (
        <div className={classNames(className, "widgets-cards-container")}>
            {!noWidgets &&
            <div className="widgets-container">
                <div className="widgets left">
                    <ItemCountWidget singularTitle={singularTitle} pluralTitle={pluralTitle}
                                     itemCount={outsideFilter?.fullLength || data.length}
                                     filteredItemCount={filteredItemCount}
                                     clearOutsideFilter={outsideFilter?.clear}

                    />
                    {allowFilterBy && <FilterWidget title={title} applyFilter={applyFilter} filterName={filterName} filters={filters} className="cards"/>}
                    {allowSort && filteredItemCount > 1 && <SortWidget sortFunc={setSorted} sortInfo={allowSort}/>}
                </div>
                <div className="widgets right">
                    <SearchWidget setFilter={setFilter}/>
                    {viewToggle && <ViewToggleWidget toggle={viewToggle}
                                                     icon={<TableIcon/>}/>}
                </div>
            </div>
            }
            <div className="card-container">
                {filteredAndSorted.map(entry => (
                    <CardItem Card={Card}
                              customClass={customClass}
                              data={entry}
                              additionalData={additionalData}
                              wizardType={wizardType}
                              key={entry.id} selector={selectionOptions.selector}
                              selected={selectionOptions.selectedId === entry.id}
                              menuItems={menuItems}
                              isMenuDisabled={isMenuDisabled}
                    />
                ))}
            </div>
        </div>
    );
};

export default CardsContainer;

