import {FM_AGENT_TYPE_AWS, FM_AGENT_TYPE_AZURE, FM_AGENT_TYPE_GCP} from '../consts';
import * as awsService from "../services/aws.api.service";
import * as azureService from "../services/azure.deploy.service";
import * as gcpService from "../services/gcp.deploy.service";

export const rollbackDeployment = (deployment) => {
    if (!deployment) return;

    const {deployDetails, providerType} = deployment;
    if (providerType === FM_AGENT_TYPE_AWS.name) {
        const {credentials, region, stackId} = deployDetails;
        awsService.deleteStack(credentials, region, stackId);
    }
    if (providerType === FM_AGENT_TYPE_AZURE.name) {
        azureService.rollback(deployDetails);
    }
    if (providerType === FM_AGENT_TYPE_GCP.name) {
        const {projectId, deploymentName} = deployDetails;
        gcpService.rollback(projectId, deploymentName);
    }
};