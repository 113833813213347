import React from 'react';
import {SelectField, InputField} from '../../../_common_/forms/forms';
import {Field} from 'react-final-form';
import {
    ENCRYPTION_OPTIONS,
    ENCRYPTION_TYPES,
    ENCRYPTION_TYPES_NAMES,
    STORAGE_CLASS_OPTIONS,
    DEFAULT_STORAGE_CLASS,
    DEFAULT_ENCRYPTION_TYPE
} from '../../../../consts'
import _ from 'lodash';
import {getCredentialsByType} from '../../../../utils/sync-utils';
import useRunOnceWhenTruthy from '../../../../hooks/useRunOnceWhenTruthy';
import useRunOnce from '../../../../hooks/useRunOnce';
import {ReactComponent as KeyIcon} from "../../../../assets/svgs/key-icon.svg";
import {updateFormValues} from "../../../../utils/formUtils";


const useInitializeEncryption = (selectedEncryptionType, bucketKmsKeys, bucketPath, bucketFullPath, form, kmsKeysOptions,
                                 selectedStorageClass, isTargetStepFromSameSourceType) => {

    useRunOnce(() => {
        const encryptionTypeOption = ENCRYPTION_OPTIONS.find(option => option.value === (selectedEncryptionType || DEFAULT_ENCRYPTION_TYPE))
        const storageClass = STORAGE_CLASS_OPTIONS.find(option => option.value === (selectedStorageClass || DEFAULT_STORAGE_CLASS));
        updateFormValues({storageClass, encryptionTypeOption}, form)
    });

    useRunOnceWhenTruthy(() => {
        if (selectedEncryptionType === ENCRYPTION_TYPES_NAMES.AES) {
            updateFormValues({encryptionTypeOption: _.find(ENCRYPTION_OPTIONS, {value: ENCRYPTION_TYPES.AES})}, form)
        }
        if (selectedEncryptionType === ENCRYPTION_TYPES_NAMES.KMS) {
            const credentials = getCredentialsByType('s3', bucketFullPath, isTargetStepFromSameSourceType);

            const kmsKey = credentials?.encryptionTypeOption?.value === ENCRYPTION_TYPES.KMS_EXISTING ? credentials?.encryptionKeyOption?.value : credentials?.encryptionKeyManual
            const encryptionKmsType = credentials?.encryptionTypeOption?.value;
            updateFormValues({encryptionTypeOption: _.find(ENCRYPTION_OPTIONS, {value: encryptionKmsType})}, form)

            //update key in form
            if (encryptionKmsType === ENCRYPTION_TYPES.KMS_EXISTING) {
                updateFormValues({encryptionKeyOption: _.find(kmsKeysOptions, {value: kmsKey})}, form)
            }
            if (encryptionKmsType === ENCRYPTION_TYPES.KMS_MANUAL) {
                updateFormValues({encryptionKeyManual: kmsKey}, form)
            }
        }
    }, selectedEncryptionType);
};


const EncryptionSettingsUI = (props) => {
    const {formValues, form, bucketPath, bucketFullPath, bucketInfo, storageClassOptions, kmsKeysOptions, selectedEncryptionType,
        selectedStorageClass, isTargetStepFromSameSourceType} = props;
    const encryptionTypeOption = formValues?.encryptionTypeOption?.value;

    useInitializeEncryption(selectedEncryptionType, bucketInfo.kmsKeys, bucketPath, bucketFullPath, form, kmsKeysOptions,
        selectedStorageClass, isTargetStepFromSameSourceType);

    return (
        <>
            <div className="encryption-container">
                <div className="title">
                    <div className="key-and-text">
                        <KeyIcon/>
                        <div>S3 Bucket Encryption</div>
                    </div>
                </div>
                    <div>
                    <Field component={SelectField}
                           isLoading={!bucketInfo.kmsKeys}
                           name="encryptionTypeOption"
                           options={bucketInfo.kmsKeys
                               ? kmsKeysOptions.length === 0
                                   ? _.reject(ENCRYPTION_OPTIONS, option => option.value === ENCRYPTION_TYPES.KMS_EXISTING)
                                   : ENCRYPTION_OPTIONS
                               : []}
                           label="Encryption type:"/>
                    {encryptionTypeOption === ENCRYPTION_TYPES.KMS_EXISTING && <div>
                        <Field component={SelectField}
                               label="Encryption Key name"
                               name="encryptionKeyOption"
                               options={kmsKeysOptions}/>
                    </div>}
                    {encryptionTypeOption === ENCRYPTION_TYPES.KMS_MANUAL && <div>
                        <Field component={InputField}
                               label="Encryption Key ARN"
                               name="encryptionKeyManual"
                               placeholder="Enter an encryption key ARN..."/>
                    </div>}
                </div>
            </div>
            <div className="storage-class-container">
                <div className="title">S3 Storage Class</div>
                <div>
                    <Field component={SelectField} valueField="value" name="storageClass"
                           options={storageClassOptions}/>
                </div>
            </div>
        </>);
};

export default EncryptionSettingsUI;
