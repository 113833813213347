import React from 'react';
import classNames from "classnames";
import {
    FM_AGENT, END_POINT_NAMES_MAP
} from '../../consts';
import {sourceAndTargetDisplayNameForSystemFlow} from "./../../utils/sync-utils";
import {ReactComponent as NfsIcon} from "../../assets/svgs/nfs-default.svg";
import {ReactComponent as CifsIcon} from "./../../assets/svgs/cifs-default.svg";
import {ReactComponent as EfsIcon} from "./../../assets/svgs/efs-default.svg";
import {ReactComponent as CvsIcon} from "./../../assets/svgs/cvs-default.svg";
import {ReactComponent as AnfIcon} from "./../../assets/svgs/anf-default.svg";
import {ReactComponent as S3Icon} from "./../../assets/svgs/s3-default.svg";
import {ReactComponent as SgwsIcon} from "./../../assets/svgs/sgws-default.svg";
import {ReactComponent as GcpIcon} from "./../../assets/svgs/gcp-default.svg";
import {ReactComponent as IbmIcon} from "./../../assets/svgs/ibm-default.svg";
import {ReactComponent as AzureIcon} from "./../../assets/svgs/azure-default.svg";
import {ReactComponent as AzureDataLakeIcon} from "./../../assets/svgs/azure_data_lake-default.svg";
import {ReactComponent as OntapIcon} from "./../../assets/svgs/ontap-default.svg";
import {ReactComponent as CvoIcon} from "./../../assets/svgs/cvo-default.svg";
import {ReactComponent as OnpremIcon} from "./../../assets/svgs/onprem-default.svg";
import {ReactComponent as FsxIcon} from "./../../assets/svgs/fsx-default.svg";
import {ReactComponent as BoxIcon} from "./../../assets/svgs/box-default.svg";
import {ReactComponent as GoogleDriveIcon} from "./../../assets/svgs/gdrive-default.svg";
import {ReactComponent as SftpIcon} from "./../../assets/svgs/sftp-default.svg";
import {ReactComponent as BiDirectionArrows} from "./../../assets/svgs/system-flow-bi-direction-arrows.svg";
import {ReactComponent as DataBrokerWithDottedArrows} from "./../../assets/svgs/data-broker-with-dotted-arrows.svg";
import {ReactComponent as DataBrokerWithArrows} from "./../../assets/svgs/data-broker-with-arrows-white.svg";
import {ReactComponent as EncryptedDiagram} from "./../../assets/svgs/how-it-work-encrypted.svg";
const indefinite = require('indefinite');
require("./systemFlow.scss");

const protocolInfo = {
    "nfs": {
        svg: <NfsIcon/>,
        title: END_POINT_NAMES_MAP.NFS
    },
    "cifs": {
        svg: <CifsIcon/>,
        title: END_POINT_NAMES_MAP.CIFS
    },
    "efs": {
        svg: <EfsIcon/>,
        title: END_POINT_NAMES_MAP.EFS
    },
    "cvs": {
        svg: <CvsIcon/>,
        title: END_POINT_NAMES_MAP.CVS
    },
    "anf": {
        svg: <AnfIcon/>,
        title: END_POINT_NAMES_MAP.ANF
    },
    "s3": {
        svg: <S3Icon/>,
        title: END_POINT_NAMES_MAP.S3
    },
    "sgws": {
        svg: <SgwsIcon/>,
        title: END_POINT_NAMES_MAP.SGWS
    },
    "gcp": {
        svg: <GcpIcon/>,
        title: `${END_POINT_NAMES_MAP.GCP}`
    },
    "ibm": {
        svg: <IbmIcon/>,
        title: END_POINT_NAMES_MAP.IBM
    },
    "azure": {
        svg: <AzureIcon/>,
        title: END_POINT_NAMES_MAP.AZURE
    },
    "ontap": {
        svg: <OntapIcon/>,
        title: END_POINT_NAMES_MAP.ONTAP
    },
    "cvo": {
        svg: <CvoIcon/>,
        title: END_POINT_NAMES_MAP.CVO
    },
    "onprem": {
        svg: <OnpremIcon/>,
        title: END_POINT_NAMES_MAP.ONPREM
    },
    "fsx": {
        svg: <FsxIcon/>,
        title: END_POINT_NAMES_MAP.FSX
    },
    "box": {
        svg: <BoxIcon/>,
        title: END_POINT_NAMES_MAP.BOX
    },
    "gdrive": {
        svg: <GoogleDriveIcon/>,
        title: END_POINT_NAMES_MAP.GDRIVE
    },
    "sftp": {
        svg: <SftpIcon/>,
        title: END_POINT_NAMES_MAP.SFTP
    },
    "azure_data_lake":{
        svg: <AzureDataLakeIcon/>,
        title:END_POINT_NAMES_MAP.AZURE_DATA_LAKE
    }
};

export const SystemFlow = ({children, wizardType, isSecure}) => {
    return ( <div>
            {!isSecure && <HowDoesItWork wizardType={wizardType} >{children}</HowDoesItWork>}
            {isSecure && <HowDoesItWorkSecure wizardType={wizardType}>{children}</HowDoesItWorkSecure>}
        </div>
    )
};

const HowDoesItWork = ({children, wizardType}) => {
    const splitted = wizardType.split('-');
    const source = splitted[0];
    const target = splitted[1];

    const dottedArrows = wizardType === "azure-azure" || wizardType === "s3-s3" || wizardType === "gcp-gcp";

    const sourceDisplayName = sourceAndTargetDisplayNameForSystemFlow[source];
    const targetDisplayName = sourceAndTargetDisplayNameForSystemFlow[target];
    return (

        <div className="system-flow">
            <div className="divTable">
                <div className="divTableBody">
                    <div className={classNames("divTableRow", {"bi-directional": dottedArrows})}>
                        <div className="table-cell-container">
                            {protocolInfo[source].svg}
                            <div className="title">{protocolInfo[source].title}</div>
                        </div>
                        {dottedArrows && <div className="table-cell-container arrows">
                            <div className="image">
                                <BiDirectionArrows/>
                            </div>
                        </div>}
                        <div className="table-cell-container">
                            {protocolInfo[target].svg}
                            <div className="title">{protocolInfo[target].title}</div>
                        </div>
                    </div>
                    <div className="divTableRow bottom">
                        <div className={`divTableCellBig ${dottedArrows ? "dotted" : ""}`}>
                            <span>{dottedArrows ? <DataBrokerWithDottedArrows/> : <DataBrokerWithArrows/>}</span>
                        </div>
                    </div>
                </div>
            </div>

            <p>Create a {FM_AGENT} that has a connection to the source and target.</p>
            {!dottedArrows && <p>The {FM_AGENT} will be used as a mediator between {sourceDisplayName} and {targetDisplayName}.</p>}
            {dottedArrows && <p>In {indefinite(sourceDisplayName)} to {targetDisplayName} sync relationship, the {FM_AGENT} initiates and monitors the relationship,
                but the data copy itself is done directly by {END_POINT_NAMES_MAP[target.toUpperCase()]}.</p>}
            {children}
        </div>
    )
};

const HowDoesItWorkSecure = ({wizardType}) => {
    const split = wizardType.split('-');
    const target = split[1];
    return (
        <div className="system-flow encrypted">
            <div>
                <FlowEncrypted wizardType={wizardType}/>
            </div>

            <div className="encryption-text">
                When you enable data-in-flight encryption, a data broker encrypts your data before sending it over the<br/>
                network to a second data broker.
                The second data broker decrypts the data before sending it to the<br/> target {protocolInfo[target].title}.
                The data brokers communicate over the network using an encrypted connection.
            </div>
        </div>
    )
};

export const FlowEncrypted = ({wizardType}) => {
    const split = wizardType.split('-');
    const source = split[0];
    const target = split[1];
    return (
        <div className="flow-encrypted">
            <div className="end-point source">
                {protocolInfo[source].svg}
                <div className="end-point-title">Source {protocolInfo[source].title}</div>
            </div>
            <div className="end-point target">
                {protocolInfo[target].svg}
                <div className="end-point-title">Target {protocolInfo[target].title}</div>
            </div>
            <EncryptedDiagram className="diagram"/>
        </div>
    )
};
