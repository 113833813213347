import {ReactComponent as NameIcon} from "../../../assets/svgs/link-icon.svg";
import EditRelationshipNameDialog from "../EditRelationshipNameDialog";
import {ReactComponent as EditCredentialsIcon} from "../../../assets/svgs/pencil-icon.svg";
import {useDialog} from '@netapp/shared-components';
import {isEmpty, noop} from 'lodash'
import './relationshipName.scss'

const RelationshipName = ({name, relationshipId, inTable, isRunning}) => {
    const {setDialog} = useDialog();
    const noName = isEmpty(name);
    return <div className={`relationship-name ${isRunning? 'disabled' : ''}`} title={isRunning ? 'Name can\'t be updated while sync in progress' : ''}>
        {!inTable && <span className="icon"><NameIcon/></span>}
        <div className="text">
            {!inTable && <div className="title">Name</div>}
            <div title={name || ""} className={`data ${inTable && noName ? 'blue' : ''} ${inTable ? 'in-table' : ''}`} onClick={(el) => {
                return isRunning ? noop : setDialog(
                    <EditRelationshipNameDialog
                        relationshipId={relationshipId}
                        name={name}
                    />,
                    el.target
                )
            }}>
                <div className="name" title={isRunning ? 'Name can\'t be updated while sync in progress' : ''}>{name || "Name your relationship"}</div>
                <EditCredentialsIcon className={`pencil ${inTable? 'inverted' : ''}`}/>
            </div>

        </div>
    </div>;
}

export default RelationshipName;
