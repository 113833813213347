import _ from 'lodash';
import { store } from '../store/store';
import {
    ACL_DISABLED_MESSAGE,
    DATA_BROKER_FUNCTION,
    DEFAULT_CIFS_VERSION,
    DEFAULT_NFS_VERSION,
    ENCRYPTION_OPTIONS,
    ENCRYPTION_TYPES,
    ENCRYPTION_TYPES_NAMES,
    END_POINT_NAMES_MAP,
    FM_AGENT,
    NFS_ANF_VERSIONS,
    NFS_CVS_VERSIONS,
    NFS_VERSIONS,
    OBJECT_STORAGE_NAMES_MAP
} from '../consts';
import {stringToTags} from './helpers';
import {getSettingsFromSessionStorage} from './settings-utils';
import {getBucketRegion, getCmIntegrationRightPanelDetails} from './data-utils';
import {
    getDirectoryObjectFromCache,
    getDirectoryObjectFromCacheGoogle,
    removeStartingAndEndingSlash
} from './encoders';
import {isIntegratedCmProvider} from "./cm-integration-utils";
import {isAclSupportedNfsVersion} from "./relationshipUtils";
import { updateStepCredentials } from '../store/wizard/action_creators';

export const END_POINT_FILE_SYSTEM = 'fileSystem';
export const END_POINT_STORAGE = 'storage';

export const fixGooglePath = (actualPath, actualRoot = "") => {
    //remove all the ids from the path, the ids are kept inside ()
    let finalPath = _.replace(actualPath, actualRoot, '');
    const regexp = /\((.*?)\)/g;
    const result = finalPath.match(regexp);
    _.forEach(result, value => {
        finalPath = _.replace(finalPath, value, "");
    })
    return finalPath;
}



export const getWizardInfo = (protocols, providers) => {
    const protocolsObj = splitCombinationToSourceTarget(protocols, '-');
    const providersObj = splitCombinationToSourceTarget(providers, ':');

    return {
        source: getEndpointObject('source', protocolsObj, providersObj),
        target: getEndpointObject('target', protocolsObj, providersObj)
    }
};

const getEndpointObject = (sourceOrTarget, protocolsObj, providersObj) => {
    return {
        protocol: protocolsObj[sourceOrTarget],
        provider: providersObj[sourceOrTarget]
    }
};

export const getStepsOrder = (wizardInfo, isSecure, weSelectionNeeded, dataSense) => {
    const isTargetOfSameSource = wizardInfo.source.protocol === wizardInfo.target.protocol;

    if (dataSense) {
        if (isSecure) {
            return _.concat(['dataSense', 'dataBrokerFunction','dataBroker'], getStepIdsByProtocol({
                protocol: wizardInfo.target.protocol,
                provider: wizardInfo.target.provider,
                isTargetOfSameSource,
                isSecure,
                weSelectionNeeded: weSelectionNeeded?.target
            }))
        }
        return _.concat(['dataSense','dataBroker'], getStepIdsByProtocol({
            protocol: wizardInfo.target.protocol,
            provider: wizardInfo.target.provider,
            isTargetOfSameSource,
            isSecure,
            weSelectionNeeded: weSelectionNeeded?.target
        }))
    }

    return _.concat(
        //source steps:
        getStepIdsByProtocol({
            protocol: wizardInfo.source.protocol,
            provider: wizardInfo.source.provider,
            isSource: true,
            isSecure,
            isTargetOfSameSource: false,
            weSelectionNeeded: weSelectionNeeded?.source
        }),
        //target steps:
        getStepIdsByProtocol({
            protocol: wizardInfo.target.protocol,
            provider: wizardInfo.target.provider,
            isTargetOfSameSource,
            isSecure,
            weSelectionNeeded: weSelectionNeeded?.target
        }));
};

const getStepIdsByProtocol = ({protocol, provider, isSource, isSecure, isTargetOfSameSource, weSelectionNeeded}) => {

    let result = [];

    if (isSecure) {
        result = isSource
            ? [createStepId(protocol, provider, ), 'dataBrokerFunction', 'dataBroker', 'directories']
            : [createStepId(protocol, provider, true), 'targetDataBroker', 'targetDirectories', 'settings', 'review'];
    }
    else {
        switch (protocol) {
            case 'nfs': {
                result = isSource
                    ? [createStepId(protocol, provider ), 'dataBroker', 'directories']
                    : isTargetOfSameSource
                        ? [createStepId(protocol, provider, true, ), 'targetDirectories', 'settings', 'review']
                        : [createStepId(protocol, provider, ), 'directories', 'settings', 'review'];
                break;
            }

            case 's3': {
                if (provider === 's3') {
                    result = isSource
                        ? ['dataBroker', 's3Buckets']
                        : isTargetOfSameSource
                            ? ['targetS3Buckets', 'settings', 'relationshipTags', 'review']
                            : ['s3Buckets', 'settings', 'relationshipTags', 'review'];
                }
                else if (provider === 'ontap') {
                    result = isSource
                        ? [createStepId(protocol, provider), 'dataBroker', createStepId(protocol, provider, false, 'Buckets')]
                        : isTargetOfSameSource
                            ? [createStepId(protocol, provider, true), createStepId(protocol, provider, true, 'Buckets'), 'settings', 'review']
                            : [createStepId(protocol, provider), createStepId(protocol, provider, false, 'Buckets'), 'settings', 'review'];
                }
                else {
                    result = isSource
                        ? [createStepId(protocol, provider), 'dataBroker', createStepId(protocol, provider, false, 'Buckets')]
                        : isTargetOfSameSource
                            ? [createStepId(protocol, provider, true), createStepId(protocol, provider, true, 'Buckets'), 'settings', 'relationshipTags', 'review']
                            : [createStepId(protocol, provider), createStepId(protocol, provider, false, 'Buckets'), 'settings', 'relationshipTags', 'review'];
                }
                break;
            }

            case 'cifs': {
                result = isSource
                    ? [createStepId(protocol, provider), 'dataBroker', 'shares']
                    : isTargetOfSameSource
                        ? [createStepId(protocol, provider, true), 'targetShares', 'settings', 'review']
                        : [createStepId(protocol, provider), 'shares', 'settings', 'review'];
                break;
            }
            case 'box' : {
                result = isSource ? ['box', 'dataBroker', 'boxFolders'] : ['box', 'boxFolders', 'settings', 'review']
                break;
            }
            case 'gdrive' : {
                result = isSource ? ['gdrive', 'dataBroker', 'gdriveFolders'] : ['gdrive', 'gdriveFolders', 'settings', 'relationshipTags', 'review']
                break;
            }
            case 'sftp' : {
                result = isSource ? ['sftp', 'dataBroker', 'sftpDirectories'] : ['sftp', 'sftpDirectories', 'settings', 'review']
                break;
            }
            case 'gcp': {
                result = isSource
                    ? ['dataBroker', 'gcpBuckets']
                    : isTargetOfSameSource
                        ? ['targetGcpBuckets', 'settings', 'relationshipTags', 'review']
                        : ['gcpBuckets', 'settings', 'relationshipTags', 'review'];
                break;
            }
            case 'azure': {
                if (provider === 'azure'){
                    result = isSource
                        ? ['azureBlob', 'dataBroker', 'blobContainers']
                        : isTargetOfSameSource
                            ? ['targetAzureBlob', 'targetBlobContainers', 'settings', 'relationshipTags', 'review']
                            : ['azureBlob', 'blobContainers', 'settings', 'relationshipTags', 'review'];

                } else {
                    result = isSource
                        ? ['azureDataLake', 'dataBroker', 'dataLakeContainers']
                        : isTargetOfSameSource
                            ? ['targetAzureDataLake', 'targetDataLakeContainers', 'settings', 'relationshipTags', 'review']
                            : ['azureDataLake', 'dataLakeContainers', 'settings', 'relationshipTags', 'review'];
                }
                break;
            }
            default: {
                throw new Error(`Unknown protocol ${protocol}`);
            }
        }
    }

    if (weSelectionNeeded) {
        const protocolCapitalized = _.capitalize(protocol);
        const providerCapitalized = _.capitalize(provider)
        result.unshift(isTargetOfSameSource ? `target${providerCapitalized}${protocolCapitalized}We` : `${provider}${protocolCapitalized}We`);
    }
    return result;
};

const createStepId = (protocol, provider, isTargetOfSameSource = false, suffix = '') => {
    const protocolAndProvider = getProtocolAndProviderKey(protocol, provider);
    const res = isTargetOfSameSource ? _.camelCase(`target ${protocolAndProvider}`) : protocolAndProvider;
    return `${res}${suffix}`
};

export const getProtocolAndProviderKey = (protocol, provider) => {
    return provider !== protocol ? _.camelCase(`${protocol} ${provider}`) : protocol;
};

export const stepInfoById = {
    dataSense: {name: "Data Sense Integration", path: 'datasense'},
    dataBroker: {name: FM_AGENT, path: 'agents'},
    dataBrokerFunction: {name: DATA_BROKER_FUNCTION, path: 'brokerfunction'},
    targetDataBroker: {
        name: `Target ${FM_AGENT}`,
        path: 'targetagents',
        isTargetStepFromSameSourceType: true
    },
    relationshipTags: {name: 'Tags/Metadata', path: 'tags'},
    settings: {name: 'Settings', path: 'settings'},
    review: {name: 'Review', path: 'review'},
    nfs: {name: END_POINT_NAMES_MAP.NFS, path: 'nfs', protocol: 'nfs'},
    targetNfs: {
        name: `Target ${END_POINT_NAMES_MAP.NFS}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    nfsAnf: {name: END_POINT_NAMES_MAP.ANF, path: 'nfs', protocol: 'nfs'},
    targetNfsAnf: {
        name: `Target ${END_POINT_NAMES_MAP.ANF}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    nfsEfs: {name: END_POINT_NAMES_MAP.EFS, path: 'nfs', protocol: 'nfs'},
    targetNfsEfs: {
        name: `Target ${END_POINT_NAMES_MAP.EFS}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    nfsCvs: {name: END_POINT_NAMES_MAP.CVS, path: 'nfs', protocol: 'nfs'},
    targetNfsCvs: {
        name: `Target ${END_POINT_NAMES_MAP.CVS}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    nfsCvo: {name: END_POINT_NAMES_MAP.CVO, path: 'nfs', protocol: 'nfs'},
    targetNfsCvo: {
        name: `Target ${END_POINT_NAMES_MAP.CVO}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    nfsOnprem: {name: END_POINT_NAMES_MAP.ONPREM, path: 'nfs', protocol: 'nfs'},
    targetNfsOnprem: {
        name: `Target ${END_POINT_NAMES_MAP.ONPREM}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    nfsFsx: {name: END_POINT_NAMES_MAP.FSX, path: 'nfs', protocol: 'nfs'},
    targetNfsFsx: {
        name: `Target ${END_POINT_NAMES_MAP.FSX}`,
        path: 'targetnfs',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    cifs: {name: [END_POINT_NAMES_MAP.CIFS], path: 'cifs', protocol: 'cifs'},
    targetCifs: {
        name: `Target ${END_POINT_NAMES_MAP.CIFS}`,
        path: 'targetcifs',
        isTargetStepFromSameSourceType: true,
        protocol: 'cifs'
    },
    cifsAnf: {name: [END_POINT_NAMES_MAP.ANF], path: 'cifs', protocol: 'cifs'},
    targetCifsAnf: {
        name: `Target ${END_POINT_NAMES_MAP.ANF}`,
        path: 'targetcifs',
        isTargetStepFromSameSourceType: true,
        protocol: 'cifs'
    },
    cifsCvs: {name: [END_POINT_NAMES_MAP.CVS], path: 'cifs', protocol: 'cifs'},
    targetCifsCvs: {
        name: `Target ${END_POINT_NAMES_MAP.CVS}`,
        path: 'targetcifs',
        isTargetStepFromSameSourceType: true,
        protocol: 'cifs'
    },
    cifsCvo: {name: [END_POINT_NAMES_MAP.CVO], path: 'cifs', protocol: 'cifs'},
    targetCifsCvo: {
        name: `Target ${END_POINT_NAMES_MAP.CVO}`,
        path: 'targetcifs',
        isTargetStepFromSameSourceType: true,
        protocol: 'cifs'
    },
    cifsOnprem: {name: [END_POINT_NAMES_MAP.ONPREM], path: 'cifs', protocol: 'cifs'},
    targetCifsOnprem: {
        name: `Target ${END_POINT_NAMES_MAP.ONPREM}`,
        path: 'targetcifs',
        isTargetStepFromSameSourceType: true,
        protocol: 'cifs'
    },
    cifsFsx: {name: [END_POINT_NAMES_MAP.FSX], path: 'cifs', protocol: 'cifs'},
    targetCifsFsx: {
        name: `Target ${END_POINT_NAMES_MAP.FSX}`,
        path: 'targetcifs',
        isTargetStepFromSameSourceType: true,
        protocol: 'cifs'
    },
    box: {name: END_POINT_NAMES_MAP.BOX, path: 'box', protocol: 'box'},
    targetBox: {name: `Target ${END_POINT_NAMES_MAP.BOX}`, path: 'targetbox', isTargetStepFromSameSourceType: false, protocol: 'box'},
    gdrive: {name: END_POINT_NAMES_MAP.GDRIVE, path: 'gdrive', protocol: 'gdrive'},
    targetgdrive: {name: `Target ${END_POINT_NAMES_MAP.GDRIVE}`, path: 'targetgdrive', isTargetStepFromSameSourceType: false, protocol: 'gdrive'},
    sftp: {name: END_POINT_NAMES_MAP.SFTP, path: 'sftp', protocol: 'sftp'},
    targetSftp: {name: `Target ${END_POINT_NAMES_MAP.SFTP}`, path: 'targetsftp', isTargetStepFromSameSourceType: true, protocol: 'sftp'},
    s3Sgws: {name: END_POINT_NAMES_MAP.SGWS, path: 's3based', protocol: 's3'},
    targetS3Sgws: {
        name: `Target ${END_POINT_NAMES_MAP.SGWS}`,
        path: 'targets3based',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    s3Ontap: {name: END_POINT_NAMES_MAP.ONTAP, path: 's3based', protocol: 's3'},
    targetS3Ontap: {
        name: `Target ${END_POINT_NAMES_MAP.ONTAP}`,
        path: 'targets3based',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    s3Ibm: {name: END_POINT_NAMES_MAP.IBM, path: 's3based', protocol: 's3'},
    targetS3Ibm: {
        name: `Target ${END_POINT_NAMES_MAP.IBM}`,
        path: 'targets3based',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    azureBlob: {name: END_POINT_NAMES_MAP.AZURE, path: 'azure', protocol: 'azure'},
    targetAzureBlob: {
        name: `Target ${END_POINT_NAMES_MAP.AZURE}`,
        path: 'targetazure',
        isTargetStepFromSameSourceType: true,
        protocol: 'azure'
    },
    azureDataLake:{name:END_POINT_NAMES_MAP.AZURE_DATA_LAKE, path:'azureDataLake', protocol:'azure'},
    targetAzureDataLake:{name:END_POINT_NAMES_MAP.AZURE_DATA_LAKE,
        path:'azureDataLake',
        isTargetStepFromSameSourceType: true,
        protocol:'azure'},
    directories: {name: 'Directories', path: 'exports', protocol: 'nfs'},
    targetDirectories: {
        name: 'Target Directories',
        path: 'targetexports',
        isTargetStepFromSameSourceType: true,
        protocol: 'nfs'
    },
    shares: {name: 'Shares', path: 'shares', protocol: 'cifs'},
    targetShares: {name: 'Target Shares', path: 'targetshares', isTargetStepFromSameSourceType: true, protocol: 'cifs'},
    boxFolders: {name: 'Box Folders', path: 'boxfolders', protocol: 'box'},
    targetBoxFolders: {name: 'Target Box Folders', path: 'targetboxfolders', isTargetStepFromSameSourceType: true, protocol: 'box'},
    gdriveFolders: {name: 'Google Folders', path: 'gdriveFolders', protocol: 'gdrive'},
    targetgdriveFolders: {name: 'Target Google Folders', path: 'targetgdriveFolders', isTargetStepFromSameSourceType: true, protocol: 'gdrive'},
    sftpDirectories: {name: 'SFTP Directories', path: 'sftpdirectories', protocol: 'sftp'},
    targetSftpDirectories: {name: 'Target SFTP Directories', path: 'targetsftpdirectories', isTargetStepFromSameSourceType: true, protocol: 'sftp'},
    s3Buckets: {name: 'AWS S3 Bucket', path: 'buckets', protocol: 's3'},
    targetS3Buckets: {
        name: 'Target AWS S3 Bucket',
        path: 'targetbuckets',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    gcpBuckets: {name: `Google Cloud Bucket`, path: 'gcpbuckets', protocol: 'gcp'},
    targetGcpBuckets: {
        name: `Target Google Cloud Bucket`,
        path: 'targetgcpbuckets',
        isTargetStepFromSameSourceType: true,
        protocol: 'gcp'
    },
    s3SgwsBuckets: {name: 'SG Bucket', path: 's3basedbuckets', protocol: 's3'},
    targetS3SgwsBuckets: {
        name: 'Target SG Bucket',
        path: 'targets3basedbuckets',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    s3OntapBuckets: {name: 'ONTAP S3 Bucket', path: 's3basedbuckets', protocol: 's3'},
    targetS3OntapBuckets: {
        name: 'Target ONTAP S3 Bucket',
        path: 'targets3basedbuckets',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    s3IbmBuckets: {name: 'IBM Bucket', path: 's3basedbuckets', protocol: 's3'},
    targetS3IbmBuckets: {
        name: 'Target IBM Bucket',
        path: 'targets3basedbuckets',
        isTargetStepFromSameSourceType: true,
        protocol: 's3'
    },
    blobContainers: {name: 'Azure Blob Containers', path: 'blobcontainers', protocol: 'azure'},
    targetBlobContainers: {
        name: 'Target Azure Blob Containers',
        path: 'targetblobcontainers',
        isTargetStepFromSameSourceType: true,
        protocol: 'azure'
    },
    dataLakeContainers: {name: 'Azure Data Lake Gen 2 Containers', path: 'dataLakecontainers', protocol: 'azure'},
    targetdataLakeContainers: {
        name: 'Target Azure Data Lake Gen 2 Containers',
        path: 'targetdataLakecontainers',
        isTargetStepFromSameSourceType: true,
        protocol: 'azure'
    },
    anfNfsWe: {name: 'Working Environment', path: 'anfnfswe', protocol: 'nfs'},
    anfCifsWe: {name: 'Working Environment', path: 'anfcifswe', protocol: 'cifs'},
    targetAnfNfsWe: {name: 'Target Working Environment', path: 'targetanfnfswe', isTargetStepFromSameSourceType: true, protocol: 'nfs'},
    targetAnfCifsWe: {name: 'Target Working Environment', path: 'targetanfcifswe', isTargetStepFromSameSourceType: true, protocol: 'cifs'},
    cvoNfsWe: {name: 'Working Environment', path: 'cvonfswe', protocol: 'nfs'},
    cvoCifsWe: {name: 'Working Environment', path: 'cvocifswe', protocol: 'cifs'},
    targetCvoNfsWe: {name: 'Target Working Environment', path: 'targetcvonfswe', isTargetStepFromSameSourceType: true, protocol: 'nfs'},
    targetCvoCifsWe: {name: 'Target Working Environment', path: 'targetcvocifswe', isTargetStepFromSameSourceType: true, protocol: 'cifs'},
    onpremNfsWe: {name: 'Working Environment', path: 'onpremnfswe', protocol: 'nfs'},
    onpremCifsWe: {name: 'Working Environment', path: 'onpremcifswe', protocol: 'cifs'},
    targetOnpremNfsWe: {name: 'Target Working Environment', path: 'targetonpremnfswe', isTargetStepFromSameSourceType: true, protocol: 'nfs'},
    targetOnpremCifsWe: {name: 'Target Working Environment', path: 'targetonpremcifswe', isTargetStepFromSameSourceType: true, protocol: 'cifs'},
    fsxNfsWe: {name: 'Working Environment', path: 'fsxnfswe', protocol: 'nfs'},
    fsxCifsWe: {name: 'Working Environment', path: 'fsxcifswe', protocol: 'cifs'},
    targetFsxNfsWe: {name: 'Target Working Environment', path: 'targetfsxnfswe', isTargetStepFromSameSourceType: true, protocol: 'nfs'},
    targetFsxCifsWe: {name: 'Target Working Environment', path: 'targetfsxcifswe', isTargetStepFromSameSourceType: true, protocol: 'cifs'}
};

const calculateSourceOrTarget = (wizardInfo, isTargetOfSameSource, protocol) => {
    let sourceOrTarget;

    if (wizardInfo.source.protocol === wizardInfo.target.protocol) {
        sourceOrTarget = isTargetOfSameSource ? 'target' : 'source';
    } else if (wizardInfo.source.protocol === protocol) sourceOrTarget = 'source';
    else if (wizardInfo.target.protocol === protocol) sourceOrTarget = 'target';

    if (!sourceOrTarget) throw new Error(`wizardInfo does not include ${protocol}`);
    return sourceOrTarget;
};

//This function identifies and returns the source/target object that is used by a specific step (the step that called the function)
export const getEndPointObjectForStep = (wizardInfo, isTargetOfSameSource, stepId) => {
    const stepProtocol = stepInfoById[stepId].protocol;
    if (!stepProtocol) return; //for neutral steps like dataBroker, settings...
    const sourceOrTarget = calculateSourceOrTarget(wizardInfo, isTargetOfSameSource, stepProtocol);
    return wizardInfo[sourceOrTarget];
};

export const calculateStepId = (wizardInfo, isTargetOfSameSource, protocol, suffix) => {
    const sourceOrTarget = calculateSourceOrTarget(wizardInfo, isTargetOfSameSource, protocol);
    return createStepId(wizardInfo[sourceOrTarget].protocol, wizardInfo[sourceOrTarget].provider, isTargetOfSameSource, suffix);
};

export const isSourceTargetSupported = (source, target) => {
    if (source.dataSense && (target.provider === "box" || target.provider === "sftp")) return false;
    if (source.provider === "ontap") return ["sgws", "ontap", "cifs", "gcp", "s3", "nfs"].includes(target.provider);
    if (target.provider === "ontap") return ["sgws", "cifs", "gcp", "s3", "nfs"].includes(source.provider);
    if (source.protocol === 'box') return ["nfs", 'cifs', "cvo", 'anf', 'fsx'].includes(target.provider) || target.protocol === 's3';
    if (target.protocol === 'box') return source.protocol === 's3';
    if (target.protocol === 'gdrive') return ['nfs', 'cifs'].includes(source.provider);
    if (source.protocol === 'sftp') return target.provider === 's3';
    if (target.provider === 'azure_data_lake') return ['nfs','cifs', 'cvo', 'anf', 'fsx', 'onprem'].includes(source.provider) || source.protocol === 's3';
    if (source.provider === 'azure_data_lake') return ['nfs','cifs', 'cvo', 'anf', 'fsx', 'onprem'].includes(target.provider) || target.protocol === 's3';
    if (target.protocol === "sftp") return false;
    if (source.protocol === 'gdrive') return ['nfs','cifs'].includes(target.provider);
    return true;
};

export const mapProtocolToSourceSteps = {
    'nfs': ['nfs', 'nfsAnf', 'nfsEfs', 'nfsCvs', 'nfsCvo', 'nfsOnprem', 'nfsFsx', 'directories', 'anfNfsWe', 'cvoNfsWe', 'onpremNfsWe', 'fsxNfsWe'],
    's3': ['s3Sgws', 's3Ibm', 's3Buckets', 's3SgwsBuckets', 's3IbmBuckets', 's3Ontap', 's3OntapBuckets'],
    'cifs': ['cifs', 'cifsAnf', 'cifsCvo', 'cifsCvs', 'cifsOnprem', 'cifsFsx', 'shares', 'anfCifsWe', 'cvoCifsWe', 'onpremCifsWe', 'fsxCifsWe'],
    'azure': ['azureBlob', 'blobContainers', 'azureDataLake', 'dataLakeContainers'],
    'gcp': ['gcpBuckets'],
    'box': ['box', 'boxFolders'],
    'gdrive': ['gdrive', 'gdriveFolders'],
    'sftp': ['sftp', 'sftpDirectories']
};

//protocols and providers
export const sourceAndTargetDisplayNameForSystemFlow = {
    's3': 'AWS S3 Bucket',
    'sgws': END_POINT_NAMES_MAP.SGWS,
    'gcp': `${END_POINT_NAMES_MAP.GCP} Bucket`,
    'ibm': END_POINT_NAMES_MAP.IBM,
    'nfs': END_POINT_NAMES_MAP.NFS,
    'cifs': END_POINT_NAMES_MAP.CIFS,
    'efs': END_POINT_NAMES_MAP.EFS,
    'box': END_POINT_NAMES_MAP.BOX,
    'sftp': END_POINT_NAMES_MAP.SFTP,
    'gdrive': END_POINT_NAMES_MAP.GDRIVE,
    'azure': END_POINT_NAMES_MAP.AZURE,
    'cvs': END_POINT_NAMES_MAP.CVS,
    'anf': END_POINT_NAMES_MAP.ANF,
    'ontap': END_POINT_NAMES_MAP.ONTAP,
    'cvo': END_POINT_NAMES_MAP.CVO,
    'fsx': END_POINT_NAMES_MAP.FSX,
    'onprem': END_POINT_NAMES_MAP.ONPREM,
    'azure_data_lake':END_POINT_NAMES_MAP.AZURE_DATA_LAKE
};

//only protocols
export const endPointTypes = {
    'nfs': END_POINT_FILE_SYSTEM,
    'cifs': END_POINT_FILE_SYSTEM,
    'box': END_POINT_FILE_SYSTEM,
    'gdrive': END_POINT_FILE_SYSTEM,
    'sftp': END_POINT_FILE_SYSTEM,
    's3': END_POINT_STORAGE,
    'gcp': END_POINT_STORAGE,
    'azure': END_POINT_STORAGE
};

export const getWizardTypeForSystemFlow = (wizardInfo) => {
    return `${wizardInfo.source.provider}-${wizardInfo.target.provider}`;
};

export const calculateNextStepObj = (wizard, currentStep) => {
    const currentStepIndex = wizard.stepsOrder.indexOf(currentStep);
    const nextStepId = wizard.stepsOrder[currentStepIndex + 1];
    return wizard.stepInfoById[nextStepId];
};

export const calculateCurrentStepObj = (wizard, currentStep) => {
    const currentStepIndex = wizard.stepsOrder.indexOf(currentStep);
    const currentStepId = wizard.stepsOrder[currentStepIndex];
    return wizard.stepInfoById[currentStepId];
};

export const getObjectStorageTargetTypeFromWizardInfo = (wizardInfo) => {
    switch (wizardInfo.target.protocol) {
        case 'azure':{
            if (wizardInfo.target.provider ==='azure'){
                return 'Azure Blob Container';
            } else {
                return 'Azure Data Lake Gen 2 Container';
            }
        }
        case 's3':
            if (wizardInfo.target.provider ==='s3'){
                return 'S3 bucket';
            } else if (wizardInfo.target.provider ==='sgws') {
                return 'StorageGRID bucket'

            } else if (wizardInfo.target.provider ==='ibm') {
                return 'IBM bucket';
            } else {
                return 'ONTAP S3 bucket';
            }
        case 'gcp':
            return 'Google Cloud Storage';
        case 'gdrive':
            return 'Google Drive';
        default:
            throw new Error(`Object storage of type ${wizardInfo.target.protocol} is not defined for getObjectStorageTargetTypeFromWizardInfo`);
    }
};

export const splitCombinationToSourceTarget = (type, splitter='-') => {
    const split = type.split(splitter);
    return {source: split[0], target: split[1]};
};

const getVersion = (type, key, isTargetOfSameSource, defaultVersion) => {
    const version = getCredentialsByType(type, key, isTargetOfSameSource)?.version
    return version?.value || defaultVersion;
};

const getStepCredentialsByKey = key => {
    const { getState } = store;
    const stepCredentials = {...getState()?.syncReducer?._wizard?.wizardInfo?.stepCredentials} || {};
    return {...stepCredentials[_.camelCase(key)]} || {};
}

export const saveStepCredentials = (type, key, data, isTargetOfSameSrc) => {
    const { dispatch } = store;
    const formattedType = isTargetOfSameSrc ? `${type}-trg` : type;
    const keyCamelCase = _.camelCase(formattedType);
    const credentials = getStepCredentialsByKey(keyCamelCase);
    credentials[key] = data;

    updateStepCredentials({ key: keyCamelCase, credentials })(dispatch);
};

export const getCredentialsByType = (type, key, isTargetOfSameSrc, remove) => {
    const formattedType = isTargetOfSameSrc ? `${type}Trg` : type;
    const credentials = getStepCredentialsByKey(formattedType);
    const credObjectByKey = {...credentials[key]};
    if (remove && credObjectByKey) {
        _.each(remove, keyToRemove => delete credObjectByKey[keyToRemove]);
    }

    return credObjectByKey;
};

export const removeCredentialsByType = (type) => {
    const { dispatch } = store;

    const credentials = getStepCredentialsByKey(type);
    delete credentials[type];
    delete credentials[_.camelCase(`${type}-trg`)];

    updateStepCredentials({ key: type, credentials })(dispatch);
};

export const getSourceAndTargetInfo = ({
                                           _wizard: {
                                               selectedWizardParams: {
                                                   selectedNFS, selectedNFSTarget,
                                                   selectedCIFS, selectedCIFSTarget,
                                                   selectedBoxAppName, selectedBoxAppNameTarget,
                                                   selectedGoogle, selectedGoogleTarget,
                                                   selectedSftp, selectedSftpTarget,
                                                   selectedS3BasedBucketPath, selectedS3BasedBucketPathTarget, selectedS3Based, selectedS3BasedTarget,
                                                   selectedBucketPath, selectedBucketPathTarget, selectedGCPBucketPath, selectedGCPBucketPathTarget,
                                                   selectedExport, selectedExportTarget,
                                                   selectedShare, selectedShareTarget,
                                                   selectedBoxDirectory, selectedBoxDirectoryTarget,
                                                   selectedGoogleDriveFolder, selectedGoogleDriveFolderTarget,
                                                   selectedSftpDirectory, selectedSftpDirectoryTarget,
                                                   selectedStorageAccountName, selectedStorageAccountNameTarget,
                                                   selectedAzureBlobContainerPath, selectedAzureBlobContainerPathTarget,
                                                   selectedAzureDataLakeContainerPath, selectedAzureDataLakeContainerPathTarget
                                               }, wizardInfo
                                           }
                                       }) => {
    const getBucketPaths = (fullBucketPath) => {
        const [bucketName, ...restOfBucketPath] = removeStartingAndEndingSlash(fullBucketPath || '').split('/');
        const bucketPath = restOfBucketPath ? restOfBucketPath.join('/') : '';
        return {
            bucketName,
            bucketPath
        }
    };
    const infoByProtocol = (endpointObj, asTarget) => {
        let objectInfo = {};

        switch (endpointObj.protocol) {
            case 'nfs' : {
                objectInfo.title = endpointObj.provider ? END_POINT_NAMES_MAP[endpointObj.provider.toUpperCase()] : END_POINT_NAMES_MAP.NFS;
                objectInfo.description = asTarget && selectedNFSTarget ? selectedNFSTarget : selectedNFS;
                objectInfo.subtitle = 'Directory';
                objectInfo.subtitleDescription = asTarget && selectedExportTarget ? selectedExportTarget : selectedExport;
                break;
            }
            case 'cifs' : {
                objectInfo.title = endpointObj.provider ? END_POINT_NAMES_MAP[endpointObj.provider.toUpperCase()] : END_POINT_NAMES_MAP.CIFS;
                objectInfo.description = asTarget && selectedCIFSTarget ? selectedCIFSTarget : selectedCIFS;
                objectInfo.subtitle = 'Share';
                objectInfo.subtitleDescription = asTarget && selectedShareTarget ? selectedShareTarget : selectedShare;
                break;
            }
            case 'box' : {
                const actualAppName = asTarget && selectedBoxAppNameTarget ? selectedBoxAppNameTarget : selectedBoxAppName;
                const {clientId} = getCredentialsByType('box', selectedBoxAppName, false)
                objectInfo.title = `Box App (Client Id)`;
                objectInfo.description = `${actualAppName} (${clientId})`;
                objectInfo.subtitle = 'Folder';
                objectInfo.subtitleDescription = asTarget && selectedBoxDirectoryTarget ? selectedBoxDirectoryTarget: selectedBoxDirectory;
                break;
            }
            case 'gdrive' : {
                const actualGoogle = asTarget && selectedGoogleTarget ? selectedGoogleTarget: selectedGoogle;
                const actualFolder = asTarget && selectedGoogleDriveFolderTarget? selectedGoogleDriveFolderTarget: selectedGoogleDriveFolder;
                objectInfo.title = `Google Drive`;
                objectInfo.description = `${actualGoogle}`;
                objectInfo.subtitle = 'Folder';
                objectInfo.subtitleDescription = fixGooglePath(actualFolder);
                break;
            }
            case 'sftp' : {
                objectInfo.title = END_POINT_NAMES_MAP.SFTP;
                objectInfo.description = asTarget && selectedSftpTarget? selectedSftpTarget : selectedSftp;
                objectInfo.subtitle = 'Directory';
                objectInfo.subtitleDescription = asTarget && selectedSftpDirectoryTarget ? selectedSftpDirectoryTarget: selectedSftpDirectory;
                break;
            }
            case 's3': {
                if (endpointObj.provider === 's3') {
                    const bucketPaths = asTarget && selectedBucketPathTarget ? getBucketPaths(selectedBucketPathTarget) : getBucketPaths(selectedBucketPath);
                    objectInfo.title = OBJECT_STORAGE_NAMES_MAP.S3;
                    objectInfo.description = bucketPaths.bucketName;
                    objectInfo.subtitle = 'Folder';
                    objectInfo.subtitleDescription = bucketPaths.bucketPath;
                    break;
                } else {
                    const host = asTarget && selectedS3BasedTarget ? selectedS3BasedTarget : selectedS3Based;
                    const port = getCredentialsByType('objectStorage', host, asTarget && selectedS3BasedTarget)?.port;
                    const bucketPaths = asTarget && selectedS3BasedBucketPathTarget ? getBucketPaths(selectedS3BasedBucketPathTarget) : getBucketPaths(selectedS3BasedBucketPath);
                    objectInfo.title = OBJECT_STORAGE_NAMES_MAP[endpointObj.provider.toUpperCase()];
                    objectInfo.description = `${host}${port ? `:${port}` : ""}/${bucketPaths.bucketName}`;//ontap s3 doesn't use port
                    objectInfo.subtitle = 'Folder';
                    objectInfo.subtitleDescription = bucketPaths.bucketPath;
                    break;
                }
            }
            case 'gcp': {
                const bucketPaths = getBucketPaths(asTarget && selectedGCPBucketPathTarget ? selectedGCPBucketPathTarget : selectedGCPBucketPath);
                objectInfo.title = OBJECT_STORAGE_NAMES_MAP.GCP;
                objectInfo.description = bucketPaths.bucketName;
                objectInfo.subtitle = 'Folder';
                objectInfo.subtitleDescription = bucketPaths.bucketPath;
                break;
            }
            case 'azure': {
                if (endpointObj.provider === 'azure'){
                    objectInfo.title = OBJECT_STORAGE_NAMES_MAP.AZURE;
                    objectInfo.description = asTarget && selectedStorageAccountNameTarget ? selectedStorageAccountNameTarget : selectedStorageAccountName;
                    objectInfo.subtitle = 'Container';
                    objectInfo.subtitleDescription = asTarget && selectedAzureBlobContainerPathTarget ? selectedAzureBlobContainerPathTarget : selectedAzureBlobContainerPath;
                    break;
                }else {
                    objectInfo.title = END_POINT_NAMES_MAP['AZURE_DATA_LAKE'];
                    objectInfo.description = asTarget && selectedStorageAccountNameTarget ? selectedStorageAccountNameTarget : selectedStorageAccountName;
                    objectInfo.subtitle = 'Container';
                    objectInfo.subtitleDescription = asTarget && selectedAzureDataLakeContainerPathTarget ? selectedAzureDataLakeContainerPathTarget : selectedAzureDataLakeContainerPath;
                    break;
                }

            }
            default: {
                objectInfo.title = 'Unknown Protocol';
                objectInfo.description = 'Unknown Protocol';
                objectInfo.subtitle = 'Unknown Protocol';
                objectInfo.subtitleDescription = 'Unknown Protocol';
                break;
            }
        }
        return objectInfo;
    };
    const sourceInfo = infoByProtocol(wizardInfo.source);
    const targetInfo = infoByProtocol(wizardInfo.target, true);
    return {sourceInfo, targetInfo};
};


export const breakFullPathToRootAndPath = (fullPath, root) => {
    const trimedRoot = removeStartingAndEndingSlash(root);
    const trimedFullPath = removeStartingAndEndingSlash(fullPath);
    let pathWithoutRoot, rootOfPath;
    if (!_.isNil(trimedRoot)) {//exports and shares
        const rootLen = trimedRoot.length;
        rootOfPath = root;
        if (_.startsWith(trimedFullPath, trimedRoot))
            pathWithoutRoot = removeStartingAndEndingSlash(trimedFullPath.slice(rootLen));
        else pathWithoutRoot = trimedFullPath;
    }
    else {//in case of bucket, there is no root;
        const indexOfFirstSlash = trimedFullPath.indexOf('/');
        pathWithoutRoot = trimedFullPath.slice(indexOfFirstSlash + 1);
        rootOfPath = trimedFullPath.slice(0, indexOfFirstSlash)
    }

    return {pathWithoutRoot, rootOfPath};
};

export const buildSyncObject = ({
                                    _wizard: {
                                        selectedWizardParams: {
                                            selectedDataBroker, selectedDataBrokerTarget, selectedIsTargetDataBrokerInitiator, selectedNFS, selectedNFSTarget,
                                            selectedCIFS, selectedCIFSTarget,
                                            selectedSftp, selectedSftpTarget,
                                            selectedS3Based, selectedS3BasedTarget, selectedS3BasedBucketPath, selectedS3BasedBucketPathTarget,
                                            selectedBucketPath, selectedBucketPathTarget, selectedStorageClass, selectedEncryptionType,
                                            selectedExport, selectedExportTarget,
                                            selectedShare, selectedShareTarget,
                                            selectedRootExport, selectedRootExportTarget,
                                            selectedBoxAppName, selectedBoxAppNameTarget,
                                            selectedBoxDirectory, selectedBoxDirectoryTarget, selectedRootBoxDirectory, selectedRootBoxDirectoryTarget,
                                            selectedGoogle, selectedGoogleTarget,
                                            selectedGoogleDrive, selectedGoogleDriveTarget, selectedGoogleDriveFolder, selectedGoogleDriveFolderTarget,
                                            selectedSftpDirectory, selectedSftpDirectoryTarget, selectedRootSftpDirectory, selectedRootSftpDirectoryTarget,
                                            selectedRootShare, selectedRootShareTarget, relationshipTags,
                                            selectedStorageAccountName, selectedStorageAccountNameTarget,
                                            selectedAzureBlobContainerPath, selectedAzureBlobContainerPathTarget,
                                            selectedAzureDataLakeContainerPath, selectedAzureDataLakeContainerPathTarget,
                                            selectedGCPBucketPath, selectedGCPBucketPathTarget, selectedBlobTier, selectedDataLakeTier, selectedGcpStorageClass,
                                            selectedAnfCifsWe, selectedAnfCifsWeTarget, selectedAnfNfsWe, selectedAnfNfsWeTarget,
                                            selectedCvoCifsWe, selectedCvoCifsWeTarget, selectedCvoNfsWe, selectedCvoNfsWeTarget,
                                            selectedOnpremCifsWe, selectedOnpremCifsWeTarget, selectedFsxCifsWe, selectedFsxCifsWeTarget,
                                            selectedOnpremNfsWe, selectedOnpremNfsWeTarget, selectedFsxNfsWe, selectedFsxNfsWeTarget,
                                            selectedEfsAccessPoint, selectedEfsAccessPointTarget
                                        }, wizardInfo, isSecure
                                    }, _showBuckets, _showBoxFolders, _showGoogleDrives, targetGcpSelectedEncryption
                                }) => {

    const relationshipTagsArray = relationshipTags === 'noTags' || !relationshipTags ? [] : stringToTags(relationshipTags);
    const selectedIsTargetDataBrokerInitiatorBool = (selectedIsTargetDataBrokerInitiator === 'true');

    const encryption = isSecure ? {
        initiator: {
            groupId: selectedIsTargetDataBrokerInitiatorBool ? selectedDataBrokerTarget.id: selectedDataBroker.id,
            endpointType: selectedIsTargetDataBrokerInitiatorBool ? "trg" : "src",

        },
        listener: {
            groupId: selectedIsTargetDataBrokerInitiatorBool ? selectedDataBroker.id : selectedDataBrokerTarget.id,
            endpointType: selectedIsTargetDataBrokerInitiatorBool ? "src" : "trg"
        }
    } : undefined;

    const settings = getSettingsFromSessionStorage();

    //CS-6087 ObjectTagging field on relationships that are not to S3 should be sent according to the protocol and provider
    if (!((wizardInfo.target.protocol === "s3" && wizardInfo.target.provider !== "ontap") || wizardInfo.target.protocol === "azure") || wizardInfo.target.protocol === "gcp") {
        settings.objectTagging = undefined;
    }

    let syncRequestObject = {
        groupId: selectedIsTargetDataBrokerInitiatorBool ? selectedDataBrokerTarget.id: selectedDataBroker.id,
        settings,
        encryption
    };

    const breakBucketPathToBucketAndPrefix = (bucketPath) => {
        const splittedBucketPath = _.split(bucketPath, '/');
        const bucket = splittedBucketPath[0];
        const prefix = _.size(splittedBucketPath) <= 1 ? '' : _.join(_.drop(splittedBucketPath), '/');
        return {bucket, prefix};

    };

    const getEncryption = (selectedEncryptionType, credentials) => {
        const encryptionTypeObj = _.find(ENCRYPTION_OPTIONS, {name: selectedEncryptionType});
        const encryptionDetails = {protocol: selectedEncryptionType};
        if (encryptionTypeObj?.isKeyRequired) {

            encryptionDetails.keyId = credentials?.encryptionTypeOption.value === ENCRYPTION_TYPES.KMS_EXISTING ? credentials?.encryptionKeyOption.value : credentials.encryptionKeyManual;
        }
        return encryptionDetails;
    };

    const getWeByProviderAndProtocol = (isTargetOfSameSource, provider, protocol) => {
        if (protocol === "nfs") {
            if (provider === "anf") return isTargetOfSameSource ? selectedAnfNfsWeTarget : selectedAnfNfsWe
            if (provider === "cvo") return  isTargetOfSameSource ? selectedCvoNfsWeTarget : selectedCvoNfsWe
            if (provider === "onprem") return  isTargetOfSameSource ? selectedOnpremNfsWeTarget : selectedOnpremNfsWe
            if (provider === "fsx") return  isTargetOfSameSource ? selectedFsxNfsWeTarget : selectedFsxNfsWe
        }
        if (protocol === "cifs") {
            if (provider === "anf") return isTargetOfSameSource ? selectedAnfCifsWeTarget : selectedAnfCifsWe
            if (provider === "cvo") return  isTargetOfSameSource ? selectedCvoCifsWeTarget : selectedCvoCifsWe
            if (provider === "onprem") return  isTargetOfSameSource ? selectedOnpremCifsWeTarget : selectedOnpremCifsWe
            if (provider === "fsx") return  isTargetOfSameSource ? selectedFsxCifsWeTarget : selectedFsxCifsWe
        }
        return undefined;
    }

    function nfsKindObjBuilder(isTargetOfSameSource, isSourceOfSameTarget, provider) {
        const brokenPath = isTargetOfSameSource ? breakFullPathToRootAndPath(selectedExportTarget, selectedRootExportTarget) : breakFullPathToRootAndPath(selectedExport, selectedRootExport);
        const host = isTargetOfSameSource ? selectedNFSTarget : selectedNFS;
        const nfsType = provider;
        const defaultVersion = DEFAULT_NFS_VERSION[nfsType.toUpperCase()];
        const version =  getVersion("nfs", host, isTargetOfSameSource, defaultVersion);
        const workingEnvironmentId = getWeByProviderAndProtocol(isTargetOfSameSource, provider, "nfs");
        const accessPoint = isTargetOfSameSource ? selectedEfsAccessPointTarget : selectedEfsAccessPoint;
        return {
            host,
            export: brokenPath.rootOfPath,
            path: brokenPath.pathWithoutRoot,
            version,
            provider,
            workingEnvironmentId,
            accessPoint
        };
    }

    const getParamsByProtocol = (protocol, provider, isTargetOfSameSource, isSourceOfSameTarget, isSecure) => {
        let protocolObject;
        switch (protocol) {
            case 'nfs': {
                protocolObject = nfsKindObjBuilder(isTargetOfSameSource, isSourceOfSameTarget, provider, isSecure);
                break;
            }
            case 's3': {
                if (provider === 's3') {
                    const actualBucket = isTargetOfSameSource ? selectedBucketPathTarget : selectedBucketPath;
                    const brokenPath = breakBucketPathToBucketAndPrefix(actualBucket);
                    const credentials = getCredentialsByType('s3', actualBucket, isTargetOfSameSource)
                    protocolObject = {
                        provider,
                        bucket: brokenPath.bucket,
                        prefix: brokenPath.prefix,
                        encryption: (isSourceOfSameTarget || !selectedEncryptionType || selectedEncryptionType === ENCRYPTION_TYPES_NAMES.NONE)
                            ? undefined
                            : getEncryption(selectedEncryptionType, credentials),
                        tags: (isSourceOfSameTarget || !relationshipTags) ? undefined : relationshipTagsArray,
                        storageClass: (isSourceOfSameTarget || !selectedStorageClass) ? undefined : selectedStorageClass,
                        region: getBucketRegion(_showBuckets, selectedDataBroker.id, brokenPath.bucket),
                        privateLinkDns: credentials?.s3PrivateLinks?.value
                    };
                    break;
                } else {
                    const brokenPath = breakBucketPathToBucketAndPrefix(isTargetOfSameSource ? selectedS3BasedBucketPathTarget : selectedS3BasedBucketPath);
                    const theHost = isTargetOfSameSource ? selectedS3BasedTarget : selectedS3Based;
                    protocolObject = {
                        provider,
                        host: theHost,
                        port: getCredentialsByType('objectStorage', theHost, isTargetOfSameSource)?.port,
                        bucket: brokenPath.bucket,
                        prefix: brokenPath.prefix
                    };
                    //for these providers add also the tags
                    if (provider === 'ibm' || provider === 'sgws') {
                        protocolObject.tags = (isSourceOfSameTarget || !relationshipTags) ? undefined : relationshipTagsArray;
                    }
                    // console.log(`%c creating S3 protocol object with provider other than S3:\n${JSON.stringify(protocolObject, null, 2)}`, 'font-weight:bold; color:red');
                    break;
                }
            }
            case 'cifs': {
                const brokenPath = isTargetOfSameSource ? breakFullPathToRootAndPath(selectedShareTarget, selectedRootShareTarget) : breakFullPathToRootAndPath(selectedShare, selectedRootShare);
                const host = isTargetOfSameSource ? selectedCIFSTarget : selectedCIFS;
                const version = getVersion("cifs", host, isTargetOfSameSource, DEFAULT_CIFS_VERSION);
                const workingEnvironmentId = getWeByProviderAndProtocol(isTargetOfSameSource, provider, "cifs")

                protocolObject = {
                    host,
                    share: brokenPath.rootOfPath,
                    path: brokenPath.pathWithoutRoot,
                    version,
                    provider,
                    workingEnvironmentId
                };
                break;
            }
            case 'box' : {
                const actualPath = isTargetOfSameSource ? selectedBoxDirectoryTarget : selectedBoxDirectory;
                const actualRoot = isTargetOfSameSource ? selectedRootBoxDirectoryTarget : selectedRootBoxDirectory;
                const storageKey = isTargetOfSameSource ? selectedBoxAppNameTarget: selectedBoxAppName;
                const {clientId, enterpriseId} = getCredentialsByType('box', storageKey, false)
                let directoryId = -1;
                if (_showBoxFolders && _showBoxFolders[storageKey] && _showBoxFolders[storageKey].succeeded ) {
                    const {foundDirectory: directoryObject, parentFolderId} = getDirectoryObjectFromCache(_showBoxFolders, storageKey, `${actualRoot}${actualPath}`)
                    if (directoryObject){
                        directoryId = directoryObject.folderId;
                    }
                    else {
                        directoryId = parentFolderId
                    }
                }
                protocolObject = {
                    provider,
                    path: directoryId === 0  ? "/" : actualPath,
                    folderId: directoryId === -1 ? undefined : (directoryId).toString(), //for new target folder there will be no folderId
                    clientId,
                    appName: storageKey,
                    enterpriseId
                };
                break;
            }
            case 'gdrive' : {
                const actualPath = isTargetOfSameSource ? selectedGoogleDriveFolderTarget : selectedGoogleDriveFolder;
                const actualRoot = isTargetOfSameSource ? selectedGoogleDriveTarget : selectedGoogleDrive;
                const finalPath = fixGooglePath(actualPath, actualRoot);
                const storageKey = isTargetOfSameSource ? selectedGoogleTarget: selectedGoogle;
                const {serverName} = getCredentialsByType('google', storageKey, false)
                if (_showGoogleDrives && _showGoogleDrives[storageKey] && _showGoogleDrives[storageKey].succeeded ) {
                    //todo extract the right driveId wither from the root drives or from within the folder
                    const {foundDirectory: directoryObject} = getDirectoryObjectFromCacheGoogle(_showGoogleDrives, storageKey, `${actualPath}`)
                    protocolObject = {
                        provider,
                        path: finalPath,
                        // folderId: directoryObject.folderId, //if it is a new directory it won't have a folderId
                        parentFolderId: directoryObject.parentFolderId, //we always give the parentFolderId
                        folderId: directoryObject.folderId,
                        subject: serverName,
                        driveName: actualRoot,
                        driveId: directoryObject.driveId || directoryObject.id, //in case of drive it is defined as id
                        tags: relationshipTagsArray
                    };
                }
                break;
            }
            case 'sftp': {
                const actualPath = isTargetOfSameSource ? selectedSftpDirectoryTarget : selectedSftpDirectory;
                const actualRoot = isTargetOfSameSource ? selectedRootSftpDirectoryTarget : selectedRootSftpDirectory;
                const host = isTargetOfSameSource ? selectedSftpTarget : selectedSftp;
                const {port} = getCredentialsByType('sftp', host, false)

                protocolObject = {
                    host,
                    port,
                    rootDirectory: actualRoot,
                    path: actualPath,
                    provider
                };
                break;
            }
            case 'azure': {
                if (provider === 'azure') {
                    const brokenPath = breakBucketPathToBucketAndPrefix(isTargetOfSameSource ? selectedAzureBlobContainerPathTarget : selectedAzureBlobContainerPath);
                    protocolObject = {
                        storageAccountName: isTargetOfSameSource ? selectedStorageAccountNameTarget : selectedStorageAccountName,
                        container: brokenPath.bucket,
                        prefix: brokenPath.prefix,
                        tags: relationshipTagsArray,
                        blobTier: isSourceOfSameTarget ? undefined : selectedBlobTier,
                        provider
                    };
                } else {
                    const brokenPath = breakBucketPathToBucketAndPrefix(isTargetOfSameSource ? selectedAzureDataLakeContainerPathTarget : selectedAzureDataLakeContainerPath);
                    protocolObject = {
                        storageAccountName: isTargetOfSameSource ? selectedStorageAccountNameTarget : selectedStorageAccountName,
                        container: brokenPath.bucket,
                        path: brokenPath.prefix,
                        tags: relationshipTagsArray,
                        blobTier: isSourceOfSameTarget ? undefined : selectedDataLakeTier,
                        provider
                    };
                }
                break;
            }
            case 'gcp': {
                const brokenPath = breakBucketPathToBucketAndPrefix(isTargetOfSameSource ? selectedGCPBucketPathTarget : selectedGCPBucketPath);
                protocolObject = {
                    bucket: brokenPath.bucket,
                    prefix: brokenPath.prefix,
                    tags: (isSourceOfSameTarget || !relationshipTags) ? undefined : relationshipTagsArray,
                    storageClass: selectedGcpStorageClass,
                    provider
                };

                if (targetGcpSelectedEncryption) {
                    protocolObject.kmsKeyName = targetGcpSelectedEncryption.encryptionKey;
                }

                break;
            }
            default: {
                throw new Error(`getParamsByProtocol unknown protocol ${protocol}`);
            }
        }
        return {protocol, [protocol]: protocolObject}

    };

    const isTargetOfSameSource = wizardInfo.source.protocol === wizardInfo.target.protocol;

    //put the basic params
    syncRequestObject.source = getParamsByProtocol(wizardInfo.source.protocol, wizardInfo.source.provider, false, isTargetOfSameSource, isSecure);
    syncRequestObject.target = getParamsByProtocol(wizardInfo.target.protocol, wizardInfo.target.provider, isTargetOfSameSource, false, isSecure);

    //Get workingEnvironmentId directly from the store is a temporary solution until the wizard will include WE step for CVO
    const cmIntegrationDetails = getCmIntegrationRightPanelDetails();

    //add param to source:
    switch (wizardInfo.source.protocol) {
        case 's3': {
            if (cmIntegrationDetails?.source?.sourceWorkingEnvironmentType === 'SGWS' &&
                syncRequestObject.source.s3.provider === 'sgws' && cmIntegrationDetails?.source?.sourceWorkingEnvironmentId){
                syncRequestObject.source.s3.workingEnvironmentId = cmIntegrationDetails.source?.sourceWorkingEnvironmentId || undefined;
            }

            if (wizardInfo.source.provider !== 's3') {
                syncRequestObject.source.s3.credentials = getCredentialsByType('objectStorage', selectedS3Based, false, ['serverName', 'port']);
                break;
            }
            break;
        }
        case 'nfs': {
            if (cmIntegrationDetails?.sourceWorkingEnvironmentId && !syncRequestObject.source.nfs.workingEnvironmentId) {
                syncRequestObject.source.nfs.workingEnvironmentId = cmIntegrationDetails.sourceWorkingEnvironmentId || undefined;
            }
            break;
        }
        case 'cifs': {
            syncRequestObject.source.cifs.credentials = getCredentialsByType('cifs', selectedCIFS, false, ['serverName', 'version', 'copyAcl', 'copyData']);
            if (cmIntegrationDetails?.sourceWorkingEnvironmentId && !syncRequestObject.source.cifs.workingEnvironmentId) {
                syncRequestObject.source.cifs.workingEnvironmentId = cmIntegrationDetails.sourceWorkingEnvironmentId  || undefined;
            }
            break;
        }
        case 'box' : {
            syncRequestObject.source.box.credentials = getCredentialsByType('box', selectedBoxAppName, false, ["serverName", "appName", "clientId", "enterpriseId"]);
            break;
        }
        case 'gdrive' : {
            syncRequestObject.source.gdrive.credentials = getCredentialsByType('google', selectedGoogle, false, ['serverName']);
            break;
        }
        case 'sftp' : {
            syncRequestObject.source.sftp.credentials = getCredentialsByType('sftp', selectedSftp, false, ["serverName", "port"]);
            break;
        }
        case 'azure': {
            const azureProvider= wizardInfo.source.provider === 'azure' ? 'azure' : 'azureDataLakeGen2';
            const connectionString = getCredentialsByType(azureProvider, selectedStorageAccountName, false)?.connectionString;
            syncRequestObject.source.azure.credentials = {storageAccountConnectionString: connectionString};
            break;
        }
        default: {
            //do nothing;
        }
    }

    //add param to target:
    switch (wizardInfo.target.protocol) {
        case 's3': {
            if (cmIntegrationDetails?.target?.targetWorkingEnvironmentType === 'SGWS' &&
                syncRequestObject.target.s3.provider === 'sgws' &&
                cmIntegrationDetails?.target?.targetWorkingEnvironmentId){
                syncRequestObject.target.s3.workingEnvironmentId = cmIntegrationDetails?.target?.targetWorkingEnvironmentId || undefined;
            }

            if (wizardInfo.target.provider !== 's3') {
                if (isTargetOfSameSource) {
                    syncRequestObject.target.s3.credentials = getCredentialsByType('objectStorage', selectedS3BasedTarget, true, ['serverName', 'port']);
                } else {
                    syncRequestObject.target.s3.credentials = getCredentialsByType('objectStorage', selectedS3Based, false, ['serverName', 'port']);
                }
                break;
            }
            break;
        }
        case 'nfs': {
            // for backward compatibility - issource should be deleted
            if (cmIntegrationDetails?.targetWorkingEnvironmentId && !syncRequestObject.target.nfs.workingEnvironmentId) {
                syncRequestObject.target.nfs.workingEnvironmentId = cmIntegrationDetails.targetWorkingEnvironmentId || undefined;
            }
            break;
        }
        case 'cifs': {
            if (isTargetOfSameSource) {
                syncRequestObject.target.cifs.credentials = getCredentialsByType('cifs', selectedCIFSTarget, true, ['serverName', 'version', 'copyAcl', 'copyData']);
            }
            else {
                syncRequestObject.target.cifs.credentials = getCredentialsByType('cifs', selectedCIFS, false, ['serverName', 'version', 'copyAcl', 'copyData']);
            }
            // for backward compatability -issource should be deleted
            if (cmIntegrationDetails?.targetWorkingEnvironmentId && !syncRequestObject.target.cifs.workingEnvironmentId) {
                syncRequestObject.target.cifs.workingEnvironmentId = cmIntegrationDetails.targetWorkingEnvironmentId || undefined;
            }
            break;
        }
        case 'box': {
            if (isTargetOfSameSource) {
                syncRequestObject.target.box.credentials = getCredentialsByType('box', selectedBoxAppNameTarget, true, ["serverName", "appName", "clientId", "enterpriseId"]);
            }
            else {
                syncRequestObject.target.box.credentials = getCredentialsByType('box', selectedBoxAppName, false, ["serverName", "appName", "clientId", "enterpriseId"]);
            }
            break;
        }
        case 'gdrive': {
            if (isTargetOfSameSource) {
                syncRequestObject.target.gdrive.credentials = getCredentialsByType('google', selectedGoogleTarget, true, ['serverName']);
            }
            else {
                syncRequestObject.target.gdrive.credentials = getCredentialsByType('google', selectedGoogle, false, ['serverName']);
            }
            break;
        }
        case 'sftp': {
            if (isTargetOfSameSource) {
                syncRequestObject.target.sftp.credentials = getCredentialsByType('sftp', selectedSftpTarget, true, ["serverName"]);
            }
            else {
                syncRequestObject.target.sftp.credentials = getCredentialsByType('sftp', selectedSftp, false, ["serverName"]);
            }
            break;
        }
        case 'azure': {
            const azureProvider= wizardInfo.target.provider === 'azure' ? 'azure' : 'azureDataLakeGen2';

            if (isTargetOfSameSource) {
                const connectionStringTarget = getCredentialsByType(azureProvider, selectedStorageAccountNameTarget, true)?.connectionString;
                syncRequestObject.target.azure.credentials = {storageAccountConnectionString: connectionStringTarget};
            } else {
                const connectionString = getCredentialsByType(azureProvider, selectedStorageAccountName, false)?.connectionString;
                syncRequestObject.target.azure.credentials = {storageAccountConnectionString: connectionString};
            }
            break;
        }
        default: {
            //do nothing;
        }
    }
    return syncRequestObject;
};

export const buildS3BasedCacheKey = (host, isTargetOfSameSrc) => {
    const osCredentials = getCredentialsByType("objectStorage", host, isTargetOfSameSrc);
    return osCredentials ? `${host}-${osCredentials.accessKey}` : host;
}
export const getWizardSelectedParams = (props, wizardState) => {
    const {_dataBrokersGroups, _showExports, _showShares, _showBoxFolders, _showGoogleDrives, _showSftpDirectories, _showBuckets, _showS3BasedBuckets, _showAzureBlobContainers, _showGCPBuckets, _showAzureDataLakeContainers, _wizard: {wizardInfo, isSecure, dataSense}} = props;
    const {
        nfsServer, nfsServerTarget, nfsVersion, targetNfsVersion, cifsServer, cifsServerTarget, s3Based, s3BasedTarget, dataBrokerGroupId, dataBrokerGroupIdTarget, isTargetDataBrokerInitiator,
        s3BasedBucketPath, s3BasedBucketPathTarget, bucketPath, storageClass, encryptionType, bucketPathTarget, exportPath, rootExport, exportPathTarget, rootExportTarget, gcpBucketPath, gcpBucketPathTarget,
        sharePath, sharePathTarget, rootShareTarget, rootShare, cifsVersion, targetCifsVersion, relationshipTags,
        storageAccountName, storageAccountNameTarget, azureBlobContainerPath, azureBlobContainerPathTarget,
        azureDataLakeContainerPath, azureDataLakeContainerPathTarget,
        blobTier, blobTierTarget, dataLakeTier, dataLakeTierTarget, gcpStorageClass, gcpStorageClassTarget,
        anfNfsWe, anfCifsWe, anfNfsWeTarget, anfCifsWeTarget,cvoNfsWe, cvoCifsWe, cvoNfsWeTarget, cvoCifsWeTarget,
        onpremNfsWe, onpremCifsWe, onpremNfsWeTarget, onpremCifsWeTarget,
        fsxNfsWe, fsxCifsWe, fsxNfsWeTarget, fsxCifsWeTarget,
        nfsVol, cifsVol, nfsVolTarget, cifsVolTarget, efsAccessPoint, efsAccessPointTarget, boxAppName, boxAppNameTarget,
        boxFolder, boxFolderTarget, rootBoxFolder, rootBoxFolderTarget,
        google, googleTarget, googleFolder, googleFolderTarget, googleDrive, googleDriveTarget,
        sftp, sftpTarget, rootSftpDirectory, rootSftpDirectoryTarget, sftpDirectory, sftpDirectoryTarget
    } = wizardState;

    const getSelectedParamByName = (selectedParamName) => {
        return selectedParamName ? _.get(props, `_wizard.selectedWizardParams.${selectedParamName}`) : undefined;
    };

    const getServer = (server, selectedParamName) => {
        return server || getSelectedParamByName(selectedParamName);
    };

    const decodePathAndRootPath = (storagePart, server, path, rootPath, selectedPathName = null, selectedRootPathName = null) => {
        let result = {path: undefined, rootPath: undefined};
        const storagePartForServer = storagePart ? storagePart[server] : null;
        if (storagePartForServer && storagePartForServer.succeeded && storagePartForServer.data) {
            result.path = path || getSelectedParamByName(selectedPathName);
            result.rootPath = rootPath || getSelectedParamByName(selectedRootPathName);
        }
        return result;
    };

    const decodeBucketAndBucketPath = (storagePart, bucketPath) => {
        const bucket = bucketPath && storagePart && storagePart.succeeded && storagePart.data ? storagePart.data.find(bucket => bucket.name === bucketPath.split('/')[0]) : null;
        return {bucket, bucketPath: bucket ? bucketPath : null};
    };

    const getSelectedParamsByProtocol = (protocol, provider, isTargetOfSameSource, isSource) => {
        const integratedProvider = isIntegratedCmProvider(provider);
        switch (protocol) {
            case 'nfs': {
                const isUsingDataBrokerTarget = isSecure && isTargetOfSameSource;
                const actualDataBrokerGroupId = isUsingDataBrokerTarget ? dataBrokerGroupIdTarget : dataBrokerGroupId;
                const decodedPathAndRootPath = isTargetOfSameSource ? decodePathAndRootPath(_showExports, `${nfsServerTarget}-${actualDataBrokerGroupId}`, exportPathTarget, rootExportTarget, 'selectedExportTarget', 'selectedRootExportTarget') :
                    decodePathAndRootPath(_showExports, `${nfsServer}-${actualDataBrokerGroupId}`, exportPath, rootExport, 'selectedExport', 'selectedRootExport');
                return isTargetOfSameSource ? {
                    selectedNFSTarget: getServer(nfsServerTarget, null),
                    selectedExportTarget: decodedPathAndRootPath.path || decodedPathAndRootPath.rootPath,
                    selectedRootExportTarget: integratedProvider ? rootExportTarget : decodedPathAndRootPath.rootPath, //no need to wait for showExports to define root export. defined in volume step
                    selectedAnfNfsWeTarget: anfNfsWeTarget,
                    selectedCvoNfsWeTarget: cvoNfsWeTarget,
                    selectedOnpremNfsWeTarget: onpremNfsWeTarget,
                    selectedFsxNfsWeTarget: fsxNfsWeTarget,
                    selectedNfsVolTarget: nfsVolTarget,
                    targetNfsVersion,
                    selectedEfsAccessPointTarget: efsAccessPointTarget === "NA" ? undefined : efsAccessPointTarget
                } : {
                    selectedNFS: getServer(nfsServer, 'selectedNFS') || (isSource ? dataSense?.host : null),
                    selectedExport: decodedPathAndRootPath.path || (isSource ? dataSense?.source : null),
                    selectedRootExport: (integratedProvider ? rootExport : decodedPathAndRootPath.rootPath)|| (isSource ? dataSense?.source : null), //no need to wait for showExports to define root export. defined in volume step
                    selectedAnfNfsWe: anfNfsWe || (isSource ? dataSense?.weName : null),
                    selectedCvoNfsWe: cvoNfsWe  || (isSource ? dataSense?.weName : null),
                    selectedOnpremNfsWe: onpremNfsWe || (isSource ? dataSense?.weName : null),
                    selectedFsxNfsWe: fsxNfsWe || (isSource ? dataSense?.weName : null),
                    selectedNfsVol: nfsVol || (isSource ? dataSense?.volName : null),
                    nfsVersion,
                    selectedEfsAccessPoint: efsAccessPoint === "NA" ? undefined : efsAccessPoint
                };
            }
            case 's3': {
                if (provider !== 's3') {
                    const relevantDecodedServer = isTargetOfSameSource ? getServer(s3BasedTarget) : getServer(s3Based);
                    const cacheKey = buildS3BasedCacheKey(relevantDecodedServer, isTargetOfSameSource);
                    const decodedBucketAndBucketPath = isTargetOfSameSource
                        ? decodeBucketAndBucketPath(_showS3BasedBuckets && _showS3BasedBuckets[cacheKey], s3BasedBucketPathTarget)
                        : decodeBucketAndBucketPath(_showS3BasedBuckets && _showS3BasedBuckets[cacheKey], s3BasedBucketPath);

                    const result =  isTargetOfSameSource ? {
                        selectedS3BasedBucketTarget: decodedBucketAndBucketPath.bucket,
                        selectedS3BasedBucketPathTarget: decodedBucketAndBucketPath.bucketPath,
                        selectedS3BasedTarget: relevantDecodedServer,
                    } : {
                        selectedS3BasedBucket: decodedBucketAndBucketPath.bucket,
                        selectedS3BasedBucketPath: decodedBucketAndBucketPath.bucketPath || (isSource ? dataSense?.source : null),
                        selectedS3Based: relevantDecodedServer || (isSource ? dataSense?.host : null)
                    }
                    if (provider !== 'ontap') {
                        result.relationshipTags = relationshipTags || undefined;
                    }
                    return result;
                } else {
                    const actualBucketPath = isTargetOfSameSource ? bucketPathTarget : bucketPath;
                    const decodedBucketAndBucketPath = decodeBucketAndBucketPath(_showBuckets && _showBuckets[dataBrokerGroupId], actualBucketPath);
                    return {
                        [isTargetOfSameSource ? "selectedBucketPathTarget": "selectedBucketPath"]: decodedBucketAndBucketPath.bucketPath || (!isTargetOfSameSource ? (isSource ? dataSense?.source : null) : null),
                        relationshipTags: relationshipTags || undefined,
                        selectedStorageClass: storageClass,
                        selectedEncryptionType: encryptionType
                    }
                }
            }
            case 'cifs': {
                const decodedPathAndRootPath = isTargetOfSameSource ? decodePathAndRootPath(_showShares, `${cifsServerTarget}-${dataBrokerGroupId}`, sharePathTarget, rootShareTarget) :
                    decodePathAndRootPath(_showShares, `${cifsServer}-${dataBrokerGroupId}`, sharePath, rootShare);
                return isTargetOfSameSource ? {
                    selectedCIFSTarget: getServer(cifsServerTarget),
                    selectedShareTarget: decodedPathAndRootPath.path || decodedPathAndRootPath.rootPath,
                    selectedRootShareTarget: integratedProvider ? rootShareTarget : decodedPathAndRootPath.rootPath, //no need to wait for showShares to define root export. defined in volume step
                    selectedAnfCifsWeTarget: anfCifsWeTarget,
                    selectedCvoCifsWeTarget: cvoCifsWeTarget,
                    selectedOnpremCifsWeTarget: onpremCifsWeTarget,
                    selectedFsxCifsWeTarget: fsxCifsWeTarget,
                    selectedCifsVolTarget: cifsVolTarget,
                    targetCifsVersion
                } : {
                    selectedCIFS: getServer(cifsServer) || (isSource ? dataSense?.host : null),
                    selectedShare: decodedPathAndRootPath.path || (isSource ? dataSense?.source : null),
                    selectedRootShare: (integratedProvider ? rootShare : decodedPathAndRootPath.rootPath)|| (isSource ? dataSense?.source : null), //no need to wait for showExports to define root export. defined in volume step
                    selectedAnfCifsWe: anfCifsWe || (isSource ? dataSense?.weName : null),
                    selectedCvoCifsWe: cvoCifsWe || (isSource ? dataSense?.weName : null),
                    selectedOnpremCifsWe: onpremCifsWe || (isSource ? dataSense?.weName : null),
                    selectedFsxCifsWe: fsxCifsWe || (isSource ? dataSense?.weName : null),
                    selectedCifsVol: cifsVol || (isSource ? dataSense?.volName : null),
                    cifsVersion
                }
            }
            case 'box' : {
                const decodedBoxDirectoryAndRootBoxDirectory = isTargetOfSameSource ? decodePathAndRootPath(_showBoxFolders, `${boxAppNameTarget}`, boxFolderTarget, rootBoxFolderTarget) :
                    decodePathAndRootPath(_showBoxFolders, `${boxAppName}`, boxFolder, rootBoxFolder);
                // console.log(`%c buildSelected decodedBoxPath ${JSON.stringify(decodedBoxDirectoryAndRootBoxDirectory)}`, 'font-weight:bold;color:red')
                return isTargetOfSameSource ? {
                    selectedBoxAppNameTarget : getServer(boxAppNameTarget),
                    selectedBoxDirectoryTarget: decodedBoxDirectoryAndRootBoxDirectory.path || decodedBoxDirectoryAndRootBoxDirectory.rootPath,
                    selectedRootBoxDirectoryTarget: decodedBoxDirectoryAndRootBoxDirectory.rootPath,
                } : {
                    selectedBoxAppName: getServer(boxAppName) || (isSource ? dataSense?.host : null),
                    selectedBoxDirectory: decodedBoxDirectoryAndRootBoxDirectory.path || decodedBoxDirectoryAndRootBoxDirectory.rootPath || (isSource ? dataSense?.source : null),
                    selectedRootBoxDirectory: decodedBoxDirectoryAndRootBoxDirectory.rootPath,
                }

            }
            case 'gdrive' : {
                const decodedGoogleDriveAndGoogleDriveFolder = isTargetOfSameSource ? decodePathAndRootPath(_showGoogleDrives, `${googleTarget}`, googleFolderTarget, googleDriveTarget) :
                    decodePathAndRootPath(_showGoogleDrives, `${google}`, googleFolder, googleDrive);
                return isTargetOfSameSource ? {
                    selectedGoogleTarget : getServer(googleTarget),
                    selectedGoogleDriveFolderTarget: decodedGoogleDriveAndGoogleDriveFolder.path || decodedGoogleDriveAndGoogleDriveFolder.rootPath,
                    selectedGoogleDriveTarget: decodedGoogleDriveAndGoogleDriveFolder.rootPath,
                    relationshipTags: relationshipTags || undefined,
                } : {
                    selectedGoogle: getServer(google),
                    selectedGoogleDriveFolder: decodedGoogleDriveAndGoogleDriveFolder.path || decodedGoogleDriveAndGoogleDriveFolder.rootPath,
                    selectedGoogleDrive: decodedGoogleDriveAndGoogleDriveFolder.rootPath,
                    relationshipTags: relationshipTags || undefined,
                }

            }
            case 'sftp' : {
                const decodedSftpDirectoryAndRootSftpDirectory = isTargetOfSameSource ? decodePathAndRootPath(_showSftpDirectories, `${sftpTarget}`, sftpDirectoryTarget, rootSftpDirectoryTarget) :
                    decodePathAndRootPath(_showSftpDirectories, `${sftp}`, sftpDirectory, rootSftpDirectory);
                // console.log(`%c buildSelected decodedBoxPath ${JSON.stringify(decodedBoxDirectoryAndRootBoxDirectory)}`, 'font-weight:bold;color:red')
                return isTargetOfSameSource ? {
                    selectedSftpTarget : getServer(sftpTarget),
                    selectedSftpDirectoryTarget: decodedSftpDirectoryAndRootSftpDirectory.path || decodedSftpDirectoryAndRootSftpDirectory.rootPath,
                    selectedRootSftpDirectoryTarget: decodedSftpDirectoryAndRootSftpDirectory.rootPath,
                } : {
                    selectedSftp: getServer(sftp) || (isSource ? dataSense?.host : null),
                    selectedSftpDirectory: decodedSftpDirectoryAndRootSftpDirectory.path || decodedSftpDirectoryAndRootSftpDirectory.rootPath || (isSource ? dataSense?.source : null),
                    selectedRootSftpDirectory: decodedSftpDirectoryAndRootSftpDirectory.rootPath,
                }

            }
            case 'gcp' : {
                const actualBucketPath = isTargetOfSameSource ? gcpBucketPathTarget : gcpBucketPath;
                const decodedBucketAndBucketPath = decodeBucketAndBucketPath(_showGCPBuckets && _showGCPBuckets[dataBrokerGroupId], actualBucketPath);
                return isTargetOfSameSource ? {
                    selectedGCPBucketTarget: decodedBucketAndBucketPath.bucket,
                    selectedGCPBucketPathTarget: decodedBucketAndBucketPath.bucketPath,
                    selectedGcpStorageClass: gcpStorageClassTarget,
                    relationshipTags: relationshipTags || undefined,
                } : {
                    selectedGCPBucket: decodedBucketAndBucketPath.bucket,
                    selectedGCPBucketPath: decodedBucketAndBucketPath.bucketPath|| (isSource ? dataSense?.source : null),
                    selectedGcpStorageClass: gcpStorageClass,
                    relationshipTags: relationshipTags || undefined,
                }
            }
            case 'azure' : {
                if (provider === 'azure'){
                    const actualStoragePart = isTargetOfSameSource
                        ? _showAzureBlobContainers && _showAzureBlobContainers[storageAccountNameTarget]
                        : _showAzureBlobContainers && _showAzureBlobContainers[storageAccountName];
                    const decodedBucketAndBucketPath = isTargetOfSameSource
                        ? decodeBucketAndBucketPath(actualStoragePart, azureBlobContainerPathTarget)
                        : decodeBucketAndBucketPath(actualStoragePart, azureBlobContainerPath);
                    return isTargetOfSameSource ? {
                        relationshipTags: relationshipTags || undefined,
                        selectedStorageAccountNameTarget: storageAccountNameTarget,
                        selectedAzureBlobContainerPathTarget: decodedBucketAndBucketPath.bucketPath,
                        selectedBlobTier: blobTierTarget

                    } : {
                        relationshipTags: relationshipTags || undefined,
                        selectedStorageAccountName: storageAccountName|| (isSource ? dataSense?.host : null),
                        selectedAzureBlobContainerPath: decodedBucketAndBucketPath.bucketPath || (isSource ? dataSense?.source : null),
                        selectedBlobTier: blobTier
                    }

                } else {
                    const actualStoragePart = isTargetOfSameSource
                        ? _showAzureDataLakeContainers && _showAzureDataLakeContainers[storageAccountNameTarget]
                        : _showAzureDataLakeContainers && _showAzureDataLakeContainers[storageAccountName];
                    const decodedBucketAndBucketPath = isTargetOfSameSource
                        ? decodeBucketAndBucketPath(actualStoragePart, azureDataLakeContainerPathTarget)
                        : decodeBucketAndBucketPath(actualStoragePart, azureDataLakeContainerPath);
                    return isTargetOfSameSource ? {
                        relationshipTags: relationshipTags || undefined,
                        selectedStorageAccountNameTarget: storageAccountNameTarget,
                        selectedAzureDataLakeContainerPathTarget: decodedBucketAndBucketPath.bucketPath,
                        selectedDataLakeTier: dataLakeTierTarget

                    } : {
                        relationshipTags: relationshipTags || undefined,
                        selectedStorageAccountName: storageAccountName|| (isSource ? dataSense?.host : null),
                        selectedAzureDataLakeContainerPath: decodedBucketAndBucketPath.bucketPath || (isSource ? dataSense?.source : null),
                        selectedDataLakeTier: dataLakeTier
                    }

                }
                }

            default: {
                return {};
            }
        }
    };

    const getSpecificSelectedWizardParams = (wizardInfo) => {
        const isTargetOfSameSource = wizardInfo.target.protocol === wizardInfo.source.protocol;
        return Object.assign({}, getSelectedParamsByProtocol(wizardInfo.source.protocol, wizardInfo.source.provider, false, true), getSelectedParamsByProtocol(wizardInfo.target.protocol, wizardInfo.target.provider, isTargetOfSameSource, false));
    };

    const selectedDataBrokerGroup = _.find(_dataBrokersGroups?.data, {id: dataBrokerGroupId});
    const selectedDataBrokerGroupTarget = _.find(_dataBrokersGroups?.data, {id: dataBrokerGroupIdTarget});
    const selectedIsTargetDataBrokerInitiator = isTargetDataBrokerInitiator ? isTargetDataBrokerInitiator : 'false';

    const wizardSelectedParams = Object.assign({}, {
        selectedDataBroker: selectedDataBrokerGroup,
        selectedDataBrokerTarget: selectedDataBrokerGroupTarget,
        selectedIsTargetDataBrokerInitiator
    }, getSpecificSelectedWizardParams(wizardInfo));
    return wizardSelectedParams;
};

export const isSecureAllowed = (source, target) => {
    if (!source || !target) return false;
    const isNfsNfs = source.protocol === 'nfs' &&  source.provider === 'nfs' &&
        target.protocol === 'nfs' &&  target.provider === 'nfs';
    const isAnfAnf = source.protocol === 'nfs' &&  source.provider === 'anf' &&
        target.protocol === 'nfs' &&  target.provider === 'anf';
    return isNfsNfs || isAnfAnf;
};

export const buildCredentialsObject = (values, from, protocol) => {
    const clearedEmpty = _.omitBy(values, _.isEmpty);
    return {
        [from.toLowerCase()]: {
            [protocol]: clearedEmpty
        }
    }
}

export const getNfsVersionList = (serverType, wasAclSelected) => {
    let initialList;
    switch (serverType) {
        case "anf": {
            initialList = NFS_ANF_VERSIONS;
            break;
        }
        case "cvs": {
            initialList = NFS_CVS_VERSIONS;
            break;
        }
        case "nfs":
        case "cvo":
        case 'onprem':
        case "fsx":
        default: {
            initialList = NFS_VERSIONS;
        }
    }
    return initialList.map(item => {
        const disabled = wasAclSelected && !isAclSupportedNfsVersion(item.value);
        return {
            ...item,
            disabled,
            disabledTitle: disabled ? ACL_DISABLED_MESSAGE : ""
        }
    });
};
export const getKeyNameByProviderAndProtocol = (provider, protocol) => {
    switch (protocol) {
        case 'cifs' :
            return 'selectedCIFS';
        case 'nfs' :
            return 'selectedNFS'
        case 'box' :
            return 'selectedBoxAppName';
        case 'gdrive' :
            return 'selectedGoogle';
        case 's3': {
            if (provider === 's3') return 'selectedBucketPath';
            else return 'selectedS3Based'
        }
        case 'sftp' :
            return 'selectedSftp';
        case 'azure':
            return 'selectedStorageAccountName';
        case 'gcp':
            return 'selectedGCPBucketPath'
        default:
            return '';
    }
}
export const getTypeByProviderAndProtocol = (provider, protocol) => {
    if (['ibm', 'sgws', 'ontap'].includes(provider)) {
        return 'objectStorage';
    }
    if (provider === 'azure_data_lake') {
        return 'azureDataLakeGen2';
    }
    return protocol;
}
export const getFilterOptions = (list, propName) => {
    const options = _.uniq(_.map(list, elem => _.get(elem, propName)));
    return _.map(options, option => ({
        name: option,
        checked: true
    }));
};
