import {
    END_POINT_NAMES_MAP,
    HOST_IPV4_IPV6_REG_EXP,
    HOST_NAME_REG_EXP,
    IP_REG_EXP,
    KEYS_REG_EXP
} from "../../../../../consts";
import {toUpper} from 'lodash';
import Validator from 'validatorjs';
import url from "is-url";



const rules = (providerUpper, fromDataSense) => {
    const portNotRequired = providerUpper === "ONTAP";
    const portRules = ['required', 'numeric', 'between:1,65535'];
    if (portNotRequired) {
        portRules.shift();
    }
    return{
        serverName: fromDataSense ? '' : ['required', 'max:256'],
        port: portRules,
        accessKey: ['required', 'regex:'+KEYS_REG_EXP],
        secretKey: ['required', 'regex:'+KEYS_REG_EXP]
    };
};

export const validate = (values, props, fromDataSense) => {
    const {serverName} = values;
    const {endpointObj} = props;
    let provider = endpointObj?.provider;
    if (fromDataSense) {
        provider = props._wizard.dataSense.provider;
    }
    const providerUpper = toUpper(provider);
    const serverType = END_POINT_NAMES_MAP[providerUpper];
    const validator = new Validator(values, rules(providerUpper, fromDataSense), {
        "max.serverName": `${serverType} hostname or IP address must be less than 256 characters`,
        "between.port": "Port must be between 1 to 65535"});
    validator.setAttributeNames({serverName : serverType, accessKey: "Access Key", secretKey: 'Secret Key' });
    validator.passes();
    const errors = validator.errors.all();
    if (serverName && !IP_REG_EXP.test(serverName) && !HOST_NAME_REG_EXP.test(serverName) && !HOST_IPV4_IPV6_REG_EXP.test(serverName) && !url(serverName)) {
        errors.serverName = errors.serverName ||[];
        errors.serverName.push("Invalid hostname or IP address");
    }
    return errors;
};

