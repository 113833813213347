import React from 'react';
import {NEW_TARGET_FOLDER_ID, TARGET_FOLDER_NOTICE, TARGET_DIRECTORY_NOTICE} from "../../../consts";
import * as _ from "lodash";
import Notice from '../../notifications/Notice';

const TargetSelectionNotice = ({selected, directory = false}) => {
    if(selected && _.includes(selected, NEW_TARGET_FOLDER_ID)) return <div/>;
    else return (
        <Notice>{directory? TARGET_DIRECTORY_NOTICE : TARGET_FOLDER_NOTICE}</Notice>
    )
};

export default TargetSelectionNotice;
