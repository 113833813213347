import React, {useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import {MISSING_CM_WE_NOTIFICATION} from "../../../consts";
import {isSecureAllowed, isSourceTargetSupported} from "../../../utils/sync-utils";
import {useDialog} from "@netapp/shared-components";
import HowDoesItWorkDialog from '../../systemFlow/HowDoesItWorkDialog';
import {removeSettingsFromSessionStorage} from '../../../utils/settings-utils';
import useRunOnce from '../../../hooks/useRunOnce';
import {
    getCMWorkingEnvironments,
    getCvsWorkingEnvironments,
    getFSXWorkingEnvironments
} from "../../../store/wizard/action_creators";
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import useRunOnceWhenTruthy from '../../../hooks/useRunOnceWhenTruthy';
import {getAnfWorkingEnvironments} from "../../../utils/data-utils";
import ButtonsPanel from '../../widgets/ButtonsPanel';
import {ReactComponent as ArrowIconSmall} from "../../../assets/svgs/arrow-down-small.svg";
import {ReactComponent as LockIcon} from "../../../assets/svgs/lock.svg";
import {getFromRightPanelValue, isLaptopScreen} from '../../../utils/cm-integration-utils';

import "./dragSourceAndTargetStepNew.scss";
import DraggableEndPointNew from "./DraggableEndPointNew";
import DropEndPointNew from "./DropEndPointNew";
import MainLoader from "../../_common_/MainLoader/MainLoader";
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';
import { useNavigate } from 'react-router-dom';

const titlesToEndPoint = {
    "On Prem ONTAP": {
        icon: require('../../../assets/svgs/fs-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/fs-dnd-small-disabled.svg'),
        protocolSelectorIcon: require('../../../assets/svgs/fs-dnd-small.svg'),
        title: "On Prem ONTAP", group: "fileSystem", provider: 'onprem', protocolOptions: ["nfs", "cifs"]
    },
    "Cloud Volumes ONTAP": {
        icon: require('../../../assets/svgs/cvo-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/cvo-dnd-small-disabled.svg'),
        protocolSelectorIcon: require('../../../assets/svgs/cvo-dnd-small.svg'),
        title: "Cloud Volumes ONTAP", provider: "cvo", group: "fileSystem", protocolOptions: ["nfs", "cifs"]
    },
    "Cloud Volumes Service": {
        icon: require('../../../assets/svgs/cvs-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/cvs-dnd-small-disabled.svg'),
        protocolSelectorIcon: require('../../../assets/svgs/cvs-dnd-small.svg'),
        title: "Cloud Volumes Service", provider: "cvs", group: "fileSystem", protocolOptions: ["nfs", "cifs"]
    },
    "Azure NetApp Files": {
        icon: require('../../../assets/svgs/anf-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/anf-dnd-small-disabled.svg'),
        protocolSelectorIcon: require('../../../assets/svgs/anf-dnd-small.svg'),
        title: "Azure NetApp Files", provider: "anf", group: "fileSystem", protocolOptions: ["nfs", "cifs"]
    },
    "FSx for ONTAP": {
        icon: require('../../../assets/svgs/fsx-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/fsx-dnd-small-disabled.svg'),
        protocolSelectorIcon: require('../../../assets/svgs/fsx-dnd-small.svg'),
        title: "FSx for ONTAP", provider: "fsx", group: "fileSystem", protocolOptions: ["nfs", "cifs"]
    },
    "SMB Server": {
        icon: require('../../../assets/svgs/fs-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/fs-dnd-small-disabled.svg'),
        title: "SMB Server", protocol: "cifs", provider: "cifs", group: "fileSystem", firstRoute: "cifs"
    },
    "NFS Server": {
        icon: require('../../../assets/svgs/fs-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/fs-dnd-small-disabled.svg'),
        title: "NFS Server", protocol: "nfs", provider: "nfs", group: "fileSystem", firstRoute: "nfs"
    },
    "AWS EFS": {
        icon: require('../../../assets/svgs/fs-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/fs-dnd-small-disabled.svg'),
        title: "AWS EFS", protocol: "nfs", provider: "efs", group: "fileSystem", firstRoute: "nfs"
    },
    "AWS S3": {
        icon: require('../../../assets/svgs/s3-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/s3-dnd-small-disabled.svg'),
        title: "AWS S3", protocol: "s3", provider: "s3", group: "storage", firstRoute: "agents"
    },
    "StorageGRID": {
        icon: require('../../../assets/svgs/sgws-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/sgws-dnd-small-disabled.svg'),
        title: "StorageGRID", protocol: "s3", provider: "sgws", group: "storage", firstRoute: "s3based"
    },
    "Google Storage": {
        icon: require('../../../assets/svgs/gcp-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/gcp-dnd-small-disabled.svg'),
        title: "Google Storage", protocol: "gcp", provider: "gcp", group: "storage", firstRoute: "agents"
    },
    "IBM Storage": {
        icon: require('../../../assets/svgs/ibm-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/ibm-dnd-small-disabled.svg'),
        title: "IBM Storage", protocol: "s3", provider: "ibm", group: "storage", firstRoute: "s3based"
    },
    "ONTAP S3 Storage": {
        icon: require('../../../assets/svgs/ontap-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/ontap-dnd-small-disabled.svg'),
        title: "ONTAP S3 Storage", protocol: "s3", provider: "ontap", group: "storage", firstRoute: "s3based"
    },
    "Azure Blob": {
        icon: require('../../../assets/svgs/azure-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/azure-dnd-small-disabled.svg'),
        title: "Azure Blob", protocol: "azure", provider: "azure", group: "storage", firstRoute: "azure"
    },
    "Azure Data Lake Gen2":{
        icon: require('../../../assets/svgs/azure_data_lake-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/azure-dnd-small-disabled.svg'),
        title: "Azure Data Lake Gen2", protocol: "azure", provider: "azure_data_lake", group: "storage", firstRoute: "azure"
    },
    "BOX": {
        icon: require('../../../assets/svgs/box-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/box-dnd-small-disabled.svg'),
        title: "BOX", protocol: "box", provider: "box", group: "storage", firstRoute: "box"
    },
    "Google Drive": {
        icon: require('../../../assets/svgs/gdrive-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/gdrive-dnd-small-disabled.svg'),
        title: "Google Drive", protocol: "gdrive", provider: "gdrive", group: "storage", firstRoute: "gdrive"
    },
    "SFTP": {
        icon: require('../../../assets/svgs/fs-dnd-small.svg'),
        disabledIcon: require('../../../assets/svgs/fs-dnd-small-disabled.svg'),
        title: "SFTP", protocol: "sftp", provider: "sftp", group: "fileSystem", firstRoute: "sftp"
    },
};

const groups = ["NetApp", "File System", "Cloud"];

const endPointsInGroups = {
    [groups[0]]: [
        titlesToEndPoint["On Prem ONTAP"],
        titlesToEndPoint["Cloud Volumes ONTAP"],
        titlesToEndPoint["Azure NetApp Files"],
        titlesToEndPoint["Cloud Volumes Service"]
    ],
    [groups[1]]: [
        titlesToEndPoint["NFS Server"],
        titlesToEndPoint["SMB Server"],
        titlesToEndPoint["AWS EFS"],
        titlesToEndPoint["FSx for ONTAP"],
        titlesToEndPoint["SFTP"],
    ],
    [groups[2]]: [
        titlesToEndPoint["AWS S3"],
        titlesToEndPoint["StorageGRID"],
        titlesToEndPoint["IBM Storage"],
        titlesToEndPoint["ONTAP S3 Storage"],
        titlesToEndPoint["Google Storage"],
        titlesToEndPoint["Azure Blob"],
        titlesToEndPoint["BOX"],
        titlesToEndPoint["Azure Data Lake Gen2"],
        titlesToEndPoint["Google Drive"],
    ]
}

const cmIntegrationTypesMap = {
    "ANF": "Azure NetApp Files",
    "CVO": "Cloud Volumes ONTAP",
    "CVS": "Cloud Volumes Service",
    "ONPREM": "On Prem ONTAP",
    "FSX": "FSx for ONTAP",
    "S3": "AWS S3",
    "AZURE": "Azure Blob",
    "GCP": "Google Storage",
    "SGWS": "StorageGRID"
};

const dataSenseIntegrationTypesMap = {
    ...cmIntegrationTypesMap,
    "NFS": "NFS Server",
    "CIFS": "SMB Server",
    "EFS": "AWS EFS",
    "SFTP" : "SFTP",
    "SGWS" : "StorageGRID",
    "IBM" : "IBM Storage",
    "ONTAP" : " ONTAP S3 Storage",
    "GCP" : "Google Storage",
    "AZURE" : "Azure Blob",
    "BOX" : "BOX",
    "AZURE_DATA_LAKE": "Azure Data Lake Gen2"
}

const DragSourceAndTargetStepCompNew = ({
                                            history, getCvsWorkingEnvironments, getCMWorkingEnvironments, getFSXWorkingEnvironments,
                                            accountInfo, anfWorkingEnvironments, cmWorkingEnvironments, fsxWorkingEnvironments,
                                            isFetchingCVSWorkingEnvironments, isFetchingCMWorkingEnvironments, isFetchingFSXWorkingEnvironments, _cmIntegration,
                                            addNotification, clearNotifications
                                        }) => {
    const [source, setSource] = useState(null);
    const [target, setTarget] = useState(null);
    const [isSecure, setIsSecure] = useState(false);
    const [fromRightPanel, setFromRightPanel] = useState(null);

    const {setDialog} = useDialog();
    const navigate = useNavigate();
    const {dataSense} = _cmIntegration;

    //For case user was in the middle of wizard and used browser's "back"
    useRunOnce(() => removeSettingsFromSessionStorage());

    //initialize cmIntegration inputs, in case of coming from right panel
    useRunOnceWhenTruthy(() => {

        const {inputDetails: {
            source:{sourceWorkingEnvironmentName, sourceWorkingEnvironmentType,
                sourceWorkingEnvironmentId}, target:{targetWorkingEnvironmentType,
                targetWorkingEnvironmentId, targetWorkingEnvironmentName} },
            general: {routingParams: {viewDashboard, fromCanvas}}} = _cmIntegration;
        if ((!fromCanvas && viewDashboard) || (!sourceWorkingEnvironmentName && !targetWorkingEnvironmentType)) return;
        const hasSourceWorkingEnvironment = sourceWorkingEnvironmentName && sourceWorkingEnvironmentType && sourceWorkingEnvironmentId;
        const hasTargetWorkingEnvironment = targetWorkingEnvironmentType && targetWorkingEnvironmentId && targetWorkingEnvironmentName;
        if (hasSourceWorkingEnvironment) {
            const sourceType = cmIntegrationTypesMap[sourceWorkingEnvironmentType];
            const sourceEndpoint = {...titlesToEndPoint[sourceType], workingEnvironmentName:sourceWorkingEnvironmentName};
            setSource(sourceEndpoint);
        }
        if (hasTargetWorkingEnvironment){
            const targetType = cmIntegrationTypesMap[targetWorkingEnvironmentType];
            const targetEndpoint = {...titlesToEndPoint[targetType], workingEnvironmentName:targetWorkingEnvironmentName};
            setTarget(targetEndpoint);
        }
        const fromRightPanelValue = getFromRightPanelValue(hasSourceWorkingEnvironment, hasTargetWorkingEnvironment);
        setFromRightPanel(fromRightPanelValue);
    }, _cmIntegration?.inputDetails);

    //initialize dataSenseIntegration
    useRunOnceWhenTruthy(() => {
        const {protocol, provider} = dataSense;
        const type = dataSenseIntegrationTypesMap[provider.toUpperCase()]
        const endpoint = {...titlesToEndPoint[type], protocol, dataSense};
        setSource(endpoint);
        setFromRightPanel('source')
    }, dataSense);

    useEffect(() => {
            getCvsWorkingEnvironments(accountInfo.data.accountId, _cmIntegration?.general?.workspaceId);
    }, [accountInfo?.data?.accountId, _cmIntegration?.general?.workspaceId, getCvsWorkingEnvironments]);

    useEffect(() => {
            const workspaceId = _cmIntegration?.general?.workspaceId;
            getCMWorkingEnvironments(workspaceId);
            getFSXWorkingEnvironments(workspaceId);
    }, [_cmIntegration?.general?.workspaceId, getCMWorkingEnvironments, getFSXWorkingEnvironments]);

    const createNotification = messege => {
        addNotification({
            id: NOTIFICATION_CONSTS.UNIQUE_IDS.DRAG_SOURCE_ERROR_NOTIFICATION,
            type: NOTIFICATION_CONSTS.TYPE.ERROR,
            children: messege
        });
    };

    const getMissingWEError = (firstEndpoint, secondEndpoint) => {
        if (isFetchingCVSWorkingEnvironments || isFetchingCMWorkingEnvironments || isFetchingFSXWorkingEnvironments) return null;

        if ((firstEndpoint?.provider === "anf" || secondEndpoint?.provider === "anf") && anfWorkingEnvironments.length === 0) {
            return MISSING_CM_WE_NOTIFICATION["ANF"];
        }
        if ((firstEndpoint?.provider === "cvo" || secondEndpoint?.provider === "cvo") && cmWorkingEnvironments) {
            if (cmWorkingEnvironments.noWorkspaceId) {
                return MISSING_CM_WE_NOTIFICATION["NO-WORKSPACE"];
            }
            if (cmWorkingEnvironments.noAgent) {
                return MISSING_CM_WE_NOTIFICATION["NO-AGENT"];
            }
            if (cmWorkingEnvironments.azureVsaWorkingEnvironments.length === 0 && cmWorkingEnvironments.gcpVsaWorkingEnvironments.length === 0 &&
                cmWorkingEnvironments.vsaWorkingEnvironments.length === 0) {
                return MISSING_CM_WE_NOTIFICATION["CVO"];
            }

        }
        if ((firstEndpoint?.provider === 'fsx' || secondEndpoint?.provider === 'fsx') && fsxWorkingEnvironments) {
            if (fsxWorkingEnvironments?.noWorkspaceId) {
                return MISSING_CM_WE_NOTIFICATION["NO-WORKSPACE"];
            }
            if (fsxWorkingEnvironments?.noAgent) {
                return MISSING_CM_WE_NOTIFICATION["NO-AGENT"];
            }
            if (fsxWorkingEnvironments.length === 0) {
                return MISSING_CM_WE_NOTIFICATION["FSX"];
            }
        }
        if ((firstEndpoint?.provider === "onprem" || secondEndpoint?.provider === "onprem") && cmWorkingEnvironments) {
            if (cmWorkingEnvironments.noWorkspaceId) {
                return MISSING_CM_WE_NOTIFICATION["NO-WORKSPACE"];
            }
            if (cmWorkingEnvironments.noAgent) {
                return MISSING_CM_WE_NOTIFICATION["NO-AGENT"];
            }
            if (cmWorkingEnvironments.onPremWorkingEnvironments.length === 0) {
                return MISSING_CM_WE_NOTIFICATION["ONPREM"];
            }
        }
        return null;
    };

    const handleDrop = (draggableEndPoint, dropEndPoint) => {
        if (isFetchingCVSWorkingEnvironments || isFetchingCMWorkingEnvironments || isFetchingFSXWorkingEnvironments) return;
        clearNotifications();
        const endPoint = titlesToEndPoint[draggableEndPoint];
        const missingWEError = getMissingWEError(endPoint);
        if (missingWEError) {
            createNotification(missingWEError);
            return;
        }

        if (dropEndPoint.includes("Source")) {
            setSource(endPoint);
        }
        if (dropEndPoint.includes("Target")) {
            setTarget(endPoint);
        }
    };


    const handleContinue = () => {
        if (isEmpty(source) || isEmpty(target)) {
            createNotification("You must select both source and target");
            return;
        }

        const missingWEError = getMissingWEError(source, target);
        if (missingWEError) {
            createNotification(missingWEError);
            return;
        }

        if (source.protocolOptions && !source.protocol) {
            createNotification("Please select source protocol in order to continue");
            return;
        }
        if (target.protocolOptions && !target.protocol) {
            createNotification("Please select target protocol in order to continue");
            return;
        }
        const protocols = (source.protocol) + "-" + (target.protocol);
        const sourceProvider = source.provider;
        const targetProvider = target.provider;
        const providers = `${sourceProvider}:${targetProvider}`;
        //This is a safety net - recheck isSourceTargetSupported
        if (isSourceTargetSupported(source, target)) {
            navigate({
                pathname: `/sync-new/${protocols}/${providers}/${isSecure}/start-wizard-new`,
                search: window.location.search
            });
        } else {
            createNotification("Unsupported selection");
        }

    };

    const removeTargetEndPoint = () => {
        setTarget(null);
        clearNotifications();
    };

    const removeSourceEndPoint = () => {
        setSource(null);
        clearNotifications();
    };

    const changeSecureSelection = (index) => {
        setIsSecure(index === 1);
    };

    const isAllowed = (entry) => {
        return source && target
            ? false
            : !source && !target
                ? true
                : isSourceTargetSupported(source || entry, target || entry);
    };

    const showLoaderCondition = (endpoint) => {
        if (endpoint.provider === "anf") {
            return isFetchingCVSWorkingEnvironments;
        }
        if (endpoint.provider === "cvo" || endpoint.provider === "onprem") {
            return isFetchingCMWorkingEnvironments;
        }
        if (endpoint.provider === "fsx") {
            return isFetchingFSXWorkingEnvironments;
        }
    };
    const renderEncryptedCheckbox = isSecureAllowed(source, target) &&
        (!isFetchingCVSWorkingEnvironments &&
            !isFetchingCMWorkingEnvironments &&
            !isFetchingFSXWorkingEnvironments
        );
    const getWizardTypeForSystemFlow = source && target ? `${source.provider}-${target.provider}` : undefined;

    //Added due to CS-5442
    useEffect(() => {
        if (isSecure && !renderEncryptedCheckbox) {
            setIsSecure(false);
        }
    }, [isSecure, renderEncryptedCheckbox]);

    const setEndpointEndClearError = (endPoint, setEndPoint) => {
        setEndPoint(endPoint);
        if (endPoint.protocolOptions && endPoint.protocol) {
            clearNotifications()
        }

    }

    const laptopScreen = isLaptopScreen();
    const stillFetching = isFetchingCMWorkingEnvironments || isFetchingCVSWorkingEnvironments || isFetchingFSXWorkingEnvironments;
    return stillFetching ? <MainLoader/> : (
        <DndProvider backend={HTML5Backend}>
            <div className="select-direction-step">
                <div className="scrollable-area main">
                    <div className={`step-content ${laptopScreen? 'small-screen' : ''}`}>
                        <div className={`left-pane ${renderEncryptedCheckbox ? 'with-encryption' : ''} ${laptopScreen? 'small-screen' : ''}`}>
                            {renderEncryptedCheckbox &&
                            <div className="encrypted-checkbox-wrapper">
                                <div className="title">
                                    <span className="lock-icon"><LockIcon/></span>
                                    <span className="title-text">Data-In-Flight Encryption</span>
                                    <span className="title-right" onClick={(el) => setDialog(<HowDoesItWorkDialog
                                        wizardType={getWizardTypeForSystemFlow} isSecure={true}/>, el.target)}>
                                How Does it Work ?</span>
                                </div>
                                <div className="radio-buttons-line">
                                    <div className="radio-buttons-label">Enable data-in-flight encryption when syncing
                                        data over public networks?
                                    </div>
                                    <div className="radio-buttons-wrapper">
                                        <label className="radio-button">
                                            <input name="secureNfs" type="radio" value="No"
                                                   checked={!isSecure}
                                                   onChange={changeSecureSelection.bind(this, 0)}/> No
                                        </label>
                                        <label className="radio-button">
                                            <input name="secureNfs" type="radio" value="Yes"
                                                   checked={isSecure}
                                                   onChange={changeSecureSelection.bind(this, 1)}/> Yes
                                        </label>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="scrollable-area endpoints">
                                <div
                                    className={`end-points-container ${renderEncryptedCheckbox ? 'with-encryption' : ''}`}>{groups.map((group) => {
                                    return (
                                        <div className="end-point-group" key={group}>
                                            <div className="title">{group}</div>
                                            <div className="end-points-list">
                                                {endPointsInGroups[group].map((entry, index) =>
                                                    <DraggableEndPointNew key={`${entry.title}-${index}`}
                                                                          icon={entry.icon}
                                                                          disabledIcon={entry.disabledIcon}
                                                                          title={entry.title}
                                                                          onDrop={handleDrop}
                                                                          isAllowed={isAllowed(entry)}>
                                                    </DraggableEndPointNew>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                        <div className={`select-container ${laptopScreen? 'small-screen' : ''}`}>
                            <DropEndPointNew title="Drag Source Here"
                                             handleEndPointSelection={removeSourceEndPoint}
                                             endPoint={source}
                                             setEndPoint={(source) => setEndpointEndClearError(source, setSource)}
                                             fromRightPanel={['source', 'both'].includes(fromRightPanel)}
                                             showLoaderCondition={showLoaderCondition}
                                             isDropSource={true}
                            >
                            </DropEndPointNew>

                            <div className={`arrow-down small ${source && source.protocolOptions ? (laptopScreen ? 'laptop' : 'lower') : (laptopScreen ? 'higher' : '')}`}>
                                <ArrowIconSmall/>
                            </div>

                            <DropEndPointNew title="Drag Target Here"
                                             handleEndPointSelection={removeTargetEndPoint}
                                             endPoint={target}
                                             setEndPoint={(target) => setEndpointEndClearError(target, setTarget)}
                                             fromRightPanel={['target', 'both'].includes(fromRightPanel)}
                                             showLoaderCondition={showLoaderCondition}
                                             isDropSource={false}
                            >
                            </DropEndPointNew>

                        </div>

                    </div>

                </div>
                <ButtonsPanel
                    variant="white"
                    type="button"
                    onClick={handleContinue}
                    submitting={isFetchingCVSWorkingEnvironments || isFetchingCMWorkingEnvironments || isFetchingFSXWorkingEnvironments}
                    text="Continue"/>
            </div>

        </DndProvider>
    )
};

const mapStateToProps = state => ({
    _ui: state.global._ui,
    accountInfo: state.global._accountInfo,
    _cmIntegration: state.global._cmIntegration,
    anfWorkingEnvironments: getAnfWorkingEnvironments(state.syncReducer._cvsWorkingEnvironments),
    cmWorkingEnvironments: state.syncReducer._cmWorkingEnvironments?.data, //todo concat all the cvo arrays together
    fsxWorkingEnvironments: state.syncReducer._fsxWorkingEnvironments?.data, //todo concat all the cvo arrays together
    isFetchingCVSWorkingEnvironments: state.syncReducer._cvsWorkingEnvironments?.inProgress,
    isFetchingCMWorkingEnvironments: state.syncReducer._cmWorkingEnvironments?.inProgress,
    isFetchingFSXWorkingEnvironments: state.syncReducer._fsxWorkingEnvironments?.inProgress
});

export default connect(mapStateToProps, {
    getCvsWorkingEnvironments,
    getCMWorkingEnvironments,
    getFSXWorkingEnvironments,
    addNotification,
    clearNotifications
})(DragSourceAndTargetStepCompNew);
