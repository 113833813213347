import {supportAclCopy} from "../../../../utils/relationshipUtils";
import {InputField} from "../../../_common_/forms/forms";
import AclSelection from "../AclSelectionNew";
import {Field} from 'redux-form';
import {Field as FinalField} from 'react-final-form'
import AclSelectionFinalForm from "../AclSelectionFinalFormNew";

const AzureAdditionalSettings = ({_wizard: {protocols, wizardInfo: {source, target}},isSourceStep, isFinalForm}) => {
    const showACL= supportAclCopy(protocols, source, target) && isSourceStep;
    const sasConnectionStringRequired = protocols === 'azure-azure' && isSourceStep;
    return (
        <>
            {!isFinalForm && <div>
                <div className="credentials-container azure">
                    <Field name="connectionString"
                           component={InputField}
                           autoFocus
                           label={sasConnectionStringRequired ? "Define a Storage Account SAS (Shared Access Signature) Connection String" : "Define a Storage Account Connection String:"}
                           type="password"/>
                </div>
                {showACL && <AclSelection/>}
            </div>}
            {isFinalForm && <div>
                <div className="credentials-container azure">
                    <FinalField name="connectionString"
                           component={InputField}
                           autoFocus
                           label={sasConnectionStringRequired ? "Define a Storage Account SAS (Shared Access Signature) Connection String" : "Define a Storage Account Connection String:"}
                           type="password"/>
                </div>
                {showACL && <AclSelectionFinalForm/>}
            </div>}
        </>
    )
};

export default AzureAdditionalSettings;
