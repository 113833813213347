import React from 'react';
import {
    // addNewShare,
    listBoxRootFolders,
    listBoxFolders,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators'
import {connect} from 'react-redux';
import {getDirectoryObjectFromCache} from "../../../utils/encoders"
import {reduxForm} from 'redux-form';
import ShowDirectoriesStepTemplate from "./ShowDirectoriesStepTemplateNew";
import {getPropFromState} from '../../../utils/mapStateToPropsUtils';
import {isIntegratedCmProvider} from "../../../utils/cm-integration-utils";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import useStepinize from "../useStepinize";
// import ManualDirectoryDialog from "../systemFlow/ManualDirectoryDialog";

const innerColumns = (row) => {
    //SER-306 SER- Wrong details in select directory screen - don't show information at all
    return [<td className="path" title={row.path} key="path">{row.path}</td>];
};

const loading = () => {return {text: "Folders", icon: require("../../../assets/svgs/directory-default.svg")}}
const fetchData = (props) => {
    const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams: {
        selectedBoxAppNameTarget, selectedBoxAppName, selectedDataBroker}}} = props;
    const localSelectedBoxAppName = isTargetStepFromSameSourceType ? selectedBoxAppNameTarget : selectedBoxAppName;
    return props.listBoxRootFolders(selectedDataBroker.id, localSelectedBoxAppName);
};

const shouldFetch = (props) => {
    const {
        _showBoxFolders, isTargetStepFromSameSourceType,
        _wizard: {selectedWizardParams: {selectedBoxAppNameTarget, selectedBoxAppName}}
    } = props;
    const encodedBOXAppName = isTargetStepFromSameSourceType ? selectedBoxAppNameTarget : selectedBoxAppName;
    const storageKey = `${encodedBOXAppName}`;
    const showDirectoriesForSpecificClientId = _showBoxFolders[storageKey];
    return !showDirectoriesForSpecificClientId || showDirectoriesForSpecificClientId.failedRetrieve;

};

const isFetching = (props) => {
    const {_showBoxFolders, _wizard: {selectedWizardParams: {selectedBoxAppName, selectedBoxAppNameTarget}}, isTargetStepFromSameSourceType} = props;
    const encodedBOX = isTargetStepFromSameSourceType ? selectedBoxAppNameTarget : selectedBoxAppName;
    const storageKey = `${encodedBOX}`;
    const showDirectoriesForBox = _showBoxFolders[storageKey];
    if (!showDirectoriesForBox) return true;
    return (showDirectoriesForBox.inProgress) || ((!showDirectoriesForBox.data || showDirectoriesForBox.data.length === 0) && !showDirectoriesForBox.retrievedAll);
};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetBoxFolders' : 'boxFolders';
};


const ShowBoxFoldersStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

    const {clearFoldersCache, _showBoxFolders, listBoxFolders,
        _wizard: {selectedWizardParams: {selectedDataBroker, selectedBoxAppName, selectedBoxAppNameTarget,
            selectedBoxDirectory, selectedBoxDirectoryTarget, selectedRootBoxDirectory, selectedRootBoxDirectoryTarget,
            }}} = props;
    const storageKey = isTargetStepFromSameSourceType ? selectedBoxAppNameTarget : selectedBoxAppName;

    const stepOptions = {
        containerOptions: {
            singularTitle: "Folder",
            pluralTitle: "Folders",
            itemIdentifier: "actualPath",
            rootName: "Root Folder",
            targetName: "Define Target Folder",
            directoriesName: "Folders",
            directoryName: "folder",
            icon: require(`../../../assets/svgs/directory-no-circle.svg`)
        },
        failedMessage: "Unable to retrieve folders",
        notFoundMessage: "No folders were found",
        title: `Select a ${isSourceStep ? "Source" : "Target"} Folder`,
        selectedDirectory: selectedBoxDirectory,
        selectedRootDirectory: selectedRootBoxDirectory,
        selectedDirectoryTarget: selectedBoxDirectoryTarget,
        selectedRootDirectoryTarget: selectedRootBoxDirectoryTarget,
        isStaticRoot: false,
        storage: _showBoxFolders,
        storageKey,
        listSubDirectoriesAction: listBoxFolders,
        getListSubDirectoriesActionParams : (path) => {
            const {foundDirectory: directoryObject} = getDirectoryObjectFromCache(_showBoxFolders, storageKey, path);
            return {
                appName: storageKey,
                groupId: selectedDataBroker.id,
                isTargetStepFromSameSourceType,
                folderId: directoryObject.folderId,
                folderName: directoryObject.actualPath
            }
        },
        selectedServer: selectedBoxAppName,
        selectedServerTarget: selectedBoxAppNameTarget,
        stepFormName: "BoxFoldersForm",
        innerColumns,
        refreshStep: isIntegratedCmProvider(endpointObj.provider) ? undefined : () => {
            clearFoldersCache({cacheName:"_showBoxFolders",storageKey});
            fetchData(props)
        },
        getSelectedParams: (fullPath, rootValue) => {
            const exportParam = isTargetStepFromSameSourceType ? 'boxFolderTarget' : 'boxFolder';
            const rootExportParam = isTargetStepFromSameSourceType ? 'rootBoxFolderTarget' : 'rootBoxFolder'
            const selectedParams = {
                [exportParam]: fullPath,
                [rootExportParam]: rootValue
            }
            return selectedParams;
        }

    };


    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ShowDirectoriesStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>
};

const BoxFoldersForm = reduxForm({form: 'BoxFoldersForm'})(ShowBoxFoldersStep);

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showBoxFolders:  getPropFromState(state, "_showBoxFolders", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    listBoxRootFolders,
    listBoxFolders,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxFoldersForm);
