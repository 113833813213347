import React, { useCallback, useState, createContext } from 'react';
import RightPanel from "../components/widgets/RightPanel";

const PanelContext = createContext();

const RightPanelProvider = props => {
    const [panelContent, setPanelContent] = useState(null);
    const closePanel = useCallback(() => {
        setPanelContent(null);
    }, [setPanelContent]);

    return <PanelContext.Provider value={{setPanelContent, closePanel}} {...props} >
        {props.children}
        <RightPanel content={panelContent} closePanel={closePanel}/>
    </PanelContext.Provider>
};

export {PanelContext, RightPanelProvider};
