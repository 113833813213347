import React from 'react';
import {
    // addNewShare,
    listSftpRootDirectories,
    listSftpDirectories,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators'
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import ShowDirectoriesStepTemplate from "./ShowDirectoriesStepTemplateNew";
import {getPropFromState} from '../../../utils/mapStateToPropsUtils';
import {isIntegratedCmProvider} from "../../../utils/cm-integration-utils";
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";

const innerColumns = (row) => {
    //SER-306 SER- Wrong details in select directory screen - don't show information at all
    return [<td className="path" title={row.path} key="path">{row.path}</td>];
};

const loading = () => {return {text: "Directories", icon: require("../../../assets/svgs/directory-default.svg")}}
const fetchData = (props) => {
    const {isTargetStepFromSameSourceType, _wizard: {selectedWizardParams: {
        selectedSftpTarget, selectedSftp, selectedDataBroker}}} = props;
    const localSelectedSftp = isTargetStepFromSameSourceType ? selectedSftpTarget : selectedSftp;
    return props.listSftpRootDirectories(selectedDataBroker.id, localSelectedSftp);
};

const shouldFetch = (props) => {
    const {
        _showSftpDirectories, isTargetStepFromSameSourceType,
        _wizard: {selectedWizardParams: {selectedSftpTarget, selectedSftp}}
    } = props;
    const encodedBOXAppName = isTargetStepFromSameSourceType ? selectedSftpTarget : selectedSftp;
    const storageKey = `${encodedBOXAppName}`;
    const showDirectoriesForSpecificClientId = _showSftpDirectories[storageKey];
    return !showDirectoriesForSpecificClientId || showDirectoriesForSpecificClientId.failedRetrieve;

};

const isFetching = (props) => {
    const {_showSftpDirectories, _wizard: {selectedWizardParams: {selectedSftp, selectedSftpTarget}}, isTargetStepFromSameSourceType} = props;
    const encodedBOX = isTargetStepFromSameSourceType ? selectedSftpTarget : selectedSftp;
    const storageKey = `${encodedBOX}`;
    const showDirectoriesForSftp = _showSftpDirectories[storageKey];
    if (!showDirectoriesForSftp) return true;
    return (showDirectoriesForSftp.inProgress) || ((!showDirectoriesForSftp.data || showDirectoriesForSftp.data.length === 0) && !showDirectoriesForSftp.retrievedAll);
};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetSftpDirectories' : 'sftpDirectories';
};



const ShowSftpDirectoriesStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

    const {clearFoldersCache, _showSftpDirectories, listSftpDirectories,
        _wizard: {selectedWizardParams: {selectedDataBroker, selectedSftp, selectedSftpTarget,
            selectedSftpDirectory, selectedSftpDirectoryTarget, selectedRootSftpDirectory, selectedRootSftpDirectoryTarget,
            }}} = props;
    const storageKey = isTargetStepFromSameSourceType ? selectedSftpTarget : selectedSftp;

    const stepOptions = {
        containerOptions: {
            singularTitle: "Directory",
            pluralTitle: "Directories",
            itemIdentifier: "actualPath",
            rootName: "Root Directory",
            targetName: "Define Target Directory",
            directoriesName: "Directories",
            directoryName: "directory",
            icon: require(`../../../assets/svgs/directory-no-circle.svg`)
        },
        failedMessage: "Unable to retrieve directories",
        notFoundMessage: "No directories were found",
        title: `Select a ${isSourceStep ? "Source" : "Target"} Directory`,
        selectedDirectory: selectedSftpDirectory,
        selectedRootDirectory: selectedRootSftpDirectory,
        selectedDirectoryTarget: selectedSftpDirectoryTarget,
        selectedRootDirectoryTarget: selectedRootSftpDirectoryTarget,
        isStaticRoot: false,
        storage: _showSftpDirectories,
        storageKey,
        listSubDirectoriesAction: listSftpDirectories,
        getListSubDirectoriesActionParams : (path) => {
            return {
                groupId: selectedDataBroker.id,
                isTargetStepFromSameSourceType,
                rootDirectory: '/',
                path,
                host: storageKey
            }
        },
        selectedServer: selectedSftp,
        selectedServerTarget: selectedSftpTarget,
        stepFormName: "SftpDirectoriesForm",
        innerColumns,
        refreshStep: isIntegratedCmProvider(endpointObj.provider) ? undefined : () => {
            clearFoldersCache({cacheName:"_showSftpDirectories",storageKey});
            fetchData(props)
        },
        getSelectedParams: (fullPath, rootValue) => {
            const exportParam = isTargetStepFromSameSourceType ? 'sftpDirectoryTarget' : 'sftpDirectory';
            const rootExportParam = isTargetStepFromSameSourceType ? 'rootSftpDirectoryTarget' : 'rootSftpDirectory'
            const selectedParams = {
                [exportParam]: fullPath,
                [rootExportParam]: rootValue
            }
            return selectedParams;
        }

    };


    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ShowDirectoriesStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>
};

const SftpDirectoriesForm = reduxForm({form: 'SftpDirectoriesForm'})(ShowSftpDirectoriesStep);

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showSftpDirectories:  getPropFromState(state, "_showSftpDirectories", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    listSftpDirectories,
    listSftpRootDirectories,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(SftpDirectoriesForm);
