import React from 'react'
import {ReactComponent as DataSenseInfoIcon} from "../../assets/svgs/info-icon-data-sense.svg";
import {noop} from 'lodash'
import './dataSenseInfo.scss'
import {cmNavigateTo} from "../../utils/cm-integration-utils";

const DataSenseInfo = ({dataSenseQueryUid, allowNavigation, fromHeader}) => {
    return <div className={`data-sense-info ${fromHeader? 'from-header' : 'from-card'}`}
                onClick={() => allowNavigation ? cmNavigateTo('/governance/investigation', '', {
                    service: 'sync',
                    dataSenseQueryUid
                }) : noop()}>
        {fromHeader && <DataSenseInfoIcon className="more-info-icon"/>}
        <div>The source files were selected in Data Sense</div>
        {!fromHeader && <DataSenseInfoIcon className="more-info-icon"/>}
    </div>;
}

export default DataSenseInfo

