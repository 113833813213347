import React, {useEffect, useState} from 'react';
import SyncSettingsForm from './SyncSettingsForm';
import {connect, useDispatch} from "react-redux";
import {getFormValuesFromSettings,} from '../../../utils/settings-utils';
import _ from 'lodash';
import {getFixedProtocol, getSVGByProtocol} from '../../../utils/dashboard-utils';
import {createStringFromEndPointObject} from '../../../utils/helpers';
import {updateRelationshipSettings} from '../../../store/wizard/action_creators';
import ButtonsPanel from '../../widgets/ButtonsPanel';
import {getPropFromState} from '../../../utils/mapStateToPropsUtils';
import MainLoader from '../../_common_/MainLoader/MainLoader';
import {isCloudToCloud, supportAclCopy, supportAclCopyNfs, protocolSupportsTags} from '../../../utils/relationshipUtils';
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';
import { findAclOptionByValues } from '../../../utils/relationshipUtils';
import { useNavigate, useParams } from 'react-router-dom';

const SyncSettingsEdit = (props) => {
    const {updateRelationshipSettings, _relationships} = props;

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const [initialFormValues, setInitialFormValues] = useState(null);
    const [relationship, setRelationship] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    let submitMethod = _.noop;
    const setSubmitMethod = (handleSubmit) => {
        submitMethod = handleSubmit;
    }

    useEffect(()=>{
        if (relationship) return;

        const relationshipId = params.relationshipId;
        const relationshipInStore = _.find(_.get(_relationships, 'data'), relationship => relationship.relationshipId === relationshipId);

        const setAclOptionsOnSettings = (supportAcl, formValues) => {
            if (supportAcl === 'multi') {
                const {copyAcl, copyData, ...rest} = formValues;
    
                return {
                    ...rest,
                    aclOptions: findAclOptionByValues(copyAcl, copyData)
                }
            } else {
                return {
                    ...formValues
                }
            }
        }

        if (relationshipInStore) {
            const sourceProtocol = relationshipInStore.source.protocol;
            const targetProtocol = relationshipInStore.target.protocol;
            const cloudToCloud = isCloudToCloud(relationshipInStore) || sourceProtocol === "box"
                || targetProtocol === "box"; //box should have only mtime like cloud to cloud
            const hasOntapS3 = relationshipInStore.source[sourceProtocol].provider === 'ontap' || relationshipInStore.target[targetProtocol].provider === 'ontap';
            const hasHardLinks = !relationshipInStore.encryption && relationshipInStore.source.protocol === 'nfs' && relationshipInStore.target.protocol === 'nfs';
            const supportAcl = supportAclCopy(`${sourceProtocol}-${targetProtocol}`, relationshipInStore.ui.source[sourceProtocol], relationshipInStore.ui.target[targetProtocol]);
            const supportAclNfs = supportAclCopyNfs(relationshipInStore, sourceProtocol, targetProtocol);
            const formValues = getFormValuesFromSettings(relationshipInStore.settings, true, cloudToCloud, hasOntapS3, hasHardLinks);
            const initialFormValues = setAclOptionsOnSettings(supportAcl || supportAclNfs, formValues);

            setInitialFormValues(initialFormValues);
            setRelationship(relationshipInStore);
        }
    }, [params, _relationships, relationship]);


    const gotoDashboard = () => {
        navigate({
            pathname: '/dashboard',
            search: window.location.search
        })
    };

    const doSubmit = (settings) => {
        clearNotifications()(dispatch);
        return (updateRelationshipSettings(relationship.relationshipId, settings))
            .then(gotoDashboard)
            .catch((err)=>{
                if (err.status !== 401) {
                    addNotification({
                        id: NOTIFICATION_CONSTS.UNIQUE_IDS.SYNC_SETTINGS_EDIT_NOTIFICATION,
                        type: NOTIFICATION_CONSTS.TYPE.ERROR,
                        children: err.data.message
                    })(dispatch);
                }
            })
    };

    if (!relationship) return <MainLoader/>;

    const targetProtocol = relationship.ui.target.protocol;
    const sourceProtocol = relationship.ui.source.protocol;
    const isDataSense = relationship.ui.isDataSense;
    const supportAcl = supportAclCopy(`${sourceProtocol}-${targetProtocol}`, relationship.ui.source[sourceProtocol], relationship.ui.target[targetProtocol]);
    const isAclEligibleCifs = supportAcl && !relationship.ui.source.cifs?.aclServer ? supportAcl : false;
    const isAclEligibleNfs = supportAclCopyNfs(relationship, sourceProtocol, targetProtocol);
    const showAclSection = (isAclEligibleCifs || isAclEligibleNfs);

    const showObjectTaggingSection = protocolSupportsTags(targetProtocol) && relationship.ui.target[targetProtocol].provider !== "ontap";

    const cloudToCloud = isCloudToCloud(relationship);
    const showCopyPropertiesSection = cloudToCloud && relationship.ui.target[targetProtocol].provider !== "ontap" && relationship.ui.source[sourceProtocol].provider !== "ontap";
    const showCreateTimeFilterSection = sourceProtocol === "cifs";
    const isCloudToCloudBool = cloudToCloud || sourceProtocol === "box" || targetProtocol === "box";
    const includeBox = sourceProtocol === "box" || targetProtocol === "box";
    const isS3CompatibleCloudToCloud = relationship.source.protocol === "s3" && relationship.target.protocol=== "s3";
    const hasOntapS3 = relationship.target[targetProtocol].provider === 'ontap' || relationship.source[sourceProtocol].provider  === 'ontap';
    const hasHardLinks = !relationship.encryption && sourceProtocol === 'nfs' && targetProtocol === 'nfs';
    const options = {showAclSection, showObjectTaggingSection, isCloudToCloud: isCloudToCloudBool, isS3CompatibleCloudToCloud, hasOntapS3, hasHardLinks,
        showCopyPropertiesSection, showCreateTimeFilterSection, includeBox, copyAclOptions: initialFormValues.aclOptions}

    return (
        <div className="settings-edit">
            <div className="settings-header">
                <h1>Sync Relationship Settings</h1>
                <p>You can modify settings that define how source files and folders are synced and maintained
                    in the target location</p>

                <div className="relationship-info-container">
                    <EndPointInfo type="source" data={relationship.ui.source} isSecureRelationship={!!relationship.encryption}/>
                    <EndPointInfo type="target" data={relationship.ui.target} isSecureRelationship={!!relationship.encryption}/>
                </div>
            </div>
            <div className="scrollable-area">
                <div className="settings-content">
                    <SyncSettingsForm doSubmit={doSubmit} initialValues={initialFormValues}
                                      setSubmitting={setSubmitting}
                                      setSubmitMethod={setSubmitMethod}
                                      options={options} isEditMode={true}
                                      dataSenseIntegration={isDataSense}/>
                </div>
            </div>
            <ButtonsPanel
                variant="white"
                type="submit"
                submitting={submitting}
                onCancel={gotoDashboard}
                onClick={(event) => submitMethod(event)}
                text="Save Settings"/>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
        _relationships: getPropFromState(state, "_relationships", {}),
        ...ownProps
    }
};
export default connect(mapStateToProps, {updateRelationshipSettings})(SyncSettingsEdit);

const EndPointInfo = (props) => {
    const {type, data, isSecureRelationship} =  props;
    const title = createStringFromEndPointObject(data);
    const svg = getSVGByProtocol(getFixedProtocol(data), isSecureRelationship);

    return (
        <div className="endpoint-info" title={title}>
            {svg} <div className="info"><b>Relationship {type}</b> | {title}</div>
        </div>
    )
};
