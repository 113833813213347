import React, { FocusEvent, useMemo, useState } from "react";
import './encryptionSetting.scss';
import { SelectField, TextField, Typography } from "@netapp/design-system";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { ReactComponent as KeyIcon } from "../../../assets/svgs/key-icon.svg";
import { optionType } from "@netapp/design-system/dist/components/Select";
import { Encryption, encryptionType } from "../../store/store.types";
import { useSelector } from "react-redux";
import { wizardSelector } from "../../store/selectors/wizard.selector";

interface EncryptOption extends optionType {
    value: encryptionType
}

interface CustomOption {
    title: string,
    placeholder: string,
    validationError?: string
}

interface ManagedOption {
    title: string,
    options: optionType[]
}

interface EncryptionSettingProps {
    platformName: string,
    encryptSettings: {
        encryptOption: EncryptOption,
        settings?: CustomOption | ManagedOption
    }[],
    validator?: (encryption: Encryption) => boolean,
    onEncryptTypeChanged?: (encryptionType: encryptionType) => void
}

const EncryptionSetting: FunctionComponent<EncryptionSettingProps> = ({ platformName, encryptSettings, validator, onEncryptTypeChanged }) => {
    const { t } = useTranslation();
    const i18DefaultPath = "sync.wizard.encryptionSetting";
    const { encryptionType, encryptionKey } = useSelector(wizardSelector.targetGcpSelectedEncryption) || {}
    const [selectedEncryption, setSelectedEncryption] = useState<encryptionType>(encryptionType || 'default')
    const [customInputKeyVisited, setCustomInputKeyVisited] = useState(false);
    const customInputKey = encryptionType === 'custom' && encryptionKey ? encryptionKey : undefined;

    const encryptOptions = useMemo(() => {
        return encryptSettings.map(opt => opt.encryptOption);
    }, [encryptSettings]);

    const customInputSettings = useMemo(() => {
        return encryptSettings.find(opt => opt.encryptOption.value === "custom")?.settings as CustomOption;
    }, [encryptSettings])

    const managedInputSettings = useMemo(() => {
        return encryptSettings.find(opt => opt.encryptOption.value === "managed")?.settings as ManagedOption;
    }, [encryptSettings])

    const validate = (encryptOption: Encryption) => {
        validator && validator(encryptOption);
    }

    const customeInputKeyBlur = (encryptionKey: string) => {
        setCustomInputKeyVisited(true);
        validate({
            encryptionType: 'custom',
            encryptionKey: encryptionKey
        })
    }

    const encryptionTypeChange = (encryptOption: Encryption) => {
        const { encryptionType } = encryptOption;
        setSelectedEncryption(encryptionType);
        setCustomInputKeyVisited(false);
        validate(encryptOption);
        onEncryptTypeChanged && onEncryptTypeChanged(encryptOption.encryptionType);
    }

    const managedListChanged = (option: optionType[]) => {
        validate({
            encryptionType: 'managed',
            encryptionKey: (option as any as optionType).value as string
        })
    }

    const defaultManagedOption = (): optionType[] | undefined => {
        if (!managedInputSettings?.options) return undefined;

        return [managedInputSettings?.options.find(opt => opt.value === encryptionKey)!];
    }

    return (
        <div className="encryptionSetting">
            <div className="encryptTitle">
                <KeyIcon className='encryptIcon' />
                <Typography variant="Semibold_14">{t(`${i18DefaultPath}.title`, { platformName })}</Typography>
            </div>
            {selectedEncryption && <SelectField className='encryptionTypeList'
                label={t(`${i18DefaultPath}.subTitle`)}
                options={encryptOptions}
                isSearchable={false}
                isClearable={false}
                defaultValue={[encryptOptions.find(opt => opt.value === (selectedEncryption || 'default'))!]}
                onChange={(option: optionType[]) => encryptionTypeChange({
                    encryptionType: (option as any as EncryptOption).value,
                    encryptionKey: undefined
                })} />}
            {selectedEncryption === 'custom' && <TextField
                className="customKey"
                label={customInputSettings!.title}
                placeholder={customInputSettings!.placeholder}
                error={customInputKeyVisited ? customInputSettings!.validationError : undefined}
                onBlur={(event: FocusEvent<HTMLElement>) => customeInputKeyBlur((event.target as HTMLInputElement).value)}
                defaultValue={customInputKey} />}
            {selectedEncryption === 'managed' && <SelectField className='encryptionTypeList'
                label={managedInputSettings!.title}
                options={managedInputSettings!.options}
                isSearchable={false}
                isClearable={false}
                defaultValue={defaultManagedOption()}
                onChange={managedListChanged}
                isLoading={managedInputSettings?.options === null} />}
        </div>
    )
}

export default EncryptionSetting;