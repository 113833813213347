export const AUTH_REFRESH_SSO_SUCCESS = "AUTH_REFRESH_SSO_SUCCESS";
export const AUTH_REFRESH_SSO_FAILED = "AUTH_REFRESH_SSO_FAILED";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";
export const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA";

export const GET_LICENSE_SETTINGS = "GET_LICENSE_SETTINGS";
export const GET_BYOL_LICENSES = "GET_BYOL_LICENSES";
export const GET_NSS_CREDENTIALS = "GET_NSS_CREDENTIALS";
export const ADD_BYOL_LICENSE = "ADD_BYOL_LICENSE";
export const VISITED_TRIAL_PAGE = "VISITED_TRIAL_PAGE";

export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS";
export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
export const UPDATE_SUPPORT_INFO = "UPDATE_SUPPORT_INFO";

export const AUTH_REFRESH_SSO = 'AUTH_REFRESH_SSO';

export const STATUS_ERROR = 'ERROR';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_PROGRESS = 'IN-PROGRESS';

export const MESSAGES = 'MESSAGES';
export const BOOT_ANALYTICS = 'BOOT_ANALYTICS';

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const SET_CM_INTEGRATION_DETAILS = "SET_CM_INTEGRATION_DETAILS";
export const CLEAR_CM_INTEGRATION_DETAILS = "CLEAR_CM_INTEGRATION_DETAILS";