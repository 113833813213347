import React, { useEffect, useState } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Validator from 'validatorjs';
import { SelectField, InputField, RadioInputs, CheckboxField } from '../_common_/forms/forms';
import _ from 'lodash';
import * as azureService from '../../services/azure.api.service';
import { AZURE_SSH_INSTRUCTIONS } from '../../consts';
import useRunOnce from '../../hooks/useRunOnce';
import InstanceDetailsColumnHeader from './InstanceDetailsColumnHeader';
import { ENABLE_DISABLE_OPTIONS } from "../../consts";
import ButtonsPanel from '../widgets/ButtonsPanel';
import { v4 as uuidv4 } from 'uuid';
import useRunOnceWhenTruthy from '../../hooks/useRunOnceWhenTruthy';
import { INSTANCE_DETAILS_FORM } from "../../consts";

import './instanceDetailsAzureForm.scss';

const INSTANCE_NAME_REGEX = '/[a-zA-Z][-a-zA-Z0-9]*/';
const USER_NAME_REGEX = '/(?!\\badmin\\b)(?!\\bAdmin\\b)(^[a-zA-Z][0-9a-zA-Z]{0,30}$)/';
export const RESOURCE_GROUP_OPTIONS = ["Generate a new group", "Use an existing group"];
export const SECURITY_GROUP_OPTIONS = ["Generate a new group", "Use an existing group"];

const InstanceDetailsAzureForm = ({ handleContinue, dataBrokerName, handleError, formValues, clearFields, initialize, submitting, handleSubmit }) => {
    const selectedSubscription = formValues?.selectedSubscription;

    const [subscriptions, setSubscriptions] = useState(undefined);
    const [regions, setRegions] = useState(undefined);
    const [vnets, setVnets] = useState(undefined);
    const [resourceGroups, setResourceGroups] = useState(undefined);
    const [securityGroups, setSecurityGroups] = useState(undefined);

    //don't initialize on back navigation
    useRunOnceWhenTruthy(() => {
        initialize({
            virtualMachineName: dataBrokerName || undefined,
            adminUserName: "databroker",
            authMethod: "Password",
            resourceGroupOption: RESOURCE_GROUP_OPTIONS[0],
            securityGroupOption: SECURITY_GROUP_OPTIONS[0],
            publicIp: ENABLE_DISABLE_OPTIONS[0]
        });
    }, !formValues);

    useRunOnce(async () => {
        try {
            const res = await getAllSubscriptions();
            setSubscriptions(res.value);
        } catch (error) {
            handleError(error);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const info = await getInfoPerSubscription(selectedSubscription.value);
                setRegions(info.regions);
                setVnets(info.vnets);
                setResourceGroups(info.resourceGroups);
                setSecurityGroups(info.securityGroups);
            } catch (error) {
                handleError(error);
            }
        };

        if (selectedSubscription) {
            fetchData();
        }
    }, [selectedSubscription, handleError]);

    return (<>
        {formValues && <form className="data-broker-wizard-step instance-details-azure" onSubmit={handleSubmit((values) => handleContinue(extractValues(values)))}>
            <div className="title">Basic Settings</div>
            <div className="content-area">
                <div className="form-content">
                    <div className="col left">
                        <InstanceDetailsColumnHeader text={"Location"} />
                        <AzureSelectField fieldName="selectedSubscription" clearFields={clearFields}
                            data={subscriptions}
                            propertyForLabel="displayName"
                            propertyForValue="subscriptionId"
                            isDisabled={submitting}
                            placeholder="Select a subscription" label="Subscription"
                            displayName="Subscriptions"
                            fieldsToClearUponChange={['selectedRegion', 'selectedVnet', 'selectedSubnet', 'selectedResourceGroupExisting', 'selectedSecurityGroupExisting']}
                        />

                        <AzureSelectField fieldName="selectedRegion" clearFields={clearFields}
                            data={regions}
                            propertyForLabel="displayName"
                            propertyForValue="name"
                            isDisabled={!formValues.selectedSubscription || submitting}
                            disabledTitle={submitting ? '' : 'Waiting for an Azure subscription.'}
                            placeholder="Select a region" label="Azure Region"
                            displayName="Azure Regions"
                            fieldsToClearUponChange={['selectedVnet', 'selectedSubnet', 'selectedResourceGroup', 'selectedResourceGroupExisting', 'selectedSecurityGroupExisting']}
                        />

                        <AzureSelectField fieldName="selectedVnet" clearFields={clearFields}
                            data={vnets}
                            propertyForLabel="name"
                            propertyForValue="id"
                            isDisabled={!formValues.selectedRegion || submitting}
                            disabledTitle={submitting ? '' : 'Waiting for an Azure region.'}
                            placeholder="Select a VNet" label="VNet"
                            displayName="VNets"
                            filterFunction={formValues.selectedRegion ? (vnet) => vnet.location === formValues.selectedRegion.value : undefined}
                            fieldsToClearUponChange={['selectedSubnet']}
                        />

                        <AzureSelectField fieldName="selectedSubnet" clearFields={clearFields}
                            data={formValues.selectedVnet ? _.get(_.find(vnets, { id: formValues.selectedVnet.value }), 'properties.subnets') : undefined}
                            propertyForLabel="name"
                            propertyForValue="name"
                            isDisabled={!formValues.selectedVnet || submitting}
                            disabledTitle={submitting ? '' : 'Waiting for an Azure Vnet.'}
                            placeholder="Select a subnet" label="Subnet"
                            displayName="Subnets"
                            filterFunction={(subnet) => subnet.properties.delegations ? subnet.properties.delegations.length === 0 : true}
                        />

                        <Field component={SelectField}
                            name="publicIp"
                            label="Public IP"
                            isDisabled={submitting}
                            options={ENABLE_DISABLE_OPTIONS}
                            valueField="value" />

                        <div className="section-title">Data Broker Role</div>
                        <Field id="createRole"
                            type="checkbox"
                            component={CheckboxField}
                            name="createRole">
                            <label htmlFor="createRole">Create Custom Role</label>
                        </Field>
                        <div>
                            <span className="notice">Notice:</span>
                            <a href='https://docs.netapp.com/us-en/cloud-manager-sync/whats-new.html#continuous-sync-from-microsoft-azure' rel="noopener noreferrer" target="_blank">Only relevant for continuous sync relationships from Azure. Users can also manually create this later.</a>
                        </div>
                    </div>
                    <div className="col right">
                        <InstanceDetailsColumnHeader text={"Connectivity"} />
                        <Field name="virtualMachineName" component={InputField} label="VM Name" disabled={submitting}
                            info={'The name can include up to 64 alphanumeric characters and hyphens. The name cannot include only numbers.'} />
                        <Field name="adminUserName" component={InputField} label="User Name" disabled={submitting}
                            info={'The name should start with an alphabetic character and contain only numbers, letters, and should be 30 or fewer characters in length. Must not be Admin.'} />

                        <div className="section-title">Authentication Method:</div>
                        <Field name="authMethod" component={RadioInputs} disabled={submitting}
                            changeSelection={_.noop}
                            checkedIndex={formValues.authMethod === 'Password' ? 0 : 1}
                            inputs={['Password', 'Public Key']}
                            className="radio-options" />
                        {formValues.authMethod === 'Password' &&
                            <Field name="adminPassword"
                                component={InputField} label="Enter Password" disabled={submitting}
                                type="password"
                                autoComplete="off"
                                info={'Password requirements: 7-71 characters in length and three of the following: Uppercase, Lowercase, Special characters, Digits (0-9).'} />}
                        {formValues.authMethod === 'Public Key' &&
                            <Field name="sshKey"
                                component={InputField} label="Enter SSH Public Key" disabled={submitting}
                                info={<div>The Linux VM uses an SSH public-private key pair for authentication. Generate an RSA
                                    SSH key file using ssh-keygen or PuTTYGen
                                    (see <a href={AZURE_SSH_INSTRUCTIONS} target="_blank" rel="noopener noreferrer">Azure
                                        docs</a>).
                                    Copy the key from the file (it starts with &#34;ssh-rsa&#34;) and paste it into this field.
                                </div>
                                } />}

                        <div className="section-title">Resource Group:</div>
                        <Field name="resourceGroupOption" component={RadioInputs} disabled={submitting}
                            changeSelection={_.noop}
                            checkedIndex={formValues.resourceGroupOption === RESOURCE_GROUP_OPTIONS[0] ? 0 : 1}
                            inputs={RESOURCE_GROUP_OPTIONS}
                            className="radio-options" />
                        {formValues.resourceGroupOption === RESOURCE_GROUP_OPTIONS[1] &&
                            <AzureSelectField fieldName="selectedResourceGroupExisting" clearFields={clearFields}
                                data={resourceGroups}
                                propertyForLabel="name"
                                propertyForValue="name"
                                isDisabled={!formValues.selectedRegion || submitting}
                                disabledTitle={submitting ? '' : 'Waiting for an Azure region.'}
                                label=''
                                placeholder="Select a Resource Group"
                                displayName="Resource Groups"
                                filterFunction={formValues.selectedRegion ? (resourceGroup) => resourceGroup.location === formValues.selectedRegion.value : undefined}
                            />}
                        <div className="section-title">Security group:</div>
                        <Field name="securityGroupOption" component={RadioInputs} disabled={submitting}
                            changeSelection={_.noop}
                            checkedIndex={formValues.securityGroupOption === SECURITY_GROUP_OPTIONS[0] ? 0 : 1}
                            inputs={SECURITY_GROUP_OPTIONS}
                            className="radio-options" />
                        {formValues.securityGroupOption === SECURITY_GROUP_OPTIONS[1] &&
                            <AzureSelectField fieldName="selectedSecurityGroupExisting" clearFields={clearFields}
                                data={securityGroups}
                                propertyForLabel="name"
                                propertyForValue="id"
                                isDisabled={!formValues.selectedRegion || submitting}
                                disabledTitle={submitting ? '' : 'Waiting for an Azure region.'}
                                label=''
                                placeholder="Select a security group"
                                displayName="Security Groups"
                                filterFunction={formValues.selectedRegion ? (securityGroups) => securityGroups.location === formValues.selectedRegion.value : undefined}
                            />}
                    </div>
                </div>
            </div>

            <ButtonsPanel
                variant="transparent"
                type="submit"
                text="Continue"
                submitting={submitting} />

        </form>}
    </>);
};

const AzureSelectField = ({ data, propertyForLabel, propertyForValue, fieldsToClearUponChange, placeholder, label, clearFields,
    displayName, fieldName, isDisabled, disabledTitle, filterFunction = () => true }) => {
    const options = data ? data.filter(filterFunction)
        .map((item) => ({ label: item[propertyForLabel], value: item[propertyForValue] })) : undefined;

    const optionsSorted = _.sortBy(options, option => option.label);
    const title = isDisabled ? disabledTitle : '';

    return (
        <div title={title}>
            <Field component={SelectField}
                onChange={() => fieldsToClearUponChange && clearFields(INSTANCE_DETAILS_FORM, false, false, ...fieldsToClearUponChange)
                }
                name={fieldName}
                options={optionsSorted}
                label={label}
                isLoading={!isDisabled && !data}
                placeholder={placeholder}
                valueField="value"
                isDisabled={isDisabled}
                noOptionsMessage={() => `No ${displayName} available`} />
        </div>
    )
};

const getAllSubscriptions = async () => {
    const subscriptions = [];
    for await (const value of azureService.getSubscriptionsGenerator()) {
        subscriptions.push(value);
    }
    return { value: subscriptions.flat() };
}

const getAllVnetWithSubnets = async (subscriptionId) => {
    const vnetAndSubnets = [];
    for await (const value of azureService.getVnetsWithSubnetsGenerator(subscriptionId)) {
        vnetAndSubnets.push(value);
    }
    return { value: vnetAndSubnets.flat() };
}

const getAllResourceGroups = async (subscriptionId) => {
    const resourceGroups = [];
    for await (const value of azureService.getResourceGroupsGenerator(subscriptionId)) {
        resourceGroups.push(value);
    }
    return { value: resourceGroups.flat() };
}

const getAllSecurityGroups = async subscriptionId => {
    const securityGroups = [];
    for await (const value of azureService.getAllSecurityGroups(subscriptionId)) {
        securityGroups.push(value);
    }

    return { value: securityGroups.flat() };
}

const getInfoPerSubscription = async subscriptionId => {
    const [resRegions, resVnets, resResourceGroups, securityGroups] = await Promise.all([
        azureService.getAzureRegions(subscriptionId),
        getAllVnetWithSubnets(subscriptionId),
        getAllResourceGroups(subscriptionId),
        getAllSecurityGroups(subscriptionId)]);

    return {
        regions: resRegions.value,
        vnets: resVnets.value,
        resourceGroups: resResourceGroups.value,
        securityGroups: securityGroups.value
    }
};

const validate = (values) => {
    // eslint-disable-next-line no-unused-vars
    Validator.register('azurePassword', (value, requirement, attribute) => {
        let passwordSatisfaction = 0;
        if (value.length > 6 && value.length < 72) {
            if (value.match(/[A-Z]/)) ++passwordSatisfaction;
            if (value.match(/[a-z]/)) ++passwordSatisfaction;
            if (value.match(/[0-9]/)) ++passwordSatisfaction;
            // eslint-disable-next-line no-useless-escape
            if (value.match(/[~!@#$%^&\*_\-\+=`|\/(){}\[\]:;"'<>,.?]/)) ++passwordSatisfaction;
        }
        // need to have at least 3 of those rules (according to Azure requirements)
        return passwordSatisfaction >= 3;
    }, `The :attribute field doesn't match the requirements.`);

    const rules = {
        virtualMachineName: ['required', `regex:${INSTANCE_NAME_REGEX}`],
        adminUserName: ['required', `regex:${USER_NAME_REGEX}`],
        selectedSubscription: ['required'],
        selectedRegion: ['required'],
        selectedVnet: ['required'],
        selectedSubnet: ['required'],
        adminPassword: values.authMethod === 'Password' ? ['required', 'azurePassword'] : [],
        sshKey: values.authMethod === 'Public Key' ? ['required'] : [],
        selectedResourceGroupExisting: values.resourceGroupOption === RESOURCE_GROUP_OPTIONS[1] ? ['required'] : [],
        selectedSecurityGroupExisting: values.securityGroupOption === SECURITY_GROUP_OPTIONS[1] ? ['required'] : []
    };

    const validator = new Validator(values, rules);
    validator.setAttributeNames({
        selectedResourceGroupExisting: 'Resource Group',
        selectedSecurityGroupExisting: 'Security Group',
        virtualMachineName: 'VM name',
        sshKey: 'SSH public key',
        adminUserName: 'User Name',
        adminPassword: 'Password',
        selectedSubscription: 'Subscription',
        selectedRegion: 'Region',
        selectedVnet: 'VNet',
        selectedSubnet: 'Subnet'
    });
    validator.passes();
    return validator.errors.all();
};

const AzureForm = reduxForm({
    form: INSTANCE_DETAILS_FORM,
    validate,
    destroyOnUnmount: false
})(InstanceDetailsAzureForm);

export default connect(state => ({ formValues: getFormValues(INSTANCE_DETAILS_FORM)(state) }))(AzureForm);

const extractValues = (values) => {
    const vnetArr = values.selectedVnet.value.split('/');
    const vnetResourceGroupName = vnetArr[vnetArr.indexOf('resourceGroups') + 1]; // Grab the vnet resource group name

    const securityGroupArr = values.securityGroupOption === SECURITY_GROUP_OPTIONS[1] ? values.selectedSecurityGroupExisting?.value.split('/') : null;
    const nsgResourceGroupName = securityGroupArr && securityGroupArr.length > 1 ? securityGroupArr[securityGroupArr.indexOf('resourceGroups') + 1] : '';
    return {
        subscriptionId: values.selectedSubscription.value,
        resourceGroupName: values.resourceGroupOption === RESOURCE_GROUP_OPTIONS[1] ? values.selectedResourceGroupExisting.value : generateResourceGroupName(),
        isExistingResourceGroup: values.resourceGroupOption === RESOURCE_GROUP_OPTIONS[1],
        location: values.selectedRegion.value,
        virtualMachineName: values.virtualMachineName,
        virtualNetworkName: values.selectedVnet.label,
        subnetName: values.selectedSubnet.value,
        adminPublicKey: values.sshKey || '',
        vnetResourceGroupName,
        adminUsername: values.adminUserName,
        authType: values.authMethod === "Password" ? "password" : "ssh",
        adminPassword: values.adminPassword || '',
        enablePublicIP: values.publicIp.value,
        createRole: values.createRole,
        networkSecurityGroupName: values.securityGroupOption === SECURITY_GROUP_OPTIONS[1] ? values.selectedSecurityGroupExisting.label : '',
        nsgResourceGroupName
    }
};

const generateResourceGroupName = () => {
    return `CloudSync-rg-${uuidv4()}`;
};