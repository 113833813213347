import React from "react"
import {map} from 'lodash'
import "./barsGraph.scss"

const DashboardBar = ({item, multipleColumns, backgroundColor}) => {
    const { name, percent, count} = item

    return (
        <div className="horizontal-bar">
            <div className="horizontal-bar-info">
                {multipleColumns && <div className="name">{name}</div>}
                <div className="horizontal-bar-info-data">{`${count} items`}</div>
            </div>
            <div className="horizontal-bar-data">
                {!multipleColumns && <div className="name">{name}</div>}
                <div className="data-container">
                    <div className="data" style={{width: `${percent}%`, backgroundColor: backgroundColor || '#1fa0ff'}}/>
                    <div className="fill" style={{ width: `${(100 - percent)}%`}}/>
                </div>
            </div>
        </div>
    )
}

const BarsGraph = ({data, numOfColumns, backgroundColor}) => {
    if (numOfColumns === 2) {
        const part = Math.ceil(data.length/numOfColumns);
        const list1 = data.slice(0, part);
        const list2 = data.slice(part);
        return (
            <div className="multiple-columns-graph multiple-columns-2">
                <ActualGraph data={list1} multipleColumns={2} backgroundColor={backgroundColor}/>
                <ActualGraph data={list2} multipleColumns={2} backgroundColor={backgroundColor}/>
            </div>
        )
    }
    else if (numOfColumns === 3) {
        const part = Math.ceil(data.length/numOfColumns);
        const list1 = data.slice(0, part);
        const list2 = data.slice(part, 2*part);
        const list3 = data.slice(2*part);
        return (
            <div className="multiple-columns-graph multiple-columns-3">
                <ActualGraph data={list1} multipleColumns={3} backgroundColor={backgroundColor}/>
                <ActualGraph data={list2} multipleColumns={3} backgroundColor={backgroundColor}/>
                <ActualGraph data={list3} multipleColumns={3} backgroundColor={backgroundColor}/>
            </div>
        )
    }
    return (
        <ActualGraph data={data} multipleColumns={false}/>
    )
}

const ActualGraph = ({data, multipleColumns, backgroundColor}) => {
    return (
        <div className="horizontal-bars-graph">
            {map(data, (item, index) => {
                return (
                    <DashboardBar key={index} item={item} multipleColumns={multipleColumns} backgroundColor={backgroundColor}/>
                )
            })}
        </div>
    )
}



export default BarsGraph;

