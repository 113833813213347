import React, { useCallback} from "react";
import SelectableTable from "./SelectableTable";
import { NEW_TARGET_FOLDER_ID } from "../../consts";
import { Field } from "react-final-form";
import { ReactComponent as PlusIcon } from "../../assets/svgs/plus-fill-circle-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/svgs/checkmark-fill-circle-icon.svg";
import { ReactComponent as XIcon } from "../../assets/svgs/x.svg";
import useRunOnceWhenTruthy from "../../hooks/useRunOnceWhenTruthy";

require("./createFolderRow.scss");

const CreateFolderRowNewForm = (props) => {
  const {
    initialTargetFolder,
    selectionOptions,
    currentPath,
    resetForm,
    targetPlaceholder,
    itemIdentifier
  } = props;

  useRunOnceWhenTruthy(() => {
    if (initialTargetFolder) {
      selectionOptions.selector(currentPath + "/" + NEW_TARGET_FOLDER_ID);
    }
  }, initialTargetFolder);

  const renderCreateFolderRowNewForm = useCallback(() => {
    const isSelected =
      selectionOptions.selectedId &&
      selectionOptions.selectedId.endsWith(NEW_TARGET_FOLDER_ID);
    if (!isSelected) {
      return (
        <div className="create-folder-row">
          <td key="plus-icon" className="check plus-button">
            <span>
              <PlusIcon />
            </span>
          </td>
          <td className="path">{targetPlaceholder}</td>
        </div>
      );
    } else {
      return (
        <div className="create-folder-row">
          <td key="check-icon" className="check" onClick={resetForm}>
            <span>
              <CheckmarkIcon />
            </span>
          </td>
          <td className="path target-folder-name">
            <Field
              name="targetFolder"
              component="input"
              autoFocus
              placeholder={targetPlaceholder}
              autoComplete="off"
              /* don't un-toggle selection when clicking inside of the input */
              onClick={(e) => {
                e.stopPropagation();
              }}
              initialValue={initialTargetFolder}
            />
          </td>
          <td className="dive clear-button" onClick={resetForm}>
            {/* any click will outside of the input will clear the target folder selection and text */}
            <button type="button">
              <span>
                <XIcon />
              </span>
            </button>
          </td>
        </div>
      );
    }
  }, [selectionOptions, resetForm, targetPlaceholder, initialTargetFolder]);

  const data = [
    {
      path: currentPath,
      [itemIdentifier]: currentPath + "/" + NEW_TARGET_FOLDER_ID,
    },
  ];

  return (
    <SelectableTable
      noAnimate={true}
      Table={{
        renderRow: (selectionOptions, targetName) =>
          renderCreateFolderRowNewForm(selectionOptions, targetName),
      }}
      data={data}
      itemIdentifier={itemIdentifier}
      selectionOptions={selectionOptions}
    />
  );
};

export default CreateFolderRowNewForm;
