import React, { useCallback } from "react";
import {
  showS3BasedBuckets,
  listS3BasedBucketFolders,
  clearFoldersCache,
  updateUrlParams,
  wizardStepStarted,
  wizardStepExited,
} from "../../../store/wizard/action_creators";
import { connect } from "react-redux";
import TagsPopover from "../../widgets/TagsPopover";
import ShowObjectStorageStepTemplate from "./ShowObjectStorageStepTemplateNew";
import { END_POINT_NAMES_MAP } from "../../../consts";
import { buildS3BasedCacheKey, calculateStepId } from "../../../utils/sync-utils";
import _ from "lodash";
import {
  getPropFromState,
} from "../../../utils/mapStateToPropsUtils";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
import useStepinize from "../useStepinize";

require("./showObjectStorageStepNew.scss");

const innerColumns = (row) => {
  const { region, tags } = row;
  return region && tags
    ? [
        <td className="path" key="path" width="30%">
          <div className="text" title={row.path}>
            {row.path}
          </div>
          <div className="title">Name</div>
        </td>,
        <td className="region" key="region">
          <div className="text">
            {region.displayName}|{region.name}
          </div>
          <div className="title">Region</div>
        </td>,
        <TagsPopover tags={tags} inTable={true} leftAdjust={-470} key="tags" />,
      ]
    : [
        <td className="path" title={row.path} key="path">
          {row.path}
        </td>,
      ];
};

const loading = ({endpointObj}) => {
  const serverType = endpointObj.provider || endpointObj.protocol;
  return {
    text: "Buckets",
    icon: require(`../../../assets/svgs/${serverType}-default.svg`),
  };
}

const stepId = ({_wizard: { wizardInfo }, isTargetStepFromSameSourceType}) => {
  return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "s3", "Buckets");
};

const fetchData = (props) => {
  const localSelected = props.isTargetStepFromSameSourceType
      ? props._wizard.selectedWizardParams.selectedS3BasedTarget
      : props._wizard.selectedWizardParams.selectedS3Based;
  const provider = props.endpointObj.provider;
  return props.showS3BasedBuckets(
      localSelected,
      props.isTargetStepFromSameSourceType,
      provider,
      props._wizard.selectedWizardParams.selectedDataBroker.id);
};

const shouldFetch = (props) => {
  const {
    _showS3BasedBuckets,
    isTargetStepFromSameSourceType,
    _wizard: {
      selectedWizardParams: {
        selectedS3BasedTarget,
        selectedS3Based,
        selectedDataBroker,
      },
    },
  } = props;
  const relevantSelected = isTargetStepFromSameSourceType
      ? selectedS3BasedTarget
      : selectedS3Based;
  const cacheKey = buildS3BasedCacheKey(
      relevantSelected,
      isTargetStepFromSameSourceType
  );
  const buckets =
      _showS3BasedBuckets[cacheKey] &&
      _showS3BasedBuckets[cacheKey].groupId ===
      _.get(selectedDataBroker, "id")
          ? _showS3BasedBuckets[cacheKey]
          : undefined;
  return !buckets || buckets.failedRetrieve;
};

const isFetching = (props) => {
  const { _showS3BasedBuckets } = props;
  const relevantSelected = props.isTargetStepFromSameSourceType
      ? props._wizard.selectedWizardParams.selectedS3BasedTarget
      : props._wizard.selectedWizardParams.selectedS3Based;
  const cacheKey = buildS3BasedCacheKey(
      relevantSelected,
      props.isTargetStepFromSameSourceType
  );
  return (
      !_showS3BasedBuckets[cacheKey] ||
      _showS3BasedBuckets[cacheKey].inProgress ||
      ((!_showS3BasedBuckets[cacheKey].data ||
          _showS3BasedBuckets[cacheKey].data.length === 0) &&
          !_showS3BasedBuckets[cacheKey].retrievedAll)
  );
};


const S3BasedBucketsStep = (props) => {
  const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
  const {endpointObj, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;

  const {clearFoldersCache, _showS3BasedBuckets, listS3BasedBucketFolders, _wizard: {
    selectedWizardParams: {selectedS3BasedBucketPath, selectedS3BasedBucketPathTarget, selectedS3Based, selectedS3BasedTarget}}} = props;
  const relevantSelected = isTargetStepFromSameSourceType ? selectedS3BasedTarget : selectedS3Based;
  const providerUpper = _.toUpper(endpointObj.provider);
  const storageType = END_POINT_NAMES_MAP[providerUpper];
  const cacheKey = buildS3BasedCacheKey(relevantSelected, isTargetStepFromSameSourceType);
  const relevantBuckets = _showS3BasedBuckets[cacheKey];
  const containerOptions = {
    singularTitle: "Bucket",
    pluralTitle: "Buckets",
    itemIdentifier: "actualPath",
    rootName: "All Buckets",
    targetName: "Define Target Folder",
    directoriesName: "Folders",
    directoryName: "folder",
    icon: require("../../../assets/svgs/bucket-icon.svg"),
  };

  const callListS3BasedBucketFoldersAction = useCallback(
    (bucket, path, groupId) => {
      listS3BasedBucketFolders(
          relevantSelected,
          bucket,
          path,
          isTargetStepFromSameSourceType,
          endpointObj.provider,
          groupId
      );
    },
    [
      relevantSelected,
      isTargetStepFromSameSourceType,
      endpointObj,
      listS3BasedBucketFolders,
    ]
  );

  const stepOptions = {
    selectedBaseObject: isTargetStepFromSameSourceType
      ? selectedS3BasedBucketPathTarget
      : selectedS3BasedBucketPath,
    title: `Select a ${isSourceStep ? "Source" : "Target"} Bucket`,
    failedMessage: "Unable to retrieve buckets",
    notFoundMessage: "No buckets were found",
    bottomMessage: `in your ${storageType}`,
    storage: relevantBuckets,
    getStorageByBrokerId: false,
    listSubFoldersAction: callListS3BasedBucketFoldersAction,
    storageObjectName: "Bucket",
    storageObjectType: storageType,
    innerColumns,
    stepFormName: "s3BasedBucketsForm",
    containerOptions,
    innerPathBeginWithSlash: true,
    refreshStep: () => {
      clearFoldersCache({
        cacheName: "_showS3BasedBuckets",
        storageKey: buildS3BasedCacheKey(
          relevantSelected,
          isTargetStepFromSameSourceType
        ),
      });
      fetchData({...props, ...moreStepInfo});
    },
    getSelectedParams: (bucket) => {
      const paramName = isTargetStepFromSameSourceType ? 's3BasedBucketPathTarget' : 's3BasedBucketPath';
      return {[paramName] : bucket}
    },
    getExtraUrlParamsNew: () =>({}),
  };

  return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ShowObjectStorageStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    _showS3BasedBuckets: getPropFromState(state, "_showS3BasedBuckets", {}),
    _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
    _ui: { ...state.syncReducer._ui, ...state.global._ui },
    routing: state.router,
  };
};

const mapDispatchToProps = {
  listS3BasedBucketFolders,
  showS3BasedBuckets,
  clearFoldersCache,
  updateUrlParams,
  wizardStepStarted,
  wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(S3BasedBucketsStep);
