import {capitalize} from "lodash";
import {isIntegratedCmProvider} from "./cm-integration-utils";
import DataBrokerStepNew from "../components/newWizard/steps/DataBrokerStepNew";
import ShowBucketsStepNew from "../components/newWizard/steps/ShowBucketsStepNew";
import SyncSettingsStepNew from "../components/newWizard/steps/SyncSettingsStepNew";
import RelationshipTagsStepNew from "../components/newWizard/steps/RelationshipTagsStepNew";
import RelationshipStepNew from "../components/newWizard/steps/RelationshipStepNew";
import NFSServerStepNew from "../components/newWizard/steps/NFSServerStepNew";
import CIFSServerStepNew from "../components/newWizard/steps/CIFSServerStepNew";
import ShowExportsStepNew from "../components/newWizard/steps/ShowExportsStepNew";
import ShowSharesStepNew from "../components/newWizard/steps/ShowSharesStepNew";
import DataBrokerFunctionStepNew from "../components/newWizard/steps/DataBrokerFunctionStepNew";
import OnPremWeStepNew from "../components/newWizard/steps/OnPremWeStepNew";
import CvoWeStepNew from "../components/newWizard/steps/CvoWeStepNew";
import AnfWeStepNew from "../components/newWizard/steps/AnfWeStepNew";
import ShowGCPBucketsStepNew from "../components/newWizard/steps/ShowGCPBucketsStepNew";
import FsxWeStepNew from "../components/newWizard/steps/FsxWeStepNew";
import SftpStepNew from "../components/newWizard/steps/SftpStepNew";
import ShowSftpDirectoriesStepNew from "../components/newWizard/steps/ShowSftpDirectoriesStepNew";
import S3BasedServerStepNew from "../components/newWizard/steps/S3BasedServerStepNew";
import S3BasedBucketsStepNew from "../components/newWizard/steps/S3BasedBucketsStepNew";
import AzureBlobStepNew from "../components/newWizard/steps/AzureBlobStepNew";
import AzureDataLakeStep from "../components/newWizard/steps/AzureDataLakeStepNew";
import ShowAzureBlobContainersStepNew from "../components/newWizard/steps/ShowAzureBlobContainersStepNew";
import ShowAzureDataLakeContainersStepNew from "../components/newWizard/steps/ShowAzureDataLakeContainersStepNew";
import BoxStepNew from "../components/newWizard/steps/BoxStepNew";
import ShowBoxFoldersStepNew from "../components/newWizard/steps/ShowBoxFoldersStepNew";
import DataSenseStepNew from "../components/newWizard/steps/dataSense/DataSenseStepNew";
import GoogleStepNew from "../components/newWizard/steps/GoogleStepNew";
import ShowGoogleDriveFoldersStepNew from "../components/newWizard/steps/ShowGoogleDriveFoldersStepNew";

export const getStepComponentForNewWizard = (stepPath) => {
    switch (stepPath) {
        case 'agents':
        case 'targetagents':
            return DataBrokerStepNew;
        case 'buckets':
        case 'targetbuckets':
            return ShowBucketsStepNew;
        case 'settings':
            return SyncSettingsStepNew;
        case 'tags':
            return RelationshipTagsStepNew;
        case 'review':
            return RelationshipStepNew;
        case 'nfs':
        case 'targetnfs':
            return NFSServerStepNew;
        case 'targetcifs':
        case 'cifs':
            return CIFSServerStepNew;
        case 'targetexports':
        case 'exports':
            return ShowExportsStepNew;
        case 'shares':
        case 'targetshares':
            return ShowSharesStepNew;
        case 'brokerfunction':
            return DataBrokerFunctionStepNew;
        case 'onpremnfswe':
        case 'onpremcifswe':
        case 'targetonpremnfswe':
        case 'targetonpremcifswe':
            return OnPremWeStepNew;
        case 'cvonfswe':
        case 'cvocifswe':
        case 'targetcvonfswe':
        case 'targetcvocifswe':
            return CvoWeStepNew;
        case 'anfnfswe':
        case 'anfcifswe':
        case 'targetanfnfswe':
        case 'targetanfcifswe':
            return AnfWeStepNew;
        case 'gcpbuckets':
        case 'targetgcpbuckets':
            return ShowGCPBucketsStepNew;
        case 'fsxnfswe':
        case 'fsxcifswe':
        case 'targetfsxnfswe':
        case 'targetfsxcifswe':
            return FsxWeStepNew;
        case 'sftp' :
            return SftpStepNew;
        case 'sftpdirectories':
        case 'targetsftpdirectories':
            return ShowSftpDirectoriesStepNew;
        case 's3based':
        case 'targets3based':
            return S3BasedServerStepNew;
        case 's3basedbuckets':
        case 'targets3basedbuckets':
            return S3BasedBucketsStepNew;
        case 'azure':
        case 'targetazure':
            return AzureBlobStepNew;
        case 'azureDataLake':
        case 'targetAzureDataLake':
            return AzureDataLakeStep;
        case 'dataLakecontainers':
        case 'targetDataLakecontainers':
            return ShowAzureDataLakeContainersStepNew;
        case 'blobcontainers':
        case 'targetblobcontainers':
            return ShowAzureBlobContainersStepNew;
        case 'box' :
            return BoxStepNew;
        case 'boxfolders':
        case 'targetboxfolders':
            return ShowBoxFoldersStepNew;
        case 'gdrive' :
            return GoogleStepNew;
        case 'gdriveFolders':
        case 'targetgdriveFolders':
            return ShowGoogleDriveFoldersStepNew;
        case 'datasense':
            return DataSenseStepNew;
        default: {
            throw new Error(`Unknown step ${stepPath}`);
        }
    }
};


export const getIsIntegratedStep = (endpointObj) => {
    return isIntegratedCmProvider(endpointObj.provider)
};

export const fetchAnfVolumes = (props, protocol) => {
    const { isTargetStepFromSameSourceType, _wizard: {selectedWizardParams}, _accountInfo, _anfVolumes, refreshVolumes} = props;
    const protocolCapitalized = capitalize(protocol);
    const selectedAnfWe = selectedWizardParams[`selectedAnf${protocolCapitalized}We`];
    const selectedAnfWeTarget = selectedWizardParams[`selectedAnf${protocolCapitalized}WeTarget`];

    const localSelectedAnf = isTargetStepFromSameSourceType
        ? selectedAnfWeTarget
        : selectedAnfWe;
    const relevantAnfVols = _anfVolumes[localSelectedAnf];
    if (!relevantAnfVols || refreshVolumes) {
        const accountId = _accountInfo?.data?.accountId;
        return props.getAnfVolumes(accountId, localSelectedAnf);
    }
};

export const scrollToTop = (element) => {
    if (!element) return;
    element.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export const getCMIntegrationInfo = (wizardInfo) => {
    const isSourceCmWe = isIntegratedCmProvider(wizardInfo.source.provider);
    const isTargetCmWe = isIntegratedCmProvider(wizardInfo.target.provider);
    return {
        source: isSourceCmWe,
        target: isTargetCmWe
    };
};

