import React from "react";
import Isvg from 'react-inlinesvg';
import ReactLoading from 'react-loading';
import classNames from "classnames";
import {
    RESPONSE_STATUS_ABORTED,
    RESPONSE_STATUS_ABORTING,
    RESPONSE_STATUS_DONE,
    RESPONSE_STATUS_FAILED,
    RESPONSE_STATUS_PENDING,
    RESPONSE_STATUS_RUNNING,
    STATUS_STARTING,
    STATUS_CONTINUOUSLY_SYNCING
} from "../consts";
import _ from 'lodash';
import {ReactComponent as NotificationErrorIcon} from "./../assets/svgs/notification-icon-error.svg";
import {ReactComponent as NotificationSuccessIcon} from "./../assets/svgs/notification-icon-success.svg";
import {ReactComponent as PendingIcon} from "./../assets/svgs/pending.svg";
import {ReactComponent as ContinuousSyncIcon} from "./../assets/svgs/continuousSync.svg";


export const getSVGByProtocol =(protocol, isSecureRelationship) => {
    let icon = null;
    try {
        icon = require(`../assets/svgs/${protocol}-${isSecureRelationship ? "table-secure" : "table"}.svg`);
    } catch (e) {
        icon = require('../assets/svgs/generic-table.svg');
    }
    return <div className="icon"><Isvg src={icon?.default || icon}/></div>;
};

export const getClassBySyncStatus = (status, showUnknown) => {
    return classNames({
        "sync-status" : true,
        "syncing" : ([RESPONSE_STATUS_RUNNING, RESPONSE_STATUS_ABORTING, STATUS_STARTING, STATUS_CONTINUOUSLY_SYNCING ].includes(status) ),
        "unknown" : !!showUnknown,
        "synced-done" : (status === RESPONSE_STATUS_DONE),
        "failed" : (status === RESPONSE_STATUS_FAILED  || status === RESPONSE_STATUS_ABORTED),
        "pending": status === RESPONSE_STATUS_PENDING
    });
};

export const getSVGBySyncStatus =(status) => {
    switch (status) {
        case "FAILED":
        case "ABORTED":
        case "INTERRUPTED": return <NotificationErrorIcon/>;
        case "RUNNING":
        case "STARTING": return <div className="loading"><ReactLoading type="spin" color="#1fa0ff" height={22} width={22} delay={50} /></div>;
        case "ABORTING": return <div className="loading"><ReactLoading type="spin" color="#1fa0ff" height={22} width={22} delay={50} /></div>;
        case "PENDING": return <PendingIcon/>;
        case "RUNNING_CONTINUOUSLY": return <ContinuousSyncIcon/>
        default: return <NotificationSuccessIcon/>;
    }
};

export const noConnectionToAllBrokers = (dataBrokers) => {
    return _.every(dataBrokers, (dataBroker) => (dataBroker.isFailed || dataBroker.isWaiting));
};

export const getFixedProtocol = (endPoint) => {
    const {protocol} = endPoint;
    const details = endPoint[protocol];
    return details.provider;
};

export const getCopyDoneByCloudProvider = (source, target) => {
    return (source.protocol === 's3' && target.protocol === 's3' && source['s3'].provider === 's3' && target['s3'].provider === 's3')
        || (source.protocol === 'azure' && target.protocol === 'azure')
        || (source.protocol === 'gcp' && target.protocol === 'gcp');
};


export const checkIfFailExist = (activityValues) => {
    const scanFailedObj = _.find(activityValues.scan, {name: "Failed"});
    const copyFailedObj = _.find(activityValues.copy, {name: "Failed"});
    const scanFailedCount = Object.values(scanFailedObj.data).reduce((sum, {original})=> sum + original, 0);
    const copyFailedCount = Object.values(copyFailedObj.data).reduce((sum, {original})=> sum + original, 0);
    const totalFailed = scanFailedCount + copyFailedCount;
    return totalFailed > 0;
};
