import {createSlice} from '@reduxjs/toolkit';
import {DEPLOY_STATUS_PROGRESS} from "../../consts";

export const initialState = {
    credentials: {},
    deployments: []
};

const deploySlice = createSlice({
    name: 'deploy',
    initialState,
    reducers: {
        setAwsCredentialsAndRegion: (state, action) => {
            state.credentials.aws = action.payload;
        },
        setAzureCredentials: (state, action) => {
            state.credentials.azure = action.payload;
        },
        addDeployment: (state, action) => {
            const {payload: {dataBrokerId, dataBrokerName, providerType, deployDetails}} = action;
            state.deployments = [...state.deployments, {
                dataBrokerId,
                dataBrokerName,
                providerType,
                deployDetails,
                deployStatus: DEPLOY_STATUS_PROGRESS
            }];
        },
        setDeploymentStatus: (state, action) => {
            const {payload: {dataBrokerId, deployStatus, errorMessage}} = action;

            state.deployments = state.deployments.map(deployment => {
                return (deployment.dataBrokerId === dataBrokerId
                    ? {...deployment, deployStatus, errorMessage}
                    : deployment);
            });
        }
    },
});

export const {setAwsCredentialsAndRegion, setAzureCredentials, addDeployment, setDeploymentStatus} = deploySlice.actions;
export default deploySlice;
