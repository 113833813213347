import React from "react"
import classNames from "classnames"
import {Button} from '@netapp/shared-components';
require("./itemCountWidget.scss");

const ItemCountWidget = ({singularTitle, pluralTitle, itemCount, filteredItemCount, className, clearOutsideFilter}) => {
    const classes = classNames(className, "item-count-widget");
    const filtered = itemCount !== filteredItemCount;
    return (
        <div className={classes}>
                <span className="count">
                    {!filtered ? itemCount : filteredItemCount + " / " + itemCount}
                </span>
                <span className="title">
                    {itemCount === 1 ? singularTitle : pluralTitle}
                </span>
                {clearOutsideFilter && filtered && <span className="view-all">
                    <Button type="button" variant="secondary" className="view-all-button" onClick={clearOutsideFilter}>View All</Button>
                </span>}
        </div>
    )
};

export default ItemCountWidget;
