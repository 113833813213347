import React from "react";
import {FM_AGENT_TYPE_AWS, FM_AGENT_TYPE_AZURE, FM_AGENT_TYPE_GCP, FM_AGENT_TYPE_ONPREM} from '../../consts';
import ProviderLoginAws from './ProviderLoginAws';
import ProviderLoginGcp from './ProviderLoginGcp';
import ProviderLoginAzure from './ProviderLoginAzure';
import useRunOnce from '../../hooks/useRunOnce';
import {INSTANCE_DETAILS_FORM} from "../../consts";
import {useDispatch} from 'react-redux';
import {reset} from 'redux-form';

const ProviderLogin = ({type, dataBroker, setStepError, onContinue}) => {
    useRunOnce(() => {
        if (type === FM_AGENT_TYPE_ONPREM.name) {
            onContinue();
        }
    });

    const dispatch = useDispatch();

    const resetNextStepAndContinue = () => {
        dispatch(reset(INSTANCE_DETAILS_FORM));
        onContinue();
    };

    return (<>
        {type === FM_AGENT_TYPE_AWS.name && <ProviderLoginAws handleContinue={resetNextStepAndContinue} setStepError={setStepError} dataBroker={dataBroker} />}
        {type === FM_AGENT_TYPE_AZURE.name && <ProviderLoginAzure handleContinue={resetNextStepAndContinue} setStepError={setStepError} />}
        {type === FM_AGENT_TYPE_GCP.name && <ProviderLoginGcp handleContinue={resetNextStepAndContinue} setStepError={setStepError} />}
    </>);
};

export default ProviderLogin;