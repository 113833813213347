import classNames from 'classnames';
import React, {useState, useEffect} from 'react';
import CreatableSelect from 'react-select/creatable';
import {defaultFilter} from "../../../utils/helpers";
import _ from "lodash";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import {TextInput, Select, TextArea} from '@netapp/shared-components';
import {ReactComponent as CalendarIcon} from "./../../../assets/svgs/calendar.svg";
import {ReactComponent as ArrowLeftSingleIcon} from "./../../../assets/svgs/arrow-left-single.svg";
import {ReactComponent as ArrowLeftDoubleIcon} from "./../../../assets/svgs/arrow-left-double.svg";
import {ReactComponent as ArrowRightSingleIcon} from "./../../../assets/svgs/arrow-right-single.svg";
import {ReactComponent as ArrowRightDoubleIcon} from "./../../../assets/svgs/arrow-right-double.svg";

import "react-datepicker/dist/react-datepicker.css";
import "./forms.scss";
import "./multi-select-input.scss";
import "./date-picker.scss";


const extractError = (error) => _.isArray(error) ? error[0] : error;

export const TextAreaField = (props) => {
    const {meta: {touched, error}, input, children, ...rest} = props;
    const err = touched && error ? extractError(error) : null;
    return (<TextArea error={err} {...input} {...rest}/>)
}

export const InputField = (props) => {
    const {meta: {touched, error}, input, children, ...rest} = props;
    const type = props.type ? props.type : "text";
    const err = touched && error ? extractError(error) : null;

    return type === "text" || type === "password"
        ? <TextInput error={err} {...input} {...rest}/>
        : <>
            <input type={type} {...input} {...rest} className={err ? 'with-error' : ''}>{children}</input>
            {err && <div className="form-error is-visible" title={err}>{err}</div>}
        </>
};

export const CheckboxField = (props) => {
    const {className = "", input, children, ...rest} = props;
    return <div className={className}>
        <input type="checkbox" {...input}{...rest}/>
        {children}
    </div>
};

export const InlineInputField = ({className = "", ...rest}) => <InputField className={`inline ${className}`} removeBottomMargin={true} {...rest}/>;

export const CheckboxInputs = ({input, meta, options, disabled: allDisabled, disabledTitle: allDisabledTitle}) => {
    const {onChange, onBlur} = input;
    const {touched, error} = meta;
    const inputValue = input.value;

    //in case a specific option is disabled it will inlcude a disabled and disabled title fields
    const checkboxes = options.map(({label, value, disabled, disabledTitle}, index) => {
        const handleChange = (event) => {
            const arr = [...inputValue];
            if (event.target.checked) {
                arr.push(value);
            }
            else {
                arr.splice(arr.indexOf(value), 1);
            }
            return onChange(arr);
        };
        const checked = inputValue.includes(value);

        return (
            <label key={`checkbox-${index}`} title={allDisabledTitle || disabledTitle || ""} className={`checkbox-inputs ${allDisabled || disabled? "disabled" : ""}`}>
                <input type="checkbox"
                       disabled={allDisabled || disabled}
                       value={value}
                       checked={checked}
                       onChange={handleChange}
                       onBlur={() => onBlur(inputValue)}/>
                <span title={disabledTitle || allDisabledTitle || ""}>{label}</span>
            </label>
        );
    });

    return (
        <div>
            <div>{checkboxes}</div>
            {touched && extractError(error) && <div className="radio form-error is-visible" title={extractError(error)}>{extractError(error)}</div>}
        </div>
    );
};

export const RadioInputs = ({className, children, inputs, labels, input, checkedIndex, disabled, changeSelection, disabledTitle, meta: {touched, error} }) => {
    const classes = classNames({
        'is-invalid-input': touched && extractError(error)
    });

    return (
        <div className={`radio-buttons ${disabled ? "disabled" : ""}`}>
            {inputs.map( (item, index) => (
                <div key={index} className={className}>
                    <label title={disabled ? disabledTitle : ""}>
                        <input className={classes} onClick={() => changeSelection(index)} type="radio" {...input} value={item}
                               checked={input.value === item || checkedIndex === index} disabled={disabled}/>
                        {labels?.[index] || item}
                        {children}
                    </label>
                </div>
            ))}
            {touched && extractError(error) && <div className="radio form-error is-visible" title={extractError(error)}>{extractError(error)}</div>}
        </div>
    )
};

export const SelectField = (props) => {
    const {input, meta: {touched, error}, selectedValue, isDisabled, variant, ...rest} = props;
    const {onBlur, onChange, value} = input;
    const err = touched && error ? extractError(error) : null;

    return <Select
        variant={variant}
        value={selectedValue || value}
        disabled={isDisabled}
        filterOption={defaultFilter}
        error={err}
        onBlur={() => onBlur(value)}
        onChange={(event) => onChange(event)}
        {...rest}/>;
};

export const MultiSelectInput = (props) => {
    const {input, meta: {touched, error}, label, className, placeholder, limit, inputTransformation} = props;
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState(input.value || []);
    const [infoMessage, setInfoMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    //This is in order to handel a "reset" performed by a parent component
    useEffect(() => {
        if (input.value.length === 0) {
            setValue([]);
        }
        else {
            setValue(input.value);
        }
    }, [input.value]);

    const handleChange = (val) => {
        if (!val) {
            setValue([]);
            props.input.onChange([]);
            return;
        }
        if (val.length < limit) {
            setInfoMessage('');
            setWarningMessage('');
        }
        setValue(val);
        props.input.onChange(val);
    };
    const handleInputChange = (inputValue) => {
        setWarningMessage('');
        setInputValue(inputValue);
    };
    const addValue = () => {
        if (!inputValue) return;
        const transformedInput = inputTransformation(inputValue);
        //non unique validation:
        const existingIndex = value.findIndex(val => val.value === transformedInput);
        if (existingIndex > -1) {
            setWarningMessage('Value already exists');
            return;
        }
        //limit validation:
        if (value.length === limit) return;
        if (value.length + 1 === limit) {
            setInfoMessage(`You have reached the limit of ${limit}`);
        } else setInfoMessage('');

        setValue([...value, createOption(transformedInput)]);
        setInputValue('');
        props.input.onChange([...value, createOption(transformedInput)]);
        props.input.onBlur();
    };
    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                addValue();
                event.preventDefault();
                return;
            default:
                return;
        }
    };
    const createOption = (label) => {
        return {label, value: label}
    };
    const classes = classNames({
        'is-invalid-input': touched && extractError(error)
    });
    const placeholderText = placeholder || 'Type something and press enter...';
    const components = {
        DropdownIndicator: null
    };

    const element = (
        <CreatableSelect classNamePrefix="multi-input" className={classNames('multi-input', classes)} {...input}
                         components={components}
                         inputValue={inputValue}
                         isClearable
                         isMulti
                         menuIsOpen={false}
                         onChange={handleChange}
                         onInputChange={handleInputChange}
                         onKeyDown={handleKeyDown}
                         placeholder={placeholderText}
                         value={value}
                         onBlur={addValue}/>);

    return (
        <div className={classNames("field-container", className)}>
            {label && <label> {label} {element} </label>}
            {!label && {element} }
            {infoMessage && <div className="info-message field-bottom-note">{infoMessage}</div>}
            {warningMessage && <div className="warning-message field-bottom-note">{warningMessage}</div>}
            {touched && extractError(error) && <div className="form-error is-visible" title={extractError(error)}>{extractError(error)}</div>}
        </div>);
};

export const DatePicker = (props) => {
    const {input, meta: {touched, error}, placeholder = "Select a date", isDisabled = false, disabledTitle, minDatePossible, minDate, maxDate} = props;

    const defaultDate = minDate || maxDate || new Date();

    const [openedDate, setOpenedDate] = useState((input && input.value) || defaultDate ); //to control the month that is displayed
    const [isOpened, setIsOpened] = useState(false); //to control the year navigation buttons display

    const handleChange = (date) => {
        setOpenedDate(date ? date : defaultDate);
        if (input) input.onChange(date);
    };

    const increaseYear = (count, isDisabled) => {
        if (!openedDate) return;
        if (isDisabled) return;
        const newDate = moment(openedDate).add(count, 'year');
        const newDateFormatted = new Date(newDate);
        setOpenedDate(newDateFormatted);
    };

    const handleClickOutside = () => {
        setIsOpened(false);
    };

    const arrowShouldBeDisabled = (limitDate, openedDate) => {
        const limit = moment(limitDate);
        const opened = moment(openedDate);
        const monthsDiff = limit.diff(opened, 'months');
        const monthsDiffAbs = Math.abs(monthsDiff);
        return monthsDiffAbs < 12 ;
    };

    const isLeftArrowDisabled = arrowShouldBeDisabled(minDate || minDatePossible, openedDate);
    const isRightArrowDisabled = maxDate ? arrowShouldBeDisabled(maxDate, openedDate) : false;

    const customHeader = ({date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => (
        <div className="calendar-custom-header">
            <button type="button" onClick={(e)=>{e.preventDefault(); decreaseMonth();}} disabled={prevMonthButtonDisabled}>
                <span><ArrowLeftSingleIcon/></span>
            </button>
            <div className="date">{moment(date).format('MMMM YYYY')}</div>
            <button type="button" onClick={(e)=>{e.preventDefault(); increaseMonth()}} disabled={nextMonthButtonDisabled}>
                <span><ArrowRightSingleIcon/></span>
            </button>
        </div>
    );

    return (
        <div className={classNames("date-picker", isOpened ? "active" : "")} title={isDisabled ? disabledTitle : ''}>
            <div className={isDisabled ? "disable-change" : ""}>
                <ReactDatePicker {...input}
                                 id={input.name}
                                 selected={isDisabled ? undefined : (input && input.value) || defaultDate}
                                 onChange={handleChange}
                                 onBlur={() => { setIsOpened(false); input.onBlur(input.value) }}
                                 onFocus={()=>{setIsOpened(true);}}
                                 onClickOutside={handleClickOutside}
                                 onMonthChange={(date) => {setOpenedDate(date);} }
                                 openToDate={openedDate}
                                 placeholderText={placeholder}
                                 minDate={minDate || minDatePossible}
                                 maxDate={maxDate}
                                 shouldCloseOnSelect
                                 isClearable={!isDisabled}
                                 disabled={isDisabled}
                                 disabledKeyboardNavigation
                                 showDisabledMonthNavigation
                                 renderCustomHeader={customHeader}>
                    {isOpened &&
                    <div className="year-navigation-overlay">
                        <button type="button" onClick={()=> increaseYear(-1, isLeftArrowDisabled)} className={classNames("nav-button left", isLeftArrowDisabled ? 'disabled' : '')}>
                            <span><ArrowLeftDoubleIcon/></span>
                        </button>
                        <button type="button" onClick={()=> increaseYear(1, isRightArrowDisabled)} className={classNames("nav-button right", isRightArrowDisabled ? 'disabled' : '')}>
                            <span><ArrowRightDoubleIcon/></span>
                        </button>
                    </div>}
                </ReactDatePicker>
            </div>

            <label htmlFor={input.name} className={classNames("calendar-label", {"disable-change": isDisabled})}>
                <div className={classNames("calendar-icon", isOpened ? "active" : "")} disabled={isDisabled}>
                   <span><CalendarIcon/></span>
                </div>
            </label>
            {touched && extractError(error) && <div className="form-error is-visible" title={extractError(error)}>{extractError(error)}</div>}
        </div>)

};
