import React, {useState, useCallback, useMemo, useEffect} from 'react';
import CardsContainer from "./../../cards/CardsContainer";
import {Popover, Button} from '@netapp/shared-components';
import {
    FM_AGENT,
    SELECTED_PARAMS_DATA_BROKER_GROUP_ID,
    SELECTED_PARAMS_DATA_BROKER_GROUP_ID_TARGET,
    DATA_BROKER_ENCRYPTION_TYPES
} from './../../../consts';
import classNames from "classnames";
import ButtonsPanel from './../../widgets/ButtonsPanel';
import {find, without, filter} from 'lodash'
import Notice from "./../../notifications/Notice";

import {getCMIntegrationInfo} from "./../../../utils/wizard-utils";
import GroupCard from "./../../tabs/manageDataBrokers/GroupCard";

import "./dataBrokerStepNew.scss";
import WizardStepHeader from "./WizardStepHeaderNew";
import { useDispatch } from 'react-redux';
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';

const getActiveDataBrokers = (dataBrokers) => {
    const activeDataBrokers = filter(dataBrokers, {isReady: true})
    return activeDataBrokers.length;
}

const DataBrokersNew = props => {
    const [selectedId, setSelectedId] = useState(null);
    const dispatch = useDispatch();

    const {_wizard, routing, data, isTargetStepFromSameSourceType, history, startDataBrokerWizard, shouldDisplayPortBool, isAwsAccessRequired, isGcpAccessRequired, up,
        continueToNextStep, updateSelectedParams} = props;
    const {selectedWizardParams, wizardTypeForSystemFlow, isSecure, wizardInfo} = _wizard;
    const {selectedDataBroker, selectedDataBrokerTarget} = selectedWizardParams || {};
    const {clearUrlParamsIfChanged} = props;
    const theRelevantDataBroker = isTargetStepFromSameSourceType ? selectedDataBrokerTarget : selectedDataBroker;
    const error = 'In order to continue to the next step you should select a Data Broker Group with an Active Data Broker';

    const setSelectedDataBrokerGroup = useCallback((id) => {
        if (selectedId !== id) {
            setSelectedId(id);
            clearNotifications()(dispatch);
        }
    }, [dispatch, selectedId]);

    useEffect(() => {
        if (theRelevantDataBroker && !selectedId) {
            setSelectedDataBrokerGroup(theRelevantDataBroker?.id);
        }
        //to auto-select a single broker if possible (only one group with active data broker and not disabled)
        else if (data.length === 1 && getActiveDataBrokers(data[0].dataBrokers)> 0 && !data[0].isDisabled) {
            setSelectedDataBrokerGroup(data[0].id)
        }
    }, [data, setSelectedDataBrokerGroup, theRelevantDataBroker, selectedId]);


    const handleContinue = useCallback(() => {
        if (!selectedId)
            addNotification({
                id: NOTIFICATION_CONSTS.UNIQUE_IDS.DATABROKER_NOTIFICATION,
                type: NOTIFICATION_CONSTS.TYPE.ERROR,
                children: error
            })(dispatch);
        else {
            //clearing everything if data broker changes
            const selectedParamName = isTargetStepFromSameSourceType ? SELECTED_PARAMS_DATA_BROKER_GROUP_ID_TARGET : SELECTED_PARAMS_DATA_BROKER_GROUP_ID;
            let paramsToClear =                 [
                "exportPath", "rootExport", "exportPathTarget", "rootExportTarget",
                "sharePath", "rootShare", "sharePathTarget", "rootShareTarget",
                "bucketPath", "s3BasedBucketPath", "s3BasedBucketPathTarget", "gcpBucketPath", "gcpBucketPathTarget",
                "azureBlobContainerPath", "azureBlobContainerPathTarget"
            ]

            //in integrated mode we chose the volume and we skip showExports so we want to keep the root exports and shares and not clear them
            const cmIntegrationInfo = getCMIntegrationInfo(wizardInfo);
            if (cmIntegrationInfo.source) {
               paramsToClear = without(paramsToClear,  "rootExport", "rootShare");
            }
            if (cmIntegrationInfo.target) {
               paramsToClear = without(paramsToClear,  "rootExportTarget", "rootShareTarget");
            }
            clearUrlParamsIfChanged(selectedParamName, selectedId, paramsToClear);
            clearNotifications()(dispatch);
            if (isTargetStepFromSameSourceType) {
                updateSelectedParams({dataBrokerGroupIdTarget: selectedId})
            }
            else {
                updateSelectedParams({dataBrokerGroupId: selectedId})
            }
            continueToNextStep();
        }
    }, [dispatch, selectedId, clearUrlParamsIfChanged, isTargetStepFromSameSourceType, wizardInfo, continueToNextStep, updateSelectedParams]);

    const disableButton = data.length === 1 && data[0].status === 'PROGRESS';
    const selectionOptions = {selector: setSelectedDataBrokerGroup, selectedId};
    const containerOptions = {singularTitle: FM_AGENT, pluralTitle: FM_AGENT + 's'};
    const additionalData = {shouldDisplayPort: shouldDisplayPortBool, isAwsAccessRequired, isGcpAccessRequired, inWizard: true};
    const showInitiatorNotice = useMemo(() => !!find(data, {encryptionType: DATA_BROKER_ENCRYPTION_TYPES.Initiator}), [data]);
    const noticeText = showInitiatorNotice ? `Data broker group with the “Initiator” label can’t be accelerated later on by adding additional data brokers to the group.` :
        (isSecure ? 'Data broker group for an encrypted relationship, can’t be accelerated later on by adding additional data brokers to the group.' : null);
    const title = isSecure
        ? `Select ${shouldDisplayPortBool ? "a Listener" : "an Initiator"} ${FM_AGENT}`
        : `Select a ${FM_AGENT}`;

    return (<div className="wizard-step data-mover-step manage-groups">
        <div className={classNames("scrollable-area", {up, down: !up})}>

            <WizardStepHeader
                _wizard={_wizard}
                routing={routing}
                history={history}
                title={title}
                rightAction={
                    <Popover
                        trigger="hover"
                        popoverClass="text-popover"
                        container={<Button variant="primary" className="add-broker-button" onClick={startDataBrokerWizard}>+</Button>}>
                    Add a {FM_AGENT}
                    </Popover>}
            />
            {noticeText && <Notice>{noticeText}</Notice>}
            {data && <CardsContainer containerOptions={containerOptions} selectionOptions={selectionOptions}
                                     className="data-brokers" customClass="in-wizard"
                                     Card={GroupCard} data={data}
                                     additionalData={additionalData} wizardType={wizardTypeForSystemFlow}
            />}
        </div>

        <ButtonsPanel
            variant="white"
            type="button"
            onClick={handleContinue}
            disable={disableButton}
            text="Continue"/>
    </div>
    )
};

export default DataBrokersNew;

