import React from "react";
import ButtonsPanel from '../widgets/ButtonsPanel';
import "./providerLoginLayout.scss";

const ProviderLoginLayout = ({providerIcon, handleClick, submitting, title, subtitle, children}) =>
    (<div className="data-broker-wizard-step provider-login-layout">

        <div className="title">{providerIcon} {title}</div>
        {subtitle && subtitle}

        <div className="content-area">
            {children}
        </div>

        <ButtonsPanel
            variant="transparent"
            type="button"
            onClick={handleClick}
            text="Continue"
            submitting={submitting}/>
    </div>);

export default ProviderLoginLayout;
