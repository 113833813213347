import React, { useCallback, useEffect, useState } from 'react';
import {
    addNewBucket,
    listGCPBucketFolders,
    showGCPBuckets,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
    gcpAdditionalStorageSettingsStep,
    gcpSelectStep
} from '../../../store/wizard/action_creators';
import { connect, useDispatch, useSelector } from 'react-redux';
import TagsPopover from "../../widgets/TagsPopover";
import ShowObjectStorageStepTemplate from "./ShowObjectStorageStepTemplateNew"
import {
    DEFAULT_GCP_STORAGE_CLASS, END_POINT_NAMES_MAP,
    GCP_BUCKET_PERMISSIONS_DOCUMENTATION, GCP_STORAGE_CLASS_OPTIONS
} from "../../../consts";
import _ from 'lodash';
import { getPropFromState } from '../../../utils/mapStateToPropsUtils';
import AdditionalSettingsUI from "./additionalSettings/AdditionalSettingsUINew";
import { ReactComponent as GcpIcon } from "../../../assets/svgs/gcp-no-circle.svg";
import { supportAclCopy } from "../../../utils/relationshipUtils";
import AclSelectionFinalForm from "./AclSelectionFinalFormNew";
import { ReactComponent as XIcon } from "../../../assets/svgs/x.svg";
import { getCredentialsByType } from "../../../utils/sync-utils";
import { getWizardParamsFromWizardState } from "../../../utils/mapStateToPropsUtils";
import useStepinize from "../useStepinize";
import { useTranslation } from 'react-i18next';
import { setTargetGcpBucketEncryption } from '../../../syncNew/store/slices/wizard.slice';
import { useRequestListGcpKmsKeysProxy } from '../../../syncNew/api/groupApi.slice';
import { wizardSelector } from '../../../syncNew/store/selectors/wizard.selector';

require("./showObjectStorageStepNew.scss");

const innerColumns = (row) => {
    const { region, tags } = row;
    const regionDisplay = _.get(region, 'name') ? `${region.displayName}|${region.name}` : "";
    return region && tags ? [
        <td className="path" key="path" width="30%">
            <div className="text" title={row.path}>{row.path}</div>
            <div className="title">Name</div>
        </td>,
        <td className="region" key="region">
            {regionDisplay && <div className="text">{regionDisplay}</div>}
            {regionDisplay && <div className="title">Region</div>}
        </td>,
        <TagsPopover tags={tags} inTable={true} leftAdjust={-470} key="tags" />,
    ] : [<td className="path" title={row.path} key="path">{row.path}</td>];
}

const loading = () => {
    return { text: "Buckets", icon: require("../../../assets/svgs/gcp-default.svg") }
}

const stepId = ({ isTargetStepFromSameSourceType }) => {
    return isTargetStepFromSameSourceType ? 'targetGcpBuckets' : 'gcpBuckets';
};

const fetchGCPBuckets = (props) => props.showGCPBuckets(props._wizard.selectedWizardParams.selectedDataBroker.id);

const fetchData = (props) => {
    fetchGCPBuckets(props);
};
const shouldFetch = (props) => {
    const { _showGCPBuckets, _wizard: { selectedWizardParams: { selectedDataBroker } } } = props;
    const showGCPBuckets = _showGCPBuckets[_.get(selectedDataBroker, 'id')];
    return !showGCPBuckets || showGCPBuckets.failedRetrieve;
};

const isFetching = (props) => {
    const { _showGCPBuckets, _wizard: { selectedWizardParams: { selectedDataBroker } } } = props;
    const gcpStorageBasedOnDataBroker = _showGCPBuckets[selectedDataBroker.id];
    return !gcpStorageBasedOnDataBroker || gcpStorageBasedOnDataBroker.inProgress || ((!gcpStorageBasedOnDataBroker.data || gcpStorageBasedOnDataBroker.data.length === 0) && !gcpStorageBasedOnDataBroker.retrievedAll);
};


const ShowGCPBucketsStep = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [encryptionKey, setEncryptionKey] = useState();
    const [encryptionOptions, setEncryptionOptions] = useState(null);
    const [googleEncryptType, setGoogleEncryptType] = useState();
    const { LoadingComp, moreStepInfo } = useStepinize({ props, fetchData, shouldFetch, loading, stepId })
    const { name: targetGcpBucket } = useSelector(wizardSelector.targetGcpBucket);
    const gcpEncryptOptions = useSelector(state => wizardSelector.targetGcpEncryptOptions(state, targetGcpBucket));
    const { isTargetStepFromSameSourceType, isSourceStep } = moreStepInfo;
    const { gcpSelectStep, gcpAdditionalStorageSettingsStep, listGCPBucketFolders } = props;
    const {
        clearFoldersCache, _showGCPBuckets,
        _ui: { gcp },
        _wizard: {
            protocols, wizardInfo: { source, target },
            selectedWizardParams: { selectedGCPBucketPath, selectedGCPBucketPathTarget, selectedDataBroker, selectedGcpStorageClass }
        },
        state: { dataBrokerGroupId }
    } = props;
    const [gcpStorageClass, setGcpStorageClass] = useState(selectedGcpStorageClass)
    const [copyAcl] = useState(() => {
        const { copyAcl } = getCredentialsByType("gcp", selectedGCPBucketPath) || { copyAcl: false };
        return copyAcl;
    })

    useRequestListGcpKmsKeysProxy(dataBrokerGroupId, googleEncryptType);

    useEffect(() => {
        if (gcpEncryptOptions) {
            const keys = gcpEncryptOptions.keys.map(key => {
                const { isEnabled, name } = key;

                return {
                    label: name.split('/').reverse()[0],
                    value: name,
                    isDisabled: !isEnabled
                }
            });

            setEncryptionOptions(keys);
        } else {
            setEncryptionOptions(null);
        }
    }, [gcpEncryptOptions])

    const containerOptions = {
        singularTitle: "Bucket",
        pluralTitle: "Buckets",
        itemIdentifier: "actualPath",
        rootName: "All Buckets",
        targetName: "Define Target Folder",
        directoriesName: "Folders",
        directoryName: "folder",
        icon: require("../../../assets/svgs/bucket-icon.svg"),
        nameForSetupStep: "Bucket Setup",
    };
    const step = gcp && gcp.step && gcp.step === "additionalSettings" ? "secondStep" : "select";

    const callListGCPBucketFoldersAction = useCallback((bucket, path, groupId) => {
        listGCPBucketFolders(bucket, path, groupId);
    }, [listGCPBucketFolders])

    const returnToBucketsStep = () => {
        setGcpStorageClass(DEFAULT_GCP_STORAGE_CLASS);
        gcpSelectStep();
    }

    const changeStorageClassSelection = (selectedOptions) => {
        setGcpStorageClass(selectedOptions.value);
    }

    const getSecondStepUi = (step, bucketPath, formValues, form, { _wizard: { protocols, wizardInfo: { source, target } }, isSourceStep, isTargetStepFromSameSourceType }) => {
        if (!bucketPath) return <div />;
        if (!isSourceStep) {
            const i18DefaultPath = "sync.wizard.encryptionSetting";
            const googleEncryptSettings = [
                {
                    encryptOption: {
                        label: t(`${i18DefaultPath}.google.options.default`),
                        value: 'default'
                    }
                },
                {
                    encryptOption: {
                        label: t(`${i18DefaultPath}.google.options.custom`),
                        value: 'custom'
                    },
                    settings: {
                        title: t(`${i18DefaultPath}.google.optionsTitle`),
                        placeholder: t(`${i18DefaultPath}.google.custom.placeholder`),
                        validationError: encryptionKey === false ? t(`${i18DefaultPath}.google.custom.validationError`) : null
                    }
                },
                {
                    encryptOption: {
                        label: t(`${i18DefaultPath}.google.options.managed`),
                        value: 'managed'
                    },
                    settings: {
                        title: t(`${i18DefaultPath}.google.optionsTitle`),
                        options: encryptionOptions
                    }
                }]

            const validateGoogleCustomKey = (encryption) => {
                const { encryptionKey, encryptionType } = encryption;
                const regex = /^projects\/[^/]+\/locations\/[^/]+\/keyRings\/[^/]+\/cryptoKeys\/[^/]+$/;

                if (encryptionType === 'default' || regex.test(encryptionKey)) {
                    setEncryptionKey(encryptionKey);
                    dispatch(setTargetGcpBucketEncryption({
                        ...encryption,
                        isValid: true
                    }))
                } else {
                    setEncryptionKey(false);
                    dispatch(setTargetGcpBucketEncryption({
                        isValid: false
                    }))
                }
            }

            return <AdditionalSettingsUI step={step} bucketPath={bucketPath}
                isSourceStep={props._wizard.isSourceStep}
                handleClose={returnToBucketsStep}
                selectedOption={gcpStorageClass || DEFAULT_GCP_STORAGE_CLASS}
                selectionOptions={GCP_STORAGE_CLASS_OPTIONS}
                handleOptionSelection={changeStorageClassSelection}
                title="Selected GCP Bucket"
                icon={<GcpIcon />}
                optionsTitle="GCP Storage Class"
                form={form}
                formValues={formValues}
                encryptionSetting={{
                    isShowEncryption: true,
                    platformName: 'Google',
                    data: googleEncryptSettings,
                    validator: validateGoogleCustomKey,
                    onEncryptTypeChanged: setGoogleEncryptType
                }}
            />
        } else {
            const showACL = supportAclCopy(protocols, source, target) && !isTargetStepFromSameSourceType;
            return showACL ?
                (
                    <div className="bucket-setup">
                        <div className="bucket-info">
                            <div className="title">
                                Selected GCP Bucket:
                            </div>
                            <div className="bucket-name-container">
                                <div className="bucket-name">
                                    <GcpIcon />
                                    <div>{bucketPath}</div>
                                </div>
                                <div className="close-step" onClick={() => returnToBucketsStep(form)}>
                                    <XIcon />
                                </div>
                            </div>
                        </div>
                        <div className="setup-container">
                            <AclSelectionFinalForm />
                        </div>
                    </div>
                ) : <div />

        }
    }

    const setAdditionalSettings = (step) => {
        if (step === "select") {
            const showACL = supportAclCopy(protocols, source, target) && isSourceStep;
            if (!isSourceStep || showACL) {
                gcpAdditionalStorageSettingsStep();
                return false; //don't progress to next wizard step, stay for the second step ui
            }
        }
        return true; //continue to the next wizard step
    }


    const stepOptions = {
        selectedBaseObject: isTargetStepFromSameSourceType ? selectedGCPBucketPathTarget : selectedGCPBucketPath,
        title: `Select a ${isSourceStep ? "Source" : "Target"} Bucket`,
        failedMessage: "Unable to retrieve buckets",
        notFoundMessage: "No buckets were found",
        bottomMessage: `in your ${END_POINT_NAMES_MAP.GCP}`,
        storage: _showGCPBuckets,
        getStorageByBrokerId: true,
        listSubFoldersAction: callListGCPBucketFoldersAction,
        storageObjectName: "Bucket",
        storageObjectType: END_POINT_NAMES_MAP.GCP,
        innerColumns,
        stepFormName: "gcpBucketsForm",
        containerOptions,
        supportAddStorageObject: {
            accountPrefix: "GCP",
            bucketPrefix: "GCP",
            requirementsUrl: GCP_BUCKET_PERMISSIONS_DOCUMENTATION,
            validatorRules: ['required', 'min:3', 'max:63', 'regex:/^[a-z0-9][a-z0-9_-]{1,61}[a-z0-9]$/'],
            cacheName: "_showGCPBuckets"
        },
        innerPathBeginWithSlash: true,
        getSecondStepUi: getSecondStepUi,
        updateStepInfo: setAdditionalSettings,
        currentStep: step,
        extraUrlParam: gcpStorageClass || DEFAULT_GCP_STORAGE_CLASS,
        resetToFirstStepAction: gcpSelectStep,
        refreshStep: () => {
            clearFoldersCache({ cacheName: '_showGCPBuckets', storageKey: selectedDataBroker.id });
            fetchGCPBuckets(props)
        },
        initialFormValues: { blobTier: gcpStorageClass, copyAcl },
        getSelectedParams: (bucket) => {
            const paramName = isTargetStepFromSameSourceType ? 'gcpBucketPathTarget' : 'gcpBucketPath';
            return { [paramName]: bucket }
        },
        getExtraUrlParamsNew: () => {
            const paramName = isTargetStepFromSameSourceType ? 'gcpStorageClassTarget' : 'gcpStorageClass'
            return { [paramName]: gcpStorageClass || DEFAULT_GCP_STORAGE_CLASS }
        },
    };

    return isFetching({ ...props, ...moreStepInfo }) ? LoadingComp : <ShowObjectStorageStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo} />;
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showGCPBuckets: getPropFromState(state, "_showGCPBuckets", {}),
        _ui: { ...state.syncReducer._ui, ...state.global._ui },
        routing: state.router,
    }
};

const mapDispatchToProps = {
    addNewBucket,
    listGCPBucketFolders,
    showGCPBuckets,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
    gcpAdditionalStorageSettingsStep,
    gcpSelectStep
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowGCPBucketsStep);
