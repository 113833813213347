import React from 'react';
import classNames from "classnames";
require("./currentLicenseSection.scss");

const CurrentLicenseSection = ({licenseSettings: {data: {freeTrial, existingRelationships, reservedRelationships, totalReservedRelationships, chargedRelationships, noCharge} }, subscribed}) => {

    const hasLicense = totalReservedRelationships > 0;

    // if trial has ended and user has no license and not subscribed and no relationships - don't show counters
    if (!freeTrial && !hasLicense && !subscribed && chargedRelationships === 0) {
        return <div/>;
    }

    return (
        <div className="current-license-section row">
            {/* show total if on free trial OR free trial ended and user has both types of relationships */}
            {(noCharge || freeTrial || (reservedRelationships > 0 && chargedRelationships > 0)) &&
            <div className="relationship-count">
                <span className="count">{existingRelationships}</span><br/>
                <span>{freeTrial? 'Free Trial':'Total'} Relationships</span>
            </div>}
            {/* show BYOL relationships if free trial ended and user has a license */}
            {!noCharge && !freeTrial && hasLicense &&
            <div className="relationship-count">
                <span className="count">{`${reservedRelationships} / ${totalReservedRelationships}`}</span><br/>
                <span>BYOL Relationships</span>
            </div>}
            {/* show marketplace relationships if free trial ended and user has marketplace relationships OR no licenses (and 0 marketplace relationships) */}
            {!noCharge && !freeTrial && (chargedRelationships > 0 || !hasLicense) &&
            <div className={classNames("relationship-count", {warning: !freeTrial && !subscribed})}>
                <span className="count">{chargedRelationships}</span><br/>
                <span>{subscribed? 'Marketplace-Charged' : 'Exceeded'} Relationships</span>
            </div>}
        </div>
    );
};

export default CurrentLicenseSection;