import React, { BaseSyntheticEvent, FunctionComponent, useEffect, useMemo, useState } from "react";
import './permissionsStep.scss';
import { Typography } from "@netapp/design-system";
// import { Trans, useTranslation } from "react-i18next";
//@ts-ignore
import { TextInput } from '@netapp/shared-components';
import ButtonsPanel from "../../buttonsPanel/buttonsPanel";
import { store } from "../../../../store/store";

interface PermissionStepFormProps {
    awsAccessKey: string,
    awsSecretKey: string
}

interface PermissionsStepProps {
    initialValues: PermissionStepFormProps,
    dataBroker?: any,
    onContinue: (args: PermissionStepFormProps) => void
}

const PermissionsStep: FunctionComponent<PermissionsStepProps> = ({ initialValues, dataBroker, onContinue }) => {
    // const i18DefaultPath = "sync.dataBrokerWizard.steps.permissions";
    // const { t } = useTranslation();

    const { getState } = store;
    const { syncReducer: { _dataBrokersGroups } } = getState();
    const { data: dataGroups } = _dataBrokersGroups || {}
 
    const { awsAccessKey: initialAccessKey, awsSecretKey: initialSecretKey } = initialValues;
    const [awsAccessKey, setAwsAccessKey] = useState(initialAccessKey || '');
    const [awsSecretKey, setAwsSecretKey] = useState(initialSecretKey || '');
    const [isLoading, setIsLoading] = useState(false);
    const [valid, setValid] = useState(true);

    // const enter = t(`sync.actions.enter`);

    useEffect(() => {
        if (!awsAccessKey && !awsSecretKey) setValid(true);
    }, [awsAccessKey, awsSecretKey]);

    const isPermissionMandatory = useMemo<boolean>(() => {
        return dataGroups && dataGroups.some((group: any) => {
            const { dataBrokers } = group;
            const { id: dataBrokerId, groupId } = dataBroker;
            return group.id === groupId && dataBrokers.some((databroker: any) => {
                const { placement, id } = databroker;
                return !!placement?.awsAccountId && dataBrokerId !== id;
            });
        })
    }, [dataGroups, dataBroker]);

    const isValid = () => {
        let valid;
        if (isPermissionMandatory) {
            valid = awsAccessKey && awsSecretKey;
        } else {
            valid = (awsAccessKey && awsSecretKey) || (!awsAccessKey && !awsSecretKey);
        }

        setValid(!!valid);
        return !!valid
    }

    return (
        <div className="permissionsStep">
            <div className="permissionStepHeader">
                <Typography variant="Regular_20" className="permissionsTitle">
                    {/* <Trans i18nKey={`${i18DefaultPath}.title`}
                        components={{ italic: <i />, bold: <strong />, break: <br /> }} /> */}
                    <strong>S3 premissions</strong>
                    {!isPermissionMandatory && <i> (optional)</i>}
                </Typography>
                <Typography variant="Regular_14">
                    {/* {t(`${i18DefaultPath}.subTitle`)} */}
                    Please provide your AWS key so we can create the data broker on you behalf.
                </Typography>
            </div>
            <div className="stepForm">
                <TextInput
                    value={awsAccessKey}
                    label='AWS access key'
                    placeholder='Enter AWS access key'
                    // label={t(`${i18DefaultPath}.awsAccessKey`)}
                    // placeholder={`${enter} ${t(`${i18DefaultPath}.awsAccessKey`)}`}
                    onChange={(event: BaseSyntheticEvent) => setAwsAccessKey(event.target.value)}
                    error={!valid && !awsAccessKey ? 'The access key field is required.' : undefined} />
                <TextInput
                    value={awsSecretKey}
                    type='password'
                    label='AWS secret key'
                    placeholder='Enter AWS secret key'
                    // label={t(`${i18DefaultPath}.awsSecretKey`)}
                    // placeholder={`${enter} ${t(`${i18DefaultPath}.awsSecretKey`)}`}
                    onChange={(event: BaseSyntheticEvent) => setAwsSecretKey(event.target.value)}
                    error={!valid && !awsSecretKey ? 'The secret key field is required.' : undefined} />
            </div>
            <ButtonsPanel isOldSyncStyle={true} buttons={[{
                children: 'Continue',
                // children: t('sync.actions.continue'),
                onClick: () => {
                    if (isValid()) {
                        onContinue({
                            awsAccessKey,
                            awsSecretKey
                        })

                        setIsLoading(true);
                    }
                },
                isLoading
            }]} />
        </div>
    )
}

export default PermissionsStep;