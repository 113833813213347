import React, {useCallback} from 'react';
import { store } from '../../../store/store';
import {
    showExports,
    addNewExport,
    listFolders,
    clearFoldersCache,
    resetCacheForReload,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from '../../../store/wizard/action_creators'
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import ShowDirectoriesStepTemplate from "./ShowDirectoriesStepTemplateNew";
import {getIsIntegratedStep} from '../../../utils/wizard-utils';
import {isIntegratedCmProvider} from "../../../utils/cm-integration-utils";
import useStepinize, { buildMoreStepInfo } from "../useStepinize";
import {getWizardParamsFromWizardState, getPropFromState} from "../../../utils/mapStateToPropsUtils";
import ManualDirectoryDialog from "../../systemFlow/ManualDirectoryDialog";
import { CASH_KEYS } from '../../../consts';

const innerColumns = (row) => {
    //SER-306 SER- Wrong details in select directory screen - don't show information at all
    return [<td className="path" title={row.path} key="path">{row.path}</td>];
};

const loading = () => {
    return {text: "Directories", icon: require("./../../../assets/svgs/directory-default.svg")}
}

const sharesKey = (isTargetStepFromSameSourceType, selectedNFSTarget, selectedNFS, selectedDataBroker) => {
    const encodedCIFS = isTargetStepFromSameSourceType ? selectedNFSTarget : selectedNFS;
    return `${encodedCIFS}-${selectedDataBroker.id}`;
}

const fetchData = (props) => {
    const { dispatch } = store;
    const {endpointObj, isTargetStepFromSameSourceType, _wizard: {selectedWizardParams: {selectedNFSTarget, selectedNFS,
        selectedDataBrokerTarget, selectedDataBroker}}} = props;
    const nfsProp = isTargetStepFromSameSourceType ? selectedNFSTarget : selectedNFS;
    const dataBrokerProp = isTargetStepFromSameSourceType && selectedDataBrokerTarget ? selectedDataBrokerTarget : selectedDataBroker;
    const isEFS = endpointObj.provider === "efs";

    const storageKey = sharesKey(isTargetStepFromSameSourceType, selectedNFSTarget, selectedNFS, selectedDataBroker);
    resetCacheForReload({ cacheName: CASH_KEYS._SHOW_EXPORTS, storageKey })(dispatch);

    return props.showExports(nfsProp, isEFS, dataBrokerProp.id);
};

const shouldFetch = (props) => {
    const {
        _showExports, isTargetStepFromSameSourceType,
        _wizard: {isSecure, selectedWizardParams: {selectedNFSTarget, selectedNFS, selectedDataBroker, selectedDataBrokerTarget}}
    } = props;
    const isUsingDataBrokerTarget = isSecure && isTargetStepFromSameSourceType;
    const dataBrokerGroup = isUsingDataBrokerTarget ? selectedDataBrokerTarget : selectedDataBroker;
    const showExportsKey = sharesKey(isTargetStepFromSameSourceType, selectedNFSTarget, selectedNFS, dataBrokerGroup);
    const showExportsForNFS = _showExports[showExportsKey];
    return !showExportsForNFS || showExportsForNFS.failedRetrieve;
};

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetDirectories' : 'directories';
};

const isFetching = (props) => {
    const {_showExports, _wizard: {isSecure, selectedWizardParams: {selectedNFS, selectedNFSTarget, selectedDataBroker, selectedDataBrokerTarget}}, isTargetStepFromSameSourceType} = props;
    const isUsingDataBrokerTarget = isSecure && isTargetStepFromSameSourceType;
    const dataBrokerGroup = isUsingDataBrokerTarget ? selectedDataBrokerTarget : selectedDataBroker;
    const showExportsKey = sharesKey(isTargetStepFromSameSourceType, selectedNFSTarget, selectedNFS, dataBrokerGroup);
    const showExportsForNFS = _showExports[showExportsKey];
    if (!showExportsForNFS) return true;
    return (showExportsForNFS.inProgress) || ((!showExportsForNFS.data || showExportsForNFS.data.length === 0) && !showExportsForNFS.retrievedAll);
};


const ShowExportsStep = (props) =>{
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {isTargetStepFromSameSourceType,isSourceStep, endpointObj} = moreStepInfo;

    const {
        addNewExport, clearFoldersCache, _showExports, listFolders, _wizard: {isSecure, selectedWizardParams}} = props;
    const {selectedExport, selectedExportTarget, selectedRootExportTarget, selectedRootExport,
        selectedNFSTarget, selectedNFS, nfsVersion, targetNfsVersion, selectedDataBroker, selectedDataBrokerTarget} = selectedWizardParams;
    const isUsingDataBrokerTarget = isSecure && isTargetStepFromSameSourceType;
    const actualDataBroker = isUsingDataBrokerTarget ? selectedDataBrokerTarget: selectedDataBroker;
    const actualServer = isTargetStepFromSameSourceType ? selectedNFSTarget : selectedNFS;
    const actualNfsVersion = isTargetStepFromSameSourceType ? targetNfsVersion : nfsVersion;
    const storageKey = `${actualServer}-${actualDataBroker.id}`;

    const addManualExport = useCallback((values) => {
        addNewExport(values.directoryName, actualServer, actualDataBroker.id, actualDataBroker.id);
    }, [actualServer, actualDataBroker, addNewExport]);

        const stepOptions = {
            containerOptions: {
                singularTitle: "Directory",
                pluralTitle: "Directories",
                itemIdentifier: "actualPath",
                rootName: "All Directories",
                targetName: "Define Target Directory",
                directoriesName: "Directories",
                directoryName: "Directory",
                icon: require(`./../../../assets/svgs/directory-no-circle.svg`)
            },
            failedMessage: "Unable to retrieve directories",
            notFoundMessage: "No directories were found",
            title: `Select a ${isSourceStep ? "Source" : "Target"} Directory`,
            selectedDirectory: selectedExport,
            selectedRootDirectory: selectedRootExport,
            selectedDirectoryTarget: selectedExportTarget,
            selectedRootDirectoryTarget: selectedRootExportTarget,
            isStaticRoot: getIsIntegratedStep( endpointObj),
            storage: _showExports,
            storageKey,
            listSubDirectoriesAction: listFolders,
            selectedServer: selectedNFS,
            selectedServerTarget: selectedNFSTarget,
            version: actualNfsVersion,
            additionalParam: {isEFS: endpointObj.provider === "efs"},
            stepFormName: "exportsForm",
            innerColumns,
            addManualOptions: {
                manualForm: ManualDirectoryDialog,
                directoryType: "export",
                addManualAction: addManualExport,
                addManualText: "Add Export Manually"
            },
            //no need to refresh step in integrated mode. we picked volume
            refreshStep: isIntegratedCmProvider(endpointObj.provider) ? undefined : () => {
                clearFoldersCache({cacheName:'_showExports',storageKey});
                const moreStepInfo = buildMoreStepInfo(props, stepId);
                fetchData({...props, ...moreStepInfo});
            },
            getSelectedParams: (fullPath, rootValue) => {
                const exportParam = isTargetStepFromSameSourceType ? 'exportPathTarget' : 'exportPath';
                const rootExportParam = isTargetStepFromSameSourceType ? 'rootExportTarget' : 'rootExport'
                const selectedParams = {
                    [exportParam]: fullPath,
                    [rootExportParam]: rootValue
                }
                return selectedParams;
            }
        };

        return isFetching({...props, ...moreStepInfo}) ? LoadingComp :<ShowDirectoriesStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showExports:  getPropFromState(state, CASH_KEYS._SHOW_EXPORTS, {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    showExports,
    addNewExport,
    listFolders,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

const ExportsForm = reduxForm({form: 'exportsForm'})(ShowExportsStep);
export default connect(mapStateToProps, mapDispatchToProps)(ExportsForm);
