import { rootSelector } from "./root.selector";

const getDeploy = rootSelector.deploy;

export const deploySelector = {
    deployments(state) {
        return getDeploy(state).deployments;
    },
    credentials(state) {
        return getDeploy(state).credentials;
    }
}