import React, { useCallback, useMemo, useState } from "react"
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { MenuPopover, CheckBox } from '@netapp/shared-components';
import { isFunction } from "lodash";
import { TooltipInfo } from '@netapp/shared-components';
import { useDialog } from "@netapp/shared-components";
import { ReactComponent as SortIcon } from "./../../assets/svgs/sort.svg";
import { ReactComponent as SortAscendingIcon } from "./../../assets/svgs/sort-ascending.svg";
import { ReactComponent as SortDescendingIcon } from "./../../assets/svgs/sort-descending.svg";
import FilterWidget from "../widgets/filter/FilterWidget";


export const HEADER_TYPES = {
    "ALL": "all",
    "FILTER": "filter",
    "TD": "td",
    "CHECKBOX": "checkbox"
};

const SelectableTable = (props) => {
    const { Table, menuItems, isMenuDisabled, data, selectionOptions, itemIdentifier, noAnimate, moreActions, useIndexForKey, onSelect = () => null } = props;
    const rowIdentifier = itemIdentifier || "id";
    const [state, setState] = useState({});
    const classes = classNames("standard", { menuable: !!menuItems, selectable: !!selectionOptions }, Table.className);
    const { setDialog } = useDialog();
    const [variant, setVariant] = useState();
    const [checkedRows, setCheckedRows] = useState({});
    const [numberOfSelectedRows, setNumberOfSelectedRows] = useState(0);


    const handleSort = useCallback((sortMethod, column) => {
        //if columns was not sorted yet, we start with ascending
        const ascending = state.sort && state.sort.column === column ? !state.sort.ascending : true;
        setState({ sort: { column, ascending } });
        sortMethod(column, ascending);
    }, [state.sort]);

    const setSelectedRows = (id, row, forceCheck) => {
        if (checkedRows[id] || forceCheck === false) {
            delete checkedRows[id];
            setNumberOfSelectedRows(numberOfSelectedRows - 1)
        } else {
            checkedRows[id] = row;
            setNumberOfSelectedRows(numberOfSelectedRows + 1)
        }

        setCheckedRows(checkedRows);
        onSelect(checkedRows);
    };

    const isAllSelected = useMemo(() => {
        return numberOfSelectedRows === data.length;
    }, [data.length, numberOfSelectedRows]);

    const selectAll = useCallback(() => {
        if (!isAllSelected) {
            data.forEach((row, index) => {
                const id = row[rowIdentifier] || index;
                checkedRows[id] = row;
            });
            setCheckedRows(checkedRows);
            setNumberOfSelectedRows(Object.keys(checkedRows).length);
            onSelect(checkedRows);
        } else {
            setCheckedRows({});
            setNumberOfSelectedRows(0);
            onSelect({});
        }

    }, [data, checkedRows, rowIdentifier, isAllSelected, onSelect])

    const headerElement = useMemo(() => {
        return Table.getTableHeader ? Table.getTableHeader().map((cell, index) => {
            const type = cell.type ? cell.type : HEADER_TYPES.TD;
            const sorted = state.sort && state.sort.column === cell.name;
            const sortedAscending = sorted && state.sort.ascending;

            const multiSelectTable = cell => {
                setVariant(HEADER_TYPES.CHECKBOX);

                return (
                    <div className="checkCell">
                        <CheckBox onClick={() => selectAll()} checked={isAllSelected} />
                    </div>
                )
            };

            return (
                <React.Fragment key={index}>
                    {type === HEADER_TYPES.ALL &&
                        <td key={index}
                            style={{ width: cell.width, maxWidth: cell.width }}
                            className={classNames(cell.className, "all-selector", { selected: cell.allOptions.allSelected })}
                            onClick={() => cell.allOptions.toggleAll()}>
                            <div />
                        </td>}
                    {type === HEADER_TYPES.FILTER &&
                        <td key={index} className={`${cell.className} filter-header`} style={{ width: cell.width, maxWidth: cell.width }}>
                            <FilterWidget title={cell.text} applyFilter={cell.filterOptions.filter}
                                filterName={cell.filterOptions.filterName} filters={cell.filterOptions.filterValues}
                                sort={state.sort} className="table" />
                        </td>}
                    {type === HEADER_TYPES.TD &&
                        <td key={index} className={cell.className} style={{ width: cell.width, maxWidth: cell.width }}>
                            {cell.info &&
                                <TooltipInfo>{cell.info.text}</TooltipInfo>}

                            <span className={classNames(cell.sort ? "sort" : "", cell.className, state.sort && state.sort.column === cell.name ? "sorted" : "")}>{cell.text}
                                {cell.sort && <button type="button" className="sort-button" onClick={() => handleSort(cell.sort, cell.name)}>
                                    {!sorted && <SortIcon />}
                                    {sorted && sortedAscending && <SortAscendingIcon />}
                                    {sorted && !sortedAscending && <SortDescendingIcon />}
                                </button>}</span>
                        </td>}
                    {type === HEADER_TYPES.CHECKBOX && multiSelectTable(cell)}
                </React.Fragment>
            )
        }) : null;
    }, [Table, handleSort, state.sort, selectAll, isAllSelected]);

    const d = data.map((row, index) => {
        const el = Table.renderRow(row, setDialog, moreActions);
        const disableMenu = isFunction(isMenuDisabled) ? isMenuDisabled(row) : false;
        const id = row[rowIdentifier] || index; //make sure there is an id
        const inProccess = (row.activity && row.activity.isBeingDeleted);
        return (
            <CSSTransition classNames="tr" timeout={200} key={useIndexForKey ? index : id}>
                <tr key={useIndexForKey ? index : id}
                    title={row.title}
                    onClick={() => !row.isDisabled && selectionOptions ? selectionOptions.selector(id) : ""}
                    className={classNames(el.props.className, { selected: selectionOptions && selectionOptions.selectedId === id, disabled: inProccess || row.isDisabled })}>
                    {variant === HEADER_TYPES.CHECKBOX && <td><CheckBox className='checkCell' checked={checkedRows[id] !== undefined} onChange={() => setSelectedRows(id, row)} /></td>}
                    {el.props.children}
                    {menuItems ?
                        <td className={classNames("table-menu", { "disabled": disableMenu || inProccess })}>
                            <MenuPopover
                                popoverClass="menu-popover"
                                triggerVariant="table"
                                menuItems={menuItems(row)}
                            />
                        </td>
                        :
                        null
                    }
                </tr>
            </CSSTransition>)
    });

    return (
        <div>
            <table className={classes}>
                {Table.renderHeader && Table.renderHeader()}

                {headerElement &&
                    <thead>
                        <tr>
                            {headerElement}
                        </tr>
                    </thead>}

                {!noAnimate && <TransitionGroup component="tbody">{d}</TransitionGroup>}
                {noAnimate && <tbody>{d}</tbody>}
            </table>
        </div>
    )
};

export default SelectableTable;
