import {useCallback} from 'react'
import {ReactComponent as SortDownIcon} from '../../assets/svgs/table-sorting-down.svg'
import {ReactComponent as SortUpIcon} from '../../assets/svgs/table-sorting-up.svg'
import {ReactComponent as SortNoneIcon} from '../../assets/svgs/table-sorting-none.svg'
import useState from 'react-usestateref'
import {isNil} from 'lodash'


import './sortWidget.scss'
import useRunOnceWhenTruthy from "../../hooks/useRunOnceWhenTruthy";

const SortWidget = ({sortFunc, sortInfo: {from, key}}) => {
    const configuration =  window.localStorage.getItem(from);
    const [sortDesc, updateSortDesc, sortDescRef] = useState(null);

    useRunOnceWhenTruthy(() => {
        //if last sort was kept in localStorage, use it
        const descVal =  configuration ? JSON.parse(configuration)[key] : null;
        if (!isNil(descVal)) {
            updateSortDesc(descVal);
            sortFunc(descVal) //update that it is should be sorted
        }
    }, configuration);

    const sortIcon = useCallback(() => {
        return isNil(sortDesc)? <SortNoneIcon/> : sortDescRef.current ? <SortDownIcon/> : <SortUpIcon/>
    }, [sortDesc, sortDescRef])


    return (
        <div className="sort-widget">Sort by name
            <div onClick={() => {
                updateSortDesc(sortDesc ? !sortDesc : true); //when clicking for the first time
                window.localStorage.setItem(from, JSON.stringify({[key]: sortDescRef.current}))
                sortFunc(sortDescRef.current);
            }} className="sort-icon">
                {sortIcon()}
            </div>
        </div>
    )
}

export default SortWidget;
