import React from 'react';
import {
    clearShowAction,
    clearUrlParamsIfChanged,
    getServersByType,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from '../../../store/wizard/action_creators';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {InputField, TextAreaField} from "../../_common_/forms/forms";
import { END_POINT_NAMES_MAP} from '../../../consts';
import Validator from 'validatorjs';
import ServerStepTemplate from "./ServerStepTemplateNew";
import {calculateStepId} from '../../../utils/sync-utils';
import {buildServersList, getPropFromState} from '../../../utils/mapStateToPropsUtils';
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
require('./boxStepNew.scss')

const rules = {
    appNme: ['required'],
    clientId: ['required'],
    clientSecret: ['required'],
    publicKeyId: ['required'],
    privateKey: ['required'],
    passphrase: ['required'],
    enterpriseId: ['required']
};

const validate = (values) => {
    const validator = new Validator(values, rules);
    validator.setAttributeNames({
        appName: 'Box app name',
        clientId: 'BOX client id',
        clientSecret: 'BOX client secret',
        publicKeyId: 'BOX public key id',
        privateKey: 'BOX private key',
        passphrase: 'BOX passphrase',
        enterpriseId: 'BOX enterprise id'
    });
    validator.passes();
    return validator.errors.all();
};

const SecondScreenUi = () => {
    return (<div className="credentials-container box">
        <div className="line">
            <Field name="clientId" autoFocus component={InputField} label="Client Id" type="text"/>
            <Field name="enterpriseId" component={InputField} label="Enterprise id" type="text"/>
        </div>
        <div className="credentials-title">Define BOX Credentials:</div>
        <div className="credentials-fields">
            <div className="left pane">
                <Field name="clientSecret" component={InputField} label="Client secret" type="password"/>
                <Field name="privateKey" component={TextAreaField} label="Private key" type="text"/>
            </div>
            <div className="right pane">
                <Field name="publicKeyId" component={InputField} label="Public key id" type="text"/>
                <Field name="passphrase" component={InputField} label="Passphrase" type="text"/>
            </div>
        </div>
    </div>)
};

const loading = ({endpointObj}) => {
    const serverType =endpointObj.provider;
    return {
        text: `${END_POINT_NAMES_MAP[serverType.toUpperCase()]}`,
        icon: require(`../../../assets/svgs/${serverType}-default.svg`)
    }
}

const stepId = ({_wizard: {wizardInfo}, isTargetStepFromSameSourceType}) => {
    return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "box");
};

const fetchData = (props) => {
    const {endpointObj, protocolAndProviderKey} = props;
    props.getServersByType(endpointObj.protocol, endpointObj.provider, protocolAndProviderKey);
};

const shouldFetch = (props) => {
    const {protocolAndProviderKey} = props;
    return (!props._servers || !props._servers[protocolAndProviderKey] || !props._servers[protocolAndProviderKey].succeeded);
};

const isFetching = (props) => {
    const {_servers, protocolAndProviderKey} = props;
    return _servers[protocolAndProviderKey].inProgress;
};

const BoxStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {endpointObj, protocolAndProviderKey, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;
    if (!props._wizard) return <div/>;
    const {_servers, _wizard, state, clearSelectedParams} = props;
    const {selectedWizardParams} = _wizard;
     const {selectedBoxAppName, selectedBoxAppNameTarget} = selectedWizardParams;

    const provider = endpointObj.provider;
    const selectedServer = isTargetStepFromSameSourceType ? selectedBoxAppNameTarget : selectedBoxAppName;
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["boxFolderTarget", "rootBoxFolderTarget"] : ["boxFolder", "rootBoxFolder"];

    const stepOptions = {
        storage: _servers[protocolAndProviderKey],
        server: selectedServer,
        serverSVG: `${provider}-default.svg`,
        serverType: "BOX",
        serverTitle: "BOX",
        selectType: "App",
        serverPlaceholder: "",
        title: `Select a BOX ${isSourceStep ? "Source" : "Target"}`,
        changeServerTitle: "Change BOX App",
        clearShowOptions: {keyToClear: ['_showBoxFolders', selectedServer]},
        validate,
        SecondScreenUi,
        stateParamsToClear,
        shouldGoToSecondScreen: () => true,
        getSelectedParams: (host) => {
            const hostParam = isTargetStepFromSameSourceType ? 'boxAppNameTarget' : 'boxAppName';
            if ((state[hostParam] && state[hostParam] !== host)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam] : host
            }
        }
    };

    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

let BoxStepForm = reduxForm({
    form: 'boxServer',
    validate,
    shouldError: () => true,
})(BoxStep);


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _servers: buildServersList(state),
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _accountInfo: state.global._accountInfo,
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getServersByType,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoxStepForm);


