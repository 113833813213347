import {useState, useRef, useEffect} from "react";
import {scrollToTop} from './../../../utils/wizard-utils';
import {saveStepCredentials, getCredentialsByType, removeCredentialsByType} from './../../../utils/sync-utils';
import _ from "lodash";
import useRunOnceWhenTruthy from "../../../hooks/useRunOnceWhenTruthy";

const useVolumeStep = (props) => {
    const {stepOptions, isTargetStepFromSameSourceType, continueToNextStep, updateSelectedParams, change, untouch,
        reset, getExtraPath = () => "", clearSelectedParams, stepType} = props;
    const {serverType, serverTitle, selectType,
        shouldGoToSecondScreen, server, selectedVersion, clearShowOptions, getSelectedParams, stateParamsToClear} = stepOptions;

    const [selectedIp, setSelectedIp] = useState(null);
    const [selectedVersionValue, setSelectedVersionValue] = useState(selectedVersion);
    const [error, setError] = useState(null);
    const [step, setStep] = useState("ip");
    const scrollArea = useRef();

    useEffect(() => {
        scrollToTop(scrollArea?.current)
    }, [step]);

    useRunOnceWhenTruthy(() => {
        if (selectedVersion){
            setSelectedVersionValue(selectedVersion);
        }
        if (server) {
            reset();
            change('serverName', server);
            setSelectedIp(server);
            setSelectedVersionValue(selectedVersion);
            if (shouldGoToSecondScreen(selectedVersionValue)) {
                const credentials = getCredentialsByType(serverType, server, isTargetStepFromSameSourceType);
                if (credentials) {
                    _.forEach(_.keys(credentials), (key) => change(key, credentials[key]));
                }
                if (stepType === "servers") {
                    //in case of "volumes", the root export url param is calculated in the table so need to render it anyway CS-5447
                    setStep("credentials");
                }
            }
        }
    }, selectedVersion || server);

    const clearError = () => {
        setError(null);
    };

    const submit = (values) => {
        if (!selectedIp) {
            setError(`In order to continue to the next step please select ${serverTitle} ${selectType}.`);
            return Promise.reject();
        }

        const localContinueToNextStep = () => {
            //keep the version always in local storage, even if target and we don't go through the credentials step so we will always have the version in storage for data sense
            const valuesWithVersion = _.cloneDeep(values);
            if (!values.version && selectedVersionValue) {
                valuesWithVersion.version = {label: selectedVersionValue, value: selectedVersionValue}
            }
            saveStepCredentials(serverType, selectedIp, valuesWithVersion, isTargetStepFromSameSourceType);

            clearError();
            //replace with updateSelectedParams - with the right params names.
            const selectedParams = {...getExtraPath(values), ...getSelectedParams(selectedIp, selectedVersionValue)}
            updateSelectedParams(selectedParams)
            continueToNextStep();
        };

        return Promise.resolve(true)
            .then(() => {
                if (step === 'credentials') {
                    if (!shouldGoToSecondScreen(selectedVersionValue)) {
                        //in case the user changed something in the 2nd screen that caused the condition to be falsy (e.g. version)
                        removeCredentialsByType(serverType.toLowerCase());
                        localContinueToNextStep();
                        return;
                    }
                    const cred = getCredentialsByType(serverType, selectedIp, isTargetStepFromSameSourceType);
                    const noCredentials = _.isEmpty(cred);
                    const credentialsChanged = !_.isEqual(values, cred);
                    if (noCredentials || credentialsChanged) {
                        saveStepCredentials(serverType, selectedIp, values, isTargetStepFromSameSourceType);
                        //force clear decoded params even if newValueFromState hasn't changed. since the credentials changed
                        if (credentialsChanged && !noCredentials) {
                            // in case credentials changed, make sure to force clear on parameters. unless there are no credentials yet, hence no need to clear
                            clearSelectedParams(stateParamsToClear);
                            if (clearShowOptions) props.clearShowAction(clearShowOptions.keyToClear);
                        }
                    }
                    localContinueToNextStep();
                    return;
                }
                if (step === 'ip' && shouldGoToSecondScreen(selectedVersionValue)) {
                    setStep('credentials');
                    return;
                }
                localContinueToNextStep();
            });
    };

    const resetCredentialsInput = () => {
        change("domain", "");
        change("username", "");
        change("password", "");
        change("connectionString", "");
        change("accessKey", "");
        change("secretKey", "");
        change("port", "");
        change("accessPoint", "");
        change("clientSecret", "");
        change("privateKey", "");
        change("publicKeyId", "");
        change("passphrase", "");
        change("enterpriseId", "");
        change("clientEmail", "");
        untouch("username");
        untouch("password");
        untouch("connectionString");
        untouch("secretKey");
        untouch("accessKey");
        untouch("port");
        untouch("accessPoint");
        untouch("clientSecret");
        untouch("privateKey");
        untouch("publicKeyId");
        untouch("passphrase");
        untouch("enterpriseId");
        untouch("clientEmail")
    };

    return {step, setStep, submit, scrollArea, selectedVersionValue, error, selectedIp, clearError, resetCredentialsInput, setSelectedIp, setSelectedVersionValue, setError};
};

export default useVolumeStep;
