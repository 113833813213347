import React from "react";
import {SWITCH} from "./../../utils/directives"
import ScrollArea from 'react-scrollbar';
import classNames from "classnames";
import {Popover} from '@netapp/shared-components';
import {ReactComponent as TagIcon} from "./../../assets/svgs/tag-icon.svg";
import {ReactComponent as InfoIcon} from "../../assets/svgs/info.svg";

require("./tagsPopover.scss");

const TagsPopover = ({tags, tagsType = "Tags", noTitleSmallResolution, withDiv, inTable})=> {
    const className = classNames(`text`,{'in-table': inTable, 'in-card-with-tags': !inTable && tags.length > 0 });
    const title = `${tagsType} ${tags.length > 0 ? `(${tags.length})` : ''}`;
    const inner = (
        <div className={className}><SWITCH on={tags && tags.length > 0}>
            <Popover popoverClass="tags-table" container={<button type="button" className="view-tags">
                {
                    inTable && <span className="isvg"><TagIcon/></span>
                }
                {
                    !inTable && <span className="isvg"><InfoIcon/></span>
                }
                {!noTitleSmallResolution && <span className={classNames("tags-title")}>{title}</span>}
                </button>
            } data-when={true}>
                <div className="popover-table-header">
                    <div>Key</div>
                    <div>Value</div>
                </div>
                <ScrollArea
                    style={{ maxHeight: 110 }}
                    className="popover-table"
                    contentClassName="content"
                    verticalScrollbarStyle={{borderRadius: 0, backgroundColor: "#fff", width: "5px", position: "relative"}}
                    verticalContainerStyle={{borderRadius: 0, opacity: 1, width: "5px"}}
                    horizontal={false}
                    smoothScrolling= {true}
                    minScrollSize={40}
                >
                    <div className="popover-table-content">
                        {tags.map(tag => {
                            const key = tag.Key ? tag.Key : tag.key;
                            const value = tag.Value ? tag.Value : tag.value;
                            return (<div className="table-row clearfix" key={key}>
                                <div title={key}>{key}</div>
                                <div title={value}>{value}</div>
                            </div>)
                        })}
                    </div>
                </ScrollArea>
            </Popover>
            <div data-when={false}>{inTable? "" : "No Tags"}</div>
        </SWITCH>
        </div>
    );

    return withDiv ?
        (
            <div className={inTable? "tags-in-table": "tags"} >
                {inner}
                <div className="title">&nbsp;</div>
            </div>
        ) :
        (
            <td className="tags">
                {inner}
                <div className="title">&nbsp;</div>
            </td>

        )
};

export default TagsPopover;

