import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Encryption, Gcp, KmsKey, Wizard } from "../store.types";

const SLICE_NAME = 'wizard';

export const initialState: Wizard = {
    step: {}
};

const initialStateGoogleBucket = (state: Wizard): Wizard => {
    const { step } = state;
    (step.target ??= { bucket: {} })

    return state;
}

const wizardSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        resetWizard(state) {
            return initialState;
        },
        setTargetGcpBucketEncryption(state, action: PayloadAction<Encryption | undefined>) {
            state = initialStateGoogleBucket(state);
            (state.step.target! as Gcp).bucket.encryption = action.payload;
        },
        setTargetGcpBucketName(state, action: PayloadAction<string>) {
            state = initialStateGoogleBucket(state);
            const { name } = state.step.target?.bucket!;

            //If selecting a deferent bucket than previous need to reset the selected ecryption
            //to reset the initial values of the encryption
            if (name !== action.payload) {
                state.step.target!.bucket!.encryption = undefined;
            }

            (state.step.target! as Gcp).bucket.name = action.payload;
        },
        setTargetGcpEncryptOptions(state, action: PayloadAction<KmsKey>) {
            const { bucket } = action.payload;
            state = initialStateGoogleBucket(state);
            let encryptOptions = (state.step.target! as Gcp).encryptOptions;
            encryptOptions = {
                ...encryptOptions,
                [bucket]: {
                    kmskey: action.payload
                }
            };

            (state.step.target! as Gcp).encryptOptions = encryptOptions;
        }
    }
});

export const { resetWizard, setTargetGcpBucketEncryption, setTargetGcpBucketName, setTargetGcpEncryptOptions } = wizardSlice.actions;

export default wizardSlice;