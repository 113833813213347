import React from 'react'
import { ReactComponent as ArrowIcon } from "./../../../assets/svgs/top-right-arrow-icon.svg";
import { useNavigate } from 'react-router-dom';
require("./reportErrorsStatus.scss");

const ReportErrorsStatus = ({ errorCount, lastReportId }) => {
    const navigate = useNavigate();

    return (
        <div className="report-error-status">
            {errorCount > 0 && <div className="red-circle" />}
            <div className="report-error-status-text">{errorCount > 0 ? errorCount : "None"}</div>
            {errorCount > 0 && <div className="report-error-link" onClick={() => navigate({
                pathname: `/report-errors/${lastReportId}`,
                search: window.location.search
            })}><ArrowIcon className="goto-error" /></div>}
        </div>
    )
}

export default ReportErrorsStatus;
