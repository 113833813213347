import get from 'lodash/get'
import each from 'lodash/each'
import first from 'lodash/first'


const thunk = (action) => (dispatch) => {
    dispatch(action);
    return action.payload;
};

export const create = (type, payload, status, selfErrorHandling = false, extra = {}) => {
    return thunk({
        type,
        payload,
        status,
        selfErrorHandling,
        extra
    });
};


export const createMultiple = (actionsArray) => {
    return thunkMultiple(actionsArray);
};

const thunkMultiple = (actionArray) => (dispatch) => {
    each(actionArray, (action) => {
        dispatch(action);
    });

    return get(first(actionArray), 'payload');
};
