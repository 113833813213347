import {KEYS_REG_EXP} from "../../../../../consts";
import Validator from 'validatorjs';


const rules = (fromDataSense) => {
    return {
        serverName: ['regex:/^[a-z0-9]+$/', 'max:24', 'min:3', !fromDataSense? 'required' : ''],
        connectionString: ['required', 'regex:' + KEYS_REG_EXP]
    }
};

export const validate = (values, props, fromDataSense) => {
    const validator = new Validator(values, rules(fromDataSense), {
        "regex.serverName": "Storage Account Name can contain only lowercase letters and numbers",
        "max.serverName": "Azure Storage Account Name must be no more than 24 characters",
        "min.serverName": "Azure Storage Account Name must be no less than 3 characters",
    });
    validator.setAttributeNames({serverName: 'Azure Storage Account Name', connectionString: "Connection String"});
    validator.passes();
    return validator.errors.all();
};

