import React, {useState} from "react";
import TagsPopover from "./../../widgets/TagsPopover";
import { find, get} from "lodash";
import {
  DEFAULT_ENCRYPTION_TYPE, DEFAULT_ENCRYPTION_TYPE_OPTION,
  DEFAULT_STORAGE_CLASS,
  DEFAULT_STORAGE_CLASS_OPTION,
  ENCRYPTION_TYPES_NAMES,
  S3_BUCKET_PERMISSIONS_DOCUMENTATION,
} from "./../../../consts";
import ShowObjectStorageStepTemplate from "./ShowObjectStorageStepTemplateNew";
import "./showObjectStorageStepNew.scss";
import { updateFormValues } from "./../../../utils/formUtils";
import {getCredentialsByType} from "./../../../utils/sync-utils";
import BucketsAdditionalSettingsUI from "./additionalSettings/BucketsAdditionalSettingsUINew";

const ShowBucketsStepBase = (props) => {
  const {isTargetStepFromSameSourceType, isSourceStep, validate, _wizard: {wizardInfo: {source},
      selectedWizardParams: {selectedBucketPath, selectedBucketPathTarget, selectedStorageClass, selectedEncryptionType,
        selectedDataBroker,}}, clearFoldersCache, _showBuckets, listBucketFolders, _ui: { s3 }} = props;


  const [copyAcl] = useState(() => {
    const {copyAcl} = getCredentialsByType("s3", selectedBucketPath) || {copyAcl : false};
    return copyAcl;
  })

  const initialFormValues = {
    encryptionTypeOption: DEFAULT_ENCRYPTION_TYPE,
    encryptionKeyOption: null,
    encryptionKeyManual: "",
    storageClass: DEFAULT_STORAGE_CLASS_OPTION,
    copyAcl
  };

  const setAdditionalSettings = (step, bucketFolderFullPath) => {
    const bucketName = bucketFolderFullPath.split("/")[0];
    //if it is not an s3 provider, we don't show the privateS3Links and not acl, go directly to the next step in the wizard
    if (isSourceStep && source.provider !== 's3') return true;
    if (step === "select") {
      props.bucketsEncryptionStep();
      const bucketInfo = find(_showBuckets[selectedDataBroker.id].data, {
        name: bucketName,
      });
      if (!bucketInfo.kmsKeys && !isSourceStep) { //storage class is selected only on target
        props.listAwsKmsKeys(selectedDataBroker.id, bucketName);
      }
      if (!bucketInfo.s3PrivateLinks) { //this is required for both s3 source and target
        props.listS3PrivateLinks(selectedDataBroker.id, bucketInfo.region.name, bucketName);
      }
      return false;
    } else { //it is the second step, we already retrieved all the lists
      return true;
    }
  };

  const returnToBucketsStep = (form) => {
    updateFormValues(
      {
        encryptionTypeOption: null,
        encryptionKeyOption: null,
        encryptionKeyManual: "",
        storageClass: DEFAULT_ENCRYPTION_TYPE_OPTION,
        s3PrivateLinks: null
      },
      form
    );
    props.clearUrlParamsIfChanged(null, null, "storageClass", null, true);
    props.clearUrlParamsIfChanged(null, null, "encryptionType", null, true);
    props.bucketsSelectStep();
  };

  const getSecondStepUi = (step, bucketPath, formValues, form, {isSourceStep, isTargetStepFromSameSourceType, _wizard: {protocols, wizardInfo: {source, target}}}, bucketFullPath) => {
    if (!bucketPath) return <div/>;
    const bucketInfo = find(_showBuckets[selectedDataBroker.id].data, {
      name: bucketPath,
    });
    return <BucketsAdditionalSettingsUI isSourceStep={isSourceStep} isTargetStepFromSameSourceType={isTargetStepFromSameSourceType}
                                        source={source} target={target} bucketInfo={bucketInfo}
                                        bucketPath={bucketPath} protocols={protocols} bucketFullPath={bucketFullPath}
                                        selectedEncryptionType={selectedEncryptionType}
                                        selectedStorageClass={selectedStorageClass}
                                        returnToBucketsStep={returnToBucketsStep} form={form} formValues={formValues}/>
  };

  const step = s3?.step === "additionalSettings" ? "secondStep" : "select";

  const stepOptions = {
    selectedBaseObject: isTargetStepFromSameSourceType
      ? selectedBucketPathTarget
      : selectedBucketPath,
    title: `Select a ${isSourceStep ? "Source" : "Target"} Bucket`,
    resetToFirstStepAction: props.bucketsSelectStep,
    failedMessage: "Unable to retrieve buckets",
    notFoundMessage: "No buckets were found",
    bottomMessage: "in your AWS account",
    storage: _showBuckets,
    getStorageByBrokerId: true,
    listSubFoldersAction: listBucketFolders,
    storageObjectName: "Bucket",
    storageObjectType: "S3",
    innerColumns,
    stepFormName: "bucketsForm",
    currentStep: step,
    containerOptions,
    getSecondStepUi: getSecondStepUi,
    updateStepInfo: setAdditionalSettings,
    getSelectedParams: (bucket) => {
      const paramName = isTargetStepFromSameSourceType ? 'bucketPathTarget' : 'bucketPath';
      return {[paramName] : bucket}
    },
    getExtraUrlParamsNew: (formValues) =>({
      storageClass: formValues?.storageClass?.value || DEFAULT_STORAGE_CLASS,
      encryptionType: formValues?.encryptionTypeOption
          ? formValues?.encryptionTypeOption.name
          : ENCRYPTION_TYPES_NAMES.AES
    }),
    supportAddStorageObject: {
      cacheName: "_showBuckets",
      accountPrefix: "AWS",
      bucketPrefix: "S3",
      requirementsUrl: S3_BUCKET_PERMISSIONS_DOCUMENTATION,
      validatorRules: [
        "required",
        "min:3",
        "max:63",
        "regex:/^((?!/).)*$/" /*'regex:/^([a-z]|(\d(?!\d{0,2}\.\d{1,3}\.\d{1,3}\.\d{1,3})))([a-z\d]|(\.(?!(\.|-)))|(-(?!\.))){1,61}[a-z\d\.]$/'*/,
      ],
      //regex from http://info.easydynamics.com/blog/aws-s3-bucket-name-validation-regex
    },
    innerPathBeginWithSlash: true,
    refreshStep: () => {
      clearFoldersCache({
        cacheName: "_showBuckets",
        storageKey: selectedDataBroker.id,
      });
      props.fetchBuckets();
    },
    validate,
    initialFormValues,
  };

  return <ShowObjectStorageStepTemplate stepOptions={stepOptions} {...props} />;
};

export default ShowBucketsStepBase;

const innerColumns = (row) => {
  const { region, tags, wasAddedManually } = row;
  const regionDisplay = wasAddedManually
    ? ""
    : get(region, "name")
    ? `${region.displayName}|${region.name}`
    : "";
  const tagsDisplay = wasAddedManually ? (
    <td className="tags" />
  ) : tags ? (
    <TagsPopover tags={tags} inTable={true} leftAdjust={-470} key="tags" />
  ) : (
    ""
  );

  return region && tags
    ? [
        <td className="path" key="path" width="30%">
          <div className="text" title={row.path}>
            {row.path}
          </div>
          <div className="title">Name</div>
        </td>,
        <td className="region" key="region">
          {regionDisplay && <div className="text">{regionDisplay}</div>}
          {regionDisplay && <div className="title">Region</div>}
        </td>,
        tagsDisplay,
      ]
    : [
        <td className="path" title={row.path} key="path">
          {row.path}
        </td>,
      ];
};

const containerOptions = {
  singularTitle: "Bucket",
  pluralTitle: "Buckets",
  itemIdentifier: "actualPath",
  rootName: "All Buckets",
  targetName: "Define Target Folder",
  directoriesName: "Folders",
  directoryName: "folder",
  icon: require("./../../../assets/svgs/bucket-icon.svg"),
  nameForSetupStep: "Bucket Setup",
};
