import React, {useState} from 'react';
import AddBYOLLicenseForm from './AddBYOLLicense';
import BYOLLicenseLine from './BYOLLicenseLine';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {purchaseInfo} from '../../../consts';
import {getLicenseSettings, getNSSCredentials, addBYOLLicense} from "../../../store/global/action_creators";
import {Button} from '@netapp/shared-components';
import {ReactComponent as NetappIcon} from "../../../assets/svgs/netapp-icon.svg";

require("./byolLicenses.scss");

const BYOLLicenses = (props) => {
    const {getLicenseSettings, licenses} = props;
    const [adding, setAdding] = useState(false);
    const isFirstLicense = licenses.length === 0;

    const switchToAddState = () => {
        setAdding(true);
    };

    const finishedAdding = () => {
        setAdding(false);
        getLicenseSettings(); // update license page
    };

    return (
        <div>
            <div className="subscription-card byol-licenses payment-option row medium">
                <div className="payment-icon">
                    <span><NetappIcon/></span>
                </div>
                {isFirstLicense ?
                    <div className="medium-8 columns payment-text">
                        <h5>Add BYOL License</h5>
                        <div>You can pay for sync relationships by purchasing licenses from
                            NetApp.<br/>{purchaseInfo}</div>
                        {adding && <AddBYOLLicenseForm
                            isFirstLicense={isFirstLicense}
                            onComplete={finishedAdding}
                        />}
                    </div>
                    :
                    <div className={classNames("columns licenses-table", {'adding': adding})}>
                        <h5>BYOL List</h5>
                        {licenses.map((license) => (
                            <BYOLLicenseLine key={license.serialNumber} license={license}/>))}
                        {adding && <AddBYOLLicenseForm
                            isFirstLicense={isFirstLicense}
                            onComplete={finishedAdding}
                        />}
                    </div>
                }

                {!adding &&
                <div className={classNames({
                    "payment-action": isFirstLicense,
                    "medium-offset-10 under-table-button": !isFirstLicense
                }, "medium-1 columns")}>
                    <Button type="button" variant="primary" onClick={switchToAddState}>Add License</Button>
                </div>}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    _licenses: state.global._licenses,
    nssCredentials: state.global._nssCredentials.data
});

export default connect(mapStateToProps, {getLicenseSettings, getNSSCredentials, addBYOLLicense})(BYOLLicenses)
