import {useState} from 'react';
import useDeepCompareEffect from './useDeepCompareEffect';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';

const useFetchWhenChange = (callback, responseKey, properties, mappingFunc, sortFunc, handleError) => {
    const [state, setState] = useState(null);

    if (state && properties.some(isNil)) {
        setState(null);
    }

    useDeepCompareEffect(() => {
        if (properties.every(property => !isNil(property))) {
            setState(null); //reset the data until it is re-fetched
            callback(...properties).then(res => {
                let data = res ? res[responseKey] : null;
                if (Array.isArray(data) && mappingFunc) {
                    data = data.map(mappingFunc);
                }
                if (Array.isArray(data) && sortFunc) {
                    data = sortBy(data, sortFunc)
                }
                setState(data)
            }).catch(handleError)
        }
    }, properties);

    return state;
};

export default useFetchWhenChange;
