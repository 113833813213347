import React from "react";
import moment from "moment";
import classNames from "classnames";
import {FREE_TRIAL_PERIOD} from "../../../consts";

const BYOLLicenseLine = ({license}) => {
    const today = moment().startOf('day');
    const daysLeft = moment(license.expirationDate).diff(today, 'days');
    const warning = daysLeft <= FREE_TRIAL_PERIOD && daysLeft > 0;
    const expired = daysLeft <= 0;
    const expiryClass = classNames("expiry", {warning, expired});
    const expirationDate  = moment.utc(license.expirationDate).format('MMMM Do YYYY');

    const expiryText = warning ? `${expirationDate } - ${daysLeft} ${daysLeft > 1? 'days' : 'day'} until expiration` : (expired? `${expirationDate } - Expired` : expirationDate );
    return (
        <table>
            <tbody>
                <tr>
                    <th>Serial Number</th>
                    <th>Reserved Relationships</th>
                    <th>Expiration Date</th>
                </tr>
                <tr>
                    <td>{license.serialNumber}</td>
                    <td>{license.reservedRelationships}</td>
                    <td className={expiryClass}>{expiryText}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default BYOLLicenseLine;