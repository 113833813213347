import React from 'react';
import {ReactComponent as CheckmarkIcon} from "../../../assets/svgs/checkmark-fill-circle-icon.svg";
import {ReactComponent as CvoIcon} from "../../../assets/svgs/cvo-default.svg";

import "./cvoWeCardNew.scss";

const CvoWeCard = {
    className: () => "cvo-we-card",
    renderContent: ({data}) => {
        const {name} = data;
        return (<>
                <div className="checkmark"><CheckmarkIcon/></div>
                <div className="card-content">
                    <div className="card-icon"><CvoIcon/></div>
                    <div className="card-details">
                        <div className="we-name">{name}</div>
                        <div className="we-details">
                            <CardDetail value={data.status?.status || 'N/A'} name="Status"/>
                            <CardDetail value={data.cloudProviderName === "Amazon" ? "AWS" : data.cloudProviderName } name="Cloud Provider"/>
                            <CardDetail value={data.isHA? 'HA' : 'SINGLE'} name="Availability"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default CvoWeCard;

const CardDetail = ({value, name}) => {
    return <div className="card-detail">
        <div className="value" title={value}>{value}</div>
        <div className="name">{name}</div>
    </div>
};
