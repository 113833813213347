import React from 'react';
import {Field} from 'react-final-form';
import {InputField, TextAreaField} from "../_common_/forms/forms";
import Validator from 'validatorjs';
import ConfirmFormDialogNew from "../dialog/ConfirmFormDialogNew";
import {forEach} from 'lodash'
import {useDispatch} from 'react-redux';
import "./editCredentialsDialog.scss";
import {updateCredentials} from "../../store/wizard/action_creators";
import {buildCredentialsObject} from "../../utils/sync-utils";

const validate = (values, props) => {
    const {credentialsToUpdate} = props;
    const rules = {};
    const attributeNames = {};
    forEach(credentialsToUpdate, (credential) => {
        rules[credential.name] = credential.rules;
        attributeNames[credential.name] = credential.label;
    })
    const validator = new Validator(values, rules);
    validator.setAttributeNames(attributeNames);
    validator.passes();
    return validator.errors.all();
};


const EditCredentialsDialog = (props) => {
    const {from, protocol, path, credentialsToUpdate, relationshipId} = props;
    const dispatch = useDispatch();
    const handleSubmit = (values) => {
        return updateCredentials(relationshipId, buildCredentialsObject(values, from, protocol))(dispatch)
    }

    return (
        <ConfirmFormDialogNew
            title={`Update Credentials for ${path}`}
            activateSubmit={handleSubmit}
            confirmText="Update"
            layoutClassName="edit-credentials-dialog"
            validate={validate}
            extractError={(error) => error.data.message ? error.data.message : error.data}
            {...props}
        >
            {credentialsToUpdate.map((credential, index) => {
                return <Field key={index} component={credential.textArea ? TextAreaField : InputField} name={credential.name}
                              placeholder={credential.placeholder} label={credential.label}
                              type={credential.type || "text"}/>
            })}
        </ConfirmFormDialogNew>
    )
};

export default EditCredentialsDialog;
