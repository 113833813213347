import React from "react";
import WizardHeaderStep from './NewWizardHeaderStep';
import _ from "lodash";
import "./NewBrokerInstanceSteps.scss";

const BrokerInstanceSteps = ({screens, setScreen, currentScreen}) => {
    return <div className="wizard-header broker-instance-steps">
        <div className="steps">
            {screens.map((screenName, index) => {
                return (<WizardHeaderStep
                            goBack={(currentScreen > index) ? () => setScreen(index) : null}
                            key={screenName}
                            current={index === currentScreen}
                            future={currentScreen < index}
                            stepName={_.capitalize(screenName)}
                            stepNumber={index + 1} last={index === (screens.length-1)}
                />)
            })}
        </div>
    </div>
};

export default BrokerInstanceSteps;