import React from 'react';
import ConfirmDialog from '../dialog/ConfirmDialog';

const DownloadLogsDialog = ({downloadDataBrokerLogs, relationship, sourceName}) => {
    const {groupInfo} = relationship;
    const dataBrokersCount = groupInfo?.dataBrokers.length;

    return (
        <ConfirmDialog
            title="Download Data Broker Logs"
            onConfirm={() => downloadDataBrokerLogs(groupInfo.id || groupInfo.groupId, relationship.relationshipId)}  //timeline is using group.groupId
            confirmText="Send Request"
        >
            <p>The logs will be saved to your web browser&#39;s download location.</p>
            <p>This process usually takes a couple of minutes.</p>
            {dataBrokersCount > 1 && <p>Note: This {sourceName} is associated with {dataBrokersCount} data brokers, so you will receive separate logs for each data broker.</p>}
        </ConfirmDialog>)
};
export default DownloadLogsDialog;