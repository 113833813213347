import {find, isEmpty, replace} from "lodash";
import queryString from 'query-string';

export const decodeAll = (text) => {
    if (text) {
        //now need to decode all the way back since can't rely on the automatic decoding
        const withPrecent = text.replace(/---/g, "%");
        const decodedURI = decodeURIComponent(withPrecent);
        return decodedURI.replace(/%25/g, "%").replace(/%2F/g, "/").replace(/%2E/g, ".").replace(/%2D/g, "-").replace(/%2C/g, "(");
    }
    return text;
};

export const clearDoubleSlash = (text) => {
    return text.replace("//", "/");
};

export const removeStartingAndEndingSlash = (path) => {
    if (isEmpty(path)) return path;
    const startsWithSlash = path[0] === "/";
    const endsWithSlash = path[path.length - 1] === "/";
    return path.substring(startsWithSlash ? 1 : 0, endsWithSlash ? path.length - 1 : path.length);
};

//path must always start with slash and end with no slash
export const fixPath = (path, keepSlash = false) => {
    if (isEmpty(path)) return path;
    const pathWithoutTrailingSlashes = path.replace(/^(?:\/)+/, "");
    return isEmpty(pathWithoutTrailingSlashes) && !keepSlash ? pathWithoutTrailingSlashes : "/" + pathWithoutTrailingSlashes;
};

export const buildUrlParams = (_routing, { params }, propsLocation, href) => {
    let location = propsLocation;
    location.query = queryString.parse(location.search);

    return {
        params,
        href,
        location
    }
};

export const extractIpAddressAndPathFromUrl = (url) => {
    const addressRegExp = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
    const ipAddress = url.match(addressRegExp)[0];
    const hostSplit =  url.split(ipAddress);
    let actualPath = hostSplit[1];
    actualPath = replace(actualPath, /:/g, "");
    actualPath = replace(actualPath, /\\/g, "");
    //to fix CS-6116 CVO - wrong path is sent - when volume is mounted to a volume shouldn't remove the inner slash, only starting and ending ones.
    actualPath = removeStartingAndEndingSlash(actualPath);
    return {ipAddress, actualPath};
}

export const extractVersionFromString = (versionString) => {
    const versionRegExp = /\d/;
    const version = versionString.match(versionRegExp);
    return version ? version[0] : null;
}

export const getParentFullPath = (path) => {
    if (path === "/") return path;
    if (path === "//") return "/";
    const paths = path.split("/");
    // console.log(paths)
    const parentPaths = paths.slice(0, paths.length -1);
    // console.log(parentPaths)
    return parentPaths.join("/")
}

export const getDirectoryObjectFromCache = (cache, cacheKey, path, idFromParent = true) => {
    if (!cache || !cacheKey || !path) return null;
    const parentPath = getParentFullPath(path, true)
    const isRootPath = path === "/" || path === "//";
    const mappedDirectories = isRootPath ? [{folderId: 0, actualPath: path}] : cache[cacheKey]?.folders[parentPath]?.data;
    let parentFolderId = -1;
    if (idFromParent) {
        parentFolderId = isRootPath ? -1: cache[cacheKey]?.folders[parentPath]?.folderId || -1;
    }
    if (!mappedDirectories) return {parentFolderId};
    const foundDirectory = find(mappedDirectories, {actualPath: path})
    return {parentFolderId: idFromParent ? parentFolderId : foundDirectory.folderId, foundDirectory};
}

export const getDirectoryObjectFromCacheGoogle = (cache, cacheKey, path) => {
    if (!cache || !cacheKey || !path) return null;
    const parentPath = getParentFullPath(path, true)
    const folders = cache[cacheKey]?.folders;
    let mappedDirectories;
    if (isEmpty(parentPath)) { //it is a drive
        mappedDirectories = folders['-'];
    }
    else {
        mappedDirectories = folders[parentPath];
    }
    if (!mappedDirectories) return {};
    let foundDirectory = find(mappedDirectories.data, {actualPath: path})
    let parentDrive;
    if (!foundDirectory) {
        //if it is a new target inside drive we will find the drive and use its data, otherwise use the relevant data from the parent folder in mappedDirectories
        parentDrive = find(folders['-'].data, {actualPath: parentPath})
        foundDirectory = {
            path: path,
            actualPath: path,
            driveId: parentDrive?.id || mappedDirectories.driveId,
            parentFolderId: mappedDirectories.folderId
        }
    }//probably a new target
    console.log(`%c getDirectoryObjectFromCacheGoogle\npath: ${path}\nparentPath: ${parentPath}\nparentDrive: ${JSON.stringify(parentDrive)}\nfoundDirectory ${JSON.stringify(foundDirectory, null, 2)}`, 'font-weight:bold; color:purple')
    return {foundDirectory};
}
