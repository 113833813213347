import React, {useState} from 'react';
import {connect} from 'react-redux';
import CurrentLicenseSection from './CurrentLicenseSection';
import LicenseInfo from './LicenseInfo';
import Subscribe from './Subscribe';
import BYOLLicenses from './BYOLLicenses';
import ContactUs from './ContactUs';
import _ from "lodash";
import * as licenseUtils from "../../../utils/license-utils";
import {getBYOLLicenses, getLicenseSettings, visitedTrialPage} from "../../../store/global/action_creators";
import useRunOnce from '../../../hooks/useRunOnce';
import MainLoader from '../../_common_/MainLoader/MainLoader';
import { Link } from 'react-router-dom';

require("./licenseSettings.scss");

const ProviderSubscription = ({freeTrial, authData, hasLicense, existingProvider}) => {
    const [provider, setProvider] = useState('AWS');

    if (authData.subscribed && !existingProvider) return <div/>;
    const showProviderSelection = authData.unsubscribed;

    const changeSelection = (event) => {
        setProvider(event.currentTarget.value);
    };
    return (
        <div className="marketplace-subscription">
            <div className="subscription-card">
                {showProviderSelection &&
                <div className="radio">
                    <label>
                        <input onChange={changeSelection} type="radio" value="AWS" checked={provider === 'AWS'}/>
                        AWS
                    </label>
                    <label>
                        <input onChange={changeSelection} type="radio" value="Azure" checked={provider === 'Azure'}/>
                        Azure
                    </label>
                </div>}
                <Subscribe  {...authData}
                        freeTrial={freeTrial}
                        hasLicense={hasLicense}
                        provider={!existingProvider || showProviderSelection ? provider : existingProvider}
                />
            </div>
        </div>
)};

const LicenseSettings = (props) => {
    const {auth, _licenses, _licenseSettings, _routing: {location: {query}}} = props;

    useRunOnce(() => {
        props.getLicenseSettings();
        props.getBYOLLicenses();
        props.visitedTrialPage();
    });

    const renderLicenseSettingsTitle = (asTrialPage) => {
        const {_ui, auth: {data: {userName, daysLeftForTrial}}} = props;

        const hi = `Hi ${userName}, `;

        if (_ui.blocked) {
            return <h1 className="header hi">{`${hi} your free trial has ended`}</h1>;
        }
        else if (asTrialPage) {
            return <h1 className="header hi">{`${hi} ${daysLeftForTrial} ${daysLeftForTrial > 1? 'days':'day'} left to your free trial`}</h1>;
        }

        return <h1 className="header">License Settings</h1>;
    };



    const subscribed = _.get(auth, "data.subscribed");
    const needToPay = _licenseSettings?.succeeded && _.get(_licenseSettings, "data.needToPay");
    const hasLicense = _licenseSettings?.succeeded && _.get(_licenseSettings, "data.totalReservedRelationships") > 0;
    const existingProvider = _.get(auth, "data.subscriptionProvider");
    const existingProviderName= licenseUtils.subscriptionProvidersNames[existingProvider];

    let licensesCapacityWarning = '';
    if (!subscribed) {
        const licensesCapacity = licenseUtils.checkLicensesCapacity(props, true);
        licensesCapacityWarning = licensesCapacity.licensesCapacityWarning;
    }

    const asTrialPage = query.trial === "true";

    const isDoneFetching = !!(_licenseSettings?.succeeded && _licenses?.succeeded);

    return (
        <div className="scrollable-area">
            <div className="license-settings">
                {renderLicenseSettingsTitle(asTrialPage)}

                {!isDoneFetching && <MainLoader/>}

                {isDoneFetching &&
                <div>
                    <CurrentLicenseSection licenseSettings={_licenseSettings} subscribed={subscribed}/>

                    <LicenseInfo className="license-info payment-option" licenseType={_licenseSettings.data.licenseType} subscribed={subscribed}
                                 licensesCapacityWarning={licensesCapacityWarning} noCharge={_licenseSettings.data.noCharge}/>

                    <ProviderSubscription
                        hasLicense={hasLicense}
                        existingProvider={existingProviderName}
                        authData={auth.data}
                        freeTrial={_licenseSettings.data.freeTrial}/>

                    <BYOLLicenses licenses={_licenses.data.licenses}/>

                    {asTrialPage &&
                    <Link className="button float-center not-now" to="/">{needToPay? 'Not Now' : 'Continue'}</Link>}

                    <ContactUs/>
                </div>}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    _licenses: state.global._licenses,
    _licenseSettings: state.global._licenseSettings
});

export default connect(mapStateToProps, {getLicenseSettings , getBYOLLicenses, visitedTrialPage})(LicenseSettings)
