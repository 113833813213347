import * as actionCreatorsConsts from './action_creators_consts.js';
import * as consts from "../../consts";
import {REPORT_STATUS_RUNNING} from "../../consts";
import {cloneDeep, find, findKey, forEach, get, map, merge, values, without} from "lodash";
import Immutable from "seamless-immutable";
import * as listActionsUtils from "../../utils/listActionsUtils";
import {endPointTypes, getWizardTypeForSystemFlow} from '../../utils/sync-utils'
import {getNextTimeHour, removeSettingsFromSessionStorage} from '../../utils/settings-utils';
import {
    addUnhandledMessages,
    buildStateFromAction,
    handleUnhandledMessages,
    processAgent,
    processEndpoint,
    processRelationship,
    resetActivityValues,
    updateCurrentStep,
    updateKeyWithBuiltState,
    updateLastRequestsForRelationshipsData,
    processDataBrokerConfiguration
} from "../../utils/reducerUtils";
import * as actions from './action_creators_consts';
import {createStringFromEndPointObject, sendEvents} from "../../utils/helpers";
import {
    isEqualByInnerKey,
    isHostEqual,
    isObjExistInField,
    updateStoreForUpdateBoxFolders,
    updateStoreForUpdateFoldersActionForFileServer, updateStoreForUpdateGoogleDriveFolders
} from "../../utils/listActionsUtilsHelpers";

export const syncDefaultState = (_routing={}) => Immutable({
    _routing,
    _ui: {},
    unhandledMessages: [],
    _servers: null,
    _wizard: null,
    _downloadLogsRequests: {},
    _azureStorageAccounts: {},
    _azureDataLakeStorageAccounts: {},
    _showExports: {},
    _showShares: {},
    _showBuckets: {},
    _showAzureBlobContainers: {},
    _showAzureDataLakeContainers: {},
    _showS3BasedBuckets: {},
    _showGCPBuckets: {},
    _showBoxFolders: {},
    _showGoogleDrives: {},
    _showSftpDirectories: {},
    _relationships: {},
    _cvsWorkingEnvironments: {},
    _anfVolumes: {},
    _cmWorkingEnvironments: {},
    _cmVolumes: {},
    _fsxWorkingEnvironments: {},
    _fsxVolumes: {},
    _dataBrokersGroups: {},
    _endpoints: {}
});

export const syncReducer = (initialState = syncDefaultState(), action = {}) => {
    const stateWithWizardInfo = fillWizardData(initialState);
    const updatedState = handleAction(stateWithWizardInfo, action);
    return handleUnhandledMessages(updatedState, handleAction);
};

function fillWizardData(state) {
    if (!state._wizard) return state;
    const {_routing: {params}} = state;
    return state.update("_wizard", _wizard => _wizard.set("urlParams", Object.assign({}, get(_wizard, "urlParams"), params)));
}

const handleAction = ((state, action) => {
    action = cloneDeep(action);

    switch (action.type) {
        case actionCreatorsConsts.UPDATE_URL_PARAMS: {
            return state.set("_routing", action.payload);
        }
        case actionCreatorsConsts.BUCKETS_ADDITIONAL_SETTINGS_STEP: {
            return state.setIn(["_ui", "s3", "step"], "additionalSettings")
        }
        case actionCreatorsConsts.AZURE_ADDITIONAL_SETTINGS_STEP: {
            return state.setIn(["_ui", "azure", "step"], "additionalSettings")
        }
        case actionCreatorsConsts.AZURE_SELECT_STEP: {
            return state.setIn(["_ui", "azure", "step"], "select")
        }
        case actionCreatorsConsts.BUCKETS_SELECT_STEP: {
            return state.setIn(["_ui", "s3", "step"], "select")
        }
        case actionCreatorsConsts.GCP_ADDITIONAL_SETTINGS_STEP: {
            return state.setIn(["_ui", "gcp", "step"], "additionalSettings")
        }
        case actionCreatorsConsts.GCP_SELECT_STEP: {
            return state.setIn(["_ui", "gcp", "step"], "select")
        }
        case actionCreatorsConsts.CLOSE_SEARCH_WIDGET: {
            return state.setIn(["_ui", "closeSearchWidget"], true);
        }
        case actionCreatorsConsts.CLEAR_CLOSE_SEARCH_WIDGET: {
            return state.setIn(["_ui", "closeSearchWidget"], false);
        }
        case actionCreatorsConsts.CLEAR_URL_PARAMS: {
            const {payload: {changedParam, newVal, without, extraKeyToClear, forceClearDueToCredentialsChange}} = action;
            //if the new parameter value !== old parameter value (in urlParams) then clear what is requested:
            const oldVal = state._wizard.urlParams[changedParam];
            if (forceClearDueToCredentialsChange || (oldVal && newVal !== oldVal)) {
                //console.log(`%c%c reducer clearUrlParams changedParam: ${changedParam} oldVal ${oldVal} newVal ${newVal} without (${without}) extraKeyToClear (${extraKeyToClear}) forceClearDueToCredentialsChange ${forceClearDueToCredentialsChange}`, "font-weight:bold", "background:yellow");
                //clear the relevant params from _wizard.urlParams and extraKey; clear _routing.params completely
                const newState = extraKeyToClear ? state.setIn(extraKeyToClear, undefined) : state;
                return newState.setIn(["_routing", "params"], undefined)
                    .updateIn(["_wizard", "urlParams"], obj => obj ? Immutable.without(obj, without) : obj);
            }
            return state;
        }
        case actionCreatorsConsts.CLEAR_SHOW_ACTION: {
            const {payload: {keyToClear}} = action;
            if (keyToClear) return state.setIn(keyToClear, undefined);
            return state;
        }
        case actionCreatorsConsts.GET_DATA_BROKERS_GROUPS: {
            return updateKeyWithBuiltState(state, action, ["_dataBrokersGroups"], (builtState) => {
                if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                    builtState.data = builtState.data.map(group => {
                        group.dataBrokers = map(group.dataBrokers, processAgent);
                        return group;
                    })
                }
                return builtState;
            });
        }
        case actionCreatorsConsts.GET_DATA_BROKER_CONFIGURATION: {
            const payload = {
                header: {...action.extra},
                localConfiguration: (() => {
                    if (action.status === actionCreatorsConsts.STATUS_ERROR) {
                        return {
                            status: consts.RESPONSE_STATUS_FAILED,
                            reason: action.payload?.data?.message || consts.UNKNOWN_ERROR,
                        }
                    }

                    return {
                        status: action.status
                    }
                })()
            };

            return processDataBrokerConfiguration(state, payload);
        }
        case actionCreatorsConsts.UPDATE_DATA_BROKER_CONFIGURATION: {
            return processDataBrokerConfiguration(state, action.payload);
        }
        case actionCreatorsConsts.GET_SERVERS: {
            const originalState = get(state, "_servers." + action.extra.protocolAndProviderKey);
            if (originalState && action.status !== actions.STATUS_PROGRESS && originalState.id !== action.id) {
                // eslint-disable-next-line no-console
                console.log(`%c handleAction discarding action @ ${action.type} since the id doesn't match the id of the same action in the store`, "font-weight: bold;");
                return state;
            }
            return state.setIn(["_servers", action.extra.protocolAndProviderKey], buildStateFromAction(action));
        }
        case actionCreatorsConsts.GET_AZURE_STORAGE_ACCOUNTS: {
            return updateKeyWithBuiltState(state, action, ["_azureStorageAccounts"]);
        }
        case actionCreatorsConsts.GET_AZURE_DATA_LAKE_STORAGE_ACCOUNTS: {
            return updateKeyWithBuiltState(state, action, ["_azureDataLakeStorageAccounts"]);
        }
        case actionCreatorsConsts.DATA_BROKER: {
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                const {id, fileLink, type, name, groupId, latestVersion} = action.payload;
                const {port} = action.extra;
                const newDataBroker = processAgent({id, fileLink, type, name, groupId, port, latestVersion});
                return state.updateIn(['_dataBrokersGroups', 'data'], groups => {
                    const groupWithNewBroker = find(groups, {id: groupId});
                    return groupWithNewBroker ?
                        groups.map(group => {
                            if (group.id === groupId) {
                                return {
                                    ...group,
                                    dataBrokers: [...group.dataBrokers, newDataBroker]
                                }
                            }
                            return group;
                        }) : [...groups, {id: groupId, dataBrokers: [newDataBroker], name, relationships: []}];
                });
            }
            return state;
        }
        case actionCreatorsConsts.EDIT_GROUP_SETTINGS: {
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                const {id} = action.payload;
                return state.updateIn(['_dataBrokersGroups', 'data'], groups => {
                    return groups.map(group => {
                            if (group.id === id) {
                                return merge({}, group, action.payload)
                            }
                            return group;
                        });
                });
            }
            return state;
        }
        case actionCreatorsConsts.DELETE_DATA_BROKER: {
            return updateKeyWithBuiltState(state, action, ["_deleteDataBroker"]);
        }
        case actionCreatorsConsts.SYNC_NOW: {
            if (!action.extra.relationshipId) return state;
            return state.updateIn(["_relationships", "data"], relationships => {
                return relationships.map(relationship => {
                    if (relationship.id === action.extra.relationshipId) {
                        relationship = cloneDeep(relationship);
                        if (action.status === actionCreatorsConsts.STATUS_PROGRESS) {
                            //reset values until "update relationship" message comes and updates the actual values:
                            relationship.ui = {...relationship.ui, ...resetActivityValues()};
                            relationship.activity.errors = [];
                            //Set the status as "STARTING", in order for the UI to show "starting sync" in the meantime (and not "completed")
                            relationship.ui.statusText = 'Starting Sync';
                            relationship.ui.status = consts.STATUS_STARTING;
                        }
                        if (action.status === actionCreatorsConsts.STATUS_ERROR) {
                            processRelationship(relationship); // in order to restore the activity values that were reset during "in progress"
                        }
                    }
                    return relationship;
                });
            });
        }
        case actionCreatorsConsts.UPDATE_RELATIONSHIP_NAME: {
            if (!action.extra.relationshipId) return state;
            return state.updateIn(["_relationships", "data"], relationships => {
                return relationships.map(relationship => {
                    if (relationship.id === action.extra.relationshipId) {
                        relationship = cloneDeep(relationship);
                        if (action.status === actionCreatorsConsts.STATUS_SUCCESS) {
                            relationship.name = action.extra.name //for search purposes
                        }
                    }
                    return relationship;
                });
            });
        }
        case actionCreatorsConsts.UPDATE_DATA_BROKER_STATUS: {
            const {status, message, id, placement} = action.payload[consts.PAYLOAD_DATA_BROKER_STATUS];
            let newState = state;

            if (!get(state, '_dataBrokersGroups.data')) {
                return newState;
            }

            newState = newState.updateIn(['_dataBrokersGroups', 'data'], groups => {
                return groups.map(group => {
                    const clonedGroup = cloneDeep(group);
                    clonedGroup.dataBrokers = map(group.dataBrokers, (dataBroker) => {
                        if (dataBroker.id === id) {
                            dataBroker = cloneDeep(dataBroker);
                            if (status) dataBroker.status = status;
                            if (placement) dataBroker.placement = placement;
                            if (message) dataBroker.message = message;
                            processAgent(dataBroker);
                        }
                        return dataBroker
                    });
                    return clonedGroup
                })
            });
            return newState;
        }
        case actionCreatorsConsts.UPDATE_RELATIONSHIP: {
            const {id, activity, group, tags} = action.payload[consts.PAYLOAD_UPDATE_RELATIONSHIP];

            let newUpdatedState;
            let isNewRelationship = true;

            if (get(state, "_relationships.data")) {
                newUpdatedState = state.updateIn(["_relationships", "data"], relationships => {
                    return relationships.map(relationship => {
                        if (relationship.id === id) {
                            isNewRelationship = false;
                            const thisRequest = find(relationship.lastRequests, function (req) {
                                return req.id === action.payload.requestId;
                            });
                            if (thisRequest && thisRequest.isDone) return relationship; //dont get more update for this request after done status
                            else if (relationship.activity.status === consts.RESPONSE_STATUS_ABORTING && activity.status === consts.RESPONSE_STATUS_RUNNING) {
                                //if in aborting status, dot update to syncing
                                return relationship;
                            }
                            const clonedRelationship = cloneDeep(relationship);
                            clonedRelationship.activity = activity;
                            clonedRelationship.group = group;
                            clonedRelationship.tags = tags;
                            processRelationship(clonedRelationship);
                            return clonedRelationship;
                        }
                        return relationship;
                    })
                });
            }

            if (isNewRelationship) {
                newUpdatedState = state.update("_ui", _ui => _ui.set("hasNewRelationship", true));
            }
            newUpdatedState = updateLastRequestsForRelationshipsData(newUpdatedState, action);

            //update only if sync already exists, hence we are in the wizard
            if (get(newUpdatedState, '_wizard.sync') && get(newUpdatedState, '_wizard.sync.relationshipId') === id) {
                if (activity.status === consts.RESPONSE_STATUS_FAILED) {
                    return newUpdatedState.updateIn(["_wizard", "sync"], sync => sync.set("completed", false).set("failed", true));
                } else if (activity.status === consts.RESPONSE_STATUS_DONE) {
                    return newUpdatedState.updateIn(["_wizard", "sync"], sync => sync.set("completed", true).set("percentCopied", 100));
                } else if (activity.status === consts.STATUS_CONTINUOUSLY_SYNCING){
                    return newUpdatedState.updateIn(["_wizard", "sync"], sync => sync.set("syncContinuously", true));
                }else {
                    return newUpdatedState.updateIn(["_wizard", "sync"], sync => sync.set("percentCopied", activity.progress).set("completed", false));
                }
            }
            return newUpdatedState;
        }
        case actionCreatorsConsts.UPDATE_DATA_BROKER_LOGS: {

            const downloadFile = async ({url, dataBrokerId, dataBrokerName, date}, accessToken) => {
                const fileName = `${dataBrokerName}-${dataBrokerId}-${date}`;
                try {
                    const response = await fetch(url, {headers: {
                        'Authorization': accessToken
                        }});
                    if (response.ok) { //in case of 403, "ok" is false
                        const blob = await response.blob();
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        document.body.appendChild(a);// For Firefox https://stackoverflow.com/a/32226068
                        a.href = url;
                        a.download = `${fileName}.7z`;
                        a.click();
                        a.remove();
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                }
            };

            //download the files:
            const {logsDetails} = action.payload[consts.PAYLOAD_DATA_BROKER_LOGS];
            const {accessToken} = action;
            forEach(logsDetails, (details) => downloadFile(details, accessToken));

            //update the state:
            const {requestId} = action.payload;
            const relationshipId = findKey(state._downloadLogsRequests, (key) => {
                return key.requestId === requestId
            });
            if (!relationshipId) return state;
            return state.setIn(["_downloadLogsRequests", relationshipId, 'status'], actionCreatorsConsts.STATUS_SUCCESS);
        }

        case actionCreatorsConsts.UPDATE_DATABROKER_TRANSFER_RATE: {
            const {dataBrokerId, transferRate} = action.payload[consts.PAYLOAD_DATABROKER_TRANSFER_RATE];

            if (!get(state, '_dataBrokersGroups.data')) {
                return state;
            }

            return state.updateIn(['_dataBrokersGroups', 'data'], groups => {
                return groups.map(group => {
                    const clonedGroup = cloneDeep(group);
                    if (clonedGroup.dataBrokers) {
                        clonedGroup.dataBrokers = clonedGroup.dataBrokers.map(dataBroker => {
                            if (dataBroker && dataBroker.id === dataBrokerId) {
                                dataBroker.transferRate = transferRate;
                                return processAgent(dataBroker);
                            }
                            return dataBroker;
                        })
                    }
                    return clonedGroup;
                });
            });
        }

        case actionCreatorsConsts.DELETE_RELATIONSHIP: {
            if (!get(state, "_relationships.data")) {
                return addUnhandledMessages(state, action);
            }
            if (action.extra.ids) {
                return state.updateIn(["_relationships", "data"], relationships => {
                    return relationships.map(relationship => {
                        if (action.extra.ids.includes(relationship.id)) {
                            relationship = relationship.asMutable({deep: true});
                            relationship.activity.isBeingDeleted = true;
                            processRelationship(relationship);
                        }
                        return relationship;
                    });
                });
            }
            return state;
        }
        case actionCreatorsConsts.ABORT_SYNC: {
            if (!get(state, "_relationships.data")) {
                return addUnhandledMessages(state, action);
            }
            if (action.extra.relationshipId) {
                return state.updateIn(["_relationships", "data"], relationships => {
                    return relationships.map(relationship => {
                        if (relationship.id === action.extra.relationshipId) {
                            relationship = relationship.asMutable({deep: true});
                            relationship.activity.status = consts.RESPONSE_STATUS_ABORTING;
                            processRelationship(relationship);
                        }
                        return relationship;
                    });
                });
            }
            return state;
        }
        case actionCreatorsConsts.START_WIZARD: {
            action.payload.sync = {};
            const wizardInfo = action.payload.wizardInfo;
            action.payload.wizardTypeForSystemFlow = getWizardTypeForSystemFlow(wizardInfo);
            action.payload.sourceType = endPointTypes[wizardInfo.source.protocol];
            action.payload.targetType = endPointTypes[wizardInfo.target.protocol];

            return state.set("_wizard", action.payload);
        }
        case actionCreatorsConsts.UPDATE_STEP_CREDENTIALS: {
            const {key, credentials} = action.payload;
            return state.updateIn(["_wizard", "wizardInfo", "stepCredentials"], stepCredentials => (stepCredentials || Immutable({})).set([key], credentials));
        }
        case actionCreatorsConsts.STARTED_WIZARD_STEP: {
            return updateCurrentStep(state.updateIn(["_wizard", "steps"], steps => (steps || Immutable({})).set(action.payload, {started: true})), action.payload, true);
        }
        case actionCreatorsConsts.STARTED_NEW_WIZARD_STEP: {
            return state.setIn(["_wizard", "currentStep"], action.payload);
        }
        case actionCreatorsConsts.EXITED_WIZARD_STEP: {
            return updateCurrentStep(state.updateIn(["_wizard", "steps"], steps => steps && steps.without(action.payload)), action.payload, false);
        }
        case actionCreatorsConsts.CLEAR_SYNC: {
            return state.update("_wizard", _wizard => _wizard.set("sync", {}));
        }
        case actionCreatorsConsts.CLEAR_NEW_WIZARD: {
            return state.set("_wizard", {})
        }
        case actionCreatorsConsts.CLEAR_WIZARD_CACHE: {
            removeSettingsFromSessionStorage();

            const {clearGroups} = action.payload;
            const updatedState = clearGroups
                ? state.set("_dataBrokersGroups", undefined)
                : state;

            //clear cache of wizard as well
            return updatedState.set("_showBuckets", undefined)
                .set(consts.CASH_KEYS._SHOW_EXPORTS, undefined)
                .set(consts.CASH_KEYS._SHOW_SHARES, undefined)
                .set("_showS3BasedBuckets", undefined)
                .set("_showGCPBuckets", undefined)
                .set("_showBoxFolders", undefined)
                .set("_showGoogleDrives", undefined)
                .set("_showSftpDirectories", undefined)
                .set("_cmVolumes", undefined)
                .set(consts.CASH_KEYS._FSX_VOLUMES, undefined)
                .set(consts.CASH_KEYS._ANF_VOLUMES, undefined)
                .setIn(["_routing", "params"], undefined)
                .set("_servers", undefined)
                .set("_azureStorageAccounts", undefined)
                .set("_azureDataLakeStorageAccounts", undefined)
                .set("_showAzureDataLakeContainers", undefined)
                .set("_showAzureBlobContainers", undefined)
                .setIn(["_wizard", "hideHeader"], false)
                .setIn(["_wizard", "wizardInfo", "stepCredentials"], undefined)
        }
        case actionCreatorsConsts.CLEAR_FOLDERS_CACHE: {
            //clear cache of specific endpoint
            const {cacheName, storageKey} = action.payload;
            return state.setIn([cacheName, storageKey], undefined)
        }
        case actionCreatorsConsts.ADD_BUCKET_NAME: {

            const {bucketName, groupId, cacheName} = action.payload;
            if (bucketName) {
                const bucketObject = {
                    actualPath: bucketName,
                    name: bucketName,
                    path: bucketName,
                    wasAddedManually: true,
                    region: {displayName: "Unknown", name: undefined}, //http://jira.tlveng.netapp.com/browse/CS-5763
                    tags: []
                };
                const addNewBucket = (data) => {
                    var dataMutable = data ? data.asMutable() : [];
                    dataMutable.unshift(bucketObject);
                    return Immutable(dataMutable);
                };
                if (!(state[cacheName][groupId].data && state[cacheName][groupId].data.find(bucket => bucket.name === bucketName.split('/')[0]))) { //if bucket not in the list
                    return state.updateIn([cacheName, groupId, "folders", "-", "data"], data => addNewBucket(data))
                        .updateIn([cacheName, groupId, "data"], data => addNewBucket(data))
                        .setIn([cacheName, groupId, "failedRetrieve"], false);
                }
            }
            return state;
        }
        case actionCreatorsConsts.ADD_SHARE_MANUALLY: {
            const {shareName, cifsServer, groupId} = action.payload;

            if (shareName) {
                const trimmedShare = shareName[shareName.length - 1] === "/" ? shareName.substring(0, shareName.length - 1) : shareName;
                const shareObject = {
                    actualPath: trimmedShare,
                    path: trimmedShare,
                    host: cifsServer
                };
                const addNewShare = (data) => {
                    const dataMutable = data ? data.asMutable() : [];
                    dataMutable.unshift(shareObject);
                    return Immutable(dataMutable);
                };
                const showSharesKey = `${cifsServer}-${groupId}`;
                if (!(state._showShares[showSharesKey].data && state._showShares[showSharesKey].data.find(exp => exp.path === trimmedShare))) { //if share not in the list
                    return state.updateIn([consts.CASH_KEYS._SHOW_SHARES, showSharesKey, "folders", "-", "data"], data => addNewShare(data))
                        .updateIn([consts.CASH_KEYS._SHOW_SHARES, showSharesKey, "data"], data => addNewShare(data))
                        .setIn([consts.CASH_KEYS._SHOW_SHARES, showSharesKey, "failedRetrieve"], false)
                }
            }
            return state;
        }
        case actionCreatorsConsts.ADD_EXPORT_MANUALLY: {
            const {exportName, nfsServer, groupId} = action.payload;

            if (exportName) {
                const trimmedExport = exportName[exportName.length - 1] === "/" ? exportName.substring(0, exportName.length - 1) : exportName;
                const exportObject = {
                    actualPath: trimmedExport,
                    path: trimmedExport,
                    host: nfsServer
                };
                const addNewExport = (data) => {
                    var dataMutable = data ? data.asMutable() : [];
                    dataMutable.unshift(exportObject);
                    return Immutable(dataMutable);
                };
                const showExportsKey = `${nfsServer}-${groupId}`;
                if (!(state._showExports[showExportsKey].data && state._showExports[showExportsKey].data.find(exp => exp.path === trimmedExport))) { //if export not in the list
                    return state.updateIn([consts.CASH_KEYS._SHOW_EXPORTS, showExportsKey, "folders", "-", "data"], data => addNewExport(data))
                        .updateIn([consts.CASH_KEYS._SHOW_EXPORTS, showExportsKey, "data"], data => addNewExport(data))
                        .setIn([consts.CASH_KEYS._SHOW_EXPORTS, showExportsKey, "failedRetrieve"], false)
                }
            }
            return state;
        }
        case actionCreatorsConsts.RESET_CACHE_FOR_RELOAD: {
            const { cacheName, storageKey } = action.payload;
            const updateState = state.setIn([cacheName, storageKey, "retrievedAll"], false).setIn([cacheName, storageKey, "failedRetrieve"], false);

            return updateState;
        }
        case actionCreatorsConsts.CREATE_SYNC: {
            const updatedState = state.update("_wizard", _wizard => _wizard.set("sync", buildStateFromAction(action)));
            if (action.status === actionCreatorsConsts.STATUS_PROGRESS) {
                return updatedState.update("_wizard", _wizard => _wizard.set("hideHeader", true));
            }
            if (action.status === actionCreatorsConsts.STATUS_ERROR) {
                return updatedState.update("_wizard", _wizard => _wizard.set("hideHeader", false));
            }
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS) {
                //send event to GA
                sendEvents('relationship', "created_relationship");
                const updatedState2 = updatedState
                    .updateIn(["_wizard", "sync"], sync => sync.set("completed", false).set("relationshipId", action.payload.id))
                    .update("_wizard", _wizard => _wizard.set("hideHeader", true))
                    .setIn(["_ui", "hasNewRelationship"], true);
                if (action.payload.activity.status === "PENDING") {
                    const startTime = action.payload.settings.schedule.nextTime;
                    const startTimeDisplay = getNextTimeHour(startTime);
                    return updatedState2.updateIn(["_wizard", "sync"], sync => sync.set("isPending", true).set("startTime", startTimeDisplay));
                } else {
                    return updatedState2.updateIn(["_wizard", "sync"], sync => sync.set("percentCopied", 0).set("bytesCopied", 0).set("isPending", false));
                }
            } else return updatedState;
        }
        case actionCreatorsConsts.SHOW_EXPORTS: {
            //exports cache is saved by host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const fullKey = [consts.CASH_KEYS._SHOW_EXPORTS, `${action.extra.host}-${action.extra.groupId}`];
            return listActionsUtils.updateStoreForShowAction(fullKey, ['host', 'groupId'], state, action);
        }
        case actionCreatorsConsts.UPDATE_EXPORTS: {
            //exports cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const host = action.payload[consts.PAYLOAD_SHOW_EXPORTS].host;
            const groupId = action.payload.groupId;
            const fullKey = [consts.CASH_KEYS._SHOW_EXPORTS, `${host}-${groupId}`];
            return listActionsUtils.updateStoreForUpdateShowActionForFileServer(fullKey, consts.PAYLOAD_SHOW_EXPORTS, 'export', state, action, isHostEqual.bind(this, fullKey, consts.PAYLOAD_SHOW_EXPORTS, 'export', state, action));
        }
        case actionCreatorsConsts.LIST_FOLDERS: {
            //exports cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const fullKey = [consts.CASH_KEYS._SHOW_EXPORTS, `${action.extra.fileServer}-${action.extra.groupId}`];
            return listActionsUtils.updateStoreForListFoldersAction(fullKey, action.extra.path, state, action);

        }
        case actionCreatorsConsts.UPDATE_FOLDERS: {
            //exports cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const nfsServer = action.payload[consts.PAYLOAD_LIST_FOLDERS].host;
            const groupId = action.payload.groupId;
            const fullKey = [consts.CASH_KEYS._SHOW_EXPORTS, `${nfsServer}-${groupId}`];
            return updateStoreForUpdateFoldersActionForFileServer(fullKey, consts.PAYLOAD_LIST_FOLDERS, state, action);

        }
        case actionCreatorsConsts.SHOW_SHARES: {
            //shares cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const fullKey = [consts.CASH_KEYS._SHOW_SHARES, `${action.extra.host}-${action.extra.groupId}`];
            return listActionsUtils.updateStoreForShowAction(fullKey, ['host', 'groupId', 'domain', 'username', 'password'], state, action);
        }
        case actionCreatorsConsts.UPDATE_SHARES: {
            //shares cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const host = action.payload[consts.PAYLOAD_LIST_CIFS_SHARES].host;
            const groupId = action.payload.groupId;
            const fullKey = [consts.CASH_KEYS._SHOW_SHARES, `${host}-${groupId}`];
            return listActionsUtils.updateStoreForUpdateShowActionForFileServer(fullKey, consts.PAYLOAD_LIST_CIFS_SHARES, "share", state, action, isHostEqual.bind(this, fullKey, consts.PAYLOAD_LIST_CIFS_SHARES, "share", state, action));

        }
        case actionCreatorsConsts.LIST_SHARE_FOLDERS: {
            //shares cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const fullKey = [consts.CASH_KEYS._SHOW_SHARES, `${action.extra.host}-${action.extra.groupId}`];
            return listActionsUtils.updateStoreForListFoldersAction(fullKey, action.extra.path, state, action);

        }
        case actionCreatorsConsts.UPDATE_SHARE_FOLDERS: {
            //shares cache is saved by both host and dataBrokerId to handle cases of same host but different data broker (can be in encrypted relationship)
            const host = action.payload[consts.PAYLOAD_LIST_CIFS_SHARE_FOLDERS].host;
            const groupId = action.payload.groupId;
            const fullKey = [consts.CASH_KEYS._SHOW_SHARES, `${host}-${groupId}`];
            return updateStoreForUpdateFoldersActionForFileServer(fullKey, consts.PAYLOAD_LIST_CIFS_SHARE_FOLDERS, state, action);

        }
        case actionCreatorsConsts.LIST_SFTP_ROOT_DIRECTORY: {
            const fullKey = ["_showSftpDirectories", `${action.extra.host}`];
            return listActionsUtils.updateStoreForShowAction(fullKey, ['host'], state, action);
        }
        case actionCreatorsConsts.UPDATE_SFTP_ROOT_DIRECTORY: {
            const {host} = action.payload[consts.PAYLOAD_LIST_SFTP_ROOT_DIRECTORY];
            const fullKey = ["_showSftpDirectories", `${host}`];
            return listActionsUtils.updateStoreForUpdateShowActionForFileServer(fullKey, consts.PAYLOAD_LIST_SFTP_ROOT_DIRECTORY, "sftp", state, action, isHostEqual.bind(this, fullKey, consts.PAYLOAD_LIST_SFTP_ROOT_DIRECTORY, "sftp", state, action), "directories");
        }
        case actionCreatorsConsts.LIST_SFTP_DIRECTORIES: {
            const fullKey = ["_showSftpDirectories", `${action.extra.host}`];
            return listActionsUtils.updateStoreForListFoldersAction(fullKey, action.extra.path, state, action);
        }
        case actionCreatorsConsts.UPDATE_SFTP_DIRECTORIES: {
            const {host} = action.payload[consts.PAYLOAD_LIST_SFTP_DIRECTORIES];
            const fullKey = ["_showSftpDirectories", `${host}`];
            return updateStoreForUpdateFoldersActionForFileServer(fullKey, consts.PAYLOAD_LIST_SFTP_DIRECTORIES, state, action, "directories");
        }
        case actionCreatorsConsts.LIST_BOX_ROOT_FOLDER: {
            const fullKey = ["_showBoxFolders", `${action.extra.appName}`];
            return listActionsUtils.updateStoreForShowAction(fullKey, ['appName'], state, action);
        }
        case actionCreatorsConsts.UPDATE_BOX_ROOT_FOLDER: {
            const appName = action.payload[consts.PAYLOAD_LIST_BOX_ROOT_FOLDER].appName;
            const fullKey = ["_showBoxFolders", `${appName}`];
            return listActionsUtils.updateStoreForUpdateShowActionForBox(fullKey, consts.PAYLOAD_LIST_BOX_ROOT_FOLDER, state, action,
                isEqualByInnerKey.bind(this, fullKey, consts.PAYLOAD_LIST_BOX_ROOT_FOLDER, state, action, "appName"));
        }
        case actionCreatorsConsts.LIST_BOX_FOLDERS: {
            const fullKey = ["_showBoxFolders", `${action.extra.appName}`];
            return listActionsUtils.updateStoreForListFoldersAction(fullKey, action.extra.folderName, state, action);
        }
        case actionCreatorsConsts.UPDATE_BOX_FOLDERS: {
            const appName = action.payload[consts.PAYLOAD_LIST_BOX_FOLDERS].appName;
            const fullKey = ["_showBoxFolders", `${appName}`];
            return updateStoreForUpdateBoxFolders(fullKey, consts.PAYLOAD_LIST_BOX_FOLDERS, state, action);
        }
        case actionCreatorsConsts.LIST_GOOGLE_DRIVES: {
            const fullKey = ["_showGoogleDrives", `${action.extra.subject}`]; //userEmail is subject
            return listActionsUtils.updateStoreForShowAction(fullKey, ['subject'], state, action);
        }
        case actionCreatorsConsts.UPDATE_GOOGLE_DRIVES: {
            const subject = action.payload[consts.PAYLOAD_LIST_GOOGLE_DRIVES].subject; //userEmail is subject
            const fullKey = ["_showGoogleDrives", `${subject}`];
            return listActionsUtils.updateStoreForUpdateShowActionForBox(fullKey, consts.PAYLOAD_LIST_GOOGLE_DRIVES, state, action,
                isEqualByInnerKey.bind(this, fullKey, consts.PAYLOAD_LIST_GOOGLE_DRIVES, state, action, "subject"), "drives");
        }
        case actionCreatorsConsts.LIST_GOOGLE_DRIVE_FOLDERS: {
            const fullKey = ["_showGoogleDrives", `${action.extra.subject}`];
            return listActionsUtils.updateStoreForListFoldersAction(fullKey, action.extra.folderName, state, action);
        }
        case actionCreatorsConsts.UPDATE_GOOGLE_DRIVE_FOLDERS: {
            const {subject} = action.payload[consts.PAYLOAD_LIST_GOOGLE_DRIVE_FOLDERS];
            const fullKey = ["_showGoogleDrives", `${subject}`];
            return updateStoreForUpdateGoogleDriveFolders(fullKey, consts.PAYLOAD_LIST_GOOGLE_DRIVE_FOLDERS, state, action);
        }
        case actionCreatorsConsts.SHOW_BUCKETS: {
            return listActionsUtils.updateStoreForShowAction(["_showBuckets", action.extra.groupId], [], state, action);
        }
        case actionCreatorsConsts.UPDATE_BUCKETS: {
            return listActionsUtils.updateStoreForUpdateBucketsAction(["_showBuckets", action.payload.groupId], consts.PAYLOAD_LIST_BUCKETS, "buckets", state, action);
        }
        case actionCreatorsConsts.LIST_BUCKETS_FOLDERS: {
            return listActionsUtils.updateStoreForListFoldersAction(["_showBuckets", action.extra.groupId], action.extra.path, state, action, isObjExistInField.bind(this, ["_showBuckets", action.extra.groupId], state, {name: action.extra.bucket}));
        }
        case actionCreatorsConsts.UPDATE_BUCKETS_FOLDERS: {
            return listActionsUtils.updateStoreForUpdateBucketsFoldersAction(["_showBuckets", action.payload.groupId], consts.PAYLOAD_LIST_SUB_DIRECTORIES, state, action);
        }
        case actionCreatorsConsts.SHOW_AZURE_BLOB_CONTAINERS: {
            return listActionsUtils.updateStoreForShowAction(["_showAzureBlobContainers", action.extra.storageAccountName], ["storageAccountName", "connectionString", "groupId"], state, action);
        }
        case actionCreatorsConsts.UPDATE_CONTAINERS: {
            return listActionsUtils.updateStoreForUpdateBucketsAction(["_showAzureBlobContainers", action.payload[consts.PAYLOAD_LIST_CONTAINERS].storageAccountName], consts.PAYLOAD_LIST_CONTAINERS, "containers", state, action);
        }
        case actionCreatorsConsts.LIST_CONTAINERS_FOLDERS: {
            return listActionsUtils.updateStoreForListFoldersAction(["_showAzureBlobContainers", action.extra.storageAccountName], action.extra.path, state, action, isObjExistInField.bind(this, ["_showAzureBlobContainers", action.extra.storageAccountName], state, {name: action.extra.container}));
        }
        case actionCreatorsConsts.UPDATE_CONTAINERS_FOLDERS: {
            return listActionsUtils.updateStoreForUpdateBucketsFoldersAction(["_showAzureBlobContainers", action.payload[consts.PAYLOAD_LIST_CONTAINER_SUB_DIRECTORIES].storageAccountName], consts.PAYLOAD_LIST_CONTAINER_SUB_DIRECTORIES, state, action, "container", "folders");
        }
        case actionCreatorsConsts.SHOW_AZURE_DATA_LAKE_CONTAINERS: {
            return listActionsUtils.updateStoreForShowAction(["_showAzureDataLakeContainers", action.extra.storageAccountName], ["storageAccountName", "connectionString", "groupId"], state, action);
        }
        case actionCreatorsConsts.UPDATE_DATA_LAKE_CONTAINERS: {
            return listActionsUtils.updateStoreForUpdateBucketsAction(["_showAzureDataLakeContainers", action.payload[consts.PAYLOAD_LIST_DATA_LAKE_CONTAINERS].storageAccountName], consts.PAYLOAD_LIST_DATA_LAKE_CONTAINERS, "containers", state, action);
        }
        case actionCreatorsConsts.LIST_AZURE_DATA_LAKE_CONTAINERS_FOLDERS: {
            return listActionsUtils.updateStoreForListFoldersAction(["_showAzureDataLakeContainers", action.extra.storageAccountName], action.extra.path, state, action, isObjExistInField.bind(this, ["_showAzureDataLakeContainers", action.extra.storageAccountName], state, {name: action.extra.container}));
        }
        case actionCreatorsConsts.UPDATE_DATA_LAKE_CONTAINERS_FOLDERS: {
            return listActionsUtils.updateStoreForUpdateBucketsFoldersAction(["_showAzureDataLakeContainers", action.payload[consts.PAYLOAD_LIST_DATA_LAKE_CONTAINER_SUB_DIRECTORIES].storageAccountName], consts.PAYLOAD_LIST_DATA_LAKE_CONTAINER_SUB_DIRECTORIES, state, action, "container", "folders");
        }

        case actionCreatorsConsts.SHOW_S3BASED_BUCKETS: {
            const key = action.extra.accessKey ? `${action.extra.host}-${action.extra.accessKey}` : action.extra.host
            return listActionsUtils.updateStoreForShowAction(["_showS3BasedBuckets", key], ["host", "groupId", "port", "accessKey", "secretKey"], state, action);
        }
        case actionCreatorsConsts.UPDATE_S3BASED_BUCKETS: {
            const payloadName = Object.keys(action.payload).find(key => key.startsWith("list"));
            const theHost = action.payload.data ? (action.payload.data.host ? action.payload.data.host : action.payload[payloadName].host) : action.payload[payloadName].host;
            const key = action.payload[payloadName].accessKey ? `${theHost}-${action.payload[payloadName].accessKey}` : theHost;
            return listActionsUtils.updateStoreForUpdateBucketsAction(["_showS3BasedBuckets", key], payloadName, "buckets", state, action);
        }
        case actionCreatorsConsts.LIST_S3BASED_BUCKETS_FOLDERS: {
            const key = action.extra.accessKey ? `${action.extra.host}-${action.extra.accessKey}` : action.extra.host
            return listActionsUtils.updateStoreForListFoldersAction(["_showS3BasedBuckets", key], action.extra.path, state, action, isObjExistInField.bind(this, ["_showS3BasedBuckets", key], state, {name: action.extra.bucket}));
        }
        case actionCreatorsConsts.UPDATE_S3BASED_BUCKETS_FOLDERS: {
            const payloadName = Object.keys(action.payload).find(key => key.startsWith("list"));
            const theHost = action.payload.data ? (action.payload.data.host ? action.payload.data.host : action.payload[payloadName].host) : action.payload[payloadName].host;
            const key = action.payload[payloadName].accessKey ? `${theHost}-${action.payload[payloadName].accessKey}` : theHost;
            return listActionsUtils.updateStoreForUpdateBucketsFoldersAction(["_showS3BasedBuckets", key], payloadName, state, action);
        }
        case actionCreatorsConsts.SHOW_GCP_BUCKETS: {
            return listActionsUtils.updateStoreForShowAction(["_showGCPBuckets", action.extra.groupId], [], state, action);
        }
        case actionCreatorsConsts.UPDATE_GCP_BUCKETS: {
            return listActionsUtils.updateStoreForUpdateBucketsAction(["_showGCPBuckets", action.payload.groupId],
                consts.PAYLOAD_LIST_GCP_BUCKETS, "buckets", state, action);
        }
        case actionCreatorsConsts.LIST_GCP_BUCKET_FOLDERS: {
            return listActionsUtils.updateStoreForListFoldersAction(["_showGCPBuckets", action.extra.groupId], action.extra.path, state, action, isObjExistInField.bind(this, ["_showGCPBuckets", action.extra.groupId], state, {name: action.extra.bucket}));
        }
        case actionCreatorsConsts.UPDATE_GCP_BUCKET_FOLDERS: {
            return listActionsUtils.updateStoreForUpdateBucketsFoldersAction(["_showGCPBuckets", action.payload.groupId], consts.PAYLOAD_LIST_GCP_BUCKET_FOLDERS, state, action);
        }
        case actionCreatorsConsts.DOWNLOAD_DATA_BROKER_LOGS: {
            if (action.status === actionCreatorsConsts.STATUS_PROGRESS) {
                const {extra: {relationshipId}} = action;
                return state.setIn(["_downloadLogsRequests", relationshipId], {status: actionCreatorsConsts.STATUS_PROGRESS});
            }
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS) {
                const {requestId, extra: {relationshipId}} = action;
                return state.setIn(["_downloadLogsRequests", relationshipId, 'requestId'], requestId);
            }
            return state;
        }
        case actionCreatorsConsts.GET_RELATIONSHIPS: {
            let nextState = updateKeyWithBuiltState(state, action, ["_relationships"], (builtState) => {
                if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {

                    builtState.data.map((entry) => {
                        entry.lastCopyMessage = entry.lastCopyMessage || {};
                        entry.lastScanMessage = entry.lastScanMessage || {};
                        entry.lastSyncMessage = entry.lastSyncMessage || undefined;
                        entry.lastRequests = entry.lastRequests || [];

                        processRelationship(entry);

                        return entry;
                    });
                }
                return builtState;
            });
            return nextState.update("_ui", _ui => _ui.set("hasNewRelationship", false));
        }
        case actionCreatorsConsts.UPDATE_KMS_KEYS: {
            const {keys, bucket} = action.payload[consts.PAYLOAD_LIST_KMS_KEYS];
            const {groupId} = action.payload;
            if (!state._showBuckets) {
                console.log(`%c got update_kms_keys for data broker id ${groupId} when there are no show buckets, ignoring`, 'font-weight:bold; color:blue');
                return state;
            } //maybe we get this message too late. not in the wizard and there is no show buckets.
            const updatedBuckets = map(state._showBuckets[groupId].data, item => (item.name === bucket) ? {...item, kmsKeys: keys} : item);
            return state.setIn(["_showBuckets", groupId, 'data'], updatedBuckets);
        }
        case actionCreatorsConsts.UPDATE_S3_PRIVATE_LINKS: {
            const {s3PrivateLinks, bucket, status, reason} = action.payload[consts.PAYLOAD_LIST_S3_PRIVATE_LINKS];
            const whatToUpdate = status === consts.RESPONSE_STATUS_FAILED ? {s3PrivateLinksErrorReason: reason, s3PrivateLinks: []} : {s3PrivateLinks}
            const {groupId} = action.payload;
            if (!state._showBuckets) {
                console.log(`%c got update_s3_private_links for data broker group id ${groupId} when there are no show buckets, ignoring`, 'font-weight:bold; color:blue');
                return state;
            } //maybe we get this message too late. not in the wizard and there is no show buckets.
            if (!groupId) {
                console.log(`%c got update_s3_private_links for without data broker group id ${JSON.stringify(action.payload, null, 2)}, ignoring`, 'font-weight:bold; color:red');
                return state;
            }
            const updatedBuckets = map(state._showBuckets[groupId].data, item => (item.name === bucket) ? {...item, ...whatToUpdate} : item);
            return state.setIn(["_showBuckets", groupId, 'data'], updatedBuckets);
        }
        case actionCreatorsConsts.GET_CVS_WORKING_ENVIRONMENTS: {
            return updateKeyWithBuiltState(state, action, ["_cvsWorkingEnvironments"]);
        }
        case actionCreatorsConsts.GET_ANF_VOLUMES: {
            return updateKeyWithBuiltState(state, action, [consts.CASH_KEYS._ANF_VOLUMES, action.extra.credentialsId]);
        }
        case actionCreatorsConsts.GET_CM_WORKING_ENVIRONMENTS: {
            return updateKeyWithBuiltState(state, action, ["_cmWorkingEnvironments"]);
        }
        case actionCreatorsConsts.GET_CM_VOLUMES: {
            action.payload = values(action.payload); //get only values as array
            return updateKeyWithBuiltState(state, action, ["_cmVolumes", action.extra.weId]);
        }
        case actionCreatorsConsts.GET_FSX_WORKING_ENVIRONMENTS: {
            return updateKeyWithBuiltState(state, action, ["_fsxWorkingEnvironments"]);
        }
        case actionCreatorsConsts.GET_FSX_VOLUMES: {
            action.payload = values(action.payload); //get only values as array
            return updateKeyWithBuiltState(state, action, [consts.CASH_KEYS._FSX_VOLUMES, action.extra.weId]);
        }
        case actionCreatorsConsts.GET_ENDPOINTS_AND_REPORTS: {
            return updateKeyWithBuiltState(state, action, ["_endpoints"], (builtState) => {
                if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                    builtState.data.map((entry) => processEndpoint(entry))
                }
                return builtState;
            });
        }
        case actionCreatorsConsts.CREATE_REPORT: {
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS && action.payload) {
                const {id, endpoint, startTime, statistics} = action.payload;
                const endPointTitle = createStringFromEndPointObject(endpoint);
                return state.updateIn(['_endpoints', 'data'], endpoints => {
                    return endpoints.map((endpoint) => {
                        let updatedEndpoint = endpoint;
                        if (endpoint.id === endPointTitle) {
                            updatedEndpoint = cloneDeep(endpoint);
                            const newReport = {
                                id,
                                startTime,
                                endTime: startTime,
                                statistics,
                                status: REPORT_STATUS_RUNNING
                            }
                            if (updatedEndpoint.reports) updatedEndpoint.reports.unshift(newReport)
                            else updatedEndpoint.reports = [newReport]
                            return processEndpoint(updatedEndpoint);
                        }
                        return updatedEndpoint;

                    })
                });
            }
            return state;
        }
        case actionCreatorsConsts.UPDATE_REPORT: {
            const payloadElement = action.payload[consts.PAYLOAD_UPDATE_REPORT];
            if (!state._endpoints?.data) return state; //if we are currently getting the endpoints and the list is empty skip updating
            //the updated endpoint will arrive in the getEndpoints.

            //now update in endpoints data
            return state.updateIn(['_endpoints', 'data'], endpoints => {
                return endpoints.map((endpoint) => {
                    let updatedEndpoint = endpoint;
                    const foundReport = find(endpoint.reports, {id: payloadElement.id});
                    if (foundReport) {
                        updatedEndpoint = cloneDeep(endpoint);
                        updatedEndpoint.reports = without(updatedEndpoint.reports, find(updatedEndpoint.reports, {id: foundReport.id})); //need to find again since it is cloned
                        updatedEndpoint.reports.unshift({...payloadElement})
                        return processEndpoint(updatedEndpoint);
                    }
                    return updatedEndpoint;

                })
            });
        }
        case actionCreatorsConsts.DELETE_REPORTS: {
            return updateKeyWithBuiltState(state, action, ["_deleteReports"]);
        }

        default:
            return state;
    }
});


export const filteringFunc = (action) => {
    //handle update messages only in certain routes, to optimize the reducer
    const ignoreUpdateRelationship = action.type === actionCreatorsConsts.UPDATE_RELATIONSHIP &&
        !(window.location.href.includes("dashboard") ||
            window.location.href.includes("review")||
            window.location.href.includes("sync-new") ||
            window.location.href.includes("timeline"));
    const ignoreUpdateTransferRateCondition = action.type === actionCreatorsConsts.UPDATE_DATABROKER_TRANSFER_RATE && !window.location.href.includes("dashboard");
    return ignoreUpdateRelationship || ignoreUpdateTransferRateCondition;
};
