import React, {useCallback, useRef, useState} from 'react'
import classNames from 'classnames';
import _ from 'lodash'
import useEvent from "../../../hooks/useEvent";

const calculateAllFilter = (filterValues) => {
    return {
        className: "all",
        name: "All",
        checked: _.reduce(filterValues, (checked, value) => checked && value.checked, true)
    };
};

const FilterByListComp = (props) => {
    const {filterValues, apply, fixed } = props;

    const wrapperRef = useRef()
    const all = calculateAllFilter(filterValues);
    const clonedFilterValues = _.cloneDeep(filterValues);
    const filterList = [...clonedFilterValues];
    const originalFilterList = _.cloneDeep(filterList);
    const [state, setState] = useState( {all, filterList, originalFilterList});

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setState({filterList: _.cloneDeep(state.originalFilterList), all: calculateAllFilter(state.originalFilterList)}); //return to original list
            props.close();
        }
    }

    useEvent('mousedown', handleClickOutside)

    const applyFilter = () => {
        apply(state.filterList);
    }

    const handleClick = useCallback((name, event) => {
        const filterList = state.filterList.map(filter => {
            if (name === "All"|| filter.name === name) {
                filter.checked = event.target.checked;
            }
            return filter;
        });

        const allFilter = state.all;
        allFilter.checked = _.reduce(filterList, (checked, value) => checked && value.checked, true);
        setState((prevState) => ({...prevState, all: allFilter, filterList}))
    }, [state])

    const clear = () => {
        handleClick("All", {target: {checked: true}});
        applyFilter();
    }

    const buildFieldElement = (value) => {
        return(
            <label title={value.name} key={value.name}>
            <input className={`value ${value.className}`}
                   onChange={(event) => handleClick(value.name, event)}
                   name={value.name} type="checkbox"
                   checked={value.checked}
            />{value.name}</label>
        );
    };

    const noneChecked = () => {
        return !_.reduce(state.filterList, (checked, value) => checked || value.checked, false);
    };

    const allChecked = () => {
        return _.every(state.filterList, filter => filter.checked);
    };

    return (
        <div className={classNames("filter-widget", {fixed})} ref={wrapperRef}>
            <div className="filters-list">
                {buildFieldElement(state.all)}
                {state.filterList.map(value => {
                    return buildFieldElement(value);
                })}
            </div>
            <div className={"buttons-row"}>
                <button type="button" disabled={allChecked()} onClick={clear}>Clear</button>
                <button type="button" disabled={noneChecked()} onClick={applyFilter}>Apply</button>
            </div>
        </div>
    );
}

export default FilterByListComp;
