import React from "react";
import {CSSTransition} from "react-transition-group";
import classNames from "classnames";
import "./rightPanel.scss";

const RightPanel = ({content}) => {
    const isOpen = !!content;
    return (<div className="right-panel">
        <div className={classNames("slider-mask", {"show-mask": isOpen})}/>
        <CSSTransition in={isOpen} classNames="slide-from-right" timeout={400} unmountOnExit>
            <div key="0" className="slide-from-right-parent">
                <div className="right-panel-content">
                    {content}
                </div>
            </div>
        </CSSTransition>
    </div>)
};

export default RightPanel;