import React from 'react';
import ProgressBar from '../_common_/progressBar/ProgressBar';
import {ReactComponent as DataBrokerIcon} from "./../../assets/svgs/group-icon.svg";

import "./dataBrokerLoadingPage.scss";

const DataBrokerLoadingPage = () => {

    return (<div className="data-broker-wizard-step data-broker-loading-page">
        <div className="content-area">
            <DataBrokerIcon className="icon fade-in"/>
            <div className="title fade-in">Loading page to deploy the Data Broker</div>
            <div className="note fade-in">The process takes around 30 seconds</div>
            <ProgressBar/>
        </div>
    </div>);
};

export default DataBrokerLoadingPage;
