import React, {useCallback} from 'react';
import Validator from 'validatorjs';
import {reduxForm, Field, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import {InputField, RadioInputs, SelectField} from '../_common_/forms/forms';
import _ from 'lodash';
import useAwsForm from './useAwsForm';
import {AWS_BROKER_POLICY_REQUIREMENTS} from '../../consts';
import InstanceDetailsColumnHeader from './InstanceDetailsColumnHeader';
import ButtonsPanel from '../widgets/ButtonsPanel';
import {INSTANCE_DETAILS_FORM, SERVICE_NAME} from "../../consts";

import './instanceDetailsAwsForm.scss';

const InstanceDetailsAwsForm = ({submitting, handleContinue, handleError, change, credentials = {}, handleSubmit, formValues}) => {
    const {selectedVpc} = formValues || {};

    const {vpcsOptions, subnetsOptions, keyPairsOptions, kmsKeysOptions} = useAwsForm(credentials, selectedVpc?.value, handleError);

    const clearFields = useCallback((fieldArr) => fieldArr.forEach(fieldName => change(fieldName, '')), [change]);

    return (<form className="data-broker-wizard-step instance-details-aws" onSubmit={handleSubmit(values => handleContinue(extractValues(values)))}>
        <div className="title">Basic Settings</div>
        <div className="content-area">
            <div className="form-content">
                <div className="col left">
                    <InstanceDetailsColumnHeader text={"Location"}/>
                    <Field component={SelectField}
                           variant='two-lines'
                           onChange={() => clearFields(['selectedSubnet'])}
                           name="selectedVpc"
                           options={vpcsOptions}
                           label="VPC"
                           isLoading={!vpcsOptions}
                           placeholder="Select VPC"
                           valueField="value"
                           isDisabled={submitting}
                           noOptionsMessage={() => `No VPCs available`}
                    />
                    <Field component={SelectField}
                           name="selectedSubnet"
                           variant='two-lines'
                           options={subnetsOptions}
                           label="Subnet"
                           isLoading={selectedVpc && !subnetsOptions}
                           placeholder="Select Subnet"
                           valueField="value"
                           isDisabled={submitting || !selectedVpc}
                           noOptionsMessage={() => `No Subnets available`}
                    />
                </div>
                <div className="col right">
                    <InstanceDetailsColumnHeader text={"Connectivity"}/>
                    <Field component={SelectField}
                           name="selectedKeyPair"
                           options={keyPairsOptions}
                           label="Key Pair"
                           isLoading={!keyPairsOptions}
                           placeholder="Select Key Pair"
                           valueField="value"
                           isDisabled={submitting}
                           noOptionsMessage={() => `No Key Pair available`}
                    />

                    <div className="section-title">Enable Public IP?</div>
                    <Field name="publicIpStatus" component={RadioInputs}
                           disabled={submitting}
                           changeSelection={_.noop}
                           checkedIndex={formValues?.publicIpStatus === 'Disable' ? 1 : 0}
                           inputs={['Enable', 'Disable']}/>

                    <div className="section-title">IAM Role (optional)</div>
                    <Field name="userRole"
                           component={InputField}
                           label="IAM Role (optional)"
                           disabled={submitting}
                           info={ <div>The name of an existing IAM role to attach to the NetApp Data Broker. <a
                                   href={AWS_BROKER_POLICY_REQUIREMENTS}
                                   target="_blank"
                                   rel="noopener noreferrer">The role must have these permissions</a>. If you don’t
                                   provide an existing role, then {SERVICE_NAME} will create one for you.</div>}
                           infoProps={{trigger: "click"}}
                    />
                    <div className="section-title">KMS Key for EBS volume (optional)</div>
                    <Field name="selectedKmsKey"
                           component={SelectField}
                           variant='two-lines'
                           options={kmsKeysOptions}
                           isLoading={!kmsKeysOptions}
                           placeholder="Select KMS Key for EBS Encryption"
                           valueField="value"
                           isDisabled={submitting}
                           noOptionsMessage={() => `No Kms Keys available`}
                    />
                </div>
            </div>
        </div>

        <ButtonsPanel
            variant="transparent"
            type="submit"
            text="Continue"
            submitting={submitting}/>

    </form>);
};

const validate = (values) => {
    const rules = {
        selectedVpc: ['required'],
        selectedSubnet: ['required'],
        selectedKeyPair: ['required']};

    const validator = new Validator(values, rules);
    validator.setAttributeNames({
        selectedVpc: 'VPC',
        selectedSubnet: 'Subnet',
        selectedKeyPair: 'Key Pair'});
    validator.passes();
    return validator.errors.all();
};

const connectedToForm = reduxForm({
    form: INSTANCE_DETAILS_FORM,
    validate,
    destroyOnUnmount: false
})(InstanceDetailsAwsForm);

export default connect(state => ({
    formValues: getFormValues(INSTANCE_DETAILS_FORM)(state),
}))(connectedToForm);

const extractValues = (values) => {
    const {selectedVpc, selectedSubnet, publicIpStatus, selectedKeyPair, userRole, selectedKmsKey} = values;
    return {
        vpc: selectedVpc?.value,
            subnet: selectedSubnet?.value,
        keyPair: selectedKeyPair?.value,
        enablePublicIp: (publicIpStatus === "Disable") ? "False" : "True",
        userRole: userRole || '',
        kmsKey: selectedKmsKey?.value ||''
    }
};
