import React from 'react';
import Isvg from 'react-inlinesvg';
import {ReactComponent as DirectoryIcon} from "../../../assets/svgs/directory-connected.svg";

import "./reviewEndpointCardNew.scss";

const ReviewEndpointCard = ({provider, title, description, subtitle, subtitleDescription}) => {
    const icon = <Isvg src={require(`../../../assets/svgs/${provider}-default.svg`)}/>;

    return (
        <div className="review-endpoint-card">
            <div className="endpoint endpoint-root">
                {icon}
                <div className="details">
                    <div className="name">{title}</div>
                    <div className="description" title={description}>{description}</div>
                </div>
            </div>
            {subtitleDescription && <div className="endpoint endpoint-directory">
                <DirectoryIcon/>
                <div className="details">
                    <div className="name">{subtitle}</div>
                    <div className="description" title={subtitleDescription}>{subtitleDescription}</div>
                </div>
            </div>}
        </div>
    );
};

export default ReviewEndpointCard;
