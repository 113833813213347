import {includes, isUndefined, isEmpty, set, cloneDeep, find, capitalize, reduce} from "lodash";
import moment from 'moment'
import {isNil, get, map} from 'lodash'
import * as actions from '../store/wizard/action_creators_consts.js';
import {PAYLOAD_UPDATE_RELATIONSHIP} from '../consts';
import numeral from "numeral";
import {mapProtocolToSourceSteps} from "./sync-utils"
import * as consts from "../consts";
import {RESPONSE_STATUS_DONE, RESPONSE_STATUS_RUNNING} from "../consts";
import {convertSpeed, createStringFromEndPointObject, formatActivityValues, statusConvert} from './helpers';
import * as actionCreatorsConsts from "../store/wizard/action_creators_consts";
import {fromNowNoFuture, stringDuration} from "./time";
import {
    getCopyDoneByCloudProvider,
    getFixedProtocol,
    getSVGByProtocol
} from "./dashboard-utils";
import {getNextTimeHour, SETTINGS_DEFAULTS} from "./settings-utils";
import Immutable from "seamless-immutable";
import {isCloudToCloud} from "./relationshipUtils";
import { NOTIFICATION_CONSTS } from "../consts/notification.consts.js";

var FifoArray = require('fifo-array');

export const isItSourceStep = (wizardInfo, stepId) => {
    return includes(mapProtocolToSourceSteps[wizardInfo.source.protocol], stepId);
};

export const updateCurrentStep = (state) => {
    const identifyCurrentStep = (stepsOrder, steps) => {
        return stepsOrder.find((stepName, index) => {
            return index+2 > stepsOrder.length || !steps[stepsOrder[index+1]]
        })
    };
    const identifiedStepId = identifyCurrentStep(state._wizard.stepsOrder, state._wizard.steps);
    //console.log(`@@@@@@@@@@@@@@@@@@@@@@@\n stepsOrder: ${state._wizard.stepsOrder}\nsteps:${JSON.stringify(state._wizard.steps)}\identifiedStepId: ${identifiedStepId}\n@@@@@@@@@@@@@@@@@@@@@@@@`);
    return state.update("_wizard", _wizard => _wizard.set("currentStep", identifiedStepId).set("isSourceStep", isItSourceStep(_wizard.wizardInfo, identifiedStepId)));
};


export const buildStateFromAction = (action, postProcessor = buildState => buildState) => {
    return postProcessor({
        id: action.id,
        failed: action.status === actions.STATUS_ERROR,
        inProgress: action.status === actions.STATUS_PROGRESS,
        succeeded: action.status === actions.STATUS_SUCCESS,
        data: action.status === actions.STATUS_SUCCESS ? action.payload : undefined,
        error: action.status === actions.STATUS_ERROR ? action.payload : undefined,
        //requestId: action.requestId
    });
};

export const updateLastRequestsForRelationshipsData = (state, action) => {
    const activityStatus = action.payload[PAYLOAD_UPDATE_RELATIONSHIP].activity.status;
    if(!state._relationships || !state._relationships.data) return state;
   return state.updateIn(["_relationships", "data"], relationships => {
        return relationships.map(relationship => {
            if(relationship.id === action.payload[PAYLOAD_UPDATE_RELATIONSHIP].id){
                relationship = cloneDeep(relationship);
                if(relationship && relationship.lastRequests){
                    const lastRequests = new FifoArray(3, relationship.lastRequests);
                    const curruntRequest = find(lastRequests , function(req) {
                        return req.id === action.payload.requestId;
                    });
                     if(curruntRequest){
                        curruntRequest.isDone = (activityStatus === RESPONSE_STATUS_DONE);
                    }
                    //new requestId
                    else{
                        lastRequests.push({
                            id : action.payload.requestId,
                            isDone : (activityStatus === RESPONSE_STATUS_DONE)
                        });
                    }
                    relationship.lastRequests = lastRequests;
                }
            }
            return relationship;
        })
    });
};

export const resetActivityValues = () => {
    const newAct = {
        bytesCopied: 0,
        bytesFailed: 0,
        bytesMarkedForCopy: 0,
        bytesMarkedForRemove: 0,
        bytesRemoved: 0,
        bytesRemovedFailed: 0,
        bytesScanned: 0,
        dirsCopied: 0,
        dirsFailed: 0,
        dirsMarkedForCopy: 0,
        dirsMarkedForRemove: 0,
        dirsRemoved: 0,
        dirsScanned: 0,
        filesCopied: 0,
        filesFailed: 0,
        filesMarkedForCopy: 0,
        filesMarkedForRemove: 0,
        filesRemoved: 0,
        filesRemovedFailed: 0,
        filesScanned: 0,
        progress: 0,
        type: "Sync",
        executionTime: 1,
        status: RESPONSE_STATUS_RUNNING
    };
    return formatActivityValues(newAct);
};

export const updateKeyWithBuiltState = (state, action, key, postProcessor) => {
    const originalState = Immutable.getIn(state, key);
    if(originalState && action.status !== actions.STATUS_PROGRESS && originalState.id !== action.id){
        // eslint-disable-next-line no-console
        console.log(`%c handleAction discarding action @ ${action.type} since the id doesn't match the id of the same action in the store`, "font-weight: bold;");
        return state;
    }

    return state.setIn(key, buildStateFromAction(action, postProcessor));
};

export const addUnhandledMessages = ((state, action) => {
    action = cloneDeep(action);
    action.unhandledCounter = action.unhandledCounter ? action.unhandledCounter + 1 : 1;
    if (action.unhandledCounter >= 5) {
        // eslint-disable-next-line no-console
        console.log(`%c addUnhandledMessages action @ ${action.type} wasn't handled for at least 5 messages, discarding`, "font-weight: bold;");
        return state;
    }
    else return state.update("unhandledMessages", unhandledMessages => unhandledMessages.concat(action));
});

export const credentialsRequiredOnDataBroker = (protocol, wizardInfo) => {
    if (protocol === 's3')  return (wizardInfo.source.protocol === protocol && wizardInfo.source.provider === protocol) ||
                                    (wizardInfo.target.protocol === protocol && wizardInfo.target.provider === protocol);

    if (protocol === 'gcp') return (wizardInfo.source.protocol === 'gcp' || wizardInfo.target.protocol === 'gcp');
    return false;
};

export const handleUnhandledMessages = (state, handleAction, metaData) => {
    return state.unhandledMessages.reduce((state, action) => {
        // eslint-disable-next-line no-console
        console.log(`%c Handling unhandled message @ ${action.type} (${action.unhandledCounter})`, "font-weight: bold;");
        return handleAction(state, action, metaData)
    }, state.set("unhandledMessages", []));
};

export const handleErrors = (action, state) => {
    if (action.status === actionCreatorsConsts.STATUS_ERROR && !action.selfErrorHandling) {
        const thePayload = action.payload;
        const errorTime = moment();
        let errorMessage = "Unknown error";
        let errorKey = action.type;
        if (!isUndefined(thePayload)) {
            const gotErrorResponse = !isEmpty(thePayload);
            const isNetworkError = gotErrorResponse && thePayload.message === "Network Error";
            const isClientError = !gotErrorResponse;
            errorKey = thePayload.status === 504 || thePayload.status === 502 || thePayload.status === 503 || isNetworkError
                ? "NETWORK"
                : action.type;
            errorMessage = thePayload.status === 504 || thePayload.status === 502 || thePayload.status === 503 || isNetworkError
                ? "Please check your network connectivity and refresh the browser."
                : (thePayload.data?.message || thePayload.data?.reason || thePayload?.statusText || thePayload?.response?.data?.message || thePayload.data || thePayload?.response?.data);
            if (!errorMessage) errorMessage = "Unknown error";

            //ugly patch to fix the firefox bug SER-394 During refresh of browser a connectivity error pops up in the client without a need, on messages.cancel the firefox throws it's own weired network error, we don't want to show it
            if (isNetworkError && thePayload.fileName && thePayload.fileName.indexOf("bundle.js") !== -1) {
                return state;
            }

            // ugly patch to fix unknown error in case there is an error from one of CM services- CS-6852
            if ([actionCreatorsConsts.GET_FSX_WORKING_ENVIRONMENTS, actionCreatorsConsts.GET_CM_WORKING_ENVIRONMENTS, actionCreatorsConsts.GET_CVS_WORKING_ENVIRONMENTS].includes(action.type)){
                errorMessage = `Failed to access Cloud Manager services:${errorMessage}`;
            }

            /* eslint-disable no-console */
            try {
                if (isClientError && console.error) {
                    console.error(action.payload)
                }
            } catch (e) {
                if (console.log) console.log(`Suppressed error ${action.payload.message}`);
            }
            /* eslint-enable no-console */
        }
        else {
            errorMessage = "Network Error. Please check your network connectivity and refresh the browser.";
        }

        const error = {
            type: NOTIFICATION_CONSTS.TYPE.ERROR,
            id: errorKey,
            children: 'An error occured',
            moreInfo: errorMessage,
            time: errorTime
        };

        const isExists = Array.isArray(state[consts.GLOBAL_KEYS._NOTIFICATIONS]) && state[consts.GLOBAL_KEYS._NOTIFICATIONS].find(notification => {
            return notification.type === error.type && notification.id === error.id;
        });
        return isExists ? state : state.updateIn([consts.GLOBAL_KEYS._NOTIFICATIONS], notifications => [...notifications, error]);
    }

    if (action.status && action.status !== actionCreatorsConsts.STATUS_ERROR) {
        const removeError = (notifications) => {
            let clean = notifications ? notifications.filter(n => !(n.type === NOTIFICATION_CONSTS.TYPE.ERROR &&
                                                    (n.id === action.type || n.id === actionCreatorsConsts.action_update_map[action.type]) )) : []

            // if the action succeeds, then of course we can remove network errors
            if (action.status === actionCreatorsConsts.STATUS_SUCCESS) clean = clean.filter(n => n.id !== "NETWORK");
            return clean;
        }

        return state.updateIn([consts.GLOBAL_KEYS._NOTIFICATIONS], removeError);
    } else return state;
};

export const processAgent = (entry) => {

    entry.isFailed = entry.status === consts.BROKER_STATUS_FAILED;
    entry.isWaiting = isEmpty(entry.placement);
    entry.isReady = !entry.isWaiting && !entry.isFailed;
    entry.isDisabled = false;
    entry.creationFrom = fromNowNoFuture(entry.createdAt);
    entry = attachDataBrokerUI(entry);

    if (entry.isFailed && entry.message) entry.agentFailureMessage = entry.message.message ? entry.message.message : entry.message;

    return entry;
};

export const processDataBrokerConfiguration = (state, payload) => {
    if (!get(state, '_dataBrokersGroups.data')) {
        return state;
    };

    const { header: { agentId, groupId }, localConfiguration: { data, reason, status } } = payload;
    return state.updateIn(['_dataBrokersGroups', 'data'], groups => {
        return groups.map(group => {
            if (group.id === groupId) {
                return {
                    ...group,
                    dataBrokers: group.dataBrokers.map(dataBroker => {
                        if (dataBroker.id === agentId) {
                            return {
                                ...dataBroker,
                                localConfiguration: {
                                    data,
                                    status,
                                    reason
                                }
                            }
                        }

                        return dataBroker;
                    })
                }
            }

            return group;
        });
    })
}

const attachDataBrokerUI = (dataBroker) => {
    dataBroker = cloneDeep(dataBroker);
    set(dataBroker, 'ui.transferRate', dataBroker.isReady && dataBroker.transferRate ? convertSpeed([dataBroker.transferRate]) : "");
    switch (dataBroker.type) {
        case consts.FM_AGENT_TYPE_AZURE.name:
            set(dataBroker, 'ui.type', consts.FM_AGENT_TYPE_AZURE);
            break;
        case consts.FM_AGENT_TYPE_GCP.name:
            set(dataBroker, 'ui.type', consts.FM_AGENT_TYPE_GCP);
            break;
        case consts.FM_AGENT_TYPE_ONPREM.name:
            set(dataBroker, 'ui.type', consts.FM_AGENT_TYPE_ONPREM);
            break;
        case consts.FM_AGENT_TYPE_AWS.name:
        default:
            set(dataBroker, 'ui.type', consts.FM_AGENT_TYPE_AWS);
            break;
    }
    return dataBroker;
};

export const processRelationship = (relationship) => {
    const isRunning = relationship.activity.status === consts.RESPONSE_STATUS_RUNNING;
    const { copyAcl, copyData } = relationship.settings || {};
    const sourceProtocol = relationship.source.protocol;
    const targetProtocol = relationship.target.protocol;
    const uploadScanning = relationship.activity.scanning;
    const calculatedProgress = uploadScanning ?
        `(preparing ${numeral(uploadScanning.objectsCompleted + uploadScanning.filesCompleted + uploadScanning.objectsIgnored).format('0.[0]a')})` :
        (isRunning && !isNil(relationship.activity.progress) ? `(${relationship.activity.progress}%)` : "");
    const statusConverted = relationship.activity.status === consts.RESPONSE_STATUS_DONE ?
        (relationship.activity.filesFailed === 0 && relationship.activity.dirsFailed === 0 && relationship.activity.dirsFailedToScan === 0 ? 'SUCCESS' : 'COMPLETE') : relationship.activity.status;
    const tags = relationship.target[relationship.target.protocol].tags;

    const estimatedTime = isRunning && !isNil(relationship.activity.timeEstimation) ? stringDuration(relationship.activity.timeEstimation * 1000) : undefined;
    const estimatedTimeText = estimatedTime ? `${estimatedTime === consts.TEXT_DURATION_FEW_SECONDS ? '' : 'About '}${estimatedTime} remaining` : '';
    const copyDoneByCloudProvider = getCopyDoneByCloudProvider(relationship.source, relationship.target);
    const cloudToCloud = isCloudToCloud(relationship);
    const hasOntapS3 = relationship.source[sourceProtocol].provider === 'ontap' ||  relationship.target[targetProtocol].provider === 'ontap';
    if (cloudToCloud || hasOntapS3) {
        relationship.settings.compareByCloudToCloud = {mtime : relationship.settings.compareBy.mtime};
    }

    if (copyAcl && !copyData) {
        relationship.settings.compareByAclOnly = SETTINGS_DEFAULTS.compareByAclOnly;
    }

    relationship.targetTags = tags;
    relationship.sourcePath = createStringFromEndPointObject(relationship.source);
    relationship.targetPath = createStringFromEndPointObject(relationship.target)
    const name = relationship.tags.length > 0 ? find(relationship.tags, {key: "name"})?.value || "": "";
    relationship.name = name;
    const statusConvertedText = statusConvert[statusConverted];
    relationship.statusForSearch = statusConvertedText;

    relationship.ui = Object.assign({}, formatActivityValues(relationship.activity), {
        statusText: statusConvertedText,
        progress: calculatedProgress,
        status: relationship.activity.status,
        pendingSyncStartTime: relationship.activity.status === consts.RESPONSE_STATUS_PENDING ? getNextTimeHour(get(relationship.settings, 'schedule.nextTime')) : '',
        isScheduleOn: get(relationship.settings, 'schedule.isEnabled') ? "ON" : "OFF",
        failureMessage: relationship.activity.failureMessage,
        syncFailedMessage: relationship.activity.errors?.length ? "Relationship failed to sync. Check the error details and try again." : "",
        actualTarget: relationship.actualTarget,
        source: relationship.source,
        target: relationship.target,
        host: relationship.host,
        type: relationship.activity.type,
        tags,
        groupId: relationship.group,
        estimatedTimeText,
        copyDoneByCloudProvider,
        recommendations: [],
        isDataSense: !!relationship.extraInformation?.dataSenseQueryUid
    });
};

export const processEndpoint = (endpointAndReports) => {
    const {endpoint, reports, relationshipIds} = endpointAndReports;
    const endPointSVG = getSVGByProtocol(getFixedProtocol(endpoint), false);
    const endPointTitle = createStringFromEndPointObject(endpoint);
    const lastReport = reports?.length ? reports[0] : null;
    const lastDuration = lastReport?.duration ? lastReport.duration : (lastReport?.startTime && lastReport?.endTime ? (moment(lastReport.endTime) - moment(lastReport.startTime)) : null);
    const humanizedDuration = lastDuration ? capitalize(stringDuration(lastDuration)) : '---';
    const numOfReports = reports?.length || 0;
    const lastReportTime = lastReport?.startTime ? moment(lastReport?.startTime).format('lll'): null;
    const reportsIds = map(reports, 'id');
    const errors = {
        scannedFiles: lastReport? lastReport.statistics.totals.directories +
            lastReport.statistics.totals.files +
            lastReport.statistics.totals.others +
            lastReport.statistics.totals.symbolicLinks : 0,
        scannedObjects: lastReport? lastReport.statistics?.totals?.objects : 0,
        failed: lastReport?.statistics?.totals?.failed,
        topErrors: lastReport?.statistics?.errors
    }
    const numOfErrors = lastReport && errors.topErrors && errors.topErrors.length > 0 ? reduce(errors.topErrors, (total, error) => {
        total += error.counter
        return total;
    }, 0) : 0;
    endpointAndReports.ui = {
        endPointTitle,
        endPointSVG,
        lastReport,
        lastReportTime,
        lastDuration,
        humanizedDuration,
        numOfReports,
        relationshipIds,
        reportsIds,
        numOfErrors,
        errors: numOfReports > 0 ? errors : null
    }
    endpointAndReports.id = endPointTitle;
    return endpointAndReports;
}
