import {CIFS_VERSIONS, DEFAULT_CIFS_VERSION, DEFAULT_NFS_VERSION, END_POINT_NAMES_MAP} from "../../../../consts";
import {supportAclCopy} from "../../../../utils/relationshipUtils";
import {InputField, SelectField} from "../../../_common_/forms/forms";
import AclSelectionFinalForm from "../AclSelectionFinalFormNew";
import NfsComponent from "./NfsComponentNew";
import S3BasedAdditionalSettings from "../additionalSettings/S3BasedAdditionalSettingsNew";
import {validate as cifsValidator} from "../additionalSettings/validators/cifsValidatorNew";
import {validate as s3BasedValidator} from "../additionalSettings/validators/s3BasedValidatorNew";
import {validate as azureValidator} from "../additionalSettings/validators/azureValidatorNew";
import {Field} from "react-final-form";
import {noop} from 'lodash'
import AzureAdditionalSettings from "../../../newWizard/steps/additionalSettings/AzureAdditionalSettingsNew";

export const getMoreDataComponent = (provider, protocol, props, formValues, form) => {
    const {_wizard: {protocols, isSecure, wizardInfo: {source, target}}, isTargetStepFromSameSourceType} = props;
    switch (protocol) {
        case 'cifs' : {
            const versionsList = CIFS_VERSIONS;
            const showACL= supportAclCopy(protocols, source, target) && !isTargetStepFromSameSourceType;
            return (
                <div>
                    {provider !== 'anf' && <div className="version">
                        <div>{END_POINT_NAMES_MAP['CIFS']} Version :</div>
                        <Field component={SelectField}
                               variant="inline"
                               name="version"
                               removeBottomMargin={true}
                               options={versionsList} className="nfs-version-cb"
                               isSearchable={false}/>
                    </div>}
                    <div>
                        <div className="credentials-container credentials">
                            <div className="credentials-title">Define SMB Credentials:</div>
                            <div className="credentials-fields">
                                <Field name="username" component={InputField} label="User Name"/>
                                <Field name="password" component={InputField} label="Password" type="password" autoComplete="off"/>
                                <Field name="domain" component={InputField} label="Domain (Optional)"/>
                            </div>
                        </div>
                        {showACL && <AclSelectionFinalForm/>}
                    </div>);
                </div>
            )
        }
        case 'nfs': {
            if (provider === 'anf') return null; //no more things to select for anf
            return  <NfsComponent provider={provider} protocols={protocols} formValues={formValues} isSecure={isSecure} form={form}/>;
        }
        case 's3': {
            if (provider !== 's3') {
                return  <S3BasedAdditionalSettings {...props} isFinalForm={true}/>
            }
            else {
                const showACL= supportAclCopy(protocols, source, target);
                const component = showACL ?
                    (<div className="setup-container">
                        <AclSelectionFinalForm/>
                    </div>) : null;
                return component;
            }
        }
        case 'azure': {
            return <AzureAdditionalSettings {...props} isFinalForm={true}/>
        }
        default :
            return null;
    }
}

export const getModeDataValidate = (protocol, provider) => {
    switch (protocol) {
        case 'cifs' : {
            return cifsValidator;
        }
        case 'nfs': {
            return noop

        }
        case 's3': {
            if (provider !== 's3') {
                return s3BasedValidator;
            }
            else {
                return noop;
            }
        }
        case 'azure': {
            return azureValidator;
        }
        default :
            return noop;
    }

}

export const getDefaultVersion = (protocol, provider) => {
    if (protocol === 'cifs') {
        return {version: {value: DEFAULT_CIFS_VERSION, label: DEFAULT_CIFS_VERSION}};
    }
    else if (protocol === 'nfs') {
        return {version: {value: DEFAULT_NFS_VERSION[provider], label: DEFAULT_NFS_VERSION[provider]}};
    }
}

