import React from 'react';
import { RELATIONSHIP_RECOMMENDATIONS } from "../consts";
import { get, keyBy, includes } from 'lodash'

const { manyRelationshipsOnFewDb, anfRelationship, manySmallFiles } = RELATIONSHIP_RECOMMENDATIONS;

export const recommendationsMap = {
    [manyRelationshipsOnFewDb]: {
        id: manyRelationshipsOnFewDb,
        title: 'Acceleration recommendation',
        description: 'The data broker group associated with this sync relationship is managing more than 6 relationships. This scenario can affect sync performance.',
        suggestion: <span>We recommend that you accelerate the relationship from the action menu on the right.</span>
    },
    [anfRelationship]: {
        id: anfRelationship,
        title: 'Optimization recommendation',
        description: `Verify that you're using the Premium or Ultra service level with Azure NetApp Files. You might experience failures and performance issues if the disk service level is Standard.`,
        suggestion: <span>Consult a solutions architect if you need help determining the right service level. The volume size and volume tier determines the <a target="_blank" rel="noopener noreferrer"
            href="https://docs.microsoft.com/en-us/azure/azure-netapp-files/azure-netapp-files-service-levels#throughput-limits">throughput</a> that you can get.</span>
    },
    [manySmallFiles]: {
        id: manySmallFiles,
        title: 'Acceleration recommendation',
        description: `We noticed that a large amount of directories are being transferred, which can affect sync performance.`,
        suggestion: <span>We recommend that you accelerate the relationship from the action menu on the right.</span>
    }
};

export const getRecommendation = ({ id, title, description, suggestion }) => ({
    id: id,
    title: title,
    text: <span>{description} {suggestion}</span>,
});

const addGroupsToRelationships = (relationships, groups) => {
    const groupMapById = keyBy(groups, "id");
    return relationships.map(relationship => ({ ...relationship, groupInfo: groupMapById[relationship.ui.groupId] }));
};

export const getRelationshipsWithGroupsAndRecommendations = (relationships, groups) => {
    if (!relationships || !relationships.data || !groups || !groups.data) {
        return { ...relationships, data: null }; //don't return partial data
    }
    const relationshipsWithGroups = addGroupsToRelationships(relationships.data, groups.data);
    const dbGroupsMap = relationshipsWithGroups.reduce(dbGroupsReducer, {});
    return relationships.updateIn(["data"], () => {
        return relationshipsWithGroups.map((relationship) => {
            relationship = isManyRelationshipOnFewDb(relationship, dbGroupsMap);
            relationship = isAnfRelationship(relationship);
            relationship = isManySmallFiles(relationship);
            return relationship;
        })
    })
};

const isManyRelationshipOnFewDb = (relationship, dbGroupsMap) => {
    const { ui: { groupId }, encryption } = relationship;
    if (encryption) return relationship;

    const { relationships, dbsInGroup } = dbGroupsMap[groupId];
    if (relationships > dbsInGroup * 6) {
        return relationship.updateIn(['ui', 'recommendations'], recommendations => [...recommendations, getRecommendation(recommendationsMap.manyRelationshipsOnFewDb)])
    }
    return relationship;
};

export const isManySmallFiles = (relationship) => {
    const { activity: { dirsScanned }, groupInfo: { dataBrokers }, encryption } = relationship;
    if (encryption) return relationship;

    //https://jira.ngage.netapp.com/browse/CS-6186 stop at maximum 10 data brokers
    if (dirsScanned > dataBrokers.length * 5000 && dataBrokers.length <= 10) {
        return relationship.updateIn(['ui', 'recommendations'], recommendations => [...recommendations, getRecommendation(recommendationsMap.manySmallFiles)])
    }
    return relationship;
};

const isAnfRelationship = (relationship) => {
    if (isSourceOrTargetAnf(relationship)) {
        return relationship.updateIn(['ui', 'recommendations'], recommendations => [...recommendations, getRecommendation(recommendationsMap.anfRelationship)])
    }
    return relationship;

};

const isSourceOrTargetAnf = relationship => (
    get(relationship, 'target.cifs.provider') === "anf" ||
    get(relationship, 'target.nfs.provider') === "anf" ||
    get(relationship, 'source.nfs.provider') === "anf" ||
    get(relationship, 'source.cifs.provider') === "anf"
);

const dbGroupsReducer = (acc, { groupInfo }) => {
    const id = groupInfo?.id;
    const dataBrokers = groupInfo?.dataBrokers;
    if (!id || !dataBrokers) {
        console.log(`%c Warning got empty group Info\n${JSON.stringify(acc, null, 2)}`, 'font-weight:bold; color:orange');
        return acc;
    }
    if (!acc[id]) {
        acc[id] = {
            relationships: 1,
            dbsInGroup: dataBrokers.length
        }
    } else {
        acc[id].relationships++;
    }
    return acc;
};

export const isAclSupportedNfsVersion = (version) => parseInt(version) >= 4;

export const isCloudToCloud = (relationship, sourceProtocol, targetProtocol) => {
    const CLOUDS = ['s3', 'azure', 'gcp'];
    const sourceProtocolLocal = relationship ? relationship.source.protocol : sourceProtocol;
    const targetProtocolLocal = relationship ? relationship.target.protocol : targetProtocol;
    // console.log(`%c sourceProtocolLocal ${sourceProtocolLocal}\ntargetProtocolLocal: ${targetProtocolLocal}\n relationship: ${JSON.stringify(relationship, null, 2)}`, 'font-weight:bold; color:green')
    return includes(CLOUDS, sourceProtocolLocal) && includes(CLOUDS, targetProtocolLocal);
};

export const COPY_ACL_OPTION = 'copyAclOptions';
export const ACL_PLACEHOLDER = 'Copy only files';

export const aclOptionsMap = {
    'FILES': {
        copyAcl: false,
        copyData: true
    },
    'ACL': {
        copyAcl: true,
        copyData: false
    },
    'FILES_ACL': {
        copyAcl: true,
        copyData: true
    }
}

export const aclOptions = [
    {
        label: ACL_PLACEHOLDER,
        value: 'FILES',
        disabled: false
    },
    {
        label: 'Copy only ACL',
        value: 'ACL',
        disabled: false
    },
    {
        label: 'Copy files and ACL',
        value: 'FILES_ACL',
        disabled: false
    }
];

export const aclOptionsSettings = keyStatusMap => {
    return keyStatusMap ? aclOptions.map(option => {
        const optionSettings = keyStatusMap[option.value];
        const {disabled, disabledTitle} = optionSettings || {};

        return {
            ...option,
            disabled,
            disabledTitle
        }
    }) : aclOptions;
}

export const findAclValuesByKey = key => {
    return aclOptionsMap[key];
}

export const findAclOptionByValues = (copyAcl = false, copyData = true) => {
    const key = Object.entries(aclOptionsMap).find(entry => entry[1].copyAcl === copyAcl && entry[1].copyData === copyData)[0];
    const option = aclOptions.find(option => option.value === key);
    return option;
}

export const supportAclCopy = (protocols, source, target, relationship) => {
    const supportedProtocols = ['cifs-s3', 'cifs-azure', 'cifs-gcp', 'azure-cifs', 's3-cifs', 'gcp-cifs']
    const supportedMultiSelectionProtocols = ['cifs-cifs']

    const supportProvider = source?.provider !== 'ontap' && target?.provider !== 'ontap';

    if (supportProvider) {
        if (includes(supportedProtocols, protocols)) return true;
        if (includes(supportedMultiSelectionProtocols, protocols)) return 'multi';
    }

    return false;
};

export const supportAclCopyNfs = (relationship, sourceProtocol, targetProtocol) => {
    const isSupportAclCopyNfs = !relationship.encryption
        && sourceProtocol === 'nfs'
        && isAclSupportedNfsVersion(relationship.ui.source.nfs.version)
        && targetProtocol === 'nfs'
        && isAclSupportedNfsVersion(relationship.ui.target.nfs.version) ? 'multi' : false;

    return isSupportAclCopyNfs;
}

export const protocolSupportsTags = (protocol, provider) => {
    return protocol === 's3' || (protocol === 'azure' && provider === 'azure');
};
