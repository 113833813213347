import React from 'react';
import {
    showBuckets,
    listBucketFolders,
    addNewBucket,
    bucketsSelectStep,
    bucketsSecondStep,
    listAwsKmsKeys,
    listS3PrivateLinks,
    clearFoldersCache,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
    clearUrlParamsIfChanged
} from '../../../store/wizard/action_creators';
import {connect} from 'react-redux';
import ShowBucketsStepBase from "./ShowBucketsStepBaseNew";
import Validator from 'validatorjs';
import _ from 'lodash';
import {ENCRYPTION_TYPES} from '../../../consts';
import {
    getPropFromState,
    getWizardParamsFromWizardState
} from '../../../utils/mapStateToPropsUtils';
import useStepinize from "../useStepinize";

const rules = encryptionTypeOption => {
    const rules = {
        encryptionTypeOption: 'required'
    };

    if (encryptionTypeOption?.value === ENCRYPTION_TYPES.KMS_EXISTING) {
        rules.encryptionKeyOption = 'required';
    }
    if (encryptionTypeOption?.value === ENCRYPTION_TYPES.KMS_MANUAL) {
        rules.encryptionKeyManual = 'required';
    }
    return rules;
};

const validate = values => {
    const {encryptionTypeOption} = values;
    const validator = new Validator(values, rules(encryptionTypeOption));
    validator.setAttributeNames({
        encryptionTypeOption: 'Encryption Type',
        encryptionKeyManual: 'Encryption Key',
        encryptionKeyOption: 'Encryption Key'
    });
    validator.passes();
    return validator.errors.all();
};

const fetchData = (props) => {
    props.showBuckets(props._wizard.selectedWizardParams.selectedDataBroker.id);
};

const shouldFetch = (props) => {
    const {_showBuckets, _wizard :{selectedWizardParams: {selectedDataBroker}}} = props;
    const showBuckets = _showBuckets[_.get(selectedDataBroker, 'id')];
    return !showBuckets || showBuckets.failedRetrieve;
};

const isFetching = (props) => {
    const {_showBuckets, _wizard: { selectedWizardParams: {selectedDataBroker}}} = props;
    const bucketsByBrokerId = _showBuckets[selectedDataBroker.id];
    return !bucketsByBrokerId || bucketsByBrokerId.inProgress || ((!bucketsByBrokerId.data || bucketsByBrokerId.data.length === 0) && !bucketsByBrokerId.retrievedAll);
};

const loading = () => {
    return {text: "Buckets", icon: require("./../../../assets/svgs/s3-default.svg")}
}

const stepId = ({isTargetStepFromSameSourceType}) => {
    return isTargetStepFromSameSourceType ? 'targetS3Buckets' : 's3Buckets';
};

const ShowBucketsStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})


    return isFetching(props) ? (LoadingComp)
        : <div className="full-height">
            <ShowBucketsStepBase fetchBuckets={() => fetchData(props)}
                                 validate={validate} {...props} {...moreStepInfo}/>
        </div>;
}



const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _showBuckets:  getPropFromState(state, "_showBuckets", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    showBuckets,
    listBucketFolders,
    addNewBucket,
    bucketsSelectStep,
    bucketsEncryptionStep: bucketsSecondStep,
    listAwsKmsKeys,
    listS3PrivateLinks,
    clearFoldersCache,
    clearUrlParamsIfChanged,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowBucketsStep);
