import * as awsService from './aws.api.service';
import {extractErrorAws} from '../utils/providers-utils';

export const startDeployAws = async (deployDetails, template, credentials, region, stackName) => {
    const params = [
        { ParameterKey: 'VpcId', ParameterValue: deployDetails.vpc },
        { ParameterKey: 'SubnetId', ParameterValue: deployDetails.subnet },
        { ParameterKey: 'AssociatePublicIpAddress', ParameterValue: deployDetails.enablePublicIp },
        { ParameterKey: 'KeyPair', ParameterValue: deployDetails.keyPair },
        { ParameterKey: 'KmsKey', ParameterValue: deployDetails.kmsKey },
        { ParameterKey: 'UserRole', ParameterValue: deployDetails.userRole },
        { ParameterKey: 'ProxyHost', ParameterValue: deployDetails.proxyHost },
        { ParameterKey: 'ProxyPort', ParameterValue: deployDetails.proxyPort },
        { ParameterKey: 'ProxyUsername', ParameterValue: deployDetails.proxyUsername },
        { ParameterKey: 'ProxyPassword', ParameterValue: deployDetails.proxyPassword }
    ];
    const stackParams = {
        StackName: stackName,
        TemplateBody: template,
        Capabilities: ['CAPABILITY_IAM'],
        Parameters: params
    };

    try {
        const res = await awsService.deployTemplate(credentials, region, stackParams);
        return res.StackId;
    }
    catch (error) {
        throw new Error(extractErrorAws(error));
    }
};
