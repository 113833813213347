import React, {useState} from 'react';
import {useDialog, DefaultDialogLayout} from '@netapp/shared-components';
import useDeepCompareEffect from '../../hooks/useDeepCompareEffect';

const ConfirmDialog = ({onConfirm, extractError, confirmText, children, disableClose, ...props}) => {
    const {closeDialog, setDisableClose} = useDialog();
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const { submitDisabled } = props;

    useDeepCompareEffect(() => {
        setDisableClose(submitting || disableClose);
    }, [submitting, disableClose]);

    const buttonsArr = [{
        text: confirmText,
        loading: submitting,
        thin: true,
        disabled: submitDisabled !== undefined ? submitDisabled : false,
        onClick: async () => {
            try {
                setError(null);
                setSubmitting(true);
                await onConfirm();
                closeDialog();
            }
            catch (error) {
                setError(extractError ? extractError(error) : "Unknown error");
                setSubmitting(false);
            }
        }
    }];

    if (!disableClose) {
        buttonsArr.push({
            text: 'Cancel',
            variant: 'secondary',
            onClick: closeDialog,
            thin: true,
            disabled: submitting
        })
    }

    return (
        <DefaultDialogLayout
            errorMessage={error}
            buttonsArr={buttonsArr}
            {...props}>

            {children}

        </DefaultDialogLayout>
    );
};

export default ConfirmDialog;