import React from "react";
import {ReactComponent as AwsNoCircleDisabledIcon} from "./assets/svgs/aws-no-circle-disabled.svg";
import {ReactComponent as AwsNoCircleIcon} from "./assets/svgs/aws-no-circle.svg";
import {ReactComponent as AwsCardIcon} from "./assets/svgs/aws-card.svg";
import {ReactComponent as AwsCardDisabledIcon} from "./assets/svgs/aws-card-disabled.svg";
import {ReactComponent as ManagedAwsIcon} from "./assets/svgs/managed-aws.svg";
import {ReactComponent as ManagedAwsDisabledIcon} from "./assets/svgs/managed-aws-disabled.svg";
import {ReactComponent as FileServerNoCircleDisabledIcon} from "./assets/svgs/file-server-no-circle-disabled.svg";
import {ReactComponent as FileServerNoCircleIcon} from "./assets/svgs/file-server-no-circle.svg";
import {ReactComponent as FileServerCardIcon} from "./assets/svgs/file-server-card.svg";
import {ReactComponent as FileServerCardDisabledIcon} from "./assets/svgs/file-server-card-disabled.svg";
import {ReactComponent as ManagedOnpremIcon} from "./assets/svgs/managed-onprem.svg";
import {ReactComponent as ManagedOnpremDisabledIcon} from "./assets/svgs/managed-onprem-disabled.svg";

import {ReactComponent as AzureNoCircleDisabledIcon} from "./assets/svgs/azure-no-circle-disabled.svg";
import {ReactComponent as AzureNoCircleIcon} from "./assets/svgs/azure-no-circle.svg";
import {ReactComponent as AzureCardIcon} from "./assets/svgs/azure-card.svg";
import {ReactComponent as AzureCardDisabledIcon} from "./assets/svgs/azure-card-disabled.svg";
import {ReactComponent as ManagedAzureIcon} from "./assets/svgs/managed-azure.svg";
import {ReactComponent as ManagedAzureDisabledIcon} from "./assets/svgs/managed-azure-disabled.svg";

import {ReactComponent as GcpNoCircleDisabledIcon} from "./assets/svgs/gcp-no-circle-disabled.svg";
import {ReactComponent as GcpNoCircleIcon} from "./assets/svgs/gcp-no-circle.svg";
import {ReactComponent as GcpCardIcon} from "./assets/svgs/gcp-card.svg";
import {ReactComponent as GcpCardDisabledIcon} from "./assets/svgs/gcp-card-disabled.svg";
import {ReactComponent as ManagedGcpIcon} from "./assets/svgs/managed-gcp.svg";
import {ReactComponent as ManagedGcpDisabledIcon} from "./assets/svgs/managed-gcp-disabled.svg";

import {ReactComponent as StatusCompleted} from './assets/svgs/checkmark-green.svg'
import {ReactComponent as StatusFailed} from './assets/svgs/status-failed.svg'
import SmallLoader from "./components/_common_/SmallLoader/SmallLoader";

export const SERVICE_NAME = "Copy & sync";

export const TARGET_FOLDER_NOTICE = "If you enable the Delete Files on Target option in the relationship settings, the target directory should be empty. Otherwise existing files within a folder might get deleted.";
export const TARGET_DIRECTORY_NOTICE = "If you enable the Delete Files on Target option in the relationship settings, the target directory should be empty. Otherwise existing files within a directory might get deleted.";

export const FAILED_AFTER_STARTUP_MESSAGE = 'You should delete the relationship and start over';

export const PAYLOAD_DATA_BROKER_STATUS = 'data-broker-status';
export const PAYLOAD_DATA_BROKER_LOCAL_CONFIGURATION = 'local-configuration';
export const PAYLOAD_SHOW_EXPORTS = 'list-nfs-exports';
export const PAYLOAD_LIST_FOLDERS = 'list-nfs-export-folders';
export const PAYLOAD_LIST_CIFS_SHARES = 'list-cifs-shares';
export const PAYLOAD_LIST_CIFS_SHARE_FOLDERS = 'list-cifs-share-folders';
export const PAYLOAD_LIST_BUCKETS = 'list-s3-buckets';
export const PAYLOAD_LIST_SUB_DIRECTORIES = 'list-s3-bucket-folders';
export const PAYLOAD_LIST_CONTAINERS = 'list-azure-blob-storage-containers';
export const PAYLOAD_LIST_CONTAINER_SUB_DIRECTORIES = 'list-azure-blob-storage-container-folders';
export const PAYLOAD_LIST_DATA_LAKE_CONTAINERS = 'list-azure-dataLake-storage-containers';
export const PAYLOAD_LIST_DATA_LAKE_CONTAINER_SUB_DIRECTORIES = 'list-azure-dataLake-storage-container-folders';
export const PAYLOAD_LIST_SGWS_BUCKETS = 'list-sgws-buckets';
export const PAYLOAD_LIST_SGWS_BUCKETS_FOLDERS = 'list-sgws-bucket-folders';
export const PAYLOAD_LIST_IBM_BUCKETS = 'list-ibm-buckets';
export const PAYLOAD_LIST_IBM_BUCKETS_FOLDERS = 'list-ibm-bucket-folders';
export const PAYLOAD_LIST_ONTAP_BUCKETS = 'list-ontap-s3-buckets';
export const PAYLOAD_LIST_ONTAP_BUCKETS_FOLDERS = 'list-ontap-s3-bucket-folders';
export const PAYLOAD_LIST_GCP_BUCKETS = 'list-gcp-buckets';
export const PAYLOAD_LIST_GCP_BUCKET_FOLDERS = 'list-gcp-bucket-folders';
export const PAYLOAD_LIST_BOX_ROOT_FOLDER = 'list-box-root-folder';
export const PAYLOAD_LIST_BOX_FOLDERS = 'list-box-folders';
export const PAYLOAD_LIST_GOOGLE_DRIVES = 'list-google-drives';
export const PAYLOAD_LIST_GOOGLE_DRIVE_FOLDERS = 'list-google-drive-folders';
export const PAYLOAD_LIST_SFTP_ROOT_DIRECTORY = 'list-sftp-root-directory';
export const PAYLOAD_LIST_SFTP_DIRECTORIES = 'list-sftp-directories';
export const PAYLOAD_DATA_BROKER_LOGS = "update-data-broker-logs";
export const PAYLOAD_LIST_KMS_KEYS = "list-kms-keys";
export const PAYLOAD_LIST_GCP_KMS_KEYS = "list-gcp-kms-keys";
export const PAYLOAD_LIST_S3_PRIVATE_LINKS = "list-s3-private-links";

export const PAYLOAD_UPDATE_RELATIONSHIP = 'update-relationship-v2';
export const PAYLOAD_UPDATE_SUBSCRIPTION_STATUS = 'update-subscription-status';
export const PAYLOAD_UPDATE_ACCOUNT_INFO = 'update-account-info';
export const PAYLOAD_DATABROKER_TRANSFER_RATE = 'databroker-transfer-rate';
export const PAYLOAD_UPDATE_REPORT = 'update-report';

export const RESPONSE_STATUS_FAILED = "FAILED";
export const RESPONSE_STATUS_SUCCEEDED = "SUCCEEDED";
export const RESPONSE_STATUS_ABORTING = "ABORTING";
export const RESPONSE_STATUS_ABORTED = "ABORTED";
export const RESPONSE_STATUS_RUNNING = "RUNNING";
export const RESPONSE_STATUS_DONE = "DONE";
export const RESPONSE_STATUS_PENDING = "PENDING";
export const STATUS_CONTINUOUSLY_SYNCING = "RUNNING_CONTINUOUSLY";

export const UNKNOWN_ERROR = "Unknown error";

//This is a UI status, for showing an accurate status between clicking "sync now" and the first "update relationship" message:
export const STATUS_STARTING = "STARTING";

export const BROKER_STATUS_FAILED = "FAILED";
export const BROKER_STATUS_COMPLETE = "COMPLETE";


export const REPORT_STATUS_COMPLETED = "DONE"
export const REPORT_STATUS_RUNNING = "RUNNING"
export const REPORT_STATUS_FAILED = "FAILED"
export const REPORT_STATUS_MAP = {
    [REPORT_STATUS_RUNNING]: {status: REPORT_STATUS_RUNNING, text: "Scanning", icon: <SmallLoader/>},
    [REPORT_STATUS_COMPLETED]: {status: REPORT_STATUS_COMPLETED, text: "Completed", icon: <StatusCompleted/>},
    [REPORT_STATUS_FAILED]: {status: REPORT_STATUS_FAILED, text: "Failed", icon: <StatusFailed/>},
}
export const DISABLED_BUTTONS_TITLES = {
    noRelationship : "Relationship related to this endpoint doesn't exists anymore. Create a relationship to add a report",
    notSupported : (target) => `At this time, reports are not supported with the ${target}`,
    inProgress: "A reporting scan is in progress. You can't run another report at this time.",
    noReports : "No reports yet",
    notInProgress: "Abort can apply only to a reporting scan in progress"
}

export const SELECTED_PARAMS_DATA_BROKER_GROUP_ID = "dataBrokerGroupId";
export const SELECTED_PARAMS_DATA_BROKER_GROUP_ID_TARGET = "dataBrokerGroupIdTarget";
export const SELECTED_PARAMS_NFS_SERVER = "nfsServer";
export const SELECTED_PARAMS_NFS_VERSION = "nfsVersion";
export const SELECTED_PARAMS_CIFS_SERVER = "cifsServer";
export const SELECTED_PARAMS_TARGET_NFS_SERVER = "nfsServerTarget";
export const SELECTED_PARAMS_TARGET_NFS_VERSION = "targetNfsVersion";
export const SELECTED_PARAMS_TARGET_CIFS_SERVER = "cifsServerTarget";
export const SELECTED_PARAMS_AZURE_STORAGE_ACCOUNT = "storageAccountName";
export const SELECTED_PARAMS_TARGET_AZURE_STORAGE_ACCOUNT = "storageAccountNameTarget";
export const SELECTED_PARAMS_BOX_APP_NAME = "appName";
export const SELECTED_PARAMS_BOX_TARGET_APP_NAME = "appNameTarget";
export const SELECTED_PARAMS_SFTP_SERVER = "sftpServer";
export const SELECTED_PARAMS_TARGET_SFTP_SERVER = "sftpServerTarget";

export const FM_AGENT = "Data Broker Group";
export const DATA_BROKER_FUNCTION = "Data Broker Functionality";


export const FM_AGENT_TYPE_AWS = {
    name: 'AWS',
    displayName: `AWS ${FM_AGENT}`,
    providerName: "Amazon Web Services",
    company: "Amazon",
    disabledIcon: <span className="aws data-broker icon"><AwsNoCircleDisabledIcon/></span>,
    colorIcon: <span className="aws data-broker icon"><AwsNoCircleIcon/></span>,
    circledIcon: <AwsCardIcon/>,
    disabledCircledIcon: <AwsCardDisabledIcon/>,
    managedCircleIcon: <ManagedAwsIcon/>,
    disabledManagedCircleIcon: <ManagedAwsDisabledIcon/>
};
export const FM_AGENT_TYPE_ONPREM = {
    name: 'ONPREM',
    displayName: `On-Prem ${FM_AGENT}`,
    providerName: "",
    company: "",
    disabledIcon: <span className="onprem data-broker icon"><FileServerNoCircleDisabledIcon/></span>,
    colorIcon: <span className="onprem data-broker icon"><FileServerNoCircleIcon/></span>,
    circledIcon: <FileServerCardIcon/>,
    disabledCircledIcon: <FileServerCardDisabledIcon/>,
    managedCircleIcon: <ManagedOnpremIcon/>,
    disabledManagedCircleIcon: <ManagedOnpremDisabledIcon/>
};
export const FM_AGENT_TYPE_AZURE = {
    name: 'AZURE',
    displayName: `Azure ${FM_AGENT}`,
    providerName: "Microsoft Azure",
    company: "Microsoft",
    disabledIcon: <span className="azure data-broker icon"><AzureNoCircleDisabledIcon/></span>,
    colorIcon: <span className="azure data-broker icon"><AzureNoCircleIcon/></span>,
    circledIcon: <AzureCardIcon/>,
    disabledCircledIcon: <AzureCardDisabledIcon/>,
    managedCircleIcon: <ManagedAzureIcon/>,
    disabledManagedCircleIcon: <ManagedAzureDisabledIcon/>

};
export const FM_AGENT_TYPE_GCP = {
    name: 'GCP',
    displayName: `Google Cloud ${FM_AGENT}`,
    providerName: "Google Cloud",
    company: "Google",
    disabledIcon: <span className="gcp data-broker icon"><GcpNoCircleDisabledIcon/></span>,
    colorIcon: <span className="gcp data-broker icon"><GcpNoCircleIcon/></span>,
    circledIcon: <GcpCardIcon/>,
    disabledCircledIcon: <GcpCardDisabledIcon/>,
    managedCircleIcon: <ManagedGcpIcon/>,
    disabledManagedCircleIcon: <ManagedGcpDisabledIcon/>
};

export const DOCUMENTATION_URL = "https://docs.netapp.com/us-en/cloud-manager-sync";
export const DOCUMENTATION_LICENSING_URL = DOCUMENTATION_URL + "/task-licensing.html";
export const DOCUMENTATION_DATA_BROKER = DOCUMENTATION_URL + "/task-quick-start.html";
export const DOCUMENTATION_DATA_BROKER_ONPREM = DOCUMENTATION_URL + "/task-installing-linux.html";
export const S3_BUCKET_PERMISSIONS_DOCUMENTATION = DOCUMENTATION_URL + "/reference-requirements.html#s3";
export const GCP_BUCKET_PERMISSIONS_DOCUMENTATION = DOCUMENTATION_URL + "/reference-requirements.html#google";
export const DOCUMENTATION_ANF_RECOMMENDATION = DOCUMENTATION_URL + "/reference-requirements.html#azure-netapp-files-requirement";
export const DOCUMENTATION_DEPLOY_GCP_PERMISSIONS = DOCUMENTATION_URL + "/task-installing-gcp.html#permissions-required-to-deploy-the-data-broker-in-google-cloud";

export const UNKNOWN_SYNC_STATUS = "The 'Sync Status' is unknown since there is no connection to the data brokers.";

export const LICENSE_TYPE_FREE_TRIAL = "Free Trial";
export const LICENSE_TYPE_PAYGO = "PayGo";
export const LICENSE_TYPE_PAYGO_WITH_BYOL = "PayGo with BYOL";
export const LICENSE_TYPE_BYOL = "BYOL";
export const LICENSE_TYPE_UNKNOWN = "Unknown License Type";

export const purchaseInfo = <span>To purchase a license please <a
    href="https://www.netapp.com/company/contact-us/support/">contact us</a>.</span>;

export const SAAS_REGISTRATION_STATUS_INIT = 'unsubscribed-init'; // from server
export const SAAS_REGISTRATION_STATUS_UNSUBSCRIBED = 'unsubscribe-success'; // from server
export const SAAS_REGISTRATION_STATUS_UNSUBSCRIBE_PENDING = 'unsubscribe-pending'; // from Amazon
export const SAAS_REGISTRATION_STATUS_RESOLVE_SUCCESS = 'resolve-success'; // from server
export const SAAS_REGISTRATION_STATUS_RESOLVE_FAILED = 'resolve-failed'; // from server
export const SAAS_REGISTRATION_STATUS_SUBSCRIBE_SUCCESS = 'subscribe-success'; // from Amazon
export const SAAS_REGISTRATION_STATUS_SUBSCRIBE_FAILED = 'subscribe-failed'; // from Amazon
export const SAAS_REGISTRATION_PAYGO_SUBSCRIPTION_TYPE = 'PAYGO'; // from Amazon

export const AWS_MP_PRICING_MODEL_PAYGO_URL = "https://aws.amazon.com/marketplace/pp/B01LZV5DUJ";
export const AWS_MP_PRICING_MODEL_ANNUAL_URL = "https://aws.amazon.com/marketplace/pp/B06XX5V3M2";
export const AZURE_MP_PRICING_MODEL_URL = "https://azuremarketplace.microsoft.com/en-us/marketplace/apps/netapp.cloud-sync-service?tab=PlansAndPrice";
export const AWS_MP_SUBSCRIBE_URL = "https://aws.amazon.com/marketplace/pp/B01LZV5DUJ";
export const AWS_MP_UNSUBSCRIBE_URL = "https://aws.amazon.com/marketplace/library";

export const END_POINT_NAMES_MAP = {
    "NFS": "NFS Server",
    "ANF": "Azure NetApp Files",
    "CVS": "Cloud Volumes Service",
    "EFS": "AWS EFS",
    "CIFS": "SMB Server",
    "BOX": "BOX App",
    "GDRIVE": "Google Drive",
    "S3": "AWS S3",
    "SGWS": "StorageGRID Server",
    "GCP": "Google Cloud Storage",
    "IBM": "IBM Cloud Object Storage Server",
    "AZURE": "Azure Blob",
    "ONTAP": "ONTAP S3 Storage Server",
    "CVO": "Cloud Volumes ONTAP",
    "ONPREM": "On-Premises ONTAP",
    "FSX": "FSx for ONTAP",
    "SFTP": "SFTP Server",
    "AZURE_DATA_LAKE": "Azure Data Lake Gen 2"};

export const OBJECT_STORAGE_NAMES_MAP = {
    S3: "AWS S3 Bucket",
    SGWS: "StorageGRID Bucket",
    GCP: "Google Cloud Storage Bucket",
    IBM: "IBM Cloud Object Storage Bucket",
    AZURE: "Azure Blob Storage Account",
    ONTAP: "ONTAP S3 Storage Bucket"
};

export const ROUTES = {
    '/': '',
    SYNC: 'sync',
    SYNC_NEW: 'sync-new',
    DASHBOARD: 'dashboard',
    LICENSE: 'license',
    MANAGE: 'manage',
    GROUP_SETTINGS: 'group-settings',
    REPORTS: 'reports',
    REPORTS_VIEW: 'report-view',
    REPORTS_ERROR: 'report-errors',
    SETTINGS: 'settings',
    FAILED: 'failed',
    DRAG_SOURCE_AND_TARGET: 'drag-source-and-target-new'
}

export const NEW_TARGET_FOLDER_ID = "NEW_TARGET_FOLDER_ID";
export const STORAGE_OBJECT_KEY_MAX_LENGTH = 1024;

export const FREE_TRIAL_PERIOD = 14;

// Regular expressions taken from OCCM and modified
export const IP_REG_EXP = /^(\\d|[1-9]\\d|1\\d\\d|2([0-4]\\d|5[0-5]))\\.(\\d|[1-9]\\d|1\\d\\d|2([0-4]\\d|5[0-5]))\\.(\\d|[1-9]\\d|1\\d\\d|2([0-4]\\d|5[0-5]))\\.(\\d|[1-9]\\d|1\\d\\d|2([0-4]\\d|5[0-5]))$/;
export const HOST_NAME_REG_EXP = /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/;
export const KEYS_REG_EXP = /^[ -~]+$/g;
export const HOST_IPV4_IPV6_REG_EXP = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
//regular expression for dns and ip - from http://jsfiddle.net/DanielD/8S4nq/

export const NFS_VERSIONS_CONVERTED = {
    "3" : "3",
    "4" : "4.0",
    "any" : "3",
    "nfs" : "3"
}
export const NFS_VERSIONS = [{label: "3", value: "3"}, {label: "4.0", value: "4.0"}, {label: "4.1", value: "4.1"}, {label: "4.2", value: "4.2"}];
export const NFS_CVS_VERSIONS = [{label: "3", value: "3"}, {label: "4.1", value: "4.1"}];
export const NFS_ANF_VERSIONS = [{label: "3", value: "3"}, {label: "4.1", value: "4.1"}];
export const CM_NFS_VERSIONS = {
    nfs3: [{label: "3", value: "3"}],
    nfs4: [{label: "4.0", value: "4.0"}, {label: "4.1", value: "4.1"}],
    any:[{label: "3", value: "3"}, {label: "4.0", value: "4.0"}, {label: "4.1", value: "4.1"}]
}
export const CIFS_VERSIONS = [
    {label: "1.0", value: "1.0"},
    {label: "2.0", value: "2.0"},
    {label: "2.1", value: "2.1"},
    {label: "3.0", value: "3.0"},
    {label: "3.11", value: "3.11"}];
export const DEFAULT_CIFS_VERSION = "2.1";

export const DEFAULT_NFS_VERSION = {
    NFS: "3",
    EFS: "4.1",
    CVS: "3",
    ANF: "3",
    CVO: "3",
    ONPREM: "3",
    NFS_ACL: "4.1",
    FSX: "3"
};

export const SERVER_TYPES = {
    "NFS": "nfs",
    "NFS-ANF": "nfsAnf",
    "NFS-EFS": "nfsEfs",
    "NFS-CVS": "nfsCvs",
    "NFS-CVO": "nfsCvo",
    "NFS-ONPREM": 'nfsOnprem',
    "NFS-FSX": 'nfsFsx',
    "CIFS": "cifs",
    "CIFS-ANF": "cifsAnf",
    "CIFS-CVS": "cifsCvs",
    "CIFS-CVO": "cifsCvo",
    "CIFS-ONPREM": "cifsOnprem",
    "CIFS-FSX": "cifsFsx",
    "BOX" : 'box',
    "GDRIVE" : 'gdrive',
    "SFTP" : 'sftp',
    "S3-SGWS": "s3Sgws",
    "S3-IBM": "s3Ibm",
    "S3-ONTAP": "s3Ontap"
};

export const MAX_S3_TAGS = 5;

export const DraggableTypes = {
    END_POINT: "endPoint"
};

export const STORAGE_CLASS_OPTIONS = [
    {label: "Standard", value: "STANDARD"},
    {label: "Standard-Infrequent Access", value: "STANDARD_IA"},
    {label: "One Zone-Infrequent Access", value: "ONEZONE_IA"},
    {label: "Intelligent-Tiering", value: "INTELLIGENT_TIERING"},
    {label: "Glacier Flexible Retrieval", value: "GLACIER"},
    {label: "Glacier Deep Archive", value: "DEEP_ARCHIVE"},
    {label: "Glacier Instant Retrieval", value: "GLACIER_IR"}
];

export const DEFAULT_STORAGE_CLASS = "STANDARD";
export const DEFAULT_STORAGE_CLASS_OPTION = {label: "Standard", value: "STANDARD"};
export const ENCRYPTION_TYPES = {
    KMS_EXISTING: "KMS_EXISTING",
    KMS_MANUAL: "KMS_MANUAL",
    AES: "AES",
};

export const ENCRYPTION_TYPES_NAMES = {
    KMS: "kms",
    AES: "aes256",
    NONE: "none"
};
export const DEFAULT_ENCRYPTION_TYPE = 'AES';
export const DEFAULT_ENCRYPTION_TYPE_OPTION=    { value: ENCRYPTION_TYPES.AES, label: 'SSE-S3 (AES-256) encryption', name: ENCRYPTION_TYPES_NAMES.AES, isKeyRequired: false};
export const ENCRYPTION_OPTIONS = [
    { value: ENCRYPTION_TYPES.KMS_EXISTING, label: 'AWS KMS encryption: select a key', name: ENCRYPTION_TYPES_NAMES.KMS, isKeyRequired: true},
    { value: ENCRYPTION_TYPES.KMS_MANUAL, label: 'AWS KMS encryption: enter a key ARN', name: ENCRYPTION_TYPES_NAMES.KMS, isKeyRequired: true},
    { value: ENCRYPTION_TYPES.AES, label: 'SSE-S3 (AES-256) encryption', name: ENCRYPTION_TYPES_NAMES.AES, isKeyRequired: false}
];

export const AZURE_BLOB_TIER_OPTIONS = [
    {label: "Hot", value: "HOT"},
    {label: "Cool", value: "COOL"},
    {label: "Archive", value: "ARCHIVE"},
    {label: "Cold", value: "COLD"}
];

export const DEFAULT_AZURE_BLOB_TIER = "HOT";

export const GCP_STORAGE_CLASS_OPTIONS = [
    {label: "STANDARD", value: "STANDARD"},
    {label: "NEARLINE", value: "NEARLINE"},
    {label: "COLDLINE", value: "COLDLINE"},
    {label: "ARCHIVE", value: "ARCHIVE"}
];

export const DEFAULT_GCP_STORAGE_CLASS = "STANDARD"

export const TEXT_DURATION_FEW_SECONDS = "Less than a minute";

export const AZURE_SSH_INSTRUCTIONS = 'https://docs.microsoft.com/en-us/azure/virtual-machines/linux/mac-create-ssh-keys';

export const DEPLOY_STATUS_ERROR = 'ERROR';
export const DEPLOY_STATUS_SUCCESS = 'SUCCESS';
export const DEPLOY_STATUS_PROGRESS = 'IN-PROGRESS';

export const DATA_BROKER_ENCRYPTION_TYPES = {
    Initiator: "INITIATOR",
    Listener: "LISTENER"
};

export const DATA_BROKER_WIZARD_STEPS = {
    DATA_BROKER_DETAILS: 0,
    INSTANCE_DETAILS: 1
};

export const INSTANCE_DETAILS_SCREENS = {
    CREDENTIALS: 0,
    SETTINGS: 1,
    PROXY: 2
};

export const ONPREM_DETAILS_SCREENS = {
    SETTINGS: 0
};

export const AZURE_SCREENS = {
    PERMISSIONS: 3
};

export const RELATIONSHIP_RECOMMENDATIONS = {
    manyRelationshipsOnFewDb: 'manyRelationshipsOnFewDb',
    anfRelationship: 'anfRelationship',
    manySmallFiles: 'manySmallFiles'
};

export const CARDS_VIEW = 'CARDS_VIEW';
export const TABLE_VIEW = 'TABLE_VIEW';

export const AWS_CREATE_BROKER_POLICY_REQUIREMENTS = "https://s3.amazonaws.com/metadata.datafabric.io/docs/aws_iam_policy.json";
export const AWS_BROKER_POLICY_REQUIREMENTS = "https://docs.netapp.com/us-en/cloud-manager-sync/media/aws_iam_policy_data_broker.json";

export const DEPLOY_PROGRESS_NOTIFICATION = `Data broker creation is in progress. You can view the status by clicking the data broker "info" icon for the associated relationship.`;

export const ENABLE_DISABLE_OPTIONS = [{value: true, label: "Enable"}, {value: false, label: "Disable"}];

export const MISSING_CM_WE_NOTIFICATION = {
    "ANF": "No Azure NetApp Files working environments were found. Go to the Canvas tab and create an Azure NetApp Files working environment.",
    "ONPREM": "No ONPREM working environments were found. Go to the Canvas tab and create an ONPREM working environment.",
    "CVO": "No Cloud Volumes ONTAP working environments were found. Go to the Canvas tab and create a CVO working environment.",
    "FSX": "No FSx working environments were found. Go to the Canvas tab and create an FSx working environment.",
    "NO-AGENT": "The Connector isn’t running. You’ll need to start it so Copy & sync can get information about your working environments and volumes.",
    "NO-WORKSPACE": "Copy & sync can’t get information about your Cloud Volumes ONTAP working environments because the Connector " +
        "is running an older version. Upgrade the Connector to the latest version, or you’ll need to create a sync relationship " +
        "by selecting NFS server or SMB server, and then by entering the Cloud Volumes ONTAP IP address"
}

export const INSTANCE_DETAILS_FORM = "instanceDetailsForm";

export const ACL_DISABLED_MESSAGE = "Copying ACLs is supported only when the NFS version for both the source and target is version 4 or later.";
export const VOLUME_HAS_NO_EXPORT_NFS = "Ensure that the volume is online and that it includes a defined export.";
export const VOLUME_HAS_NO_EXPORT_CIFS = "Ensure that the volume is online and that it includes a CIFS share.";

export const STORAGE_CLASS_INFO = {
    S3: <span>The number of objects associated with an Amazon S3 storage class. <a href="https://aws.amazon.com/s3/storage-classes/" target="_blank" rel="noopener noreferrer">Learn more</a></span>,
    GCP: <span>The number of objects associated with a Google Cloud storage class. <a href=" https://cloud.google.com/storage/docs/storage-classes" target="_blank" rel="noopener noreferrer">Learn more</a></span>,
    AZURE: <span>The number of objects associated with an Azure Blob access tier. <a href="https://docs.microsoft.com/en-us/azure/storage/blobs/storage-blob-storage-tiers" target="_blank" rel="noopener noreferrer">Learn more</a></span>,
}

export const GROUP_SETTINGS_INFO = "If the default configuration for a data broker group isn't suitable for your environment, " +
    "you can change the configuration to decrease the number of sync errors. Be aware that changes to the group's configuration can affect performance by slowing down the transfer. Contact us if you need help."
export const GROUP_SETTINGS_SCAN_CONCURRENCY_INFO = "The number of tasks that the scanner works on in parallel. For example, if the value is set to 10, the scanner will scan 10 directories in parallel. The default value is 50."
export const GROUP_SETTINGS_SCAN_PROCESSES_INFO = "The maximum number of scanner processes in a data broker, as long as the limit is below the number of CPUs on the data broker. For example, if a data broker has 4 CPUs and the scanner process limit is set to 3, the process limit will be 3. But if the process limit is set to 5, the maximum number of scanner processes will be 4 due to the number of CPUs. The default value is 10."
export const GROUP_SETTINGS_TRANSFERRER_CONCURRENCY_INFO = "The number of tasks that the transferrer works on in parallel. For example, if the value is set to 10, the transferrer will copy 10 files in parallel. The default value is 50."
export const GROUP_SETTINGS_TRANSFERRER_PROCESSES_INFO = "The maximum number of transferrer processes in a data broker, as long as the limit is below the number of CPUs on the data broker. For example, if a data broker has 4 CPUs and the transferrer process limit is set to 3, the process limit will be 3. But if the process limit is set to 5, the maximum number of transferrer processes will be 4 due to the number of CPUs. The default value is 10."

export const CM_WE_PROVIDER_SUPPORTED_TYPES = ["anf", "cvo", "onprem", "fsx"];

export const PROVIDERS_SUPPORT_EDIT_CREDENTIALS = ["cifs", "azure", "ibm", "sgws", "sftp", "anf", "cvo", "onprem", "cvs", "ontap", "box", "fsx", "azure_data_lake", "gdrive"];
export const PROTOCOLS_SUPPORT_EDIT_CREDENTIALS = ["cifs", "azure", "s3", "sftp", "box", "gdrive"];
export const CREDENTIALS_BY_PROTOCOL = {
    "cifs" : [
        {name: "username", label: "User Name", placeholder: "Enter new user name", rules: ["required"]},
        {name: "password", label: "Password", placeholder: "Enter new password", rules: ["required"], type: "password"},
        {name: "domain", label: "Domain", placeholder: "Enter new domain(optional)", rules: []}
    ],
    "s3" : [
        {name: "accessKey", label: "Access Key", placeholder: "Enter new access key", rules: ["required"]},
        {name: "secretKey", label: "Secret Key", placeholder: "Enter new secret key", rules: ["required"], type: "password"}
    ],
    "azure" : [
        {name: "storageAccountConnectionString", label: "Connection String", placeholder: "Enter new connection string", rules: ["required"]}
    ],
    "sftp" : [
        {name: "username", label: "User Name", placeholder: "Enter new user name", rules: ["required"]},
        {name: "password", label: "Password", placeholder: "Enter new password", rules: ["required"], type: "password"}
    ],
    "box" : [
        {name: "clientSecret", label: "Client secret", placeholder: "Enter new client secret", rules: ["required"]},
        {name: "privateKey", label: "Private key", placeholder: "Enter new private key", rules: ["required"], textArea: true},
        {name: "publicKeyId", label: "Public key id", placeholder: "Enter new public key id", rules: ["required"]},
        {name: "passphrase", label: "Passphrase", placeholder: "Enter new passphrase", rules: ["required"]}
    ],
    "gdrive" : [
        {name: "clientEmail", label: "Client Email", placeholder: "Enter new client email", rules: ["required", "email"], textArea: false},
        {name: "privateKey", label: "Private key", placeholder: "Enter new private key", rules: ["required"], textArea: true},
    ],
}

export const CASH_KEYS = {
    _FSX_VOLUMES: "_fsxVolumes",
    _ANF_VOLUMES : "_anfVolumes",
    _SHOW_SHARES: "_showShares",
    _SHOW_EXPORTS: "_showExports"
}

export const GLOBAL_KEYS = {
    _NOTIFICATIONS: "_notifications"
}
