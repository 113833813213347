import React from 'react';

/*eslint-disable */
export const SWITCH = (props) => {
    const {on, children} = props;
    const child = children.find ? children.find(child => child.props["data-when"] === on) : children.props["data-when"] === on ? children : null ;
    return child || <div/>
};

/*eslint-enable */
