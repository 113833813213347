import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { closeSearchWidget, createReport, deleteReports, getEndPointsAndReports } from "../../../store/wizard/action_creators";
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import SearchableTableContainer from "../../table/SearchableSelectableTable";
import { concat, filter, get, includes, noop, reverse, sortBy } from 'lodash'
import MainLoader from "../../_common_/MainLoader/MainLoader";
import { Button, useDialog } from '@netapp/shared-components';
import ReportStatus from "./ReportStatus";
import { ReactComponent as NoReports } from '../../../assets/svgs/no-reports.svg'
import { DISABLED_BUTTONS_TITLES, END_POINT_NAMES_MAP, REPORT_STATUS_RUNNING, ROUTES } from "../../../consts";
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';
import pluralize from 'pluralize'
import useRunOnceWhenTruthy from "../../../hooks/useRunOnceWhenTruthy";
import { MenuPopover } from '@netapp/shared-components';
import DeleteReportsDialog from "./DeleteReportDialog";
import { cmNavigateTo } from '../../../utils/cm-integration-utils';
import { useNavigate, useParams } from 'react-router-dom';

import './reports.scss'
import ReportErrorsStatus from "./ReportErrorsStatus";


const EndPoint = ({ endPoint }) => {
    const { endPointSVG, endPointTitle } = endPoint
    return (<div className="endpoint-report">
        {endPointSVG}
        <div className="endpoint-name" title={endPointTitle}>{endPointTitle}</div>
    </div>)

}

const ReportButtons = ({ ui, handleCreateReport, deleteReports, endpoint, submitting, id, navigate }) => {
    const { numOfReports, lastReport, relationshipIds, reportsIds } = ui;
    const { setDialog } = useDialog();

    const reportId = lastReport?.id;
    const status = lastReport?.status;
    const menuItems = () => {
        return [
            <button type="button"
                onClick={() => reportId ? navigate({
                    pathname: `/report-view/${reportId}`,
                    search: window.location.search
                }) : noop()}
                disabled={!reportId}
                title={!reportId ? DISABLED_BUTTONS_TITLES.noPreports : ""}
            >
                {"View"}
            </button>,
            <button type="button" onClick={() => handleCreateReport(endpoint, id)}
                disabled={relationshipIds.length === 0 || status === REPORT_STATUS_RUNNING}
                title={(status === REPORT_STATUS_RUNNING ? DISABLED_BUTTONS_TITLES.inProgress :
                    (relationshipIds.length === 0 ? DISABLED_BUTTONS_TITLES.noRelationship : ""))}
            >
                {"Create New"}
            </button>,
            <button type="button" onClick={(element) => {
                setDialog(<DeleteReportsDialog navigate={navigate} className="default-dialog" reports={[lastReport.id]} deleteReports={deleteReports} status={status} />, element.target);
            }}
                disabled={numOfReports === 0}
                title={(numOfReports === 0 ? DISABLED_BUTTONS_TITLES.noReports : "No running report")}
            >
                {submitting || lastReport.status === REPORT_STATUS_RUNNING ? "Abort last report" : "Delete last report"}
            </button>,
            <button type="button" onClick={(element) => {
                setDialog(<DeleteReportsDialog navigate={navigate} className="default-dialog" reports={reportsIds} fromAll={true} deleteReports={deleteReports} />, element.target);
            }}
                disabled={numOfReports === 0}
                title={(numOfReports === 0 ? DISABLED_BUTTONS_TITLES.noReports : "")}
            >
                {"Delete all reports"}
            </button>
        ]
    }
    if (numOfReports === 0) {
        const protocolNotSupported = endpoint[endpoint.protocol].provider === 'azure_data_lake';
        const notSupportedTarget = END_POINT_NAMES_MAP['AZURE_DATA_LAKE'];
        return (
            <div className="buttons-list">
                <Button type="button" onClick={() => handleCreateReport(endpoint, id)}
                    disabled={protocolNotSupported} title={protocolNotSupported ? DISABLED_BUTTONS_TITLES.notSupported(notSupportedTarget) : ""}
                    loading={submitting} className="reporter-button activate" variant="secondary">
                    {"Create"}
                </Button>
            </div>
        )
    } else {
        return (
            <div className="menu-popover">
                <MenuPopover
                    popoverClass="menu-popover"
                    triggerVariant="table"
                    menuItems={menuItems()}
                />

            </div>
        )
    }
}

const keysToSearch = ["endPoint", "id", "relationshipIds", "reports", "ui"];

const containerOptions = {
    singularTitle: "Path",
    pluralTitle: "Paths",
    keysToSearch
};

const renderRow = (row, handleCreateReport, submitting, deleteReports, navigate) => {
    const { ui, endpoint, id } = row;
    const { lastReport, lastReportTime, humanizedDuration, numOfReports, numOfErrors } = ui
    return (
        <div>
            {/*blank*/}
            <td className="blank" />
            <td className="end-point"><EndPoint endPoint={ui} /></td>
            <td className="num-of-reports">{numOfReports ? `${numOfReports} ${pluralize("Report", numOfReports)}` : "None"}</td>
            <td className="last">{lastReportTime ? lastReportTime : "---"}</td>
            <td className="duration">{humanizedDuration}</td>
            <td className="status"><ReportStatus status={lastReport?.status ? lastReport.status : '---'} reason={lastReport?.reason} /></td>
            <td><ReportErrorsStatus errorCount={numOfErrors} lastReportId={lastReport?.id} /></td>
            <td className="reports"><ReportButtons ui={ui} handleCreateReport={handleCreateReport}
                endpoint={endpoint} submitting={submitting ? submitting[id] : false}
                id={id} deleteReports={deleteReports} lastReport={lastReport} navigate={navigate} /></td>
        </div>
    )
}


const getTableHeader = (applySort) => {
    return [
        { className: 'blank', width: "2%" },
        { className: 'end-point', name: "endPoint", text: "Path", width: "20%" },
        { className: 'num-of-reports', name: "ui.numOfReports", text: "Reports #", sort: applySort, width: "10%" },
        { className: 'last', name: "ui.lastReportTime", text: "Last Report Date", sort: applySort },
        { className: 'duration', name: "ui.lastDuration", text: "Last Scan Duration", sort: applySort },
        { className: 'status', name: "ui.lastReport.status", text: "Last Report Status", sort: applySort },
        { className: 'errors', name: "ui.numOfErrors", text: "Errors", sort: applySort, width: "10%" },
        { className: 'reports', text: "Reports Actions", width: "12%" }
    ]
}


const Reports = (props) => {
    const { _endpoints, createReport, deleteReports, getEndPointsAndReports, closeSearchWidget, addNotification, clearNotifications } = props;
    const [showEndpoints, setShowEndpoints] = useState(null)
    const [submitting, setSubmitting] = useState({})
    const { relationshipId } = useParams('relationshipId');
    const [closeNotification, setCloseNotification] = useState(false);
    const navigate = useNavigate();

    useRunOnceWhenTruthy(() => {
        getEndPointsAndReports()
    }, !_endpoints?.succeeded)

    useEffect(() => {
        if (_endpoints?.data) {
            if (relationshipId) {
                const filteredByRelationship = filter(_endpoints?.data, (endpoint) => includes(endpoint.relationshipIds, relationshipId))
                setShowEndpoints(filteredByRelationship)
                cmNavigateTo(`/${ROUTES.SYNC}/${ROUTES.REPORTS}`, null, {});
            }
            else {
                setShowEndpoints(_endpoints?.data)
            }
        }
    }, [_endpoints, relationshipId])

    useEffect(() => {
        if (!closeNotification && relationshipId) {
            addNotification({
                id: NOTIFICATION_CONSTS.UNIQUE_IDS.REPORTS_NOTIFICATION,
                type: NOTIFICATION_CONSTS.TYPE.WARNING,
                children: 'The table is filtered by the relationship you selected in the dashboard. Click “View All” to view all paths.',
                onClose: () => setCloseNotification(true)
            });
        } else {
            clearNotifications();
        }
    }, [addNotification, clearNotifications, closeNotification, relationshipId]);

    const applySort = (column, ascending) => {
        let sortedEndpoints;
        if (column === 'ui.lastReportTime') {
            const endpointsWithNoReports = filter(showEndpoints, (endpoint) => !endpoint.ui.lastReportTime);
            const endpointsWithReports = filter(showEndpoints, (endpoint) => endpoint.ui.lastReportTime);
            sortedEndpoints = endpointsWithReports.sort((endPoint1, endPoint2) => {
                const endPoint1Value = get(endPoint1, column);
                const endPoint2Value = get(endPoint2, column);
                return new Date(endPoint1Value) - new Date(endPoint2Value)
            })
            const actualSort = ascending ? sortedEndpoints : reverse(sortedEndpoints)
            setShowEndpoints(concat(actualSort, endpointsWithNoReports)); //put the endpoints without reports always in the end
        }
        else {
            sortedEndpoints = sortBy(showEndpoints, [column]);
            setShowEndpoints(ascending ? sortedEndpoints : reverse(sortedEndpoints));
        }
    };

    const handleCreateReport = (endpoint, id) => {
        setSubmitting((prev) => {
            prev[id] = true
            return prev;
        });
        //we can't wait for a change in the endpoints since there can be an error.
        setTimeout(() => setSubmitting((prev) => {
            prev[id] = false
            return prev;
        }), 1000);
        createReport(endpoint)
    };


    return (
        <div className="scrollable-area">
            <div className="reports-dashboard">
                {!showEndpoints && <div>
                    <MainLoader />
                </div>}
                {showEndpoints && showEndpoints.length > 0 &&
                    <SearchableTableContainer containerOptions={containerOptions}
                        Table={{
                            renderRow: (row) => renderRow(row, handleCreateReport, submitting, deleteReports, navigate),
                            getTableHeader: () => getTableHeader(applySort)
                        }}
                        data={showEndpoints}
                        outsideFilter={relationshipId ? {
                            fullLength: _endpoints?.data?.length,
                            clear: () => {
                                closeSearchWidget();
                                navigate({
                                    pathname: `/reports`,
                                    search: window.location.search
                                });
                            }
                        } : {}}
                    />
                }
                {showEndpoints && showEndpoints.length === 0 && <div className="no-reports">
                    <NoReports />
                    <div className="no-reports-title">No Sync Relationships Exist</div>
                    <div className="no-reports-info">Create your first relationship from the Dashboard tab and then come
                        back later to run a report.
                    </div>
                </div>}
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    _endpoints: state.syncReducer._endpoints,
    _ui: { ...state.syncReducer._ui, ...state.global._ui }
});

const mapDispatchToProps = {
    getEndPointsAndReports,
    createReport,
    deleteReports,
    closeSearchWidget,
    addNotification,
    clearNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
