import { EC2 } from "@aws-sdk/client-ec2";
import {KMS} from '@aws-sdk/client-kms'
import { CloudFormation } from "@aws-sdk/client-cloudformation";
import {SimulatedEC2, SimulatedCloudFormation, SimulatedKMS} from "../simulator/SimulatedAwsResources";

export const DEFAULT_REGION = 'us-east-1';

const useMockApi = process.env.REACT_APP_SIMULATE_API === 'true';
const mockBaseApi = 'http://aws';

const createEC2Client = (credentials) => {
    const {region, ...rest} = credentials;
    const config = { credentials: rest, region, correctClockSkew: true};
    return useMockApi ? new SimulatedEC2(mockBaseApi) : new EC2(config);
};

const createCloudFormationClient = (credentials, region) => {
    const config = {credentials, region, correctClockSkew: true};
    return useMockApi ? new SimulatedCloudFormation(mockBaseApi) : new CloudFormation(config);
};

const createKMSClient = (credentials) => {
    const {region, ...rest} = credentials;
    const config = { credentials: rest, region, correctClockSkew: true};
    return useMockApi ? new SimulatedKMS(mockBaseApi) : new KMS(config);
};

// export const getRegions = (credentials) => {
//     const ec2 = createEC2Client({...credentials, region: DEFAULT_REGION});
//     return ec2.describeRegions().promise();
// };

export const getVpcs = (credentials) => {
    const ec2 = createEC2Client(credentials);
    return ec2.describeVpcs({});
};

export const getSubnets = (credentials) => {
    const ec2 = createEC2Client(credentials);
    return ec2.describeSubnets({});
};

export const getKeyPairs = (credentials) => {
    const ec2 = createEC2Client(credentials);
    return ec2.describeKeyPairs({});
};

export const getKmsKeys = async (credentials) => {
    const kms = createKMSClient(credentials);
    return  kms.listAliases({});
}

export const deployTemplate = (credentials, region, params) => {
    const cf = createCloudFormationClient(credentials, region);
    return cf.createStack(params);
};

export const getDeploymentStatus = (credentials, region, stackId) => {
    const cf = createCloudFormationClient(credentials, region);
    return cf.describeStacks({StackName: stackId});

};

export const deleteStack = (credentials, region, stackId) => {
    const cf = createCloudFormationClient(credentials, region);
    return cf.deleteStack({StackName: stackId});
};

export const awsRegions = [
    {'value': 'af-south-1', 'label': 'Africa | Cape Town'},
    {'value': 'ap-southeast-1', 'label': 'Asia Pacific | Singapore'},
    {'value': 'ap-southeast-2', 'label': 'Asia Pacific | Sydney'},
    {'value': 'ap-southeast-3', 'label': 'Asia Pacific | Jakarta'},
    {'value': 'ap-southeast-4', 'label': 'Asia Pacific | Melbourne'},
    {'value': 'ap-northeast-1', 'label': 'Asia Pacific | Tokyo'},
    {'value': 'ap-northeast-2', 'label': 'Asia Pacific | Seoul'},
    {'value': 'ap-northeast-3', 'label': 'Asia Pacific | Osaka'},
    {'value': 'ap-east-1', 'label': 'Asia Pacific | Hong Kong'},
    {'value': 'ap-south-1', 'label': 'Asia Pacific | Mumbai'},
    {'value': 'ap-south-2', 'label': 'Asia Pacific | Hyderabad'},
    {'value': 'ca-central-1', 'label': 'Canada | Central'},
    {'value': 'eu-central-1', 'label': 'EU | Frankfurt'},
    {'value': 'eu-central-2', 'label': 'EU | Zurich'},
    {'value': 'eu-south-1', 'label': 'EU | Milan'},
    {'value': 'eu-south-2', 'label': 'EU | Aragón'}, //spain
    {'value': 'eu-west-1', 'label': 'EU | Ireland'},
    {'value': 'eu-west-2', 'label': 'EU | London'},
    {'value': 'eu-west-3', 'label': 'EU | Paris'},
    {'value': 'eu-north-1', 'label': 'EU | Stockholm'},
    {'value': 'me-south-1', 'label':'Middle East | Bahrain'},
    {'value': 'me-central-1', 'label':'Middle East (UAE)'},
    {'value': 'il-central-1', 'label':'Israel (Tel Aviv)'},
    {'value': 'sa-east-1', 'label': 'South America | Sao Paulo'},
    {'value': 'us-east-1', 'label': 'US East | N. Virginia'},
    {'value': 'us-east-2', 'label': 'US East | Ohio'},
    {'value': 'us-west-1', 'label': 'US West | N. California'},
    {'value': 'us-west-2', 'label': 'US West | Oregon'},
    {'value': 'us-gov-west-1', 'label': 'GovCloud | US-West'},
    {'value': 'us-gov-east-1', 'label': 'GovCloud | US-East'}
];

