import React from 'react'
import {ReactComponent as CSIcon} from './assets/svgs/landing-cs.svg'
import {ReactComponent as CMIcon} from './assets/svgs/landing-cm.svg'
import {ReactComponent as ArrowsIcon} from './assets/svgs/landing-arrows.svg'
import {ReactComponent as ClockIcon} from './assets/svgs/landing-clock.svg'
import {Button} from '@netapp/shared-components';
import queryString from 'query-string';
import {get} from 'lodash'


import './landingPage.scss'
import useRunOnce from "./hooks/useRunOnce";

const IconWithText = ({icon: Icon, text}) => {
    return (<div className="icon-with-text">
        {Icon}
        <div>{text}</div>
    </div>)
}

const LandingPage = () => {
    const redirectUrl = process.env.REACT_APP_INTEGRATED_CS;
    useRunOnce(() => setTimeout(() => {
        const queryParams = queryString.parse(window.location.search);
        const stay = get(queryParams, 'stay', null);
        if (!stay)
            window.location = redirectUrl;
    }, 30000))

    return (<div className="landing-page">
        <header>
            <IconWithText icon={<CSIcon/>} text="Copy & sync"/>
            <ArrowsIcon/>
            <IconWithText icon={<CMIcon/>} text="Cloud Manager"/>
        </header>
        <div className="landing-content">
            <div className="landing-left">
                <h1>Copy & sync is now fully integrated into the Cloud Manager user interface</h1>
                <p>The standalone Copy & sync service has been retired. You should now access Copy & sync directly from Cloud Manager. Cloud Manager provides a centralized control plane to manage, monitor, and automate data in hybrid cloud environments.</p>
                <Button type="button" onClick={() => window.location = redirectUrl}>Go to Copy & sync's New Home</Button>
            </div>
            <img src={require('./assets/images/landing-images.png')} alt=""/>
        </div>
        <footer>
            <div>
                <ClockIcon/>
                <div className="clock-text">
                    Your browser should automatically redirect you in 30 seconds.
                    If it doesn't, then use this link:  <a href={redirectUrl} target="_self" rel="noopener noreferrer">{redirectUrl}</a>
                </div>
            </div>
        </footer>
    </div>)
}

export default LandingPage;
