import React from "react";
import {TooltipInfo, useDialog} from '@netapp/shared-components';
import DataBrokerGroupInfo from "../DataBrokerGroupInfo";
import TagsPopover from "../../widgets/TagsPopover";
import {createStringFromEndPointObject} from "../../../utils/helpers"
import {getFixedProtocol, getSVGByProtocol} from '../../../utils/dashboard-utils';
import {
    AZURE_BLOB_TIER_OPTIONS, GCP_STORAGE_CLASS_OPTIONS, STORAGE_CLASS_OPTIONS,
    CREDENTIALS_BY_PROTOCOL, RESPONSE_STATUS_RUNNING, STATUS_CONTINUOUSLY_SYNCING
} from '../../../consts';
import _ from 'lodash';
import {ReactComponent as DataBrokerIcon} from "../../../assets/svgs/group-icon.svg";
import {ReactComponent as EditCredentialsIcon} from "../../../assets/svgs/pencil-icon.svg"
import EditCredentialsDialog from "../EditCredentialsDialog";
import {shouldSupportCredentialsEdit} from "../../../utils/data-utils";
import RelationshipName from "./RelationshipName";

const EndpointContainer = ({svg, from, targetSettingsDiv, path, showEditCredentials, isRunning, protocol, relationshipId, tooltip}) => {
    const {setDialog} = useDialog();
    return (<div className="endpoint-container">
        {svg}
        <div className="text">
            <div className="title">{from}</div>
            <div className="data">
                {targetSettingsDiv &&
                <TooltipInfo popoverClass="target-settings-info">{targetSettingsDiv}</TooltipInfo>}
                <div className="name" title={tooltip ? tooltip : path}>{path}</div>
            </div>
            {showEditCredentials && <div title={isRunning ? "Credentials can't be updated while sync in progress" : ""}
                                         className={`edit-credentials ${isRunning ? 'disabled' : ''}`} onClick={(el) =>
                {isRunning ? _.noop() : setDialog(
                    <EditCredentialsDialog
                        relationshipId={relationshipId}
                        from={from} protocol={protocol}
                        credentialsToUpdate={CREDENTIALS_BY_PROTOCOL[protocol]}
                        path={path}
                    />,
                    el.target
                )}
            }
            >
                <EditCredentialsIcon/>
                <div>Update Credentials</div>
            </div>}
        </div>
    </div>)

}

const RelationshipInfo = ({data: {id, encryption, groupInfo, settings, name, ui: {source, target, isScheduleOn, tags, copyDoneByCloudProvider, status}}}) => {
    const isSecureRelationship = !!encryption;
    const sourceSVG = getSVGByProtocol(getFixedProtocol(source), isSecureRelationship);
    const targetSVG = getSVGByProtocol(getFixedProtocol(target), isSecureRelationship);
    const syncTrigger = `Schedule [${isScheduleOn}]`;
    const sourceTitle = createStringFromEndPointObject(source);
    const targetTitle = createStringFromEndPointObject(target);
    const targetTooltip = target.protocol === 'gdrive' ? `${targetTitle}(${target[target.protocol]?.subject})` : targetTitle;

    //calculate the target settings
    const targetSettings = [];
    if (target.protocol === "s3" && !_.isNil(target.s3.encryption)) {
        targetSettings.push("Encryption key was set on target bucket");
    }
    if (target.protocol === "s3" && target.s3.storageClass) {
        targetSettings.push(`Selected Storage Class for target bucket: "${_.find(STORAGE_CLASS_OPTIONS, {value: target.s3.storageClass}).label}"`);
    }
    if (target.protocol === "azure" && target.azure.provider === 'azure' && target.azure.blobTier) {
        targetSettings.push(`Selected Blob Tier for target blob: "${_.find(AZURE_BLOB_TIER_OPTIONS, {value: target.azure.blobTier}).label}"`);
    }

/*    if (target.protocol === "azure" && target.azure.provider === 'azure_data_lake' && target.azure.blobTier) {
        targetSettings.push(`Selected Access Tier for target: "${_.find(AZURE_BLOB_TIER_OPTIONS, {value: target.azure.blobTier}).label}"`);
    }*/

    if (target.protocol === "gcp" && target.gcp.storageClass) {
        targetSettings.push(`Selected Storage Class for target bucket: "${_.find(GCP_STORAGE_CLASS_OPTIONS, {value: target.gcp.storageClass}).label}"`);
    }
    const showTargetSettings = targetSettings.length > 0;

    const showEditSourceCredentials = shouldSupportCredentialsEdit(source.protocol, source[source.protocol].provider);
    const showEditTargetCredentials = shouldSupportCredentialsEdit(target.protocol, target[target.protocol].provider);

    const tagsType = settings.objectTagging ? "Tags" : "Metadata"

    const targetSettingsDiv = (
        <div>
            {targetSettings.map((setting, index) => {
                return <div key={index}>{setting}
                    {index < targetSettings.length - 1 && <hr/>}
                </div>

            })}
        </div>
    );


    const running = [STATUS_CONTINUOUSLY_SYNCING, RESPONSE_STATUS_RUNNING ].includes(status);
    return (
        <>
            <div className="relationship-info">
                <div className="left-pane">
                    <RelationshipName name={name} relationshipId={id} isRunning={running}/>
                    <EndpointContainer svg={sourceSVG} from="Source" path={sourceTitle} relationshipId={id}
                                       showEditCredentials={showEditSourceCredentials}
                                       protocol={source.protocol} isRunning={running}/>
                    <EndpointContainer svg={targetSVG} from="Target" path={targetTitle} tooltip={targetTooltip} relationshipId={id}
                                       targetSettingsDiv={showTargetSettings ? targetSettingsDiv : null}
                                       protocol={target.protocol} isRunning={running}
                                       showEditCredentials={showEditTargetCredentials}/>
                    <div className="data-broker">
                        <span className="icon"><DataBrokerIcon/></span>
                        <div className="text">
                            <div
                                className="title">Data Broker Group</div>
                            <DataBrokerGroupInfo group={groupInfo}
                                                 relationshipId={id}
                                                 relationshipStatus={status}
                                                 copyDoneByCloudProvider={copyDoneByCloudProvider}/>
                        </div>
                    </div>

                </div>
                <div className="relationship-footer">
                    <div className="footer-part">
                        {syncTrigger}
                    </div>
                    <div className="separator">
                        |
                    </div>
                    <div className="footer-part">
                        <TagsPopover withDiv={true} tags={tags || []} tagsType={tagsType} leftAdjust={-86}
                                     arrowStyle={{left: "46px"}}/>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RelationshipInfo;
