import {supportAclCopy} from "../../../../utils/relationshipUtils";
import {InputField} from "../../../_common_/forms/forms";
import AclSelection from "../AclSelectionNew";
import {Field} from 'redux-form';
import {Field as FinalField} from 'react-final-form'

const S3BasedAdditionalSettings = ({_wizard: {protocols, wizardInfo: {source, target}},isSourceStep, isTargetStepFromSameSourceType, isFinalForm}) => {
    const showACL= supportAclCopy(protocols, source, target) && !isTargetStepFromSameSourceType && isSourceStep;

    return (
        <div>
            <div className="credentials-container">
                <div className="credentials-title">Object Storage Credentials:</div>
                {!isFinalForm && <div className="credentials-fields">
                    <Field name="accessKey" component={InputField} label="Access Key" type="text"/>
                    <Field name="secretKey" component={InputField} label="Secret Key" type="password"
                           autoComplete="off"/>
                    {<Field name="port" component={InputField} label="Port" type="text"/>}
                </div>}
                {isFinalForm && <div className="credentials-fields">
                    <FinalField name="accessKey" component={InputField} label="Access Key" type="text"/>
                    <FinalField name="secretKey" component={InputField} label="Secret Key" type="password"
                           autoComplete="off"/>
                    {<FinalField name="port" component={InputField} label="Port" type="text"/>}
                </div>}
            </div>
            {showACL && <AclSelection/>}
        </div>
    )
};

export default S3BasedAdditionalSettings;


