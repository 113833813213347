import _ from "lodash";
import sdk from '../../sdk.js';
import cvsApi from '../../services/cvs.api.service';
import cmApi from '../../services/cm.api.service';
import {getCredentialsByType} from '../../utils/sync-utils';
import {fixPath, removeStartingAndEndingSlash} from "../../utils/encoders";
import {removeKeyFromPath} from "../../utils/helpers";
import * as actionCreatorsConsts from "./action_creators_consts";
import {create, createMultiple} from "../../utils/actions-utils";
import {GET_LICENSE_SETTINGS} from "../global/action_creators_consts";
import {rollbackDeployment} from "../../services/rollback.deployment.service";
import * as consts from "../../consts";

export const startup = (dispatch) => {
    dispatch(getRelationships());
    dispatch(getDataBrokersGroups());
};


export const updateUrlParams = (params) => create(actionCreatorsConsts.UPDATE_URL_PARAMS, params);

export const createDataBroker = (values) => create(actionCreatorsConsts.DATA_BROKER, sdk.createDataBroker(values), undefined, true, {port: values.port});
export const restartDataBroker = (brokerId) => create(actionCreatorsConsts.DATA_BROKER_RESTART, sdk.restartDataBroker(brokerId));
export const updateDataBrokerProxy = (brokerId, opts) => create(actionCreatorsConsts.DATA_BROKER_PROXY, sdk.updateProxyConfiguration(brokerId, opts), undefined, true);
export const deleteDataBrokersGroup = (groupId, deployments) => {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.DELETE_GROUP,
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.deleteGroup(groupId);
                _.forEach(deployments, (deployment => {
                    rollbackDeployment(deployment);
                }))
                dispatch(getDataBrokersGroups());
                return res
            })()
        };
        dispatch(action);
        return action.payload
    }
};

export const getServersByType = (protocol, provider, protocolAndProviderKey) => create(actionCreatorsConsts.GET_SERVERS, sdk.getServers(protocol, provider), undefined, false, {protocolAndProviderKey});

export const azureStorageAccounts = () => create(actionCreatorsConsts.GET_AZURE_STORAGE_ACCOUNTS, sdk.azureStorageAccounts());

export const azureDataLakeStorageAccounts = () => create(actionCreatorsConsts.GET_AZURE_DATA_LAKE_STORAGE_ACCOUNTS, sdk.azureDataLakeStorageAccounts());

export const deleteDataBroker = (id, existingDeployment) => {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.DELETE_DATA_BROKER,
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.deleteDataBroker(id);
                rollbackDeployment(existingDeployment);
                dispatch(getDataBrokersGroups());
                return res
            })()
        };
        dispatch(action);
        return action.payload
    }
};

export const moveDataBroker = (dataBrokerId, data) => {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.MOVE_DATA_BROKER,
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.moveDataBroker(dataBrokerId, data);
                dispatch(getDataBrokersGroups());
                return res
            })()
        };
        dispatch(action);
        return action.payload
    }
};


export const clearCloseSearchWidget = () => create(actionCreatorsConsts.CLEAR_CLOSE_SEARCH_WIDGET);

export const closeSearchWidget = () => create(actionCreatorsConsts.CLOSE_SEARCH_WIDGET);

export const clearUrlParamsIfChanged = (changedParam, newVal, without, extraKeyToClear, forceClearDueToCredentialsChange) => create(actionCreatorsConsts.CLEAR_URL_PARAMS, {
    changedParam,
    newVal,
    without,
    extraKeyToClear,
    forceClearDueToCredentialsChange
});

export const clearShowAction = (keyToClear) => create(actionCreatorsConsts.CLEAR_SHOW_ACTION, {keyToClear});

export const bucketsSelectStep = () => create(actionCreatorsConsts.BUCKETS_SELECT_STEP);
export const bucketsSecondStep = () => create(actionCreatorsConsts.BUCKETS_ADDITIONAL_SETTINGS_STEP);

export const azureSelectStep = () => create(actionCreatorsConsts.AZURE_SELECT_STEP);
export const azureAdditionalStorageSettingsStep = () => create(actionCreatorsConsts.AZURE_ADDITIONAL_SETTINGS_STEP);

export const gcpSelectStep = () => create(actionCreatorsConsts.GCP_SELECT_STEP);
export const gcpAdditionalStorageSettingsStep = () => create(actionCreatorsConsts.GCP_ADDITIONAL_SETTINGS_STEP);
export const updateStepCredentials = payload => create(actionCreatorsConsts.UPDATE_STEP_CREDENTIALS, payload);
export const resetCacheForReload = payload => create(actionCreatorsConsts.RESET_CACHE_FOR_RELOAD, payload);

export const showExports = (fileServer, isEFS, groupId) => {
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_EXPORTS,
            extra: {host: fileServer, groupId},
            payload: sdk.showExports( fileServer, isEFS, groupId)
        })
    }
};


export const listFolders = ({fileServer, path, firstLayerPath, version, isEFS, accessPoint, groupId}) => {
    const finalPath = removeKeyFromPath(path, firstLayerPath);

    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_FOLDERS,
            extra: {fileServer, path, groupId},
            payload: sdk.listFolder(fileServer, finalPath, firstLayerPath, version, isEFS, accessPoint, groupId)
        });
    }
};

export const showShares = (host, cifsVersion, isTargetOfSameSrc, groupId) => {
    const cifsCredentials = getCredentialsByType("cifs", host, isTargetOfSameSrc);
    if (!cifsCredentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.SHOW_SHARES,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra: {host,  groupId},
                payload: {
                    data: {message: "Unable to get shares, no credentials defined"}
                }
            })
        }
    }
    const {username, password, domain} = cifsCredentials;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_SHARES,
            extra: {host,  username, password, domain, cifsVersion, groupId},
            payload: sdk.showShares( host, username, password, domain, cifsVersion, groupId)
        })
    }
};


export const listShareFolders = ({ fileServer, path, firstLayerPath, version, isTargetStepFromSameSourceType, groupId}) => {
    const cifsCredentials = getCredentialsByType("cifs", fileServer, isTargetStepFromSameSourceType);
    if (!cifsCredentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_SHARE_FOLDERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra: {host: fileServer,  groupId},
                payload: {
                    data: {message: "Unable to get folders, no credentials defined"}
                }
            })
        }
    }
    //remove the share part from the path
    const fixedShare = fixPath(firstLayerPath);
    const fixedPath = removeKeyFromPath(fixPath(path), fixedShare);
    // console.log(`%c listShareFolders fixedShare @ ${fixedShare} fixedPath @ (${fixedPath})`, "font-weight: bold;");
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_SHARE_FOLDERS,
            extra: {host: fileServer, path,  groupId}, //put the original path since we need the starting slash in the drilldown
            payload: sdk.listShareFolders( fileServer, fixedShare, fixedPath, cifsCredentials.username, cifsCredentials.password, cifsCredentials.domain, version, groupId)
        });
    }
};

export const showS3BasedBuckets = (host, isTargetOfSameSrc, provider, groupId) => {
    const osCredentials = getCredentialsByType("objectStorage", host, isTargetOfSameSrc);
    if (!osCredentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.SHOW_S3BASED_BUCKETS,
                status: actionCreatorsConsts.STATUS_ERROR,
                payload: {
                    data: {message: "Unable to get buckets, no credentials defined"}
                },
                extra: {host, groupId}
            })
        }
    }
    const {port, accessKey, secretKey} = osCredentials;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_S3BASED_BUCKETS,
            extra: {host: host, port, accessKey, secretKey, groupId},
            payload: sdk.fetchS3BasedBuckets(host, osCredentials, provider, groupId)
        })
    }
};

export const listS3BasedBucketFolders = (host, bucket, path, isTargetOfSameSrc, provider, groupId) => {
    const osCredentials = getCredentialsByType("objectStorage", host, isTargetOfSameSrc);
    if (!osCredentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_S3BASED_BUCKETS_FOLDERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                payload: {
                    data: {message: "Unable to get folders, no credentials defined"}
                },
                extra: {host, groupId}
            })
        }
    }
    //remove the share part from the path
    const fixedPath = removeKeyFromPath(path, bucket);
    // console.log(`%c listS3BasedBucketFolders bucket @ ${bucket} fixedPath @ (${fixedPath})`, "font-weight: bold;");
    const {accessKey, secretKey, port} = osCredentials;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_S3BASED_BUCKETS_FOLDERS,
            extra: {host, bucket, path, accessKey, groupId}, //keep the original path since we need it in the drill down
            payload: sdk.listS3BasedBucketFolders( host, bucket, fixedPath, accessKey, secretKey, port, provider, groupId)
        });
    }
};

export const showGCPBuckets = (groupId) => {
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_GCP_BUCKETS,
            extra: {groupId},
            payload: sdk.listGCPBuckets(groupId)
        })
    }
};

export const listGCPBucketFolders = (bucket, path, groupId) => {
    //remove the share part from the path
    const fixedPath = removeKeyFromPath(path, bucket);
    // console.log(`%c listGCPBucketFolders bucket @ ${bucket} fixedPath @ (${fixedPath})`, "font-weight: bold;");
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_GCP_BUCKET_FOLDERS,
            extra: {groupId, bucket, path}, //keep the original path since we need it in the drill down
            payload: sdk.listGCPBucketFolders(bucket, fixedPath, groupId)
        });
    }
};

export const listBoxRootFolders = (groupId, appName, isTargetStepFromSameSourceType) => {
    const credentials = getCredentialsByType("box", appName, isTargetStepFromSameSourceType);
    const extra = {groupId, appName};
    if (!credentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_BOX_ROOT_FOLDER,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra,
                payload: {
                    data: {message: "Unable to get BOX root directories, no credentials defined"}
                }
            })
        }
    }
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_BOX_ROOT_FOLDER,
            extra,
            payload: sdk.listBoxRootFolders({groupId, appName, ...credentials})
        })
    }
};
export const listBoxFolders = ({groupId, appName, folderId, folderName, isTargetStepFromSameSourceType}) => {
    const credentials = getCredentialsByType("box", appName, isTargetStepFromSameSourceType);
    const extra = {groupId, appName, folderId, folderName}; //keep the original path since we need it in the drill down
    if (!credentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_BOX_FOLDERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra,
                payload: {
                    data: {message: "Unable to get BOX folders, no credentials defined"}
                }
            })
        }
    }
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_BOX_FOLDERS,
            extra,
            payload: sdk.listBoxFolders({groupId, folderId, folderName, appName, ...credentials})
        });
    }
};

export const listGoogleDrives = (groupId, subject, isTargetStepFromSameSourceType) => {
    const credentials = getCredentialsByType("google", subject, isTargetStepFromSameSourceType);
    const extra = {groupId, subject};
    if (!credentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_GOOGLE_DRIVES,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra,
                payload: {
                    data: {message: "Unable to get Google Drives, no credentials defined"}
                }
            })
        }
    }
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_GOOGLE_DRIVES,
            extra,
            payload: sdk.listGoogleDrives({groupId,subject, ...credentials})
        })
    }
};

export const listGoogleDriveFolders = ({groupId, subject, driveId, folderId, folderName, isTargetStepFromSameSourceType}) => {
    const credentials = getCredentialsByType("google", subject, isTargetStepFromSameSourceType);
    const extra = {subject, folderId, folderName}; //keep the original path since we need it in the drill down
    if (!credentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_GOOGLE_DRIVE_FOLDERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra,
                payload: {
                    data: {message: "Unable to get Google drive folders, no credentials defined"}
                }
            })
        }
    }
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_GOOGLE_DRIVE_FOLDERS,
            extra,
            payload: sdk.listGoogleDriveFolders({groupId, subject, driveId, folderId, folderName, ...credentials})
        });
    }
};

export const listSftpRootDirectories = (groupId, host, port, isTargetStepFromSameSourceType) => {
    const credentials = getCredentialsByType("sftp", host, isTargetStepFromSameSourceType);
    const extra = {groupId, host, port};
    if (!credentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_SFTP_ROOT_DIRECTORY,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra,
                payload: {
                    data: {message: "Unable to get SFTP root directories, no credentials defined"}
                }
            })
        }
    }
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_SFTP_ROOT_DIRECTORY,
            extra,
            payload: sdk.listSftpRootDirectory({groupId, host, port, ...credentials})
        })
    }
};

export const listSftpDirectories = ({groupId, host, path, isTargetStepFromSameSourceType}) => {
    const credentials = getCredentialsByType("sftp", host, isTargetStepFromSameSourceType);
    const extra = {groupId, host, path}; //keep the original path since we need it in the drill down
    if (!credentials) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_SFTP_DIRECTORIES,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra,
                payload: {
                    data: {message: "Unable to get SFTP directories, no credentials defined"}
                }
            })
        }
    }
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_SFTP_DIRECTORIES,
            extra,
            payload: sdk.listSftpDirectories({groupId, host, path, ...credentials})
        });
    }
};

export const listAwsKmsKeys = (groupId, bucket) => {
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_AWS_KMS_KEYS,
            extra: {groupId, bucket},
            payload: sdk.listAwsKmsKeys(groupId, bucket)
        })
    }
};

export const listS3PrivateLinks = (groupId, region, bucket) => {
    if (!region) {
        // in case of external bucket - there is no region - we cant get S3 private links- which makes sense we try to access bucket not in our account
        // we shouldnt call to S3 private links CS-6554- List private links on a manually added bucket returns an error
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.UPDATE_S3_PRIVATE_LINKS,
                status: actionCreatorsConsts.STATUS_SUCCESS,
                payload: {
                    groupId,
                    [consts.PAYLOAD_LIST_S3_PRIVATE_LINKS]:{
                        s3PrivateLinks:[], bucket, status: 'SUCCEEDED'
                    }
                }
            })
        }
    }

    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_S3_PRIVATE_LINKS,
            extra: {groupId, region, bucket},
            payload: sdk.listS3PrivateLinks(groupId, region, bucket)
        })
    }
};

export const clearSync = () => create(actionCreatorsConsts.CLEAR_SYNC);

export const clearWizardCache = ({clearGroups}) => create(actionCreatorsConsts.CLEAR_WIZARD_CACHE, {clearGroups});
export const clearNewWizard = () => create(actionCreatorsConsts.CLEAR_NEW_WIZARD);

export const clearFoldersCache = (props) => create(actionCreatorsConsts.CLEAR_FOLDERS_CACHE, props);

export const addNewBucket = (bucketName, dataBrokerId, cacheName, groupId) => create(actionCreatorsConsts.ADD_BUCKET_NAME, {bucketName, dataBrokerId, cacheName, groupId});

export const addNewExport = (exportName, nfsServer, dataBrokerId, groupId) => create(actionCreatorsConsts.ADD_EXPORT_MANUALLY, {exportName, nfsServer, dataBrokerId, groupId});

export const addNewShare = (shareName, cifsServer, dataBrokerId, groupId) => create(actionCreatorsConsts.ADD_SHARE_MANUALLY, {shareName, cifsServer, dataBrokerId, groupId});

export const createSync = (opts) => create(actionCreatorsConsts.CREATE_SYNC, sdk.createSync(opts));
export const updateCredentials = (relationshipId, opts) => create(actionCreatorsConsts.UPDATE_CREDENTIALS, sdk.updateCredentials(relationshipId, opts), undefined, true);
export const updateRelationshipName = (relationshipId, opts) => {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.UPDATE_RELATIONSHIP_NAME,
            extra: {relationshipId, name: opts.remove ? '' : opts.add[0].value},
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.updateRelationshipName(relationshipId, opts);
                // dispatch(getRelationships());
                return res;
            })()
        };
        dispatch(action);
        return action.payload;
    }

};

export const syncNow = (relationshipId) => {
    return dispatch =>
        dispatch({
            type: actionCreatorsConsts.SYNC_NOW,
            extra: {relationshipId},
            payload: sdk.syncNow(relationshipId)
        });
};

export function abortSync(relationshipId) {
    return dispatch =>
        dispatch({
            type: actionCreatorsConsts.ABORT_SYNC,
            extra: {relationshipId},
            payload: sdk.abortSync(relationshipId)
        });
}

export const updateRelationshipSettings = (relationshipId, settings) => create(actionCreatorsConsts.UPDATE_RELATIONSHIP_SETTINGS, sdk.updateRelationship(relationshipId, settings), undefined, true); //self error handling

export const showBuckets = (groupId) => {
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_BUCKETS,
            extra: {groupId},
            payload: sdk.fetchBuckets(groupId)
        });
    }
};

export const listBucketFolders = (bucket, path, groupId) => {
    const fixedPath = removeKeyFromPath(path, bucket);
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_BUCKETS_FOLDERS,
            extra: {bucket, path, groupId},
            payload: sdk.listBucketFolders(groupId, bucket, fixedPath)
        });
    }
};

export const showAzureBlobContainers = (storageAccountName, isTargetOfSameSrc, groupId) => {
    const azureCredentialsObject = getCredentialsByType("azure", storageAccountName, isTargetOfSameSrc);
    if (!azureCredentialsObject) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.SHOW_AZURE_BLOB_CONTAINERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra: {storageAccountName, groupId},
                payload: {
                    data: {message: "Unable to get azure containers, storage account connection string is not defined"}
                }
            })
        }
    }
    const {connectionString} = azureCredentialsObject;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_AZURE_BLOB_CONTAINERS,
            extra: {storageAccountName, connectionString, groupId},
            payload: sdk.fetchAzureBlobContainers(storageAccountName, connectionString, groupId)
        })
    }
};

export const listAzureBlobContainerFolders = (storageAccountName, container, path, isTargetOfSameSrc, groupId) => {
    const azureCredentialsObject = getCredentialsByType("azure", storageAccountName, isTargetOfSameSrc);
    const fixedPath = removeKeyFromPath(path, container);
    if (!azureCredentialsObject) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_CONTAINERS_FOLDERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra: {storageAccountName, groupId},
                payload: {
                    data: {message: "Unable to get folders, storage account connection string is not defined"}
                }
            })
        }
    }
    const {connectionString} = azureCredentialsObject;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_CONTAINERS_FOLDERS,
            extra: {container, path, storageAccountName, connectionString, groupId},
            payload: sdk.listContainerFolders(storageAccountName, connectionString, container, removeStartingAndEndingSlash(fixedPath), groupId)
        })
    }
};

export const showAzureDataLakeContainers = (storageAccountName, isTargetOfSameSrc, groupId) => {
    const azureCredentialsObject = getCredentialsByType("azureDataLakeGen2", storageAccountName, isTargetOfSameSrc);
    if (!azureCredentialsObject) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.SHOW_AZURE_DATA_LAKE_CONTAINERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra: {storageAccountName, groupId},
                payload: {
                    data: {message: "Unable to get azure data lake containers containers, storage account connection string is not defined"}
                }
            })
        }
    }
    const {connectionString} = azureCredentialsObject;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.SHOW_AZURE_DATA_LAKE_CONTAINERS,
            extra: {storageAccountName, connectionString, groupId},
            payload: sdk.fetchAzureDataLakeContainers(storageAccountName, connectionString, groupId)
        })
    }
};

export const listAzureDataLakeContainerFolders = (storageAccountName, container, path, isTargetOfSameSrc, groupId) => {
    // console.log(`%c listAzureDataLakeContainerFolders storageAccountName @ ${storageAccountName} container @ (${container}) isTargetOfSameSrc @ (${isTargetOfSameSrc})`, "font-weight: bold;");
    const azureCredentialsObject = getCredentialsByType("azureDataLakeGen2", storageAccountName, isTargetOfSameSrc);
    const fixedPath = removeKeyFromPath(path, container);
    if (!azureCredentialsObject) {
        return dispatch => {
            dispatch({
                type: actionCreatorsConsts.LIST_AZURE_DATA_LAKE_CONTAINERS_FOLDERS,
                status: actionCreatorsConsts.STATUS_ERROR,
                extra: {storageAccountName, groupId},
                payload: {
                    data: {message: "Unable to get folders, storage account connection string is not defined"}
                }
            })
        }
    }
    const {connectionString} = azureCredentialsObject;
    return dispatch => {
        dispatch({
            type: actionCreatorsConsts.LIST_AZURE_DATA_LAKE_CONTAINERS_FOLDERS,
            extra: {container, path, storageAccountName, connectionString, groupId},
            payload: sdk.listAzureDataLakeContainerFolders(storageAccountName, connectionString, container, removeStartingAndEndingSlash(fixedPath), groupId)
        })
    }
};


export function deleteRelationship(ids) {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.DELETE_RELATIONSHIP,
            extra: {ids},
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.deleteRelationship(ids);
                dispatch(getRelationships());
                return res;
            })()
        };
        dispatch(action);
        return action.payload;
    }
}

export const downloadDataBrokerLogs = (groupId, relationshipId) => create(
    actionCreatorsConsts.DOWNLOAD_DATA_BROKER_LOGS,
    (async () => await sdk.downloadDataBrokerLogs(groupId))(),
    undefined,
    true,
    {relationshipId},
);

export const startWizard = (opt) => create(actionCreatorsConsts.START_WIZARD, {...opt});
export const wizardStepStarted = (stepId) => create(actionCreatorsConsts.STARTED_WIZARD_STEP, stepId);
export const newWizardStepStarted = (stepId) => create(actionCreatorsConsts.STARTED_NEW_WIZARD_STEP, stepId);
export const wizardStepExited = (stepId) => create(actionCreatorsConsts.EXITED_WIZARD_STEP, stepId);
const createUpdater = (type, payload) => {
    const actualPayload = _.values(payload)[0];
    payload.data = actualPayload;

    return create(type, payload, actualPayload.status === "FAILED" ? actionCreatorsConsts.STATUS_ERROR : undefined)
};
export const updateDataBrokerStatus = (payload) => create(actionCreatorsConsts.UPDATE_DATA_BROKER_STATUS, payload, undefined); //handles errors by itself
export const updateExports = (payload) => createUpdater(actionCreatorsConsts.UPDATE_EXPORTS, payload);
export const updateFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_FOLDERS, payload);
export const updateShares = (payload) => createUpdater(actionCreatorsConsts.UPDATE_SHARES, payload);
export const updateShareFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_SHARE_FOLDERS, payload);
export const updateBoxRootFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_BOX_ROOT_FOLDER, payload);
export const updateBoxFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_BOX_FOLDERS, payload);
export const updateGoogleDrives = (payload) => createUpdater(actionCreatorsConsts.UPDATE_GOOGLE_DRIVES, payload);
export const updateGoogleDriveFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_GOOGLE_DRIVE_FOLDERS, payload);
export const updateSftpRootDirectory = (payload) => createUpdater(actionCreatorsConsts.UPDATE_SFTP_ROOT_DIRECTORY, payload);
export const updateSftpDirectories = (payload) => createUpdater(actionCreatorsConsts.UPDATE_SFTP_DIRECTORIES, payload);
export const updateBuckets = (payload) => createUpdater(actionCreatorsConsts.UPDATE_BUCKETS, payload);
export const updateBucketsFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_BUCKETS_FOLDERS, payload);
export const updateContainers = (payload) => createUpdater(actionCreatorsConsts.UPDATE_CONTAINERS, payload);
export const updateContainersFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_CONTAINERS_FOLDERS, payload);
export const updateDataLakeContainers = (payload) => createUpdater(actionCreatorsConsts.UPDATE_DATA_LAKE_CONTAINERS, payload);
export const updateDataLakeContainerFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_DATA_LAKE_CONTAINERS_FOLDERS, payload);

export const updateS3BasedBuckets = (payload) => createUpdater(actionCreatorsConsts.UPDATE_S3BASED_BUCKETS, payload);
export const updateS3BasedBucketsFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_S3BASED_BUCKETS_FOLDERS, payload);
export const updateGCPBuckets = (payload) => createUpdater(actionCreatorsConsts.UPDATE_GCP_BUCKETS, payload);
export const updateGCPBucketFolders = (payload) => createUpdater(actionCreatorsConsts.UPDATE_GCP_BUCKET_FOLDERS, payload);
export const updateRelationship = (payload) => create(actionCreatorsConsts.UPDATE_RELATIONSHIP, payload);//handles errors by itself
export const updateDatabrokerTransferRate = (payload) => create(actionCreatorsConsts.UPDATE_DATABROKER_TRANSFER_RATE, payload);
export const updateKmsKeys = (payload) => create(actionCreatorsConsts.UPDATE_KMS_KEYS, payload);
export const updateS3PrivateLinks = (payload) => create(actionCreatorsConsts.UPDATE_S3_PRIVATE_LINKS, payload);
export const updateReport = (payload) => create(actionCreatorsConsts.UPDATE_REPORT, payload);

// export const updateDataBrokerLogs = (payload) => create(actionCreatorsConsts.UPDATE_DATA_BROKER_LOGS, payload);
export const updateDataBrokerLogs = (payload) => {
    return (dispatch, getState) => {
        const state = getState();
        const action = {
            type: actionCreatorsConsts.UPDATE_DATA_BROKER_LOGS,
            payload,
            accessToken: _.get(state.global.auth, "accessToken")
        }
        dispatch(action)
        return action.payload;
    }
};

export const getRelationships = () => createMultiple([
    {type: actionCreatorsConsts.GET_RELATIONSHIPS, payload: sdk.getRelationships()},
    {type: GET_LICENSE_SETTINGS, payload: sdk.getLicenseSettings()},
    {type: actionCreatorsConsts.GET_ENDPOINTS_AND_REPORTS, payload: sdk.getEndPointsAndReports()},
    {type: actionCreatorsConsts.GET_DATA_BROKERS_GROUPS, payload: sdk.getDataBrokersGroups()}
]);

export const getCvsWorkingEnvironments = (accountId, workspaceId) => create(actionCreatorsConsts.GET_CVS_WORKING_ENVIRONMENTS, cvsApi.getWorkingEnvironments(accountId, workspaceId), undefined);
export const getAnfVolumes = (accountId, credentialsId) => create(actionCreatorsConsts.GET_ANF_VOLUMES, cvsApi.getAnfVolumes(accountId, credentialsId), undefined, false, {credentialsId});
export const getCMWorkingEnvironments = (workspaceId) => create(actionCreatorsConsts.GET_CM_WORKING_ENVIRONMENTS, cmApi.getCMWorkingEnvironments(workspaceId), undefined, false);
export const getCMVolumes = (provider, isHA, weId) => create(actionCreatorsConsts.GET_CM_VOLUMES, cmApi.getCMVolumes(provider, isHA, weId), undefined, false, {weId});
export const getFSXWorkingEnvironments = (workspaceId) => create(actionCreatorsConsts.GET_FSX_WORKING_ENVIRONMENTS, cmApi.getFSXWorkingEnvironments(workspaceId), undefined, false);
export const getFSXVolumes = (weId) => create(actionCreatorsConsts.GET_FSX_VOLUMES, cmApi.getFSXVolumes(weId), undefined, false, {weId});

export const getDataBrokersGroups = () => create(actionCreatorsConsts.GET_DATA_BROKERS_GROUPS, sdk.getDataBrokersGroups());
export const getDataBrokerConfiguration = (groupId, agentId) => create(actionCreatorsConsts.GET_DATA_BROKER_CONFIGURATION, sdk.getDataBrokerConfiguration(agentId), undefined, true, { agentId, groupId })
export const updateDataBrokerConfiguration = (localConfiguration, header) => create(actionCreatorsConsts.UPDATE_DATA_BROKER_CONFIGURATION, {localConfiguration, header}, undefined, true);

export const renameGroup = (groupId, newName) => {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.RENAME_GROUP,
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.editGroupName(groupId, newName);
                dispatch(getDataBrokersGroups());
                return res
            })()
        };
        dispatch(action);
        return action.payload
    };
};


export const editGroupSettings = (groupId, settings) => create(actionCreatorsConsts.EDIT_GROUP_SETTINGS, sdk.editGroupSettings(groupId, settings), undefined, true);

export const getEndPointsAndReports = () => create(actionCreatorsConsts.GET_ENDPOINTS_AND_REPORTS, sdk.getEndPointsAndReports());
export const createReport = (endpoint) => create(actionCreatorsConsts.CREATE_REPORT, sdk.createReport(endpoint));
export const deleteReports = (reports) => {
    return dispatch => {
        const action = {
            type: actionCreatorsConsts.DELETE_REPORTS,
            selfErrorHandling: true,
            payload: (async () => {
                const res = await sdk.deleteReports(reports);
                dispatch(getEndPointsAndReports());
                return res
            })()
        };
        dispatch(action);
        return action.payload
    }
}


export const removeError = (id) => create(actionCreatorsConsts.REMOVE_ERROR, id);
