export const getCMWorkingEnvironmentsMocked = (workspaceId) => {
    if (workspaceId === 'empty') {
        return {
            azureVsaWorkingEnvironments: [],
            gcpVsaWorkingEnvironments: [],
            vsaWorkingEnvironments: [],
            onPremWorkingEnvironments: []
        }
    }
    return {
        isSimulator: true,
        "vsaWorkingEnvironments": [
            {
                "publicId": "VsaWorkingEnvironment-PSmYj3YN",
                "name": "CVOAWSSN",
                "tenantId": "Tenant-2345",
                "svmName": "svm_CVOAWSSN",
                "creatorUserEmail": "auth0|5d481fd2ae65cd0cc3dd4b76",
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "awsProperties": null,
                "reservedSize": null,
                "encryptionProperties": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "actionsRequired": null,
                "interClusterLifs": null,
                "cronJobSchedules": null,
                "snapshotPolicies": null,
                "svms": null,
                "activeActions": null,
                "replicationProperties": null,
                "schedules": null,
                "cloudProviderName": "Amazon",
                "isHA": false,
                "workingEnvironmentType": "VSA",
                "supportRegistrationProperties": null,
                "supportRegistrationInformation": [],
                "haProperties": null,
                "capacityFeatures": null,
                "cloudSyncProperties": null,
                "supportedFeatures": null,
                "k8sProperties": null,
                "fpolicyProperties": null,
                "saasProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            },
            {
                "publicId": "VsaWorkingEnvironment-PSmYj3YQ",
                "name": "CVOAWSHAwithaverylongnametotestlongnames",
                "tenantId": "Tenant-2345",
                "svmName": "svm_CVOAWSHA",
                "creatorUserEmail": "auth0|5d481fd2ae65cd0cc3dd4b76",
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "awsProperties": null,
                "reservedSize": null,
                "encryptionProperties": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "actionsRequired": null,
                "interClusterLifs": null,
                "cronJobSchedules": null,
                "snapshotPolicies": null,
                "svms": null,
                "activeActions": null,
                "replicationProperties": null,
                "schedules": null,
                "cloudProviderName": "Amazon",
                "isHA": true,
                "workingEnvironmentType": "VSA",
                "supportRegistrationProperties": null,
                "supportRegistrationInformation": [],
                "haProperties": null,
                "capacityFeatures": null,
                "cloudSyncProperties": null,
                "supportedFeatures": null,
                "k8sProperties": null,
                "fpolicyProperties": null,
                "saasProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            }
        ],
        "onPremWorkingEnvironments": [
            {
                "publicId": "OnPremWorkingEnvironment-RFqReIEq",
                "tenantId": "Tenant-2345",
                "description": "",
                "name": "ONPREM1",
                "discoverUserEmail": "auth0|59bf9dcf7c2eab787c16be6e",
                "location": "ON_PREM",
                "reservedSize": null,
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "interClusterLifs": null,
                "cronJobSchedules": null,
                "svms": null,
                "snapshotPolicies": null,
                "replicationProperties": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "workingEnvironmentType": "ON_PREM",
                "cloudSyncProperties": null,
                "actionsRequired": null,
                "capacityFeatures": null,
                "aff": false,
                "k8sProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            }
        ],
        "azureVsaWorkingEnvironments": [
            {
                "publicId": "VsaWorkingEnvironment-cZJROrvQ",
                "name": "CVOAzureSN",
                "tenantId": "Tenant-2345",
                "svmName": "svm_CVOAzureSN",
                "creatorUserEmail": "auth0|5d481fd2ae65cd0cc3dd4b76",
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "providerProperties": null,
                "reservedSize": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "cloudProviderName": "Azure",
                "snapshotPolicies": null,
                "actionsRequired": null,
                "activeActions": null,
                "replicationProperties": null,
                "schedules": null,
                "svms": null,
                "workingEnvironmentType": "VSA",
                "supportRegistrationProperties": null,
                "supportRegistrationInformation": [],
                "capacityFeatures": null,
                "encryptionProperties": null,
                "supportedFeatures": null,
                "isHA": false,
                "haProperties": null,
                "k8sProperties": null,
                "fpolicyProperties": null,
                "saasProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            },
            {
                "publicId": "VsaWorkingEnvironment-cZJROrvJ",
                "name": "CVOAzureHA",
                "tenantId": "Tenant-2345",
                "svmName": "svm_CVOAzureHA",
                "creatorUserEmail": "auth0|5d481fd2ae65cd0cc3dd4b76",
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "providerProperties": null,
                "reservedSize": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "cloudProviderName": "Azure",
                "snapshotPolicies": null,
                "actionsRequired": null,
                "activeActions": null,
                "replicationProperties": null,
                "schedules": null,
                "svms": null,
                "workingEnvironmentType": "VSA",
                "supportRegistrationProperties": null,
                "supportRegistrationInformation": [],
                "capacityFeatures": null,
                "encryptionProperties": null,
                "supportedFeatures": null,
                "isHA": true,
                "haProperties": null,
                "k8sProperties": null,
                "fpolicyProperties": null,
                "saasProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            },
        ],
        "gcpVsaWorkingEnvironments": [
            {
                "publicId": "vsaworkingenvironment-xed28qml",
                "name": "cvogcpsn",
                "tenantId": "Tenant-2345",
                "svmName": "svm_cvogcpsn",
                "creatorUserEmail": "auth0|5d481fd2ae65cd0cc3dd4b76",
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "providerProperties": null,
                "reservedSize": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "cloudProviderName": "GCP",
                "snapshotPolicies": null,
                "actionsRequired": null,
                "activeActions": null,
                "replicationProperties": null,
                "schedules": null,
                "svms": null,
                "workingEnvironmentType": "VSA",
                "supportRegistrationProperties": null,
                "supportRegistrationInformation": [],
                "capacityFeatures": null,
                "encryptionProperties": null,
                "supportedFeatures": null,
                "isHA": false,
                "haProperties": null,
                "k8sProperties": null,
                "fpolicyProperties": null,
                "saasProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            },
            {
                "publicId": "vsaworkingenvironment-xed28qmo",
                "name": "cvogcpha",
                "tenantId": "Tenant-2345",
                "svmName": "svm_cvogcpha",
                "creatorUserEmail": "auth0|5d481fd2ae65cd0cc3dd4b76",
                "status": {
                    "status": "ON",
                    "message": "",
                    "failureCauses": {
                        "invalidOntapCredentials": false,
                        "noCloudProviderConnection": false,
                        "invalidCloudProviderCredentials": false
                    },
                    "extendedFailureReason": null
                },
                "providerProperties": null,
                "reservedSize": null,
                "clusterProperties": null,
                "ontapClusterProperties": null,
                "cloudProviderName": "GCP",
                "snapshotPolicies": null,
                "actionsRequired": null,
                "activeActions": null,
                "replicationProperties": null,
                "schedules": null,
                "svms": null,
                "workingEnvironmentType": "VSA",
                "supportRegistrationProperties": null,
                "supportRegistrationInformation": [],
                "capacityFeatures": null,
                "encryptionProperties": null,
                "supportedFeatures": null,
                "isHA": true,
                "haProperties": null,
                "k8sProperties": null,
                "fpolicyProperties": null,
                "saasProperties": null,
                "cbsProperties": null,
                "complianceProperties": null,
                "monitoringProperties": null
            },
        ]
    }
}
export const getFSXWorkingEnvironmentsMocked = () => {
    return [{
        "id": "fs-00fb83930c3ebdc7d",
        "name": "fsx_we_1",
        "region": "us-east-2",
        "deploymentType": "MULTI_AZ_HA_1",
        "providerDetails": {
            "status": {"status": "ON", "lifecycle": "AVAILABLE"},
            "awsAccountId": "733004784675",
            "storageCapacity": 1024,
            "throughputCapacity": 512,
            "storageType": "SSD",
            "vpcId": "vpc-f7f6189e",
            "subnetIds": {"primary": "subnet-435db72a", "secondary": "subnet-9b1f10e3"},
            "networkInterfaceIds": ["eni-0f583a830f4c72549", "eni-0d02e0de39d96f152"],
            "encryption": {
                "kmsKeyId": "641ad832-b7c7-453e-9e43-23890f70f896",
                "alias": "aws/fsx",
                "status": "Enabled",
                "origin": "AWS_KMS"
            },
            "arn": "arn:aws:fsx:us-east-2:733004784675:file-system/fs-00fb83930c3ebdc7d",
            "tags": [],
            "endpoints": {
                "intercluster": {
                    "dnsName": "intercluster.fs-00fb83930c3ebdc7d.fsx.us-east-2.aws.internal",
                    "ipAddresses": ["172.31.12.143", "172.31.29.140"]
                },
                "management": {
                    "dnsName": "management.fs-00fb83930c3ebdc7d.fsx.us-east-2.aws.internal",
                    "ipAddresses": ["3.1.229.90"]
                }
            },
            "minimumSsdIops": 0,
            "routeTableIds": ["rtb-1808e671"]
        }
    },
        {
            "id": "fs-00fb83930c3ebdc7e",
            "name": "fsx_we_2",
            "region": "us-east-2",
            "deploymentType": "MULTI_AZ_HA_1",
            "providerDetails": {
                "status": {"status": "ON", "lifecycle": "AVAILABLE"},
                "awsAccountId": "733004784675",
                "storageCapacity": 1024,
                "throughputCapacity": 512,
                "storageType": "SSD",
                "vpcId": "vpc-f7f6189e",
                "subnetIds": {"primary": "subnet-435db72a", "secondary": "subnet-9b1f10e3"},
                "networkInterfaceIds": ["eni-0f583a830f4c72549", "eni-0d02e0de39d96f152"],
                "encryption": {
                    "kmsKeyId": "641ad832-b7c7-453e-9e43-23890f70f896",
                    "alias": "aws/fsx",
                    "status": "Enabled",
                    "origin": "AWS_KMS"
                },
                "arn": "arn:aws:fsx:us-east-2:733004784675:file-system/fs-00fb83930c3ebdc7d",
                "tags": [],
                "endpoints": {
                    "intercluster": {
                        "dnsName": "intercluster.fs-00fb83930c3ebdc7d.fsx.us-east-2.aws.internal",
                        "ipAddresses": ["172.31.12.143", "172.31.29.140"]
                    },
                    "management": {
                        "dnsName": "management.fs-00fb83930c3ebdc7d.fsx.us-east-2.aws.internal",
                        "ipAddresses": ["3.1.229.90"]
                    }
                },
                "minimumSsdIops": 0,
                "routeTableIds": ["rtb-1808e671"]
            }
        }
    ]
}
const mockedVolumes = [
    {
        "name": "Vol1NFS",
        "uuid": "Vol1NFS_uuid",
        "svmName": "svm_CVOAWS",
        "size": {
            "size": 2000.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/Vol1NFS",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": "10.20.1.14:/Vol1NFS",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_CVOAWS-Vol1NFS",
            "policyType": "custom",
            "ips": [
                "10.20.0.0/16"
            ],
            "nfsVersion": [
                "nfs3",
                "nfs4"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 2400.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWS.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "Vol2NFSnfsany",
        "uuid": "Vol1NFS_uuid",
        "svmName": "svm_CVOAWS",
        "size": {
            "size": 2000.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/Vol2NFSnfsany",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": "10.20.1.14:/Vol2NFSnfsany",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_CVOAWS-Vol2NFSnfsany",
            "policyType": "custom",
            "ips": [
                "10.20.0.0/16"
            ],
            "nfsVersion": [
                'any'
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 2400.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWS.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "Vol2NFSnfs3only",
        "uuid": "Vol1NFS_uuid",
        "svmName": "svm_CVOAWS",
        "size": {
            "size": 2000.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/Vol2NFSnfs3only",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": "10.20.1.14:/Vol2NFSnfs3only",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_CVOAWS-Vol2NFSnfs3only",
            "policyType": "custom",
            "ips": [
                "10.20.0.0/16"
            ],
            "nfsVersion": [
                'nfs3'
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 2400.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWS.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "Vol2NFSnfs4only",
        "uuid": "Vol1NFS_uuid",
        "svmName": "svm_CVOAWS",
        "size": {
            "size": 2000.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/Vol2NFSnfs4only",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": "10.20.1.14:/Vol2NFSnfs4only",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_CVOAWS-Vol2NFSnfs4only",
            "policyType": "custom",
            "ips": [
                "10.20.0.0/16"
            ],
            "nfsVersion": [
                'nfs4'
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 2400.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWS.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "vol1cifs",
        "uuid": "vol1cifs_uuid",
        "svmName": "svm_CVOAWSN",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/vol1cifs",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": null,
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "ntfs",
        "exportPolicyInfo": {
            "name": "default",
            "policyType": "none",
            "ips": [],
            "nfsVersion": null
        },
        "shareNames": [
            "vol1cifs_share"
        ],
        "shareInfo": [
            {
                "shareName": "vol1cifs_share",
                "accessControlList": [
                    {
                        "permission": "full_control",
                        "users": [
                            "Everyone"
                        ]
                    }
                ]
            }
        ],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 12.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": "\\\\10.20.1.14\\vol1cifs_share",
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWD.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "mixedVolume",
        "uuid": "mixedVolume_uuid",
        "svmName": "svm_CVOAWSN",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/mixedVolume",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": "10.20.1.14:/mixedVolumeMount",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "ntfs",
        "shareNames": [
            "mixedVolume_share"
        ],
        "shareInfo": [
            {
                "shareName": "mixedVolume_share",
                "accessControlList": [
                    {
                        "permission": "full_control",
                        "users": [
                            "Everyone"
                        ]
                    }
                ]
            }
        ],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 12.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": "\\\\10.20.1.14\\\\mixedVolume_share",
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWD.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "volNoShareNoMountPoint",
        "uuid": "volNoShareNoMountPoint_uuid",
        "svmName": "svm_CVOAWSN",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/volNoShareNoMountPoint",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": null,
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "ntfs",
        "exportPolicyInfo": {
            "name": "default",
            "policyType": "none",
            "ips": [],
            "nfsVersion": null
        },
        "shareNames": [
            "volNoShareNoMountPoint_share"
        ],
        "shareInfo": [
            {
                "shareName": "volNoShareNoMountPoint_share",
                "accessControlList": [
                    {
                        "permission": "full_control",
                        "users": [
                            "Everyone"
                        ]
                    }
                ]
            }
        ],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 12.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWD.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "vol3MountedToVol4",
        "uuid": "b880ca37-d255-11eb-b65d-d72faff15b79",
        "svmName": "svm_chenWE",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 2.9754638671875E-4,
            "unit": "GB"
        },
        "junctionPath": "/vol3/vol4",
        "volumeTotalInodes": 311287,
        "volumeUsedInodes": 96,
        "mountPoint": "10.20.30.207:/vol3/vol4",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": false,
        "compression": false,
        "deduplication": false,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_chenWE-vol3vol4",
            "policyType": "custom",
            "ips": [
                "10.20.30.0/24"
            ],
            "nfsVersion": [
                "nfs3",
                "nfs4"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {
            "size": 12.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "none",
        "tierInactiveUserData": {
            "size": 0.0,
            "unit": "GB"
        },
        "tierInactiveUserDataPercent": 0,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 151552.0,
            "unit": "Byte"
        },
        "cbsBackupsInfo": {
            "totalBackups": 0
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.1992-08.com.netapp:sn.d50ae491d19b11eb94d0bdcc4ac6527f:vs.2",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "Vol1NFSWithExportPolicyNoVersion",
        "uuid": "Vol1NFS_uuid",
        "svmName": "svm_CVOAWS",
        "size": {
            "size": 2000.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "junctionPath": "/Vol1NFS",
        "volumeTotalInodes": 21000,
        "volumeUsedInodes": 100,
        "mountPoint": "10.20.1.14:/Vol1NFS",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": true,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_CVOAWS-Vol1NFS",
            "policyType": "custom",
            "ips": [
                "10.20.0.0/16"
            ],
            "nfsVersion": null
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 2400.0,
            "unit": "GB"
        },
        "providerVolumeType": "gp2",
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": null,
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": null,
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": {
            "totalBackups": 1
        },
        "minimumCoolingDays": null,
        "targetName": "iqn.svm_CVOAWS.simulatoriqn",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    }

];
const fsxMockedVolumes = [
    {
        "name": "vol1",
        "uuid": "5f70ce65-f39e-11eb-9ee7-49fcbacc23e6",
        "svmName": "svm_default",
        "size": {"size": 1.0, "unit": "GB"},
        "usedSize": {"size": 0.002574920654296875, "unit": "GB"},
        "junctionPath": "",
        "volumeTotalInodes": 31122,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.10:/vol1",
        "compressionSpaceSaved": {"size": 0.0, "unit": "GB"},
        "deduplicationSpaceSaved": {"size": 0.0, "unit": "GB"},
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "default", "policyType": "none", "ips": [], "nfsVersion": [
                "nfs3",
                "nfs4"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {"size": 1.1999969482421875, "unit": "GB"},
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {"size": 0.0, "unit": "GB"},
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {"size": 184320.0, "unit": "Byte"},
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "volNfs3Version",
        "uuid": "5f60ce65-f39e-11eb-9ee7-49fcbacc23e6",
        "svmName": "svm_default",
        "size": {"size": 1.0, "unit": "GB"},
        "usedSize": {"size": 0.002574920654296875, "unit": "GB"},
        "junctionPath": "",
        "volumeTotalInodes": 31122,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.10:/vol2",
        "compressionSpaceSaved": {"size": 0.0, "unit": "GB"},
        "deduplicationSpaceSaved": {"size": 0.0, "unit": "GB"},
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {"name": "default", "policyType": "none", "ips": [],"nfsVersion": [
                "nfs3"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {"size": 1.1999969482421875, "unit": "GB"},
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {"size": 0.0, "unit": "GB"},
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {"size": 184320.0, "unit": "Byte"},
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "volNfsversion",
        "uuid": "5f50ce65-f39e-11eb-9ee7-49fcbacc23e6",
        "svmName": "svm_default",
        "size": {"size": 1.0, "unit": "GB"},
        "usedSize": {"size": 0.002574920654296875, "unit": "GB"},
        "junctionPath": "",
        "volumeTotalInodes": 31122,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.10:/vol3",
        "compressionSpaceSaved": {"size": 0.0, "unit": "GB"},
        "deduplicationSpaceSaved": {"size": 0.0, "unit": "GB"},
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {"name": "default", "policyType": "none", "ips": [],"nfsVersion": [
                "nfs"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {"size": 1.1999969482421875, "unit": "GB"},
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {"size": 0.0, "unit": "GB"},
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {"size": 184320.0, "unit": "Byte"},
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "volNFS4",
        "uuid": "5f40ce65-f39e-11eb-9ee7-49fcbacc23e6",
        "svmName": "svm_default",
        "size": {"size": 1.0, "unit": "GB"},
        "usedSize": {"size": 0.002574920654296875, "unit": "GB"},
        "junctionPath": "",
        "volumeTotalInodes": 31122,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.10:/vol4",
        "compressionSpaceSaved": {"size": 0.0, "unit": "GB"},
        "deduplicationSpaceSaved": {"size": 0.0, "unit": "GB"},
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {"name": "default", "policyType": "none", "ips": [],"nfsVersion": [
                "nfs4"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {"size": 1.1999969482421875, "unit": "GB"},
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {"size": 0.0, "unit": "GB"},
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {"size": 184320.0, "unit": "Byte"},
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "voCifs",
        "uuid": "3cf3d493-fa24-11eb-86c9-dfa8fafcfe28",
        "svmName": "svm_default",
        "size": {
            "size": 1,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.011852264404296875,
            "unit": "GB"
        },
        "junctionPath": "/voCifs",
        "volumeTotalInodes": 31122,
        "volumeUsedInodes": 97,
        "mountPoint": null,
        "compressionSpaceSaved": {
            "size": 0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "ntfs",
        "exportPolicyInfo": {
            "name": "default",
            "policyType": "none",
            "ips": [],
            "nfsVersion": null
        },
        "shareNames": [
            "voCifs_share"
        ],
        "shareInfo": [
            {
                "shareName": "voCifs_share",
                "accessControlList": [
                    {
                        "permission": "full_control",
                        "users": [
                            "Everyone"
                        ]
                    }
                ]
            }
        ],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 11,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {
            "size": 0,
            "unit": "GB"
        },
        "cifsShareAccessPoint": "\\\\1.1.0.22\\voCifs_share",
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 1888256,
            "unit": "Byte"
        },
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "offlineVol",
        "uuid": "022b1e0a-f5de-11eb-86c9-dfa8fafcfe28",
        "svmName": "svm_default",
        "size": {
            "size": 8.023880004882812,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0,
            "unit": "GB"
        },
        "junctionPath": "",
        "volumeTotalInodes": null,
        "volumeUsedInodes": null,
        "mountPoint": "1.1.0.22:",
        "compressionSpaceSaved": {
            "size": 0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": false,
        "snapshotPolicy": "default",
        "securityStyle": "",
        "exportPolicyInfo": {
            "name": "export-svm_default-offlineVol",
            "policyType": "custom",
            "ips": [
                "0.0.0.0/0"
            ],
            "nfsVersion": [
                "nfs3"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "offline",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "",
        "maxGrowSize": {
            "size": 0,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": null,
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "auto",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": null,
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "anyVol",
        "uuid": "b0c029e1-00cd-11ec-ad1b-b3954597a9c6",
        "svmName": "svm_default",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.10524749755859375,
            "unit": "GB"
        },
        "junctionPath": "/anyVol2",
        "volumeTotalInodes": 311287,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.159:/anyVol2",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "any",
            "policyType": "custom",
            "ips": [
                "0.0.0.0/0"
            ],
            "nfsVersion": [
                "any"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {
            "size": 12.0,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 4980736.0,
            "unit": "Byte"
        },
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "cifsvol2DifferentShare",
        "uuid": "7ebe4c0f-f5f3-11eb-86c9-dfa8fafcfe28",
        "svmName": "svm_default",
        "size": {
            "size": 1,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.012531280517578125,
            "unit": "GB"
        },
        "junctionPath": "/cifsvol2",
        "volumeTotalInodes": 31122,
        "volumeUsedInodes": 97,
        "mountPoint": null,
        "compressionSpaceSaved": {
            "size": 0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "ntfs",
        "exportPolicyInfo": {
            "name": "cifs",
            "policyType": "custom",
            "ips": [
                "0.0.0.0/0"
            ],
            "nfsVersion": [
                "cifs"
            ]
        },
        "shareNames": [
            "cifsvolshare"
        ],
        "shareInfo": [
            {
                "shareName": "cifsvolshare",
                "accessControlList": [
                    {
                        "permission": "full_control",
                        "users": [
                            "Everyone"
                        ]
                    }
                ]
            }
        ],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {
            "size": 1.1999969482421875,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {
            "size": 0,
            "unit": "GB"
        },
        "cifsShareAccessPoint": "\\\\1.1.0.22\\cifsvolshare",
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 2908160,
            "unit": "Byte"
        },
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "volBothNfs3andNfs4",
        "uuid": "57983394-fd8c-11eb-ad1b-b3954597a9c6",
        "svmName": "svm_default",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.090545654296875,
            "unit": "GB"
        },
        "junctionPath": "/vol4",
        "volumeTotalInodes": 311287,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.159:/vol4",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_default-vol4",
            "policyType": "custom",
            "ips": [
                "0.0.0.0/0"
            ],
            "nfsVersion": [
                "nfs4", "nfs3"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 110.0,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 4972544.0,
            "unit": "Byte"
        },
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "volmix",
        "uuid": "35ee1acb-00c6-11ec-ad1b-b3954597a9c6",
        "svmName": "svm_default",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.09053421020507812,
            "unit": "GB"
        },
        "junctionPath": "/volmix",
        "volumeTotalInodes": 311287,
        "volumeUsedInodes": 96,
        "mountPoint": "1.1.0.159:/volmix",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_default-vol4",
            "policyType": "custom",
            "ips": [
                "0.0.0.0/0"
            ],
            "nfsVersion": [
                "nfs4"
            ]
        },
        "shareNames": [
            "mixedVol"
        ],
        "shareInfo": [
            {
                "shareName": "mixedVol",
                "accessControlList": [
                    {
                        "permission": "full_control",
                        "users": [
                            "Everyone"
                        ]
                    }
                ]
            }
        ],
        "parentVolumeName": "",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": null,
        "autoSizeMode": "off",
        "maxGrowSize": {
            "size": 12.0,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "cifsShareAccessPoint": "\\\\1.1.0.159\\mixedVol",
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 5468160.0,
            "unit": "Byte"
        },
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {
        "name": "vol1_cloned",
        "uuid": "a788d0c2-ff39-11eb-ad1b-b3954597a9c6",
        "svmName": "svm_default",
        "size": {
            "size": 10.0,
            "unit": "GB"
        },
        "usedSize": {
            "size": 0.09078216552734375,
            "unit": "GB"
        },
        "junctionPath": "/vol1_cloned",
        "volumeTotalInodes": 311287,
        "volumeUsedInodes": 123,
        "mountPoint": "1.1.0.159:/vol1_cloned",
        "compressionSpaceSaved": {
            "size": 0.0,
            "unit": "GB"
        },
        "deduplicationSpaceSaved": {
            "size": 0.27538299560546875,
            "unit": "GB"
        },
        "thinProvisioning": true,
        "compression": false,
        "deduplication": true,
        "snapshotPolicy": "default",
        "securityStyle": "unix",
        "exportPolicyInfo": {
            "name": "export-svm_default-vol1_cloned",
            "policyType": "custom",
            "ips": [
                "0.0.0.0/0"
            ],
            "nfsVersion": [
                "any"
            ]
        },
        "shareNames": [],
        "shareInfo": [],
        "parentVolumeName": "vol1",
        "rootVolume": false,
        "state": "online",
        "volumeType": "rw",
        "aggregateName": "aggr1",
        "parentSnapshot": "clone_vol1_cloned.2021-08-17_090104.0",
        "autoSizeMode": "grow",
        "maxGrowSize": {
            "size": 110.0,
            "unit": "GB"
        },
        "providerVolumeType": null,
        "cloneNames": [],
        "moving": false,
        "primaryNoFailoverMountPoint": null,
        "secondaryNoFailoverMountPoint": null,
        "capacityTier": "S3",
        "capacityTierUsedSize": {
            "size": 0.0,
            "unit": "GB"
        },
        "cifsShareAccessPoint": null,
        "primaryCifsShareAccessPoint": null,
        "secondaryCifsShareAccessPoint": null,
        "tieringPolicy": "snapshot_only",
        "tierInactiveUserData": null,
        "tierInactiveUserDataPercent": null,
        "comment": null,
        "qosPolicyGroupName": null,
        "snaplockType": "non_snaplock",
        "constituentsAggregates": [],
        "snapshotsUsedSize": {
            "size": 7532544.0,
            "unit": "Byte"
        },
        "cbsBackupsInfo": null,
        "minimumCoolingDays": null,
        "targetName": "",
        "iscsiEnabled": false,
        "isFlexGroupVolume": false
    },
    {"name":"awsVol","uuid":"b37d6673-0348-11ed-8aca-43498218f86f","svmName":"svm_fsx01","size":{"size":0.01953125,"unit":"GB"},"usedSize":{"size":5.30242919921875E-4,"unit":"GB"},"junctionPath":"/vol2","volumeTotalInodes":566,"volumeUsedInodes":103,"mountPoint":"10.20.30.61:/vol2","compressionSpaceSaved":{"size":0.0,"unit":"GB"},"deduplicationSpaceSaved":{"size":0.0,"unit":"GB"},"thinProvisioning":true,"compression":false,"deduplication":false,"snapshotPolicy":"default","securityStyle":"unix","exportPolicyInfo":{"name":"default","policyType":"none","ips":[],"nfsVersion":null,"rules":null},"shareNames":[],"shareInfo":[],"parentVolumeName":"","rootVolume":false,"state":"online","volumeType":"rw","aggregateName":"aggr1","parentSnapshot":null,"autoSizeMode":"off","maxGrowSize":{"size":0.0234375,"unit":"GB"},"providerVolumeType":null,"cloneNames":[],"moving":false,"primaryNoFailoverMountPoint":null,"secondaryNoFailoverMountPoint":null,"capacityTier":"S3","capacityTierUsedSize":{"size":0.0,"unit":"GB"},"cifsShareAccessPoint":null,"primaryCifsShareAccessPoint":null,"secondaryCifsShareAccessPoint":null,"tieringPolicy":"auto","tierInactiveUserData":null,"tierInactiveUserDataPercent":null,"comment":null,"qosPolicyGroupName":null,"snaplockType":"non_snaplock","constituentsAggregates":[],"snapshotsUsedSize":{"size":147456.0,"unit":"Byte"},"cbsBackupsInfo":null,"minimumCoolingDays":null,"targetName":"iqn.1992-08.com.netapp:sn.f86db517033f11ed8aca43498218f86f:vs.3","iscsiEnabled":false,"isFlexGroupVolume":false}
]
export const getCMVolumesMocked = () => {
    return mockedVolumes
}
export const getFSXVolumesMocked = () => {
    return fsxMockedVolumes;
}
