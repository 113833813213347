import axios from 'axios';
import {store} from '../store/store';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CM_API_BASE_URL + "/cloud-volumes/cvs"
});

const isSimulator = process.env.REACT_APP_SIMULATE_API === "true";

const extractData = (response) => {
    return response.data;
};

const getHeaders = () => {
    const accessToken = store.getState().global.auth.accessToken;
    return isSimulator
    ? { headers: { Authorization: accessToken, "X-Simulator": "true", referer:'cs' } }
    : { headers: { Authorization: accessToken, referer:'cs' } };
};

const service = {
    getWorkingEnvironments: (accountId, workspaceId) => {
        return axiosInstance.get(workspaceId ? `/accounts/${accountId}/working-environments?workspace=${workspaceId}` : `/accounts/${accountId}/working-environments`, getHeaders()).then(extractData);
    },
    getAnfVolumes: (accountId, credentialsId) => {
        return axiosInstance.get(`/azure/accounts/${accountId}/credentials/${credentialsId}/volumes`, getHeaders()).then((response) => {
            const data = extractData(response)
            if (isSimulator) { //for test purposes add a dual protocol volume
                data.push(  {
                        "mountTargets": [
                            {
                                "provisioningState": "",
                                "mountTargetId": "67a3cfca-7d36-d962-bcaf-ad440d587094",
                                "fileSystemId": "67a3cfca-7d36-d962-bcaf-ad440d587094",
                                "startIp": "",
                                "endIp": "",
                                "gateway": "",
                                "netmask": "",
                                "subnet": "",
                                "ipAddress": "10.84.88.5",
                                "smbServerFQDN": "AADANFUSU3-3f0e.bp1.ad.bp.com"
                            }
                        ],
                        "throughputMibps": 631.552,
                        "dataProtection": {
                            "snapshot": {}
                        },
                        "provisioningState": "Succeeded",
                        "fileSystemId": "67a3cfca-7d36-d962-bcaf-ad440d587094",
                        "serviceLevel": "Standard",
                        "creationToken": "zscbzgevol02",
                        "usageThreshold": 42382737276928,
                        "exportPolicy": {
                            "rules": [
                                {
                                    "ruleIndex": 1,
                                    "unixReadOnly": false,
                                    "unixReadWrite": true,
                                    "cifs": false,
                                    "nfsv3": true,
                                    "nfsv41": false,
                                    "allowedClients": "0.0.0.0/0",
                                    "kerberos5ReadOnly": false,
                                    "kerberos5ReadWrite": false,
                                    "kerberos5iReadOnly": false,
                                    "kerberos5iReadWrite": false,
                                    "kerberos5pReadOnly": false,
                                    "kerberos5pReadWrite": false,
                                    "hasRootAccess": true
                                }
                            ]
                        },
                        "protocolTypes": [
                            "CIFS",
                            "NFSv3"
                        ],
                        "baremetalTenantId": "baremetalTenant_svm_792bebc69f2311e9b8c056c2c0befafd_9babfd3a",
                        "subnetId": "/subscriptions/e29fc282-f738-44d5-b3dc-13b17a6a27ca/resourceGroups/zsc-usu3-p-02-net-rsg/providers/Microsoft.Network/virtualNetworks/zsc-usu3-p-02-net-stratp-00-net/subnets/zsc-usu3-p-02-net-stratp-00-ANF-sub",
                        "snapshotDirectoryVisible": true,
                        "kerberosEnabled": false,
                        "securityStyle": "Ntfs",
                        "id": "/subscriptions/e29fc282-f738-44d5-b3dc-13b17a6a27ca/resourceGroups/ZSC-USU3-P-20-SBZ-RSG/providers/Microsoft.NetApp/netAppAccounts/ZSC-USU3-P-20-SBZ-ANFAccount/capacityPools/zscbzpcp01std/volumes/zscbzgevol02",
                        "labels": {
                            "MinFreeSpaceGb": "512",
                            "MaxFreeSpaceGb": "1024"
                        },
                        "region": "southcentralus",
                        "location": "southcentralus",
                        "lifeCycleState": "Succeeded",
                        "name": "zscbzgevol02",
                        "quotaInBytes": 42382737276928,
                        "usedBytes": 0,
                        "protocol": "dual",
                        "smbServerFQDN": "AADANFUSU3-3f0e.bp1.ad.bp.com",
                        "workingEnvironmentType": "ANF",
                        "provider": "azure",
                        "subscriptionId": "e29fc282-f738-44d5-b3dc-13b17a6a27ca",
                        "resourceGroupName": "ZSC-USU3-P-20-SBZ-RSG",
                        "netAppAccountName": "ZSC-USU3-P-20-SBZ-ANFAccount",
                        "poolName": "zscbzpcp01std",
                        "activeDirectoryDnsIps": [
                            null
                        ]
                    },
                )
            }
            return data;
        });
    },
};

export default service;
