import React, { useState } from 'react';
import { connect } from "react-redux";
import { createStringFromEndPointObject } from "./../../utils/helpers";
import { deleteRelationship } from "../../store/wizard/action_creators";
import ConfirmDialog from "../dialog/ConfirmDialog";
import SearchableTableContainer from "./../table/SearchableSelectableTable";
import { getSVGByProtocol, getFixedProtocol } from '../../utils/dashboard-utils';
import { HEADER_TYPES } from '../table/SelectableTable';
require('./deleteRelationshipDialog.scss')

const DeleteRelationshipDialog = ({ data, deleteRelationship }) => {
    const [sortedRelationships, setSortedRelationships] = useState(data);
    const [rowsForDelete, setRowsForDelete] = useState({});

    const containerOptions = {
        singularTitle: "Relationship",
        pluralTitle: "Relationships"
    };

    const singleReportDelete = relationship => {
        return (
            <>
                <p>Are you sure you want to delete the Sync Relationship
                    from {createStringFromEndPointObject(relationship.source)} to {createStringFromEndPointObject(relationship.target)}?</p>
                <p>All the data in the target will remain.</p>
            </>
        )
    }

    const applySort = (column, ascending) => {
        const sortIndexMap = {
            'name': 'name',
            'source': 'sourcePath',
            'target': 'targetPath'
        }
        
        const sortedList = Array.from([...sortedRelationships].sort((rel1, rel2) => {
            return rel2[sortIndexMap[column]].localeCompare(rel1[sortIndexMap[column]]);
        }));

        setSortedRelationships(ascending ? sortedList : sortedList.reverse());
    };

    const getTableHeader = (applySort) => {
        return [
            {
                type: HEADER_TYPES.CHECKBOX,
                checked: false
            },
            {
                name: 'name',
                width: "20%",
                className: 'relationshipName',
                text: 'Name',
                sort: applySort
            },
            {
                name: 'source',
                width: "35%",
                className: 'relationshipSource',
                text: 'Source',
                sort: applySort
            },
            {
                name: 'target',
                width: "35%",
                className: 'relationshipTarget',
                text: 'Target',
                sort: applySort
            }
        ];
    };

    const renderRow = (row, setDialog , moreActions) => {
        const { source, target } = row;
        const isSecureRelationship = !!row.encryption;
        const sourceSVG = getSVGByProtocol(getFixedProtocol(source), isSecureRelationship);
        const targetSVG = getSVGByProtocol(getFixedProtocol(target), isSecureRelationship);

        return (
            <>
                <td title={row.name}>{row.name}</td>
                <td><div title={row.sourcePath} className='relationshipCell'>{sourceSVG}<span className='cellText'>{row.sourcePath}</span></div></td>
                <td><div title={row.targetPath} className='relationshipCell'>{targetSVG}<span className='cellText'>{row.targetPath}</span></div></td>
            </>
        )
    };

    const multiReportDelete = () => {
        return (
            <div className="multiRelationsDelete">
                <p>Please select the relationships to delete.</p>
                <p>All the data in the target will remain.</p>
                <SearchableTableContainer
                    containerOptions={containerOptions}
                    Table={{ renderRow, getTableHeader: () => getTableHeader(applySort) }}
                    data={sortedRelationships}
                    isMenuDisabled={false}
                    onSelect={rows => setRowsForDelete({...rows})}
                />
            </div>
        )
    }

    const relationshipIdsForDelete = () => {
        return data.length === 1 ? [data[0].id] : Object.keys(rowsForDelete);
    }

    return <ConfirmDialog
        title="Delete Sync Relationship"
        confirmText="Delete"
        onConfirm={() => deleteRelationship(relationshipIdsForDelete())}
        extractError={(error) => error?.data?.message}
        className="delete-relationship-dialog"
        submitDisabled={data && data.length > 1 && Object.keys(rowsForDelete).length === 0}
    >
        {data.length === 1 ? singleReportDelete(data[0]) : multiReportDelete()}

    </ConfirmDialog>
};

export default connect(null, { deleteRelationship })(DeleteRelationshipDialog);
