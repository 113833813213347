import React, {useState, useRef} from 'react';
import {clearDoubleSlash} from "../../../utils/encoders"
import DrillDownBreadCrumbs from "./../../table/DrillDownBreadCrumbs";
import {NEW_TARGET_FOLDER_ID} from "../../../consts";
import _ from "lodash";
import classNames from "classnames";
import TargetSelectionNotice from "./TargetSelectionNoticeNew"
import {useDialog} from "@netapp/shared-components";
import {scrollToTop} from "../../../utils/wizard-utils";
import {ReactComponent as PlusIcon} from "./../../../assets/svgs/plus-fill-circle-icon.svg";
import {ReactComponent as FolderIcon} from "./../../../assets/svgs/folder-icon.svg";
import WizardStepHeader from "./WizardStepHeaderNew";
import RefreshButton from "../../refreshButton/RefreshButton";
import ButtonsPanel from "../../widgets/ButtonsPanel";
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import { useDispatch } from 'react-redux';
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';
import { useEffect } from 'react';

require("./showDirectoriesStepTemplateNew.scss");

const FOLDER_REGEX = /^[./]/;

const ShowDirectoriesStep = (props) => {
    const {stepOptions, isTargetStepFromSameSourceType, _wizard, routing, history, up, handleSubmit, continueToNextStep, updateSelectedParams, isSourceStep} = props;
    const {selectedDirectory, selectedRootDirectory, selectedDirectoryTarget, selectedRootDirectoryTarget, getSelectedParams, pathMask} = stepOptions;
    const initialPath = isTargetStepFromSameSourceType ? selectedDirectoryTarget : selectedDirectory;
    const initialRootDirectory = isTargetStepFromSameSourceType ? selectedRootDirectoryTarget : selectedRootDirectory;
    const {setDialog} = useDialog();
    const dispatch = useDispatch();
    const errorRef = useRef();

    const [localState, setLocalState] = useState({
        selectedId: initialPath || null,
        error: null,
        initialPath: initialPath || null,
        rootDirectory: initialRootDirectory || null,
        rootValue: null
    });

    const scrollArea = useRef();

    const setSelected = (selected) => {
        setLocalState({...localState, selectedId: selected.value, rootValue: selected.rootValue, error: null});
    };

    const submit = (values) => {
        return Promise.resolve(true)
            .then(() => {
                onContinue(values);
            });
    };

    const goToNextStep = (fullPath, rootValue) => {
        setLocalState({...localState, error: null});
        updateSelectedParams(getSelectedParams(clearDoubleSlash(fullPath), clearDoubleSlash(rootValue ? rootValue : fullPath)));
        continueToNextStep();
    };

    const onContinue = (values) => {
        let error;
        if (!localState.selectedId) {
            error = "In order to continue to the next step please select a directory.";
        }
        // user chose a bucket
        else {
            // chose new target folder - validate
            if (localState.selectedId.endsWith(NEW_TARGET_FOLDER_ID)) {
                const {targetFolder} = values;
                // validate folder name exists
                if (!targetFolder || !_.isString(targetFolder)) {
                    error = "Please enter a folder name.";
                }
                // block chars that are illegal in linux
                else if (FOLDER_REGEX.test(targetFolder)) {
                    error = "Target folder name shouldn't start with . or /";
                } else if (targetFolder.length > 255) {
                    error = "Target folder name's maximum length should be 255 characters";
                }

                // folder name exists
                if (!error) {
                    //use replace with the function as parameter and not string otheriwse $ will be used as escaped character causing unexpected target folder names...
                    //see SER-1678 Create target folder on NFS - problem with dollar sign
                    const targetFolderFullPath = localState.selectedId.replace(NEW_TARGET_FOLDER_ID, () => targetFolder);
                    goToNextStep(targetFolderFullPath, localState.rootValue);
                    return;
                }
            }
            // chose existing folder - no validations
            else {
                goToNextStep(localState.selectedId, localState.rootValue);
            }
        }

        // update errors only in case there are errors, otherwise we get errors in the console for trying to set state of unmounted component
        if (error) {
            setLocalState({...localState, error});
            setTimeout(() => {
                addNotification({
                    id: NOTIFICATION_CONSTS.UNIQUE_IDS.SHOW_DIRECTORIES_STEP_NOTIFICATION ,
                    type: NOTIFICATION_CONSTS.TYPE.ERROR,
                    children: errorRef.current
                })(dispatch)
            }, 0);
        }
    };

    const drillDown = (path, firstLayerPath) => {
        const {storage, storageKey, listSubDirectoriesAction, selectedServer, selectedServerTarget, version, additionalParam, getListSubDirectoriesActionParams} = stepOptions;
        const {isSecure, selectedWizardParams: {selectedDataBroker, selectedDataBrokerTarget, selectedEfsAccessPoint, selectedEfsAccessPointTarget}} = _wizard;
        const isUsingDataBrokerTarget = isSecure && isTargetStepFromSameSourceType;
        const relevantDataBroker = isUsingDataBrokerTarget ? selectedDataBrokerTarget : selectedDataBroker;
        const actualServer = isTargetStepFromSameSourceType ? selectedServerTarget : selectedServer;
        const relevantEfsAccessPoint = isTargetStepFromSameSourceType ? selectedEfsAccessPointTarget : selectedEfsAccessPoint;
        const folders = storage[storageKey].folders;
        if (folders[path] && (folders[path].succeeded || folders[path].inProgress)) return;
        listSubDirectoriesAction(getListSubDirectoriesActionParams ? getListSubDirectoriesActionParams(path, firstLayerPath) : {
            fileServer: actualServer,
            path,
            firstLayerPath,
            version,
            isTargetStepFromSameSourceType,
            accessPoint: relevantEfsAccessPoint,
            groupId: relevantDataBroker.id,
            ...additionalParam
        })
    };

    const isRefreshDisabled = () => {
        const {selectedServer, selectedServerTarget} = stepOptions;
        return (selectedServer === selectedServerTarget && !isSourceStep);
    };

    const {failedMessage, notFoundMessage, serverTypeName, storage, storageKey, containerOptions, title,
        selectedServer, selectedServerTarget, innerColumns, stepFormName, addManualOptions, refreshStep, isStaticRoot} = stepOptions;
    const {manualForm: ManualForm, directoryType, addManualAction, addManualText} = addManualOptions ? addManualOptions : {manualForm: {}};
    const actualStorage = storage[storageKey];
    const {data, folders, retrievedAll, failedRetrieve} = actualStorage;
    errorRef.current = actualStorage.failed ? actualStorage.data : localState.error;

    useEffect(() => {
        if (localState.selectedId) {
            clearNotifications()(dispatch);
        }
    }, [dispatch, localState.selectedId])

    const addManualDirectory = (values) => {
        addManualAction(values);
        setLocalState((prevState) => ({...prevState, selectedId: values.directoryName, rootValue: values.directoryName, error: null}));
    };

    return (
        <div className="wizard-step show-export-step">
            <form className="wizard-step-form" onSubmit={handleSubmit(submit)}>
                <div className={classNames("scrollable-area", {up, down: !up})} ref={scrollArea}>
                    <WizardStepHeader
                        _wizard={_wizard}
                        routing={routing}
                        history={history}
                        title={title}
                        rightAction={addManualAction
                            ? <button type="button" className="outline new-export"
                                                               onClick={(el) => setDialog(<ManualForm directoryType={directoryType}
                                                                                                       onSubmitFunction={addManualDirectory}/>, el.target)}>
                                <PlusIcon/>{addManualText}
                            </button>
                            : null}
                    />

                    {(data && data.length > 0) &&
                    <div>
                        {!isSourceStep &&
                        <div className="header-text">Drill down in order to create a new folder inside an export or
                            inside an existing folder</div>}

                        {!isSourceStep && <TargetSelectionNotice selected={localState.selectedId} directory={true}/>}
                        <DrillDownBreadCrumbs containerOptions={containerOptions}
                                              isStaticRoot={isStaticRoot}
                                              drillDown={drillDown} data={folders || []}
                                              selectionOptions={{
                                                  selector: setSelected,
                                                  selectedId: localState.selectedId,
                                                  pathPrefix: '/'
                                              }}
                                              refreshButton={refreshStep ? <RefreshButton onClick={refreshStep} storageObjectName={containerOptions.pluralTitle} disabled={isRefreshDisabled()}/> : undefined}
                                              scrollToTop={() => scrollToTop(scrollArea.current)}
                                              innerColumns={innerColumns}
                                              canCreateTargetFolder={!isSourceStep || isTargetStepFromSameSourceType}
                                              resetForm={props.reset}
                                              initialPath={localState.initialPath}
                                              rootExport={localState.rootDirectory} formName={stepFormName}
                                              beginWithSlash={true} {...props}
                                              pathMask={pathMask} />
                    </div>}

                    {(retrievedAll && ((data && data.length === 0) || !data)) &&
                    <div className="nothing-found">
                        <span><FolderIcon/></span>
                        <p>{failedRetrieve ? `${failedMessage}` : `${notFoundMessage}`}</p>
                        <p>in {serverTypeName} server {isTargetStepFromSameSourceType ? selectedServerTarget : selectedServer}</p>
                    </div>}

                </div>
                <ButtonsPanel
                    variant="white"
                    type="submit"
                    text="Continue"/>
            </form>
        </div>
    );
};

export default ShowDirectoriesStep;
