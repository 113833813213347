import React from 'react'
import {Popover} from '@netapp/shared-components';
import {ReactComponent as InfoIcon} from "../../assets/svgs/info.svg";

import "./RelationshipRecommendations.scss";

// variant: 'tableView' | 'cardView'
const RelationshipRecommendations = ({recommendations, variant}) => {
    return <div className={`recommendations-message ${variant === 'tableView' ? 'reverse' : ''}`}>
        <Popover popoverClass="recommendations-tooltip"
                      placement="top"
                      trigger="click"
                      container={<span className="recommendation-trigger">
                         <InfoIcon className="i-icon"/>
                      </span>}>
            <ul className="recommendations-list">
                {recommendations.map(({id, text}) => <li key={id}>{text}</li>)}
            </ul>
        </Popover>
        <span className="recommendations-text">
            {`${variant === 'cardView' ? 'You have ' : ''} ${recommendations.length} recommendation${recommendations.length > 1 ? "s" : ""}`}
        </span>
    </div>

};

export default RelationshipRecommendations;
