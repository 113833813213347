import React from 'react';
import Isvg from 'react-inlinesvg';
import ConfirmDialog from '../../dialog/ConfirmDialog';
import {SERVICE_NAME} from '../../../consts';
import {ReactComponent as CloudSyncLogo} from "../../../assets/svgs/cloud-sync-logo.svg";
import {ReactComponent as TriangleIcon} from "../../../assets/svgs/triangle-circle-icon.svg";

require("./subscriptionFlowDialog.scss");

const SubscriptionFlowDialog = ({providerInstructions, backToCloudSyncInstructions, videoLink, provider, iconFileName, providerLink}) => {

    const goToMarketplace = () => {
        window.open(providerLink); //always open in new tab after standalone retirement
    };
    return (
        <ConfirmDialog
            title={`${provider} Subscription Steps`}
            onConfirm={goToMarketplace}
            confirmText="Continue"
            className="subscription-flow-dialog"
        >

        <div className="step row medium">
            <div className="medium-2 step-icon">
                <span><CloudSyncLogo/></span>
            </div>
            <div className="medium-10">
                <h6>{SERVICE_NAME}</h6>
                <p>Clicking Continue redirects you to the {provider === 'AWS' ? 'AWS Marketplace' : 'Azure Portal'}.</p>
            </div>
        </div>

        <div className="row medium">
            <div className="medium-2">
                <div className="step-divider"/>
            </div>
        </div>

        <div className="step row medium">
            <div className="medium-2 step-icon">
                <span><Isvg src={require(`../../../assets/svgs/${iconFileName}.svg`)}/></span>
            </div>
            <div className="medium-10">
                <h6>{provider === 'AWS' ? 'AWS Marketplace' : 'Azure Portal'}</h6>
                <p>{providerInstructions}</p>
            </div>
        </div>

        <div className="row medium">
            <div className="medium-2">
                <div className="step-divider"/>
            </div>
        </div>

        <div className="step row medium">
            <div className="medium-2 step-icon">
                <span><CloudSyncLogo/></span>
            </div>
            <div className="medium-10 step-text">
                <h6>{SERVICE_NAME}</h6>
                <p>{backToCloudSyncInstructions}</p>
            </div>
        </div>

       {videoLink &&
        <div className="view-video-container">
            <a href={videoLink} target="_blank" rel="noopener noreferrer"  className="view-video"><span><TriangleIcon/></span>View Video Instruction</a>
        </div>}

    </ConfirmDialog>)
};

export default SubscriptionFlowDialog;
