import React from 'react';
import {connect} from "react-redux";
import {createStringFromEndPointObject} from "./../../utils/helpers";
import {abortSync} from '../../store/wizard/action_creators';
import ConfirmDialog from '../dialog/ConfirmDialog';

const AbortSyncConfirmDialog = ({abortSync, data}) => (
    <ConfirmDialog
        title="Abort Sync"
        onConfirm={() => abortSync(data.id)}
        confirmText="Abort"
    >
        <p>Are you sure you want to abort the current sync process from : {createStringFromEndPointObject(data.source)} to: {createStringFromEndPointObject(data.target)}?</p>
    </ConfirmDialog>
);

export default connect(null, {abortSync})(AbortSyncConfirmDialog);