import React from 'react'
import {isString} from 'lodash'

require('./viewCommonComponents.scss');

export const HeaderItem = ({title, data, className}) => {
    return (
        <div className={`header-item ${className? className : ""}`}>
            <div className="header-item-data" title={isString(data) ? data : ""}>{data}</div>
            <div className="header-item-title">{title}</div>
        </div>
    )
}



