import { AccountInfo, Auth, SyncState, Wizard } from "../store.types";

const rootSelector = {
    auth(state: SyncState): Auth {
        return state.auth;
    },
    accountInfo(state: SyncState): AccountInfo {
        return state.accountInfo;
    },
    wizard(state: SyncState): Wizard {
        return state.wizard;
    }
}

export default rootSelector;