import {useEffect, useState} from 'react';
import sortBy from 'lodash/sortBy';

const useFetchOnce = (callback, responseKey, mappingFunc, sortFunc, handleError) =>{
    const [state, setState] = useState(null);
    useEffect(()=>{
        if(!state) {
            callback().then(res => {
                let data = res[responseKey];
                if (Array.isArray(data) && mappingFunc) {
                    data = data.map(mappingFunc);
                }
                if (Array.isArray(data) && sortFunc) {
                    data = sortBy(data, sortFunc)
                }
                setState(data);
            }).catch(handleError)
        }
    }, [state, callback, mappingFunc, responseKey, sortFunc, handleError]);
    return state;
};

export default useFetchOnce;
