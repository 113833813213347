import React from 'react';
import {InputField} from "../../_common_/forms/forms";
import {Field } from 'react-final-form';
import Validator from 'validatorjs';
import {renameGroup} from "../../../store/wizard/action_creators";
import ConfirmFormDialogNew from '../../dialog/ConfirmFormDialogNew';
import './editGroupName.scss'

const rules = {
    groupName: 'required'
};

const validate = (values) => {
    const validator = new Validator(values, rules);
    validator.setAttributeNames({groupName : 'Group Name'});
    validator.passes();
    return validator.errors.all();
};


const EditGroupNameDialog = (props) => {
    const {groupId, groupName} = props;

    const submit = (values, dispatch) => {
        const {groupName} = values;
        return renameGroup(groupId, groupName)(dispatch)
    };

    const extractError = (error) => {
        return error.data.message
    }

    return (
        <ConfirmFormDialogNew
            title="Edit Group Name"
            formName="editGroupName"
            activateSubmit={submit}
            confirmText="Save"
            extractError={extractError}
            layoutClassName="edit-group-name"
            validate={validate}
            {...props}
        >
                <p>Give your group <b>"{groupName}"</b> a new Name and click save to continue</p>
                <div className='new-name'>
                    <Field component={InputField} name="groupName" placeholder="Data Broker Group Name"
                           label="New Name" value={groupName}/>
                </div>
        </ConfirmFormDialogNew>
    );
}

export default EditGroupNameDialog;

