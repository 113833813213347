import _ from "lodash";
import * as consts from "../consts";

export function licenseEnforcement(state) {
    let builtState = state;

    if (_.get(builtState, 'auth.succeeded')) {

        // update subscription fields
        const subscriptionStatus = _.get(builtState, 'auth.data.subscriptionStatus');
        const subscriptionType = _.get(builtState, 'auth.data.subscriptionType');
        const subscribed = subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_SUBSCRIBE_SUCCESS;
        builtState = builtState.setIn(['auth', 'data', 'subscribing'], subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_RESOLVE_SUCCESS) // server is still waiting for SUBSCRIBE_SUCCESS from Amazon
            .setIn(['auth', 'data', 'subscribed'], subscribed)
            .setIn(['auth', 'data', 'failedToSubscribe'], subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_RESOLVE_FAILED || subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_SUBSCRIBE_FAILED)
            .setIn(['auth', 'data', 'isPayGoSubscription'], subscriptionType === consts.SAAS_REGISTRATION_PAYGO_SUBSCRIPTION_TYPE)
            .setIn(['auth', 'data', 'unsubscribed'], subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_INIT || subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_UNSUBSCRIBED || subscriptionStatus === consts.SAAS_REGISTRATION_STATUS_UNSUBSCRIBE_PENDING);
        if (_.get(builtState, '_licenseSettings.succeeded')) {
            /*
             check the cases in which the user needs to pay after the free trial:
             1.       User is not subscribed from the AWS Marketplace and has no licenses, and created marketplace relationships (chargedRelationships > 0)
             2.       User is not subscribed from the AWS Marketplace and has licenses, but created more relationships than the capacity limit of the licenses (chargedRelationships > 0)
             */

            //in case there are only ANF/CVS relationships
            const noCharge = _.get(builtState, "auth.data.noCharge");
            builtState = builtState.setIn(["_licenseSettings", "data", "noCharge"], noCharge);

            const needToPay = noCharge ? false : (!_.get(builtState, "auth.data.subscribed") && _.get(builtState, '_licenseSettings.data.chargedRelationships') > 0);

            builtState = builtState.setIn(["_licenseSettings", "data", "needToPay"], needToPay);

            const daysLeftForTrial = _.get(builtState, "auth.data.daysLeftForTrial");

            // show trial page once if user needs to pay and on trial (not on first day of trial)
            const onTrial = daysLeftForTrial > 0 && daysLeftForTrial < consts.FREE_TRIAL_PERIOD;
            builtState = builtState.setIn(["_ui", "showTrialPage"], needToPay && onTrial && !builtState._ui.visitedTrialPage);

            // block ui if trial has ended, user needs to pay and has no license
            const hasLicense = _.get(builtState, "_licenseSettings.data.totalReservedRelationships") > 0;
            const shouldBlock = daysLeftForTrial <= 0 && needToPay && !hasLicense;
            builtState = builtState.setIn(["_ui", "blocked"], shouldBlock);

            // block creating a new relationship if trial has ended, user is not subscribed and licenses capacity exceeded
            const {reachedLicensesCapacity, licensesCapacityWarning} = checkLicensesCapacity(builtState);
            builtState = builtState.setIn(["_ui", "shouldBlockCreatingRelationship"], daysLeftForTrial <= 0 && !subscribed && reachedLicensesCapacity && licensesCapacityWarning); // will be falsy or an error message
        }
    }

    return builtState;
}

// calculated according to docs/ui/license-status-footer-truth-table.xlsx
export function calculateLicenseStatusFooter(state) {
    let licenseStatusText = 'LOADING...', warning = false, error = false, title = "";

    if (state) {
        const {auth, _licenseSettings} = state;
        if (_.get(auth, 'succeeded') && _.get(_licenseSettings, 'succeeded')) {
            const daysLeftForTrial = _.get(auth, "data.daysLeftForTrial");
            const isNoCharge = _.get(auth, "data.noCharge");
            const trialEnded = daysLeftForTrial <= 0;

            const subscribed = _.get(auth, "data.subscribed");
            const hasLicense = _.get(_licenseSettings, "data.totalReservedRelationships") > 0;

            const needToPay = _.get(_licenseSettings, "data.needToPay");


            if (!trialEnded) {
                licenseStatusText = `Free Trial (${daysLeftForTrial} ${daysLeftForTrial > 1 ? 'Days' : 'Day'} left) - Subscribe Now`;
                warning = needToPay;
                error = daysLeftForTrial <= 7 && warning;
            }
            else if (!needToPay && (hasLicense || subscribed)) {
                licenseStatusText = 'Active Subscription';
            }
            else {
                licenseStatusText = 'Subscription Required';
                error = true;
            }

            // display warning if reached license capacity and not subscribed
            if (hasLicense && !subscribed) {
                const {reachedLicensesCapacity} = checkLicensesCapacity(state);
                warning = reachedLicensesCapacity;
            }

            // in case of no charging - ignore the above logic
            if (isNoCharge) {
                warning = false;
                error = false;
                licenseStatusText = "FREE OF CHARGE";
                title = "There is no charge for sync relationships that include Azure NetApp Files or Cloud Volumes Service.";
            }
        }
    }

    return {licenseStatusText, warning, error, title};
}

export function checkLicensesCapacity(state, inLicenseSettings = false) {
    let reachedLicensesCapacity = false, licensesCapacityWarning = '';
    if (state) {
        const {_licenseSettings} = state;
        if (_.get(_licenseSettings, 'succeeded')) {
            const {totalReservedRelationships, existingRelationships} = _licenseSettings.data;
            if (totalReservedRelationships > 0) { // else there's no license capacity to check

                reachedLicensesCapacity = existingRelationships >= totalReservedRelationships;

                const paymentOptions = `${inLicenseSettings? '' : 'go to the License Settings page and '}either subscribe to ${consts.SERVICE_NAME} from the AWS Marketplace or add another BYOL license.`;

                if (reachedLicensesCapacity) {
                    if (existingRelationships === totalReservedRelationships) {
                        licensesCapacityWarning = `You have reached the maximum number of relationships that your licenses provide.\n`
                        + `To create a new relationship, ${paymentOptions}`;
                    }
                    else { // existingRelationships > totalReservedRelationships
                        const numOfNotCovered = existingRelationships - totalReservedRelationships;
                        licensesCapacityWarning = `You created ${existingRelationships} sync relationships, but you have licenses for only ${totalReservedRelationships}.\n`
                        + `To pay for the remaining ${numOfNotCovered} ${numOfNotCovered > 1 ? 'relationships' : 'relationship'}, ${paymentOptions}`;
                    }
                }
            }
        }
    }
    return {reachedLicensesCapacity, licensesCapacityWarning};
}

export const subscriptionProvidersNames = {
    aws: 'AWS',
    azure: 'Azure'
};
