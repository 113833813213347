import React from 'react';
import {useSelector} from "react-redux";
import {deleteDataBrokersGroup} from "../../../store/wizard/action_creators";
import ConfirmFormDialogNew from "../../dialog/ConfirmFormDialogNew";
import {useDispatch} from 'react-redux';
import {InputField} from "../../_common_/forms/forms";
import Validator from 'validatorjs';
import {Field} from 'react-final-form';
import {isEqual} from 'lodash'
import "./deleteGroupDialog.scss"
import {find} from "lodash";
import { deploySelector } from '../../../store/selectors/deploy.selector';

const validate = (values, groupName) => {
    const rules = {groupName: "shouldEqual"};
    Validator.register("shouldEqual", (value) => {
        return (isEqual(value, groupName))
    }, 'Please enter the group name for confirmation')
    const validator = new Validator(values, rules);
    validator.passes();
    return validator.errors.all();
};


const DeleteGroupDialog = ({data}) => {
    const dispatch = useDispatch();
    const {dataBrokers} = data;
    const deployments = useSelector(deploySelector.deployments);

    const existingDeployments = dataBrokers.map(broker => {
        return find(deployments, {dataBrokerId: broker.id})
    })
    const handleSubmit = () => {
        return deleteDataBrokersGroup(data.id, existingDeployments)(dispatch)
    }

    return (
        <ConfirmFormDialogNew
            title={`Delete the group "${data.name}"`}
            activateSubmit={handleSubmit}
            confirmText="Delete Group"
            extractError={(error) => error?.data?.message}
            className="delete-group-dialog"
            validate={(values) => validate(values, data.name)}
            disableActionTillChange={true}
        >
            <p>Data Brokers</p>
            <div className="delete-warning">All Data Brokers inside this group will be deleted permanently</div>
            <div className="confirm-field">
                <div>Type the name of the group in order to delete it.</div>
                <Field component={InputField} name="groupName"
                        type="text" label="Group Name"/></div>
        </ConfirmFormDialogNew>
    );
};

export default DeleteGroupDialog;
