import _ from "lodash";
import numeral from "numeral";
import bytes from 'bytes';
import * as consts from '../consts';
import {fromNowNoFuture, stringDuration} from "./../utils/time";

export const statusConvert = {
    SUCCESS: "Synced Successfully",
    COMPLETE: "Sync Completed",
    FAILED: "Failed To Sync",
    RUNNING: "Sync In Progress",
    INTERRUPTED: "Sync Interrupted",
    ABORTING: "Aborting Sync",
    ABORTED: "Sync Aborted",
    PENDING: "Pending First Sync",
    "RUNNING_CONTINUOUSLY":"Running Continuously"
};

export const formatBytes = (bytes) =>{
    if(!bytes) return {size: "0", suffix: "B"};
    let formattedCapacity = numeral(bytes).format('0.[00] ib');
    formattedCapacity = formattedCapacity !== 0 ?  formattedCapacity.replace("i", "") : formattedCapacity;
    return {
        size: (formattedCapacity.split(" ")[0]),
        suffix: formattedCapacity.split(" ")[1]
    };
};

export const formatActivityValues = (activity) => {

    const getDataObject = (originalValue, isBytes, label) => ({
            original: originalValue,
            formatted: isBytes ? formatBytes(originalValue).size : numeral(originalValue).format('0.[00]a'),
            label: isBytes ? formatBytes(originalValue).suffix : label
    });

    const isRunning = activity.status === consts.RESPONSE_STATUS_RUNNING;
    const lastTransferred = activity.lastMessageTime ? fromNowNoFuture(activity.lastMessageTime) : "No previous copy";
    const duration = activity.executionTime ? _.capitalize(stringDuration(activity.executionTime)) : "No previous copy";
    const lastTransferText = (activity.status === consts.RESPONSE_STATUS_FAILED) ? lastTransferred :
        (isRunning ? `${activity.type} | Duration: ${duration}` : `${activity.type} | Duration: ${duration} | ${lastTransferred}` );
    return {
        lastTransferredHeaderText: lastTransferText,
        activityValues: {
            scan: [
                { name: "Succeeded", data: {
                        dirs: getDataObject (activity.dirsScanned, false, 'Directories'),
                        files: getDataObject (activity.filesScanned, false, 'Files'),
                        bytes: getDataObject(activity.bytesScanned, true)}
                },
                { name: "Failed", data: {
                        dirs: getDataObject (activity.dirsFailedToScan, false, 'Directories'),
                        files: {original: null, formatted: null, label: 'Files'},
                        bytes: {original: null, formatted: null, label: 'Bytes'}}
                },
                { name: "Marked for Copy", data: {
                        dirs: getDataObject (activity.dirsMarkedForCopy, false, 'Directories'),
                        files: getDataObject (activity.filesMarkedForCopy, false, 'Files'),
                        bytes: getDataObject(activity.bytesMarkedForCopy, true)}
                },
                { name: "Marked for Delete", data: {
                        dirs: getDataObject (activity.dirsMarkedForRemove, false, 'Directories'),
                        files: getDataObject (activity.filesMarkedForRemove, false, 'Files'),
                        bytes: getDataObject(activity.bytesMarkedForRemove, true)}
                }
            ],
            copy: [
                { name: "Succeeded", data: {
                        dirs: getDataObject (activity.dirsCopied, false, 'Directories'),
                        files: getDataObject (activity.filesCopied, false, 'Files'),
                        bytes: getDataObject(activity.bytesCopied, true)}
                },
                { name: "Failed", data: {
                        dirs: getDataObject (activity.dirsFailed, false, 'Directories'),
                        files: getDataObject (activity.filesFailed + activity.filesRemovedFailed, false, 'Files'),
                        bytes: getDataObject(activity.bytesFailed + activity.bytesRemovedFailed, true)}
                },
                { name: "Deleted", data: {
                        dirs: getDataObject (activity.dirsRemoved, false, 'Directories'),
                        files: getDataObject (activity.filesRemoved, false, 'Files'),
                        bytes: getDataObject(activity.bytesRemoved, true)}
                }
            ]
        }
    };
};

//used to show info in the dashboard, dialogs etc...
export function createStringFromEndPointObject(object) {
    const {protocol} = object;
    const details = object[protocol];
    let objectString;
    switch (protocol) {
        case 's3': {
            const {host, port, bucket, provider} = details;
            let {prefix} = details;
            let fixedProtocol = provider;
            if (fixedProtocol === "ontap") fixedProtocol = "ontapS3"; //this case is an exception to the rule
            objectString = host ? ((port) ? `${fixedProtocol}://${host}:${port}` : `${fixedProtocol}://${host}`) : 's3:/';
            prefix = (prefix) ? (prefix.startsWith('/') ? prefix.substr(1) : prefix) : prefix;
            objectString = (prefix) ? `${objectString}/${bucket}/${prefix}` : `${objectString}/${bucket}`;
            break;
        }
        case 'cifs': {
            const {host} = details;
            let {path, share} = details;
            const fixedProtocol = (details.provider !== protocol) ? `smb-${details.provider}` : 'smb';
            path = (path) ? (path.startsWith('/') ? path.substr(1) : path) : path;
            share = share.startsWith('/') ? share.substr(1) : share;
            objectString = (path) ? `${fixedProtocol}://${host}/${share}/${path}` : `${fixedProtocol}://${host}/${share}`;
            break;
        }
        case 'nfs': {
            const {host} = details;
            let {path, export: nfsExport} = details;
            const fixedProtocol = (details.provider !== protocol) ? (details.provider !== "efs" ? `${protocol}-${details.provider}` : `${details.provider}`) : protocol;
            path = (path) ? (path.startsWith('/') ? path.substr(1) : path) : path;
            nfsExport = nfsExport.startsWith('/') ? nfsExport.substr(1) : nfsExport;
            const hostAndRootPath = _.isEmpty(nfsExport) ? `${fixedProtocol}://${host}` : `${fixedProtocol}://${host}/${nfsExport}`;
            objectString =  (path) ? `${hostAndRootPath}/${path}` : hostAndRootPath ;
            break;
        }
        case 'sftp': {
            const {host, port} = details;
            let {path} = details;
            const fixedProtocol = 'sftp';
            path = (path) ? (path.startsWith('/') ? path.substr(1) : path) : path;
            const hostAndPort = port ? `${fixedProtocol}://${host}:${port}` : `${fixedProtocol}://${host}`;
            objectString =  path ? `${hostAndPort}/${path}` : hostAndPort ;
            break;
        }
        case 'azure': {
            const {container, storageAccountName, provider, path} = details;
            let {prefix} = details;
            prefix = prefix || path;
            const fixedProtocol = provider === 'azure_data_lake' ? 'azureDataLake' : provider;
            prefix = (prefix) ? (prefix.startsWith('/') ? prefix.substr(1) : prefix) : prefix;
            objectString = (prefix) ? `${fixedProtocol}://${storageAccountName}/${container}/${prefix}` : `${fixedProtocol}://${storageAccountName}/${container}`;
            break;
        }
        case 'gcp': {
            const {bucket} = details;
            let {prefix} = details;
            prefix = (prefix) ? (prefix.startsWith('/') ? prefix.substr(1) : prefix) : prefix;
            objectString = (prefix) ? `gcp://${bucket}/${prefix}` : `gcp://${bucket}`;
            break;
        }
        case 'box': {
            let{ path} = details;
            const {appName, clientId} = details;
            const boxIdentifier = appName || clientId;
            path = (path) ? (path.startsWith('/') ? path.substr(1) : path) : path;
            objectString =  `box(${boxIdentifier})://${path}`;
            break;
        }
        case 'gdrive': {
            let{ path, driveName} = details;
            path = (path) ? (path.startsWith('/') ? path.substr(1) : path) : "";
            driveName = (driveName) ? (driveName.startsWith('/') ? driveName.substr(1) : driveName) : driveName;
            const displayPath = driveName ? `${driveName}/${path}` : path;
            objectString =  `gdrive://${displayPath}`;
            break;
        }
        default: {
            objectString = `${protocol} - will be fully supported in future versions`;
            break;
        }
    }
    return objectString;
}

export const createSplitPath = (pathWithHost) => {
    //split delimiter :// is included in the result
    return pathWithHost.split(/(?=:\/\/)/g);
};

export const routeFromPath = pathName => pathName && pathName.split('/').slice(-1)[0];
export const rootPath = pathName => pathName.split('/').filter(pathElement => pathElement.length > 0)[0];

export const defaultFilter = (item, filterString) => {
    if (_.isFunction(item)) return false;
    return _.values(item).find(value => {
        if (_.isEmpty(value) || !value) {
            return false;
        }
        else if(_.isArray(value)){
            return false;
        }
        else if(_.isObject(value)){
            return defaultFilter(value, filterString);
        }
        else return _.toString(value).toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
    } );
};

export const defaultFilterWithKeys = (item, filterString, keysToInclude) => {
    if (_.isFunction(item)) return false;
    return _.keys(item).find(key => {
        if (keysToInclude && !_.includes(keysToInclude, key)) return false;
        const value = item[key];
        if (_.isEmpty(value) || !value) {
            return false;
        }else if (_.isObject(value) && !_.isArray(value)) {
            return defaultFilter(value, filterString);
        } else if (_.isArray(value)) {
            return value.find(obj => defaultFilterWithKeys(obj, filterString, keysToInclude))
        }  else return _.toString(value).toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
    });
};

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

export const removeKeyFromPath = (path, key) => {
    if(_.startsWith(path, key))
        return _.replace(path, key, "");
    else return path;
};

export function uuid() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export const tagsToString = (tagsArray) => {
    const tagsString = _.reduce(tagsArray, (str, tag) => {
        return str ? (tag && tag.key && tag.value ? `${str}&${tag.key}=${tag.value}` : str) : (tag && tag.key && tag.value ? `${tag.key}=${tag.value}` : "");
    }, "");
    return tagsString ? tagsString : "noTags";
};

export const stringToTags = (tagsString) => {
    const tagsArray = tagsString.split("&");
    return _.reduce(tagsArray, (tags, tagString) => {
        const splitTag = tagString.split("=");
        tags.push({key: splitTag[0], value: splitTag[1]});
        return tags;
    }, []);
};

export const selectText = (element) => {
    if (window.getSelection) {
        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(element);
        window.getSelection().addRange(range);
    }
};

export const sendEvents = (gaAction) => {
    window.ga && window.ga('send', {
        hitType: 'event',
        eventCategory: 'onboarding',
        eventAction: gaAction
    });
};

//build an options array to be used by React Select
export const getSelectOptions = (valuesArray) => {
    return valuesArray.map((item) => ({label: item.toString(), value: item}));
};

export const convertSizeToBytes = (value, resolution) => {
    return bytes(`${value}${resolution}`);
};

export const convertBytesToSize = (bytesInput, decimals = 2) => {
    const converted = bytes(bytesInput, {decimalPlaces: decimals, unitSeparator: ' '});
    const convertedArray = converted ? converted.split(' ') : ['0', 'KB'];
    return {value: convertedArray[0], resolution: convertedArray[1]};
};

export const convertSpeed = (speedArray) => {
    const accumulated = _.reduce(speedArray, (sum, n) => sum + n, 0)
    if (accumulated === 0) return 'N/A';

    const formattedBytes = formatBytes(accumulated);
    return `${formattedBytes.size} ${formattedBytes.suffix}/s`;
}



export const randomString = function(length) {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

export const sortObjectEntries = object => {
    //Outcome array is keys sorted by their values (object values are numbers).
    return _.flattenDeep(_.values(_.invertBy(object)));
};

export const prettyfyJson = jsonData => {
    try {
        const jsonObj = JSON.parse(jsonData);
        return JSON.stringify(jsonObj, null, '\t');
    } catch (error) {
        return JSON.stringify(jsonData, null, '\t');
    }
};
