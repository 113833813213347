import React, { FunctionComponent, useRef } from "react";
import './relationshipCard.scss'
import Card from '../card/card'
//@ts-ignore
import { ReactComponent as NameIcon } from '../../../assets/svgs/link-icon.svg';
//@ts-ignore
import { ReactComponent as DataBrokerIcon } from "../../../assets/svgs/group-icon.svg";
//@ts-ignore
import { useDialog } from '@netapp/shared-components';
import InfoItem from "./relationshipInfoItem/relationshipInfoItem";
import { Typography } from "@netapp/design-system";
import { useTranslation } from "react-i18next";
import EditRelationshipNameDialog from "./editRelationshipNameDialog/editRelationshipNameDialog";
import { Relationship } from "../../api/api.types";
import isFeatureFlag, { FeatureFlags } from "../../utils/featureFlagUtil";

interface RelationshipCardProps {
    relationship: Relationship
}

const RelationshipCard: FunctionComponent<RelationshipCardProps> = ({ relationship }) => {
    const isFlagOn = isFeatureFlag(FeatureFlags.CS_7671);
    const { t } = useTranslation();
    const { setDialog } = useDialog();
    const editNameRef = useRef(null);

    return (
        <>
            {isFlagOn && <Card className="relationshipCard">
                <div className="relationshipInfo">
                    <div className="itemsContainer" ref={editNameRef}>
                        <InfoItem
                            title="sdf"
                            subTitle="ffff"
                            isRunning={false}
                            disabledText={t('sync.dashboard.relationship.disableName')}
                            onClick={() => setDialog(<EditRelationshipNameDialog />, editNameRef.current)}>
                            <NameIcon />
                        </InfoItem>
                        <InfoItem title="sdf" subTitle="ffff">
                            <DataBrokerIcon />
                        </InfoItem>
                        <InfoItem title="sdf" subTitle="ffff">
                            <NameIcon />
                        </InfoItem>
                        <InfoItem title="sdf" subTitle="ffff">
                            <NameIcon />
                        </InfoItem>
                    </div>
                    <div className="infoFooter">
                        <div className="infoFooterDetails">
                            <Typography variant="Regular_14" className="infoFooterDetailsText">blaaaa</Typography>
                            <Typography variant="Regular_14" className="infoFooterDetailsText">blaaaa</Typography>
                        </div>
                    </div>
                </div>
                <div className="relationshipDetails">dddd</div>
            </Card>}
        </>
    )
}

export default RelationshipCard;