import React, {useState} from "react";
import {FM_AGENT_TYPE_AZURE} from '../../consts';
import ProviderLoginLayout from './ProviderLoginLayout';
import {connect, useSelector} from 'react-redux';
import {doLogin} from '../../services/azure.api.service';
import {setAzureCredentials} from "../../store/deploy/deploySlice";
import useRunOnce from '../../hooks/useRunOnce';
import useRunOnceWhenTruthy from '../../hooks/useRunOnceWhenTruthy';
import {extractErrorAzure} from "../../utils/providers-utils";
import "./providerLoginAzure.scss";

const ProviderLoginAzure = props => {
    const { setAzureCredentials, handleContinue, setStepError} = props;
    const [resetDone, setResetDone] = useState(false);
    const [isGov, setIsGov] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const credentials = useSelector(state => {
        return state.deploy.credentials.azure;
    });
    useRunOnce(() => {
        //reset if exists from previous use, to force re-login every time
        if (credentials?.accessToken) {
            setAzureCredentials(null);
        }
        setResetDone(true);
    });

    const login = async (isGov) => {
        try {
            setStepError(null);
            setSubmitting(true);
            await doLogin(isGov);
        }
        catch (err) {
            setSubmitting(false);
            setStepError(extractErrorAzure(err));
        }
    };

    useRunOnceWhenTruthy(() => {
        handleContinue();
    }, resetDone && credentials?.accessToken);

    return <ProviderLoginLayout
        title={`Log In to ${FM_AGENT_TYPE_AZURE.providerName}`}
        subtitle={<p>Select your preferred login method and click <b>Continue</b> to log in to your {FM_AGENT_TYPE_AZURE.providerName} account
            using a form that is owned and hosted by {FM_AGENT_TYPE_AZURE.company}.
            Your credentials are not provided to NetApp.</p>}
        providerIcon={FM_AGENT_TYPE_AZURE.circledIcon}
        handleClick={() => login(isGov)}
        submitting={submitting}
    >
        <div className={`radio-buttons-wrapper ${submitting ? "disabled" : ""}`}>
            <div className="radio-option">
                <label htmlFor="gov1">
                <input id="gov1" name="isGov" type="radio" disabled={submitting}
                       checked={!isGov}
                       onChange={() => setIsGov(false)} />
                 Azure</label>
            </div>
            <div className="radio-option">
                <label htmlFor="gov2">
                <input id="gov2" name="isGov" type="radio" disabled={submitting}
                       checked={isGov}
                       onChange={() => setIsGov(true)} />
                Azure Government</label>
            </div>
        </div>
    </ProviderLoginLayout>
};


export default connect(null, {setAzureCredentials})(ProviderLoginAzure);
