import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { filteringFunc, syncReducer } from './wizard/reducer';
import { createLogger } from 'redux-logger';
import * as actions from "./wizard/action_creators_consts";
import isFunction from "lodash/isFunction";
import { uuid } from "../utils/helpers";
import { reducer as formReducer } from 'redux-form';
import { ignoreActions } from "redux-ignore";
import { globalReducer } from "./global/reducer";
import deploySlice from './deploy/deploySlice';
import { apiSlice } from '../syncNew/api/api.slice';
import authSlice from '../syncNew/store/slices/auth.slice';
import accountInfoSlice from '../syncNew/store/slices/accountInfo.slice';
import wizardSlice from '../syncNew/store/slices/wizard.slice';

const reducer = () => combineReducers({
    global: globalReducer,
    syncReducer: ignoreActions(syncReducer, filteringFunc),
    form: formReducer,
    [deploySlice.name]: deploySlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [accountInfoSlice.name]: accountInfoSlice.reducer,
    [wizardSlice.name]: wizardSlice.reducer
});

const predicate = (getState, action) => {
    if ((action.type === actions.UPDATE_RELATIONSHIP) && !(window.location.href.includes("dashboard") ||
        window.location.href.includes("review") || window.location.href.includes("sync-new") ||
        window.location.href.includes("timeline"))) return false;
    if ((action.type === actions.UPDATE_DATABROKER_TRANSFER_RATE) && !window.location.href.includes("dashboard")) return false;
    return true;
};

const logger = createLogger({ predicate });

const promise = ({ dispatch }) => {
    return next => action => {
        if (action.payload && action.payload.then && isFunction(action.payload.then)) {
            const id = uuid();
            action.payload.then(
                r => dispatch(Object.assign({}, action, { payload: r, id, requestId: r ? r.requestId : undefined, status: actions.STATUS_SUCCESS })),
                e => dispatch(Object.assign({}, action, { payload: e, id, status: actions.STATUS_ERROR })));

            next(Object.assign({}, action, { id, payload: null, status: actions.STATUS_PROGRESS }));
        }
        else {
            next(action);
        }
    }
};

const middlewares = [
    apiSlice.middleware,
    promise,
    logger
];

function configureReduxStore() {
    return configureStore({
        reducer: reducer(),
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(middlewares)
    });

}

export const store = configureReduxStore();
