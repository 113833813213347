import React from 'react';
import {Field } from 'react-final-form';
import {InputField} from "../_common_/forms/forms";
import Validator from 'validatorjs';
import _ from "lodash";
import ConfirmFormDialogNew from '../dialog/ConfirmFormDialogNew';
import {SERVICE_NAME} from "../../consts";
const indefinite = require('indefinite');

const rules = {directoryName: ['required', 'regex:/^(/)/']}


const validate = (values, props) => {
    const {directoryType} = props;
    const validator = new Validator(values, rules, {
        "regex.directoryName": `The ${directoryType} name must start with "/"`
    });
    validator.setAttributeNames({directoryName : `${directoryType} name` });
    validator.passes();
    return validator.errors.all();
};


const ManualDirectoryDialog = (props) => {
    const {onSubmitFunction, directoryType} = props;

    const article = {
        "export": "an",
        "share": "a"
    };

    const directoryName = {
        "export": "export",
        "share": "share"
    };

    const serverName = {
        "export": "NFS",
        "share": "SMB"
    };

    const title = `Add ${_.capitalize(article[directoryType])} ${_.capitalize(directoryName[directoryType])} Manually`;
    return (
        <ConfirmFormDialogNew
            title={title}
            activateSubmit={onSubmitFunction}
            confirmText="Add"
            layoutClassName="new-bucket-dialog"
            validate={validate}
            {...props}
        >
            <div className="new-bucket-instructions">
                Enter the name of {indefinite(serverName[directoryType])} {directoryName[directoryType]} to manually add it.
                <br/>
                This step might be necessary if {SERVICE_NAME} cannot retrieve {(directoryName[directoryType])}s from the {serverName[directoryType]} server.
            </div>

            <Field component={InputField} className="bucket-name" name="directoryName" placeholder={`/My${_.capitalize(directoryName[directoryType])}Name`} label={`${_.capitalize(directoryName[directoryType])} name`}/>

        </ConfirmFormDialogNew>
    )
};

export default ManualDirectoryDialog
