import {SystemFlow} from './SystemFlow';
import React from 'react';
import AlertDialog from '../dialog/AlertDialog';

const HowDoesItWorkDialog = ({wizardType, isSecure, className = ""}) => (
    <AlertDialog
        className={`how-it-works ${isSecure ? "encrypted" : ""} ${className}`}
        title={"How It Works"}
    >
        <SystemFlow wizardType={wizardType} isSecure={isSecure}/>
    </AlertDialog>
);

export default HowDoesItWorkDialog;