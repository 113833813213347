import React, {useEffect, useState} from 'react'
import numeral from "numeral";
import {capitalize, keys, map, reduce, values} from 'lodash'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {TooltipInfo} from '@netapp/shared-components';
import BarsGraph from "./BarsGraph";
import SummaryItem from './SummaryItem'
import {getVerticalBarOptions} from "./reportData";
import {ReactComponent as CapacityIcon} from '../../../assets/svgs/capacity-icon.svg'
import {ReactComponent as ObjectsIcon} from '../../../assets/svgs/objects-icon.svg'
import {STORAGE_CLASS_INFO} from "../../../consts";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const buildBarsDataSet = (sizes) => {
    const labels = keys(sizes);
    const colorArray = Array.apply(null,{length: labels.length}).map(() => '#1fa0ff');
    const hoverArray = Array.apply(null,{length: labels.length}).map(() => '#006dc9');
    const datasets = [{
        data:  values(sizes),
        backgroundColor: colorArray,
        hoverBackgroundColor: hoverArray,
        borderColor: colorArray,
        borderWidth: 1,
        barThickness: 19,
        borderRadius: 8
    }]
    return {labels, datasets};
}

const buildHorizontalGraphData = (sizes) => {
    const labels = keys(sizes);
    const counts = values(sizes)
    const total = reduce(counts, (sum, size) => sum + size, 0)
    const fixName = (name) => {
        switch (name.trim()) {
            case "Glacier Ir" : return "Glacier Instant Retrieval";
            case "Glacier": return "Glacier Flexible Retrieval";
            default: return name;
        }
    }
    return map(labels, (label, index) => {
        const splitLabel = label.split('_');
        const name = `${capitalize(splitLabel[0])} ${splitLabel.length > 1 ? (splitLabel[1] === 'IA' ? 'IA' : capitalize(splitLabel[1])) : ''}`
        const fixedName = fixName(name);
        return ({name: fixedName, count: numeral(counts[index]).format('0.[0]a'), percent: (counts[index] / total) * 100})
    });
}

const ReportViewObjects = ({report, protocol, provider}) => {
    const [fileSizes, setFileSizes] = useState(null)
    const [modifyTime, setModifyTime] = useState(null)
    const [storageClasses, setStorageClasses] = useState(null)

    useEffect(() => {
        setFileSizes(buildBarsDataSet(report.statistics.distributions.size))
        setModifyTime(buildHorizontalGraphData(report.statistics.distributions.modifiedTime))
        setStorageClasses(buildHorizontalGraphData(report.statistics.distributions.storageClass))
    }, [report])

    if (!report || !fileSizes) return <div/>
    const maxValue = Math.max(...values(report.statistics.distributions.size));
    const noStorageClass = protocol === 's3' && provider !== 's3';
    return (<div className="reports-view-objects">
        <div className="top-line">
            <div className="summary-graph report-card">
                <div className="card-header">Path Items</div>
                <div className="summary-content">
                    <SummaryItem top={{icon: CapacityIcon, title: "Capacity", data: report.statistics.totals.size, convertToBytes: true}}/>
                    <SummaryItem top={{icon: ObjectsIcon, title: "Objects", data: report.statistics.totals.objects}}
                                 bottom={{title: "Empty Objects", data: report.statistics.totals.emptyObjects}}/>
                </div>
            </div>
            <div className="files">
                <div className="files-sizes report-card objects-view">
                    <div className="card-header">Objects Size</div>
                    <div className="card-content">
                        <Bar data={fileSizes} options={getVerticalBarOptions(maxValue)}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="times">
            <div className={`times-box modify report-card objects-view ${protocol} ${noStorageClass ? 'multiple-columns-3' : 'multiple-columns-2'}`}>
                <div className="card-header">Modify Time<TooltipInfo>The last time that an object was modified.</TooltipInfo></div>
                <div className="card-content"><BarsGraph data={modifyTime} numOfColumns={noStorageClass ? 3 : 2 }/></div>
            </div>
            {!noStorageClass && <div className={`times-box change report-card objects-view ${protocol} ${noStorageClass ? 'multiple-columns-3' : 'multiple-columns-2'}`}>
                <div className="card-header">{provider.toUpperCase() === "AZURE" ? "Access Tier" : "Storage Class"}<TooltipInfo trigger="click">{STORAGE_CLASS_INFO[provider.toUpperCase()]}</TooltipInfo></div>
                <div className="card-content"><BarsGraph data={storageClasses} numOfColumns={2} backgroundColor="#71dbc7"/></div>
            </div>}
        </div>

    </div>)
}

export default ReportViewObjects;
