import "regenerator-runtime/runtime.js";
import { store } from '../store/store';
import { setAzureCredentials } from '../store/deploy/deploySlice';
import { mockAzureRoutes } from "../simulator/cloudProvidersSimulator";
import { randomString } from '../utils/helpers';
import moment from "moment";
import * as msal from "@azure/msal-browser";
import axios from 'axios';

const useMockApi = process.env.REACT_APP_SIMULATE_API === 'true';

export const AZURE_BASE_URL = {
    MOCK: "http://azure",
    PUBLIC: "https://management.azure.com",
    GOV: "https://management.usgovcloudapi.net"
};
const SCOPES = ["https://management.azure.com/.default"];
const GOV_SCOPES = ["https://management.core.usgovcloudapi.net/.default"];

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(response => response.data, error => Promise.reject(error.response));

if (useMockApi) {
    mockAzureRoutes(axiosInstance);
}

let isGovCloud = false;
let currentAccount = null;

const getMsalInstance = async (isGov) => {
    if (isGov) {
        //gov tenant (directory) id :1769cddf-83e7-4758-90fa-2ff00cb49e41
        const msalConfigGov = {
            auth: {
                clientId: process.env.REACT_APP_AZURE_CLIENT_ID_GOV,
                redirectUri: window.location.origin,
                authority: "https://login.microsoftonline.us/1769cddf-83e7-4758-90fa-2ff00cb49e41",
            },
            cache: {
                claimsBasedCachingEnabled: true
            }
        };

        return await msal.PublicClientApplication.createPublicClientApplication(msalConfigGov);
    } else {
        const msalConfig = {
            auth: {
                clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
                redirectUri: window.location.origin,
                authority: "https://login.microsoftonline.com/common",
            },
            cache: {
                claimsBasedCachingEnabled: true
            }
        };

        return await msal.PublicClientApplication.createPublicClientApplication(msalConfig);
    }
}

export const doLogin = async (isGov) => {
    if (useMockApi) {
        axiosInstance.defaults.baseURL = AZURE_BASE_URL.MOCK;
        updateStoreWithToken("dummyToken");
        return Promise.resolve();
    }
    isGovCloud = isGov;
    if (isGov) {
        axiosInstance.defaults.baseURL = AZURE_BASE_URL.GOV;
    }
    else {
        axiosInstance.defaults.baseURL = AZURE_BASE_URL.PUBLIC;
    }
    const authInstance = await getMsalInstance(isGov);
    try {
        const loginResponse = await authInstance.loginPopup({});
        currentAccount = loginResponse.account;
        const tokenResponse = await acquireToken();
        handleToken(tokenResponse);
    }
    catch (error) {
        return await Promise.reject(error);
    }
};

const acquireToken = async () => {
    const authInstance = await getMsalInstance(isGovCloud);

    const silentRequest = {
        scopes: isGovCloud ? GOV_SCOPES : SCOPES,
        account: currentAccount
    };
    const request = {
        scopes: isGovCloud ? GOV_SCOPES : SCOPES,
        loginHint: currentAccount.username
    };

    try {
        return await authInstance.acquireTokenSilent(silentRequest);
    }
    catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await authInstance.acquireTokenPopup(request);
        }
    }
};

const setRenewToken = (expiresOn) => {
    const expiresUnix = moment(expiresOn).valueOf();
    const now = Date.now();
    const timeToExpire = expiresUnix - now;

    setTimeout(async () => {
        try {
            const tokenResponse = await acquireToken();
            handleToken(tokenResponse);
        }
        catch (e) {
            doLogin();
        }
    }, timeToExpire - 10 * 1000);//renew token 10 seconds before the token is expired
};

const handleToken = (token) => {
    updateStoreWithToken(token.accessToken);
    setRenewToken(token.expiresOn);
};

const updateStoreWithToken = (accessToken) => {
    store.dispatch(setAzureCredentials({ accessToken }));
};

const getAzureTokenHeader = () => {
    const accessToken = store.getState().deploy.credentials.azure.accessToken;
    return { headers: { Authorization: `Bearer ${accessToken}` } };
};

const createMarketplaceAgreementUrl = (subscriptionId, publisher, product, plan) => {
    return `/subscriptions/${subscriptionId}/providers/Microsoft.MarketplaceOrdering/offerTypes/virtualmachine/publishers/${publisher}/offers/${product}/plans/${plan}/agreements/current?api-version=2021-01-01`
}
export const getSubscriptions = (path) => {
    return axiosInstance.get(path || `/subscriptions?api-version=2016-06-01`, getAzureTokenHeader());
};

export const getVnetsWithSubnets = (subscriptionId, path) => {
    return axiosInstance.get(path || `/subscriptions/${subscriptionId}/providers/Microsoft.Network/virtualNetworks?api-version=2019-07-01`, getAzureTokenHeader());
};


export const getResourceGroups = (subscriptionId, path) => {
    return axiosInstance.get(path || `/subscriptions/${subscriptionId}/resourcegroups?api-version=2018-02-01`, getAzureTokenHeader());
};

export async function* getResourceGroupsGenerator(subscriptionId) {
    let { value, nextLink } = await getResourceGroups(subscriptionId);
    yield value;
    while (nextLink) {
        const newUrl = new URL(nextLink);
        const pathToSend = `${newUrl.pathname}${decodeURIComponent(newUrl.search)}`;
        ({ value, nextLink } = await getResourceGroups(subscriptionId, pathToSend));
        if (value.length) {
            yield value;
        }
    }
}


export async function* getVnetsWithSubnetsGenerator(subscriptionId) {
    let { value, nextLink } = await getVnetsWithSubnets(subscriptionId);
    yield value;
    while (nextLink) {
        const newUrl = new URL(nextLink);
        const pathToSend = `${newUrl.pathname}${decodeURIComponent(newUrl.search)}`;
        ({ value, nextLink } = await getVnetsWithSubnets(subscriptionId, pathToSend));
        if (value.length) {
            yield value;
        }
    }
}

export async function* getSubscriptionsGenerator() {
    let { value, nextLink } = await getSubscriptions();
    yield value;
    while (nextLink) {
        const newUrl = new URL(nextLink);
        const pathToSend = `${newUrl.pathname}${decodeURIComponent(newUrl.search)}`;
        ({ value, nextLink } = await getSubscriptions(pathToSend));
        if (value.length) {
            yield value;
        }
    }
}


export const getAzureRegions = (subscriptionId) => {
    return axiosInstance.get(`/subscriptions/${subscriptionId}/locations?api-version=2016-06-01`, getAzureTokenHeader());
};

export const createResourceGroup = (subscriptionId, resourceGroupName, location) => {
    return axiosInstance.put(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}?api-version=2018-02-01`,
        { 'location': location }, getAzureTokenHeader());
};

export const deleteResourceGroup = (subscriptionId, resourceGroupName) => {
    return axiosInstance.delete(`subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}?api-version=2019-05-10`, getAzureTokenHeader());
};

export const azureTemplateDeploymentValidation = (deployDetails) => {
    return axiosInstance.post(`/subscriptions/${deployDetails.subscriptionId}/resourcegroups/${deployDetails.resourceGroupName}/providers/Microsoft.Resources/deployments/${deployDetails.deploymentName}/validate?api-version=2018-02-01`,
        { properties: deployDetails.properties }, getAzureTokenHeader());
};

export const azureTemplateDeployment = (deployDetails) => {
    return axiosInstance.put(`/subscriptions/${deployDetails.subscriptionId}/resourcegroups/${deployDetails.resourceGroupName}/providers/Microsoft.Resources/deployments/${deployDetails.deploymentName}?api-version=2018-02-01`,
        { properties: deployDetails.properties }, getAzureTokenHeader());
};

export const getDeploymentStatus = (subscriptionId, resourceGroupName, deploymentName) => {
    return axiosInstance.get(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Resources/deployments/${deploymentName}?api-version=2018-02-01`, getAzureTokenHeader());
};

export const cancelDeployment = (subscriptionId, resourceGroupName, deploymentName) => {
    return axiosInstance.post(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Resources/deployments/${deploymentName}/cancel?api-version=2019-08-01`, null, Object.assign({}, getAzureTokenHeader(), { maxContentLength: 0 }));
};

export const getVm = (subscriptionId, resourceGroupName, vmName) => {
    return axiosInstance.get(`/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2017-12-01`, getAzureTokenHeader());
};

export const deleteVM = (subscriptionId, resourceGroupName, vmName) => {
    return axiosInstance.delete(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Compute/virtualMachines/${vmName}?api-version=2017-12-01`, getAzureTokenHeader());
};

export const deleteDisk = (subscriptionId, resourceGroupName, diskName) => {
    return axiosInstance.delete(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Compute/disks/${diskName}?api-version=2017-03-30`, getAzureTokenHeader());
};

export const deleteNetworkInterface = (subscriptionId, resourceGroupName, networkInterfaceName) => {
    return axiosInstance.delete(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Network/networkInterfaces/${networkInterfaceName}?api-version=2018-04-01`, getAzureTokenHeader());
};

export const deletePublicIP = (subscriptionId, resourceGroupName, publicIpAddressName) => {
    return axiosInstance.delete(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Network/publicIPAddresses/${publicIpAddressName}?api-version=2018-04-01`, getAzureTokenHeader());
};

const getSecurityGroups = (subscriptionId, path) => {
    return axiosInstance.get(path || `/subscriptions/${subscriptionId}/providers/Microsoft.Network/networkSecurityGroups?api-version=2022-11-01`, getAzureTokenHeader());
};

export async function* getAllSecurityGroups(subscriptionId) {

    let { value, nextLink } = await getSecurityGroups(subscriptionId);
    yield value;
    while (nextLink) {
        const newUrl = new URL(nextLink);
        const pathToSend = `${newUrl.pathname}${decodeURIComponent(newUrl.search)}`;
        ({ value, nextLink } = await getSecurityGroups(subscriptionId, pathToSend));
        if (value.length) {
            yield value;
        }
    }
};

export const deleteSecurityGroup = (subscriptionId, resourceGroupName, networkSecurityGroupName) => {
    return axiosInstance.delete(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroupName}/providers/Microsoft.Network/networkSecurityGroups/${networkSecurityGroupName}?api-version=2018-04-01`, getAzureTokenHeader());
};

export const createRoleDefinition = (subscriptionId, roleId) => {
    return axiosInstance.put(`/subscriptions/${subscriptionId}/providers/Microsoft.Authorization/roleDefinitions/${roleId}?api-version=2015-07-01`, {
        name: `${roleId}`,
        properties: {
            roleName: `Data Broker role-${randomString(4)}`,
            description: 'Data Broker role',
            type: 'CustomRole',
            permissions: [
                {
                    actions: [
                        'Microsoft.Storage/storageAccounts/read',
                        'Microsoft.EventGrid/systemTopics/eventSubscriptions/write',
                        'Microsoft.EventGrid/systemTopics/eventSubscriptions/read',
                        'Microsoft.EventGrid/systemTopics/eventSubscriptions/delete',
                        'Microsoft.EventGrid/systemTopics/eventSubscriptions/getFullUrl/action',
                        'Microsoft.EventGrid/systemTopics/eventSubscriptions/getDeliveryAttributes/action',
                        'Microsoft.EventGrid/systemTopics/read',
                        'Microsoft.EventGrid/systemTopics/write',
                        'Microsoft.EventGrid/systemTopics/delete',
                        'Microsoft.EventGrid/eventSubscriptions/write',
                        'Microsoft.Storage/storageAccounts/write'
                    ],
                    notActions: []
                }
            ],
            assignableScopes: [`subscriptions/${subscriptionId}`]
        }
    }, getAzureTokenHeader());
}

export const assignRole = (subscriptionId, roleAssignmentId, roleId, principalId) => {
    return axiosInstance.put(`/subscriptions/${subscriptionId}/providers/Microsoft.Authorization/roleAssignments/${roleAssignmentId}?api-version=2015-07-01`,
        {
            properties: {
                roleDefinitionId: roleId,
                principalId
            }
        }, getAzureTokenHeader());
}

export const getMarketplaceAgreement = (subscriptionId, publisher, offer, plan) => {
    return axiosInstance.get(createMarketplaceAgreementUrl(subscriptionId, publisher, offer, plan),
        getAzureTokenHeader())
}

export const acceptMarketplaceAgreement = (subscriptionId, publisher, product, plan,
    licenseTextLink, marketplaceTermsLink, privatePolicyLink,
    retrieveDatetime, signature) => {
    return axiosInstance.put(createMarketplaceAgreementUrl(subscriptionId, publisher, product, plan), {
        properties: {
            publisher, product, plan, liceneseTextLink: licenseTextLink, marketplaceTermsLink, privatePolicyLink, retrieveDatetime, signature,
            accepted: true
        }
    },
        getAzureTokenHeader())
}
