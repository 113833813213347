import React, { useState } from "react";
import RelationshipInfo from "./RelationshipInfo"
import SyncInfo from "./SyncInfo"
import { RESPONSE_STATUS_FAILED } from '../../../consts';
import { NOTIFICATION_CONSTS } from "../../../consts/notification.consts";
import { getDataBrokerNameById } from "../../../utils/data-utils";
import RelationshipErrorsDialog from "../../errorsDialog/RelationshipErrorsDialog";
import { Notification } from '@netapp/design-system'
import { handleChildren } from "../../NotificationGroup/NotificationGroup";
import "./relationshipCard.scss"

const RelationshipCardContent = ({ data, setDialog }) => {
    const { errors, status } = data.activity;
    const [notificationCleared, setNotificationCleared] = useState(false);
    const notificationText = getNotificationText(errors);

    return (
        <>
            <div className="relationship-card-content">
                <RelationshipInfo data={data} />
                <SyncInfo data={data} calledFrom="dashboard" />
            </div>
            {status !== RESPONSE_STATUS_FAILED && notificationText && !notificationCleared && <Notification className="relationship-card-notification"
                                                                                                                type={NOTIFICATION_CONSTS.TYPE.ERROR}
                                                                                                                id={data.group}
                                                                                                                children={handleChildren({
                                                                                                                    children: notificationText,
                                                                                                                    moreInfo: (el)=>{setDialog(<RelationshipErrorsDialog errors={errors}/>, el.target)}
                                                                                                                })}
                                                                                                                onClose={() => setNotificationCleared(true)}
            />}
        </>
    )
}

const RelationshipCard = {
    className: () => {
        return "relationship-card"
    },
    renderContent: ({ data, setDialog }) => {
        return <RelationshipCardContent data={data} setDialog={setDialog} />
    }
};

export default RelationshipCard;

const getNotificationText = (errors) => {
    const errorsCount = errors?.length;
    if (!errorsCount) return null;
    const dataBrokerName = getDataBrokerNameById(errors[0].dataBrokerId);
    return errorsCount === 1 ? `Data Broker ${dataBrokerName} failed.` : `${errorsCount} errors.`;
};
