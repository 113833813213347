import React from 'react';
import { FunctionComponent } from 'react';
import './card.scss';

interface CardProps {
    className?: string,
    children: JSX.Element | JSX.Element[]
}

const Card: FunctionComponent<CardProps> = ({ children, className }) => {
    return (
        <div className={`globalCard ${className ? className : ''}`}>{children}</div>
    )
}

export default Card;