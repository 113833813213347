import React from "react";
import {useState} from "react";
import {SystemFlow} from '../../systemFlow/SystemFlow';
import useRunOnceWhenTruthy from '../../../hooks/useRunOnceWhenTruthy';

const useShowHowItWorks = ({_relationships, _wizard, selectedParam, name}) => {
    const {stepsOrder, wizardTypeForSystemFlow, isSecure} = _wizard;
    const [showHowItWorks, setShowHowItWorks] = useState(null);

    useRunOnceWhenTruthy(() => {
        const isFirstStepOfWizard = stepsOrder[0] === name;
        setShowHowItWorks(_relationships?.data.length === 0 && isFirstStepOfWizard && !selectedParam);
    }, name && _relationships?.data);

    const HowItWorks = () => <div className="how-it-works-step">
        <h3>How It Works</h3>
        <SystemFlow wizardType={wizardTypeForSystemFlow} isSecure={isSecure}/>
    </div>;

    return {showHowItWorks, setShowHowItWorks, HowItWorks};
};

export default useShowHowItWorks;
