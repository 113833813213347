import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import SelectableTable from "./../table/SelectableTable";
import { STATUS_PROGRESS } from '../../store/global/action_creators_consts';
import { Popover, useDialog } from "@netapp/shared-components"
import DownloadLogsDialog from './DownloadLogsDialog';
import classNames from "classnames";
import { downloadDataBrokerLogs } from "../../store/wizard/action_creators"
import { checkIfFailExist, noConnectionToAllBrokers } from '../../utils/dashboard-utils';
import { getPropFromState } from '../../utils/mapStateToPropsUtils';
import MainLoader from '../_common_/MainLoader/MainLoader';
import { isCloudToCloud } from '../../utils/relationshipUtils';
import { ReactComponent as DownloadIcon } from "./../../assets/svgs/download.svg";
import useRunOnceWhenTruthy from "../../hooks/useRunOnceWhenTruthy";
import { LegendNarrow, LegendWide, SummaryCard } from "./FailedComponents";
import { ReactComponent as DirectoryIcon } from './../../assets/svgs/directories-icon.svg'
import { ReactComponent as FilesIcon } from './../../assets/svgs/files-icon.svg'
import { ReactComponent as ObjectsIcon } from './../../assets/svgs/objects-icon.svg'
import { ReactComponent as RightArrow } from "../../assets/svgs/right-arrow.svg";
import { HeaderItem } from "./commonComponents/ViewCommonComponents";
import { createStringFromEndPointObject } from "../../utils/helpers";
import { useNavigate, useParams } from 'react-router-dom';

require("./failedDetails.scss");

const FailedDetails = (props) => {
    const { _relationships, groupMapById, _routing, _downloadLogsRequests } = props;
    const [relationship, setRelationship] = useState();
    const [groupInfo, setGroupInfo] = useState(null);
    const { setDialog } = useDialog();
    const showObjects = relationship ? isCloudToCloud(relationship) : null;

    const params = useParams();
    const navigate = useNavigate();
    const relationshipId = params.relationshipId;

    useRunOnceWhenTruthy(() => {
        setGroupInfo(groupMapById[relationship?.group]);
    }, relationship?.group && groupMapById)

    useEffect(() => {
        if (_relationships && !_relationships.data) {
            return;
        }
        const rel = _.find(_relationships.data, { relationshipId });
        if (!rel) {
            navigate({
                pathname: '/dashboard',
                search: window.location.search
            });
        }
        setRelationship(rel);
    }, [_relationships, relationshipId, navigate]);

    if (!relationship) return <MainLoader />;
    const { activity, source, target } = relationship;

    const goBackClicked = () => {
        const { location: { query } } = _routing;
        navigate({
            pathname: `/${query.calledFrom}`,
            search: window.location.search
        });
    };

    const phaseMap = {
        LIST: "Scan",
        TRANSFER: "Copy"
    };

    const tableData = activity.topFiveMostCommonRelationshipErrors.map((error, index) => ({
        id: index,
        count: error.counter,
        failurePoint: phaseMap[error.step],
        errorMessage: `${error.errorCode}${error.description ? `: ${error.description}` : ''}`
    }));

    const isFailExist = checkIfFailExist(relationship.ui.activityValues);

    const downloadLogsInProgress = _.get(_downloadLogsRequests, [relationshipId, 'status']) === STATUS_PROGRESS;
    const noConnectionToBrokers = noConnectionToAllBrokers(groupInfo?.dataBrokers);
    const disableDownloadLogs = noConnectionToBrokers || downloadLogsInProgress;
    const downloadLogsTitle = noConnectionToBrokers ? 'No available data brokers' : '';
    const sourcePath = createStringFromEndPointObject(source)
    const targetPath = createStringFromEndPointObject(target)

    return (
        <div className="scrollable-area">
            <div className="failed-files">
                <div className="bread-crumbs" title="Relationship Information">
                    <div className="back" onClick={goBackClicked}>Dashboard</div>
                    <div className="right-arrow"><RightArrow /></div>
                    <div className="path">{`${sourcePath} --> ${targetPath} | Errors`}</div>
                </div>
                <div className="header">
                    <div className="header-info">
                        <HeaderItem className="blue" data="Relationship Information" />
                        <HeaderItem title="Source" data={sourcePath} />
                        <HeaderItem title="Target" data={targetPath} />
                    </div>
                </div>
                <div className="summary-graphs">
                    {!showObjects && <>
                        <SummaryCard header="Failed to Scan (Directories)"
                            labelInsidePie="Failed Directories"
                            failedCount={activity.dirsFailedToScan}
                            successCount={activity.dirsScanned}>
                            <LegendNarrow failedLabel="Failed to scan"
                                successLabel="Succeeded to scan"
                                failedCount={activity.dirsFailedToScan}
                                successCount={activity.dirsScanned}
                                icon={<DirectoryIcon />}
                                subtitle="Directories" />
                        </SummaryCard>
                        <SummaryCard header="Failed to Copy (Files)"
                            labelInsidePie="Failed Files"
                            failedCount={activity.filesFailed + activity.filesRemovedFailed}
                            successCount={activity.filesCopied}>
                            <LegendNarrow failedCount={activity.filesFailed + activity.filesRemovedFailed}
                                successCount={activity.filesCopied}
                                failedLabel="Failed to copy"
                                successLabel="Succeeded to copy"
                                icon={<FilesIcon />}
                                subtitle="Files" />
                        </SummaryCard>
                    </>}
                    {showObjects && <SummaryCard header="Failed to Copy (Objects)"
                        labelInsidePie="Failed Objects"
                        failedCount={activity.filesFailed + activity.filesRemovedFailed}
                        successCount={activity.filesCopied}>
                        <LegendWide failedCount={activity.filesFailed + activity.filesRemovedFailed}
                            successCount={activity.filesCopied}
                            failedLabel="Failed to copy"
                            successLabel="Succeeded to copy"
                            icon={<ObjectsIcon />} />

                    </SummaryCard>}
                </div>
                <div className="failed-files-table">
                    <div className="table-title">
                        <h5>Top 5 Failures</h5>
                        <Popover popoverClass={classNames("text-popover download-logs", { 'hidden': !downloadLogsInProgress })}
                            trigger="hover"
                            container={
                                <button type="button" disabled={disableDownloadLogs} title={downloadLogsTitle} className="download-logs"
                                    onClick={(el) => { setDialog(<DownloadLogsDialog sourceName='relationship' relationship={{ ...relationship, groupInfo }} {...props} />, el.target); }}>
                                    <span className="icon"><DownloadIcon /></span>
                                    <span className="text">Download Data Broker Logs</span>
                                </button>}>
                            {`Request was sent. This process usually takes a couple of minutes. The logs will be saved to your web browser's download location.`}
                        </Popover>
                    </div>
                    <SelectableTable Table={{ renderRow, renderHeader }} data={tableData}>
                    </SelectableTable>
                    {tableData.length === 0 && isFailExist &&
                        <div className="table-no-data">Sync Failed Details is a new feature.<br />
                            The errors will be listed starting from the next sync.</div>}
                </div>
            </div>
        </div>)
};

const mapStateToProps = (state, ownProps) => ({
    _relationships: state.syncReducer._relationships,
    groupMapById: _.keyBy(state.syncReducer._dataBrokersGroups.data, "id") || {},
    _routing: getPropFromState(state, "_routing", {}),
    _downloadLogsRequests: getPropFromState(state, "_downloadLogsRequests", {}),
    ...ownProps
});

export default connect(mapStateToProps, { downloadDataBrokerLogs })(FailedDetails);

const renderRow = (row) => (
    <div className="table-row">
        <td className="blank" />
        <td className="value">{row.count}</td>
        <td>{row.failurePoint}</td>
        <td className="host-name" title={row.errorMessage}>{row.errorMessage}</td>
    </div>
);

const renderHeader = () => (
    <thead>
        <tr>
            <td className="blank" style={{ width: "3%" }} />
            <td style={{ width: "13%" }}>Failed Items</td>
            <td style={{ width: "13%" }}>Failure Point</td>
            <td style={{ width: "80%" }}>Failure Reason</td>
        </tr>
    </thead>
);