import React, {useState} from 'react';
import classNames from "classnames";
import CardsContainer from '../../cards/CardsContainer';
import useRunOnceWhenTruthy from '../../../hooks/useRunOnceWhenTruthy';
import ButtonsPanel from '../../widgets/ButtonsPanel';
import WizardStepHeader from './WizardStepHeaderNew';
import useShowHowItWorks from "./useShowHowItWorksNew";
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';
import { useDispatch } from 'react-redux';

const WeStepTemplate = (props) => {
    const [selectedWE, setSelectedWE] = useState(null);
    const dispatch = useDispatch();

    const { _relationships, _wizard, up, isTargetStepFromSameSourceType,
         history, routing, stepOptions,continueToNextStep, updateSelectedParams, name, isSourceStep} = props;
    const {workingEnvironments, selectedStepParamName, selectedTargetStepParamName,
        showOnlyCmSelection, WECard, getSelectedParams} = stepOptions;
    const {currentStep, selectedWizardParams} = _wizard;
    const selectedWEfromParams = selectedWizardParams[selectedStepParamName];
    const selectedWETargetFromParams = selectedWizardParams[selectedTargetStepParamName];

    const theRelevantWE = isTargetStepFromSameSourceType ? selectedWETargetFromParams : selectedWEfromParams;


    //"start wizard" action updates "currentStep" and "isSourceStep". Only when this is done, "isSourceStep" is up to date.
    const isSourceStepUpdated = currentStep?.toLowerCase().endsWith("we");

    //initialize selection:
    useRunOnceWhenTruthy(() => {
        //in case of only 1 exists
        if (workingEnvironments.length === 1) {
            setSelected(workingEnvironments[0].id);
            return;
        }
        //in case of back navigation
        if (theRelevantWE) {
            setSelected(theRelevantWE);
        }
    }, workingEnvironments?.length > 0 && isSourceStepUpdated);

    const handleContinue = () => {
        if (!selectedWE) {
            addNotification({
                id: NOTIFICATION_CONSTS.UNIQUE_IDS.WEB_STEP_NOTIFICATION,
                type: NOTIFICATION_CONSTS.TYPE.ERROR,
                children: "You need to select a working environment before you can continue."
            })(dispatch);
            return;
        }
        updateSelectedParams(getSelectedParams(selectedWE))
        continueToNextStep();
    };

    const setSelected = (id) => {
        if (selectedWE !== id) {
            setSelectedWE(id);
            clearNotifications()(dispatch);
        }
    };

    const containerOptions = {singularTitle: "Working Environment", pluralTitle: "Working Environments", noWidgets: showOnlyCmSelection};
    const selectionOptions = {selector: setSelected, selectedId: selectedWE};

    const {showHowItWorks, setShowHowItWorks, HowItWorks} = useShowHowItWorks({_relationships, _wizard, selectedParam: theRelevantWE, name});

    return (
        <div className="wizard-step we-step">
            <div className={classNames("scrollable-area", {up, down: !up})}>

                {showHowItWorks && <HowItWorks/>}

                {!showHowItWorks &&
                <>
                    <WizardStepHeader
                        _wizard={_wizard}
                        routing={routing}
                        history={history}
                        title={`${showOnlyCmSelection ? "Selected " : "Select a"} ${isSourceStep ? "Source" : "Target"} Working Environment`}
                    />
                    {workingEnvironments &&
                    <CardsContainer
                        containerOptions={containerOptions}
                        selectionOptions={selectionOptions}
                        className="working-environments"
                        Card={WECard}
                        data={workingEnvironments}
                    />}
                </>}
            </div>
            <ButtonsPanel
                variant="white"
                type="button"
                onClick={showHowItWorks ? () => {setShowHowItWorks(false)} : handleContinue}
                text="Continue"/>
        </div>)

};

export default WeStepTemplate

