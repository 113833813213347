import React from 'react';
require("./reloadMessage.scss");

const ReloadMessage = () => <div className="reload-message">
    <div className="frame">
        <p className="text-color">Reload this page from your web browser to log in again.</p>
        <p>{`This is required because you were logged out of Cloud Central's single sign-on service from another browser tab.`}</p>
    </div>
</div>;

export default ReloadMessage;