import React, { useEffect, useState } from 'react';
import { startWizard, clearWizardCache, newWizardStepStarted } from '../../store/wizard/action_creators'
import { connect } from 'react-redux';
import {
    calculateCurrentStepObj,
    getStepsOrder,
    getWizardInfo,
    stepInfoById
} from './../../utils/sync-utils';
import { getCMIntegrationInfo, getStepComponentForNewWizard } from './../../utils/wizard-utils';
import { getPropFromState } from '../../utils/mapStateToPropsUtils';
import { store } from "../../store/store";
import { cloneDeep, keys, without, pick } from 'lodash'
import StepWizard from "react-step-wizard";
import NewWizardHeader from "./NewWizardHeader";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useRunOnce from '../../hooks/useRunOnce';


const Step = (props) => {
    const { currentStep, nextStep, isActive, newWizardStepStarted, StepComponent, name, state, updateSelectedParams } = props;
    useEffect(() => {
        if (isActive) {
            console.log(`%c name: ${name}\ncurrent: ${currentStep}\nstate: ${JSON.stringify(state, null, 2)}`, 'font-weight:bold;color:red')
            newWizardStepStarted(name);
        }
    }, [isActive, currentStep, name, newWizardStepStarted, state])
    return (<div className="full-height new-wizard">
        <StepComponent name={name} currentStep={currentStep} continueToNextStep={nextStep} state={state} updateSelectedParams={updateSelectedParams} {...props} />
    </div>)
}

const NewWizardContent = (props) => {
    const { _wizard, startWizard, clearWizardCache } = props;
    const [state, setState] = useState({});

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const clearSelectedParams = (paramsList) => {
        const stateKeys = keys(state);
        const withoutCleared = without(stateKeys, ...paramsList);
        const newState = pick(state, withoutCleared);
        setState(newState);
    }

    const updateSelectedParams = (params) => {
        setState(prev => ({ ...prev, ...params }))
    }


    useRunOnce(() => {
        const protocols = params.protocols;
        const providers = params.providers;
        const isSecure = params.isSecure === 'true';
        const wizardInfo = getWizardInfo(protocols, providers);
        const dataSense = store.getState().global._cmIntegration?.dataSense;
        const stepsOrder = getStepsOrder(wizardInfo, isSecure, getCMIntegrationInfo(wizardInfo), dataSense);
        const opt = { protocols, wizardInfo, isSecure, stepsOrder, stepInfoById };
        if (dataSense) {
            opt.dataSense = cloneDeep(dataSense);
        }
        startWizard(opt);
        const firstStepPath = stepInfoById[stepsOrder[0]].path;
        //this condition is needed in order to support page refresh
        if (location.pathname.endsWith("/start-wizard-new")) {
            clearWizardCache({ clearGroups: true });
            navigate({
                pathname: `/sync-new/${protocols}/${providers}/${isSecure}/${firstStepPath}`,
                search: window.location.search
            });
        }
    });

    if (!_wizard?.stepsOrder) return <div />;

    const noTransitions = {
        enterRight: '',
        enterLeft: '',
        exitRight: '',
        exitLeft: ''
    };
    return (
        <StepWizard nav={<NewWizardHeader />} isLazyMount={true} transitions={noTransitions}>
            {_wizard.stepsOrder.map((step, index) => {
                const currentStep = calculateCurrentStepObj(_wizard, step);
                const stepComponent = currentStep ? getStepComponentForNewWizard(currentStep?.path) : undefined;
                return <Step name={step} key={index} currentStepIndex={index + 1} StepComponent={stepComponent} state={state}
                    updateSelectedParams={updateSelectedParams} clearSelectedParams={clearSelectedParams} {...props} />
            })}
        </StepWizard>

    )
};


const mapStateToProps = (state) => ({
    _wizard: getPropFromState(state, "_wizard", null),
    _ui: { ...state.syncReducer._ui, ...state.global._ui }
});

export default connect(mapStateToProps, { startWizard, clearWizardCache, newWizardStepStarted })(NewWizardContent);
