import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SyncState } from '../store/store.types';
import { ApiResponse, PaginationApiResponse } from './api.types';
import isFeatureFlag, { FeatureFlags } from '../utils/featureFlagUtil';

export const pollingInterval = 30000;

export const apiMockResponseInitial: ApiResponse<any> = {
  isError: false,
  isFetching: true,
  isLoading: true,
  isSuccess: false,
}

export const paginationApiMockResponseInitial: PaginationApiResponse<any> = {
  isError: false,
  isFetching: true,
  isLoading: true,
  isSuccess: false,
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
  prepareHeaders: (headers, { getState }) => {
    const { auth: { accessToken }, accountInfo: { accountId, connectorId } } = (getState() as SyncState);

    if (accessToken && accountId) {
      const bearer = accessToken.includes('Bearer') ? '' : 'Bearer';
      headers.set('authorization', `${bearer} ${accessToken}`)
      headers.set('x-account-id', accountId)
      headers.set('x-Agent-id', connectorId!)
    }

    return headers
  },
})

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: ['relationships', 'groups'],
  endpoints: (builder) => ({
  }),
})

export const skip = (accountId: string | null | undefined, featureFlag: FeatureFlags, condition: boolean = false) => {
  return !accountId || condition || !isFeatureFlag(featureFlag);
}