import _ from 'lodash';
import React from 'react';
import moment from "moment";
import {convertBytesToSize, convertSizeToBytes, getSelectOptions, statusConvert} from './helpers';
import {SERVICE_NAME} from '../consts';
import {ReactComponent as ExclamationIcon} from "../assets/svgs/exclamation-mark-triangle-icon.svg";
import { findAclValuesByKey } from './relationshipUtils';

const SETTINGS_KEY = "settings";

//General functions/consts for all types of settings:

export const SETTINGS_DEFAULTS = {
    gracePeriod: 30,
    deleteOnTarget: false,
    deleteOnSource: false,
    objectTagging: false,
    fileTypes: {
        files: true,
        directories: true,
        symlinks: true,
        hardlinks: false
    },
    copyProperties: {
        tags: false,
        metadata: false
    },
    compareBy: {
        uid: true,
        gid: true,
        mtime: true,
        mode: true
    },
    compareByCloudToCloud: {
        uid: false,
        gid: false,
        mtime: true,
        mode: false
    },
    compareByAclOnly: {
        uid: false,
        gid: false,
        mtime: true,
        mode: false
    },
    retries: 3,
    files: {
        excludeExtensions: [],
        excludeByRegex: [],
        minSize: 0,
        maxSize: Number.MAX_SAFE_INTEGER,
        minDate: "1970-01-01",
        maxDate: null,
        minCreationDate: "1970-01-01",
        maxCreationDate: null
    },
    directories : {
        excludeByRegex: [],
        excludeNames:[]
    },
    schedule: {
        syncInDays: 1,
        syncInHours: 0,
        syncInMinutes: 0,
        isEnabled: true,
        syncWhenCreated: true,
        nextTime: moment().set({"minute": 0}).toISOString() //The UI only uses HH:mm , the date doesn't matter.
    },
    scheduleAbort: {
        abortInDays: 1,
        abortInHours: 0,
        abortInMinutes: 0,
        isEnabled: false,
    },
    copyAcl: false,
    copyData: true,
    notifications: {
        done: false,
        aborted: false,
        failed: false
    },
    continuousSync:false
};

export const isHardlinksSelected = (formValues) => {
    return formValues.fileTypes.includes(fileTypesMap.hardlinks);
}

export const aclMultiAndOnly = (formValues, copyAclOptions) => {
    if (!copyAclOptions) return false;
    const {aclOptions} = formValues;
    const {copyAcl, copyData} = findAclValuesByKey(copyAclOptions.value);

    if (aclOptions) {
        const {copyAcl: formCopyAcl, copyData: formCopyData} = findAclValuesByKey(aclOptions.value);
        return formCopyAcl && !formCopyData;
    }

    return copyAcl && !copyData;
}

export const getSummaryLines = (formValues, isCloudToCloud, hasOntapS3, hardlinksSelected, copyAclOptions) => {
    const includeAllFileTypes = _.get(formValues.fileTypes, 'length') === fileTypesOrder.length;
    const compareByOrderCalculated = compareByOrder(isCloudToCloud, hasOntapS3);
    const compareBySummary = getArraySummary(formValues.compareBy, compareByOrderCalculated);
    const copyPropertiesSummary = getArraySummary(formValues.copyProperties, copyPropertiesOrder);
    const notificationsSummary = getArraySummary(formValues.notifications, getNotificationsOrder(formValues.continuousSync));
    
    const getCompareBySummaryLines = () => {
        return aclMultiAndOnly(formValues, copyAclOptions) ? [compareByMap.mtime] : compareBySummary;
    }

    return {
        gracePeriod: getGraceSummary(formValues.gracePeriodOn, formValues.graceValue, formValues.graceResolution),
        deleteOnTarget: getDeleteOnTargetSummary(formValues.deleteOnTarget),
        deleteOnSource: getDeleteOnSourceSummary(formValues.deleteOnSource),
        objectTagging: formValues.objectTagging,
        fileTypes: getFileTypesSummary(includeAllFileTypes, hasOntapS3, hardlinksSelected, formValues),
        compareBy: _.isEmpty(compareBySummary) ? 'None of the attributes (besides size)' : `The following attributes (and size): ${getCompareBySummaryLines()}`,
        copyProperties: _.isEmpty(copyPropertiesSummary) ? 'None of the properties' : `The following properties : ${copyPropertiesSummary}`,
        retries: `Retry ${_.get(formValues.retries, 'value')} ${_.get(formValues.retries, 'value') === 1 ? 'time' : 'times'} before skipping file`,
        filesToExclude: _.get([...formValues.filesExtensions, ...formValues.filesRegex], 'length', 0) === 0 ? 'None' : [...formValues.filesExtensions, ...formValues.filesRegex].map(item => item.value).join(', '),
        directoriesNames: _.get(...formValues.directoriesRegex, formValues.directoriesNames, 'length', 0) === 0 ? 'None' : [...formValues.directoriesRegex, ...formValues.directoriesNames].map(item => item.value).join(', '),
        fileSize: formValues.fileSizeOption === FILE_SIZE_OPTION_DISABLED ? 'All' :
            `Min size: ${formValues.fileMinSizeValue} ${_.get(formValues.fileMinSizeResolution, 'value')}, Max size:  ${formValues.fileMaxSizeValue} ${_.get(formValues.fileMaxSizeResolution, 'value')}`,
        dateModified: getDateModifiedSummary(_.get(formValues.fileDateModifiedOption, 'value'), formValues.fileDateModifiedMin, formValues.fileDateModifiedMax),
        dateCreated: getDateCreatedSummary(_.get(formValues.fileDateCreatedOption, 'value'), formValues.fileDateCreatedMin, formValues.fileDateCreatedMax),
        schedule: getScheduleSummary(formValues.scheduleStatus, formValues.scheduleInterval, _.get(formValues.scheduleResolution, 'value')),
        scheduleAbort: getAbortSummary(formValues.abortStatus, formValues.abortInterval, _.get(formValues.abortResolution, 'value')),
        acl: getAclSummary(formValues),
        copyData: formValues.copyData,
        notifications: _.isEmpty(notificationsSummary)? 'No notifications selected' : `Notify me when: ${notificationsSummary}`,
        continuousSync: formValues.continuousSync ? "ON" : "OFF"
    };
};

export const createSettingsObjectFromFormValues = (values, isCloudToCloud) => {
    return {
        gracePeriod: getGracePeriod(values.graceValue, values.graceResolution, !_.includes(values.gracePeriodOn, NOT_EXCLUDE_FILES_OPTION)),
        deleteOnTarget: getBool(values.deleteOnTarget, DELETE_FILES_FROM_TARGET_OPTION_TEXT),
        deleteOnSource: getBool(values.deleteOnSource, DELETE_FILES_FROM_SOURCE_OPTION_TEXT),
        objectTagging: getBool(values.objectTagging, OBJECT_TAGGING_USE_OPTION_TEXT),
        fileTypes: getFileTypesObj(values.fileTypes),
        compareBy: getCompareByObj(values.compareBy, isCloudToCloud),
        copyProperties: getCopyPropertiesObj(values.copyProperties),
        retries: values.retries.value,
        files: {
            excludeExtensions: values.filesExtensions.map(item => item.value),
            excludeByRegex: values.filesRegex.map(item => item.value),
            minSize: values.fileSizeOption === FILE_SIZE_OPTION_DISABLED ? SETTINGS_DEFAULTS.files.minSize : convertSizeToBytes(values.fileMinSizeValue, values.fileMinSizeResolution.value),
            maxSize: values.fileSizeOption === FILE_SIZE_OPTION_DISABLED ? SETTINGS_DEFAULTS.files.maxSize : convertSizeToBytes(values.fileMaxSizeValue, values.fileMaxSizeResolution.value),
            minDate: getMinDateForDateModified(values),
            maxDate: getMaxDateForDateModified(values),
            minCreationDate: getMinDateForDateCreated(values),
            maxCreationDate: getMaxDateForDateCreated(values)
        },
        directories:{
          excludeNames: values.directoriesNames.map(item => item.value),
          excludeByRegex: values.directoriesRegex.map(item => item.value),
        },
        schedule: getScheduleData(values),
        scheduleAbort: getAbortData(values),
        continuousSync: values.continuousSync,
        copyAcl: values.copyAcl,
        copyData: values.copyData,
        notifications: {cm: getNotificationsObject(values.notifications)}
    };
};

const getFileTypes = (fileTypes, hasOntapS3, hasHardLinks) => {
    return _.omit(fileTypes, [hasOntapS3 && 'symlinks', !hasHardLinks && 'hardlinks']);
}

export const getFormValuesFromSettings = (settings, isEditMode, isCloudToCloud, hasOntapS3, hasHardLinks, aclOptions) => {
    const formGracePeriod = getFormGrace(settings.gracePeriod);
    const formFileMinSize = getFormFileSize(settings.files.minSize);
    const formFileMaxSize = getFormFileSize(settings.files.maxSize);
    const isFileSizeDefaults = settings.files.minSize === SETTINGS_DEFAULTS.files.minSize && settings.files.maxSize === SETTINGS_DEFAULTS.files.maxSize;
    const dateModifiedOption = getDateModifiedOption(settings.files.minDate, settings.files.maxDate);
    const dateCreatedOption = getDateCreatedOption(settings.files.minCreationDate, settings.files.maxCreationDate);
    const scheduleNextTime = getNextTimeHour(settings.schedule.nextTime);
    let fileTypes = getFileTypes(settings.fileTypes, hasOntapS3, hasHardLinks);
    const { copyAcl, copyData } = isEditMode ? settings : (aclOptions || {});

    const setCompareBy = () => {
        let checkedList = settings.compareBy;
        if (copyAcl && !copyData) checkedList = SETTINGS_DEFAULTS.compareByAclOnly;
        if (isCloudToCloud || hasOntapS3) checkedList = settings.compareByCloudToCloud;
        const checkMap = isCloudToCloud || hasOntapS3 ? compareByMapCloudToCloud : compareByMap;
        return createArrayFromObject(checkedList, checkMap);
    }

    return {
        graceValue: formGracePeriod.count,
        graceResolution: formGracePeriod.resolution,
        gracePeriodOn: settings.gracePeriod > 0 ? EXCLUDE_FILES_OPTION : NOT_EXCLUDE_FILES_OPTION,
        deleteOnTarget: settings.deleteOnTarget ? DELETE_FILES_FROM_TARGET_OPTION_TEXT : NOT_DELETE_FILES_FROM_TARGET_OPTION_TEXT,
        deleteOnSource: settings.deleteOnSource ? DELETE_FILES_FROM_SOURCE_OPTION_TEXT : NOT_DELETE_FILES_FROM_SOURCE_OPTION_TEXT,
        objectTagging: settings.objectTagging ? OBJECT_TAGGING_USE_OPTION_TEXT : OBJECT_TAGGING_DISABLE_OPTION_TEXT,
        fileTypes: createArrayFromObject(fileTypes, fileTypesMap),
        compareBy: setCompareBy(),
        copyProperties: createArrayFromObject(settings.copyProperties, copyPropertiesMap),
        retries: {label: `${settings.retries}`, value: settings.retries},
        filesExtensions: getSelectOptions(settings.files.excludeExtensions),
        filesRegex: getSelectOptions(settings.files.excludeByRegex),
        directoriesRegex: getSelectOptions(settings.directories.excludeByRegex),
        fileSizeOption: isFileSizeDefaults ? FILE_SIZE_OPTION_DISABLED : FILE_SIZE_OPTION,
        fileMinSizeValue: formFileMinSize.value,
        fileMinSizeResolution: {label: `${formFileMinSize.resolution}`, value: formFileMinSize.resolution},
        fileMaxSizeValue: formFileMaxSize.value,
        fileMaxSizeResolution: {label: `${formFileMaxSize.resolution}`, value: formFileMaxSize.resolution},
        fileDateModifiedMin: convertStringToDate(settings.files.minDate),
        fileDateModifiedMax: convertStringToDate(settings.files.maxDate),
        fileDateModifiedOption: {label: dateModifiedOption, value: dateModifiedOption},
        fileDateCreatedMin: convertStringToDate(settings.files.minCreationDate),
        fileDateCreatedMax: convertStringToDate(settings.files.maxCreationDate),
        fileDateCreatedOption: {label: dateCreatedOption, value: dateCreatedOption},
        scheduleStatus: getFormScheduleStatus(settings.schedule.isEnabled, settings.schedule.syncWhenCreated, isEditMode),
        scheduleNextTime: {label: scheduleNextTime, value: scheduleNextTime},
        scheduleInterval: settings.schedule.syncInDays > 0 ? settings.schedule.syncInDays : (settings.schedule.syncInHours > 0 ? settings.schedule.syncInHours : settings.schedule.syncInMinutes),
        scheduleResolution: settings.schedule.syncInDays > 0 ? {
            label: 'Days',
            value: 'Days'
        } : (settings.schedule.syncInHours > 0 ? {label: 'Hours', value: 'Hours'} : {
            label: 'Minutes',
            value: 'Minutes'
        }),
        directoriesNames: getSelectOptions(settings.directories.excludeNames),
        continuousSync: settings.continuousSync,
        abortStatus: settings.scheduleAbort ? getFormAbortStatus(settings.scheduleAbort.isEnabled) : ABORT_OPTIONS.OFF,
        abortInterval: settings.scheduleAbort ? (settings.scheduleAbort.abortInDays > 0 ? settings.scheduleAbort.abortInDays : (settings.scheduleAbort.abortInHours > 0 ? settings.scheduleAbort.abortInHours : settings.scheduleAbort.abortInMinutes)): 1,
        abortResolution: settings.scheduleAbort ?
            (settings.scheduleAbort.abortInDays > 0 ? {label: 'Days', value: 'Days'} :
                (settings.scheduleAbort.abortInHours > 0 ? {label: 'Hours', value: 'Hours'} : {
                    label: 'Minutes',
                    value: 'Minutes'
                })) :
            {label: 'Days', value: 'Days'},
        copyAcl: settings.copyAcl,
        copyData: settings.copyData,
        notifications: createArrayFromObject(settings.notifications?.cm, notificationsMap)
    };
};

//Specific functions/consts:

export const UI_MAX_FILE_SIZE_LIMIT = convertSizeToBytes('4', 'PB');
export const UI_MAX_FILE_SIZE_DEFAULT = convertSizeToBytes('500', 'TB');

export const NOT_EXCLUDE_FILES_OPTION = "Don’t exclude files that are modified before a scheduled sync";
export const EXCLUDE_FILES_OPTION = "Exclude files that are modified up to ";

export const NOT_DELETE_FILES_FROM_TARGET_OPTION_TEXT = "Never delete files from the target location";
export const DELETE_FILES_FROM_TARGET_OPTION_TEXT = "Delete files from the target location if they were deleted from the source";

export const NOT_DELETE_FILES_FROM_SOURCE_OPTION_TEXT = "Never delete files from the source location";
export const DELETE_FILES_FROM_SOURCE_OPTION_TEXT = "Delete files from the source location after they are copied to the target";

export const OBJECT_TAGGING_USE_OPTION_TEXT = `Allow ${SERVICE_NAME} to tag objects`;
export const OBJECT_TAGGING_DISABLE_OPTION_TEXT = `Allow ${SERVICE_NAME} to use objects metadata`;

export const FILE_SIZE_OPTION_DISABLED = 'Sync all file sizes';
export const FILE_SIZE_OPTION = 'Select a file size range';

export const MAX_RETRIES = 10;

export const DATE_MODIFIED_OPTIONS = {
    ALL: 'All',
    ONLY_MIN: 'Sync files modified after a specific date',
    ONLY_MAX: 'Sync files modified before a specific date',
    RANGE: 'Sync files modified between a time range'
};

export const DATE_CREATED_OPTIONS = {
    ALL: 'All',
    ONLY_MIN: 'Sync files created after a specific date',
    ONLY_MAX: 'Sync files created before a specific date',
    RANGE: 'Sync files created between a time range'
};

export const SCHEDULE_OPTIONS = {
    NOW: 'Start sync now',
    ON: 'Future sync',
    ONCE: 'One time copy',
    OFF: 'Turn sync schedule off'
};

export const ABORT_OPTIONS = {
    OFF: 'Sync will not be canceled',
    ON: 'Sync will be canceled'
};

export const MOCK_DATE_FOR_TESTS = "2018–01–30T11:00:0+00:00";

export const MIN_DATE_POSSIBLE = '01/01/1970';

export const saveSettingsToSessionStorage = (settings) => {
    window.sessionStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
};

export const getSettingsFromSessionStorage = (isCloudToCloud) => {
    let settings = JSON.parse(window.sessionStorage.getItem(SETTINGS_KEY));
    if (settings === null) {
        settings = SETTINGS_DEFAULTS;
    }
    //in case we are in the middle of the wizard we use the settings compareBy from the compareBy and not compareByCloudToCloud
    //CS-5985 UI: CompareBy: on cloud to cloud reset to defaults is not saved
    else if (isCloudToCloud && !settings.compareByCloudToCloud) {
        settings.compareByCloudToCloud = settings.compareBy;
    }
    return settings;
};

export const removeSettingsFromSessionStorage = () => {
    window.sessionStorage.removeItem(SETTINGS_KEY);
};

export const fileTypesMap = {
    files: 'Files',
    directories: 'Directories',
    symlinks: 'Symbolic Links',
    hardlinks: 'Hard Links'
};

export const fileTypesOrder = [fileTypesMap.files, fileTypesMap.directories, fileTypesMap.symlinks, fileTypesMap.hardlinks];
export const fileTypesForOntapS3Order = [fileTypesMap.files, fileTypesMap.directories];

export const getFileTypesOptions = (hasOntapS3, hasHardLinks, isAclOnly) => {
    const fileTypeOptions = hasOntapS3 ? getSelectOptions(fileTypesForOntapS3Order) : getSelectOptions(fileTypesOrder);
    const fileTypesOptions = fileTypeOptions.filter(fileType => fileType.value !== fileTypesMap.hardlinks || hasHardLinks);

    if (isAclOnly) {
        return fileTypesOptions.map(option => {
            if (option.value === fileTypesMap.hardlinks) {
                option.disabled = true;
            }

            return option;
        });
    }

    return fileTypesOptions;
};

export const compareByMap = {
    uid: 'uid',
    gid: 'gid',
    mode: 'mode',
    mtime: 'mtime'
};

export const compareByMapCloudToCloud = {
    mtime: 'mtime'
}

export const compareByOrder = (isCloudToCloud, hasOntapS3)=> isCloudToCloud || hasOntapS3 ? [compareByMapCloudToCloud.mtime]: [compareByMap.uid, compareByMap.gid, compareByMap.mode, compareByMap.mtime ];

export const getCompareByOptions = (isCloudToCloud, hasOntapS3, isAclOnly) => {
    const compareOptions = getSelectOptions(compareByOrder(isCloudToCloud, hasOntapS3));

    if (isAclOnly) {
        compareOptions.forEach(option => {
            if (option.value !== compareByMapCloudToCloud.mtime) {
                option.disabled = true;
            }
        });
    }
    
    return compareOptions;
};

export const copyPropertiesMap = {
    tags: 'tags',
    metadata: 'metadata'
};

export const copyPropertiesOrder = [copyPropertiesMap.tags, copyPropertiesMap.metadata ];

export const notificationsMap = {
    done : `${statusConvert['SUCCESS']} or ${statusConvert['COMPLETE']}`,
    aborted: statusConvert['ABORTED'],
    failed: statusConvert['FAILED'],
    running_continuously: statusConvert['RUNNING_CONTINUOUSLY']
}

export const getNotificationsOrder = (continuousSync) => {
    if (continuousSync){
        return [notificationsMap.failed, notificationsMap.running_continuously]
    } else {
        return [notificationsMap.done, notificationsMap.failed, notificationsMap.aborted];
    }
}

// export const notificationsOrder = [notificationsMap.done, notificationsMap.failed, notificationsMap.aborted, notificationsMap.running_continuously]




export const getNotificationsOptions = (continuousSync) => getSelectOptions(getNotificationsOrder(continuousSync));

export const getCopyPropertiesOptions = (isS3CompatibleCloudToCloud, isEditMode) => {
    if (isS3CompatibleCloudToCloud || isEditMode) //if s3 compatible all options are valid, if edit mode all disabled
        return getSelectOptions(copyPropertiesOrder);
    else return [{label: "metadata", value: "metadata"}, {label:"tags", value: "tags", disabled: true, disabledTitle: "Tags are supported only on compatible source and target"}]
};

export const getRetriesOptions = () => {
    const valuesArray = Array.from(Array(MAX_RETRIES + 1), (x, index) => index);
    return getSelectOptions(valuesArray);
};

export const getScheduleResolutionOptions = () => {
    return getSelectOptions(["Minutes", "Hours", "Days"]);
};

export const getScheduleNextTimeOptions = () => {
    return getSelectOptions(["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]);
};

export const getAbortResolutionOptions = () => {
    return getSelectOptions(["Hours", "Days", "Minutes"]);
};

export const getFileSizeResolutionOptions = () => {
    return getSelectOptions(["B", "KB", "MB", "GB", "TB"]);
};

export const getGraceResolutionOptions = () => {
    return getSelectOptions(["Seconds", "Minutes", "Hours", "Days"]);
};

export const getDateModifiedOptions = () => {
    return getSelectOptions(Object.values(DATE_MODIFIED_OPTIONS));
};

export const getDateCreatedOptions = () => {
    return getSelectOptions(Object.values(DATE_CREATED_OPTIONS));
};

export const extensionsInputTransformation = (inputValue) => (inputValue.startsWith('.') ? inputValue : `.${inputValue}`);
export const namesInputTransformation = (inputValue) => (inputValue);

export const getGraceSummary = (gracePeriodOn, graceValue, graceResolution) => {
    return _.includes(gracePeriodOn, NOT_EXCLUDE_FILES_OPTION) ? NOT_EXCLUDE_FILES_OPTION :
        <div>{EXCLUDE_FILES_OPTION}<b> {graceValue} {_.get(graceResolution, 'label')}</b> before a scheduled sync</div>
};

const getFileTypesSummary = (includeAllFileTypes, hasOntapS3, hardlinksSelected, formValues) => {
    const includeFileTypes = `Include ${includeAllFileTypes ? (hasOntapS3 ? 'Only' :'All') : 'Only'}: ${getArraySummary(formValues.fileTypes, hasOntapS3 ? fileTypesForOntapS3Order : fileTypesOrder)}`;

    if (!hardlinksSelected) return includeFileTypes;

    return (
        <div className='fileTypeSummary'>
            {hardlinksSelected && <div className="warning"><span><ExclamationIcon/></span></div>}
            {includeFileTypes}
        </div>
    )
}

const getDeleteOnTargetSummary = (deleteOnTarget) => {
    const warningIcon = (<div className="warning"><span><ExclamationIcon/></span></div>);
    return deleteOnTarget === DELETE_FILES_FROM_TARGET_OPTION_TEXT ? (<div>{warningIcon}{deleteOnTarget}</div>) : deleteOnTarget;
};

const getDeleteOnSourceSummary = (deleteOnSource) => {
    const warningIcon = (<div className="warning"><span><ExclamationIcon/></span></div>);
    return deleteOnSource === DELETE_FILES_FROM_SOURCE_OPTION_TEXT ? (<div>{warningIcon}{deleteOnSource}</div>) : deleteOnSource;
};

export const getArraySummary = (array, arrayOrder) => {
    if (!array) return;
    array.sort((typeA, typeB) => {
        return (arrayOrder.indexOf(typeA) - arrayOrder.indexOf(typeB));
    });
    return array.join(', ');

}

const getDateModifiedSummary = (option, dateMin, dateMax) => {
    const format = "MM/DD/YYYY";
    switch (option) {
        case DATE_MODIFIED_OPTIONS.ALL: {
            return DATE_MODIFIED_OPTIONS.ALL;
        }
        case DATE_MODIFIED_OPTIONS.ONLY_MIN: {
            return `Sync files modified after ${moment(dateMin).format(format)}`
        }
        case DATE_MODIFIED_OPTIONS.ONLY_MAX: {
            return `Sync files modified before ${moment(dateMax).format(format)}`
        }
        case DATE_MODIFIED_OPTIONS.RANGE: {
            return `Sync files modified between ${moment(dateMin).format(format)} and ${moment(dateMax).format(format)}`
        }
        default:
            return '';
    }
};

const getDateCreatedSummary = (option, dateMin, dateMax) => {
    const format = "MM/DD/YYYY";
    switch (option) {
        case DATE_CREATED_OPTIONS.ALL: {
            return DATE_CREATED_OPTIONS.ALL;
        }
        case DATE_CREATED_OPTIONS.ONLY_MIN: {
            return `Sync files created after ${moment(dateMin).format(format)}`
        }
        case DATE_CREATED_OPTIONS.ONLY_MAX: {
            return `Sync files created before ${moment(dateMax).format(format)}`
        }
        case DATE_CREATED_OPTIONS.RANGE: {
            return `Sync files created between ${moment(dateMin).format(format)} and ${moment(dateMax).format(format)}`
        }
        default:
            return '';
    }
};

const getScheduleSummary = (status, interval, resolution) => {
    if ( _.includes([SCHEDULE_OPTIONS.OFF, SCHEDULE_OPTIONS.ONCE], status)) return 'OFF';
    const summary =  `ON | Every ${interval} ${resolution}`;
    return (interval === '1' || interval === 1) ? summary.substring(0, summary.length - 1) : summary;
};

export const isScheduleActive = (status) => {
    return _.includes([SCHEDULE_OPTIONS.ON, SCHEDULE_OPTIONS.NOW], status);
};

const getAbortSummary = (status, interval, resolution) => {
    if ( ABORT_OPTIONS.OFF === status) return 'OFF';
    const summary =  `ON | After ${interval} ${resolution}`;
    return (interval === '1' || interval === 1) ? summary.substring(0, summary.length - 1) : summary;
};

const getAclSummary = formValues => {
    if (formValues.copyAcl) return "Active";
    if (formValues.aclOptions) return formValues.aclOptions.label;
    return "Inactive";
}

export const isAbortActive = (status) => {
    return ABORT_OPTIONS.ON === status;
};

export const calculatePower =(res) => {
    if (res === "Days"){
        return 86400;
    } else if (res === "Hours"){
        return 3600;
    } else if (res === "Minutes"){
        return 60
    } else if (res === "Seconds"){
        return 1;
    }
};

export const getGracePeriod = (graceValue, graceResolution, isGraceEnabled) => {
    const res = graceResolution.value;
    const power = calculatePower(res);
    const grace = isGraceEnabled ? graceValue : 0;
    return grace * power;
};

export const getFormGrace = (seconds) => {
    if (seconds < 60) return {count: seconds, resolution: {label: "Seconds", value: "Seconds"}};
    else if (seconds < 3600) return {count: (seconds / 60), resolution: {label: "Minutes", value: "Minutes"}};
    else if (seconds < 86400) return {count: (seconds / 3600), resolution: {label: "Hours", value: "Hours"}}
    else return {count: (seconds / 86400), resolution: {label: "Days", value: "Days"}};
}

export const getBool = (toCompare, compareBy) => {
    return _.includes(toCompare, compareBy);
}

export const getFileTypesObj = (typesArray) => {
    return {
        files: _.includes(typesArray, fileTypesMap.files),
        directories: _.includes(typesArray, fileTypesMap.directories),
        symlinks: _.includes(typesArray, fileTypesMap.symlinks),
        hardlinks: _.includes(typesArray, fileTypesMap.hardlinks)
    }
};

export const getCompareByObj = (typesArray, isCloudToCloud) => isCloudToCloud ?
    {
        mtime: _.includes(typesArray, compareByMapCloudToCloud.mtime)
    } : {
        uid: _.includes(typesArray, compareByMap.uid),
        gid: _.includes(typesArray, compareByMap.gid),
        mtime: _.includes(typesArray, compareByMap.mtime),
        mode: _.includes(typesArray, compareByMap.mode),
    };

export const getNotificationsObject = (typesArray) => {
    return {
        done: _.includes(typesArray, notificationsMap.done),
        failed: _.includes(typesArray, notificationsMap.failed),
        aborted: _.includes(typesArray, notificationsMap.aborted),
        running_continuously: _.includes(typesArray, notificationsMap.running_continuously)
    }
};

export const getCopyPropertiesObj = (typesArray) => {
    return {
        tags: _.includes(typesArray, copyPropertiesMap.tags),
        metadata: _.includes(typesArray, copyPropertiesMap.metadata)
    }
};

export const createArrayFromObject = (object, map) => {
    const resultArray = [];
    _.forEach(object, (value, type) => {
        if (value) resultArray.push(map[type]);
    });
    // console.log(`%c createArrayFromObject ${JSON.stringify(object, null, 2)}\n map: ${JSON.stringify(map, null, 2)}\narray: ${JSON.stringify(JSON.stringify(resultArray, null, 2))}`, 'font-weight:bold; color:red');
    return resultArray;
};

export const getFormFileSize = (size) => {
    return (size < UI_MAX_FILE_SIZE_DEFAULT) ? convertBytesToSize(size) : convertBytesToSize(UI_MAX_FILE_SIZE_DEFAULT);
};

export const convertDateToString = (date) => {
    if (!date) return null;
    return moment(date).format("YYYY-MM-DD");
};

export const convertStringToDate = (string) => {
    if (!string || string === SETTINGS_DEFAULTS.files.minDate) return null;
    return new Date(string);
};

const getDateModifiedOption = (minDate, maxDate) => {
    if (minDate !== SETTINGS_DEFAULTS.files.minDate && maxDate) return DATE_MODIFIED_OPTIONS.RANGE;
    if (maxDate) return DATE_MODIFIED_OPTIONS.ONLY_MAX;
    if (minDate !== SETTINGS_DEFAULTS.files.minDate) return DATE_MODIFIED_OPTIONS.ONLY_MIN;
    return DATE_MODIFIED_OPTIONS.ALL;
};

const getMinDateForDateModified = (values) => {
    if (_.includes([DATE_MODIFIED_OPTIONS.ONLY_MIN, DATE_MODIFIED_OPTIONS.RANGE], values.fileDateModifiedOption.value))
        return !values.fileDateModifiedMin ? SETTINGS_DEFAULTS.files.minDate : convertDateToString(values.fileDateModifiedMin);
    return SETTINGS_DEFAULTS.files.minDate;
};

const getMaxDateForDateModified = (values) => {
    if (_.includes([DATE_MODIFIED_OPTIONS.ONLY_MAX, DATE_MODIFIED_OPTIONS.RANGE], values.fileDateModifiedOption.value))
        return convertDateToString(values.fileDateModifiedMax);
    return SETTINGS_DEFAULTS.files.maxDate;
};

const getDateCreatedOption = (minDate, maxDate) => {
    if (minDate !== SETTINGS_DEFAULTS.files.minDate && maxDate) return DATE_CREATED_OPTIONS.RANGE;
    if (maxDate) return DATE_CREATED_OPTIONS.ONLY_MAX;
    if (minDate !== SETTINGS_DEFAULTS.files.minDate) return DATE_CREATED_OPTIONS.ONLY_MIN;
    return DATE_CREATED_OPTIONS.ALL;
};

const getMinDateForDateCreated = (values) => {
    if (_.includes([DATE_CREATED_OPTIONS.ONLY_MIN, DATE_CREATED_OPTIONS.RANGE], values.fileDateCreatedOption.value))
        return !values.fileDateCreatedMin ? SETTINGS_DEFAULTS.files.minCreationDate : convertDateToString(values.fileDateCreatedMin);
    return SETTINGS_DEFAULTS.files.minCreationDate;
};

const getMaxDateForDateCreated = (values) => {
    if (_.includes([DATE_CREATED_OPTIONS.ONLY_MAX, DATE_CREATED_OPTIONS.RANGE], values.fileDateCreatedOption.value))
        return convertDateToString(values.fileDateCreatedMax);
    return SETTINGS_DEFAULTS.files.maxCreationDate;
};

export const getScheduleData = (values) => ({
    syncInMinutes: values.scheduleResolution.value === 'Minutes' ? Number.parseInt(values.scheduleInterval, 10) : 0,
    syncInDays: values.scheduleResolution.value === 'Days' ? Number.parseInt(values.scheduleInterval, 10) : 0,
    syncInHours: values.scheduleResolution.value === 'Hours' ? Number.parseInt(values.scheduleInterval, 10) : 0,
    isEnabled: isScheduleActive(values.scheduleStatus),
    syncWhenCreated: _.includes([SCHEDULE_OPTIONS.NOW, SCHEDULE_OPTIONS.ONCE], values.scheduleStatus),
    nextTime: getNextTimeIsoString(_.get(values.scheduleNextTime, 'value'))
});

export const getAbortData = (values) => ({
    abortInDays: values.abortResolution.value === 'Days' ? Number.parseInt(values.abortInterval, 10) : 0,
    abortInHours: values.abortResolution.value === 'Hours' ? Number.parseInt(values.abortInterval, 10) : 0,
    abortInMinutes :values.abortResolution.value === 'Minutes' ? Number.parseInt(values.abortInterval, 10) : 0,
    isEnabled: isAbortActive(values.abortStatus),
});

export const getNextTimeHour = (isoString) => {
    if (!isoString) return undefined;
     //this is local time
    return moment(isoString).format("HH:mm");
};

export const getNextTimeIsoString = (nextTimeHour) => {
    const nowHour = moment().format("HH:mm");
    const isTomorrow = nextTimeHour <= nowHour;
    const date = isTomorrow ? moment().add(1, 'd').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    return moment(date + " " + nextTimeHour).toISOString();
};

const getFormScheduleStatus = (isEnabled, syncWhenCreated, isEditMode) => {
    if (isEditMode) return isEnabled ? SCHEDULE_OPTIONS.ON : SCHEDULE_OPTIONS.OFF;
    if (isEnabled && syncWhenCreated) return SCHEDULE_OPTIONS.NOW;
    if (!isEnabled ) return SCHEDULE_OPTIONS.ONCE;
    return SCHEDULE_OPTIONS.ON;
};

const getFormAbortStatus = (isEnabled) => {
    return isEnabled ? ABORT_OPTIONS.ON : ABORT_OPTIONS.OFF;
};
