import axios from 'axios';
import {isString, reduce} from "lodash";
import * as consts from "./consts";
import {getAccountId} from "./utils/data-utils";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + "/api",
});

export const setAuthHeader = (t) => {
    axiosInstance.defaults.headers.common['Authorization'] = t.authorization;
    return t;
};

export const setAccountHeader = (accountId) => {
    axiosInstance.defaults.headers.common['x-account-id'] = accountId;
};

const extractData = (response) => {
    const data = response.data;
    const requestId = response.headers["x-dfio-req-id"];
    if (data && !isString(data)) {
        data.requestId = requestId;
        return data;
    } else return {requestId};
};

const sdk = {
    getAccounts: () => {
        return axiosInstance.get('/accounts').then(extractData);
    },
    getAccountInfo: (accountId) => {
        setAccountHeader(accountId);
        return axiosInstance.get('/accounts/info').then(extractData);
    },
    registerToMarketplace: (defaultAccountId, mpCustomerToken, mpProvider) => {
        const config = { headers: { "x-account-id": defaultAccountId } };
        if (mpCustomerToken && mpProvider) {
            return axiosInstance.put('/accounts/register',{mpCustomerToken, mpProvider}, config).then(extractData);
        }
        return Promise.resolve();
    },
    createDataBroker: function (opts) {
        return axiosInstance.post('/data-brokers', opts).then(extractData);
    },
    restartDataBroker: function (brokerId) {
        const accountId = getAccountId();
        return axiosInstance.put(`accounts/${accountId}/data-brokers/${brokerId}/restart`).then(extractData);
    },
    updateProxyConfiguration: function (brokerId, opts) {
        const accountId = getAccountId();
        return axiosInstance.put(`/accounts/${accountId}/data-brokers/${brokerId}/proxy`, opts).then(extractData);
    },
    deleteDataBroker: function (id) {
        return axiosInstance.delete(`/data-brokers/${id}`).then(extractData);
    },
    deleteGroup: function (id) {
        const accountId = getAccountId();
        return axiosInstance.delete(`accounts/${accountId}/groups/${id}`).then(extractData);
    },
    moveDataBroker: function (dataBrokerId, data) {
        const accountId = getAccountId();
        return axiosInstance.put(`accounts/${accountId}/data-brokers/${dataBrokerId}/move`,data).then(extractData);
    },
    getServers: function (serverTypeBase, provider) {
        const providerParam = provider ? `?provider=${provider}` : '';
        return axiosInstance.get(`/storages/${serverTypeBase}${providerParam}`).then(extractData);
    },
    azureStorageAccounts: function () {
        return axiosInstance.get('/storages/azure').then(extractData);
    },
    azureDataLakeStorageAccounts: function () {
        return axiosInstance.get('/storages/azure?provider=azure_data_lake').then(extractData);
    },
    messages: function (lastMessage) {
        const filter = (lastMessage) ? '?last=' + lastMessage : '';
        return axiosInstance.get(`/messages/client${filter}`).then(extractData);
    },
    showExports: function (nfsServer, isEFS, groupId) {
        const accountId = getAccountId();
        const params = {host: nfsServer};
        if (isEFS) {
            params.isEFS = true;
        }
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_SHOW_EXPORTS}`,{params}).then(extractData);
    },
    listFolder: function (nfsServer, path, exportName, nfsVersion, isEFS, accessPoint, groupId) {
        const accountId = getAccountId();
        const params = {
            host: nfsServer,
            path,
            exportName,
            version: nfsVersion || undefined
        };
        if (isEFS) {
            params.isEFS = true;
            params.accessPoint = accessPoint;
        }
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_FOLDERS}`, {params})
            .then(() => ({nfsServer, path, groupId}));
    },
    showShares: function (cifsServer, username, password, domain, cifsVersion, groupId) {
        const accountId = getAccountId();
        let headers = {
            username,
            password
        };
        if (domain) {
            headers.domain = domain;
        }

        return axiosInstance.get(`/accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_CIFS_SHARES}`, {
            params: {
                host: cifsServer,
                version: cifsVersion || undefined
            },
            headers
        }).then(extractData);
    },
    listShareFolders: function (host, share, path, username, password, domain, cifsVersion, groupId) {
        const accountId = getAccountId();
        let headers = {
            username,
            password
        };
        if (domain) {
            headers.domain = domain;
        }
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_CIFS_SHARE_FOLDERS}`, {
            params: {
                host,
                share,
                path,
                version: cifsVersion || undefined
            },
            headers
        }).then(extractData);
    },
    fetchS3BasedBuckets: function (s3Based, osCredentials, provider, groupId) {
        const accountId = getAccountId();
        const payloadName = `PAYLOAD_LIST_${provider.toUpperCase()}_BUCKETS`;
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts[payloadName]}`, {
            params: {
                host: s3Based,
                port: osCredentials.port
            },
            headers: {
                "access-key": osCredentials.accessKey,
                "secret-key": osCredentials.secretKey
            }
        }).then(extractData);
    },
    listS3BasedBucketFolders: function (host, bucket, path, accessKey, secretKey, port, provider, groupId) {
        const accountId = getAccountId();
        const payloadName = `PAYLOAD_LIST_${provider.toUpperCase()}_BUCKETS_FOLDERS`;
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts[payloadName]}`, {
            params: {
                host,
                bucket,
                prefix: path,
                port

            },
            headers: {
                "access-key": accessKey,
                "secret-key": secretKey
            }
        }).then(extractData);
    },
    fetchBuckets: function (groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_BUCKETS}`).then(extractData);
    },
    listBucketFolders: function (groupId, bucket, prefix) {
        const accountId = getAccountId();
        const params = new URLSearchParams({prefix});
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_SUB_DIRECTORIES}?bucket=${bucket}&${params.toString()}`).then(extractData);
    },
    fetchAzureBlobContainers: function (storageAccountName, connectionString, groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_CONTAINERS}`, {
            params: {
                storageAccountName
            },
            headers: {
                'storage-account-connection-string': connectionString
            }
        }).then(extractData);
    },
    listContainerFolders: function (storageAccountName, connectionString, container, path, groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_CONTAINER_SUB_DIRECTORIES}`, {
            params: {
                storageAccountName,
                container,
                prefix:path !== "" ? path : undefined
            },
            headers: {
                'storage-account-connection-string': connectionString
            }
        }).then(extractData);
    },
    fetchAzureDataLakeContainers: function (storageAccountName, connectionString, groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_DATA_LAKE_CONTAINERS}`, {
            params: {
                storageAccountName
            },
            headers: {
                'storage-account-connection-string': connectionString
            }
        }).then(extractData);
    },
    listAzureDataLakeContainerFolders: function (storageAccountName, connectionString, container, path, groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_DATA_LAKE_CONTAINER_SUB_DIRECTORIES}`, {
            params: {
                storageAccountName,
                container,
                path:path !== "" ? path : undefined
            },
            headers: {
                'storage-account-connection-string': connectionString
            }
        }).then(extractData);
    },
    listGCPBuckets: function (groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_GCP_BUCKETS}`).then(extractData);
    },
    listGCPBucketFolders: function (bucket, path, groupId) {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_GCP_BUCKET_FOLDERS}`, {
            params: {
                bucket,
                prefix:path !== "" ? path : undefined
            }
        }).then(extractData);
    },
    listBoxRootFolders: (opts) => {
        const {groupId, clientId, clientSecret, publicKeyId, privateKey, passphrase, enterpriseId, appName} = opts;
        const accountId = getAccountId();
        // console.log(`%c privateKey ${privateKey}\n ${JSON.stringify(privateKey)}`, 'font-weight:bold; color:blue')
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_BOX_ROOT_FOLDER}`, {
            params : {
                appName, clientId, enterpriseId
            },
            headers: {
                "client-secret" : clientSecret,
                "public-key-id" : publicKeyId,
                "private-key" : JSON.stringify(privateKey),
                "passphrase" : passphrase,
            }
        }).then(extractData);

    },
    listBoxFolders: (opts) => {
        const {groupId, folderId, folderName, clientId, clientSecret, publicKeyId, privateKey, passphrase, enterpriseId, appName} = opts;
        const accountId = getAccountId();
        // console.log(`%c privateKey ${privateKey}\n ${JSON.stringify(privateKey)}`, 'font-weight:bold; color:red')
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_BOX_FOLDERS}`, {
            params: {
                folderId,
                folderName,
                appName,
                clientId, enterpriseId
            },
            headers: {
                "client-secret" : clientSecret,
                "public-key-id" : publicKeyId,
                "private-key" : JSON.stringify(privateKey),
                "passphrase" : passphrase,
            }
        }).then(extractData);

    },
    listGoogleDrives: (opts) => {
        const {groupId, privateKey, clientEmail, subject} = opts;
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_GOOGLE_DRIVES}`, {
            params : {
            },
            headers: {
                "client-email" : clientEmail,
                "subject" : subject,
                "private-key" : JSON.stringify(privateKey),
            }
        }).then(extractData);

    },
    listGoogleDriveFolders: (opts) => {
        const {groupId, folderId, folderName, driveId, privateKey, clientEmail, subject} = opts;
        const accountId = getAccountId();
        // console.log(`%c privateKey ${privateKey}\n ${JSON.stringify(privateKey)}`, 'font-weight:bold; color:red')
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_GOOGLE_DRIVE_FOLDERS}`, {
            params: {
                folderId,
                folderName,
                driveId
            },
            headers: {
                "client-email" : clientEmail,
                "subject" : subject,
                "private-key" : JSON.stringify(privateKey),
            }
        }).then(extractData);

    },
    listSftpRootDirectory: (opts) => {
        const {groupId, host, port, username, password} = opts;
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_SFTP_ROOT_DIRECTORY}`, {
            params : {
                host, port
            },
            headers: {
                username, password
            }
        }).then(extractData);

    },
    listSftpDirectories: (opts) => {
        const {groupId, path, host, port, username, password} = opts;
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/${consts.PAYLOAD_LIST_SFTP_DIRECTORIES}`, {
            params: {
                host,
                port,
                path,
                rootDirectory: '/'
            },
            headers: {
                username, password
            }
        }).then(extractData);

    },
    createSync: function (opts) {
        const config = { headers: {  "x-cm-integration": true } };
        return axiosInstance.post(`/relationships-v2`, opts, config).then(extractData);
    },
    syncNow: function (relationshipId) {
        return axiosInstance.put(`/relationships/${relationshipId}/sync`, {}).then(extractData);
    },
    abortSync: function (relationshipId) {
        return axiosInstance.put(`/relationships/${relationshipId}/abort`, {}).then(extractData);
    },
    getRelationships: function () {
        return axiosInstance.get("/relationships-v2").then(extractData);
    },
    deleteRelationship: function (relationships) {
        const accountId = getAccountId();
        const relationshipsQuery = reduce(relationships, (total, report) => {
            return !total? `relationships=${report}`: `${total}&relationships=${report}`
        }, null);
        
        return axiosInstance.delete(`/v2/accounts/${accountId}/relationships?${relationshipsQuery}`).then(extractData);
    },
    updateRelationship: function (relationshipId, opts) {
        return axiosInstance.put(`/relationships-v2/${relationshipId}`, opts).then(extractData);
    },
    updateCredentials: function(relationshipId, opts) {
        const accountId = getAccountId();
        return axiosInstance.put(`/accounts/${accountId}/relationships/${relationshipId}/credentials`, opts);
    },
    updateRelationshipName: function(relationshipId, opts) {
        const accountId = getAccountId();
        return axiosInstance.put(`/accounts/${accountId}/relationships/${relationshipId}/tags`, opts);
    },
    getLicenseSettings: function () {
        return axiosInstance.get("/accounts/license-settings").then(extractData);
    },
    getBYOLLicenses: function () {
        return axiosInstance.get("/accounts/licenses").then(extractData);
    },
    getNSSCredentials: function () {
        const accountId = getAccountId();
        return axiosInstance.get(`v2/accounts/${accountId}/nss-credentials`).then(extractData);
    },
    addBYOLLicense: function (values) {
        const accountId = getAccountId();
        return axiosInstance.put(`/accounts/${accountId}/licenses-v2`, values).then(extractData);
    },
    downloadDataBrokerLogs: (groupId) => {
        return axiosInstance.put(`/groups/${groupId}/download-data-brokers-logs`, {}, {maxContentLength: 0}).then(extractData);
    },
    listAwsKmsKeys: (groupId, bucket) => {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/list-aws-kms-keys`, {params: {bucket}}).then(extractData);
    },
    listS3PrivateLinks: (groupId, region, bucket) => {
        const accountId = getAccountId();
        return axiosInstance.get(`accounts/${accountId}/groups/${groupId}/list-s3-private-links`, {params: {region}, headers: {'x-bucket-name': bucket}}).then(extractData);
    },
    interceptors: function () {
        return axiosInstance.interceptors;
    },
    getDataBrokersGroups: () => {
        return axiosInstance.get(`/groups`).then(extractData);
    },
    getDataBrokerConfiguration: dataBrokerId => {
        const accountId = getAccountId();
        return axiosInstance.get(`/accounts/${accountId}/data-brokers/${dataBrokerId}/${consts.PAYLOAD_DATA_BROKER_LOCAL_CONFIGURATION}`).then(extractData)
    },
    editGroupName: function (groupId, newName) {
        const accountId = getAccountId();
        return axiosInstance.put(`accounts/${accountId}/groups/${groupId}/settings`, {name: newName}).then(extractData);
    },
    editGroupSettings: function (groupId, settings) {
        const accountId = getAccountId();
        return axiosInstance.put(`accounts/${accountId}/groups/${groupId}/settings`, settings).then(extractData);
    },
    getEndPointsAndReports: function() {
        const accountId = getAccountId();
        return axiosInstance.get(`/accounts/${accountId}/reports/end-points`).then(extractData)
    },
    createReport: (endpoint) => {
        const accountId = getAccountId();
        return axiosInstance.post(`/accounts/${accountId}/reports`, {endpoint}).then(extractData);
    },
    deleteReports: (reports) => {
        const accountId = getAccountId();
        const reportsString = reduce(reports, (total, report) => {
            return !total? `reports=${report}`: `${total}&reports=${report}`
        }, null)
        // console.log(`%c reportsString ${reportsString}`, 'font-weight:bold;color:orange')
        return axiosInstance.delete(`/accounts/${accountId}/reports?${reportsString}`).then(extractData);
    },

};

export default sdk;
