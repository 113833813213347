import React, {useState, useCallback} from "react";
import RightPanelLayout from '../rightPanel/RightPanelLayout';
import {useDispatch} from "react-redux";
import {destroy} from "redux-form";
import DataBrokerDetails from "./DataBrokerDetails";
import {DATA_BROKER_WIZARD_STEPS as STEPS, DOCUMENTATION_DATA_BROKER, FM_AGENT_TYPE_ONPREM} from '../../consts';
import InstanceDetails from "./InstanceDetails";
import {TooltipInfo} from '@netapp/shared-components';
import {INSTANCE_DETAILS_FORM} from "../../consts";
import "./dataBrokerWizard.scss";

const typeNamesMap = {
    "AWS": "AWS",
    "AZURE": "Azure",
    "GCP": "Google Cloud"
};

const buildRightPanelTitle = (titleAddition, existingGroupId, fromGroups, groupName) => {
    if (fromGroups) {
        return groupName ? <div>Add Data Broker to "{groupName}"</div>: <div>Create Data Broker</div>;
    } else {
        return existingGroupId
            ? <>
                <div>Accelerate relationship by adding a Data Broker {titleAddition}</div>
                <TooltipInfo>If the existing data brokers in this relationship are also used in other sync relationships,
                    then we’ll automatically add this new data broker to those relationships, as well.</TooltipInfo></>
            : `Create Data Broker ${titleAddition}`;
    }
};

const DataBrokerWizard = (props) => {
    const {closePanel, isGcpAccessRequired, isAwsAccessRequired, shouldDisplayPortBool, existingGroupId, allowedAgentType, fromManageGroups, groupName} = props;
    //wizard forms has destroyOnUnmount flag set to "false" (to support back navigation)
    //hence need to destroy them upon closing panel
    const dispatch = useDispatch();
    const closePanelAndDestroyForms = useCallback(() => {
        dispatch(destroy(INSTANCE_DETAILS_FORM));
        dispatch(destroy('providerLoginAws'));
        closePanel();
    }, [dispatch, closePanel]);

    const [step, setStep] = useState(STEPS.DATA_BROKER_DETAILS);
    const [type, setType] = useState(null);
    const [dataBroker, setDataBroker] = useState({});

    const titleAddition = (step > STEPS.DATA_BROKER_DETAILS && type !== FM_AGENT_TYPE_ONPREM.name) ? `in ${typeNamesMap[type]}` : "";

      const rightPanelTitle = buildRightPanelTitle(titleAddition, existingGroupId, fromManageGroups, groupName);

    const goToNextStep = useCallback(() => {
        setStep(step => (step + 1 >= STEPS.length) ?  step : step + 1);
    }, []);


    return <RightPanelLayout
        title={rightPanelTitle}
        closePanel={closePanelAndDestroyForms}
        customClass="data-broker-wizard"
        helpUrl={DOCUMENTATION_DATA_BROKER}
    >

        {step === STEPS.DATA_BROKER_DETAILS &&
        <DataBrokerDetails
            goToNextStep={goToNextStep}
            type={type}
            setType={setType}
            isGcpAccessRequired={isGcpAccessRequired}
            isAwsAccessRequired={isAwsAccessRequired}
            shouldDisplayPortBool={shouldDisplayPortBool}
            defaultProvider={allowedAgentType}
            setDataBroker={setDataBroker}
            existingGroupId={existingGroupId}
            existingGroupName={groupName}
            fromManageGroups={fromManageGroups}
        />}

        {step === STEPS.INSTANCE_DETAILS &&
        <InstanceDetails
            type={type}
            dataBroker={dataBroker}
            closePanel={closePanelAndDestroyForms}
            isGcpAccessRequired={isGcpAccessRequired}
            isAwsAccessRequired={isAwsAccessRequired}
            isAccelerate={!!existingGroupId && !fromManageGroups}
        />}
    </RightPanelLayout>
};

export default DataBrokerWizard;
