import React, {useMemo} from 'react';
import Validator from 'validatorjs';
import {Form, Field} from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import {InputField, SelectField} from '../../_common_/forms/forms';
import {Button} from '@netapp/shared-components';
import SmallLoader from '../../_common_/SmallLoader/SmallLoader';
import map from "lodash/map";
import {ButtonBase} from '@netapp/shared-components';
import {navigateToAddNssCredentials} from "../../../utils/cm-integration-utils";
import {getNSSCredentials, addBYOLLicense} from "../../../store/global/action_creators";
import {connect} from 'react-redux';
import useRunOnce from "../../../hooks/useRunOnce";

require("./addBYOLLicense.scss");

const rules = {
    serialNumber: 'required',
    nssKeyId: 'required'
};

const validate = (values) => {
    const validator = new Validator(values, rules);
    validator.setAttributeNames({serialNumber: 'serial number', nssKeyId: 'NSS user name'});
    validator.passes();
    return validator.errors.all();
};

const AddBYOLLicense = (props) => {
    const {onComplete, isFirstLicense, nssCredentials} = props;
    const {addBYOLLicense, getNSSCredentials} = props;
    const nssCredentialsExist = !!(nssCredentials?.length);

    useRunOnce(() => {
        getNSSCredentials();
    });

    const nssCredentialsOptions = useMemo(() => {
        return map(nssCredentials, ({nssKeyId, username}) => ({value: nssKeyId, label: username}));
    }, [nssCredentials]);

    const submit = (values) => {
        const handleError = (error) => {
            if (error.status !== 401) {
                return Promise.resolve({[FORM_ERROR]: error.data.message ? error.data.message : error.data});
            }

        };
        const addLicense = () => {
            const params = {serialNumber: values.serialNumber, nssKeyId: values.nssKeyId.value};
            return addBYOLLicense(params)
                .then(onComplete)
                .catch(handleError)
        };
        return addLicense();
    };

    return (
        <div className="add-license">
            <div>
                {!isFirstLicense &&
                <h5>Add License</h5>}
                <div className="add-nss">
                    {nssCredentials && !nssCredentialsExist &&
                    <div>In order to add BYOL License you need to have NSS Credentials</div>}
                    <ButtonBase type="button" onClick={navigateToAddNssCredentials}>Add NSS Credentials</ButtonBase>
                </div>

                <Form onSubmit={submit} validate={validate}>
                    {({handleSubmit, submitting, submitError:error, form}) => {
                        const formState = form.getState();
                        const {modifiedSinceLastSubmit} = formState;

                        return (<form noValidate onSubmit={handleSubmit} autoComplete="new-password">
                            <div className="license-row">
                                {!nssCredentials && <SmallLoader/>}
                                {nssCredentials && nssCredentialsExist && <div className="serial-number-container">
                                    <div className="serial-number">
                                        <Field name="serialNumber"
                                               className="serial-number"
                                               component={InputField} label="License Serial Number"
                                               autoComplete="off"
                                               info="You should have received the license serial number when you purchased the license."/>
                                    </div>
                                    <div className="nss-credentials-select">
                                        <Field name="nssKeyId"
                                               className="nss-credentials"
                                               component={SelectField}
                                               options={nssCredentialsOptions}
                                               isLoading={!nssCredentialsOptions}
                                               label="NSS User Name"/>
                                    </div>
                                </div>}
                                {nssCredentials && nssCredentialsExist && <div className="buttons">
                                    <Button type="submit" variant="primary" loading={submitting}>Add</Button>
                                    <Button type="button" variant="secondary" onClick={onComplete}>Cancel</Button>
                                </div>}
                            </div>
                            {error && !modifiedSinceLastSubmit && <div className="page-error">{error}</div>}
                        </form>)
                    }}

                </Form>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    nssCredentials: state.global._nssCredentials.data
});

export default connect(mapStateToProps, {addBYOLLicense, getNSSCredentials})(AddBYOLLicense)

