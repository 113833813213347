import React, {useState} from "react";
import {FM_AGENT_TYPE_GCP} from '../../consts';
import ProviderLoginLayout from './ProviderLoginLayout';
import * as gcpAuthService from '../../services/gcp.auth.service';
import useRunOnce from '../../hooks/useRunOnce';
import {extractErrorGcp} from '../../utils/providers-utils';

const ProviderLoginGcp = ({handleContinue, setStepError}) => {
    const [submitting, setSubmitting] = useState(false);

    const doLogin = async () => {
        setStepError(null);
        setSubmitting(true);
        try {
            const isLoggedIn = await authenticateWithGoogle();
            if (isLoggedIn) {
                handleContinue();
            } else {
                setSubmitting(false);
            }
        }
        catch (e) {
            setStepError(extractErrorGcp(e));
            setSubmitting(false);
        }
    };

    useRunOnce(() => {
        doLogin();
    });

    return <ProviderLoginLayout
        title={`Log In to ${FM_AGENT_TYPE_GCP.providerName}`}
        subtitle={<p>Click <b>Continue</b> to log in to your {FM_AGENT_TYPE_GCP.providerName} account using a form that is owned and hosted by {FM_AGENT_TYPE_GCP.company}.
            Your credentials are not provided to NetApp.</p>}
        providerIcon={FM_AGENT_TYPE_GCP.circledIcon}
        handleClick={doLogin}
        submitting={submitting}
    />
};

export default ProviderLoginGcp;

const authenticateWithGoogle = async () => {
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_SIMULATE_API === "true"){
        return 'token';
    } else {
        await gcpAuthService.init();
        return gcpAuthService.getToken();
    }
};
