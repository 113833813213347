import {useState} from 'react'
import {ReactComponent as FilterIcon} from "../../../assets/svgs/filter.svg";
import FilterByListComp from "./FilterByListComp";
import {some} from 'lodash'
import './filterWidget.scss'

const FilterWidget = ({title, filterName, filters, applyFilter, sort, className}) => {
    const [state, setState] = useState({})
    const isFilterByActive = filters ? some(filters, filterValue => !filterValue.checked) : false;

    return <div className="filter-widget-container">
        <button type="button"
                className={`filter-button ${isFilterByActive ? "active" : ""} ${className || ''}`}
                onClick={() => setState({
                    [filterName]: {filterOpen: true}
                })}>
            {title && <span>{title}</span>}
            <FilterIcon/>
        </button>
        {state[filterName]?.filterOpen &&
        <FilterByListComp filterValues={filters}
                          close={() => setState({
                              [filterName]: {filterOpen: false}
                          })}
                          apply={(filterList) => {
                              applyFilter(filterName, filterList, sort)
                              setState({
                                  [filterName]: {filterOpen: false}
                              })

                          }}
        />}</div>
}

export default FilterWidget;
