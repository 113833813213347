import './aclMultiSelection.scss';
import React from 'react';
import { Field } from 'redux-form';
import { SelectField } from '../../_common_/forms/forms';
import { aclOptions, COPY_ACL_OPTION, ACL_PLACEHOLDER } from '../../../utils/relationshipUtils';

const AclMultiSelection = () => {
    return (
        <div className="aclMultiSelection">
            <Field component={SelectField}
                valueField="value"
                name={COPY_ACL_OPTION}
                maxMenuHeight={200}
                options={aclOptions}
                isSearchable={false}
                placeholder={ACL_PLACEHOLDER}
            />
        </div>
    )
}

export default AclMultiSelection;