import React from 'react';
import classNames from 'classnames';
import { getPropFromState } from '../../utils/mapStateToPropsUtils';
import { connect, useDispatch } from 'react-redux';
import { SERVICE_NAME } from "../../consts";
import { ReactComponent as SyncIcon } from "../../assets/svgs/sync-cm.svg";
import { ReactComponent as XIcon } from "../../assets/svgs/x.svg";
import DataSenseInfo from "./DataSenseInfo";
import { DashboardTabs, CmHeaderRightSide } from "./CmDashboardTabs"
import { setCmIntegrationDetails } from '../../store/global/action_creators';
import { ROUTES } from '../../consts';
import "./cmDashboardTabs.scss";
import { useLocation, Link } from 'react-router-dom';

require("./cmHeader.scss");

const CmHeader = (props) => {
    const { pathname } = useLocation();

    const { showCmTabs, _cmIntegration } = props;
    const isDashboard = showCmTabs;
    const isDragDrop = pathname === `/${ROUTES.DRAG_SOURCE_AND_TARGET}`;
    const isWizard = pathname.startsWith("/sync");

    const dispatch = useDispatch();

    const routeToDashboard = pathname => {
        setCmIntegrationDetails({
            general: {
                routingParams: {
                    pathname
                }
            }
        }
        )(dispatch);
    };

    return (<div className={classNames("cm-header", { dashboard: isDashboard })}>
        <div className="header-content">
            <div className="title">
                {isDashboard && <SyncIcon />}
                {isDashboard && <span id={"service"}>
                    {SERVICE_NAME}
                </span>}
                {!isDashboard && <span id={"wizardTitle"}>Sync Relationship</span>}
            </div>
            <div className="middle">
                {isDragDrop && <div className="header-name">Define Sync Relationship</div>}
                {isDashboard && <DashboardTabs />}
            </div>
            <div className="close">
                {isDashboard && <CmHeaderRightSide />}
                {(isWizard || isDragDrop) && <Link to={`/${ROUTES.DASHBOARD}?fromCmIntegration=true`} onClick={() => routeToDashboard(ROUTES.DASHBOARD)}><span><XIcon /></span></Link>}
            </div>
            {_cmIntegration.dataSense && !isDashboard && <DataSenseInfo fromHeader={true} />}
        </div>
    </div>)
};

const mapStateToProps = (state) => ({
    _wizard: getPropFromState(state, "_wizard", null),
    _cmIntegration: state.global._cmIntegration
});

export default connect(mapStateToProps)(CmHeader);
