import React, { FunctionComponent } from "react";
import './buttonsPanel.scss';
import { Button } from "@netapp/design-system";
//@ts-ignore
import { Button as OldSyncButton } from '@netapp/shared-components';

interface ButtonPanel {
    className?: string,
    variant?: 'primary' | 'secondary' | 'icon' | 'link' | 'text',
    isThin?: boolean,
    isDisabled?: boolean,
    isHovered?: boolean,
    isLoading?: boolean,
    children: React.ReactNode,
    title?: React.ReactNode,
    onClick: () => void
}
interface ButtonsPanelProps {
    className?: string,
    isOldSyncStyle: boolean,
    buttons: ButtonPanel[]
}

const ButtonsPanel: FunctionComponent<ButtonsPanelProps> = ({ buttons, className, isOldSyncStyle }) => {

    const buttonNewOld = (button: ButtonPanel) => {
        const { children, isDisabled, isHovered, isLoading, isThin, title, variant, onClick } = button;

        if (!isOldSyncStyle) {
            return (
                <Button variant={variant} isDisabled={isDisabled} isHovered={isHovered} isLoading={isLoading} isThin={isThin} title={title}
                    onClick={onClick} >
                    {children}
                </Button>
            )
        } else {
            return (
                <OldSyncButton variant={variant} disabled={isDisabled} loading={isLoading} type="button" onClick={onClick}>
                    {children}
                </OldSyncButton>
            )
        }
    }

    return (
        <div className={`buttonsPanel ${className || ''}`}>
            {buttons.map(button => {
                return buttonNewOld(button);
            })}
        </div>
    )
}

export default ButtonsPanel;