import React, {useCallback} from "react";
import useGcpForm from './useGcpForm';
import SelectReduxField from '../_common_/forms/SelectReduxField';
import {DOCUMENTATION_DEPLOY_GCP_PERMISSIONS, ENABLE_DISABLE_OPTIONS} from '../../consts';
import Validator from 'validatorjs';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import _ from "lodash";
import InstanceDetailsColumnHeader from './InstanceDetailsColumnHeader';
import ButtonsPanel from '../widgets/ButtonsPanel';
import {INSTANCE_DETAILS_FORM} from "../../consts";

import "./instanceDetailsGcpForm.scss";

const InstanceDetailsGcpForm = ({handleContinue, handleError, change, selectedProjectId, selectedVpc, selectedRegion, handleSubmit, submitting}) => {

    const {projects, serviceAccounts, regions, vpcs, zones, subnets} = useGcpForm(selectedProjectId, selectedVpc, selectedRegion, handleError);

    const clearFields = useCallback((fieldArr) => fieldArr.forEach(fieldName => change(fieldName, '')), [change]);

    return (<form className="data-broker-wizard-step instance-details-gcp" onSubmit={handleSubmit(values => handleContinue(extractValues(values)))}>
        <div className="title">Basic Settings</div>
        <div className="content-area">
            <div className="form-content">
                <div className="col left">
                    <InstanceDetailsColumnHeader text={"Project"}/>
                    <SelectReduxField fieldName="project"
                                      data={projects}
                                      propertyToDisplay="name"
                                      placeholder="Select a project"
                                      label="Project"
                                      displayName="Project"
                                      isDisabled={submitting}
                                      handleChangeFunction={() => clearFields(['serviceAccount', 'region', 'zone', 'VPC', 'subnet'])}/>

                    <SelectReduxField fieldName="serviceAccount"
                                      data={serviceAccounts}
                                      propertyToDisplay="displayName"
                                      isDisabled={!selectedProjectId || submitting}
                                      disabledTitle="Waiting for a project"
                                      placeholder="Select a service account"
                                      label="Service Account"
                                      displayName="Service Account"/>
                    <span className="permissions-label">Select a Service Account that includes
                                  <a href={DOCUMENTATION_DEPLOY_GCP_PERMISSIONS}
                                     target="_blank" rel="noopener noreferrer"> these permissions</a> </span>

                </div>
                <div className="col right">
                    <InstanceDetailsColumnHeader text={"Location"}/>
                    <SelectReduxField fieldName="region"
                                      data={regions}
                                      propertyToDisplay="name"
                                      isDisabled={!selectedProjectId || submitting}
                                      disabledTitle="Waiting for a project"
                                      placeholder="Select a region"
                                      label="Region"
                                      displayName="Region"
                                      handleChangeFunction={() => clearFields(['zone', 'subnet'])}/>

                    <SelectReduxField fieldName="zone"
                                      data={zones}
                                      propertyToDisplay="displayName"
                                      isDisabled={!selectedRegion || submitting}
                                      disabledTitle="Waiting for a region"
                                      placeholder="Select a zone"
                                      label="Zone"
                                      displayName="Zone"/>

                    <SelectReduxField fieldName="VPC"
                                      data={vpcs}
                                      propertyToDisplay="displayName"
                                      isDisabled={!selectedProjectId || !selectedRegion || submitting}
                                      disabledTitle="Waiting for a project and a region"
                                      placeholder="Select a VPC"
                                      label="VPC"
                                      displayName="VPC"
                                      handleChangeFunction={() => clearFields(['subnet'])}/>

                    <SelectReduxField fieldName="subnet"
                                      data={subnets}
                                      propertyToDisplay="displayName"
                                      isDisabled={!selectedVpc || submitting}
                                      disabledTitle="Waiting for a VPC"
                                      placeholder="Select a subnet"
                                      label="Subnet"
                                      displayName="subnet"/>
                    <SelectReduxField fieldName="publicIp"
                                      data={ENABLE_DISABLE_OPTIONS}
                                      propertyToDisplay="label"
                                      placeholder="Enable or Disable public IP"
                                      label="Public IP"
                                      displayName="Public IP"/>
                </div>
            </div>
        </div>

        <ButtonsPanel
            variant="transparent"
            type="submit"
            text="Continue"
            submitting={submitting}/>

    </form>);
};

const validate = (values) => {
    const rules = {
        project: ['required'],
        serviceAccount: ['required'],
        region: ['required'],
        zone: ['required'],
        VPC: ['required'],
        subnet: ['required'],
        publicIp: ['required']
    };

    const validator = new Validator(values, rules);
    validator.passes();
    return validator.errors.all();
};

const connectedToForm = reduxForm({
    form: INSTANCE_DETAILS_FORM,
    validate,
    destroyOnUnmount: false
})(InstanceDetailsGcpForm);

const selector = formValueSelector(INSTANCE_DETAILS_FORM);

export default connect(state => ({
    selectedProjectId: _.get(selector(state, 'project'), 'value'),
    selectedVpc: selector(state, 'VPC'),
    selectedRegion: selector(state, 'region')
}))(connectedToForm);

const extractValues = ({region, zone, VPC, subnet, serviceAccount, project, publicIp}) => ({
    region: region.value,
    zone: zone.value,
    network: VPC.value,
    subnetwork: subnet.value,
    serviceAccount: serviceAccount.value,
    projectId: project.value,
    enablePublicIP: publicIp.value
});
