import React from "react";
import {ReactComponent as CloseIcon} from "../../assets/svgs/notification-close.svg";

import "./rightPanelLayout.scss";

const RightPanelLayout = ({title, helpUrl, closePanel, customClass, children}) => <div className={`right-panel-layout ${customClass ? customClass : ""}`}>
    <div className="right-panel-layout-header">
        <div className="right-panel-layout-header-title">{title}</div>
        <div className="right-panel-layout-header-help"><a className="text-button" href={helpUrl} target="_blank" rel="noopener noreferrer">Need Help?</a></div>
        <button type="button" className="right-panel-layout-header-close" onClick={closePanel} title="Close">
            <CloseIcon/>
        </button>
    </div>
        {children}
</div>;

export default RightPanelLayout;
