import React, {useState, useCallback, useEffect, useRef} from "react"
import classNames from "classnames"
import ReactDOM from "react-dom";
import {parentIsComparator} from "./../../utils/dom";
import useEvent from "../../hooks/useEvent";
import {ReactComponent as SearchIcon} from "./../../assets/svgs/magnifying-glass.svg";
import {ReactComponent as XIcon} from "./../../assets/svgs/x.svg";
import {clearCloseSearchWidget} from "../../store/wizard/action_creators";
import {connect} from 'react-redux';
require("./searchWidget.scss");

const SearchWidget = (props) => {
    const {clearCloseSearchWidget, setFilter, _ui, className} = props
    const [isOpen, setIsOpen] = useState(false)
    const classes = classNames(className, "search-widget", {open: isOpen});

    const widgetRef = useRef();
    const inputRef = useRef();

    const open = useCallback(() => {
        setIsOpen(true);
        ReactDOM.findDOMNode(inputRef.current).value = "";
        setTimeout(() => { ReactDOM.findDOMNode(inputRef.current).focus(); })
    }, [setIsOpen])

    const closeListener = (event) => {
        if(!isOpen || !inputRef.current || inputRef.current.value || parentIsComparator(event.target, widgetRef.current)) return;
        close();
    };
    useEvent("click", closeListener)

    const applyFilter = useCallback((filterValue) =>{
        setFilter(filterValue);
    }, [setFilter])

    const handleChange = (event) =>{
        applyFilter(event.target.value);
    }
    const close = useCallback(() => {
        applyFilter(null);
        setIsOpen( false);
    }, [applyFilter, setIsOpen])


    useEffect(() => {
        if (_ui && _ui.closeSearchWidget && isOpen) {
            close();
            clearCloseSearchWidget();
        }
    }, [_ui, close, clearCloseSearchWidget, isOpen])


    return (
        <div className={classes} ref={widgetRef}>
            <button type="button" className="open" onClick={open}> <span><SearchIcon/></span></button>
            <input autoFocus ref={inputRef} onChange={handleChange} placeholder="You can search by all visible fields"/>
            <button type="button" className="close float-right" onClick={close}> <span><XIcon/></span></button>
        </div>
    )
};

const mapStateToProps = (state) => ({
    _ui: {...state.syncReducer._ui, ...state.global._ui}
});

export default connect(mapStateToProps, {clearCloseSearchWidget})(SearchWidget)
