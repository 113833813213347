import React from "react";
import {connect, useDispatch} from 'react-redux';
import {Popover} from '@netapp/shared-components';
import { cmNavigateTo } from "../../utils/cm-integration-utils";
import { setCmIntegrationDetails } from "../../store/global/action_creators";
import { ROUTES } from "../../consts";
import "./tabHeader.scss";
import { NavLink } from "react-router-dom";

const TabHeader = ({_routing: {location: {query}}, _ui: {blocked: isUiBlocked}}) => {
    const dispatch = useDispatch();
    if (query.noTab !== undefined) return (<div/>);

    const navigateMainFrame = pathname => {
        cmNavigateTo(`/${ROUTES.SYNC}${pathname ? `/${pathname}` : ''}`, null, {});
        setCmIntegrationDetails({
            general: {
                routingParams: {
                    pathname
                }
            }
        }
        )(dispatch);
    };

    return (
        <div className="container">
            {!isUiBlocked && <NavLink to={`/${ROUTES.DASHBOARD}`} className="link" onClick={() => navigateMainFrame('')}>Dashboard</NavLink>}
            {!isUiBlocked && <NavLink to={`/${ROUTES.REPORTS}`} className="link" onClick={() => navigateMainFrame(ROUTES.REPORTS)}>Reports</NavLink>}
            {isUiBlocked &&  <Popover trigger="hover" popoverClass="text-popover"
                                      container={<div className="link is-disabled">{"Dashboard"}</div>}>
                The free trial of 14 days ended. To continue using this service you have 2 options:
                NetApp BYOL License & Provider Subscription (AWS or Azure)
            </Popover>}
            <NavLink to={`/${ROUTES.LICENSE}`} className="link" onClick={() => navigateMainFrame(ROUTES.LICENSE)}>Licensing</NavLink>
            <NavLink to={`/${ROUTES.MANAGE}`} className="link" onClick={() => navigateMainFrame(ROUTES.MANAGE)}>Manage Data Brokers</NavLink>
        </div>
    )
};

const mapStateToProps = state => ({
    _routing: state.syncReducer._routing,
    _ui: state.global._ui
});
export default connect(mapStateToProps)(TabHeader)
