import React from "react";
import {Button} from '@netapp/shared-components';
import "./buttonsPanel.scss";

//variant: white / transparent
const ButtonsPanel = (props) => {
    const { variant = "white", disable, text, moreInfo, submitting = false, onCancel, onClick, type, ...buttonProps } = props;

    return <div className={`button-with-notification ${variant}`}>
        {moreInfo && <div className="more-info">{moreInfo}</div>}
        <Button variant="primary" disabled={disable} loading={submitting} type={type || "button"} onClick={onClick} {...buttonProps}>
            {text}
        </Button>
        {onCancel &&
            <Button variant="secondary" className="secondary-button" disabled={disable || submitting} type="button" onClick={onCancel} {...buttonProps}>
                Cancel
            </Button>}
    </div>
};

export default ButtonsPanel;
