export const parentHasClass = (node, className) => {
    return (node.classList && node.classList.contains(className)) || (node.parentNode && parentHasClass(node.parentNode, className));
};

export const parentIsComparator = (node, comparator) => {
    return (node === comparator) || (node.parentNode && parentIsComparator(node.parentNode, comparator));
};

export const clickRoot = () => {
    const rootElement = document.getElementById("root");
    rootElement.click();
};