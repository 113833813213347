import React from "react";
import {FM_AGENT_TYPE_AWS, FM_AGENT_TYPE_AZURE, FM_AGENT_TYPE_GCP, FM_AGENT_TYPE_ONPREM} from '../../consts';
import InstanceDetailsAzureForm from './InstanceDetailsAzureForm';
import InstanceDetailsOnprem from './InstanceDetailsOnprem';
import InstanceDetailsAwsForm from './InstanceDetailsAwsForm';
import InstanceDetailsGcpForm from './InstanceDetailsGcpForm';
import {extractErrorAws, extractErrorAzure, extractErrorGcp} from '../../utils/providers-utils';

const InstanceSettings = props => {
    const {type, setStepError, onContinue, credentials, dataBroker, isGcpAccessRequired, isAwsAccessRequired} = props;
    return (<>
        {type === FM_AGENT_TYPE_AWS.name && <InstanceDetailsAwsForm handleContinue={onContinue} handleError={(error) => setStepError(extractErrorAws(error))} credentials={credentials.aws}/>}
        {type === FM_AGENT_TYPE_AZURE.name && <InstanceDetailsAzureForm handleContinue={onContinue} handleError={(error) => setStepError(extractErrorAzure(error))} dataBrokerName={dataBroker.name}/>}
        {type === FM_AGENT_TYPE_GCP.name && <InstanceDetailsGcpForm handleContinue={onContinue} handleError={(error) => setStepError(extractErrorGcp(error))}/>}
        {type === FM_AGENT_TYPE_ONPREM.name && <InstanceDetailsOnprem handleContinue={onContinue} dataBroker={dataBroker} isGcpAccessRequired={isGcpAccessRequired} isAwsAccessRequired={isAwsAccessRequired}/>}
    </>)
};

export default InstanceSettings;
