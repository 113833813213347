import React from "react";
import {getClassBySyncStatus, getSVGBySyncStatus, noConnectionToAllBrokers} from "../../../utils/dashboard-utils";
import {connect} from "react-redux";
import SyncStatistics from './SyncStatistics';
import {
    RESPONSE_STATUS_ABORTED,
    RESPONSE_STATUS_FAILED,
    RESPONSE_STATUS_PENDING, RESPONSE_STATUS_RUNNING,
    UNKNOWN_SYNC_STATUS,
    STATUS_CONTINUOUSLY_SYNCING
} from "../../../consts";
import _ from "lodash";
import {downloadDataBrokerLogs} from '../../../store/wizard/action_creators';
import {TooltipInfo} from '@netapp/shared-components';
import {ReactComponent as TimeIcon} from "./../../../assets/svgs/time-estimation.svg";
import RelationshipErrorsDialog from '../../errorsDialog/RelationshipErrorsDialog';
import {useDialog, ButtonBase} from "@netapp/shared-components"
import DataSenseInfo from "../../cmIntegration/DataSenseInfo";
import { ReactComponent as TriangleIcon } from "./../../../assets/svgs/exclamation-mark-triangle-icon.svg";

const SyncInfoHeader = ({data, showUnknown, isDataSense}) => {
    const {
        extraInformation,
        ui: {
            status, statusText, progress, pendingSyncStartTime, estimatedTimeText, copyDoneByCloudProvider
        },
        activity: {syncType}
    } = data;
    const syncStatusClass = getClassBySyncStatus(status, showUnknown);
    return (
        <div>
            {!showUnknown && <div className="sync-info-header">
                <div className={"sync-status " + syncStatusClass}>
                    <div className="status-icon">{getSVGBySyncStatus(status)}</div>
                    <div className="status-info">
                        <div className="status-text">
                            <div>{statusText}</div>
                            {syncType === 'copy-acl' && <div className="aclCopy small-text">
                                <span> - </span>
                                &nbsp;<TriangleIcon  className='aclNotification'/>&nbsp;
                                <div>ACL only</div>
                            </div>}
                            {!_.isEmpty(progress) && <div className="small-text" title={progress}>{progress}</div>}
                            {status === RESPONSE_STATUS_PENDING && !_.isNil(pendingSyncStartTime) &&
                            <TooltipInfo> The first sync for this relationship is scheduled to start at {pendingSyncStartTime}.</TooltipInfo>}
                        </div>
                    </div>
                </div>
                {!copyDoneByCloudProvider && estimatedTimeText && <div className="estimated-time">
                    <div className="estimated-time-text">{estimatedTimeText}</div>
                    <TimeIcon/> </div>}
                {isDataSense && <DataSenseInfo dataSenseQueryUid={extraInformation.dataSenseQueryUid} allowNavigation={true}/>}
            </div>}
        </div>
    )
};

const SyncInfo = (props) => {
    const {data, calledFrom} = props;
    const { ui: { status, failureMessage, activityValues, isDataSense}, activity: {errors}} = data;
    const {setDialog} = useDialog();
    const {dataBrokers} = data.groupInfo || {};
    const showUnknown = [RESPONSE_STATUS_RUNNING, STATUS_CONTINUOUSLY_SYNCING].includes(status) && noConnectionToAllBrokers(dataBrokers);
    if (!activityValues.scan) return <div/>;

    const syncFailedMessage = <>
        <div>There are no values to display on this relationship because it failed to sync.</div>
        <div>Check the error details and try again.</div>
        <div className="view-details">
            <div>{errors.length > 1 ? `${errors.length} errors.` : "1 error."}</div>
            <ButtonBase type="button" className="underline-button" onClick={(el) => setDialog(<RelationshipErrorsDialog errors={errors}/>, el.target)}>
                View Details
            </ButtonBase>
        </div>
    </>;

    const showFailureMessage = status === RESPONSE_STATUS_FAILED || status === RESPONSE_STATUS_ABORTED;
    const abortOrFailureMessage = status === RESPONSE_STATUS_FAILED && errors?.length ? syncFailedMessage : failureMessage;

    return (
        <div className="sync-info-wide">
            <SyncInfoHeader data={data} showUnknown={showUnknown} isDataSense={isDataSense}/>
            {showFailureMessage &&
            <div className="sync-info-failure">
                <div className="failure-message">
                    {abortOrFailureMessage}
                </div>
            </div>
            }
            {showUnknown &&
            <div className="sync-info-failure no-border">
                <div className="failure-message">
                    {UNKNOWN_SYNC_STATUS}
                </div>
            </div>
            }
            {!showUnknown && !showFailureMessage &&
            <SyncStatistics relationship={data} isCardView={true} calledFrom={calledFrom} {...props} />
            }
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        _downloadLogsRequests: state.syncReducer._downloadLogsRequests
    }
};

export default connect(mapStateToProps, {downloadDataBrokerLogs})(SyncInfo)
