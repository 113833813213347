import React, {useState} from "react";
import {selectText} from '../../utils/helpers';
import {DOCUMENTATION_DATA_BROKER_ONPREM} from '../../consts';
import {Select} from '@netapp/shared-components';
import _ from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";
import {ReactComponent as CopyIcon} from "../../assets/svgs/copy-to-clipboard.svg";

import "./onPremInstallationInstructions.scss";

const OnPremInstallationInstructions = ({dataBroker, isGcpAccessRequired, isAwsAccessRequired}) => {
    const {fileLink} = dataBroker;

    const [withAwsAccess, setWithAwsAccess] = useState(isAwsAccessRequired);
    const [withGcpAccess, setWithGcpAccess] = useState(isGcpAccessRequired);
    const [proxyConfig, setProxyConfig] = useState("NO_PROXY");
    const [withCertificate, setCertificate] = useState(false);

    const installerName = "data_broker_installer.sh";

    const proxySuffix =   proxyConfig === "PROXY_WITH_AUTH" ? " -x <proxy_username>:<proxy_password>@<proxy_host>:<proxy_port>" :
        proxyConfig === "PROXY" ? " -x <proxy_host>:<proxy_port>": "";
    const downloadCommand = `curl ${fileLink} -o ${installerName}${proxySuffix}`;

    const installCommandBase = `sudo -s\nchmod +x ${installerName}\n./${installerName}${withAwsAccess ? " -a <aws_access_key> -s <aws_secret_key>" : ""}${withGcpAccess ? " -g <absolute_path_to_the_json_file>" : ""}${withCertificate ? " -c <absolute_path_to_the_certificate>"
    : ""}`;
    const installCommandWithSuffix = `${installCommandBase}${proxyConfig.startsWith("PROXY") ? " -h <proxy_host> -p <proxy_port>" : ""}${proxyConfig === "PROXY_WITH_AUTH" ? " -u <proxy_username> -w <proxy_password>" : ""}`;

    const installerCommandWithLogFile = `${installCommandWithSuffix} > data_broker_installer.log`;
    const proxyOptions = ([{label:"No proxy", value: "NO_PROXY"}, {label:"Use proxy server", value: "PROXY"}, {label:"Use proxy server with authentication", value: "PROXY_WITH_AUTH"}]);


    return <div className="installation-instructions">
        <div className="section">
            <a href={DOCUMENTATION_DATA_BROKER_ONPREM} target="_blank" rel="noopener noreferrer">Verify host and networking requirements in the docs</a>
        </div>

        <div className="section section-access">
            <div className="enable-access-header">Enable access to:</div>
            <label htmlFor="awsAccess" title={isAwsAccessRequired ? "AWS access is required for the current relationship.": ""}>
                <input id="awsAccess" type="checkbox" value="aws" disabled={isAwsAccessRequired}
                       checked={withAwsAccess}
                       onChange={()=>{setWithAwsAccess(!withAwsAccess)}}/>AWS
            </label>

            <label htmlFor="gcpAccess" title={isGcpAccessRequired ? "Google Cloud access is required for the current relationship.": ""}>
                <input id="gcpAccess" type="checkbox" value="gcp" disabled={isGcpAccessRequired}
                       checked={withGcpAccess}
                       onChange={()=>{setWithGcpAccess(!withGcpAccess)}}/>Google Cloud</label>
            <div className="note">Enabling cloud provider access is important if you might use this data broker for any relationships that include
                an S3 bucket or Google Cloud Storage bucket. Access to Azure is defined per relationship by providing a storage account and a
                connection string in the Sync Relationship wizard.</div>

        </div>
        <div className="section section-proxy">
            Select a proxy installation option:
            <Select className="proxy-options"
                    isSearchable={false}
                    isClearable={false}
                    options={proxyOptions}
                    value={_.find(proxyOptions, (option) => option.value === proxyConfig)}
                    onChange={(e)=>{setProxyConfig(e.value)}}/>
        </div>
        <div>
            <label title={"Certificate"}>
                <input id="certificate" type="checkbox" value="certificate"
                       checked={withCertificate}
                       onChange={()=>{setCertificate(!withCertificate)}}/>Use certificate
            </label>
        </div>

        <div className="section section-commands">Download the installer:
           <CommandArea command={downloadCommand}/>
        </div>
        <div className="section section-commands">Switch to superuser, make the installer executable and install the software:
            <CommandArea command={installerCommandWithLogFile}/>
        </div>
    </div>;
};

export default OnPremInstallationInstructions;

const CommandArea = ({command}) => {
    let commandNode = React.createRef();
    return (
        <div className="command">
            <div className="command-text">
                <pre ref={(code) => commandNode = code} title={command}>{command}</pre>
            </div>
            <CopyToClipboard text={command}
                             onCopy={() => selectText(commandNode)}>
                <CopyIcon/>
            </CopyToClipboard>
        </div>)
};
