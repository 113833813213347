import React, {useMemo, useState} from 'react';
import _ from 'lodash';
import SearchableTableContainer from '../../table/SearchableSelectableTable';
import {convertBytesToSize} from '../../../utils/helpers';
import {HEADER_TYPES} from '../../table/SelectableTable';
import {DOCUMENTATION_ANF_RECOMMENDATION} from "../../../consts";
import useRunOnceWhenTruthy from '../../../hooks/useRunOnceWhenTruthy';
import Notice from '../../notifications/Notice';
import {ReactComponent as VolumeIcon} from "../../../assets/svgs/volume.svg";
import {ReactComponent as CheckmarkIcon} from "../../../assets/svgs/checkmark-fill-circle-icon.svg";
import RefreshButton from '../../refreshButton/RefreshButton';
import "./volSelectionNew.scss";
import "./cmTableNew.scss";
import {getFilterOptions} from "../../../utils/sync-utils";

const AnfVolSelection = ({volumes, defaultVolumeId, setVolumeInStep, volumesFilterFunction, volumeMapFunction, protocol, refreshStep}) => {
    const [showVolumes, setShowVolumes] = useState([]); //the displayed volumes, after filter and sort
    const [selectedVolume, setSelectedVolume] = useState(null);
    const [filters, setFilters] = useState({});

    const formattedVolumes = useMemo(() => {
        return volumes.data && processVolumes(volumes.data, volumesFilterFunction, volumeMapFunction, protocol);
    }, [volumes, volumesFilterFunction, volumeMapFunction, protocol]);

    useRunOnceWhenTruthy( () => {
        setShowVolumes(formattedVolumes);
        setFilters({
            serviceLevel: getFilterOptions(formattedVolumes, "serviceLevel"),
            protocolWithVersion: getFilterOptions(formattedVolumes, "protocolWithVersion"),
            anfAccount: getFilterOptions(formattedVolumes, "anfAccount"),
            capacityPool: getFilterOptions(formattedVolumes, "capacityPool")
        });

        //initialize default selected (back navigation):
        handleVolumeSelect(defaultVolumeId);

    }, formattedVolumes);

    const handleVolumeSelect = volId => {
        const volume = _.find(formattedVolumes, {id: volId});
        setSelectedVolume(volume); //for the selection in the table
        setVolumeInStep(volume); //for the step selection
    };

    const applySort = (column, ascending, volumes) => {
        const sortedVolumes = _.sortBy(volumes ? volumes : showVolumes, [column]);
        setShowVolumes(ascending ? sortedVolumes : _.reverse(sortedVolumes));
    };

    const applyFilter = (filterName, filterOptions, sort) => {
        const updatedFilter = filters[filterName].map(value => {
            const filterOption = _.find(filterOptions, { name: value.name });
            value.checked = filterOption.checked;
            return value;
        });

        const updatedFilters = {...filters, [filterName]: updatedFilter};

        let updatedShowVolumes = _.cloneDeep(formattedVolumes);

        _.forEach(updatedFilters, (filterOptions, filterName) => {
            let copyVolumes = _.cloneDeep(updatedShowVolumes);
            updatedShowVolumes = _.reduce(filterOptions, (volumes, filterOption) => {
                return filterOption.checked ?
                    [...volumes, ...(_.filter(copyVolumes, vol => vol[filterName] === filterOption.name))] :
                    volumes;
                }, []);
        });

        setFilters(updatedFilters);
        if (!sort) {
            setShowVolumes(updatedShowVolumes);
        } else {
            applySort(sort.column, sort.ascending, updatedShowVolumes);
        }
    };

    return (
        <div className="vol-selection">
            {formattedVolumes?.length > 0 &&
                <div className="volumes-table">
                    <Notice>
                        {`Verify that you're using the Premium or Ultra service level with Azure NetApp Files.`}
                        <a href={DOCUMENTATION_ANF_RECOMMENDATION} target="_blank" rel="noopener noreferrer">Click Here</a> to learn more.
                    </Notice>
                <SearchableTableContainer
                    containerOptions={containerOptions}
                    Table={{renderRow, getTableHeader: () => getTableHeader(applySort, applyFilter, filters)}}
                    data={showVolumes}
                    selectionOptions={{selector: handleVolumeSelect, selectedId: selectedVolume && selectedVolume.id}}
                    refreshButton={refreshStep ? <RefreshButton onClick={refreshStep} storageObjectName={containerOptions.pluralTitle}/> : undefined}
                />
                </div>}
            {formattedVolumes?.length === 0 &&
            <div className="no-volumes">
                <VolumeIcon/>
                <div>There are no volumes</div>
            </div>}
            {volumes.failed &&
            <div className="nothing-found">
                <span><VolumeIcon/></span>
                <p>Error retrieving volumes</p>
            </div>}
        </div>)
};

export default AnfVolSelection;

const processVolumes = (vols, filterFunction = () => true, volumeMapFunction, protocol) => {
    const updatedVols = vols
        .filter(vol => vol.provisioningState === "Succeeded" && filterFunction(vol))
        .map(vol => {
            const quotaBytes = vol.usageThreshold;
            const quotaObj = convertBytesToSize(quotaBytes);
            const protocolWithVersion = protocol === "cifs" ? protocol.toUpperCase() : (vol.protocolTypes ? _.find(vol.protocolTypes, (protocol)=>protocol.toUpperCase().startsWith("NFS")) : 'NFSv3');
            const protocolSplit = protocolWithVersion ? protocolWithVersion.split("v"): [];
            const version = protocolSplit && protocolSplit[1];
            let host = _.get(vol.properties, 'mountTargets[0].ipAddress') || _.get(vol,"mountTargets[0].ipAddress"); //second option for dual protocol volume
            const rootPath = vol.creationToken  ||  vol.name;
            return {
                id: `${vol.subscriptionId}/${vol.resourceGroupName}/${vol.netAppAccountName}/${vol.poolName}/${vol.name}`,
                name: vol.name,
                root: rootPath,
                anfAccount: vol.netAppAccountName,
                capacityPool: vol.poolName,
                mountPath: vol.mountTargets ?
                    (vol.protocol === "nfs" ? `${vol.mountTargets[0].ipAddress}:/${vol.creationToken}` : `\\\\${vol.mountTargets[0].smbServerFqdn}\\${vol.creationToken}`) :
                    "",
                serviceLevel: vol.serviceLevel,
                protocolWithVersion,
                quota: `${quotaObj.value} ${quotaObj.resolution}`,
                protocol: vol.protocol,
                ipAddress: vol.mountTargets && vol.mountTargets[0].ipAddress,
                version,
                host
            };
        });
        return volumeMapFunction ? updatedVols.map(volumeMapFunction) : updatedVols;
};

const containerOptions = {
    singularTitle: "Volume",
    pluralTitle: "Volumes"
};

const renderRow = (row) => {
    return (
        <div>
            <td className="check"> <span><CheckmarkIcon/></span></td>
            <td title={row.name}>{row.name}</td>
            <td title={row.anfAccount}>{row.anfAccount}</td>
            <td title={row.capacityPool}>{row.capacityPool}</td>
            <td title={row.mountPath}>{row.mountPath}</td>
            <td title={row.serviceLevel}>{row.serviceLevel}</td>
            <td title={row.protocolWithVersion}>{row.protocolWithVersion}</td>
            <td title={row.quota}>{row.quota}</td>
        </div>
    )
};

const getTableHeader = (applySort, applyFilter, filters) => {
    return [
        {
            className: 'blank',
            width: "6%",
        },
        {
            name: 'name',
            width: "13%",
            className: 'volume-name',
            text: 'Volume Name',
            sort: applySort
        },
        {
            text: 'NetApp Account',
            width: "14%",
            className: 'anf-account',
            type: HEADER_TYPES.FILTER,
            filterOptions: {
                filterName: 'anfAccount',
                filter: applyFilter,
                filterValues: filters.anfAccount
            },
            sort: applySort //must give the sort method to allow sorting after the filter is applied
        },
        {
            text: 'Capacity Pool',
            className: 'capacity-pool',
            width: "13%",
            type: HEADER_TYPES.FILTER,
            filterOptions: {
                filterName: 'capacityPool',
                filter: applyFilter,
                filterValues: filters.capacityPool
            },
            sort: applySort //must give the sort method to allow sorting after the filter is applied
        },
        {
            name: 'mountPath',
            width: "22%",
            className: 'mount-path',
            text: 'Mount Path',
            sort: applySort
        },
        {
            text: 'Service Level',
            width: "13%",
            className: 'service-level',
            type: HEADER_TYPES.FILTER,
            filterOptions: {
                filterName: 'serviceLevel',
                filter: applyFilter,
                filterValues: filters.serviceLevel
            },
            sort: applySort //must give the sort method to allow sorting after the filter is applied
        },
        {
            text: 'Protocol',
            width: "10%",
            className: 'protocol',
            type: HEADER_TYPES.FILTER,
            filterOptions: {
                filterName: 'protocolWithVersion',
                filter: applyFilter,
                filterValues: filters.protocolWithVersion
            },
            sort: applySort //must give the sort method to allow sorting after the filter is applied
        },
        {
            name: 'quota',
            className: 'protocol',
            text: 'Quota',
            sort: applySort
        }
    ];
};
