import React from 'react';
import ConfirmDialog from '../../dialog/ConfirmDialog';

const ResetSettingsDialog = ({resetSettings}) => (
    <ConfirmDialog
        title="Reset settings to defaults"
        onConfirm={resetSettings}
        confirmText="Reset"
    >
        <p>Are you sure you want to reset the settings to defaults?</p>
    </ConfirmDialog>
);

export default ResetSettingsDialog;