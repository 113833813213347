import {HOST_IPV4_IPV6_REG_EXP, HOST_NAME_REG_EXP, IP_REG_EXP} from "../../../../../consts";
import Validator from 'validatorjs';


const rules = (fromDataSense) => {
    return {
        serverName: fromDataSense ? '' : ['required', 'max:256'],
        username: ['required'],
        password: 'required'
    }
};

export const validate = (values, props, fromDataSense) => {
    const {serverName} = values;
    const validator = new Validator(values, rules(fromDataSense), {
        "max.serverName": "SMB hostname or IP address must be less than 256 characters"
    });
    validator.setAttributeNames({serverName: 'SMB server', username: "User Name"});
    validator.passes();
    const errors = validator.errors.all();
    if (serverName && !IP_REG_EXP.test(serverName) && !HOST_NAME_REG_EXP.test(serverName) && !HOST_IPV4_IPV6_REG_EXP.test(serverName)) {
        errors.serverName = errors.serverName || [];
        errors.serverName.push("Invalid hostname or IP address");
    }
    return errors;
};
