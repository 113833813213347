import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
    DEPLOY_PROGRESS_NOTIFICATION,
    FM_AGENT_TYPE_AWS,
    FM_AGENT_TYPE_AZURE,
    FM_AGENT_TYPE_GCP,
    FM_AGENT_TYPE_ONPREM,
} from '../../consts';
import { NOTIFICATION_CONSTS } from "../../consts/notification.consts";
import ProviderLogin from './ProviderLogin';
import InstanceSettings from './InstanceSettings';
import InstanceProxy from './InstanceProxy';
import { connect } from "react-redux";
import { addNotification, clearNotifications } from "../../store/global/action_creators";
import { addDeployment } from "../../store/deploy/deploySlice";
import { INSTANCE_DETAILS_SCREENS as INSTANCE_SCREENS } from '../../consts';
import { ONPREM_DETAILS_SCREENS as ONPREM_SCREENS } from '../../consts';
import { AZURE_SCREENS } from '../../consts';
import { startDeployAws } from '../../services/aws.deploy.service';
import { extractErrorAws, extractErrorAzure, extractErrorGcp } from '../../utils/providers-utils';
import * as azureService from '../../services/azure.api.service';
import * as azureDeployService from '../../services/azure.deploy.service';
import * as gcpDeployService from '../../services/gcp.deploy.service';
import BrokerInstanceSteps from '../newWizard/NewBrokerInstanceSteps'
import { sortObjectEntries } from "../../utils/helpers";
import { getDeployName } from '../../utils/providers-utils';
import _ from "lodash";
import "./instanceDetails.scss";
import PermissionsStep from "../../syncNew/components/dataBrokerWizard/permissionsStep/permissionsStep";


const InstanceDetails = props => {
    const { type, closePanel, isAccelerate, addNotification, clearNotifications, dataBroker } = props;
    let screens = type === FM_AGENT_TYPE_ONPREM.name ? ONPREM_SCREENS : INSTANCE_SCREENS;
    let screensArray = sortObjectEntries(INSTANCE_SCREENS);
    
    if (type === FM_AGENT_TYPE_AZURE.name) {
        screens = {...screens, ...AZURE_SCREENS};
        screensArray = [...screensArray, ...Object.keys(AZURE_SCREENS)];
    }

    const [screen, setScreen] = useState(0);
    const [deployValues, setDeployValues] = useState(null);

    const credentials = useSelector(state => {
        return state.deploy.credentials;
    });

    const dispatch = useDispatch();

    const handleNotification = (moreInfo, type = NOTIFICATION_CONSTS.TYPE.ERROR, children = "An error occurred.") => {
        moreInfo ? addNotification({ type, children, moreInfo }) : clearNotifications();
    };

    const goToPrevScreen = useCallback(() => {
        setScreen(screen => (screen < 1) ? screen : screen - 1);
    }, []);

    const onContinue = async (values) => {
        const updatedDeployValues = { ...deployValues, ...values };
        const nextScreen = screen + 1;
        if (nextScreen === _.keys(screens).length) {
            await onFinish(updatedDeployValues);
        }
        else {
            setDeployValues(updatedDeployValues);
            setScreen(nextScreen);
        }
    };

    const onFinish = async (values) => {
        try {
            switch (type) {
                case FM_AGENT_TYPE_AWS.name: {
                    await handleDeployClickAws(values, dataBroker, credentials.aws, dispatch);
                    break;
                }
                case FM_AGENT_TYPE_AZURE.name: {
                    await handleDeployClickAzure(values, dataBroker, dispatch);
                    break;
                }
                case FM_AGENT_TYPE_GCP.name: {
                    await handleDeployClickGcp(values, dataBroker, dispatch);
                    break;
                }
                default: {
                    //do nothing
                }
            }

            closePanel();

            if (isAccelerate) {
                //Need to happen only in dashboard, not needed in the wizard
                if (type !== FM_AGENT_TYPE_ONPREM.name) {
                    handleNotification(DEPLOY_PROGRESS_NOTIFICATION,
                        NOTIFICATION_CONSTS.TYPE.INFO,
                        "Data broker creation is in progress.")
                }
            }
        }
        catch (err) {
            handleNotification(err);
        }
    };


    return (<>
        {type !== FM_AGENT_TYPE_ONPREM.name && <BrokerInstanceSteps screens={screensArray} setScreen={setScreen} currentScreen={screen} />}

        {screen === screens.CREDENTIALS && <ProviderLogin onContinue={onContinue} setStepError={handleNotification} {...props} />}
        {screen === screens.SETTINGS && <InstanceSettings onContinue={onContinue} goToPrevScreen={goToPrevScreen} setStepError={handleNotification} credentials={credentials} {...props} />}
        {screen === screens.PROXY && <InstanceProxy onContinue={onContinue} goToPrevScreen={goToPrevScreen} type={type} {...props} />}
        {screen === screens.PERMISSIONS && type === FM_AGENT_TYPE_AZURE.name && <PermissionsStep dataBroker={dataBroker} onContinue={onContinue} initialValues={{
            awsAccessKey: deployValues.awsAccessKey,
            awsSecretKey: deployValues.awsSecretKey
        }} />}
    </>);
};


export default connect(null, {
    addNotification,
    clearNotifications
})(InstanceDetails);


const handleDeployClickAws = async (deployDetails, dataBroker, credentials, dispatch) => {
    const { id, name, template } = dataBroker;
    const stackName = getDeployName(name);
    const { region } = credentials;

    try {
        const stackId = await startDeployAws(deployDetails, template, credentials, region, stackName);
        dispatch(addDeployment({
            dataBrokerId: id,
            dataBrokerName: name,
            providerType: FM_AGENT_TYPE_AWS.name,
            deployDetails: {
                stackId,
                credentials,
                region
            }
        }));
    }
    catch (err) {
        throw extractErrorAws(err);
    }
};

const handleDeployClickAzure = async (values, dataBroker, dispatch) => {
    const { fileLink, id, name } = dataBroker;
    const deploymentName = getDeployName(name);
    try {
        const deployDetails = azureDeployService.getDeployDetails(values, fileLink, deploymentName);

        await azureDeployService.prepareForDeploy(deployDetails);

        await azureService.azureTemplateDeployment(deployDetails);

        dispatch(addDeployment({
            dataBrokerId: id,
            dataBrokerName: name,
            providerType: FM_AGENT_TYPE_AZURE.name,
            deployDetails: { ...deployDetails, createRole: values.createRole }
        }));
    }
    catch (err) {
        throw extractErrorAzure(err);
    }
};

const handleDeployClickGcp = async (values, dataBroker, dispatch) => {
    const { template, id, name } = dataBroker;

    const deploymentName = getDeployName(name);
    try {
        const deployDetails = {
            ...values,
            dataBrokerName: name,
            deploymentName
        };
        const deployResponse = await gcpDeployService.startDeployProcess(
            deployDetails,
            template
        );
        const operationName = deployResponse.name;
        dispatch(addDeployment({
            dataBrokerId: id,
            dataBrokerName: name,
            providerType: FM_AGENT_TYPE_GCP.name,
            deployDetails: {
                projectId: deployDetails.projectId,
                operationName,
                deploymentName
            }
        }));
    }
    catch (err) {
        throw extractErrorGcp(err);
    }
};
