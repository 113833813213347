import React from 'react';
import {
    clearShowAction,
    clearUrlParamsIfChanged,
    getServersByType,
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from '../../../store/wizard/action_creators';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {InputField} from "../../_common_/forms/forms";
import {
    END_POINT_NAMES_MAP, HOST_IPV4_IPV6_REG_EXP, HOST_NAME_REG_EXP, IP_REG_EXP,
} from '../../../consts';
import Validator from 'validatorjs';
import ServerStepTemplate from "./ServerStepTemplateNew";
import {calculateStepId} from '../../../utils/sync-utils';
import {buildServersList, getPropFromState} from '../../../utils/mapStateToPropsUtils';
import useStepinize from "../useStepinize";
import {getWizardParamsFromWizardState} from "../../../utils/mapStateToPropsUtils";
require('./sftpStepNew.scss')

const rules = {
    serverName: ['required', 'max:256'],
    username: 'required',
    password: 'required',
    port: ['required', 'numeric', 'between:1,65535']
};

const validate = (values) => {
    const {serverName} = values;
    const validator = new Validator(values, rules, {
        "max.serverName": "SFTP hostname or IP address must be less than 256 characters"
    });
    validator.setAttributeNames({serverName: 'SFTP server', username: "User Name", password: "Password", port: "Port"});
    validator.passes();
    const errors = validator.errors.all();
    if (serverName && !IP_REG_EXP.test(serverName) && !HOST_NAME_REG_EXP.test(serverName) && !HOST_IPV4_IPV6_REG_EXP.test(serverName)) {
        errors.serverName = errors.serverName || [];
        errors.serverName.push("Invalid hostname or IP address");
    }
    return errors;
};

const SecondScreenUi = () => {
    return (
        <div>
            <div className="credentials-container credentials">
                <div className="credentials-title">Define SFTP Credentials:</div>
                <div className="credentials-fields">
                    <Field name="username" component={InputField} label="User Name"/>
                    <Field name="password" component={InputField} label="Password" type="password" autoComplete="off"/>
                    <Field name="port" component={InputField} label="Port"/>
                </div>
            </div>
        </div>);
};

const loading = ({endpointObj}) => {
    const serverType = endpointObj.provider;
    return {
        text: `${END_POINT_NAMES_MAP[serverType.toUpperCase()]}`,
        icon: require('../../../assets/svgs/file-server-default.svg')
    }
}

const stepId = ({_wizard: {wizardInfo}, isTargetStepFromSameSourceType}) => {
    return calculateStepId(wizardInfo, isTargetStepFromSameSourceType, "sftp");
};

const fetchData = (props) => {
    const {endpointObj, protocolAndProviderKey} = props;
    props.getServersByType(endpointObj.protocol, endpointObj.provider, protocolAndProviderKey);
};

const shouldFetch = (props) => {
    const {protocolAndProviderKey} = props;
    return (!props._servers || !props._servers[protocolAndProviderKey] || !props._servers[protocolAndProviderKey].succeeded);
};

const isFetching = (props) => {
    const {_servers, protocolAndProviderKey} = props;
    return _servers[protocolAndProviderKey].inProgress;
};



const SftpStep = (props) => {
    const {LoadingComp, moreStepInfo} = useStepinize({props, fetchData, shouldFetch, loading, stepId})
    const {protocolAndProviderKey, isTargetStepFromSameSourceType,isSourceStep} = moreStepInfo;
    const {_servers, _wizard, clearSelectedParams, state} = props;
    const {selectedWizardParams} = _wizard;
     const {selectedSftp, selectedSftpTarget} = selectedWizardParams;

    const selectedServer = isTargetStepFromSameSourceType ? selectedSftpTarget : selectedSftp;
    const stateParamsToClear = isTargetStepFromSameSourceType ? ["sftpDirectoryTarget", "rootSftpDirectoryTarget"] : ["sftpDirectory", "rootSftpDirectory"]

    const stepOptions = {
        storage: _servers[protocolAndProviderKey],
        server: selectedServer,
        serverSVG: `sftp-default.svg`,
        serverType: "SFTP",
        serverTitle: "SFTP",
        selectType: "Server",
        serverPlaceholder: "",
        title: `Select an SFTP ${isSourceStep ? "Source" : "Target"}`,
        changeServerTitle: "Change SFTP Server",
        clearShowOptions: {keyToClear: ['_showSftpDirectories', selectedServer]},
        validate,
        SecondScreenUi,
        shouldGoToSecondScreen: () => true,
        stateParamsToClear,
        getSelectedParams: (host) => {
            const hostParam = isTargetStepFromSameSourceType ? 'sftpTarget' : 'sftp';
            if ((state[hostParam] && state[hostParam] !== host)) {
                clearSelectedParams(stateParamsToClear)
            }
            return {
                [hostParam] : host
            }
        }
    };

    return isFetching({...props, ...moreStepInfo}) ? LoadingComp : <ServerStepTemplate stepOptions={stepOptions} {...props} {...moreStepInfo}/>;
};

let SftpStepForm = reduxForm({
    form: 'sftpServer',
    validate,
    shouldError: () => true,
})(SftpStep);


const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _servers: buildServersList(state),
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _accountInfo: state.global._accountInfo,
        _relationships: getPropFromState(state, "_relationships", {}),
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    }
};

const mapDispatchToProps = {
    getServersByType,
    clearUrlParamsIfChanged,
    clearShowAction,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(SftpStepForm);


