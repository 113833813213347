import React from 'react'
import {formatBytes} from "../../../utils/helpers";
import numeral from "numeral";

const SummaryItem = ({top: {icon:Icon, title, data, convertToBytes}, bottom}) => {
    //only for objects view we give data that should be converted to bytes.
    const formattedData = convertToBytes ? formatBytes(data) : null;
    const formattedDataString = convertToBytes ? `${formattedData.size} ${formattedData.suffix}` :
        numeral(data).format('0.[0]a')
    return (
        <div className="summary-item">
            <div className="summary-top">
                <div className="icon"><Icon/></div>
                <div className="summary-values">
                    <div className="summary-count" title={formattedDataString}>{formattedDataString}</div>
                    <div className="summary-title">
                        <div>{title}</div>
                    </div>
                </div>
            </div>
            {bottom && <div className="summary-bottom">
                <div className="summary-title">{bottom.title}</div>
                <div className="summary-count">{numeral(bottom.data).format('0.[0]a')}</div>
            </div>}
        </div>
    )
}

export default SummaryItem
