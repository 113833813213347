import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {RelationshipTagsForm} from "./RelationshipTagsFormNew";
import {
    updateUrlParams,
    wizardStepExited,
    wizardStepStarted
} from './../../../store/wizard/action_creators';
import {
    getSettingsFromSessionStorage,
    saveSettingsToSessionStorage,
    SETTINGS_DEFAULTS
} from "./../../../utils/settings-utils";
import {isCloudToCloud} from "./../../../utils/relationshipUtils";
import {getWizardParamsFromWizardState} from "./../../../utils/mapStateToPropsUtils";
import {tagsToString} from "../../../utils/helpers";

require("./relationshipTagsNew.scss");

const RelationshipTagsStep = (props) => {
    const {_wizard, up, continueToNextStep, updateSelectedParams} = props;
    const {wizardInfo, selectedWizardParams: {relationshipTags}} = _wizard;
    //must be defined here and given as parameter to the form, so can be used in validate of the form from the props
    const [objectTaggingDisabled, setObjectTaggingDisabled] = useState(() => {
        const cloudToCloud = isCloudToCloud(null, wizardInfo.source.protocol, wizardInfo.target.protocol);
        const initialSettings = getSettingsFromSessionStorage(cloudToCloud);
        return initialSettings ? !initialSettings.objectTagging : !SETTINGS_DEFAULTS.objectTagging;
    });

    const nextStep = useCallback((values, objectTaggingDisabled) => {
        const cloudToCloud = isCloudToCloud(null, wizardInfo.source.protocol, wizardInfo.target.protocol);
        const initialSettings = getSettingsFromSessionStorage(cloudToCloud);
        const settingsWithObjectTagging = Object.assign({}, initialSettings, {objectTagging: !objectTaggingDisabled});
        saveSettingsToSessionStorage(settingsWithObjectTagging);
        updateSelectedParams({relationshipTags: tagsToString(values.tags)});
        continueToNextStep();
    }, [wizardInfo.source.protocol, wizardInfo.target.protocol, updateSelectedParams, continueToNextStep]);

    return (
        <div className="wizard-step relationship-tags-step">
            <RelationshipTagsForm
                complete={nextStep}
                targetProtocol={wizardInfo.target.protocol}
                targetProvider={wizardInfo.target.provider}
                wizardInfo={wizardInfo}
                relationshipTags={relationshipTags}
                up={up}
                objectTaggingDisabled={objectTaggingDisabled}
                setObjectTaggingDisabled={setObjectTaggingDisabled}
                {...props}/>
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        routing: state.router,
    }
};

const mapDispatchToProps = {
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipTagsStep);
