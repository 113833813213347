import React, {useRef, useState} from "react";
import {Form} from "react-final-form";
import {updateUrlParams, wizardStepExited, wizardStepStarted,} from "../../../../store/wizard/action_creators";
import {connect, useDispatch} from "react-redux";
import classNames from 'classnames'
import WizardStepHeader from "../WizardStepHeaderNew";
import ButtonsPanel from "../../../widgets/ButtonsPanel";
import useRunOnceWhenTruthy from "../../../../hooks/useRunOnceWhenTruthy";
import {getSVGByProtocol} from "../../../../utils/dashboard-utils";
import DoubleLineItem from "../../../_common_/DoubleLineItem";
import {END_POINT_NAMES_MAP} from "../../../../consts";
import {
    saveStepCredentials,
    getCredentialsByType,
    getKeyNameByProviderAndProtocol,
    getTypeByProviderAndProtocol
} from "../../../../utils/sync-utils";
import {getDefaultVersion, getModeDataValidate, getMoreDataComponent} from "./dataSenseUtilsNew";
import {getWizardParamsFromWizardState} from "../../../../utils/mapStateToPropsUtils";
import { addNotification } from "../../../../store/global/action_creators";
import { NOTIFICATION_CONSTS } from "../../../../consts/notification.consts";

import ("./dataSenseStepNew.scss");

const DataSenseStep = (props) => {
    const { _wizard, up, routing, history, continueToNextStep } = props;

    const {dataSense: {provider, protocol, host, source, port, weName, volName}} = _wizard;
    const scrollArea = useRef();
    const [sourceValues, setSourceValues] = useState([])
    const [icon, setIcon] = useState(null);
    const [formValues] = useState(() => {
        //fill the default values when coming back to the step as saved in local storage
        const keyName = getKeyNameByProviderAndProtocol(provider, protocol);
        const key = _wizard.selectedWizardParams[keyName];
        const type = getTypeByProviderAndProtocol(provider, protocol)
        let values = getCredentialsByType(type, key, false) || {};
        if ((protocol === 'nfs' || protocol === 'cifs') && (!values.version)) {
            //fill the default version for first time
            values = getDefaultVersion(protocol, provider.toUpperCase());
        }
        //fill the selected port if exists
        if (port) {
            values.port = port;
        }
        return values;
    });

    const dispatch = useDispatch();
    const errorRef = useRef();

    useRunOnceWhenTruthy(() => {
        //fill the source info according to what was received from data sense
        const icon = getSVGByProtocol(provider)
        const sourceValues = [{title: "Source", value: source}];
        if (host) {
            sourceValues.push({title: "Host", value: host})
        }
        if (weName) {
            sourceValues.push({title: "Working Environment", value: weName})
        }
        if (volName) {
            sourceValues.push({title: "Volume", value: volName})
        }
        setSourceValues(sourceValues)
        setIcon(icon)
    }, _wizard.dataSense)

    const submit = (values) => {
        const keyName = getKeyNameByProviderAndProtocol(provider, protocol);
        const key = _wizard.selectedWizardParams[keyName];
        const type = getTypeByProviderAndProtocol(provider, protocol);
        saveStepCredentials(type, key  , {...values});
        continueToNextStep();

        if (errorRef.current) {
            setTimeout(() => {
                addNotification({
                    id: NOTIFICATION_CONSTS.UNIQUE_IDS.DATA_SENSE_STEP_NOTIFICATION,
                    type: NOTIFICATION_CONSTS.TYPE.ERROR,
                    children: errorRef.current
                })(dispatch)
            }, 0);
        }
    }

    const error = null;
    const validate = getModeDataValidate(protocol, provider);

    return (
        <div className="wizard-step data-sense-step">
            <Form
                onSubmit={submit}
                validate={(values) => (validate(values, props, true))}
                initialValues={formValues}
            >
                {({handleSubmit, form, values, submitError}) => {
                    const Component = getMoreDataComponent(provider, protocol, props, formValues, form)
                    const formState = form.getState();
                    const {modifiedSinceLastSubmit} = formState;
                    errorRef.current = !modifiedSinceLastSubmit ? error || submitError : null;

                    return (
                        <form noValidate className="wizard-step-form" onSubmit={handleSubmit}>
                            <div className={classNames("scrollable-area", {up, down: !up})}
                                 ref={scrollArea}>
                                <WizardStepHeader
                                    _wizard={_wizard}
                                    routing={routing}
                                    history={history}
                                    title="Selected Data Sense Source"
                                />
                                <div>
                                    <div className="source-info">
                                        <div className="provider-info">{icon}
                                            <div
                                                className="provider-name">{END_POINT_NAMES_MAP[provider.toUpperCase()]}</div>
                                        </div>
                                        {sourceValues.map((sourceValue) => (
                                            <DoubleLineItem key={sourceValue.title} title={sourceValue.title}
                                                            value={sourceValue.value}/>
                                        ))}

                                    </div>
                                </div>
                                {Component && <div>
                                    <h3>A few more things before we continue</h3>
                                    {Component}
                                </div>}

                            </div>

                            <ButtonsPanel
                                variant="white"
                                type="submit"
                                text="Continue"
                            />
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        _ui: {...state.syncReducer._ui, ...state.global._ui},
        routing: state.router,
    };
};

const mapDispatchToProps = {
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSenseStep);
