import React from 'react';
import SelectableTable from "../../table/SelectableTable";
import {ReactComponent as CheckmarkIcon} from "../../../assets/svgs/checkmark-fill-circle-icon.svg";

import "./hostsTableNew.scss";

const renderRow = (row) => {
    return (
        <div className="table-rows">
            <td className="checkmark">
                <CheckmarkIcon/>
            </td>
            <td className="host-name">
                <div title={row.host}>
                    {row.host}
                </div>
            </td>
        </div>
    )
};

const HostsTable = (props) => {
    const {tableData, selector, selectedId} = props;
    return (
        <SelectableTable Table={{renderRow}} data={tableData} selectionOptions={{selector: selector, selectedId: selectedId}} itemIdentifier="host">
        </SelectableTable>
    )
};

export default HostsTable
