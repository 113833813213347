import React from 'react';
import {restartDataBroker} from "../../../store/wizard/action_creators";
import ConfirmDialog from "../../dialog/ConfirmDialog";

const RestartDataBrokerDialog = ({data}) => {
    return (
        <ConfirmDialog
            title={`Restart Data Broker`}
            confirmText="Restart Data Broker"
            onConfirm={() => restartDataBroker(data.id)}
            extractError={(error) => error?.data?.message}
        >
            <p>Are you sure that you want to restart the Data Broker {`'${data.name}'`}?</p>
            <div>This action might take a few seconds</div>

        </ConfirmDialog>
    );
};

export default RestartDataBrokerDialog;
