import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import useRunOnce from "../../../hooks/useRunOnce";
import { getDataBrokersGroups, downloadDataBrokerLogs } from "../../../store/wizard/action_creators";
import { addNotification, clearNotifications } from '../../../store/global/action_creators';
import CardsContainer from "../../cards/CardsContainer";
import GroupCard from "./GroupCard";
import useRightPanel from "../../../hooks/useRightPanel";
import DataBrokerWizard from "../../dataBrokerWizard/DataBrokerWizard";
import { ReactComponent as AddIcon } from '../../../assets/svgs/plus-circle-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/svgs/pencil-circle-icon.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/svgs/download-circle.svg'
import { Button, useDialog } from '@netapp/shared-components';
import EditGroupNameDialog from "./EditGroupNameDialog";
import MainLoader from "../../_common_/MainLoader/MainLoader";
import { filter, find, get, isEmpty, noop } from 'lodash'
import { getDataBrokerAllowedType } from "../../../utils/dataBrokersUtils";
import queryString from 'query-string';
import './manageGroups.scss'
import { NOTIFICATION_CONSTS } from '../../../consts/notification.consts';
import DeleteGroupDialog from "./DeleteGroupDialog";
import { ReactComponent as RemoveIcon } from "../../../assets/svgs/remove-circle-icon.svg";
import DownloadLogsDialog from "../DownloadLogsDialog";
import { cmNavigateTo } from '../../../utils/cm-integration-utils';
import { ROUTES } from '../../../consts';
import { useNavigate, useParams } from 'react-router-dom';

const keysToSearch = ["name", 'dataBrokers'];

const containerOptions = {
    singularTitle: "Data Broker Group",
    pluralTitle: "Data Broker Groups",
    keysToSearch
};

const groupsMenu = (startDataBrokerWizard, setDialog) => {
    return (group) => {
        const { id: existingGroupId, name: groupName, dataBrokers } = group;
        const allowedAgentType = getDataBrokerAllowedType(dataBrokers);
        //disallow adding data broker if the data broker in the group is an initator, or a listener
        const disallow = !isEmpty(group.encryptionType);
        const reason = "You can’t add data brokers to a group that manages an encrypted sync relationship.";
        const isGcpAccessRequired = dataBrokers?.length && !!find(dataBrokers, dataBroker => dataBroker.placement?.gcpProjectId);
        const isAwsAccessRequired = dataBrokers?.length && !!find(dataBrokers, dataBroker => dataBroker.placement?.awsAccountId);
        const deleteGroupDisabled = group.relationships.length > 0;
        const downloadLogsDisabled = !!find(dataBrokers, dataBroker => dataBroker.status === 'FAILED');
        return [
            <button type="button"
                className="add-data-broker"
                disabled={disallow}
                title={disallow ? reason : ''}
                onClick={() => {
                    disallow ? noop() : startDataBrokerWizard({ existingGroupId, fromManageGroups: true, groupName, allowedAgentType, isGcpAccessRequired, isAwsAccessRequired });
                }}>
                <span className="plus-circle"><AddIcon /></span>
                <span>Add Data Broker</span>
            </button>,
            <button type="button"
                className="edit-name"
                title={'Edit Group Name'}
                onClick={(el) => {
                    setDialog(<EditGroupNameDialog className="default-dialog" groupName={group.name} groupId={group.id} data={el} />, el.target);
                }}>
                <span className="edit-pencil"><EditIcon /></span>
                <span>Edit Group Name</span>
            </button>,
            <button disabled={deleteGroupDisabled} title={deleteGroupDisabled ? "Group that serves relationships can't be deleted. Delete the relationships first " : ""} type="button" onClick={(element) => {
                setDialog(<DeleteGroupDialog className="default-dialog" data={group} />, element.target);
            }}>
                <span className="garbage-bin"><RemoveIcon /></span>
                <span>Delete Group</span>
            </button>,
            <button disabled={downloadLogsDisabled}
                type="button"
                title={downloadLogsDisabled ? "Download Logs can not be activated on a Group with no active data brokers" : ""}
                onClick={(element) => {
                    setDialog(<DownloadLogsDialog sourceName='group' relationship={{ groupInfo: group }}
                        downloadDataBrokerLogs={downloadDataBrokerLogs} />, element.target);
                }}>
                <span className="garbage-bin"><DownloadIcon /></span>
                <span>Download Logs</span>
            </button>
        ];
    }
};

const ManageGroups = (props) => {
    const { _dataBrokersGroups, addNotification, clearNotifications } = props
    const { setDialog } = useDialog();
    const { setPanelContent, closePanel } = useRightPanel();
    const queryParams = queryString.parse(window.location.search);
    const extendCard = get(queryParams, 'extendGroup');
    const { groupId } = useParams('groupId');
    const [showGroups, setShowGroups] = useState(null)
    const [closeNotification, setCloseNotification] = useState(false);

    const navigate = useNavigate();

    useRunOnce(() => {
        props.getDataBrokersGroups()
    })

    useEffect(() => {
        if (_dataBrokersGroups?.data) {
            if (groupId) {
                const filteredByGroupId = filter(_dataBrokersGroups?.data, { id: groupId })
                setShowGroups(filteredByGroupId)
                setCloseNotification(false)
                cmNavigateTo(`/${ROUTES.SYNC}/${ROUTES.MANAGE}`, null, {});
            }
            else {
                setShowGroups(_dataBrokersGroups?.data)
                setCloseNotification(true)
            }
        }
    }, [_dataBrokersGroups, groupId])

    useEffect(() => {
        if (!closeNotification) {
            if (groupId && _dataBrokersGroups?.data?.length > 1) {
                addNotification({
                    id: NOTIFICATION_CONSTS.UNIQUE_IDS.DATABROKER_GROUP_NOTIFICATION,
                    type: NOTIFICATION_CONSTS.TYPE.WARNING,
                    children: 'The groups are filtered by the group you selected to view from the sync card. Click “View All” to view all groups.',
                    onClose: () => setCloseNotification(true)
                });
            }
        } else {
            clearNotifications();
        }
    }, [_dataBrokersGroups, closeNotification, addNotification, clearNotifications, groupId]);


    const startDataBrokerWizard = (props) => {
        setPanelContent(<DataBrokerWizard
            closePanel={closePanel}
            {...props}
        />)
    };

    const clearFilterByGroupId = () => {
        navigate({
            pathname: `/manage`,
            search: window.location.search
        });
    }

    return (
        <div className="scrollable-area">
            {!showGroups && <MainLoader />}
            <div className='manage-groups'>
                <div className='add-container'><Button variant="primary" className="groups-add-broker-button" onClick={() => {
                    clearFilterByGroupId();
                    startDataBrokerWizard({ fromManageGroups: true })
                }}>Add New Data Broker</Button></div>
                {showGroups && <CardsContainer
                    className={"managed-data-broker-buttons"}
                    additionalData={{ extendCard }}
                    containerOptions={containerOptions}
                    Card={GroupCard}
                    data={showGroups}
                    menuItems={groupsMenu(startDataBrokerWizard, setDialog)}
                    outsideFilter={groupId ? {
                        fullLength: _dataBrokersGroups?.data?.length,
                        clear: clearFilterByGroupId
                    } : {}}
                />}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    _dataBrokersGroups: state.syncReducer._dataBrokersGroups
});

export default connect(mapStateToProps, { getDataBrokersGroups, addNotification, clearNotifications })(ManageGroups)