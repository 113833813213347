import React from "react";
import {useDialog} from "@netapp/shared-components";
import HowDoesItWorkDialog from "./HowDoesItWorkDialog";

const HowDoesItWorkButton = ({wizardType, isSecure}) => {
    const {setDialog} = useDialog();
    return <button type="button" className="outline how-it-works" onClick={(el) =>
        setDialog(<HowDoesItWorkDialog
            wizardType={wizardType}
            isSecure={isSecure}/>, el.target)}>
            How does it work?
    </button>
};

export default HowDoesItWorkButton;