import React from "react";
import HowDoesItWorkButton from '../../systemFlow/HowDoesItWorkButton';
import "./wizardStepHeaderNew.scss";

const WizardStepHeader = ({title, rightAction, _wizard}) => {
    const {wizardTypeForSystemFlow, isSecure} = _wizard;

    return (
        <div className="wizard-step-header">
            <div className="step-header-item">
                <div className="align-to-left">
                    <HowDoesItWorkButton wizardType={wizardTypeForSystemFlow} isSecure={isSecure}/>
                </div>
            </div>
            <div className="step-header-item header-title">
                <h3>{title}</h3>
            </div>
            <div className="step-header-item">
                <div className="align-to-right">
                    {rightAction}
                </div>
            </div>
        </div>
    )
};

export default WizardStepHeader;
