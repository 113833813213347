export const NOTIFICATION_CONSTS = {
    TYPE: {
        INFO: 'info',
        ERROR: 'error',
        SUCCESS: 'success',
        WARNING: 'warning',
        URGENT: 'urgent'
    },
    VARIANT: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary'
    },
    UNIQUE_IDS: {
        DASHBOARD_IS_FILTERED: 'DASHBOARD_IS_FILTERED',
        DATABROKER_NOTIFICATION: 'DATABROKER_NOTIFICATION',
        SERVER_STEP_NOTIFICATION: 'SERVER_STEP_NOTIFICATION',
        SHOW_DIRECTORIES_STEP_NOTIFICATION: 'SHOW_DIRECTORIES_STEP_NOTIFICATION',
        SHOW_OBJECT_STORAGE_STEP_NOTIFICATION: 'SHOW_OBJECT_STORAGE_STEP_NOTIFICATION',
        VOLUME_STEP_NOTIFICATION: 'VOLUME_STEP_NOTIFICATION',
        WEB_STEP_NOTIFICATION: 'WEB_STEP_NOTIFICATION',
        DATA_SENSE_STEP_NOTIFICATION: 'DATA_SENSE_STEP_NOTIFICATION',
        DRAG_SOURCE_ERROR_NOTIFICATION: 'DRAG_SOURCE_ERROR_NOTIFICATION',
        DATABROKER_GROUP_NOTIFICATION: 'DATABROKER_GROUP_NOTIFICATION',
        REPORTS_NOTIFICATION: 'REPORTS_NOTIFICATION',
        SYNC_SETTINGS_EDIT_NOTIFICATION: 'SYNC_SETTINGS_EDIT_NOTIFICATION'
    }
};