import React from "react"
import classNames from "classnames"
require("./viewToggleWidget.scss");

const ViewToggleWidget = (props) => {
    const {toggle, icon, className} = props;
    return (
        <div className={classNames(className, "view-toggle-widget")}>
            <button onClick={toggle} type="button"> <span>{icon}</span></button>
        </div>
    )
};

export default ViewToggleWidget;
