import React, { useEffect, useState } from "react";
import * as consts from "../../consts";
import { connect, useDispatch, useSelector } from 'react-redux';
import RelationshipTable from "./RelationshipTable";
import RelationshipCard from "./relationshipCard/RelationshipCard";
import CardsContainer from "./../cards/CardsContainer";
import { SWITCH } from "./../../utils/directives"
import SyncNowConfirmDialog from "./SyncNowConfirmDialog";
import AbortSyncConfirmDialog from "./AbortSyncConfirmDialog"
import DeleteRelationshipDialog from "./DeleteRelationshipDialog";
import SearchableTableContainer from "./../table/SearchableSelectableTable";
import { Button, useDialog } from "@netapp/shared-components";
import SubscriptionRequiredDialog from "./SubscriptionRequiredDialog";
import _ from "lodash";
import { clearWizardCache, getRelationships, clearNewWizard } from "../../store/wizard/action_creators";
import { clearCmIntegrationDetails, addNotification, clearNotifications } from "../../store/global/action_creators";
import { getRelationshipsWithGroupsAndRecommendations } from "../../utils/relationshipUtils";
import { noConnectionToAllBrokers } from "../../utils/dashboard-utils";
import useRunOnce from '../../hooks/useRunOnce';
import useRightPanel from '../../hooks/useRightPanel';
import DataBrokerWizard from '../dataBrokerWizard/DataBrokerWizard';
import MainLoader from '../_common_/MainLoader/MainLoader';
import { getDataBrokerAllowedType } from "../../utils/dataBrokersUtils";
import useRunOnceWhenTruthy from '../../hooks/useRunOnceWhenTruthy';
import { ReactComponent as GarbageIcon } from "./../../assets/svgs/garbage-bin-icon.svg";
import { ReactComponent as ReportIcon } from "./../../assets/svgs/report-icon.svg";
import { ReactComponent as PlusIcon } from "./../../assets/svgs/plus-circle-icon.svg";
import { ReactComponent as SettingsIcon } from "./../../assets/svgs/settings.svg";
import { ReactComponent as XIcon } from "./../../assets/svgs/x-circle-icon.svg";
import { ReactComponent as ArrowsIcon } from "./../../assets/svgs/rotating-arrows-icon.svg";
import { NOTIFICATION_CONSTS } from "../../consts/notification.consts";
import { RESPONSE_STATUS_RUNNING } from "../../consts";
import queryString from 'query-string';
import { MenuPopover } from '@netapp/shared-components';
import { default as RelationshipCardNew } from "../../syncNew/components/relationshipCard/relationshipCard";
import "./dashboard.scss";
import { useLazyGetRelationshipsQuery } from "../../syncNew/api/relationshipApi.slice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { apiSlice, skip } from "../../syncNew/api/api.slice";
import { accountInfoSelector } from "../../syncNew/store/selectors/accountInfo.selector";
import { FeatureFlags } from "../../syncNew/utils/featureFlagUtil";
import { resetWizard } from "../../syncNew/store/slices/wizard.slice";
//Exclude non-relevant relationship properties from the search filter, to avoid crash and improve performance (CS-5275)
import { useNavigate, useParams } from 'react-router-dom';

const keysToSearch = ["relationshipId", "source", "target", "groupInfo", "sourcePath", "targetPath", "name", "statusForSearch", "key", "value", "targetTags"];

const sortList = (relationships, column, ascending) => {
    let sortedRelationships;
    sortedRelationships = _.sortBy(relationships, [column]);
    return (ascending ? sortedRelationships : _.reverse(sortedRelationships));
}

const Dashboard = (props) => {
    const dispatch = useDispatch();
    //CS-7644 - refactor
    const accountId = useSelector(accountInfoSelector.accountId);
    // const { data: relationships } = useGetRelationshipsQuery(skip(accountId, FeatureFlags.CS_7671) && skipToken);
    const [triggerGetRelationshipsQuery, { data: relationships }] = useLazyGetRelationshipsQuery();
    //CS-7644 - refactor

    const navigate = useNavigate();

    const { _relationships, _ui, closeSearchWidget } = props;
    const { data } = _relationships;
    const { relationshipId: relationshipIdFromUrl } = useParams('relationshipId');
    const [relationshipId, setRelationshipId] = useState(() => {
        return null;
    })
    const [dashboardView, setDashboardView] = useState(consts.CARDS_VIEW)
    const [showRelationships, setShowRelationships] = useState(null)

    const { hasNewRelationship, shouldBlockCreatingRelationship } = _ui;
    //action creators:
    const { getRelationships, clearWizardCache, clearCmIntegrationDetails, clearNewWizard, addNotification, clearNotifications } = props;
    const { setDialog } = useDialog();

    const { setPanelContent, closePanel } = useRightPanel();

    const updateRelationshipId = (relationshipId) => {
        setDashboardView(consts.CARDS_VIEW)
        setRelationshipId(relationshipId)
        notifyFilteredDashboard(relationshipId)
    }

    const startDataBrokerWizard = (props) => {
        setPanelContent(<DataBrokerWizard
            closePanel={closePanel}
            {...props}
        />)
    };

    useRunOnceWhenTruthy(() => {
        if (relationshipId !== relationshipIdFromUrl)
            updateRelationshipId(relationshipIdFromUrl);
    }, relationshipIdFromUrl)

    useEffect(() => {
        const triggerRelationshipCall = () => {
            if (!(skip(accountId, FeatureFlags.CS_7671) && skipToken)) {
                triggerGetRelationshipsQuery();
            }
        }

        dispatch(resetWizard());
        dispatch(apiSlice.util.resetApiState());
        triggerRelationshipCall();
    }, [dispatch, triggerGetRelationshipsQuery, accountId]);

    useEffect(() => {
        if (_relationships?.data) {
            if (relationshipId) {
                const filteredByRelationship = _.filter(_relationships?.data, { id: relationshipId })
                setShowRelationships(filteredByRelationship)
                setDashboardView(consts.CARDS_VIEW)
            }
            else {
                setShowRelationships(_relationships?.data)
            }
        }
    }, [_relationships, relationshipId, relationshipIdFromUrl, setDashboardView])

    const notifyFilteredDashboard = (relationshipId) => {
        if (!_relationships.inProgress &&
            showRelationships &&
            (relationshipId || relationshipIdFromUrl)) {
            addNotification({
                id: NOTIFICATION_CONSTS.UNIQUE_IDS.DASHBOARD_IS_FILTERED,
                type: NOTIFICATION_CONSTS.TYPE.WARNING,
                children: 'The dashboard is filtered by the sync you selected to view in the table. Click “View All” to view all syncs.',
                onClose: () => clearNotifications()
            });
        } else {
            clearNotifications();
        }
    }

    useRunOnce(() => {
        getRelationships(); //will also get the data brokers groups

        clearWizardCache({ clearGroups: false });//make sure all Credentials are cleared from before
        clearCmIntegrationDetails();//make sure all entry point input details are cleared
        clearNewWizard();
    });

    useRunOnceWhenTruthy(() => {
        const queryParams = queryString.parse(window.location.search);
        const fromCmIntegration = _.get(queryParams, 'fromCmIntegration', null);
        //navigate only once to drag source and target -- http://jira.tlveng.netapp.com/browse/CS-5675
        if (!fromCmIntegration) {
            navigate({
                pathname: `/${consts.ROUTES.DRAG_SOURCE_AND_TARGET}`,
                search: window.location.search
            });
        }
    }, !hasNewRelationship && _relationships.succeeded && data?.length === 0);

    useEffect(() => {
        if (hasNewRelationship && data) {
            getRelationships();
        }
    }, [hasNewRelationship, data, getRelationships]);

    const toggleSwitch = () => {
        if (relationshipId) {
            setRelationshipId(null);
        }
        setDashboardView(dashboardView === consts.CARDS_VIEW ? consts.TABLE_VIEW : consts.CARDS_VIEW);
        clearNotifications();
    };

    const applySort = (column, ascending, relationships) => {
        setShowRelationships(sortList(relationships ? relationships : showRelationships, column, ascending));
    };

    const containerOptions = {
        singularTitle: "Sync",
        pluralTitle: "Syncs",
        viewToggle: toggleSwitch,
        keysToSearch,
        sort: { from: "dashboard", key: "name" },
    };

    const menuItems = () => {
        const deleteAndReportButton = [
            <button type="button"
                onClick={(el) => {
                    setDialog(<DeleteRelationshipDialog data={showRelationships} />, el.target);
                }}
                disabled={!showRelationships || showRelationships.length === 0} >
                <span className="garbage-bin"><GarbageIcon /></span>
                <span>Delete</span>
            </button>
        ];

        return deleteAndReportButton;
    }

    return (
        <div className="scrollable-area">
            <div className={`sync-dashboard ${dashboardView === consts.TABLE_VIEW ? 'table' : ''}`}>
                <div className="clearfix dashboard-action-header">
                    <Button
                        type="button"
                        onClick={(el) => createNewRelationship(el, shouldBlockCreatingRelationship, setDialog, navigate)}
                        className="create-button">
                        {"Create New Sync"}
                    </Button>
                    <MenuPopover
                        popoverClass={`menu-popover`}
                        className='dashboard-menu'
                        triggerVariant="card"
                        menuItems={menuItems(data)}
                    />
                </div>
                <SWITCH on={_relationships.inProgress || !showRelationships}>
                    <div data-when={true}>
                        <MainLoader />
                    </div>
                    <div data-when={false}>
                        <div>
                            {dashboardView === consts.TABLE_VIEW &&
                                <SearchableTableContainer containerOptions={containerOptions} Table={{
                                    renderRow: RelationshipTable.renderRow,
                                    getTableHeader: () => RelationshipTable.getTableHeader(applySort)
                                }}
                                    data={showRelationships} isMenuDisabled={false}
                                    moreActions={{ updateRelationshipId: updateRelationshipId.bind(this) }}
                                    menuItems={relationshipMenu(startDataBrokerWizard, setDialog, navigate)}
                                />}
                            {relationships && <RelationshipCardNew relationship={relationships[0]} />}
                            {dashboardView === consts.CARDS_VIEW &&
                                <CardsContainer containerOptions={containerOptions} Card={RelationshipCard}
                                    data={showRelationships} isMenuDisabled={false}
                                    menuItems={relationshipMenu(startDataBrokerWizard, setDialog, navigate)}
                                    outsideFilter={relationshipId ? {
                                        fullLength: _relationships?.data?.length,
                                        clear: () => {
                                            closeSearchWidget();
                                            setRelationshipId(null)
                                            notifyFilteredDashboard()
                                        }
                                    } : {}}

                                />
                            }
                        </div>
                    </div>
                </SWITCH>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    _relationships: getRelationshipsWithGroupsAndRecommendations(state.syncReducer._relationships, state.syncReducer._dataBrokersGroups) || {},
    _dataBrokersGroups: state.syncReducer._dataBrokersGroups,
    _ui: { ...state.syncReducer._ui, ...state.global._ui }
});

export default connect(mapStateToProps, {
    getRelationships,
    clearWizardCache,
    clearNewWizard,
    clearCmIntegrationDetails,
    addNotification,
    clearNotifications
})(Dashboard);

const createNewRelationship = (element, shouldBlockCreatingRelationship, setDialog, navigate) => {
    // check if user should blocked from creating a new relationship
    if (shouldBlockCreatingRelationship) {
        setDialog(<SubscriptionRequiredDialog
            errorMessage={shouldBlockCreatingRelationship} navigate={navigate} />,
            element.target);
        return;
    }

    navigate({
        pathname: `/${consts.ROUTES.DRAG_SOURCE_AND_TARGET}`,
        search: window.location.search
    });
};

const relationshipMenu = (startDataBrokerWizard, setDialog, navigate) => {
    return (element) => {
        const relationshipId = element.relationshipId;
        const syncProgress = element.ui.status === "RUNNING" || element.ui.status === "ABORTING" || element.ui.status === "STARTING";
        const syncAborting = element.ui.status === "ABORTING";
        const continuousSync = element.settings.continuousSync && element.ui.status !== 'FAILED';
        const failedDataBroker = _.includes(element.ui.failureMessage, consts.FAILED_AFTER_STARTUP_MESSAGE);
        const allBrokersAreDown = noConnectionToAllBrokers(element.groupInfo?.dataBrokers);
        const disableTitleSyncNow = failedDataBroker ? element.ui.failureMessage : allBrokersAreDown ? "You must have at least one available data broker." : "";
        const isReady = !syncProgress && !failedDataBroker && !allBrokersAreDown;
        const existingGroupId = element.ui.groupId;
        const allowedAgentType = getDataBrokerAllowedType(element.groupInfo?.dataBrokers);
        const isEncryptedRelationship = !!element.encryption;
        const isGcpAccessRequired = element.source.protocol === "gcp" || element.target.protocol === "gcp";
        const isAwsAccessRequired = (element.source.protocol === "s3" && element.source[element.source.protocol].provider === "s3") ||
            (element.target.protocol === "s3" && element.target[element.target.protocol].provider === "s3");
        const { dataBrokers } = element.groupInfo || {};
        const showUnknown = element.ui.status === RESPONSE_STATUS_RUNNING && noConnectionToAllBrokers(dataBrokers);
        const isDataSense = element.ui.isDataSense;

        const deleteAndReportButton = [
            <button type="button" disabled={!!isDataSense || !!continuousSync} onClick={() => {
                isDataSense || continuousSync ? _.noop() : navigate({
                    pathname: `/reports/${relationshipId}`,
                    search: window.location.search
                });
            }}>
                <span><ReportIcon /></span>
                <span>Report</span>
            </button>,
            <button type="button"
                onClick={(el) => {
                    setDialog(<DeleteRelationshipDialog data={[element]} />, el.target);
                }}>
                <span className="garbage-bin"><GarbageIcon /></span>
                <span>Delete</span>
            </button>
        ];
        const encryptedMsg = "Accelerating sync performance is currently not supported for relationships that use data-in-flight encryption.";
        const nonEncryptedMsg = "Enhance sync performance by adding an additional data broker.";


        const accelerateAndSettingsButtons = [
            <button type="button"
                className="add-data-broker"
                title={isEncryptedRelationship ? encryptedMsg : nonEncryptedMsg}
                disabled={isEncryptedRelationship}
                onClick={() => {
                    startDataBrokerWizard({
                        existingGroupId,
                        allowedAgentType,
                        isGcpAccessRequired,
                        isAwsAccessRequired
                    });
                }}
            >
                <span className="plus-circle"><PlusIcon /></span>
                <span>Accelerate</span>
            </button>,

            <button type="button"
                title="View and edit sync relationship settings"
                disabled={continuousSync}
                onClick={() => {
                    navigate({
                        pathname: `/settings/${relationshipId}`,
                        search: window.location.search
                    });
                }}>
                <span className="settings"><SettingsIcon /></span>
                <span>Settings</span>
            </button>
        ];

        const syncOrAbortButton = (syncProgress || syncAborting || continuousSync) ?
            [<button type="button"
                title={"Abort current sync"}
                disabled={syncAborting || showUnknown || continuousSync}
                onClick={(el) => {
                    setDialog(<AbortSyncConfirmDialog data={element} />, el.target);
                }}>
                <span className="clock"><XIcon /></span>
                <span>Abort Sync</span>
            </button>]
            :
            [<button type="button"
                title={disableTitleSyncNow}
                disabled={!isReady}
                onClick={(el) => {
                    setDialog(<SyncNowConfirmDialog data={element} />, el.target);
                }}>
                <span className="rotating-arrows"><ArrowsIcon /></span>
                <span>Sync Now</span>
            </button>];

        return syncOrAbortButton.concat(accelerateAndSettingsButtons).concat(deleteAndReportButton);
    }
};
