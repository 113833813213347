import * as api from './gcp.api.service';
import _ from 'lodash';


export const startDeployProcess = async (deployDetails, deployBodyContent) => {
    try {
        const {projectId, region, zone, network, subnetwork, serviceAccount, deploymentName, proxyHost, proxyPort, proxyUsername, proxyPassword, enablePublicIP} = deployDetails;

        _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
        const template = _.template(deployBodyContent.config.content);
        const compliedTemplate = template({
            project: projectId,
            region,
            zone,
            subnetwork,
            network,
            serviceAccount,
            proxyHost,
            proxyPort,
            proxyUsername,
            proxyPassword,
            enablePublicIP
        });


        const payload = {
            name: deploymentName,
            target: {
                config: {
                    content: compliedTemplate
                },
                imports: deployBodyContent.imports
            }
        };

        return await api.deploy(payload, deployDetails.projectId);
    } catch (err) {
        throw err;
    }
};

export const rollback = async (projectId, deploymentName) => {
    try {
        //stop is needed in case the user wants to delete an on-going deployment
        const deployment = await api.getDeployment(projectId, deploymentName);
        const {fingerprint} = deployment;
        const stopResponse = await api.stopDeployment(projectId, deploymentName, {fingerprint});
        await waitForOperationToComplete(projectId, stopResponse.name);
    }
    catch {
        // eslint-disable-next-line
        console.log('Could not stop deployment');
    }
    const deleteResponse = await api.deleteDeployment(projectId, deploymentName);
    await waitForOperationToComplete(projectId, deleteResponse.name);
};

export const waitForOperationToComplete = async (projectId, operationName) => {
    const timeout = ms => new Promise(res => setTimeout(res, ms));
    let done = false;
    while (!done) {
        const statusRes = await api.getOperationStatus(
            projectId,
            operationName
        );
        done = statusRes.status === 'DONE';
        if (!done) {
            await timeout(5000);
        } else {
            return statusRes;
        }
    }
};