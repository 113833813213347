import {store} from '../store/store';
import {getAllDataBrokers} from "./dataBrokersUtils";
import {find, isEqual, keys, pick} from 'lodash'
import {PROTOCOLS_SUPPORT_EDIT_CREDENTIALS, PROVIDERS_SUPPORT_EDIT_CREDENTIALS} from "../consts";

const AZURE = "azure";

export const getAccountId = () => store.getState().global._accountInfo.data.accountId;

export const getAnfWorkingEnvironments = (cvsWorkingEnvironments) => {
    if (!cvsWorkingEnvironments || !cvsWorkingEnvironments.data) {
        return [];
    }
    return cvsWorkingEnvironments.data
        .filter(we => we.provider === AZURE && !we.error)
        .map(we => ({...we, id: we.weId}))
};

export const getCvoWorkingEnvironments = (workingEnvironments) => {
    if (!workingEnvironments || !workingEnvironments.data) {
        return [];
    }
    const allWe =  [...workingEnvironments.data.azureVsaWorkingEnvironments, ...workingEnvironments.data.gcpVsaWorkingEnvironments, ...workingEnvironments.data.vsaWorkingEnvironments];
    return allWe.filter(we => we.status?.status === "ON" || we.status?.status === "DEGRADED").map( (we) => ({...we, id:we.publicId}));
};

export const getOnpremWorkingEnvironments = (workingEnvironments) => {
    if (!workingEnvironments || !workingEnvironments.data) {
        return [];
    }
    const allWe =  [...workingEnvironments.data.onPremWorkingEnvironments];
    return allWe.filter(we => we.status?.status === "ON" || we.status?.status === "DEGRADED").map( (we) => ({...we, id:we.publicId}));
}

export const getFsxWorkingEnvironmentsWithId = (workingEnvironments) => {
    if (!workingEnvironments) {
        return [];
    }
    return workingEnvironments.filter(we => we.providerDetails?.status?.status === "ON" || we.providerDetails?.status?.status === "DEGRADED");
}

export const getBucketRegion = (_showBuckets, dataBrokerId, bucketName) => {
    const bucketsPerDataBroker = _showBuckets[dataBrokerId]?.data;
    const bucket = find(bucketsPerDataBroker, {name: bucketName});
    return bucket?.region?.name;
};

export const getCmIntegrationRightPanelDetails = () => store.getState().global._cmIntegration.inputDetails;

export const getDataBrokerById = (_dataBrokersGroups, id) => {
    if (!_dataBrokersGroups?.data || _dataBrokersGroups.data.length === 0) return null;
    const allDataBrokers = getAllDataBrokers(_dataBrokersGroups.data);
    return find(allDataBrokers, {id});
};

export const getDataBrokerNameById = (dataBrokerId) => {
    const dataBroker = getDataBrokerById(store.getState().syncReducer._dataBrokersGroups, dataBrokerId);
    return dataBroker?.name;
};

export const extractConfigurationFromGroupOrPlacement = (data) => {
    if (!data) return null;

    const configuration = {};
    if (data?.scanner?.concurrency) configuration.scannerConcurrency = data.scanner.concurrency;
    if (data?.scanner?.processesLimit) configuration.scannerProcessesLimit = data.scanner.processesLimit;
    if (data?.transferrer?.concurrency) configuration.transferrerConcurrency = data.transferrer.concurrency;
    if (data?.transferrer?.processesLimit) configuration.transferrerProcessesLimit = data.transferrer.processesLimit;
    return configuration;
}

export const compareConfiguration = (placement, configuration) => {
    const configurationKeys = keys(configuration);
    const pickedKeysFromPlacement = pick(placement, configurationKeys);
    return isEqual(pickedKeysFromPlacement, configuration);
}


export const shouldSupportCredentialsEdit = (protocol, provider) => {
    return PROTOCOLS_SUPPORT_EDIT_CREDENTIALS.includes(protocol) && PROVIDERS_SUPPORT_EDIT_CREDENTIALS.includes(provider)
}
