import React from 'react';
import { SelectField } from "../../../_common_/forms/forms";
import { Field } from 'react-final-form';
import { ReactComponent as XIcon } from "../../../../assets/svgs/x.svg";
import EncryptionSetting from '../../../../syncNew/components/encryptionSetting/encryptionSetting';
import './AdditionalSettingsUINew.scss';

const AdditionalSettingsUI = (props) => {
    const { step, isSourceStep, bucketPath, handleClose, form, handleOptionSelection, selectedOption, selectionOptions, title, icon, optionsTitle, encryptionSetting } = props;
    const actualSelectedOption = selectionOptions.find((option) => option.value === selectedOption);
    const { isShowEncryption, platformName, data, validator, onEncryptTypeChanged } = encryptionSetting || {};

    return step === "secondStep" && !isSourceStep ? (
        <div className="bucket-setup additionalSettingsUINew">
            <div className="bucket-info">
                <div className="title">
                    {title}:
                </div>
                <div className="bucket-name-container">
                    <div className="bucket-name">
                        {icon}
                        <div>{bucketPath}</div>
                    </div>
                    <div className="close-step" onClick={() => handleClose(form)}>
                        <XIcon />
                    </div>
                </div>
            </div>
            <div className="setup-container">
                <div className='additionalSettings'>
                    {isShowEncryption && <EncryptionSetting 
                        platformName={platformName}
                        encryptSettings={data} 
                        validator={validator}
                        onEncryptTypeChanged={onEncryptTypeChanged} />}
                    <div className="blob-tier-container">
                        <div className="title">{optionsTitle}</div>
                        <Field component={SelectField} onChange={handleOptionSelection}
                            valueField="value" name="blobTier" options={selectionOptions}
                            selectedValue={actualSelectedOption}
                            className="storage-classes" />
                    </div>
                </div>

            </div>
        </div>) : null;
};

export default AdditionalSettingsUI;
