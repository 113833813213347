import React, { useEffect, useMemo, useState } from 'react';
import {
    clearSync,
    clearWizardCache,
    createSync,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
} from './../../../store/wizard/action_creators';
import { connect, useSelector } from 'react-redux';
import { buildSyncObject, getSourceAndTargetInfo } from './../../../utils/sync-utils';
import { isNull, isEmpty } from 'lodash';
import { credentialsRequiredOnDataBroker } from './../../../utils/reducerUtils';
import classNames from 'classnames';
import LoadingBar from './../../_common_/loadingBar/LoadingBar';
import { FlowEncrypted } from './../../systemFlow/SystemFlow';
import useRunOnceWhenTruthy from './../../../hooks/useRunOnceWhenTruthy';
import { getPropFromState } from './../../../utils/mapStateToPropsUtils';
import { ReactComponent as ArrowRight } from "./../../../assets/svgs/corner-square-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "./../../../assets/svgs/corner-square-arrow-left.svg";

import './relationshipStepNew.scss';
import { getWizardParamsFromWizardState } from "../../../utils/mapStateToPropsUtils";
import ReviewEndpointCard from "./ReviewEndpointCardNew";
import ReviewGroupCard from "./ReviewGroupCardNew";
import ButtonsPanel from "../../widgets/ButtonsPanel";
import { wizardSelector } from '../../../syncNew/store/selectors/wizard.selector';
import { useNavigate, useLocation } from 'react-router-dom';

const REVIEW = 'REVIEW';
const CREATING = 'CREATING';
const CREATED = 'CREATED';
const PENDING = 'PENDING';
const FAILED = 'FAILED';
const RUNNING_CONTINUOUSLY = 'RUNNING_CONTINUOUSLY';
const FAILED_TO_START = 'FAILED_TO_START';

const getInfoPerStatus = (status, sync) => {
    switch (status) {
        case CREATING:
            return {
                headerText: 'Creating Relationship',
                buttonText: 'View in Dashboard',
                additionalText: `${sync?.percentCopied || 0}%`,
                additionalTextClass: 'percentage',
                nameDisabled: true
            };
        case CREATED:
            return {
                headerText: 'Relationship Successfully Created',
                buttonText: 'View in Dashboard',
                additionalText: '100%',
                additionalTextClass: 'percentage',
                nameDisabled: true
            };
        case PENDING:
            return {
                headerText: 'Relationship Successfully Created',
                buttonText: 'View in Dashboard',
                additionalText: sync ? `Sync will start at ${sync.startTime}` : "",
                additionalTextClass: 'pending',
                nameDisabled: true
            };
        case RUNNING_CONTINUOUSLY:
            return {
                headerText: 'Creating Relationship',
                buttonText: 'View in Dashboard',
                additionalText: 'Relationship Running Continuously',
                additionalTextClass: 'pending',
                nameDisabled: true
            };
        case FAILED:
            return {
                headerText: 'Creating Relationship',
                buttonText: 'View in Dashboard',
                additionalText: 'Sync Failed',
                additionalTextClass: 'failed',
                nameDisabled: true
            };
        case FAILED_TO_START:
            return {
                headerText: 'Review Sync Relationship',
                buttonText: 'Create Relationship',
                additionalText: 'Sync Failed',
                additionalTextClass: 'failed',
                nameDisabled: false
            };
        case REVIEW:
        default:
            return {
                headerText: "Review Sync Relationship",
                buttonText: 'Create Relationship',
                additionalText: '',
                additionalTextClass: '',
                nameDisabled: false
            };
    }
};

const RelationshipStep = props => {
    const { clearSync, clearWizardCache, up, _wizard } = props;
    const { currentStep, wizardInfo, wizardTypeForSystemFlow, sync, selectedWizardParams: {
        selectedBucketPath, selectedBucketPathTarget, selectedDataBroker, selectedDataBrokerTarget, selectedIsTargetDataBrokerInitiator }
    } = _wizard;
    const { succeeded, isPending } = sync;
    const isTargetOfSameSource = wizardInfo.source.protocol === wizardInfo.target.protocol;
    const { sourceInfo, targetInfo } = getSourceAndTargetInfo(props);
    const submitting = sync && sync.inProgress;
    const targetGcpSelectedEncryption = useSelector(wizardSelector.targetGcpSelectedEncryption);

    const navigate = useNavigate();
    const location = useLocation();

    const status = useMemo(() => {
        if (sync?.completed) return CREATED;
        if (sync?.isPending) return PENDING;
        if (sync?.syncContinuously) return RUNNING_CONTINUOUSLY;
        if (sync?.failed && sync?.succeeded) return FAILED;
        if (sync?.failed) return FAILED_TO_START;
        if (sync?.succeeded) return CREATING;
        return REVIEW; //this is the initial status
    }, [sync]);

    const [relationshipName, setRelationshipName] = useState("");

    const syncStarted = sync?.succeeded;

    const { headerText, additionalText, additionalTextClass, buttonText, nameDisabled } = getInfoPerStatus(status, sync);

    const gotoDashboard = () => {
        navigate({
            pathname: '/dashboard',
            search: window.location.search
        });
        clearWizardCache({ clearGroups: false });
    };

    const handleClick = () => {
        if (status === REVIEW || status === FAILED_TO_START) {
            const { _wizard, createSync, _showBuckets, _showBoxFolders, _showGoogleDrives, _cmIntegration: { dataSense } } = props;
            const syncObject = buildSyncObject({ _wizard, _showBuckets, _showBoxFolders, _showGoogleDrives, targetGcpSelectedEncryption });
            syncObject.tags = relationshipName && !isEmpty(relationshipName) ? [{ key: "name", value: relationshipName }] : []
            if (dataSense) {
                syncObject.extraInformation = {
                    connectorId: dataSense.connectorId,
                    dataSenseQueryUid: dataSense.hash
                }
            }
            createSync(syncObject);
        } else {
            gotoDashboard();
        }
    };

    useRunOnceWhenTruthy(() => {
        clearSync();
    }, true);

    useEffect(() => {
        if (succeeded && !isPending) {
            navigate({
                pathname: location.pathname,
                search: `${window.location.search}&inProgress=true`
            });
        }
    }, [succeeded, isPending, location.pathname, navigate]);

    useEffect(() => {
        if (credentialsRequiredOnDataBroker('s3', wizardInfo) && currentStep === 'review' && isNull(isTargetOfSameSource ? selectedBucketPath && selectedBucketPathTarget : selectedBucketPath)) {
            //if s3 bucket was added manually and wizard was refreshed
            const separator = isNull(selectedBucketPath) ? 'buckets' : 'targetbuckets';
            const redirectToBuckets = location.pathname.split(separator)[0];
            const reducerUrl = redirectToBuckets ? redirectToBuckets + separator : '/dashboard';
            navigate({
                pathname: reducerUrl,
                search: window.location.search
            })
        }
    }, [isTargetOfSameSource, selectedBucketPath, selectedBucketPathTarget, location.pathname, currentStep, wizardInfo, navigate]);

    return (<div className={classNames('wizard-step relationship-step', { 'sync-started': syncStarted })}>
        {status === CREATING && <LoadingBar />}
        <div className={classNames("scrollable-area", { up, down: !up })}>
            <div className="top-panel">
                <h3 className="review-title">{headerText}</h3>
                <div className="relationship-name">
                    <input name="relationshipName" onChange={(event) => {
                        setRelationshipName(event.target.value);
                    }} placeholder="Name your relationship" disabled={nameDisabled || submitting} />
                    <label htmlFor="relationshipName">(Optional)</label>
                </div>
            </div>
            {selectedDataBrokerTarget &&
                <div className="secure-nfs-relationship">
                    <div className="diagram">
                        <FlowEncrypted wizardType={wizardTypeForSystemFlow} />
                    </div>
                    <div className="databrokers-function-pair">
                        <div
                            className="databroker-function source"><div>{(selectedIsTargetDataBrokerInitiator === 'true') ? '(Listener)' : '(Initiator)'}</div></div>
                        <div
                            className="databroker-function target"><div>{(selectedIsTargetDataBrokerInitiator === 'true') ? '(Initiator)' : '(Listener)'}</div></div>
                    </div>
                    <div className="secure-relationship-description-pair">
                        <div className="secure-relationship-description source">
                            <div className="field-name">
                                <div>{`${sourceInfo.title} :`}</div>
                                {sourceInfo.description}
                            </div>
                            <div className="field-name">
                                <div>NFS Directory:</div>
                                <div>{`${sourceInfo.description} ${sourceInfo.subtitleDescription}`}</div>
                            </div>
                            <div className="field-name">
                                <div>Data Broker Group:</div>
                                <div title={selectedDataBroker.name}>{`${selectedDataBroker.name}`}</div>
                            </div>
                            {selectedDataBroker.dataBrokers[0].port && <div className="field-name">
                                <div>Data Broker Port:</div>
                                <div>{`${selectedDataBroker.dataBrokers[0].port}`}</div>
                            </div>}
                        </div>
                        <div className="secure-relationship-description target">
                            <div className="field-name">
                                <div>{`${targetInfo.title} :`}</div>
                                <div>{targetInfo.description}</div>
                            </div>
                            <div className="field-name">
                                <div className="field-name">NFS Directory:</div>
                                <div>{`${targetInfo.description} ${targetInfo.subtitleDescription}`}</div>
                            </div>
                            <div className="field-name">
                                <div className="field-name">Data Broker Group:</div>
                                <div title={selectedDataBrokerTarget.name}>{`${selectedDataBrokerTarget.name}`}</div>
                            </div>
                            {selectedDataBrokerTarget.dataBrokers[0].port && <div className="field-name">
                                <div className="field-name">Data Broker Port:</div>
                                <div>{`${selectedDataBrokerTarget.dataBrokers[0].port}`}</div>
                            </div>}
                        </div>
                    </div>
                </div>}

            {!selectedDataBrokerTarget &&
                <div className="cards">
                    <div className="end-points-area">
                        <ReviewEndpointCard protocol={wizardInfo.source.protocol} provider={wizardInfo.source.provider}
                            title={sourceInfo.title}
                            description={sourceInfo.description} subtitle={sourceInfo.subtitle}
                            subtitleDescription={sourceInfo.subtitleDescription} />
                        <ReviewEndpointCard protocol={wizardInfo.target.protocol} provider={wizardInfo.target.provider}
                            title={targetInfo.title}
                            description={targetInfo.description} subtitle={targetInfo.subtitle}
                            subtitleDescription={targetInfo.subtitleDescription} />
                    </div>
                    <div className="data-broker-area">
                        <div className="data-broker-container">
                            <ArrowLeft className="left-arrow" />
                            <div className="data-broker-card-position">
                                <ReviewGroupCard selectedGroup={selectedDataBroker} />
                            </div>
                            <ArrowRight className="right-arrow" />
                        </div>
                    </div>
                </div>}

            <div className={`additional-text ${additionalTextClass}`}>{additionalText}</div>

        </div>

        <ButtonsPanel
            variant="white"
            type="button"
            onClick={handleClick}
            submitting={submitting}
            text={buttonText} />

    </div>);
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        _wizard: getWizardParamsFromWizardState(state, ownProps, ownProps.state)._wizard,
        routing: state.router,
        _routing: getPropFromState(state, "_routing", {}),
        _ui: { ...state.syncReducer._ui, ...state.global._ui },
        _showBuckets: getPropFromState(state, "_showBuckets", {}),
        _showBoxFolders: getPropFromState(state, "_showBoxFolders", {}),
        _showGoogleDrives: getPropFromState(state, "_showGoogleDrives", {}),
        _cmIntegration: state.global._cmIntegration,
    }
};

const mapDispatchToProps = {
    clearSync,
    clearWizardCache,
    createSync,
    updateUrlParams,
    wizardStepStarted,
    wizardStepExited
};

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipStep);
