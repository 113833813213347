import React, {useState, useMemo} from "react";
import classNames from "classnames";
import {MenuPopover} from '@netapp/shared-components';
import {useDialog} from "@netapp/shared-components";
import { ReactComponent as ExpandIcon} from '../../assets/svgs/collapse-arrow.svg'
import { ReactComponent as ExpandedIcon} from '../../assets/svgs/collapse-arrow-down.svg'
import _ from "lodash";

const CardItem = (props) => {
    const {Card, data, selected, selector, menuItems, isMenuDisabled, additionalData, customClass = ""} = props;
    const extendable = useMemo(() => _.isFunction(Card.renderCollapsed), [Card]);
    const shouldExtendCard = extendable && Card.getInitiallyExpanded(data, additionalData);
    const [collapsed, setCollapsed] = useState(extendable && !shouldExtendCard); //if the card is extendable, show it collapsed at first, or not if extendCard arrived in additionalData
    const {setDialog} = useDialog();

    const isSelectionDisabled = !selector || (_.isFunction(Card.isSelectionDisabled) ? Card.isSelectionDisabled(data) : false);
    const disableMenu = !menuItems || (_.isFunction(isMenuDisabled) ? isMenuDisabled(data) : false);
    const cardClass = classNames(customClass, {
        selectable: !isSelectionDisabled,
        selected,
        menuable: !!menuItems
    }, _.isFunction(Card.className)? Card.className(data) : Card.className);


    return (
        <div className={classNames(cardClass, {collapsed})}>
            {extendable && <div className="collapse-button" onClick={() => setCollapsed(!collapsed)}>
                {collapsed && <ExpandIcon/>}
                {!collapsed && <ExpandedIcon/>}
            </div>}
            {!disableMenu && <div className="card-menu">
                <MenuPopover
                    popoverClass={`menu-popover ${customClass}`}
                    triggerVariant="card"
                    menuItems={menuItems(data)}
                />
            </div>}

            {collapsed && <div className="card-body" onClick={() => selector && !isSelectionDisabled ? selector(data.id) : _.noop()}>{Card.renderCollapsed(data, additionalData)}</div>}
            {!collapsed && selector && !isSelectionDisabled && <div type="button" className="card-body" onClick={() => selector(data.id)}>{Card.renderContent({data, additionalData, setDialog})}</div>}
            {!collapsed && (!selector || isSelectionDisabled) && <div className="card-body">{Card.renderContent({data, additionalData, setDialog})}</div>}
        </div>
    );

};

export default CardItem;
