import React from 'react';
import {Field } from 'react-final-form';
import {InputField} from "../_common_/forms/forms";
import Validator from 'validatorjs';
import ConfirmFormDialogNew from "../dialog/ConfirmFormDialogNew";
import "./newBucketDialog.scss";

const rules = (validatorRules) => {
    return {
        bucketName: validatorRules
    }
};

const validate = (values, props) => {
    const {validatorRules} = props;
    const validator = new Validator(values, rules(validatorRules));
    validator.setAttributeNames({bucketName : 'Bucket Name' });
    validator.passes();
    return validator.errors.all();
};


const NewBucketDialog = (props) => {
    const {onSubmitFunction, accountPrefix, bucketPrefix, requirementsUrl} = props;
    const placeholder = "Bucket Name";
    return (
        <ConfirmFormDialogNew
            title="Add A Bucket Manually"
            activateSubmit={onSubmitFunction}
            confirmText="Add"
            layoutClassName="new-bucket-dialog"
            validate={validate}
            {...props}
        >

            <div className="new-bucket-instructions">
                <div>Enter the name of a bucket that is not associated with your {accountPrefix} account.</div>
                <div>Specific permissions must be applied to the {bucketPrefix} bucket. </div>
                <div>View the <a href={requirementsUrl} rel="noopener noreferrer" target="_blank">permission requirements</a>.</div>
            </div>

            <Field component={InputField} className="bucket-name" name="bucketName" placeholder={placeholder} label={placeholder}/>

        </ConfirmFormDialogNew>
    )
};

export default NewBucketDialog;
