import moment from "moment";
import {TEXT_DURATION_FEW_SECONDS} from "../consts";
import _ from "lodash";

export const stringDuration = duration => {
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);
    let t = moment.duration(duration).humanize();
    if(t === "a few seconds"){
        t = TEXT_DURATION_FEW_SECONDS
    }
    return t;
};

export const fromNowNoFuture = (time) => {
    const d = moment(time);
    if (d.diff(moment()) > 0) return "Just now";
    else return _.capitalize(d.fromNow());
};
