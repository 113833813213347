import React, {useState, useEffect} from 'react';
import Isvg from 'react-inlinesvg';
import classNames from "classnames";
import {useDrop} from 'react-dnd';
import { DraggableTypes} from "../../../consts";
import SmallLoader from '../../_common_/SmallLoader/SmallLoader';
import {ReactComponent as XIcon} from "../../../assets/svgs/x-delete-icon.svg";
import {ReactComponent as DropIconSource} from "../../../assets/svgs/drop-source-icon.svg";
import {ReactComponent as DropIconTarget} from "../../../assets/svgs/drop-target-icon.svg";
import {clearCmIntegrationDetails} from '../../../store/global/action_creators'; 
import {useDispatch} from 'react-redux';

import "./dropEndPointNew.scss";

const DropEndPointNew = ({title, className, endPoint, handleEndPointSelection, setEndPoint, showLoaderCondition, fromRightPanel, isDropSource, onDrop}) => {
    const [showSelector, setShowSelector] = useState(false);
    const dispatch = useDispatch();

    const [{ isOver}, drop] = useDrop(() => ({
        accept: DraggableTypes.END_POINT,
        drop: () => ({dropEndPoint: title}),
        canDrop() {
            //allow dropping only when there is no end point yet
            return !endPoint;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        }),
    }), [endPoint])


    //decide if need to show the ProtocolSelector
    useEffect(() => {
        setShowSelector(endPoint?.protocolOptions);
    }, [endPoint]);

    const unselectEndPoint = (fromRightPanel) => {
        handleEndPointSelection(null);
        //if the endpoint is cleared need to clear also the integration details that were set.
        if (fromRightPanel) {
            return clearCmIntegrationDetails({endPoint:isDropSource ? 'source':'target' })(dispatch);
        }
    };

    return (<div className={classNames("add-end-point", className)} ref={drop}>

            <div className={`add-box ${isDropSource ? 'source' : 'target'}`}>
                {!endPoint &&
                <>
                    {!isOver && isDropSource && <div className="drop-box"><DropIconSource className="add-box-svg"/></div>}
                    {isOver && isDropSource && <div className="drop-box is-hover"><DropIconSource className="add-box-svg"/></div>}
                    {!isOver && !isDropSource && <div className="drop-box"><DropIconTarget className="add-box-svg"/></div>}
                    {isOver && !isDropSource && <div className="drop-box is-hover"><DropIconTarget className="add-box-svg"/></div>}
                    <div className={classNames("text", {"is-hover" : isOver})}>{title}</div>
                </>}

                <div className={`dropped-endpoint-container ${endPoint ? 'show' : ''}`}>
                    {endPoint && <div className="dropped-endpoint">
                        <div className="provider-box">
                            <div className="top-area">
                                {endPoint && <XIcon className="unselect-icon" onClick={() => unselectEndPoint(fromRightPanel)}/>}
                            </div>
                            <div className="provider-box-icon">
                                <Isvg className="provider-icon" src={endPoint.icon?.default || endPoint.icon}/>
                            </div>
                        </div>
                        <div className="endpoint-name">{endPoint.title}</div>
                    </div>}
                    {endPoint && showSelector && <ProtocolSelector
                        endpoint={endPoint}
                        setProtocol={protocol => setEndPoint({...endPoint, protocol})}/>}
                </div>

            </div>
            {endPoint && showLoaderCondition(endPoint) &&
            <div className="loader">
                <SmallLoader/>
            </div>}
        </div>)

};

export default DropEndPointNew;

const ProtocolSelector = ({setProtocol, endpoint: {protocolOptions, protocol}}) => {
    return <div className="endpoint-protocol">
            <div className="select-text">Select Protocol</div>
            <div className="protocol-options">
                {protocolOptions.map((option, idx) =>
                    <button type="button" key={`option-${idx}`} className={`protocol ${option === protocol ? "selected" : ""}`} onClick={()=>{setProtocol(option)}}>
                        {option === "cifs" ? "SMB" : option.toUpperCase()}
                    </button>)}
            </div>
    </div>
};
