import React from 'react';

export const ClickableDiv = (divProps) => {
    // if div has click handler - activate it with Enter or Space keys
    const handleKeyUp = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            divProps.onClick();
        }
    };

    // tabIndex 0 makes div focusable (role button is for screen readers)
    return <div {...divProps} onKeyUp={divProps.onClick && handleKeyUp} tabIndex="0" role="button"/>;
};