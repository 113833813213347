import React from 'react';
import ProviderLoginLayout from './ProviderLoginLayout';
import {AWS_CREATE_BROKER_POLICY_REQUIREMENTS, FM_AGENT_TYPE_AWS} from '../../consts';
import {InputField, SelectField} from '../_common_/forms/forms';
import {reduxForm, Field, getFormValues} from 'redux-form';
import Validator from 'validatorjs';
import {getVpcs} from '../../services/aws.api.service';
import {connect, useDispatch} from 'react-redux';
import {setAwsCredentialsAndRegion} from "../../store/deploy/deploySlice";
import {extractErrorAws} from "../../utils/providers-utils";
import {awsRegions} from '../../services/aws.api.service';
import { TooltipInfo } from '@netapp/shared-components';
import "./providerLoginAws.scss";


const ProviderLoginAws = ({handleContinue, setStepError, handleSubmit, submitting, dataBroker, formValues}) => {
    const {fileLink} = dataBroker;
    const templateUrl = fileLink && fileLink.substr(fileLink.indexOf("https%3"));
    const cloudFormationLink = templateUrl && `${fileLink}&templateUrl=${templateUrl}`;
    const noCloudFormationForGovMsg = (<span>This option <strong>can't</strong> be used with GovCloud regions</span>);

    const dispatch = useDispatch();
    const onSubmit = async (values) => {
        setStepError(null);
        const credentials = {
            accessKeyId: values.accessKeyId,
            secretAccessKey: values.secretAccessKey,
            region: values.selectedRegion?.value
        };
        try {
            //try to fetch data just in order to validate the credentials
            await getVpcs(credentials);
            dispatch(setAwsCredentialsAndRegion(credentials));
            handleContinue();
        } catch (err) {
            setStepError(extractErrorAws(err));
        }
    };

    return (
        <ProviderLoginLayout
            providerIcon={FM_AGENT_TYPE_AWS.circledIcon}
            title={"Provide AWS credentials"}
            handleClick={handleSubmit(onSubmit)}
            submitting={submitting}
        >
            <div className="form-content">
                <p>We need an AWS access key to create the data broker in AWS on your behalf.</p>
                <p>{`The keys aren't saved or used for any other purposes.`}</p>

                <form className="aws-login-form">
                    <Field component={InputField}
                           label="AWS Access Key"
                           name="accessKeyId"
                           placeholder="Enter AWS Access Key"/>
                    <Field component={InputField}
                           label="AWS Secret Key"
                           name="secretAccessKey"
                           type="password"
                           placeholder="Enter AWS Secret Key"/>
                    <Field component={SelectField}
                           label="Region"
                           name="selectedRegion"
                           placeholder="Select Region"
                           options={awsRegions}
                           valueField="value"
                           isDisabled={submitting}/>

                </form>
                <p>Make sure that your AWS account has the permission defined in the <a href={AWS_CREATE_BROKER_POLICY_REQUIREMENTS} target="_blank" rel="noopener noreferrer"> Policy Requirements</a>.
                </p>
                {!formValues?.selectedRegion?.value.includes('-gov-') && <p>If you’d rather not provide access keys, <a href={cloudFormationLink} target="_blank"
                                                                                       rel="noopener noreferrer">use a
                    CloudFormation template instead. </a>
                    <TooltipInfo>{noCloudFormationForGovMsg}</TooltipInfo>
                </p>}
            </div>
        </ProviderLoginLayout>);
};

const rules = {
    accessKeyId: ['required'],
    secretAccessKey: ['required'],
    selectedRegion: ['required']
};

const validate = (values) => {
    const validator = new Validator(values, rules);
    validator.setAttributeNames({
        accessKeyId: 'AWS Access Key',
        secretAccessKey: 'AWS Secret Key',
        selectedRegion: 'Region'});
    validator.passes();
    return validator.errors.all();
};

const ProviderLoginAwsForm = reduxForm({
    form: 'providerLoginAws',
    validate,
    destroyOnUnmount: false
})(ProviderLoginAws);


export default connect((state) => ({
    formValues: getFormValues('providerLoginAws')(state)}),
    {}
)(ProviderLoginAwsForm);
