import React, {useState, useMemo} from 'react';
import _ from 'lodash';
import SearchableTableContainer from '../../table/SearchableSelectableTable';
import useRunOnceWhenTruthy from '../../../hooks/useRunOnceWhenTruthy';
import {ReactComponent as VolumeIcon} from "../../../assets/svgs/volume.svg";
import {ReactComponent as CheckmarkIcon} from "../../../assets/svgs/checkmark-fill-circle-icon.svg";
import numeral from "numeral";
import "./volSelectionNew.scss";
import "./cmTableNew.scss";
import {extractIpAddressAndPathFromUrl, extractVersionFromString} from "../../../utils/encoders";
import {DEFAULT_CIFS_VERSION, NFS_VERSIONS_CONVERTED} from "../../../consts";
import RefreshButton from '../../refreshButton/RefreshButton';

const CmVolSelection = ({volumes, defaultVolumeId, setVolumeInStep, volumesFilterFunction, volumeMapFunction, protocol, refreshStep}) => {

    const [showVolumes, setShowVolumes] = useState([]); //the displayed volumes, after filter and sort
    const [selectedVolume, setSelectedVolume] = useState(null);

    const formattedVolumes = useMemo(() => {
        return volumes.data && processVolumes(volumes.data, volumesFilterFunction, volumeMapFunction, protocol);
    }, [volumes, volumesFilterFunction, volumeMapFunction, protocol]);

    useRunOnceWhenTruthy( () => {
        setShowVolumes(formattedVolumes);
        //initialize default selected (back navigation):
        handleVolumeSelect(defaultVolumeId);

    }, formattedVolumes);

    const handleVolumeSelect = volId => {
        const volume = _.find(formattedVolumes, {id: volId});
        setSelectedVolume(volume); //for the selection in the table
        setVolumeInStep(volume); //for the step selection
    };

    const applySort = (column, ascending, volumes) => {
        const sortedVolumes = _.sortBy(volumes ? volumes : showVolumes, [column]);
        setShowVolumes(ascending ? sortedVolumes : _.reverse(sortedVolumes));
    };

    return (
        <div className="vol-selection">
            {formattedVolumes?.length > 0 &&
                <div className="volumes-table">
                    <SearchableTableContainer
                        containerOptions={containerOptions}
                        Table={{renderRow, getTableHeader: () => getTableHeader(applySort)}}
                        data={showVolumes}
                        selectionOptions={{
                            selector: handleVolumeSelect,
                            selectedId: selectedVolume && selectedVolume.id
                        }}
                        refreshButton={refreshStep ? <RefreshButton onClick={refreshStep} storageObjectName={containerOptions.pluralTitle}/> : undefined}
                    />
                </div>}
            {formattedVolumes?.length === 0 &&
            <div>
                <div className="no-volumes">
                <VolumeIcon className="no-volumes-icon"/>
                <div>There are no volumes</div>
            </div>
            </div>}
            {volumes.failed &&
            <div className="nothing-found">
                <span><VolumeIcon/></span>
                <p>Error retrieving volumes</p>
            </div>}
        </div>)
};

export default CmVolSelection;

const processVolumes = (vols, filterFunction = () => true, volumeMapFunction, protocol) => {
    const updatedVols = vols
        .filter(vol => filterFunction(vol))
        .map(vol => {
            const protocolWithVersion = vol.exportPolicyInfo && vol.exportPolicyInfo?.nfsVersion?.length > 0 ? vol.exportPolicyInfo.nfsVersion[0] : '';
            const version = extractVersionFromString(protocolWithVersion);
            const listOfNFSVersions = vol.exportPolicyInfo?.nfsVersion || ['nfs3', 'nfs4'];

            let ipAddress = null;
            let actualPath = null; //in cvo it can differ from the volume name
            const isCifsProtocol = protocol === "cifs";
            if (isCifsProtocol && vol.cifsShareAccessPoint) {
                const cifsShareAccessPoint = vol.cifsShareAccessPoint;
                const params = extractIpAddressAndPathFromUrl(cifsShareAccessPoint);
                ipAddress = params.ipAddress;
                actualPath = params.actualPath;
                if (_.isEmpty(actualPath)) {
                    actualPath = "/";
                }
            }
            else if (!isCifsProtocol && vol.mountPoint) {
                const mountPoint = vol.mountPoint;
                const params = extractIpAddressAndPathFromUrl(mountPoint);
                ipAddress = params.ipAddress;
                actualPath = params.actualPath || vol.name; //in case the volume is offline there won't be an actual path, use the vol name instead
            }
            // console.log(`%c processVolumes isAddress is ${ipAddress} and actual path is ${actualPath}`, 'font-weight:bold; color:blue');
            return {
                id: vol.name,
                name: vol.name,
                root: actualPath,
                svmName: vol.svmName,
                aggregate: vol.aggregateName,
                size: vol.size,
                usedSize: vol.usedSize,
                junctionPath: vol.junctionPath,
                mountPoint: isCifsProtocol? vol.cifsShareAccessPoint : vol.mountPoint,
                protocol: isCifsProtocol? "cifs" : "nfs",
                version: !isCifsProtocol? NFS_VERSIONS_CONVERTED[version] : DEFAULT_CIFS_VERSION,
                ipAddress,
                listOfNFSVersions : !isCifsProtocol ? listOfNFSVersions : undefined,
                listOfCifsVersions: isCifsProtocol,
                hasExportPolicyWithVersions : Boolean(vol.exportPolicyInfo?.nfsVersion)
            };
        });
        return volumeMapFunction ? updatedVols.map(volumeMapFunction) : updatedVols;
};

const containerOptions = {
    singularTitle: "Volume",
    pluralTitle: "Volumes"
};

const renderRow = (row) => {
    return (
        <div>
            <td className="check"> <span><CheckmarkIcon/></span></td>
            <td title={row.name}>{row.name}</td>
            <td>{row.svmName}</td>
            <td>{row.aggregate}</td>
            <td>{row.mountPoint}</td>
            <td>{numeral(row.size.size).format('0.[0]a')} {row.size.unit}</td>
            <td>{numeral(row.usedSize.size).format('0.[0]a')} {row.usedSize.unit}</td>
        </div>
    )
};

const getTableHeader = (applySort) => {
    return [
        {
            className: 'blank',
            width: "6%",
        },
        {
            name: 'name',
            width: "13%",
            className: 'volume-name',
            text: 'Volume Name',
            sort: applySort
        },
        {
            name: 'svmName',
            className: 'svm-name',
            text: 'SVM Name',
            sort: applySort
        },
        {
            name: 'aggregate',
            className: 'aggr-name',
            text: 'Aggregate Name',
            sort: applySort
        },
        {
            name: 'path',
            className: 'path',
            text: 'Mount Point',
            width: "20%",
            sort: applySort
        },
        {
            name: 'size',
            className: 'size',
            text: 'Size'
        },
        {
            name: 'usedSize',
            className: 'used-size',
            text: 'Used Size'
        }    ];
};
