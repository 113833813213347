import AlertDialog from '../dialog/AlertDialog';
import React, { useState } from "react";
import "./errorsDialog.scss"
import ErrorItem from './ErrorItem';


const ErrorsDialog = ({errors, errorIdentifier, dialogTitle, getErrorTitle}) => {

  const [expandedItemId, setExpandedItemId] = useState(errors[0][errorIdentifier]);

  return (
    <AlertDialog
      title={dialogTitle}
      className="errors-dialog"
    >
      {errors.map((error, index) =>
          <ErrorItem
            key={`err-${index}`}
            error={error}
            title={getErrorTitle(error)}
            isExpanded={errorIdentifier ? expandedItemId === error[errorIdentifier] : true}
            setExpandedItemId={setExpandedItemId}
            isSingleError={errors.length === 1}
          />)}
    </AlertDialog>
  )
};

export default ErrorsDialog;