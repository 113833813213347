import React  from 'react'
import {Doughnut} from 'react-chartjs-2';

require ('./failedComponents.scss')

export const SummaryCard = ({header, labelInsidePie, failedCount, successCount, children}) => (
    <div className="pie-chart-container">
        <div className="chart-title">{header}</div>
        <div className="chart-details">
            <PieChart failedCount={failedCount} successCount={successCount} labelInsidePie={labelInsidePie}/>
            <div className="legend-area">
                <div className="legend-area-separator"/>
                <div className="legend-area-container">
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export const LegendNarrow = ({failedCount, successCount, icon, subtitle, failedLabel, successLabel}) => (<>
    <div className="subtitle">
        {icon}
        {subtitle}
    </div>
    <div className="legend">
        <LegendUnit value={failedCount} text={failedLabel} status="failed"/>
        <LegendUnit value={successCount} text={successLabel} status="success"/>
    </div>
</>);

export const LegendWide = ({failedCount, successCount, icon, failedLabel, successLabel}) => (<>
    <div className="legend legend-wide">
        <LegendUnit value={failedCount} text={failedLabel} status="failed" icon={icon}/>
        <LegendUnit value={successCount} text={successLabel} status="success" icon={icon}/>
    </div>
</>);

const LegendUnit = ({value, status, text, icon}) => (
    <div className="legend-with-icon">
        {icon}
        <div className="legend-unit">
            <div className="value">{value}</div>
            <div className={`status ${status}`}>{text}</div>
        </div>
    </div>
);

const PieChart = ({failedCount, successCount, labelInsidePie}) => {

    const total = failedCount + successCount;
    const isTotalZero = total === 0;
    const percentFailed = isTotalZero ? 0 : Number(failedCount / total * 100);
    const percentFailedRounded = Math.round( percentFailed * 10 ) / 10;

    const dataForPie = [
        {
            value: failedCount,
            color: '#DA1E27'
        }, {
            value: isTotalZero ? 1 : successCount, //in case of isTotalZero, give the success a value just so the pie will be painted
            color: '#93b3e5'
        }];

    return <div className="pie-chart">
        <Doughnut
            options={{
                animation: false,
                plugins: {datalabels: {display: false}, tooltip: {enabled: false}},
                preserveAspectRatio: true,
                cutout: 90
            }}
            legend={{ display: false }}
            data={{
                datasets: [{
                    data: dataForPie.map(item => item.value),
                    backgroundColor: dataForPie.map(item => item.color)}]}}
        />
        <div className="inside-pie">
            <div className="big-text">{percentFailedRounded}%</div>
            <div className="small-text">{labelInsidePie}</div>
        </div>
    </div>
};




