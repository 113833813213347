import axios from "axios";
import {mockAwsRoutes} from './cloudProvidersSimulator';

const axiosInstance = axios.create();
mockAwsRoutes(axiosInstance);

class SimulatedEC2 {
    constructor(mockBaseApi){
        this.mockBaseApi = mockBaseApi;
    }
    describeVpcs(){
        return axiosInstance.get(`${this.mockBaseApi}/describe-vpcs`).then(res => res.data);
    }
    describeSubnets(){
        return axiosInstance.get(`${this.mockBaseApi}/describe-subnets`).then(res => res.data);
    }
    describeKeyPairs(){
        return axiosInstance.get(`${this.mockBaseApi}/describe-key-pairs`).then(res => res.data);
    }
}

class SimulatedKMS {
    constructor(mockBaseApi){
        this.mockBaseApi = mockBaseApi;
    }
    listAliases(){
        return axiosInstance.get(`${this.mockBaseApi}/describe-aliases-kms`).then(res => res.data);
    }
}

class SimulatedCloudFormation {
    constructor(mockBaseApi){
        this.mockBaseApi = mockBaseApi;
    }
    createStack(params){
        return axiosInstance.post(`${this.mockBaseApi}/create-stack`, params).then(res => res.data);
    }
    describeStacks({StackName}){
        if (StackName === "StackError")
            return axiosInstance.get(`${this.mockBaseApi}/describe-stacks/deploy-error`).then(res => res.data);
        if (StackName === "StackProgress")
            return axiosInstance.get(`${this.mockBaseApi}/describe-stacks/deploy-progress`).then(res => res.data);
        return axiosInstance.get(`${this.mockBaseApi}/describe-stacks`).then(res => res.data);
    }
    deleteStack(){
        return axiosInstance.post(`${this.mockBaseApi}/delete-stack`).then(res => res.data);
    }
}

export {SimulatedEC2, SimulatedCloudFormation, SimulatedKMS}
