import React from 'react'
import './doubleLineItem.scss'

const DoubleLineItem = ({title, value, className='', valueComp: ValueComp}) => {
    return (<div className={`double-line flex flex-dir-column ${className}`}>
        {!ValueComp && <div className="double-line-value" title={value}>{value}</div>}
        {ValueComp && <div className="double-line-value">{ValueComp}</div>}
        <div className="double-line-title">{title}</div>
    </div>)
};
export default DoubleLineItem
