import React from 'react';
import {ReactComponent as ErrorIcon} from '../../assets/svgs/notification-icon-error.svg';
import {ReactComponent as CaretDownIcon} from '../../assets/svgs/triangle.svg';
import {ReactComponent as CopyToClipboardIcon} from '../../assets/svgs/copy-to-clipboard-new.svg';
import {ButtonBase} from '@netapp/shared-components';
import AnimateHeight from 'react-animate-height';
import CopyToClipboard from "react-copy-to-clipboard";
import './errorItem.scss';

const ErrorItem = ({title, error, isExpanded, setExpandedItemId, isSingleError}) => {

    const errorText = error.reason;

    return <div className={`error-item`}>

        <div className="error-title">
            <div className="title-and-icon">
                <div className="notification-icon">
                    <ErrorIcon/>
                </div>
                <div className="error-text">{title}</div>
            </div>

            {!isSingleError && <ButtonBase type="button" className="underline-button" onClick={() => setExpandedItemId(isExpanded ? null : error.dataBrokerId)}>
                <div className={`caret-icon ${(isExpanded || isSingleError) ? 'open' : ''}`}>
                    <CaretDownIcon/>
                </div>
            </ButtonBase>}

        </div>
        <AnimateHeight
            duration={300}
            easing='cubic-bezier(.25,.8,.25,1)'
            height={(isExpanded) ? 'auto' : 0}>
            <div className='error-expanded'>
                <div className={'error-expanded-message'}>
                    <p>Error Message:</p>
                    {errorText}
                </div>
                <div className={'error-copy'}>
                    <CopyToClipboard text={errorText} className="copy-button">
                        <div>
                            <CopyToClipboardIcon/>
                            <div className="copy-text">Copy</div>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        </AnimateHeight>
    </div>
};

export default ErrorItem;