import {useEffect, useRef} from 'react';
import isEqual from "lodash/isEqual";

const useDeepCompareEffect = (effect, dependencies) => {
    const ref = useRef(undefined);

    if (!ref.current || !isEqual(dependencies, ref.current)) {
        ref.current = dependencies;
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, ref.current);
};

export default useDeepCompareEffect;