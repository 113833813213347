import React from 'react';
import { CheckboxField } from '../../_common_/forms/forms';
import { Field } from 'redux-form';
import Notice from '../../notifications/Notice';
import AclMultiSelection from './AclMultiSelection';
import "./aclSelectionNew.scss";

const AclSelection = ({ aclMultiSelection }) => {
    return <div className="acl-selection credentials-container">
        <div className="acl-title">
            ACL - Access Control List
        </div>
        <div className="acl">
            {aclMultiSelection ?
                <AclMultiSelection/> : <>
                    <Field id="copyAcl"
                        type="checkbox"
                        component={CheckboxField}
                        name="copyAcl"
                        className="acl-check-box"
                    />
                    <label htmlFor="copyAcl">Copy Access Control Lists to the target</label>
                </>
            }
        </div>
        <div className="notices">
            <Notice type="warning" noIcon={true}>Copying ACLs can affect sync performance. You can change this setting after you create the relationship.</Notice>
            <Notice type="warning" noticeTitle="Attention" noIcon={true}>If the sync relationship includes Cloud Volumes ONTAP or an on-prem ONTAP cluster and you selected NFSv4 or later, then you'll need to enable NFSv4 ACLs on the ONTAP system. This is required to copy the ACLs.</Notice>
        </div>
    </div>;
};

export default AclSelection;
