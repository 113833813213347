import React, {useState} from 'react'
import {ReactComponent as RightArrow} from "../../../assets/svgs/right-arrow.svg";
import {ReactComponent as GreenCheckMark} from '../../../assets/svgs/checkmark-green.svg'
import {ReactComponent as FailedIcon} from '../../../assets/svgs/failed-icon.svg'
import {Button} from '@netapp/shared-components';
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import Validator from "validatorjs";
import {InputField} from "../../_common_/forms/forms";
import {editGroupSettings} from "../../../store/wizard/action_creators";
import {connect, useDispatch} from 'react-redux';
import SearchableTableContainer from "../../table/SearchableSelectableTable";
import {find , map, isNil} from 'lodash'
import MainLoader from "../../_common_/MainLoader/MainLoader";
import useRunOnceWhenTruthy from "../../../hooks/useRunOnceWhenTruthy";
import {TooltipInfo} from '@netapp/shared-components';
import Notice from '../../notifications/Notice';
import {compareConfiguration, extractConfigurationFromGroupOrPlacement} from "../../../utils/data-utils";
import './groupSettings.scss'
import {
    GROUP_SETTINGS_INFO,
    GROUP_SETTINGS_SCAN_CONCURRENCY_INFO,
    GROUP_SETTINGS_SCAN_PROCESSES_INFO,
    GROUP_SETTINGS_TRANSFERRER_CONCURRENCY_INFO,
    GROUP_SETTINGS_TRANSFERRER_PROCESSES_INFO
} from "../../../consts";
import { useNavigate, useParams } from 'react-router-dom';

const rules =  {
        scannerConcurrency: ['integer', 'max:200', 'min:1'],
        scannerProcessesLimit: ['integer', 'max:10', 'min:1'],
        transferrerConcurrency: ['integer', 'max:200', 'min:1'],
        transferrerProcessesLimit: ['integer', 'max:10', 'min:1']
};


const validate = (values, props, groupId) => {
    const {_dataBrokersGroups} = props;
    const group = find(_dataBrokersGroups?.data, {id: groupId});
    const groupConfiguration = extractConfigurationFromGroupOrPlacement(group?.configuration)
    const scannerConcurrency = groupConfiguration?.scannerConcurrency || null;
    const scannerProcessesLimit = groupConfiguration?.scannerProcessesLimit || null;
    const transferrerConcurrency = groupConfiguration?.transferrerConcurrency || null;
    const transferrerProcessesLimit = groupConfiguration?.transferrerProcessesLimit || null;

    const validator = new Validator(values, rules, {
        integer: 'Value must be an integer',
        max: 'Max value is :max',
        min: 'Min value is :min'
    });
    validator.setAttributeNames({
        scannerConcurrency: 'Scanner Concurrency',
        scannerProcessesLimit: "Scanner Processes Limit",
        transferrerConcurrency: "Transferrer Concurrencty",
        transferrerProcessesLimit: "Transferrer Processes Limit"
    });
    validator.passes();
    const errors = validator.errors.all();
    const setValueError = "Set field can't be deleted."
    if (!isNil(scannerConcurrency )&& isNil(values.scannerConcurrency)) {
        errors.scannerConcurrency = errors.scannerConcurrency || [];
        errors.scannerConcurrency.push(setValueError);
    }
    if (!isNil(scannerProcessesLimit) && isNil(values.scannerProcessesLimit)) {
        errors.scannerProcessesLimit = errors.scannerProcessesLimit || [];
        errors.scannerProcessesLimit.push(setValueError);
    }
    if (!isNil(transferrerConcurrency) && isNil(values.transferrerConcurrency)) {
        errors.transferrerConcurrency = errors.transferrerConcurrency || [];
        errors.transferrerConcurrency.push(setValueError);
    }
    if (!isNil(transferrerProcessesLimit) && isNil(values.transferrerProcessesLimit)) {
        errors.transferrerProcessesLimit = errors.transferrerProcessesLimit || [];
        errors.transferrerProcessesLimit.push(setValueError);
    }
    return validator.errors.all();
};


const renderRow = (row) => {
    const {isReady, placement, name, matchConfiguration} = row;
    const {scanner, transferrer} = placement ? placement : {scanner: {concurrency: "N/A", processesLimit: "N/A"}, transferrer: {concurrency: "N/A", processesLimit: "N/A"}};
    const tooltipText =
        !isReady ?
            <span><b>Attention: </b>A unified configuration will be applied to active data brokers only. The configuration for inactive data brokers will be updated when restarted.</span> :
        !matchConfiguration ?
            <span><b>Attention: </b> This data broker has a different configuration than the unified group configuration. Click "Unify Configuration" to set the configuration of this data broker to the values defined above.
            </span> : null;
    return (
        <div>
            {/*blank*/}
            <td className="blank"/>
            <td className="name with-tooltip"><div className="name" title={name}>{name}</div>{!matchConfiguration || !isReady ? <TooltipInfo popoverClass="data-broker-warning">{tooltipText}</TooltipInfo>: ''}</td>
            <td className="status">{!isReady ? <FailedIcon/> : <GreenCheckMark/>}</td>
            <td className="scanner-concurrency">{!isReady ? '--' : scanner.concurrency}</td>
            <td className="scanner-processes-limit">{!isReady ? '--' : scanner.processesLimit}</td>
            <td className="transferrer-concurrency">{!isReady ? '--' : transferrer.concurrency}</td>
            <td className="transferrer-processes-limit">{!isReady ? '--' : transferrer.processesLimit}</td>
        </div>
    )
}


const getTableHeader = () => {
    return [
        {className: 'blank'},
        {className: 'name', name: "name", text: "Data Broker Name", width: "15%"},
        {className: 'status', name: "status", text: "Status", width: "10%"},
        {className: 'scanner-concurrency', name: "scanner.concurrency", text: "Scanner Concurrency"},
        {className: 'scanner-processes-limit', name: "scanner.processesLimit", text: "Scanner Processes Limit"},
        {className: 'transferrer-concurrency', name: "transferrer.concurrency", text: "Transferrer Concurrency"},
        {className: 'transferrer**/-processes-limit', name: "transferrer.processesLimit", text: "Transferrer Processes Limit"},
    ]
}

const containerOptions = {
};

const GroupSettings = (props) => {
    const {_dataBrokersGroups} = props;
    const {groupId} = useParams('groupId');
    const {groupName} = useParams('groupName');
    const group = find(_dataBrokersGroups?.data, {id: groupId});
    const [dataBrokers, setDataBrokers] = useState(null)
    const [initialFormValues, setInitialFormValues] = useState(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useRunOnceWhenTruthy(() => {
        let originalConfiguration = null;
        const formValues = {};
        if (group?.configuration) {
            originalConfiguration = extractConfigurationFromGroupOrPlacement(group.configuration) ;
            if (!isNil(group.configuration.scanner?.concurrency)) {
                formValues.scannerConcurrency = group.configuration.scanner.concurrency.toString();
            }
            if (!isNil(group.configuration.scanner?.processesLimit)) {
                formValues.scannerProcessesLimit = group.configuration.scanner.processesLimit.toString();
            }
            if (!isNil(group.configuration.transferrer?.concurrency)) {
                formValues.transferrerConcurrency = group.configuration.transferrer.concurrency.toString();
            }
            if (!isNil(group.configuration.transferrer?.processesLimit)) {
                formValues.transferrerProcessesLimit = group.configuration.transferrer.processesLimit.toString();
            }
        }
        const dataBrokers = map(group?.dataBrokers, dataBroker => {
            return {
                ...dataBroker,
                //if there is no configuration data broker matches by default
                matchConfiguration: group?.configuration && dataBroker.placement? compareConfiguration(extractConfigurationFromGroupOrPlacement(dataBroker.placement), originalConfiguration) : true
            }
        });
        setDataBrokers(dataBrokers);
        setInitialFormValues(formValues);
    }, group)

    const goBack = (groupId) => {
        navigate({
            pathname: `/manage`,
            search: `${window.location.search}${groupId ? `?extendGroup=${groupId}`: ''}`
        });
    }

    const submit = (values) => {
        const settings = {
            scanner: {
                concurrency: values.scannerConcurrency ? parseInt(values.scannerConcurrency) : undefined,
                processesLimit: values.scannerProcessesLimit ? parseInt(values.scannerProcessesLimit) : undefined
            },
            transferrer: {
                concurrency: values.transferrerConcurrency ? parseInt(values.transferrerConcurrency) : undefined,
                processesLimit: values.transferrerProcessesLimit ? parseInt(values.transferrerProcessesLimit) : undefined
            }
        }
        return editGroupSettings(groupId, settings)(dispatch)
            .then(() => {
                goBack(groupId);
            })
            .catch((error) => {
                if (error.status !== 401) {
                    return Promise.resolve({[FORM_ERROR]: error.data.message ? error.data.message : error.data});
                }
            })
    };

    if (!dataBrokers) return <MainLoader/>

    return (<div className="scrollable-area">

        <div className="group-settings">
            <div className="bread-crumbs">
                <div className="back" onClick={() => goBack()}>Manage Data Brokers</div>
                <div className="right-arrow"><RightArrow/></div>
                <div className="path">{`Data Broker Group ${groupName} configuration`}</div>
                <div/>
            </div>
            <Form onSubmit={submit} validate={(values) => validate(values, props, groupId)} initialValues={initialFormValues}>
                {({ handleSubmit, form, submitError: error}) => {
                    const formState = form.getState();
                    const {modifiedSinceLastSubmit} = formState;
                    return (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className="group-settings-card">
                            <div className="group-settings-values">
                                <div className="values-title">{group?.configuration ? "Edit the" : "Define a"} Unified
                                    Configuration for all the Data Brokers in the group
                                    <TooltipInfo popoverClass="settings-info">{GROUP_SETTINGS_INFO}</TooltipInfo>
                                </div>
                                <div className="settings-container">
                                    <div className="field">
                                        <label htmlFor="scannerConcurrency">Scanner Concurrency</label>
                                        <label
                                            htmlFor="scannerConcurrency">(1-200)<TooltipInfo popoverClass="settings-info">{GROUP_SETTINGS_SCAN_CONCURRENCY_INFO}</TooltipInfo></label>
                                        <Field name="scannerConcurrency"
                                               component={InputField}
                                               type="number" min={1} max={200} placeholder="--"/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="scannerProcessesLimit">Scanner Processes Limit</label>
                                        <label
                                            htmlFor="scannerProcessesLimit">(1-10)<TooltipInfo popoverClass="settings-info" >{GROUP_SETTINGS_SCAN_PROCESSES_INFO}</TooltipInfo></label>
                                        <Field name="scannerProcessesLimit"
                                               component={InputField}
                                               type="number" min={1} max={10} placeholder="--"/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="transferrerConcurrency">Transferrer Concurrency</label>
                                        <label
                                            htmlFor="transferrerConcurrency">(1-200)<TooltipInfo popoverClass="settings-info" >{GROUP_SETTINGS_TRANSFERRER_CONCURRENCY_INFO}</TooltipInfo></label>
                                        <Field name="transferrerConcurrency"
                                               component={InputField}
                                               type="number" min={1} max={200} placeholder="--"/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="transferrerProcessesLimit">Transferrer Processes Limit</label>
                                        <label
                                            htmlFor="transferrerProcessesLimit">(1-10)<TooltipInfo popoverClass="settings-info" >{GROUP_SETTINGS_TRANSFERRER_PROCESSES_INFO}</TooltipInfo></label>
                                        <Field name="transferrerProcessesLimit"
                                               component={InputField}
                                               type="number" min={1} max={10} placeholder="--"/>
                                    </div>
                                </div>
                            </div>
                            <div className="data-brokers-values">
                                <div>Data Brokers Configuration</div>
                                <div className="data-brokers">
                                    <div className="scrollable-area">
                                        {dataBrokers &&
                                        <SearchableTableContainer noHeader={true} containerOptions={containerOptions}
                                                                  Table={{renderRow, getTableHeader}}
                                                                  data={dataBrokers}
                                        />}

                                    </div>
                                </div>
                            </div>
                            <div className="group-settings-buttons-container">
                                <div className="buttons-error">
                                    {error && !modifiedSinceLastSubmit && <Notice type="error" noticeTitle="Error">{error}</Notice>}
                                </div>
                                <div className="group-settings-buttons">
                                    <Button type="submit" disabled={!form.getState().dirty}>Unify Configuration</Button>
                                    <Button variant="secondary" onClick={() => goBack()}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}}
            </Form>
        </div>
    </div>)
}


const mapStateToProps = (state) => {
    return {
        _dataBrokersGroups: state.syncReducer._dataBrokersGroups,
    }
};

export default connect(mapStateToProps, {editGroupSettings})(GroupSettings);
