import React from 'react'
import useRunOnceWhenTruthy from "../../hooks/useRunOnceWhenTruthy";
import LoadingBar from "../_common_/loadingBar/LoadingBar";
import LoadingIcon from "../_common_/loadingIcon/LoadingIcon";
import {getEndPointObjectForStep, getProtocolAndProviderKey} from "../../utils/sync-utils";
import {isItSourceStep} from "../../utils/reducerUtils";

export const buildMoreStepInfo = (props, stepId) => {
    if (!props) return;

    const {_wizard: {wizardInfo}, name} = props;
    const stepInfoByIdElement = props._wizard.stepInfoById[name];
    const {isTargetStepFromSameSourceType, protocol} = stepInfoByIdElement || {};
    const currentStepId = stepId({...props, isTargetStepFromSameSourceType, protocol});
    const endpointObj = getEndPointObjectForStep(wizardInfo, isTargetStepFromSameSourceType, currentStepId);
    const sourceStep = isItSourceStep(wizardInfo, currentStepId);
    const moreStepInfo = {
        currentStepId,
        endpointObj,
        isSourceStep: sourceStep,
        protocolAndProviderKey: endpointObj ? getProtocolAndProviderKey(endpointObj.protocol, endpointObj.provider) : undefined,
        isTargetStepFromSameSourceType,
        protocol: endpointObj?.protocol
    };
    return moreStepInfo;
}

const useStepinize = ({props, fetchData, shouldFetch, loading, stepId}) => {
    const moreStepInfo = buildMoreStepInfo(props, stepId);
    if (process.env.REACT_APP_INFO_LOGS === "true"){
        console.log(`%ccurrentStep: ${props._wizard.currentStep} MoreInfo:\n${JSON.stringify(moreStepInfo, null,2)}`, 'font-weight:bold;color:green')
    }


    useRunOnceWhenTruthy(()=> {
        fetchData({...props, ...moreStepInfo})
    }, shouldFetch({...props, ...moreStepInfo}));

    const LoadingComp = loading ? (<div className="full-height new-wizard">
        <div className="wizard-loading">
            <LoadingBar/>
            {loading && <LoadingIcon {...loading({...moreStepInfo})} />}
        </div>
    </div>) : null;


    return {LoadingComp, moreStepInfo}
}

export default useStepinize;
