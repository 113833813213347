import React from 'react';
import { store } from '../../store/store';
import {connect} from 'react-redux';
import {setCmIntegrationDetails} from '../../store/global/action_creators';
import {get, includes} from "lodash";
import {setActiveAccount} from "../../utils/messages-utils";
import queryString from 'query-string';
import MainLoader from '../_common_/MainLoader/MainLoader';
import useRunOnceWhenTruthy from "../../hooks/useRunOnceWhenTruthy";
import {getRelationshipIdFromPath} from "../../utils/cm-integration-utils";
import { routeToNewPathName } from '../ContentWrapper';
import { setAccountInfo } from '../../syncNew/store/slices/accountInfo.slice';
import { useNavigate, useLocation } from 'react-router-dom';

const typesMap = {
    ANF: "ANF",
    CVS: "CVS",
    VSA: "CVO",
    HA: "CVO",
    ON_PREM: "ONPREM",
    S3: "S3",
    FSX: "FSX",
    GCP: "GCP",
    AZURE: "AZURE",
    SG:"SGWS"
};

const CmIntegrationEntryPoint = (props) => {
    const { setActiveAccount, setCmIntegrationDetails} = props;
    const queryParams = queryString.parse(window.location.search);
    const navigate = useNavigate();
    const { pathname: pathnameFromLocation } = useLocation();

    useRunOnceWhenTruthy(() => {
        const pathname = queryParams.pathname;
        const accountId = queryParams.accountId;
        const agentId = queryParams.connectorId;
        const workspaceId = queryParams.workspaceId;

        const supportedWorkingEnvironmentTypes = Object.keys(typesMap);
        let sourceWorkingEnvironmentId = get(queryParams, 'sourceWorkingEnvironmentId', get(queryParams, 'workingEnvironmentId', null)); // another get or backward compatibility so we wont be dependant on CM changes
        let sourceWorkingEnvironmentName = get(queryParams, 'sourceWorkingEnvironmentName', get(queryParams, 'workingEnvironmentName', null)); // another get or backward compatibility so we wont be dependant on CM changes
        let sourceWorkingEnvironmentTypeParam = get(queryParams, 'sourceWorkingEnvironmentType', get(queryParams, 'workingEnvironmentType', null)); // another get or backward compatibility so we wont be dependant on CM changes
        let sourceWorkingEnvironmentType = includes(supportedWorkingEnvironmentTypes, sourceWorkingEnvironmentTypeParam)
            ? typesMap[sourceWorkingEnvironmentTypeParam]
            : null;
        let targetWorkingEnvironmentName = get(queryParams, 'targetWorkingEnvironmentName', null);
        let targetWorkingEnvironmentId = get(queryParams, 'targetWorkingEnvironmentId', null);
        const targetWorkingEnvironmentTypeParam = get(queryParams, 'targetWorkingEnvironmentType', null);
        let targetWorkingEnvironmentType = includes(supportedWorkingEnvironmentTypes, targetWorkingEnvironmentTypeParam)
            ? typesMap[targetWorkingEnvironmentTypeParam]
            : null;

        const isSourceQuery = queryParams.isSource;
       if (isSourceQuery === "false"){
            // old cm and target
           targetWorkingEnvironmentName = sourceWorkingEnvironmentName;
           targetWorkingEnvironmentId = sourceWorkingEnvironmentId;
           targetWorkingEnvironmentType = sourceWorkingEnvironmentType;
           sourceWorkingEnvironmentTypeParam = null;
           sourceWorkingEnvironmentName = null;
           sourceWorkingEnvironmentId = null;
           sourceWorkingEnvironmentType = null;
        }

        const hasSource = Boolean(sourceWorkingEnvironmentType && sourceWorkingEnvironmentName && sourceWorkingEnvironmentId);
        const hasTarget = Boolean(targetWorkingEnvironmentName && targetWorkingEnvironmentType && targetWorkingEnvironmentId);
        const fromCanvas = hasSource || hasTarget;
        const isSource = (isSourceQuery === "true" ? true : isSourceQuery === "false" ? false : null) ??
            (fromCanvas ? hasSource : null);

        const dataSenseHash = get(queryParams, 'dataSenseQueryUid', null);
        const {relationshipId:relationshipIdFromPath} = getRelationshipIdFromPath(pathname);
        const relationshipId = get(queryParams, 'relationshipId', relationshipIdFromPath);
        let dataSenseProtocol, dataSenseProvider, dataSenseHost, dataSensePort, dataSenseSource, dataSenseWeName, dataSenseVolName;
        if (dataSenseHash) {
            dataSenseProtocol = queryParams.sourceProtocol;
            dataSenseProvider = queryParams.sourceProvider;
            dataSenseHost = queryParams.host;
            dataSensePort = queryParams.port;
            dataSenseSource = queryParams.source;
            dataSenseWeName = queryParams.weName;
            dataSenseVolName = queryParams.volName;
        }
        const viewDashboard = !dataSenseHash;
        setActiveAccount({accountId:accountId}); //this also sets the account header

        //CS-7644 - refactor
        const { dispatch } = store;
        dispatch(setAccountInfo({
            accountId,
            connectorId: agentId,
            workspaceId
        }))

        const cmIntegrationDetails = {
            inputDetails: {
                source: {
                    sourceWorkingEnvironmentType,
                    sourceWorkingEnvironmentId,
                    sourceWorkingEnvironmentName,
                },
                target: {
                    targetWorkingEnvironmentType,
                    targetWorkingEnvironmentName,
                    targetWorkingEnvironmentId,
                },
                isSource,
            },
            general: {
                workspaceId,
                agentId,
                routingParams: {
                    pathname,
                    relationshipId,
                    viewDashboard,
                    fromCanvas,
                }
            },
            dataSense: dataSenseHash ? {
                connectorId: agentId,
                hash: dataSenseHash,
                protocol: dataSenseProtocol,
                provider: dataSenseProvider,
                host: dataSenseHost,
                port: dataSensePort,
                source: dataSenseSource,
                weName: dataSenseWeName,
                volName: dataSenseVolName
            } : undefined
        };
        setCmIntegrationDetails(cmIntegrationDetails);
        routeToNewPathName({pathname, relationshipId, viewDashboard, fromCanvas}, navigate, pathnameFromLocation);
    }, window.location.search);

    return <MainLoader/>;
};

const mapStateToProps = (state) => {
    return {
        auth: state.global.auth,
        _ui:  {...state.syncReducer._ui, ...state.global._ui},
    }
};

export default connect(mapStateToProps, {
    setCmIntegrationDetails,
    setActiveAccount
})(CmIntegrationEntryPoint);
