import React from 'react';
import Isvg from 'react-inlinesvg';
import { DraggableTypes} from "../../../consts";
import {useDrag} from "react-dnd";

const DraggableEndPointNew = ({icon, disabledIcon, isAllowed, title, onDrop}) => {
    const [{isDragging}, drag, preview] = useDrag(() => ({
        type: DraggableTypes.END_POINT,
        item: {endPointName: title},
        end(item, monitor) {
            if (!monitor.didDrop()) {
                return;
            }
            const { endPointName } = item;
            const { dropEndPoint } = monitor.getDropResult();
            onDrop(endPointName, dropEndPoint);
        },
        canDrag() {
            return isAllowed;
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
            opacity: monitor.isDragging() ? 0.4: 1,
        }),
    }), [isAllowed]);
    //Note: both svgs exists in advanced (only their display changes) in order to have them loaded when the step loads
    // This is to prevent a "jump" in UI when endpoint is dragged and svg load occurs.
    return (
        <div ref={preview}>
            <div className={`end-point-wrapper ${isDragging ? 'moving' : ''} ${isAllowed ? 'default' : 'disabled'}`}
                 key={title} style={{
                cursor: !isAllowed ? "auto" : "move"
            }} ref={drag}>
                <div className="end-point" title={title}>
                    <Isvg className={`${(isDragging || !isAllowed) ? "hidden" : ""}`} src={icon?.default || icon}/>
                    <Isvg className={`${(!isDragging && isAllowed) ? "hidden" : ""}`}
                          src={disabledIcon?.default || disabledIcon}/>
                </div>
                <div
                    className={`text ${isAllowed ? "" : "is-disabled"}`}>{title}
                </div>
            </div>
        </div>
    )
};


export default DraggableEndPointNew;
