import React, { FunctionComponent, useMemo } from "react";
import './relationshipInfoItem.scss';
import { Typography } from "@netapp/design-system";
//@ts-ignore
import { ReactComponent as EditCredentialsIcon } from "../../../../assets/svgs/pencil-icon.svg";

interface InfoItemProps {
    children: JSX.Element,
    title: string,
    subTitle: string,
    isRunning?: boolean,
    disabledText?: string,
    onClick?: (...args: any) => void
}

const InfoItem: FunctionComponent<InfoItemProps> = ({ children, title, subTitle, isRunning, disabledText, onClick }) => {

    const isEditable = useMemo(() => {
        return onClick && !isRunning;
    }, [onClick, isRunning]);

    return (
        <div className="relationshipInfoItem">
            {children}
            <div className="infoItemDetails">
                <Typography className='title' variant='Regular_14'>{title}</Typography>
                <div className={`subTitleContainer ${isEditable ? 'clickable' : ''}`} onClick={onClick}>
                    <Typography
                        title={isRunning ? disabledText : undefined}
                        className={`subTitle ${isRunning ? 'disabled' : ''}`}
                        variant='Regular_14'>
                        {subTitle}
                    </Typography>
                    {isEditable && <EditCredentialsIcon className='editIcon' />}
                </div>
            </div>
        </div>
    )
}

export default InfoItem;